import React, { useState, useEffect } from "react";
import {
    Box,
    Input,
    Button
} from "@amzn/awsui-components-react";

const CustomSingleInput = (props) => {
    var [rows, setRows] = useState([]);
    useEffect(() => {
        loadRows()
        return () => {
        };
    }, []);

    useEffect(() => {
        // console.log(props,getMergedInputValues())
        props.updateValue(props.field, getMergedInputValues())
    }, [rows])

    const getMergedInputValues = () => {
        var filtered = rows.filter(function (row) {
            return row != null;
        });
        return filtered
    }

    const onEdit = (value, index) => {
        rows[index] = value
        setRows([...rows])
    }

    const getInputItem = (value, index) => {
        return <div className='rows' key={index}>
            <div className="row" ><Input
                onChange={({ detail }) => onEdit(detail.value, index)}
                value={value}
            /></div>
            <div className="row"><Button iconName="close" variant="icon" onClick={() => {
                deleteRow(index)
            }} /></div>
        </div>
    }
    const deleteRow = (index) => {
        rows.splice(index, 1)
        setRows([...rows])
    }
    const addRow = () => {
        setRows([...rows, null])
    }
    const getAddButton = () => {
        return <div> <Button onClick={addRow} disabled={rows?.length>=1}>Add</Button> </div>
    }

    const loadRows = () => {
        let values = props.getValues(props.field)
        rows=[];
        values.forEach(element => {
            rows.push(element)
        });
        setRows(JSON.parse(JSON.stringify(values)))
    }

    return (
        <Box>

            {rows? (Array.from(rows).map((value, index) => {
                return getInputItem(value, index);
            })) : ''}

            {
                getAddButton()
            }
        </Box>
    )
}

export default CustomSingleInput;