import React, { useState, useEffect, useRef, useCallback } from "react";

import { useSelector, useDispatch } from "react-redux";
import CircleWithText from "../../CircleWithText";
import { useAuth } from "../../context/AuthContextProvider";
import { summaryColumnOptions } from './Constants'
import CollectionPreferences from "@amzn/awsui-components-react/polaris/collection-preferences";

import {
  AppLayout,
  Badge,
  Box,
  BreadcrumbGroup,
  Button,
  ColumnLayout,
  Container,
  Tabs,
  Grid,
  Header,
  HelpPanel,
  Icon,
  Modal,
  SpaceBetween,
  Spinner,
  Alert,
  Table,
  ExpandableSection,
  Flashbar,
  StatusIndicator,
  Link,
  Multiselect
} from "@amzn/awsui-components-react";
import { useHistory } from "react-router-dom";
import SideNav from "./SideNav";
import appSettings from "./app_settings";
import * as XLSX from "xlsx";

import moment from "moment";

import { setCurrentAppId } from "../../store/userAuthSlice";

import * as d3 from "d3";

import { AgGridReact } from "ag-grid-react";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";

import AccessDenied from "../../AccessDenied";
import WaitForPageAccess from "../../WaitForPageAccess";
import { API, graphqlOperation } from "aws-amplify";
import { Storage } from "aws-amplify";

import { numberFilterParams, dateFilterParams } from "../../utilities/Factory";
import {Board, BoardItem} from "@amzn/awsui-board-components";
import { boardI18nStrings, boardItemI18nStrings } from 'src/i18n-strings/board-I18nStrings';
import {getBoffinComputesWithStartedStatus} from './BoffinAPIs'


const EditItem = () => {
  const userAuthenticationDetails = useAuth();

  const userLDAPS = userAuthenticationDetails.USER_LDAP_GROUPS;

  const userId = useSelector((globalState) => globalState.auth.userId);
  const history = useHistory();
  const currentAppId = useSelector(
    (globalState) => globalState.auth.currentAppId
  );
  const isCurrentAppAuthorised = useSelector(
    (globalState) => globalState.auth.isCurrentAppAuthorized
  );
  const [isCheckingAccess, setIsCheckingAccess] = useState(true);
  const [isAuthorized, setIsAuthorized] = useState();

  const gridRef = useRef();
  const gridRef2 = useRef();


  const currentComputeId = useSelector(
    (globalState) => globalState.boffin.BoffinComputeId
  );
  const dispatch = useDispatch();

  const [activeTab, setActiveTab] = useState("first");
  const [item, setItem] = useState([]);
  const [ShowLoadingIcon, setShowLoadingIcon] = useState(false);
  const [showOverrideModal, setShowOverrideModal] = useState(false);
  const [hasUserAgreedToPost, setHasUserAgreedToPost] = useState(false);

  const [showPostingButton, setShowPostingButton] = useState(false);

  const [isFileSelected, setIsFileSelected] = useState(false);
  const [items, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedfileDetails, setSelectedFileDetails] = useState([]);
  const [fileName, setFileName] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const [statusUpdate, setStatusUpdate] = useState(
    "No status update at the moment."
  );
  const [dt, setDt] = useState("");

  const [summaryInfo, setSummaryInfo] = useState([]);
  const [summaryBreakdownInfo, setSummaryBreakdownInfo] = useState([]);
  const [filteredSummaryBreakdownInfo, setFilteredSummaryBreakdownInfo]  = useState([])


  const [activeTabId, setActiveTabId] = useState("details");

  const [approvedStatus, setApprovedStatus] = useState();

  const [postToOFAClicked, setPostToOFAClicked] = useState(false);

  const [postingMsgToUser, setPostingMsgToUser] = useState("Not Initiated");
  const [postingStatusData, setPostingStatusData] = useState("");
  const [showGroupIdDetailsModal, setShowGroupIdDetailsModal] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [dissableOverrideButtons, setDissableOverrideButtons] = useState(false);
  const [showFailureBadge, setShowFailureBadge] = useState(false)
  const [postingStatusInComputeInfo, setPostingStatusInComputeInfo] = useState();
  const [flashBarComments, setFlashBarComments] = useState( getFlasbarItems());
  const [showFlashBar, setShowFlashBar] = useState(false)
  const [summarySelected, setSummarySelected] = useState({selected:[]})
  const [boardItems, setBoardItems] = useState([
    { id: "SUMMARY_TABLE", rowSpan: 5, columnSpan: 6,data:{content:(<></>)}, header: "The following table shows the data of JE / GL Balance and Variance"},
    { id: "SUMMARY_FILTER_TABLE", rowSpan: 5, columnSpan: 6,data:{content:(<></>)}, header: "The following table shows the data based on row selection of the main summary table (All rows are shown if none selected on the main table)"}
  ]);
  const [selectedColumns, setSelectedColumns] = useState(summaryColumnOptions);

  const [summaryFilterColumnDefs] = useState([
    {field:'RowId' , width: '150px',sortable: true, resizable: true, 
          pinned: 'left'
    },
    {field:'cost_acct' , width: '150px',sortable: true, resizable: true, headerName:'Cost Account' },
    {field:'company' , width: '75px',sortable: true, resizable: true },
    {field:'dataset' ,    width: '150px', sortable: true, resizable: true },
    {field:'location' ,width: '75px', sortable: true, resizable: true },
    {field:'costcenter' , width: '75px',sortable: true, resizable: true },
    {field:'account' , width: '75px',sortable: true, resizable: true },
    {field:'project' , width: '75px',sortable: true, resizable: true },
    {field:'debit' , width: '150px',sortable: true, resizable: true, sort: 'desc', filterParams: numberFilterParams, comparator: (a,b) => (customSort(a,b))},
    {field:'credit' , width: '150px',sortable: true, resizable: true, filterParams: numberFilterParams, comparator: (a,b) => (customSort(a,b))},
    {field:'journalname' , width: '150px',sortable: true, resizable: true },
    {field:'linedescription' ,width: '350px', sortable: true, resizable: true },
    {field:'final_date_for_comparison' , width: '150px',sortable: true, resizable: true, filter: 'agDateColumnFilter', filterParams: dateFilterParams},
    {field:'dep_in_months' , width: '150px',sortable: true, resizable: true },
    {field:'dep_amount' , width: '150px',sortable: true, resizable: true, filterParams: numberFilterParams, comparator: (a,b) => (customSort(a,b))}

  ])

  useEffect(() => {
    if (currentAppId !== appSettings.appId) {
      dispatch(setCurrentAppId(appSettings.appId));
    }
    setIsAuthorized(isCurrentAppAuthorised);
    return () => {
      // this now gets called when the component unmounts
    };
  }, [ isCurrentAppAuthorised]);

  useEffect(() => {
    if(isAuthorized === true || isAuthorized === false){
      setIsCheckingAccess(!isCheckingAccess);
    } 
  }, [isAuthorized]);

  useEffect(() => {
    if (typeof(postingStatusInComputeInfo) !== "undefined" && postingStatusInComputeInfo !== "Not Initiated") {
      const FAILED_STATUS_LIST = ["FAIL", "ERROR", "JE_POST_ERROR", "JE_CREATION_ERROR"]
      setDissableOverrideButtons(true)
      setShowFailureBadge(false)
      if(postingStatusData) {
        postingStatusData.every((data) => {
          const [,record] = data
          if (FAILED_STATUS_LIST.includes(record.msg_validation_status?.toUpperCase()) ||
          FAILED_STATUS_LIST.includes(record.msg_je_created_status?.toUpperCase()) ||
          FAILED_STATUS_LIST.includes(record.msg_je_posted_status?.toUpperCase())
          ) {
            setDissableOverrideButtons(false)
            setShowFailureBadge(true)
            return false;
          }
          return true;
        })
      }
    }   
  }, [postingStatusData]);

  function getFlasbarItems() {
    return [{
      type: "error",
      dismissible: true,
      dismissLabel: "Dismiss message",
      onDismiss: () => {setFlashBarComments([])
                        setShowFlashBar(false)},
      content: (
        <>
        Please ReCompute after sometime - Other Compute Underway!
        </>
      ),
      id: "comments"
    }]
  }

  const breadItems = [
    { text: "Home", href: "/Home" },
    { text: "ATeam BI", href: "/ATeamBIHome" },
    { text: "Boffin", href: "/BoffinHome" },
    { text: "Review", href: "/BoffinViewAllItems" },
    { text: "Compute Details", href: "" },
  ];

  const onFollowHandler = (ev) => {
    ev.preventDefault();
    if (ev.detail.href) {
      history.push(ev.detail.href.substring(1));
    }
  };
  const helpContent = (
    <HelpPanel
      footer={
        <div>
          <h3>
            Learn more <Icon name="external" />
          </h3>
        </div>
      }
      header={
        <div>
          <div></div>
          <h2>Additional Info </h2>
        </div>
      }
    >
      <div></div>
      <div></div>
    </HelpPanel>
  );

  useEffect(() => {
    loadData();
  }, []);

  useEffect(()=>{
    setBoardItems((previous) => {
      const updated = boardItems.map((item) => {
        if(item.id === "SUMMARY_TABLE") {
          item.data.content = (
            <>
            <SpaceBetween direction="horizontal" size="xs">
              <Box>
                <Multiselect
                  options={summaryColumnOptions}
                  placeholder="Show / Hide Columns     "
                  checkboxes={true}
                  selectedOptions={selectedColumns}
                  onChange={({ detail }) => {
                    setSelectedColumns(detail.selectedOptions);
                    const keys = detail.selectedOptions.map(
                      (option) => option?.value
                    );
                    let notSelected = summaryColumnOptions.filter(
                      (option) => !keys.includes(option.value)
                    );
                    notSelected = notSelected.map((option) => option?.value);
                    gridRef.current.columnApi.setColumnsVisible(keys, true);
                    gridRef.current.columnApi.setColumnsVisible(
                      notSelected,
                      false
                    );
                  }}
                  keepOpen={true}
                  hideTokens={true}
                ></Multiselect>
              </Box>
              <Button
                iconName="download"
                onClick={() => downloadFileClick(`Boffin/compute/summary/summary_${currentComputeId}.csv`)}
              >
                Download
              </Button>
              </SpaceBetween>
              <br />
              <div
                className="ag-theme-alpine"
                style={{ height: "90%", width: "100%" }}
              >
                <AgGridReact
                  ref={gridRef}
                  rowData={summaryInfo}
                  defaultColDef={defaultColDef}
                  columnDefs={columnDefs}
                  rowSelection="single"
                  animateRows={true}
                  rowHeight={30}
                  enableCellTextSelection={true}
                  getRowClass={getRowClass}
                  rowMultiSelectWithClick={true}
                  onSelectionChanged={onSummarySelectionChanged}
                ></AgGridReact>
              </div>
            </>
          );
        } else {
          item.data.content = (<div
            className="ag-theme-alpine"
            style={{ height: "92%", width: "100%" }}
          >
            <AgGridReact
              ref={gridRef2}
              rowData={filteredSummaryBreakdownInfo}
              columnDefs={summaryFilterColumnDefs}
              defaultColDef={defaultColDef}
              animateRows={true}
              rowHeight={30}
              enableCellTextSelection={true}
            ></AgGridReact>
          </div>)
        }
        return item;
      })
      return updated
    })
  },[summaryInfo, filteredSummaryBreakdownInfo,selectedColumns])

  useEffect(() => {
  },[summarySelected.selected])

 
  const loadData = async () => {
    setShowLoadingIcon(true);
    // Get details for this  currentComputeId
    var mQry =
      `query MyQuery {
                getUI4BI_BoffinComputeInfo(id: "` +
      currentComputeId +
      `") {
                  accounts
                  accrualOption
                  createDate
                  datasets
                  filterOption
                  isAccrualSelected
                  isAllJEPosted
                  isReclassSelected
                  jeTypes
                  ledgerTypes
                  onlyAWS
                  periodMonth
                  periodYear
                  postOFA
                  pre_signed_url
                  processRow
                  processStatus
                  reclassOption
                  recompute
                  selectedAWSoption
                  selectedCompanyCodes
                  selectedLedgers
                  showPostingButton
                  teamName
                  userId
                  isApproved
                  postingStatus
                }
              }
    `;
    // console.log(mQry)
    let response = await API.graphql(graphqlOperation(mQry));
    let qResultJSON = response.data.getUI4BI_BoffinComputeInfo;
    setItem(qResultJSON);

    let folder = "Boffin/compute/summary/";
    let fn = folder + "summary_" + currentComputeId + ".csv";
    const fileURL = await Storage.get(fn, { level: "public", expires: 60 });
    // console.log(fileURL)
    let csvdata = await d3.csv(fileURL).then((result) => {
      // setState here..
      setSummaryInfo(result);
    });

    let summaryBreakdownfolder = "Boffin/compute/data/";
    let fn2 = summaryBreakdownfolder + "data_" + currentComputeId + ".csv";
    const fileURL2 = await Storage.get(fn2, { level: "public", expires: 60 });
    // console.log(fileURL)
    let csvdata2 = await d3.csv(fileURL2).then((result) => {
      // setState here..
      setSummaryBreakdownInfo(result);
      setFilteredSummaryBreakdownInfo(result);
    });

    if (qResultJSON["showPostingButton"] === "Yes") {
      setShowPostingButton(true);
    } else {
      setShowPostingButton(false);
    }

    if (qResultJSON["isApproved"] === "Yes") {
      setApprovedStatus("Approved");
    } else {
      setApprovedStatus("Not Approved");
    }
    const status = qResultJSON["postingStatus"]
    setPostingStatusInComputeInfo(status)
    let mQry2 =
      `
        query MyQuery {
            getOneTouchPostingStatus(id: "` +
      currentComputeId +
      `") {
                msg_to_user
                postingStatus
            }
          }
        `;

    const data = await API.graphql(graphqlOperation(mQry2)).then((result) => {
      if (result.data.getOneTouchPostingStatus === null) {
      } else {
        if (result.data.getOneTouchPostingStatus.postingStatus.length > 0) {
          let mJsonData = JSON.parse(
            result.data.getOneTouchPostingStatus.postingStatus
          );
          setPostingStatusData(Object.entries(mJsonData.Data).sort(timeStampCompareFn));
        }

        let mMsg = result.data.getOneTouchPostingStatus.msg_to_user;
        setPostingMsgToUser(mMsg);
      }
    });
  setShowLoadingIcon(false);

  };

  const timeStampCompareFn = (a, b) => {
    const dateA = a[1]["date_created"].split(" ")[0];
    const dateATime = a[1]["date_created"].split(" ")[1];
    const dateB = b[1]["date_created"].split(" ")[0];
    const dateBTime = b[1]["date_created"].split(" ")[1];

    const dateAParts = dateA.split("-");
    const dateATimeParts = dateATime.split(":");
    const yearA = Number(dateAParts[0]);
    const monthA = Number(dateAParts[1]) - 1;
    const dayA = Number(dateAParts[2]);
    const hourA = Number(dateATimeParts[0]);
    const minuteA = Number(dateATimeParts[1]);
    const secondA = Number(dateATimeParts[2]);
    const cellDateA = new Date(yearA, monthA, dayA, hourA, minuteA, secondA);

    const dateBParts = dateB.split("-");
    const dateBTimeParts = dateBTime.split(":");
    const yearB = Number(dateBParts[0]);
    const monthB = Number(dateBParts[1]) - 1;
    const dayB = Number(dateBParts[2]);
    const hourB = Number(dateBTimeParts[0]);
    const minuteB = Number(dateBTimeParts[1]);
    const secondB = Number(dateBTimeParts[2]);
    const cellDateB = new Date(yearB, monthB, dayB, hourB, minuteB, secondB);

    // Now that both parameters are Date objects, we can compare
    if (cellDateA < cellDateB) {
      return 1;
    } else if (cellDateA > cellDateB) {
      return -1;
    }
    return 0;
  };
  const changeHandler = async (event) => {
    setIsFileSelected(true);
    setIsLoading(true);

    const file = event.target.files[0];
    // console.log(file)
    setSelectedFileDetails(file);
    setFileName(file.name);
    const data = await file.arrayBuffer();
    const wb = XLSX.readFile(data, { sheetRows: 1 });
    const workbook = XLSX.read(data);
    const worksheet = workbook.Sheets[workbook.SheetNames[0]];
    var readOptions = {
      type: "binary",
      raw: false,
      defval: null,
      cellNF: false,
      cellDates: true,
      cellText: false,
    };
    const jsonData = XLSX.utils.sheet_to_json(worksheet, readOptions);
    // console.log(jsonData)
    let OverrideRows = jsonData.filter((row) => row.Override === "Yes");
    // console.log(OverrideRows)
    setItems(OverrideRows);
    setIsLoading(false);
  };

  //
  const OverrideSubmitClick = async () => {

    
    let res = await getBoffinComputesWithStartedStatus();

    if(res.length > 0) {
      setFlashBarComments(getFlasbarItems())
      setShowFlashBar(true)
      return;
    }

    if (items) {
      // This code is upload an excel file
      setIsUploading(true);

      let folder = "Boffin/uploads/pending/Override_";
      let fn = folder + currentComputeId + ".csv";

      var ws = XLSX.utils.json_to_sheet(items);
      var csv = XLSX.utils.sheet_to_csv(ws);

      Storage.put(fn, csv, {
        tagging: "userId=" + userId,
        level: "public",
      }).then((result) => {
        // console.log(result)
        console.log("File has been uploaded");
        callContico(fn);
      });
    }
  };
  const callContico = async (fn) => {
    let mTxt =
      `
                {
                  "processName": "boffin",
                  "scriptName": "LoadOverrideFile",
                  "parameters": {"computeId": "` +
      currentComputeId +
      `"}
                }
                `;

    // console.log(mTxt)
    let folder = "contico_lambda_trigger/pending/";
    let fileName = folder + currentComputeId + ".json";
    Storage.put(fileName, mTxt, {
      tagging: "userId=" + userId,
      level: "public",
    }).then((result) => {
      // console.log(result)
      setStatusUpdate("Processing is about to start");
      setIsUploading(false);
      let mQry1 =
        `
                    mutation MyMutation {
                      updateUI4BI_BoffinComputeInfo(
                            input: {id: "` +
        currentComputeId +
        `", 
                            processStatus    :"Processing Recompute"
                            }){
                              id
                            }
                    }
        `;
      // console.log(mQry1)
      const data1 = API.graphql(graphqlOperation(mQry1));

      let dt = moment().format();

      let mQry2 =
        `
        mutation MyMutation {
            createUI4BI_BoffinOverrideFiles(input: 
              {
                computeId: "` +
        currentComputeId +
        `",
                uploadDate: "` +
        dt +
        `", 
                user: "` +
        userId +
        `"
              }) {
                computeId
          }
        }
        
        `;
      // console.log(mQry1)
      const data2 = API.graphql(graphqlOperation(mQry2));
      history.push("/BoffinViewAllItems");
    });
  };

  const downloadFileClick = async (fileLocation) => {
    const fileURL = await Storage.get(fileLocation, {
      level: "public",
      expires: 60,
    });
    window.open(fileURL);
  };


  const defaultColDef = {
    // set filtering on for all columns
    filter: true,
    filterParams: {
      buttons: ["reset"],
      debounceMs: 200,
      maxNumConditions: 10
    },
  };


  const [columnDefs] = useState([
    {
      field: "ledger_name",
      width: "150px",
      sortable: true,
      resizable: true,
      headerName: "Ledger Name"
    },
    { field: "company", sortable: true, resizable: true },
    { field: "cost_account", width: "150px", sortable: true, resizable: true, headerName: "Cost Account"},
    {
      field: "net_cost_je_amount",
      width: "150px",
      sortable: true,
      resizable: true,
      type: "rightAligned",
      headerName: "Cost",
      filterParams: numberFilterParams,
      comparator: (a, b) => customSort(a, b),
    },
    {
      field: "cost_balance",
      width: "150px",
      sortable: true,
      resizable: true,
      type: "rightAligned",
      headerName: "Cost TB Balance",
      filterParams: numberFilterParams,
      comparator: (a, b) => customSort(a, b),
    },
    {
      field: "percent1",
      width: "150px",
      sortable: true,
      resizable: true,
      type: "rightAligned",
      headerName: "Percent",
      filterParams: numberFilterParams,
      comparator: (a, b) => customSort(a, b),
    },

    {
      field: "accum_dep_acct",
      width: "150px",
      sortable: true,
      resizable: true,
      type: "rightAligned",
      headerName: "Accum Depr Acct",
    },

    {
      field: "net_dep_je_amount",
      width: "150px",
      sortable: true,
      resizable: true,
      type: "rightAligned",
      headerName: "Depr Amount",
      filterParams: numberFilterParams,
      comparator: (a, b) => customSort(a, b),
    },

    {
      field: "accum_dep_bal",
      width: "150px",
      sortable: true,
      resizable: true,
      type: "rightAligned",
      headerName: "Accum Depr TB Balance",
      filterParams: numberFilterParams,
      comparator: (a, b) => customSort(a, b),
    },

    {
      field: "net_je_amt_dep_percent",
      width: "150px",
      sortable: true,
      resizable: true,
      type: "rightAligned",
      headerName: "Depr / Accum Depr",
      filterParams: numberFilterParams,
      comparator: (a, b) => customSort(a, b),
    },

    {
      field: "dep_percent",
      width: "150px",
      sortable: true,
      resizable: true,
      type: "rightAligned",
      headerName: "Cost / Depr Amnt",
      filterParams: numberFilterParams,
      comparator: (a, b) => customSort(a, b),
    },

    { field: "" },
  ]);

  const postingColumnDefinitions = [
    {
      id: "Group_Id",
      header: "Group_Id",
      width: 150,
      cell: (item) => <Box variant="small">{item[1].Group_Id}</Box>,
    },
    
    {
      id: "msg_ack_status",
      header: "Initiated",
      width: 50,
      cell: (item) =>
        item[1].msg_ack_status === "VS_ACK" ? (
          <Icon name="status-positive" variant="success"></Icon>
        ) : (
          <Box variant="small">
            <Icon name="status-pending" variant="normal"></Icon>
            {item[1].msg_ack_status}{" "}
          </Box>
        ),
    },
    {
      id: "msg_validation_status",
      header: "Validation",
      width: 50,
      cell: (item) =>
        item[1].msg_validation_status?.toUpperCase() === "PASS" ? (
          <div>
          <Icon name="status-positive" variant="success"></Icon>
          </div>
        ) : item[1].msg_validation_status?.toUpperCase() === "SUCCESS" ? (
          <div>
          <Icon name="status-positive" variant="success"></Icon>
          </div>
        ) : item[1].msg_validation_status?.toUpperCase() === "FAIL" ? (
          <div>
            <Icon name="status-negative" variant="error"></Icon>{" "}
          </div>
        ) : item[1].msg_validation_status?.toUpperCase() === "ERROR" ? (
          <div>
            <Icon name="status-negative" variant="error"></Icon>{" "}
          </div>
        ) : (
          <Box variant="small">
            <Icon name="status-pending" variant="normal"></Icon>
            {/* {item[1].msg_validation_status}  */}
          </Box>
        ),
    },
    {
      id: "msg_je_created_status",
      header: "JE Creation",
      width: 50,
      cell: (item) => {
        switch (item[1].msg_je_created_status?.toUpperCase()) {
          case "PENDING":
            return <Icon name="status-pending" variant="subtle"></Icon>;
          case "JE_CREATED":
            return <Icon name="status-positive" variant="success"></Icon>;
          case "JE_CREATION_ERROR":
            return <Icon name="status-negative" variant="error"></Icon>;
          case "ERROR":
            return <Icon name="status-negative" variant="error"></Icon>;
          case "FAIL":
            return <Icon name="status-negative" variant="error"></Icon>;  
          case "MESSAGE SENT FOR POSTING":
            return (
              <div>
                <Icon name="status-in-progress" variant="subtle"></Icon>{" "}
                <Icon name="envelope" variant="subtle"></Icon>
              </div>
            );
          default:
            return <Box variant="small">{item[1].msg_je_created_status} </Box>;
        }
      },
    },
    {
      id: "msg_je_posted_status",
      header: "JE Posting",
      width: 50,
      cell: (item) => {
        switch (item[1].msg_je_posted_status?.toUpperCase()) {
          case "PENDING":
            return <Icon name="status-pending" variant="subtle"></Icon>;
          case "JE_POSTED":
            return <Icon name="status-positive" variant="success"></Icon>;
          case "ERROR":
            return <Icon name="status-negative" variant="error"></Icon>;
          case "FAIL":
            return <Icon name="status-negative" variant="error"></Icon>;
          case "JE_POST_ERROR":
            return <Icon name="status-negative" variant="error"></Icon>;

          default:
            return <Box variant="small">{item[1].msg_je_posted_status} </Box>;
        }
      },
    },
    {
      id: "date_created",
      header: "Date Time Stamp",
      width: 50,
      cell: (item) => <Box variant="small">{String(item[1].date_created)}</Box>
    }
  ];

  const customSort = (a, b) => {
    const numA = Number(a.replaceAll(",", "").replaceAll("%", ""));
    const numB = Number(b.replaceAll(",", "").replaceAll("%", ""));
    if (numA === numB) return 0;
    return numA > numB ? 1 : -1;
  };

  const getRowClass = (params) => {
    const accountList = ["16960", "16999"];
    let account = params.data.account;
    let percent = params.data.percent1;
    let percent2 = params.data.net_je_amt_dep_percent;
    let percent3 = params.data.dep_percent;

    percent = percent
      ? Number(percent.replaceAll(",", "").replaceAll("%", ""))
      : 0;
    percent2 = percent2
      ? Number(percent2.replaceAll(",", "").replaceAll("%", ""))
      : 0;
    percent3 = percent3
      ? Number(percent3.replaceAll(",", "").replaceAll("%", ""))
      : 0;

    const condition1 = percent > 20 || percent <= -20;
    const condition2 = percent2 > 20 || percent2 <= -20;
    const condition3 = percent3 > 20 || percent3 <= -20;
    const condition4 =
      (percent >= 10 && percent <= 19) || (percent >= -19 && percent <= -10);
    const condition5 =
      (percent2 >= 10 && percent2 <= 19) ||
      (percent2 >= -19 && percent2 <= -10);
    const condition6 =
      (percent3 >= 10 && percent3 <= 19) ||
      (percent3 >= -19 && percent3 <= -10);

    if (
      (condition1 || condition2 || condition3) &&
      accountList.indexOf(account) === -1
    ) {
      return "rag-red";
    } else if (
      (condition4 || condition5 || condition6) &&
      accountList.indexOf(account) === -1
    ) {
      return "rag-yellow";
    } else {
      return "rag-white";
    }
  };

  const approveOrRevoke = () => {
    let mQry1 =
      `
                    mutation MyMutation {
                      updateUI4BI_BoffinComputeInfo(
                            input: {id: "` +
      currentComputeId +
      `", 
                            isApproved    :"Yes"
                            }){
                              id
                            }
                    }
        `;

    let mQry2 =
      `
        mutation MyMutation {
          updateUI4BI_BoffinComputeInfo(
                input: {id: "` +
      currentComputeId +
      `", 
                isApproved    :"No"
                }){
                  id
                }
        }
`;
    if (approvedStatus === "Approved") {
      setApprovedStatus("Not Approved");
      API.graphql(graphqlOperation(mQry2));
    } else {
      setApprovedStatus("Approved");
      API.graphql(graphqlOperation(mQry1));
    }
  };

  const postToOFAClick = () => {
    let mQry =
      `
    mutation MyMutation {
      updateUI4BI_BoffinComputeInfo(
            input: {id: "` +
      currentComputeId +
      `", 
            processRow: "Yes", 
            postOFA: "Yes",
            postingStatus: "Posting Process - Initiated",
            showPostingButton: "No"
            }){
              id
            }
    }
`;

    const data = API.graphql(graphqlOperation(mQry));
    setPostToOFAClicked(true);
  };

  const onSelectionChange = (detail) => {
    setShowGroupIdDetailsModal(true);
    setSelectedItems(detail.selectedItems);
  };


  const onSummarySelectionChanged= () => {
    const selectedRows = gridRef.current.api.getSelectedRows();
    if(selectedRows.length === 0) {
      setFilteredSummaryBreakdownInfo(summaryBreakdownInfo)
    } else {
      const filteredItems = summaryBreakdownInfo.filter((item)=>((item.company.toUpperCase() === selectedRows[0].company.toUpperCase())
      && (item.cost_acct.toUpperCase() === selectedRows[0].cost_account.toUpperCase())))

      setFilteredSummaryBreakdownInfo(filteredItems)
    }

  }

  const content = (
    <div>
      {isAuthorized ? (
        <Box>
          <Container
            header={
              <Header
                variant="awsui-h1-sticky"
                actions={
                  <SpaceBetween direction="horizontal" size="xs">
                    <Button
                      variant="normal"
                      iconName="close"
                      onClick={() => {
                        history.push("/BoffinViewAllItems");
                      }}
                    ></Button>
                  </SpaceBetween>
                }
              >
                Compute Details
              </Header>
            }
          >
            <ColumnLayout columns={4}>
              <Box>
                <Box variant="awsui-key-label">Compute Id</Box>
                <Box>{currentComputeId}</Box>
              </Box>
              <Box>
                <Box variant="awsui-key-label"> Status</Box>

                <Box>
                  {item["processStatus"] === "Processing has completed" ? (
                    <Icon variant="success" name="status-positive"></Icon>
                  ) : (
                    <Icon variant="normal" name="status-pending"></Icon>
                  )}{" "}
                  &nbsp; {item["processStatus"]}
                </Box>
              </Box>
              <Box>
                <Box variant="awsui-key-label">CreateDate</Box>
                <Box>{item["createDate"]}</Box>
              </Box>
              <Box>
                <Box variant="awsui-key-label">User Id</Box>
                <img
                  alt=""
                  className="rounded-circle"
                  src={
                    "https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=" +
                    item["userId"]
                  }
                  width={20}
                  height={20}
                />
                &nbsp;
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={"https://phonetool.amazon.com/users/" + item.userId}
                >
                  {item.userId}
                </a>
              </Box>
            </ColumnLayout>
          </Container>
          <Box>
            <br></br>

            <SpaceBetween direction="horizontal" size="xs">
              <Button
                variant="normal"
                iconName="refresh"
                onClick={() => loadData()}
              >
                Refresh
              </Button>
              {ShowLoadingIcon ? (
                <Modal visible={ShowLoadingIcon}>
                  <Box>
                    <Spinner></Spinner>
                    Loading...
                  </Box>
                </Modal>
              ) : (
                <Box></Box>
              )}
            </SpaceBetween>
            <br />

            <Tabs
              variant="container"
              activeTabId={activeTabId}
              onChange={({ detail }) => setActiveTabId(detail.activeTabId)}
              tabs={[
                {
                  label: "Next Steps",
                  id: "details",
                  content: (
                    <ColumnLayout columns={4} borders="vertical">
                      <Box>
                        <Grid
                          gridDefinition={[{ colspan: 2 }, { colspan: 10 }]}
                        >
                          <Box
                            fontSize="display-l"
                            fontWeight="bold"
                            color="text-body-secondary"
                          >
                            1
                          </Box>
                          <Box>
                            <SpaceBetween direction="vertical" size="l">
                              <Button
                                iconName="download"
                                onClick={() =>
                                  downloadFileClick(item["pre_signed_url"])
                                }
                              >
                                Download
                              </Button>
                              <Box color="text-body-secondary">
                                Click to start download of excel file for
                                review.
                              </Box>
                            </SpaceBetween>

                            <br></br>
                          </Box>
                        </Grid>
                      </Box>
                      <Box>
                        <Grid
                          gridDefinition={[{ colspan: 2 }, { colspan: 10 }]}
                        >
                          <Box
                            fontSize="display-l"
                            fontWeight="bold"
                            color="text-body-secondary"
                          >
                            2
                          </Box>
                          <Box>
                            <SpaceBetween direction="vertical" size="l">
                              <Button
                                iconName="upload"
                                disabled={dissableOverrideButtons}
                                onClick={() => {
                                  // reset file here...
                                  setDt(Math.random().toString(36));
                                  setFileName("");
                                  setIsFileSelected(false);
                                  setItems([]);
                                  setShowOverrideModal(true);
                                }}
                              >
                                Override
                              </Button>

                              <Box color="text-body-secondary">
                                Select your override file and submit to
                                re-compute.
                              </Box>
                              <hr></hr>
                              <Box color="text-body-secondary">
                                Alternatively, you can onverride using the
                                onscreen option.
                              </Box>
                              <Button
                                variant="normal"
                                iconName="file-open"
                                disabled={dissableOverrideButtons}
                                onClick={() => {
                                  history.push("/BoffinNewOverride");
                                }}
                              >
                                OnScreen Override
                              </Button>

                              {dissableOverrideButtons && !showFailureBadge && (
                                <Box>
                                  <Alert type="info">
                                    <Box
                                      color="text-body-secondary"
                                      variant="small"
                                    >
                                      Override Buttons are disabbled as JE is
                                      posted to OFA, refer posting status tab
                                      for more info.
                                    </Box>
                                  </Alert>
                                </Box>
                              )}

                              {showFailureBadge && !dissableOverrideButtons && (
                                <Box>
                                  <Alert type="error">
                                    <Box
                                      color="text-body-secondary"
                                      variant="small"
                                    >
                                      Some error occured while posting, you
                                      might need to override something. Check
                                      posting status tab for more Info.
                                    </Box>
                                  </Alert>
                                </Box>
                              )}
                            </SpaceBetween>
                          </Box>
                        </Grid>
                      </Box>
                      <Box>
                        <Grid
                          gridDefinition={[{ colspan: 2 }, { colspan: 10 }]}
                        >
                          <Box
                            fontSize="display-l"
                            fontWeight="bold"
                            color="text-body-secondary"
                          >
                            3
                          </Box>
                          <Box>
                            <SpaceBetween direction="vertical" size="l">
                              <Box color="text-body-secondary">
                                Go to summary tab to approve JE, before posting
                                to OFA
                              </Box>
                              <Button
                                iconName="folder-open"
                                onClick={() => {
                                  setActiveTabId("summary");
                                }}
                              >
                                View Summary
                              </Button>
                            </SpaceBetween>
                          </Box>
                        </Grid>
                      </Box>
                      <Box>
                        <Grid
                          gridDefinition={[{ colspan: 2 }, { colspan: 10 }]}
                        >
                          <Box
                            fontSize="display-l"
                            fontWeight="bold"
                            color="text-body-secondary"
                          >
                            4
                          </Box>
                          <Box>
                            <SpaceBetween direction="vertical" size="l">
                              <Button
                                iconName="envelope"
                                disabled={
                                  !(
                                    showPostingButton === true &&
                                    approvedStatus === "Approved"
                                  )
                                }
                                onClick={() => {
                                  postToOFAClick();
                                }}
                              >
                                Post to OFA
                              </Button>
                              <Box color="text-body-secondary">
                                Click Post To OFA, to initiate JE Posting
                                process.
                              </Box>
                            </SpaceBetween>
                          </Box>
                        </Grid>
                      </Box>

                      {postToOFAClicked && (
                        <Modal
                          onDismiss={() => {
                            setPostToOFAClicked(false);
                            setDissableOverrideButtons(true);
                            setShowPostingButton(false);
                            loadData();
                          }}
                          visible={postToOFAClicked}
                          closeAriaLabel="Close modal"
                          header="Posting Status"
                          footer={
                            <Box float="right">
                              <SpaceBetween direction="horizontal" size="xs">
                                <Button
                                  variant="primary"
                                  onClick={() => {
                                    history.push("/BoffinViewAllItems");
                                  }}
                                >
                                  Go to Review Page
                                </Button>
                              </SpaceBetween>
                            </Box>
                          }
                        >
                          Posting Process - Initiated
                        </Modal>
                      )}
                    </ColumnLayout>
                  ),
                },
                {
                  label: "Summary",
                  id: "summary",
                  content: (
                    <div>
                      <Box>
                        {/* <Box>The following table shows the summary of JE / GL Balance and Variance</Box>   */}
                        <Header
                          variant="h3"
                          actions={
                            <SpaceBetween direction="horizontal" size="xs">
                              <Box>
                                <strong>Current Status: </strong>
                                <Badge
                                  color={
                                    approvedStatus === "Approved"
                                      ? "green"
                                      : "red"
                                  }
                                >
                                  {approvedStatus}
                                </Badge>
                              </Box>
                            </SpaceBetween>
                          }
                        >
                          
                        </Header>
                        <br />
                        <Board
                          renderItem={(item) => (
                            <BoardItem 
                            header={<Header>{item.header}</Header>}
                            i18nStrings={boardItemI18nStrings}>
                              {item.data.content}
                              {/* <div
                                className="ag-theme-alpine"
                                style={{ height: "92%", width: "100%" }}
                              >
                                <AgGridReact
                                  ref={gridRef}
                                  rowData={summaryInfo}
                                  defaultColDef={defaultColDef}
                                  columnDefs={columnDefs}
                                  rowSelection="multiple"
                                  animateRows={true}
                                  rowHeight={30}
                                  enableCellTextSelection={true}
                                  getRowClass={getRowClass}
                                  rowMultiSelectWithClick={true}
                                  onSelectionChanged={onSummarySelectionChanged}
                                ></AgGridReact>
                              </div> */}
                            </BoardItem>
                            
                          )}
                          onItemsChange={(event) =>
                            setBoardItems(event.detail.items)
                          }
                          items={boardItems}
                          i18nStrings={boardI18nStrings}
                        />
                      </Box>
                      <br></br>
                      <Container>
                        <SpaceBetween direction="horizontal" size="m">
                          <Button
                            variant="primary"
                            iconAlign="right"
                            iconName="status-positive"
                            disabled={
                              (approvedStatus === "Approved" ||
                              (showPostingButton === false
                                ? true
                                : false) 
                                || (summaryInfo.length === 0)
                              )
                            }
                            onClick={() => {
                              approveOrRevoke();
                            }}
                          >
                            Approve JE
                          </Button>

                          <Button
                            variant="primary"
                            iconAlign="right"
                            iconName="status-negative"
                            disabled={
                              approvedStatus === "Not Approved" ? true : false
                            }
                            onClick={() => {
                              approveOrRevoke();
                            }}
                          >
                            Revoke Approval
                          </Button>
                        </SpaceBetween>
                        <br />
                        {(approvedStatus === "Approved" ||
                          showPostingButton === false) &&
                        approvedStatus === "Not Approved" ? (
                          <Alert type="error">
                            <span>
                              You may not Approve until Logical Errors are
                              Cleared!
                            </span>
                          </Alert>
                        ) : (
                          <div></div>
                        )}
                      </Container>
                    </div>
                  ),
                },
                {
                  label: "Posting Status",
                  id: "second",
                  content: (
                    <Container header={<Header>Posting status</Header>}>
                      {postingMsgToUser === "Not Initiated" ? (
                        <Box>Posting is Not Initiated.</Box>
                      ) : (
                        <Box>
                          {postingStatusData && postingStatusData.length > 0 ? (
                            <Box>
                              <Box variant="p">{postingMsgToUser}</Box> <br />
                              <Alert header="Note">
                                If the validation fails, please go to OnScreen
                                Override and click Submit. Once the recompute
                                has finished, you’ll see the validation error
                                message in the Validation_err_msg field.
                              </Alert>
                              <br />
                              <ColumnLayout columns={1}>
                                <Box>
                                  {/* Select group id below to view details */}
                                  <Table
                                    columnDefinitions={postingColumnDefinitions}
                                    items={postingStatusData}
                                    selectionType="single"
                                    onSelectionChange={({ detail }) =>
                                      onSelectionChange(detail)
                                    }
                                    selectedItems={selectedItems}
                                    preferences={
                                      <CollectionPreferences />
                                    }
                                  ></Table>
                                  <br></br>
                                  <Box>
                                    <ExpandableSection
                                      headerText="What the icons indicate?"
                                      expanded={true}
                                    >
                                      <ColumnLayout columns={6} borders="none">
                                        <Box>
                                          <Icon
                                            name="status-pending"
                                            variant="subtle"
                                          ></Icon>{" "}
                                          &nbsp; Pending
                                        </Box>
                                        <Box>
                                          <Icon
                                            name="status-in-progress"
                                            variant="subtle"
                                          ></Icon>{" "}
                                          &nbsp; In Progress
                                        </Box>
                                        <Box>
                                          <Icon
                                            name="envelope"
                                            variant="subtle"
                                          ></Icon>{" "}
                                          &nbsp; Message sent for posting
                                        </Box>
                                        <Box>
                                          <Icon
                                            name="status-negative"
                                            variant="error"
                                          ></Icon>{" "}
                                          &nbsp; Error
                                        </Box>
                                        <Box>
                                          <Icon
                                            name="status-positive"
                                            variant="success"
                                          ></Icon>{" "}
                                          &nbsp; Success
                                        </Box>
                                      </ColumnLayout>
                                    </ExpandableSection>
                                  </Box>
                                </Box>
                              </ColumnLayout>
                            </Box>
                          ) : (
                            <div>
                              {postingMsgToUser} <br />
                              <Alert header="Note">
                                If the validation fails, please go to OnScreen
                                Override and click Submit. Once the recompute
                                has finished, you’ll see the validation error
                                message in the Validation_err_msg field.
                              </Alert>
                            </div>
                          )}
                          <Modal
                            size="large"
                            visible={showGroupIdDetailsModal}
                            onDismiss={() => {
                              setShowGroupIdDetailsModal(false);
                              setSelectedItems([]);
                            }}
                            header={
                              <Box variant="h3">
                                Posting Status
                                <br></br>
                                <small>id: {currentComputeId}</small>
                              </Box>
                            }
                          >
                            <Box>
                              <Tabs
                                tabs={[
                                  {
                                    label: "Details",
                                    id: "First",
                                    disabled: false,
                                    content:
                                      selectedItems.length > 0 ? (
                                        <div>
                                          <Box>
                                            <Box color="text-label">
                                              {" "}
                                              Group Id
                                            </Box>
                                            <Box>
                                              {selectedItems[0][1].Group_Id}
                                            </Box>
                                          </Box>

                                          <br></br>
                                          <Box>
                                            <Box color="text-label">
                                              {" "}
                                              Date & Time (start)
                                            </Box>
                                            <Box>
                                              {selectedItems[0][1].date_created}
                                            </Box>
                                          </Box>
                                          <br></br>

                                          <Box>
                                            <Box color="text-label">
                                              {" "}
                                              fileName
                                            </Box>
                                            <Box color="text-status-info">
                                              {selectedItems[0][1].fileName}
                                            </Box>
                                          </Box>
                                          <br></br>

                                          <ExpandableSection headerText="Acknowledgement Message">
                                            <Box variant="code">
                                              {selectedItems[0][1].msg_ack_body}
                                            </Box>
                                          </ExpandableSection>
                                        </div>
                                      ) : (
                                        <div>
                                          select group id to view details
                                        </div>
                                      ),
                                  },

                                  {
                                    label: "Validation",
                                    id: "second",
                                    content:
                                      selectedItems.length > 0 ? (
                                        <div>
                                          <ExpandableSection
                                            headerText="Validation Message"
                                            expanded={true}
                                          >
                                            {/* {
                                                                    this.state.selectedItems[0][1].vs_err_file!="" ?
                                                                    <Link 
                                                                    external 
                                                                    href={this.state.selectedItems[0][1].vs_err_file}>
                                                                    Link to Error file
                                                                    </Link> : ''
                                                                } */}

                                            <br></br>

                                            <Box variant="code">
                                              {
                                                selectedItems[0][1]
                                                  .msg_validation_body
                                              }
                                            </Box>
                                          </ExpandableSection>
                                        </div>
                                      ) : (
                                        <div>
                                          select group id to view details
                                        </div>
                                      ),
                                  },
                                  {
                                    label: "JE Creation",
                                    id: "third",
                                    content:
                                      selectedItems.length > 0 ? (
                                        <div>
                                          <Box>
                                            <Box color="text-label">
                                              {" "}
                                              Status
                                            </Box>
                                            <Box color="text-status-info">
                                              {
                                                selectedItems[0][1]
                                                  .msg_je_created_status
                                              }
                                            </Box>
                                          </Box>
                                          <br></br>
                                          <ExpandableSection headerText="JE Created Message">
                                            <Box variant="code">
                                              {
                                                selectedItems[0][1]
                                                  .msg_je_created_body
                                              }
                                            </Box>
                                          </ExpandableSection>
                                        </div>
                                      ) : (
                                        <div>Select Group Id</div>
                                      ),
                                  },
                                  {
                                    label: "JE Posted",
                                    id: "fourth",
                                    content:
                                      selectedItems.length > 0 ? (
                                        <div>
                                          <Box>
                                            <Box color="text-label">Status</Box>
                                            <Box color="text-status-info">
                                              {
                                                selectedItems[0][1]
                                                  .msg_je_posted_status
                                              }
                                            </Box>
                                          </Box>
                                          <br></br>
                                          <ExpandableSection headerText="JE Posted Message">
                                            <Box variant="code">
                                              {
                                                selectedItems[0][1]
                                                  .msg_je_posted_body
                                              }
                                            </Box>
                                          </ExpandableSection>
                                        </div>
                                      ) : (
                                        <div>Select Group Id</div>
                                      ),
                                    disabled: false,
                                  },
                                ]}
                              />
                            </Box>
                          </Modal>
                        </Box>
                      )}
                    </Container>
                  ),
                },
                {
                  label: "Lynx Download",
                  id: "lynx_download",
                  content: (
                    <Box>
                      <div className="ag-theme-alpine">
                        <SpaceBetween direction="vertical" size="l">
                          <Button
                            iconName="download"
                            onClick={() =>
                              downloadFileClick(
                                `Boffin/compute/lynx/lynx_${currentComputeId}.csv`
                              )
                            }
                          >
                            Download Lynx
                          </Button>
                          <Box color="text-body-secondary">
                            Click to start download of lynx excel file for
                            review.
                          </Box>
                        </SpaceBetween>
                      </div>
                    </Box>
                  ),
                },
              ]}
            />
            <br></br>

            <br></br>
          </Box>

          <Modal
            header="Submit your override file"
            visible={showOverrideModal}
            onDismiss={() => {
              setShowOverrideModal(!showOverrideModal);
            }}
            footer={
              <Box float="right">
                <SpaceBetween direction="horizontal" size="xs">
                  {items.length ? (
                    <Box>
                      Number of Rows to Override : {items.length}
                      &nbsp;
                      <Button
                        loading={isUploading}
                        variant="primary"
                        onClick={OverrideSubmitClick}
                      >
                        Submit
                      </Button>
                      {showFlashBar && (
                        <div style={{ paddingTop: "1rem" }}>
                          <Flashbar items={flashBarComments} />
                        </div>
                      )}
                    </Box>
                  ) : (
                    <Box>Number of Rows to Override : {items.length}</Box>
                  )}
                </SpaceBetween>
              </Box>
            }
          >
            <Box>
              <input
                type="file"
                accept=".xlsx"
                key={dt}
                style={{ padding: "0px" }}
                onChange={changeHandler}
              />
            </Box>
            {isLoading ? <Spinner></Spinner> : <Box></Box>}
          </Modal>
        </Box>
      ) : isCheckingAccess ? (
        <div>
          <WaitForPageAccess></WaitForPageAccess>
        </div>
      ) : (
        <div>
          <AccessDenied appName={appSettings.appName}></AccessDenied>
        </div>
      )}
    </div>
  );
  return (
    <AppLayout
      breadcrumbs={
        <BreadcrumbGroup
          items={breadItems}
          onFollow={(event) => onFollowHandler(event)}
        ></BreadcrumbGroup>
      }
      content={content}
      navigation={<SideNav activeHref="/BoffinViewAllItems" />}
      headerSelector="#TopBar"
      navigationHide={false}
      tools={helpContent}
      maxContentWidth = {Number.MAX_VALUE}
      headerVariant="high-contrast"
    ></AppLayout>
  );
};

export default EditItem;
