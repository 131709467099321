import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'

import checkPageAccess from '../../checkPageAccess';
import AccessDenied from '../../AccessDenied'
import WaitForPageAccess from '../../WaitForPageAccess'

import './newupload.scss';

import Wizard from "@amzn/awsui-components-react/polaris/wizard";
import {
    AppLayout, HelpPanel, Icon, BreadcrumbGroup, Box, Alert, ExpandableSection,
    Container, Header, ColumnLayout, Spinner, Link, Tabs, Button, Table, SpaceBetween, Modal
} from '@amzn/awsui-components-react';
import SideNav from './SideNav';
import appSettings from './app_settings';
import { setCurrentAppId } from '../../store/userAuthSlice';
import { useAuth } from "../../context/AuthContextProvider";

import MJEmonitoringSampleFile from '../../images/MJEMonitoringSampleFile.png'
import { PORTAL } from "src/constants/AppConstants";

import { useHistory } from "react-router-dom";

import * as XLSX from 'xlsx';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

import { API, graphqlOperation } from 'aws-amplify';
import Amplify, { Storage } from 'aws-amplify';
import {configureGenericS3} from 'src/context/AuthContextUtility'
import {configureMJES3} from 'src/context/AuthContextUtility'
import {configureGenericAppSync, configureMJEAppSync} from 'src/context/AuthContextUtility'

export default () => {
    const userId = useSelector((globalState) => globalState.auth.userId)
    const history = useHistory();
    const userAuthenticationDetails = useAuth();
    const userLDAPS = userAuthenticationDetails.USER_LDAP_GROUPS;

    const [isCheckingAccess, setIsCheckingAccess] = useState(true);
    const [isAuthorized, setIsAuthorized] = useState();

    const dispatch = useDispatch()



    const [activeTabId, setActiveTabId] = useState("first");
    const [isFileSelected, setIsFileSelected] = useState(false);
    const [showFileNotSeleted, setShowFileNotSeleted] = useState(false);

    const currentAppId = useSelector((globalState) => globalState.auth.currentAppId)
    const isCurrentAppAuthorised = useSelector((globalState) => globalState.auth.isCurrentAppAuthorized)

    const [showLoadingIcon, setShowLoadingIcon] = useState(false);
    const [columnsList, setColumnsList] = useState({})
    const [items, setItems] = useState([])
    const [fileName, setFileName] = useState('');
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [mainErrorMessage, setMainErrorMessage] = useState('');
    const [missingFields, setMissingFields] = useState(new Set());


    useEffect(() => {
        // async function checkUser() {
        //     const auth = await checkPageAccess(appSettings.appId, userLDAPS);
        //     setIsAuthorized(auth);
        //     setIsCheckingAccess(false);
        //   }
        // checkUser();
        setup()
        return () => {
            // this now gets called when the component unmounts
            unset()
        };
    }

        , []);


    useEffect(() => {

        if (currentAppId !== appSettings.appId) {
            dispatch(setCurrentAppId(appSettings.appId))
        }
        setIsAuthorized(isCurrentAppAuthorised)     
        return () => {
            // this now gets called when the component unmounts
        };
    }, [isCurrentAppAuthorised]);

    useEffect(() => {
        if(isAuthorized === true || isAuthorized === false){
          setIsCheckingAccess(!isCheckingAccess);
        } 
      }, [isAuthorized]);

    const setup = async() => {
        setShowLoadingIcon(true)
        await configureMJES3()
        await configureMJEAppSync()
        setShowLoadingIcon(false)
    }

    const unset = async() => {
        configureGenericS3()
        configureGenericAppSync()
    }

    const helpContent = (
        <HelpPanel
            footer={
                <div>
                    <h3>
                        Learn more <Icon name="external" />
                    </h3>

                </div>
            }
            header={<div>
                <div>

                </div>
                <h2>Additional Info </h2>
            </div>}
        >
            <div>
            </div>
            <div>

            </div>
        </HelpPanel>
    )

    const getAllowedFileFormats = () => {
        return ["text/csv", "text/plain"]
    }
    const getMandatoryColumnNames = () => {
        let mandatoryColumns = new Set()
        mandatoryColumns.add("group_id")
        mandatoryColumns.add("request_id")
        mandatoryColumns.add("period_name")
        mandatoryColumns.add("user_je_source_name")
        mandatoryColumns.add("user_je_category_name")
        mandatoryColumns.add("je_name")
        mandatoryColumns.add("user_name")
        mandatoryColumns.add("status")
        mandatoryColumns.add("creation_date")
        mandatoryColumns.add("count")
        return mandatoryColumns
    }

    const isAllowedFileFormat = (format, allowedFileFormats) => {
        let isAllowed = false
        if (!format) {
            isAllowed = false
        }
        isAllowed = allowedFileFormats.some(item => format === item);
        return isAllowed
    }
    const validateInputFileFormat = (file) => {
        let allowedFileFormats = getAllowedFileFormats()
        let isAllowed = isAllowedFileFormat(file?.type, allowedFileFormats)
        if (!isAllowed) {
            throw new Error("File format is not allowed : ", file?.type);
        }
        return isAllowed
    }

    const validateFileInputColumnNames = (inputColumnNames) => {
        missingFields.clear()
        let mandatoryColumns = getMandatoryColumnNames()
        let inputColumnNamesSet = new Set(inputColumnNames);
        mandatoryColumns.forEach((column, index) => {
            if (!inputColumnNamesSet.has(column)) {
                missingFields.add(column)
            }
        });
        setMissingFields(missingFields)
        if (missingFields.size > 1) {
            throw new Error("Mandatory fields missing.")
        }
    }

    const validateEmptyContents = (rowData) => {
        if (!rowData || rowData.length == 0) {
            throw new Error("The inputs rows are empty")
        }
    }

    const changeHandler = async (event) => {
        try {
            setIsFileSelected(true)
            setShowFileNotSeleted(false)

            const file = event.target.files[0]
            validateInputFileFormat(file)

            setFileName(file.name)
            const data = await file.arrayBuffer();
            const wb = XLSX.readFile(data, { sheetRows: 1 })
            const workbook = XLSX.read(data)
            const worksheet = workbook.Sheets[workbook.SheetNames[0]]
            var readOptions = { type: 'binary', raw: false, defval: null, cellNF: false, cellDates: true, cellText: false }
            const jsonData = XLSX.utils.sheet_to_json(worksheet, readOptions)
            const headerInfo = XLSX.utils.sheet_to_json(worksheet, { header: 1, defval: "" })

            const colNames = headerInfo[0]

            validateFileInputColumnNames(colNames)
            const definitions = colNames.map(colName => ({
                id: colName,
                header: colName,
                cell: item => item[colName] || '-'
            }))

            validateEmptyContents(jsonData)

            setColumnsList(definitions)

            setItems(jsonData)
            setShowLoadingIcon(false)
            setActiveTabId('second')
        } catch (error) {
            setErrorMessage(error?.message)
        }

    }

    const onSubmitClick = async () => {

        if (isFileSelected) {
            // This code is upload an excel file


            let newId = uuidv4()

            let folder = 'mje_monitoring/pending/'
            let fileName = folder + newId + '.csv'

            var ws = XLSX.utils.json_to_sheet(items);
            var csv = XLSX.utils.sheet_to_csv(ws);

            let dt = moment().format();

            setShowLoadingIcon(true)
            Storage.vault.put(fileName, csv, {
                contentType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                tagging: "userId=" + userId,
                level: 'public',
                acl: 'bucket-owner-full-control'
            }).then(result => {
                setMainErrorMessage('')
                let mQry = `
                    mutation MyMutation {
                        createUI4BI_MJE_Monitiring_File_Upload(
                            input: {fileId: "` + newId + `", 
                            createDate: "` + dt + `", 
                            processingStatus: "Processing",
                            processingDetails: "The file has been uploaded and is being processed.", 
                            userId: "` + userId + `"}) 
                            {
                                fileId
                            }
                    }
        `
            // console.log(mQry)
            let response =  API.graphql(graphqlOperation(mQry)).then(result => {
                // console.log(response)
                // console.log('File has been uploaded')
                setShowSuccessModal(true)
                setMainErrorMessage('')
              }, function (error) {
                setMainErrorMessage("File was uploaded successfully. Error in adding entry to records")
                setShowLoadingIcon(false)
              });
            
            }, function (error) {
                setMainErrorMessage("File was not uploaded successfully")
                setShowLoadingIcon(false)
              })

        }
    }

    const cancelButtonClick = () => {
        setActiveTabId('first')
        setShowSuccessModal(false)


    }
    const next1Click = () => {

        setActiveTabId('third')

    }

    const ModalOkClick = () => {
        history.push('/MJEMonitoringHistory')
    }
    const breadItems = [
        { text: 'Home', href: '/Home' },
        { text: 'ATeam BI', href: '/ATeamBIHome' },
        { text: 'MJE Monitoring', href: '/MJEMonitoringHome' },
        { text: 'New Upload', href: '' }

    ];

    const onFollowHandler = (ev) => {

        ev.preventDefault();
        if (ev.detail.href) {
            history.push(ev.detail.href.substring(1));
        }
    }
    const content = (
        <div>
            {isAuthorized ?
                <div>
                    <p></p>
                    <Container
                        header={
                            <Header
                                variant='h2'
                                actions= {
                                    <SpaceBetween direction="horizontal" size="xs">
                                        {showLoadingIcon ? (
                                            <Box>
                                                <Spinner size="normal"></Spinner>
                                            </Box>
                                        ) : (
                                            <Box></Box>
                                        )}
                                        <Button variant='normal' onClick={() => { history.push('/MJEMonitoringHistory') }}>
                                            View Upload History
                                        </Button>
                                    </SpaceBetween>
                                }
                                description="The following steps shall enable you to upload the updated journal entries."
                            >Upload file to Override journal entries


                            </Header>
                        }
                    >

                    </Container>

                    <p></p>
                    <Tabs
                        activeTabId={activeTabId}
                        variant='container'
                        tabs={[
                            {
                                activeTabId: { activeTabId },
                                label: "Step 1 : Upload File",
                                id: "first",
                                content: (
                                    <div>
                                        <br></br>
                                        <ColumnLayout borders="vertical" columns={2} >
                                            <Box>
                                                {showFileNotSeleted ? <Alert type='error'>File Not Selected</Alert> : ''}
                                                <input
                                                    type="file"
                                                    accept=".txt"
                                                    style={{ padding: '0px' }}
                                                    onChange={changeHandler}
                                                />
                                                {errorMessage && (
                                                    <div className="custom-new-upload__error-message"> {errorMessage} </div>
                                                )}
                                            </Box>


                                            <Box>
                                                <ExpandableSection header="Sample file image." >
                                                    <img src={MJEmonitoringSampleFile} ></img>
                                                </ExpandableSection>

                                            </Box>
                                            <Box></Box>
                                            <Box></Box>
                                            <Box></Box>


                                            <Box>
                                                <ExpandableSection header="The file should contain the following columns." >
                                                    <ol>
                                                        {
                                                            Array.from(getMandatoryColumnNames()).map(
                                                                column => <li
                                                                    value={column}
                                                                    key={column}>
                                                                    <span>{column}</span>
                                                                    <span className='missing'> {(missingFields && missingFields.size > 1 && missingFields.has(column)) ? ' - required' : ''} </span>
                                                                </li>
                                                            )
                                                        }
                                                    </ol>
                                                </ExpandableSection>

                                            </Box>


                                        </ColumnLayout>

                                    </div>

                                )
                            },
                            {
                                label: "Step 2 : Review File",
                                id: "second",
                                content: (
                                    <Box>
                                        <Header
                                            variant="h2"
                                            description="File preview - Read Only"
                                            actions={
                                                <SpaceBetween
                                                    direction="horizontal"
                                                    size="xs"
                                                >
                                                    <Button variant="primary" onClick={next1Click}>Next</Button>

                                                    <Button variant="normal" onClick={cancelButtonClick}>Previous</Button>


                                                </SpaceBetween>
                                            }
                                        />
                                        <Table
                                            columnDefinitions={columnsList}
                                            items={items}
                                            stickyHeader={true}
                                            resizableColumns={true}
                                        >

                                        </Table>
                                    </Box>
                                )
                            },
                            {
                                label: "Step 3 : Submit",
                                id: "third",
                                content: (<div>
                                    <Header
                                        variant="h2"
                                        description="File Details"
                                        actions={
                                            <SpaceBetween
                                                direction="horizontal"
                                                size="xs"
                                            >
                                                <Button variant="normal" onClick={cancelButtonClick}>Cancel</Button>
                                                <Button variant="primary" onClick={onSubmitClick}>Submit</Button>
                                            </SpaceBetween>
                                        }
                                    />


                                    <ColumnLayout columns={3}>
                                        < Box>
                                            Filename: {fileName}
                                            {mainErrorMessage && (
                                                    <div className="custom-new-upload__error-message"> {mainErrorMessage} </div>
                                                )}
                                        </Box>
                                    </ColumnLayout>
                                </div>),
                            }
                        ]}
                    />
                    <Modal
                        visible={showSuccessModal}
                        footer={
                            <Box float="right">
                                <SpaceBetween direction="horizontal" size="xs">
                                    <Button variant="primary" onClick={ModalOkClick}>Ok</Button>
                                </SpaceBetween>
                            </Box>
                        }
                        header="File Upload Status"
                    >
                        <Box>
                            <SpaceBetween size='m' direction='vertical'>
                                <p>
                                    <Icon variant="success" size='medium' name='status-positive'></Icon>
                                    &nbsp; &nbsp;
                                    Your file has been uploaded
                                </p>
                                <p>
                                    <Icon variant="normal" size='medium' name='status-info'></Icon>
                                    &nbsp; &nbsp;
                                    It will take aproximately 10 minutes for the database to be updated.
                                </p>

                            </SpaceBetween>

                        </Box>

                        <br></br>

                    </Modal>
                </div>
                :
                isCheckingAccess ?
                    <div>
                        <WaitForPageAccess></WaitForPageAccess>
                    </div> :
                    <div>
                        <AccessDenied appName={appSettings.appName}></AccessDenied>
                    </div>
            }
        </div>
    )
    return (





        <AppLayout
            breadcrumbs={<BreadcrumbGroup items={breadItems} onFollow={(event) => onFollowHandler(event)}></BreadcrumbGroup>}
            content={content}
            navigation={<SideNav activeHref="#/MJEMonitoringNewUpload" />}
            headerSelector="#TopBar"
            navigationHide={false}
            disableBodyScroll={true}
            tools={helpContent}
            headerVariant="high-contrast"

        ></AppLayout>

    );
}