import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { Amplify, Auth, Hub,  } from 'aws-amplify';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { LoadingSpinner } from '../generic-components/LoadingSpinner';
import { eLDAPGroup } from '../constants/AppConstants';
import { UserAuthContext, LoadingStatus } from './AppContextModels';
import { getAppSyncConfig, getS3Config, getAPIGateWayConfig } from 'src/utilities/AWSServices';
// import { configureTheLogger } from 'src/logger';
import {configureGenericS3} from './AuthContextUtility'
import { setCurrentUserId, setLoginRedirectPath} from '../store/userAuthSlice'

import {useHistory, useLocation} from 'react-router-dom'
import { useDispatch} from 'react-redux'

const initialData: UserAuthContext = {
  userAuthDataLoadingStatus: LoadingStatus.Loading
} as UserAuthContext;
const AuthContextDetails = createContext(initialData);
export const useAuth = () => useContext(AuthContextDetails);

// Provider component that wraps your app and makes auth object
export const AuthContextProvider = ({ children }: any) => {
  const userAuthData: UserAuthContext = useAuthProvider();
  if (userAuthData?.userAuthDataLoadingStatus !== LoadingStatus.Completed) {
    return <LoadingSpinner />;
  } else {
    return <AuthContextDetails.Provider value={userAuthData}>{children}</AuthContextDetails.Provider>;
  }
};

const useAuthProvider = () => {
  const [userSession, setUserSession] = useState<any>();
  const history = useHistory()
  let location = useLocation()
  const dispatch = useDispatch()

  const [userCognitoAuthData, setUserCognitoAuthData] = useState<UserAuthContext>({
    ...initialData,
    error: '',
    userAuthDataLoadingStatus: LoadingStatus.Loading
  });

  useEffect(() => {

    Auth.configure(getAuthConfig());

    const configureAppSync = async () => {
      try {
        const awsAppSync = await getAppSyncConfig();
        Amplify.configure(awsAppSync.Appsync);
      } catch (error: any) {
        console.error('Unable to fetch AppSync Config', error);
      }
    };

    const comfigureAPIGateWay = () => {
      try {
        const awsAPIGateWay =  getAPIGateWayConfig();
        Amplify.configure(awsAPIGateWay.APIGateWay);
      } catch (error: any) {
        console.error('Unable to configure APIGateway', error);
      }

    }



    const signInWithAmazonFederate = async () => {
      try {
        const userSessionDetails = await Auth.federatedSignIn({ customProvider: 'AmazonFederate', customState: `${location.pathname}${location.search}` });
        setUserSession(userSessionDetails);
        const session = await Auth.currentSession();
        dispatch(setCurrentUserId(session.getIdToken().payload['identities'][0].userId))
        setUserCognitoAuthData(getSessionDetails(session));
      } catch (error: any) {
        console.error('Unable to sign in with AmazonFederate', error);
        setUserCognitoAuthData({
          ...userCognitoAuthData,
          error: 'Unable to sign in with AmazonFederate',
          userAuthDataLoadingStatus: LoadingStatus.Failed
        });
      }
    };

    Auth.currentAuthenticatedUser()
      .then(async (userSessionDetails) => {
        if(location.pathname !== '/') {
          dispatch(setLoginRedirectPath(`${location.pathname}${location.search}`))
        }
        await configureAppSync();
        configureGenericS3();
        comfigureAPIGateWay();
        setUserSession(userSessionDetails);
        const session = await Auth.currentSession();
        dispatch(setCurrentUserId(session.getIdToken().payload['identities'][0].userId))        
        setUserCognitoAuthData(getSessionDetails(session));
      })
      .catch(() => {
        signInWithAmazonFederate();
      });

      Hub.listen('auth', ({payload: {event, data}}) => {

        if(event === 'customOAuthState') {
          if(data !== '/') {
            dispatch(setLoginRedirectPath(data))
          }

        }else if(event === 'tokenRefresh_failure' || event === 'oAuthSignOut'){
          // save to localstorage
          dispatch(setCurrentUserId(null))
          dispatch(setLoginRedirectPath(`${location.pathname}${location.search}`))
        }
    
    })
      
  }, []);


  const getSessionDetails = (credentials: CognitoUserSession) => {
    return {
      Alias: credentials.getIdToken().payload['identities'][0].userId,
      DisplayName: credentials.getIdToken().payload['custom:DISPLAY_NAME'],
      GivenName: credentials.getIdToken().payload['custom:GIVEN_NAME'],
      Email: credentials.getIdToken().payload['custom:EMAIL'],
      JobCode: credentials.getIdToken().payload['custom:AMZN_JOB_CODE'],
      Description: credentials.getIdToken().payload['custom:DESCRIPTION'],
      AmznDeptName: credentials.getIdToken().payload['custom:AMZN_DEPT_NAME'],
      EmployeeId: credentials.getIdToken().payload['custom:EMPLOYEE_ID'],
      USER_LDAP_GROUPS: credentials.getIdToken().payload['custom:LDAP_GROUPS'],
      error: '',
      userAuthDataLoadingStatus: LoadingStatus.Completed
    };
  };

  return userCognitoAuthData;
};

const getAuthConfig = () => {
  return {
    Auth: {
      region: 'us-west-2',
      userPoolId: 'us-west-2_Bk4TcNAaa',
      userPoolWebClientId: '14j4pl1ic0rlhto79qh5rrurgq',
      identityPoolId: 'us-west-2:eecb2ea9-4b9d-4b78-9388-8e284943dc7a',
      awsAccountId: '359182851103',
      mandatorySignIn: true,
      authenticationFlowType: 'USER_SRP_AUTH',
      oauth: {
        domain: 'accounting-bi-central-prod.auth.us-west-2.amazoncognito.com',
        scope: ['openid'],
        redirectSignIn: 'https://bi-central.accounting.amazon.dev',
        redirectSignOut: 'https://bi-central.accounting.amazon.dev',
        responseType: 'code'
      }
    }
  };
};