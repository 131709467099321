import React, { useState, useEffect } from 'react';
import {
  Checkbox
} from "@amzn/awsui-components-react";


const CustomCheckbox = (props) => {
  return (
    <Checkbox
    onChange={({ detail }) =>
        props.updateValue(props.field, detail.checked, props.subKey)
      }
      checked={props.getValue(props.field, props.subKey)? props.getValue(props.field, props.subKey): false}
      disabled={props.hasOwnProperty("disabled")?props.disabled:false}
    >
      {props.text}
    </Checkbox>
  )
}

export default CustomCheckbox;