export const filterOptions = [
    { label: "Team Name", value: "filterOption1" ,  description:  "select this option and specify your team name" , disabled: false},
    { label: "Ledger Name", value: "filterOption2" , description: "select this option and specify your ledger names", disabled: false},
    { label: "Company Code", value: "filterOption3" , description: "select this option and specify your Company Codes", disabled: false}
]


export const reclassOptions = [
        { label: "Primary Ledger Posting", value: "ReclassOption1" ,  description:  "Dr Natural Account 16XXX  Cr Queue/Accrual  16999/16960" , disabled: false},
        { label: "Secondary Ledger Posting", value: "ReclassOption2" , description: "Dr Natural Account 16XXX  Cr Queue/Accrual  16999/16960", disabled: false}
]

export const accrualsOptions = [
    { label: "Primary ledger entry to 17099 (Secondary automated copy)",  value: "AccrualsOption1" , description: "Dr Expense Cr 17099" , disabled: false},
    { label: "Primary ledger entry to 17XXX (Secondary automated copy)", value: "AccrualsOption2" , description: "Dr Expense Cr 17XXX" , disabled: false},
    // { label: "Primary ledger entry to 17099 & Secondary ledger Reclass to 17XXX", value: "AccrualsOption3" , description: "Dr Expense Cr 17099 [Primary], Dr 17099 Cr 17XXX [Secondary]" , disabled: true},
    { label: "Secondary ledger entry only 17099", value: "AccrualsOption4" , description: "Dr Expense Cr 17099" , disabled: false},
    { label: "Secondary ledger entry only 17XXX", value: "AccrualsOption5" , description: "Dr Expense Cr 17XXX" , disabled: false}

]
export const accounts = [
    { label: "16999", value: "16999", descriiption: "Account desc..", disabled: false },
    { label: "16960", value: "16960", descriiption: "Account desc..", disabled: false },
    { label: "16460", value: "16460", descriiption: "Account desc..", disabled: true },
]

export const defaultAccounts =[
    { label: "16960",value: "16960",descriiption: "Account desc..", disabled: false},
    { label: "16999",value: "16999",descriiption: "Account desc..", disabled: false},
  ]


export const jeTypes = [
    { label: "Reclass", value: "Reclass", disabled: true },
    { label: "Depreciation-Accrual", value: "Depreciation-Accrual", disabled: true }
]
export const loadTypes = [
    { label: "Delete previous & Add New Data ", value: "TRIN"},
    { label: "Append", value: "APPEND", }
]

export const uploadDatasets = [
    { label: "Additional-DataSet", value: "ADDITIONAL_DATASET"},
    { label: "CIP Tracker", value: "CIP_TRACKER"},
    { label: "QUEUE MassAditions (Manual)", value: "QUEUE_MANUAL"},
    { label: "TeamName-Company-Mapping", value: "map_teamname_comp"},

]
// { label: "Tririga", value: "TRIRIGA"},

export const datasets = [
    
    { label: "Buysell CF Purchased Asset Accrual", value: "BUYSELL_PURCHASE_ASSET", disabled: false, isMappingTable: false },
    { label: "Buysell CF True Up Cost Accrual", value: "BUYSELL_TRUEUP_COST", disabled: false, isMappingTable: false },
    { label: "Cash In Transit", value: "CIT", disabled: false, isMappingTable: false },
    { label: "IOH", value: "IOH", disabled: false, isMappingTable: false },
    { label: "OPO", value: "OPO", disabled: false, isMappingTable: false },
    { label: "Payee Central", value: "PAYEE_CENTRAL", disabled: false, isMappingTable: false },
    { label: "Queue - Mass Additions", value: "QUEUE", disabled: false, isMappingTable: false },
    { label: "Unaccounted Invoices", value: "UN_ACCOUNTED_INVOICES", disabled: false, isMappingTable: false },
    { label: "FDI", value: "FDI", disabled: false, isMappingTable: false },    
    { label: "Additional-DataSet", value: "ADDITIONAL_DATASET", disabled: false, isMappingTable: true },
    
]

export const mappingTables = [
    { label: "Catergory-Account-Mapping", value: "MAP_CAT_ACT", disabled: false, isMappingTable: true },
    { label: "Catergory-Mapping", value: "MAP_SL_CAT", disabled: false, isMappingTable: true },
    { label: "Process-Company-Mapping", value: "MAP_PROCESS_COMP", disabled: false, isMappingTable: true },
    { label: "Suppliers-Master-List", value: "SUPPLIERS_MASTER", disabled: false, isMappingTable: true },

]

export const mappingDatasets = [

    { label: "Catergory-Account-Mapping", value: "MAP_CAT_ACT", disabled: false },
    { label: "Catergory-Mapping", value: "MAP_SL_CAT", disabled: false },
    { label: "Process-Company-Mapping", value: "MAP_PROCESS_COMP", disabled: false },



]

export const months = [
    { label: "JAN", value: "JAN", disabled: false },
    { label: "FEB", value: "FEB", disabled: false },
    { label: "MAR", value: "MAR", disabled: false },
    { label: "APR", value: "APR", disabled: false },
    { label: "MAY", value: "MAY", disabled: false },
    { label: "JUN", value: "JUN", disabled: false },
    { label: "JUL", value: "JUL", disabled: false },
    { label: "AUG", value: "AUG", disabled: false },
    { label: "SEP", value: "SEP", disabled: false },
    { label: "OCT", value: "OCT", disabled: false },
    { label: "NOV", value: "NOV", disabled: false },
    { label: "DEC", value: "DEC", disabled: false },
    
]


export const years = [
    { label: "22", value: "22", disabled: false },
    { label: "23", value: "23", disabled: false },
    { label: "24", value: "24", disabled: false },
    { label: "25", value: "25", disabled: false },


]

export const inServiceMonths = [
    
    { label: "JAN", value: "1", disabled: false },
    { label: "FEB", value: "2", disabled: false },
    { label: "MAR", value: "3", disabled: false },
    { label: "APR", value: "4", disabled: false },
    { label: "MAY", value: "5", disabled: false },
    { label: "JUN", value: "6", disabled: false },
    { label: "JUL", value: "7", disabled: false },
    { label: "AUG", value: "8", disabled: false },
    { label: "SEP", value: "9", disabled: false },
    { label: "OCT", value: "10", disabled: false },
    { label: "NOV", value: "11", disabled: false },
    { label: "DEC", value: "12", disabled: false },
    
]

export const inServiceYears = [
    { label: "2000", value: "2000", disabled: false },
    { label: "2001", value: "2001", disabled: false },
    { label: "2002", value: "2002", disabled: false },
    { label: "2003", value: "2003", disabled: false },
    { label: "2004", value: "2004", disabled: false },
    { label: "2005", value: "2005", disabled: false },
    { label: "2006", value: "2006", disabled: false },
    { label: "2007", value: "2007", disabled: false },
    { label: "2008", value: "2008", disabled: false },
    { label: "2009", value: "2009", disabled: false },
    { label: "2010", value: "2010", disabled: false },
    { label: "2011", value: "2011", disabled: false },
    { label: "2012", value: "2012", disabled: false },
    { label: "2013", value: "2013", disabled: false },
    { label: "2014", value: "2014", disabled: false },
    { label: "2015", value: "2015", disabled: false },
    { label: "2016", value: "2016", disabled: false },
    { label: "2017", value: "2017", disabled: false },
    { label: "2018", value: "2018", disabled: false },
    { label: "2019", value: "2019", disabled: false },
    { label: "2020", value: "2020", disabled: false },
    { label: "2021", value: "2021", disabled: false },
    { label: "2022", value: "2022", disabled: false },
    { label: "2023", value: "2023", disabled: false },

    


]

export const Datasets =[
    {value: "QUEUE",                   label: "Queue - Mass Additions"},
    {value: "QUEUE_MANUAL",            label: "Manual Queue - Mass Additions"},

    {value: "OPO",                     label: "OPO"},
    {value: "IOH",                     label: "IOH"},
    {value: "CIT",                     label: "Cash In Transit"},
    {value: "PAYEE_CENTRAL",           label: "Payee Central"},
    {value: "UNACCT_INV",              label: "Unaccounted Invoices"},
    {value: "BUYSELL_PURCHASE_ASSET",  label: "Buysell CF True Up Cost Accrual"},
    {value: "BUYSELL_TRUEUP_COST",     label: "Buysell CF Purchased Asset Accrual"},
    {value: "FDI",                     label: "FDI Accrual"},
    {value: "ADDITIONAL_DATASET",      label: "Additional-Dataset"},

    {value: "GLTD",                    label: "GLTD-Transaction Details"},
    {value: "MJE",                     label: "MJE" },
    {value: "PinkFloyd",                     label: "Pink Floyd" },
   
  ]

  export const defaultDatasets =[
    {value: "QUEUE",                   label: "Queue - Mass Additions"},
    {value: "OPO",                     label: "OPO"},
    {value: "IOH",                     label: "IOH"},
    {value: "CIT",                     label: "Cash In Transit"},
    {value: "PAYEE_CENTRAL",           label: "Payee Central"},
    {value: "UNACCT_INV",              label: "Unaccounted Invoices"},
    {value: "BUYSELL_PURCHASE_ASSET",  label: "Buysell CF True Up Cost Accrual"},
    {value: "BUYSELL_TRUEUP_COST",     label: "Buysell CF Purchased Asset Accrual"},
    {value: "FDI",                     label: "FDI Accrual"},
    {value: "ADDITIONAL_DATASET",      label: "Additional-Dataset"},
    {value: "GLTD",                    label: "GLTD-Transaction Details"},
    
  ]
  
export const teamNames =[
    { label :"Non-AWS -JP" , value :"Non-AWS -JP"} ,
    { label :"Non-AWS -NA" , value :"Non-AWS -NA"} ,
    { label :"MENA" , value :"MENA"} ,
    { label :"DE" , value :"DE"} ,
    { label :"UK" , value :"UK"} ,
    { label :"Non-AWS-EMEA" , value :"Non-AWS-EMEA"} ,
    { label :"AWS-NA" , value :"AWS-NA"} ,
    { label :"APAC" , value :"APAC"} ,
    { label :"FR" , value :"FR"} ,
    { label :"AWS-EMEA" , value :"AWS-EMEA"} ,
    { label :"Global" , value :"Global"} ,
    { label :"India" , value :"India"} ,
    { label :"LATAM SOUTH" , value :"LATAM SOUTH"} ,
    { label :"Kuiper" , value :"Kuiper"} ,
    { label :"BLANK" , value :""}
]
export const ofaStage = { label: "PROD", value: "PROD" }

export const AWSoptions =[ {label: "Non-AWS-Only"}, {label: "AWS-Only" }, {label: "All" }]

export const ledgerNames = [
    { label :"Amazon.com, Inc." , value :"Amazon.com, Inc."} ,
    { label :"Amazon.co.uk Ltd." , value :"Amazon.co.uk Ltd."} ,
    { label :"Amazon.co.jp" , value :"Amazon.co.jp"} ,
    { label :"Amazon.com.au" , value :"Amazon.com.au"} ,
    { label :"Amazon.fr.eur" , value :"Amazon.fr.eur"} ,
    { label :"Amazon.cz" , value :"Amazon.cz"} ,
    { label :"Amazon.eg" , value :"Amazon.eg"} ,
    { label :"Amazon.sw" , value :"Amazon.sw"} ,
    { label :"Amazon.th" , value :"Amazon.th"} ,
    { label :"Amazon.de.eur" , value :"Amazon.de.eur"} ,
    { label :"Amazon.es.eur" , value :"Amazon.es.eur"} ,
    { label :"Amazon.ca" , value :"Amazon.ca"} ,
    { label :"Amazon.eu" , value :"Amazon.eu"} ,
    { label :"Amazon.in" , value :"Amazon.in"} ,
    { label :"Amazon.cn" , value :"Amazon.cn"} ,
    { label :"Amazon.sa" , value :"Amazon.sa"} ,
    { label :"Amazon.hk" , value :"Amazon.hk"} ,
    { label :"Amazon.ron" , value :"Amazon.ron"} ,
    { label :"Amazon.sg" , value :"Amazon.sg"} ,
    { label :"Amazon.cr" , value :"Amazon.cr"} ,
    { label :"Amazon.it" , value :"Amazon.it"} ,
    { label :"Amazon.br" , value :"Amazon.br"} ,
    { label :"Amazon.no" , value :"Amazon.no"} ,
    { label :"Amazon.dk" , value :"Amazon.dk"} ,
    { label :"Amazon.sk" , value :"Amazon.sk"} ,
    { label :"Amazon.ma" , value :"Amazon.ma"} ,
    { label :"Amazon.com.ch" , value :"Amazon.com.ch"} ,
    { label :"Amazon.pl" , value :"Amazon.pl"} ,
    { label :"Amazon.kr" , value :"Amazon.kr"} ,
    { label :"Amazon.ph" , value :"Amazon.ph"} ,
    { label :"Amazon.mx" , value :"Amazon.mx"} ,
    { label :"Amazon.tw" , value :"Amazon.tw"} ,
    { label :"Amazon.nl.eur" , value :"Amazon.nl.eur"} ,
    { label :"Amazon.nz" , value :"Amazon.nz"} ,
    { label :"Amazon.il" , value :"Amazon.il"} ,
    { label :"Amazon.tr" , value :"Amazon.tr"} ,
    { label :"Amazon.my" , value :"Amazon.my"} ,
    { label :"Amazon.at" , value :"Amazon.at"} ,
    { label :"Amazon.bh" , value :"Amazon.bh"} ,
    { label :"Amazon.cl" , value :"Amazon.cl"} ,
    { label :"Amazon.co" , value :"Amazon.co"} ,
    { label :"Amazon.id" , value :"Amazon.id"} ,
    { label :"Amazon.ae" , value :"Amazon.ae"} ,
    { label :"Amazon.vn" , value :"Amazon.vn"} ,
    { label :"Amazon.be.eur" , value :"Amazon.be.eur"} ,
    { label :"Amazon.ar" , value :"Amazon.ar"} ,
    { label :"Amazon.jo" , value :"Amazon.jo"} ,
    { label :"Amazon.mu" , value :"Amazon.mu"} ,
    { label :"Amazon.kh.usd" , value :"Amazon.kh.usd"}
]


export const dsColDefs = [
    {
        "QUEUE_MANUAL" : [
            { field: 'QUEUE_NAME' ,  sortable: true, resizable: true},
            { field: 'BOOK_TYPE_CODE' ,  sortable: true, resizable: true},
            { field: 'FEEDER_SYSTEM_NAME' ,  sortable: true, resizable: true},
            { field: 'DESCRIPTION' ,  sortable: true, resizable: true},
            { field: 'DATE_PLACED_IN_SERVICE' ,  sortable: true, resizable: true},
            { field: 'FIXED_ASSETS_COST' ,  sortable: true, resizable: true},
            { field: 'MERGED_COSTS' ,  sortable: true, resizable: true},
            { field: 'TOTAL_COSTS' ,  sortable: true, resizable: true},
            { field: 'INVOICE_NUMBER' ,  sortable: true, resizable: true},
            { field: 'QUANTITY_INVOICED' ,  sortable: true, resizable: true},
            { field: 'REQUESTOR_NAME' ,  sortable: true, resizable: true},
            { field: 'LASERFICHE_ID' ,  sortable: true, resizable: true},
            { field: 'AP_ACCTG_DATE' ,  sortable: true, resizable: true},
            { field: 'FA_DATE' ,  sortable: true, resizable: true},
            { field: 'VENDOR_NUMBER' ,  sortable: true, resizable: true},
            { field: 'SUPPLIER_NAME' ,  sortable: true, resizable: true},
            { field: 'PO_NUMBER' ,  sortable: true, resizable: true},
            { field: 'PO_LINE_NUMBER' ,  sortable: true, resizable: true},
            { field: 'POSTING_STATUS' ,  sortable: true, resizable: true},
            { field: 'PAYABLES_BATCH_NAME' ,  sortable: true, resizable: true},
            { field: 'CREATE_BATCH_DATE' ,  sortable: true, resizable: true},
            { field: 'MASS_ADDITION_ID' ,  sortable: true, resizable: true},
            { field: 'GL_STRING' ,  sortable: true, resizable: true},
            { field: 'SPLIT_MERGED_CODE' ,  sortable: true, resizable: true},
            { field: 'PARENT_MASS_ADDITION_ID' ,  sortable: true, resizable: true},
            { field: 'PARENT_POSTING_STATUS' ,  sortable: true, resizable: true},
            { field: 'PARENT_QUEUE_NAME' ,  sortable: true, resizable: true},
            { field: 'ASSET_CATEGORY' ,  sortable: true, resizable: true},
            { field: 'USEFUL_LIFE_IN_MONTHS' ,  sortable: true, resizable: true},
            { field: 'ASSET_TYPE' ,  sortable: true, resizable: true},
            { field: 'PO_COUPA_COMMODITY' ,  sortable: true, resizable: true},
            { field: '' }
    
        ]
    }
]

export const ruleSearchColumns = [
    { label :"Company Code" , value :"company", tags: ['text']} ,
    { label :"Cost Center" , value :"costcenter", tags: ['text']} ,
    { label :"Project" , value :"project", tags: ['text']} ,
    { label :"Journal Name" , value :"journalname", tags: ['text']} ,
    { label :"Line Description" , value :"linedescription", tags: ['text']} ,
    { label :"Final Major Category" , value :"final_maj_cat", tags: ['text'] } ,
    { label :"Final Minor Category" , value :"final_min_cat", tags: ['text'] } ,
    { label :"Cost Account" , value :"cost_acct",tags: ['text'] } ,
    { label :"Accumulated Depreciation Account" , value :"acc_dep_acct" , tags: ['text']} ,
    { label :"Expense Account" , value :"dep_acct", tags: ['text']} ,
    { label :"Team Name" , value :"teamname", tags: ['text']} ,
    { label :"Dataset" , value :"dataset", tags: ['text']} ,
    { label :"Ledger Name" , value :"ledger_name", tags: ['text']} ,
    { label :"PO Item Category" , value :"po_item_category", tags: ['text']} ,
    { label :"Supplier Number" , value :"suppliernumber", tags: ['text']} ,
    { label :"Warning Message" , value :"WarningMsg", tags: ['text']},
    { label :"Is Project CIP" , value :"isproject_cip", tags: ['text']},
    { label :"Location" , value :"location", tags: ['text']} ,
    { label :"PO Number" , value :"ponumber", tags: ['text']} ,
    { label :"Is Building" , value :"is_building", tags: ['text']} 



]
export const ruleActionColumns = [
    { label :"Final Major Category" , value :"final_maj_cat", tags: ['text']} ,
    { label :"Final Minor Category" , value :"final_min_cat", tags: ['text'] } ,
    { label :"EUL" , value :"eul", tags: ['number']} ,
    { label :"Cost Account" , value :"cost_acct",tags: ['text'] } ,
    { label :"Accumulated Depreciation Account" , value :"acc_dep_acct" , tags: ['text']} ,
    { label :"Expense Account" , value :"dep_acct", tags: ['text']} ,
    { label :"Warning Message" , value :"WarningMsg",  tags: ['text']} ,
]

export const ruleOperations = [
    { label :"Equals to" , value :"=", } ,
    { label :"Not Equal to" , value :"<>", } ,
    { label :"Contains" , value :"LIKE", } ,
    { label :"Not Contains" , value :"NOT LIKE", }

]
export const ruleLogicals = [
    { label :"OR" , value :"OR"} ,
    { label :"AND" , value :"AND"} ,
     


]

export const reportsDashboardLink = {
    "beta":"https://us-east-1.quicksight.aws.amazon.com/sn/dashboards/c32b20a9-a651-4d48-b5c2-54cf1625dc9b",
    "prod": ""
}

export const reviewOptions = [
    { label :"1. Expense" , value :"EXPENSED", description: "All expensed items"},
    { label :"2. Equipment" , value :"EQUIPMENT", description: "All items defaulting to Equipment"},
    { label :"3. Capitalised" , value :"CAPITALISED", description: "All capitalised items that are not in any of the other buckets"},
    { label :"4. MJEs" , value :"MJE", description: "All manual journals"},
    { label :"5. CIP" , value :"CIP", description: "All lines allocated to CIP"},
    { label :"6. Previously Posted" , value :"PREVIOUSLY-POSTED", description: "All lines with Rule-1.1"},
    {label :"Failed Logic Checks" , value :"FAILED-LOGIC-CHECKS", description: ""},
    {label :"Rows With Warning Msg" , value :"ROWS-WITH-WARNING-MSG", description: ""},
    { label :"All Rows" , value :"ALL-ROWS", description: ""}
]

export const onscreenOverrideColumnOptions = [
    { label :"RowId" , value :"RowId"} ,
    { label :"dataset" , value :"dataset"} ,
    { label :"ledger_id" , value :"ledger_id"} ,
    { label :"ledger_name" , value :"ledger_name"} ,
    { label :"periodname" , value :"periodname"} ,
    { label :"reference_id" , value :"reference_id"} ,
    { label :"company" , value :"company"} ,
    { label :"location" , value :"location"} ,
    { label :"costcenter" , value :"costcenter"} ,
    { label :"account" , value :"account"} ,
    { label :"product" , value :"product"} ,
    { label :"channel" , value :"channel"} ,
    { label :"project" , value :"project"} ,
    { label :"debit" , value :"debit"} ,
    { label :"credit" , value :"credit"} ,
    { label :"transaction_currency" , value :"transaction_currency"} ,
    { label :"journalname" , value :"journalname"} ,
    { label :"linedescription" , value :"linedescription"} ,
    { label :"suppliernumber" , value :"suppliernumber"} ,
    { label :"suppliername" , value :"suppliername"} ,
    { label :"dateplacedinservice" , value :"dateplacedinservice"} ,
    { label :"ponumber" , value :"ponumber"} ,
    { label :"invoicenumber" , value :"invoicenumber"} ,
    { label :"userid" , value :"userid"} ,
    { label :"book_type_code" , value :"book_type_code"} ,
    { label :"po_item_category" , value :"po_item_category"} ,
    { label :"islocation_cip" , value :"islocation_cip"} ,
    { label :"location_go_live_date" , value :"location_go_live_date"} ,
    { label :"isproject_cip" , value :"isproject_cip"} ,
    { label :"project_go_live_date" , value :"project_go_live_date"} ,
    { label :"final_date_for_comparison" , value :"final_date_for_comparison"} ,
    { label :"isproject_lhi" , value :"isproject_lhi"} ,
    { label :"is_building" , value :"is_building"} ,
    { label :"lease_end_date" , value :"lease_end_date"} ,
    { label :"sl_maj_cat" , value :"sl_maj_cat"} ,
    { label :"sl_min_cat" , value :"sl_min_cat"} ,
    { label :"sl_book" , value :"sl_book"} ,
    { label :"final_maj_cat" , value :"final_maj_cat"} ,
    { label :"final_min_cat" , value :"final_min_cat"} ,
    { label :"eul" , value :"eul"} ,
    { label :"dep_in_months" , value :"dep_in_months"} ,
    { label :"dep_amount" , value :"dep_amount"} ,
    { label :"cost_acct" , value :"cost_acct"} ,
    { label :"acc_dep_acct" , value :"acc_dep_acct"} ,
    { label :"dep_acct" , value :"dep_acct"} ,
    { label :"WarningMsg" , value :"WarningMsg"} ,
    { label :"Rule_Number" , value :"Rule_Number"} ,
    { label :"Override" , value :"Override"} ,
    { label :"Override_Prepare_JE_Reclass" , value :"Override_Prepare_JE_Reclass"} ,
    { label :"Override_Prepare_JE_Dep_Accrual" , value :"Override_Prepare_JE_Dep_Accrual"} ,
    { label :"Override_CIP_YN" , value :"Override_CIP_YN"} ,
    { label :"Override_InServiceDate_Month" , value :"Override_InServiceDate_Month"} ,
    { label :"Override_InServiceDate_Year" , value :"Override_InServiceDate_Year"} ,
    { label :"Override_Major_Min_Category" , value :"Override_Major_Min_Category"} ,
    { label :"Override_EUL" , value :"Override_EUL"} ,
    { label :"Validation_Status" , value :"Validation_Status"} ,
    { label :"Validation_err_msg" , value :"Validation_err_msg"} ,
    { label :"JE_POSTED_STATUS" , value :"JE_POSTED_STATUS"} ,
    { label :"Suggested_Maj_Cat" , value :"Suggested_Maj_Cat"} ,
    { label :"Suggested_Min_Cat" , value :"Suggested_Min_Cat"} ,
    { label :"Logic_Checks" , value :"Logic_Checks"} ,
    { label :"last_update_date" , value :"last_update_date"} ,
    { label :"Suggested_sl_book" , value :"Suggested_sl_book"} ,
    { label :"teamname" , value :"teamname"}   
]

export const summaryColumnOptions = [
    { label :"Ledger Name" , value :"ledger_name"} ,
    { label :"Company" , value :"company"} ,
    { label :"Cost Account" , value :"cost_account"} ,
    { label :"Cost" , value :"net_cost_je_amount"} ,
    { label :"Cost TB Balance" , value :"cost_balance"} ,
    { label :"Percent" , value :"percent1"} ,
    { label :"Accum Depr Acct" , value :"accum_dep_acct"} ,
    { label :"Depr Amount" , value :"net_dep_je_amount"} ,
    { label :"Accum Depr TB Balance" , value :"accum_dep_bal"} ,
    { label :"Depr / Accum Depr" , value :"net_je_amt_dep_percent"} ,
    { label :"Cost / Depr Amnt" , value :"dep_percent"} 
]
