import React from "react";
import {
  Select
} from "@amzn/awsui-components-react";

const CustomSelect = (props) => {
  const makeSelectObject = () => {
    let value = props.getValue(props.field, props.subKey)
    if(value == null){
      return null
    }else{
      return { label: value, value: value }
    }
  }
  return (
    <Select
      selectedOption={makeSelectObject()}
      onChange={({ detail }) =>
        props.updateValue(props.field, detail.selectedOption.value, props.subKey)
      }
      placeholder="Choose options"
      options={props.getOptions(props.field, props.subKey)}
    />
  )
}

export default CustomSelect;