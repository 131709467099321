import React from "react";
import {
    Input,
    Box,
    Grid
} from "@amzn/awsui-components-react";

const CustomInput = (props) => {
    return (
        <Grid
      gridDefinition={[{ colspan: props.required?10:12 }, { colspan: props.required?2:0 }]}
    >
      <div><Box>
            {props.info? <span className="info">{props.info}</span> : ''}
            <Input
            onChange={({ detail }) => {
                let val =detail.value
                if(props?.postProcess){
                    val=props.postProcess(detail.value)
                }
                props.updateValue(props.field, val, props.subKey)
            }}
            value={props.getValue(props.field, props.subKey)} placeholder={props?.placeholder}
            ariaRequired={props.required}
        />
        
        </Box></div>
      <div>{props.required? <span className="mandatory">*</span> : ''}</div>
    </Grid>
        
    )
}

export default CustomInput;