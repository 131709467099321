import React, { useState, useEffect } from "react";
import { APPROVER_TYPES, glossary, PROCESS_KEYS, UPLOADER_TYPES } from "./Constants";
import { setCurrentAppId, setTemplateId } from "../../store/userAuthSlice";
import SideNav from "./SideNav";
import {
  AppLayout,
  Button,
  ColumnLayout,
  Input,
  Icon,
  Textarea,
  Container,
  Header,
  Box,
  Grid,
  SpaceBetween,
  Link,
  ExpandableSection,
  ContentLayout,
  Table,
  Modal,
  Spinner,
  RadioGroup,
  FileUpload,
  HelpPanel,
  Flashbar,
  Alert,
  Checkbox
} from "@amzn/awsui-components-react";

import "../../index.scss";
import "./styles.scss"
import { useSelector, useDispatch } from "react-redux";
import { canAddEditDeleteApplication } from "../Templates/Utils/Permissions";
import { useHistory, useParams } from "react-router-dom";
import CustomInput from "../Templates/components/CustomInput";
import CustomCheckbox from "../Templates/components/CustomCheckbox";
import CustomMultiHybridInputAndSelect from "../Templates/components/CustomMultiHybridInputAndSelect";
import { getEditableFields } from "../Templates/Utils/FormMetadata";
import {  getAppPromise, createAuditLog, createLogicoProcess, updateLogicoProcess, deleteLogicoProcess, getLogicoProcessById } from "src/Ateam-BI/Templates/Utils/AppActivityUtils"
import { TEMPLATE_APP, APPS_WITH_APP_LEVEL_PERMISSIONS_IN_TEMPLATE } from "../Templates/Constants";
import Feedback from "src/generic-components/UserFeedback/Feedback";
import { configureLogicoS3 } from "src/context/AuthContextUtility";

export default (props) => {
  const dispatch = useDispatch();
  const currentStage = useSelector((globalState) => globalState.auth.currentStage)
  const templateId = useSelector((globalState) => globalState.auth.templateId)
  const history = useHistory();
  const [NavOpen, setNavOpen] = useState(true);
  const [showLoadingIcon, setShowLoadingIcon] = useState(true);
  const userId = useSelector((globalState) => globalState.auth.userId);
  let { id } = useParams();
  let { lpid } = useParams();
  const [isNewProcess, setIsNewProcess] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [errorMessage, setErrorMessage] = useState('')
  const [alertErrorMessage, setAlertErrorMessage] = useState(null)
  const [accessPerms, setAccessPerms] = useState(props.accessPerms);
  const [canDelete, setCanDelete] = useState(false)
  const [canEdit, setCanEdit] = useState(false)
  const [canAdd, setCanAdd] = useState(false)
  const [enableEditAction, setEnableEditAction] = useState(false)
  const [processData, setProcessData] = useState({})
  const [processDataEdited, setProcessDataEdited] = useState({})
  const [fieldMetadata, setFieldMetadata] = useState({});
  const [sampleFile, setSampleFile] = useState([]);
  const [generatingSchemaLoader, setGeneratingSchemaLoader] = useState(false);
  const [deleteConfirmationModal, setDeleteConfirmationModal] = useState(false);
  const [deleteNotificationModal, setDeleteNotificationModal] = useState(false);
  const [updateNotificationModal, setUpdateNotificationModal] = useState(false);
  const [deleteAppName, setDeleteAppName] = useState('');
  const [flashBarComments, setFlashBarComments] = useState();
  const [isApprovalDisabled, setIsApprovalDisabled] = useState(false);
  const [backUrl, setBackUrl] = useState(null)
  const [updateComments, setUpdateComments] = useState("")
  const [modalError, setModalError] = useState("")
  const [toolsOpen, setToolsOpen] = useState(false);
  const [dataGenerationPlatform, setDataGenerationPlatform] = useState('')
  const [dataSharePlatform, setDataSharePlatform] = useState('')
  const [dataGenerationPlatformClass, setDataGenerationPlatformClass] = useState('')
  const [dataSharePlatformClass, setDataSharePlatformClass] = useState('')
  const [deleteMessage, setDeleteMessage] = useState('')
  useEffect(() => {
    configureLogicoS3()
    setInitView()
    setBackUrl(getParam('back'))
    return () => {
      // this now gets called when the component unmounts
    };
  }, []);

 

  useEffect(() => {
    if (processDataEdited.hasOwnProperty(PROCESS_KEYS.critical_data) && processDataEdited.hasOwnProperty(PROCESS_KEYS.sox_approved)) {
    
      if(processDataEdited[PROCESS_KEYS.critical_data] && processDataEdited[PROCESS_KEYS.sox_approved]) {
        setDataGenerationPlatform("Red")
        setDataSharePlatform("None")
        setDataGenerationPlatformClass("red_badge")
        setDataSharePlatformClass("grey_badge")
      } else if (!processDataEdited[PROCESS_KEYS.critical_data] && processDataEdited[PROCESS_KEYS.sox_approved]) {
        setDataGenerationPlatform("Red")
        setDataSharePlatform("Orange")
        setDataGenerationPlatformClass("red_badge")
        setDataSharePlatformClass("orange_badge")
      } else if (!processDataEdited[PROCESS_KEYS.critical_data] && !processDataEdited[PROCESS_KEYS.sox_approved]) {
        setDataGenerationPlatform("Orange")
        setDataSharePlatform("Red")
        setDataGenerationPlatformClass("orange_badge")
        setDataSharePlatformClass("red_badge")
      } else if (processDataEdited[PROCESS_KEYS.critical_data] && !processDataEdited[PROCESS_KEYS.sox_approved]) {
        setDataGenerationPlatform("Red")
        setDataSharePlatform("None")
        setDataGenerationPlatformClass("red_badge")
        setDataSharePlatformClass("grey_badge")
      }

    }
  },[processDataEdited])

  useEffect(() => {
    setProcessDataEdited(processData)
    if (processData.hasOwnProperty(PROCESS_KEYS.status)
      && processData[PROCESS_KEYS.status]
      && processData[PROCESS_KEYS.status].toLowerCase().includes("failed")) {
      setFlashBarComments([{
        type: "error",
        dismissible: true,
        dismissLabel: "Dismiss message",
        onDismiss: () => setFlashBarComments([]),
        content: (
          <>
            {processData[PROCESS_KEYS.status]}
          </>
        ),
        id: "comments"
      }])
    }
    
  }, [processData])

 

  useEffect(() => {
    checkIfFormIsSubmittable()
  }, [processDataEdited, canEdit])

  const dismissDeleteDialog = () => {
    setDeleteAppName('')
    setDeleteConfirmationModal(false)
  }

  const dismissDeleteNotifyDialog = () => {
    setDeleteNotificationModal(false)
    if(backUrl){
      history.push(`/${backUrl}`)
    }else{
      history.push(`/App/${id}`)
    }
    

  }

  const dismissUpdateNotifyDialog = () => {
    setUpdateNotificationModal(false)
  }
  



  const increaseCount = (key, incVal, annotation) => {
    if (!annotation.hasOwnProperty(key)) {
      annotation[key] = 0
    }
    annotation[key] = annotation[key] + incVal
  }



  const getParam= (key) => {
    const search = props.location.search;
    const params = new URLSearchParams(search);
    const value = params.get(key);
    return value;
  }

  const getGroup = () => {
    return getParam('group')
  }
  
  const setInitView = async () => {
    
  let next_step = getParam('next_step')
  if(next_step){
    processData[PROCESS_KEYS.next_step] = next_step
  }
    processData[PROCESS_KEYS.app_id] = id
    processData[PROCESS_KEYS.approval_required] = false
    processData[PROCESS_KEYS.critical_data] = false
    processData[PROCESS_KEYS.sox_approved] = false

    loadEditableFields()
    if (lpid) {
      dispatch(setCurrentAppId(id));
      loadData();
      setIsEditMode(false)
      setIsNewProcess(false)
    }else{
      setIsNewProcess(true)
      setShowLoadingIcon(false);
      (!isEditMode) ? setIsEditMode(true) : ''
    }
    let appTemplateID = templateId
    if (!templateId) {
      // fetch template from app id
      let result = await getAppPromise(id)
      setTemplateId(result.data.getAppsV2[0][TEMPLATE_APP.TEMPLATE_ID])
      appTemplateID = result.data.getAppsV2[0][TEMPLATE_APP.TEMPLATE_ID]
    }
    let accessPerms;
    if(id && APPS_WITH_APP_LEVEL_PERMISSIONS_IN_TEMPLATE[currentStage?.toUpperCase()].includes(id)) {
      accessPerms = await canAddEditDeleteApplication(userId, id, currentStage)
    }
    else {
      accessPerms = await canAddEditDeleteApplication(userId, appTemplateID, currentStage)
    }
    setCanDelete(accessPerms?.delete)
    setCanEdit(accessPerms?.edit)
    setCanAdd(accessPerms?.add)

    // !lpid means isNewProcess=true. Sometimes the useeffect take time, so relying on lpid. lpid is a path param
    if(!lpid && !accessPerms?.add){
      setAlertErrorMessage(
        {
          "header":"Access denied",
          "message": "No access to add a new process"
        }
      )
    }else{
      setAlertErrorMessage(null)
    }
  }

  const loadData =  () => {
    // setProcessData()
    getLogicoProcessById(lpid, function onSuccess(response) {
      const r = response.data.getLogicoProcessByID
      
      if (r.hasOwnProperty(PROCESS_KEYS.approvers) && r[PROCESS_KEYS.approvers]) {
        r[PROCESS_KEYS.approvers].sort((a,b) => a.column_index - b.column_index)
      }
      setProcessData(r)
      setShowLoadingIcon(false);
    }, function onFailure(response) {
      setShowLoadingIcon(false);
    })
  }

  const loadEditableFields = async () => {
    let res = await getEditableFields()
    // res[PROCESS_KEYS.approvers] = { values: [{ label: "USER", value: APPROVER_TYPES.APPROVER }, { label: "LDAP", value: APPROVER_TYPES.APPROVER_LDAP }] }
    res[PROCESS_KEYS.uploaders] = { values: [{ label: "USER", value: UPLOADER_TYPES.UPLOADER }, { label: "LDAP", value: UPLOADER_TYPES.UPLOADER_LDAP }] }
    setFieldMetadata(res)
  }

  const getSelectFieldValues = (fieldName, subKey = null) => {
    if (fieldMetadata && fieldMetadata[fieldName] && fieldMetadata[fieldName]['values']) {
      return fieldMetadata[fieldName]['values']
    } else if (subKey && fieldMetadata && fieldMetadata[fieldName][subKey] && fieldMetadata[fieldName][subKey]['values']) {
      return fieldMetadata[fieldName][subKey]['values']
    } else {
      return []
    }
  }

  const updateValue = (fieldName, value, subKey = null) => {
    if (subKey) {
      if (!processDataEdited.hasOwnProperty(fieldName)) {
        processDataEdited[fieldName] = {}
      }
      processDataEdited[fieldName][subKey] = value
    } else {
      processDataEdited[fieldName] = value
    }
    if(fieldName == PROCESS_KEYS.sox_approved) {
      if(value){
        // approval required MUST be true
        setIsApprovalDisabled(true)
        updateValue(PROCESS_KEYS.approval_required, true)
      } else{
        setIsApprovalDisabled(false)
      }   
    }


    setProcessDataEdited(JSON.parse(JSON.stringify(processDataEdited)))
  }


  const getValue = (fieldName, subKey = null) => {
    if (subKey) {
      if (processDataEdited.hasOwnProperty(fieldName)) {
        return processDataEdited[fieldName][subKey]
      } else {
        return null
      }
    } else {
      return processDataEdited[fieldName]
    }
  }

  const getMultiInputFieldValues = (fieldName) => {
    if (processDataEdited && processDataEdited[fieldName]) {
      return processDataEdited[fieldName]
    } else {
      return []
    }
  }

  const getMultiSelectedOptions = (fieldName) => {
    let arr = processDataEdited[fieldName]
    let selectedOptions = []
    if (arr) {
      arr.forEach((item) => {
        selectedOptions.push({ label: item, value: item })
      })
    }
    return selectedOptions
  }

  const postProcessTeamEmails = (value) => {
    return value.replaceAll("@amazon.com", "")
  }

  const approversValidation = (elements) => {
    let emptyValues = true
    elements?.forEach((element) => {
      if (!element.type || !element.alias) {
        emptyValues = false
      }
    })
    return emptyValues
  }

  const mandatoryFieldListener = (key, enableUpdate) => {
    // mandatoryFieldsMissing[key] = enableUpdate
  }

  
  const checkIfNull = (data, field, missingFields) => {
    if (data && (!data.hasOwnProperty(field) || !data[field])) {
      missingFields.push(field)
    }else if(Array.isArray(data[field]) && data[field].length<=0){
      missingFields.push(field)
    }
  }

  const getMissingFields = (data, fields) => {
    let missingFields = []
    fields.forEach((field) => {
      if (typeof field == 'string') {
        checkIfNull(data, field, missingFields)
      } else {
        if (field?.mainKey) {
          field.subKeys.forEach(subKey => {
            data[field.mainKey]?.forEach((item) => {
              checkIfNull(item, subKey, missingFields)
            })
          })
        }
      }
    })
    return missingFields
  }

  const checkIfFormIsSubmittable = () => {
    let missingFields = getMissingFields(processDataEdited, [PROCESS_KEYS.process_name, PROCESS_KEYS.process_description, PROCESS_KEYS.fast_table, PROCESS_KEYS.fast_view, PROCESS_KEYS.display_label])
    if (missingFields.length <= 0) {
      missingFields = [...missingFields]
    }
    // if (missingFields.length <= 0
    //   && processDataEdited.hasOwnProperty(PROCESS_KEYS.approval_required)
    //   && processDataEdited[PROCESS_KEYS.approval_required]) {
    //   if (!missingFields?.includes(PROCESS_KEYS.approvers)
    //     && processDataEdited?.hasOwnProperty(PROCESS_KEYS.approvers) && processDataEdited[PROCESS_KEYS.approvers]?.length == 0) {
    //     missingFields = [...missingFields, PROCESS_KEYS.approvers]
    //   }
    //   let missingFields2 = getMissingFields(processDataEdited, [{ mainKey: PROCESS_KEYS.approvers, subKeys: [PROCESS_KEYS.APPROVER_KEYS.alias, PROCESS_KEYS.APPROVER_KEYS.type] }])
    //   missingFields = [...missingFields, ...missingFields2]

    // }

    let approvalDecision = true
    if (!validateSoxRequirements(processDataEdited)) {
      approvalDecision = false
    } else if (processDataEdited.hasOwnProperty(PROCESS_KEYS.approval_required) && processDataEdited[PROCESS_KEYS.approval_required] && processDataEdited.hasOwnProperty(PROCESS_KEYS.approvers)
     && processDataEdited[PROCESS_KEYS.approvers] && processDataEdited[PROCESS_KEYS.approvers].length <= 0) {
      approvalDecision = false
    }
    setEnableEditAction(missingFields.length <= 0 && approvalDecision && ((isNewProcess && canAdd ) || (isEditMode && canEdit)))
  }

  const validateSoxRequirements = (processDataEdited) => {
    if (processDataEdited.hasOwnProperty(PROCESS_KEYS.sox_approved) && processDataEdited[PROCESS_KEYS.sox_approved]) {
      // if(processDataEdited.hasOwnProperty(PROCESS_KEYS.sox_evidence) && !processDataEdited[PROCESS_KEYS.sox_evidence] && processDataEdited[PROCESS_KEYS.sox_evidence] == ""){
      //   return false
      // }
      if (processDataEdited[PROCESS_KEYS.approvers]?.length < 2) {
        let groupCount = 0
        processDataEdited[PROCESS_KEYS.approvers].forEach((approver) => {
          if(approver[PROCESS_KEYS.APPROVER_KEYS.type] == APPROVER_TYPES.APPROVER_LDAP){
            groupCount = groupCount + 1
          }
        })
        if(groupCount > 0){
          return true
        }else{
          return false
        }
      } else {
        return true
      }
    } else {
      return true
    }
  }

 
  const addProcessDetails = () => {
    setErrorMessage("")
    let missingFields = getMissingFields(processDataEdited, [PROCESS_KEYS.process_name])
    if (missingFields && missingFields.length > 0) {
      setErrorMessage('missing mandatory fields : ' + missingFields.join(","))
    } else {
      setShowLoadingIcon(true)
      
  
      processDataEdited[PROCESS_KEYS.last_updated_by] = userId
      processDataEdited[PROCESS_KEYS.created_by] = userId

 
      createAuditLog(`LOGICO`,`ADD_REQ#${getCurrentTime()}`, "Adding a process", JSON.stringify({}), JSON.stringify(processDataEdited), "ADD REQ", userId)
      setProcessData(processDataEdited)
      createLogicoProcess(processDataEdited, function onSuccess(response) {
        setIsNewProcess(false)
        setProcessData(JSON.parse(JSON.stringify(response.data.createLogicoProcess)))
        setShowLoadingIcon(false)
        history.push(`/App/${response.data.createLogicoProcess.app_id}/LogicoProcess/${response.data.createLogicoProcess.logico_process_id}`)
        cancelEdition()
      }, function onFailure(error) {
        setErrorMessage(error?.errors[0]?.message)
        setShowLoadingIcon(false)
      })
    }
  }

  const updateProcessDetails = () => {
    setErrorMessage("")
    let missingFields = getMissingFields(processDataEdited, [PROCESS_KEYS.logico_process_id, PROCESS_KEYS.process_name])
    if (missingFields && missingFields.length > 0) {
      setErrorMessage('missing mandatory fields : ' + missingFields.join(","))
    } else {
      setShowLoadingIcon(true)
      
      processDataEdited[PROCESS_KEYS.last_updated_by] = userId
      if (updateComments) {
        setErrorMessage("")
        // add an audit log
        createAuditLog(`LOGICO#${lpid}`, `${getCurrentTime()}`, updateComments, JSON.stringify(processData), JSON.stringify(processDataEdited), "UPDATE REQ", userId)
        setUpdateComments("")
        
        setProcessData(processDataEdited)
        updateLogicoProcess(processDataEdited, function onSuccess(response) {
         
        }, function onFailure(error) {

        })
        setIsNewProcess(false)
        setShowLoadingIcon(false)
        cancelEdition()
        setUpdateNotificationModal(true)
      } else {
        setShowLoadingIcon(false)
        setErrorMessage("Please provide your comments for the update")
      }
      
    }
  }

  const cancelEdition = () => {
    setErrorMessage("")
    setIsEditMode(false)
    setProcessDataEdited(processData)
  }

  const editProcess = () => {
    setIsEditMode(true)
    setProcessDataEdited({ ...processData })
  }

  const delProcess = () => {
    if(updateComments){
      setShowLoadingIcon(true)
    dismissDeleteDialog()
      setModalError("")
      createAuditLog(`LOGICO#${lpid}`,`${getCurrentTime()}`, updateComments, JSON.stringify(processData), JSON.stringify({}), "DELETE REQ", userId)
      setUpdateComments("")
      deleteLogicoProcess(lpid, id, function onSuccess(response) {
        setDeleteMessage(response?.data?.deleteLogicoProcess)
        setShowLoadingIcon(false)
        setDeleteConfirmationModal(false)
        setDeleteNotificationModal(true)
      }, function onFailure(response) {
        setShowLoadingIcon(false)
        setDeleteConfirmationModal(false)
        setDeleteNotificationModal(true)
      })

    }else{
      setModalError("Please provide comments with the reason for deletion")
    }
    

  }

  const getCurrentTime = () => {
    return Date.now();
  }

  const getDate = (value) => {
    var d = new Date(value)
    return d.toLocaleString()
  }

  const lastUpdatedContent = (<>
    {getValue(PROCESS_KEYS.last_updated_timestamp) ? <>

      <span className="last_updated"> Last updated : <span className="bold">{getDate(getValue(PROCESS_KEYS.last_updated_timestamp))}</span></span>
      {getValue(PROCESS_KEYS.last_updated_by) ? <span className="last_updated"> &nbsp; by  <span className="bold">{getValue(PROCESS_KEYS.last_updated_by)}</span></span> : ''}
    </> : ''}
  </>)


  const dataClasificationContent = (
    <Box>
    <SpaceBetween direction="horizontal" size="m">
      <Box>Data Generation Platform: <span className={dataGenerationPlatformClass}>{dataGenerationPlatform}</span></Box>
      <Box>Data Share Platform: <span className={dataSharePlatformClass}> {dataSharePlatform} </span></Box>
    </SpaceBetween>
    </Box>
  )
  const Content = (
    <>
      {showLoadingIcon ? <Modal visible={showLoadingIcon}>
        <Box>
          <Spinner></Spinner>
          Loading...
        </Box>
      </Modal> :
        <ContentLayout
          headerVariant="high-contrast"
          className="content"
          header={
            <SpaceBetween size="l">
              <Header
                variant="h1"
              >

              </Header>

            </SpaceBetween>
          }
        >

          <SpaceBetween size="l" >
          {
            alertErrorMessage?<Alert
            statusIconAriaLabel="Error"
            type="error"
            header={alertErrorMessage?.header}
          >
            {alertErrorMessage?.message}
          </Alert>:''
          }

            <Container
            >
              <SpaceBetween direction="vertical">
                <SpaceBetween direction="horizontal">
                  <Box variant="h1">Logico Process Details</Box>
                  {
                    !isEditMode && canEdit ?
                      <Button iconName="edit" variant="icon" onClick={editProcess} /> : <></>
                  }
                  {
                    !isEditMode && canDelete ?
                      <Button iconName="remove" variant="icon" onClick={() => { setDeleteConfirmationModal(true) }} /> : <></>

                  }
                  <Modal
                    onDismiss={dismissDeleteDialog}
                    visible={deleteConfirmationModal}
                    footer={
                      <Box float="right">
                        <SpaceBetween direction="horizontal" size="xs">
                          <Button variant="link" onClick={dismissDeleteDialog} >Cancel</Button>
                          <Button disabled={deleteAppName !== processData[PROCESS_KEYS.process_name]} variant="primary" onClick={delProcess}  >Confirm</Button>
                        </SpaceBetween>
                      </Box>
                    }
                    header={
                      <React.Fragment>
                        <Box variant="h2">
                          Delete confirmation for '{processData[PROCESS_KEYS.process_name]}'
                        </Box>
                      </React.Fragment>
                    }
                  >
                    <SpaceBetween direction="vertical" size="m">
                      <Box>To confirm deletion, enter the name of the process in the text input field below</Box>
                      <Box>
                        <Input placeholder={processData[PROCESS_KEYS.process_name]} onChange={({ detail }) => setDeleteAppName(detail.value)} value={deleteAppName} />
                      </Box>
                      <Box>
                        <Textarea
                          class="description"
                          onChange={({ detail }) => setUpdateComments(detail.value)}
                          value={updateComments}
                          placeholder="Comments"
                        ></Textarea>
                      </Box>
                      <Box>
                        {modalError && (
                          <Box variant="p" color="text-status-error">
                            {modalError}
                          </Box>
                        )}
                      </Box>
                    </SpaceBetween>
                  </Modal>
                  <Modal
                    onDismiss={dismissDeleteNotifyDialog}
                    visible={deleteNotificationModal}
                    footer={
                      <Box float="right">
                        <SpaceBetween direction="horizontal" size="xs">
                          <Button variant="primary" onClick={dismissDeleteNotifyDialog}>Okay</Button>
                        </SpaceBetween>
                      </Box>
                    }
                    header={
                      <React.Fragment>
                        <Box variant="h2">
                          Info
                        </Box>
                      </React.Fragment>
                    }
                  >
                    <SpaceBetween direction="vertical" size="m">
                      <Box><Icon name="status-positive" variant="success" /> {deleteMessage}</Box>
                    </SpaceBetween>
                  </Modal>
                  <Modal
                    onDismiss={dismissUpdateNotifyDialog}
                    visible={updateNotificationModal}
                    footer={
                      <Box float="right">
                        <SpaceBetween direction="horizontal" size="xs">
                          <Button variant="primary" onClick={dismissUpdateNotifyDialog}>Okay</Button>
                        </SpaceBetween>
                      </Box>
                    }
                    header={
                      <React.Fragment>
                        <Box variant="h2">
                          Update scheduled for '{processData[PROCESS_KEYS.process_name]}'
                        </Box>
                      </React.Fragment>
                    }
                  >
                    <SpaceBetween direction="vertical" size="m">
                      <Box><Icon name="status-positive" variant="success" /> Process is scheduled to be updated, please check back after 5 minutes.</Box>
                    </SpaceBetween>
                  </Modal>
                </SpaceBetween>
                <Box >{lastUpdatedContent}</Box>
                <br></br>
              </SpaceBetween>
              
              <SpaceBetween size="l">
              
                <ColumnLayout columns={2}>
                  <div>
                    <Box variant="h4">Logico Process Name</Box>
                    {
                      isEditMode ?
                        <CustomInput field={PROCESS_KEYS.process_name} placeholder="Process name" updateValue={updateValue} getValue={getValue}></CustomInput>
                        : processData?.hasOwnProperty(PROCESS_KEYS.process_name) ? processData[PROCESS_KEYS.process_name] : ''
                    }
                  </div>
                  <div>
                    <Box variant="h4">Label - To display in the application page</Box>
                    {
                      isEditMode ?
                        <CustomInput field={PROCESS_KEYS.display_label} placeholder="label" updateValue={updateValue} getValue={getValue}></CustomInput>
                        : processData?.hasOwnProperty(PROCESS_KEYS.display_label) ? processData[PROCESS_KEYS.display_label] : ''
                    }
                  </div>
                  <div>
                    <Box variant="h4">FAST Table Name</Box>
                    {
                      isEditMode ?
                        <CustomInput field={PROCESS_KEYS.fast_table} placeholder="Table name" updateValue={updateValue} getValue={getValue}></CustomInput>
                        : processData?.hasOwnProperty(PROCESS_KEYS.fast_table) ? processData[PROCESS_KEYS.fast_table] : ''
                    }
                  </div>
                  <div>
                    <Box variant="h4">FAST View Name</Box>
                    {
                      isEditMode ?
                        <CustomInput field={PROCESS_KEYS.fast_view} placeholder="View name" updateValue={updateValue} getValue={getValue}></CustomInput>
                        : processData?.hasOwnProperty(PROCESS_KEYS.fast_view) ? processData[PROCESS_KEYS.fast_view] : ''
                    }
                  </div>
                  <div>
                    <Box variant="h4">Description</Box>
                    {
                      isEditMode ?
                        <Textarea
                          class="description"
                          onChange={({ detail }) => updateValue(PROCESS_KEYS.process_description, detail.value)}
                          value={getValue(PROCESS_KEYS.process_description)}
                          placeholder="Description"
                        />
                        : processData?.hasOwnProperty(PROCESS_KEYS.process_description) ? processData[PROCESS_KEYS.process_description] : ''
                    }
                  </div>
                  
                </ColumnLayout>
              </SpaceBetween>

              <SpaceBetween size="m">

                  <SpaceBetween>
                    <ColumnLayout columns={2}>

                    <div>
                    <Box variant="h4">&nbsp;</Box>
                          <SpaceBetween direction="vertical">
                            <div className="header_menu">
                              <div className="header_left">
                                <Box variant="h1">Uploader(s) Details</Box>
                              </div>
                            </div>
                            {
                              isEditMode ?
                                <CustomMultiHybridInputAndSelect field={PROCESS_KEYS.uploaders}
                                  inputKey={PROCESS_KEYS.UPLOADER_KEYS.alias} selectKey={PROCESS_KEYS.UPLOADER_KEYS.type} selectHint="Choose type"
                                  placeholder="alias" getValues={getValue} postProcess={postProcessTeamEmails} validator={approversValidation} mandatoryFieldListener={mandatoryFieldListener}
                                  updateValue={updateValue} getOptions={getSelectFieldValues} info=' ' errorMessage='type and alias input are mandatory' duplicatesNotAllowed={true} />
                                :
                                <div>
                                  {getValue(PROCESS_KEYS.uploaders) ? <Table
                                    columnDefinitions={[
                                      {
                                        id: "variable",
                                        header: "Type",
                                        cell: item => (item[PROCESS_KEYS.UPLOADER_KEYS.type] == UPLOADER_TYPES.UPLOADER ? 'USER' : 'LDAP') || "-",
                                        isRowHeader: true
                                      },
                                      {
                                        id: "alt",
                                        header: "Alias",
                                        cell: item => item[PROCESS_KEYS.UPLOADER_KEYS.alias] || "-",
                                        sortingField: "alt"
                                      }
                                    ]}
                                    items={getValue(PROCESS_KEYS.uploaders)}
                                    loadingText="Loading uploaders details"
                                    sortingDisabled
                                    empty={
                                      <Box
                                        margin={{ vertical: "xs" }}
                                        textAlign="center"
                                        color="inherit"
                                      >
                                        <SpaceBetween size="m">
                                          <b>No uploaders yet</b>
                                        </SpaceBetween>
                                      </Box>
                                    }
                                    variant='embedded'
                                  /> : ''}
                                </div>
                            }

                          </SpaceBetween>
                        </div>

                      {isEditMode ?
                        <>
                          <div>
                            <Box variant="h4">&nbsp;</Box>
                            <SpaceBetween direction="horizontal" size="s">
                              <SpaceBetween direction="horizontal" size="s">
                              <CustomCheckbox field={PROCESS_KEYS.sox_approved} getValue={getValue} updateValue={updateValue} text="Enable 2 touch approval" disabled={true} />
                              <Button  variant="inline-link" onClick={()=>{setToolsOpen(!toolsOpen)}}>info</Button>
                              </SpaceBetween>
                              {
                                processDataEdited[PROCESS_KEYS.sox_approved]?
                                <Box>
                                <Textarea
                                  class="Notes"
                                  onChange={({ detail }) => updateValue(PROCESS_KEYS.sox_evidence, detail.value)}
                                  value={getValue(PROCESS_KEYS.sox_evidence)}
                                  placeholder="notes"
                                />
                              </Box>:''
                              }
                            </SpaceBetween>
                            <CustomCheckbox field={PROCESS_KEYS.approval_required} getValue={getValue} updateValue={updateValue} text="Approval required" disabled={true} />
                            <CustomCheckbox field={PROCESS_KEYS.critical_data} getValue={getValue} updateValue={updateValue} text="Critical data" disabled={!isNewProcess} /> 
                            <Link external href="https://policy.a2z.com/docs/97/publication">Click here to review Amazon policy on Data Classification Standard</Link>
                            <br/>
                            <br/>
                            {dataClasificationContent}
                          </div></> : <>

                          {dataClasificationContent}
                          </>}
                    </ColumnLayout>
                  </SpaceBetween>
              </SpaceBetween>

            </Container>
          </SpaceBetween>
          <SpaceBetween size="m">
            <Box>
              {errorMessage && (
                <Box variant="p" color="text-status-error">
                  {errorMessage}
                </Box>
              )}
            </Box>
            {
              (isNewProcess && canAdd) ?
                <div className="buttons">
                  <SpaceBetween direction="horizontal" size="l">
                    <Button variant="primary" onClick={addProcessDetails}
                    disabled={!enableEditAction} >Save</Button>
                  </SpaceBetween>
                </div>
                :
                ''
            }
            {
              !isNewProcess && isEditMode ?
                <SpaceBetween direction="vertical" size="l">
                  <Textarea 
                  class="description"
                  onChange={({ detail }) => setUpdateComments(detail.value)}
                  value={updateComments}
                  placeholder="Comments"
                  ></Textarea>
                  <div className="buttons">
                  <SpaceBetween direction="horizontal" size="l">
                    <Button variant="primary" onClick={updateProcessDetails} disabled={!enableEditAction} >Update</Button>

                    <Button onClick={cancelEdition}>Cancel</Button>
                  </SpaceBetween>
                </div>
                </SpaceBetween>
                :
                ''
            }
          </SpaceBetween>

          <br />
          {
            !isNewProcess && !isEditMode ?
              <SpaceBetween size="l" >
                <Feedback appId={id} pageId={`logico_process_${id}_${lpid}`} userId={userId} parentId={"null"} level={0} limit={1}></Feedback>
              </SpaceBetween> : ''
          }
        </ContentLayout>}
        
 
    </>
  )

  const helpContent = (
    <HelpPanel header={<Box variant="h2">Glossary</Box>}>
        <Box>
        <SpaceBetween direction="horizontal" size="s">
          {/* <Box variant="strong">2 touch approval</Box> */}
          {/* <Box>Check the box if the use case is 'Enable 2 touch approval' for the two touch approval workflow for SOX purposes or for other regulatory purposes (such as statutory disclosure generation or publicly published balances such as TTC).</Box> */}
          <h4>2 touch approval required if</h4>
          <ul>
            <li>a) Ledger Impact = Primary Ledger (US GAAP) OR Secondary Ledger (Local GAAP) OR Both Primary and Secondary Ledger (US GAAP/Local GAAP/IFRS) AND</li>
            <li>b) Financial Impact &gt; $500MM</li>
          </ul>
          <h4>2 touch approval NOT required if</h4>
          <ul>
            <li>a) Ledger Impact = Other regulations OR Operational AND</li>
            <li>b) Financial Impact &lt; $500MM</li>
          </ul>
        </SpaceBetween>
        </Box>
    </HelpPanel>
);


  return (
    <AppLayout
      disableContentPaddings={true}
      content={Content}
      navigation={<SideNav activeHref={ lpid ? `/App/${id}/LogicoProcess/${lpid}` : `/App/${id}/LogicoProcess`} id={id} lpid={lpid} process={processData} back={backUrl}/>}
      contentType="default"
      navigationOpen={NavOpen}
      onNavigationChange={() => {
        setNavOpen(!NavOpen);
      }}
      tools={helpContent}
      toolsOpen={toolsOpen}
      onToolsChange={() => { setToolsOpen(!toolsOpen) }}
      headerVariant="high-contrast"
    />
  );
};