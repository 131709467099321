import React, {useState, useEffect} from 'react';

import SideNavigation from "@amzn/awsui-components-react/polaris/side-navigation";
import { useHistory } from "react-router-dom";

export default (props) => {
  const [activeHref, setActiveHref] = useState(props.activeHref);
  const [sideHeader, setSideHeader] = useState(props.sideHeader);
  const [headerHref, setHeaderHref] = useState(props.headerHref);
  const [back, setBack] = useState(props.back);

  const history = useHistory();

  const items = [
    
    {
        "type": "link",
        "text": "Back",
        "href": back
    },

    ];

  useEffect(() => {
    setSideHeader(props.sideHeader)
  },[props.sideHeader])

  useEffect(() => {
    setBack(props.back)
  },[props.back])

  useEffect(() =>{
    setHeaderHref(props.headerHref)
  },[props.headerHref])

  useEffect(() =>{
    setActiveHref(props.activeHref)
  },[props.activeHref])
  
  return (
    <SideNavigation
      header={{ href: `${headerHref}`, text: `${(sideHeader) ? sideHeader : ''}` }}
      items={items}
    />
  );
}