import { getAccessLevel, getAccessLevelCorpReporting } from "src/checkPageAccess"
import { DELETE_APP_ACCESS_LEVELS, EDIT_APP_ACCESS_LEVELS, ADD_APP_ACCESS_LEVELS, ADMIN_ACCESS_LEVELS, CORP_REPORTING_ADMIN_ACCESS_LEVELS } from 'src/constants/AppConstants'

export const canEditApplication = async (userId, templateId, currentStage) => {
    let access = await getAccessLevel(userId, templateId, currentStage)
    return EDIT_APP_ACCESS_LEVELS.includes(access?.appAccessLevel)
}

export const canDeleteApplication = async (userId, templateId, currentStage) => {
    let access = await getAccessLevel(userId, templateId, currentStage)
    return DELETE_APP_ACCESS_LEVELS.includes(access?.appAccessLevel)
}

export const canAddEditDeleteApplication = async (userId, templateId, currentStage) => {
    let access = await getAccessLevel(userId, templateId, currentStage)
    return { add: ADD_APP_ACCESS_LEVELS.includes(access?.appAccessLevel), 
        edit: EDIT_APP_ACCESS_LEVELS.includes(access?.appAccessLevel), 
        delete: DELETE_APP_ACCESS_LEVELS.includes(access?.appAccessLevel) }
}

export const isCorpAdmin = async (userId, appId, currentStage) => {
    let access = await getAccessLevelCorpReporting(userId, appId, currentStage)
    return { admin: CORP_REPORTING_ADMIN_ACCESS_LEVELS.includes(access?.appAccessLevel) }
}