export const months = [
    { label: "JAN", value: "JAN", disabled: false },
    { label: "FEB", value: "FEB", disabled: false },
    { label: "MAR", value: "MAR", disabled: false },
    { label: "APR", value: "APR", disabled: false },
    { label: "MAY", value: "MAY", disabled: false },
    { label: "JUN", value: "JUN", disabled: false },
    { label: "JUL", value: "JUL", disabled: false },
    { label: "AUG", value: "AUG", disabled: false },
    { label: "SEP", value: "SEP", disabled: false },
    { label: "OCT", value: "OCT", disabled: false },
    { label: "NOV", value: "NOV", disabled: false },
    { label: "DEC", value: "DEC", disabled: false },

]

export const years = [
    { label: "22", value: "22", disabled: false },
    { label: "23", value: "23", disabled: false },
    { label: "24", value: "24", disabled: false },
    { label: "25", value: "25", disabled: false },


]

export const ted_regions = [
    { label: "All", value: "All" },
    { label: "LATAM_South", value: "LATAM_South" },
    { label: "LATAM_North", value: "LATAM_North" },
    { label: "APAC", value: "APAC" }
]

export const ted_insert_or_delete_regions = [
    { label: "LATAM_South", value: "LATAM_South" },
    { label: "LATAM_North", value: "LATAM_North" },
    { label: "APAC", value: "APAC" }
]

export const select_default_value_all = { label: "All", value: "All" }

export const ted_periods = [
    { label: "All", value: "All" },
    { label: "01-MAY-2023", value: "01-MAY-2023" },
    { label: "01-APR-2023", value: "01-APR-2023" },
    { label: "01-MAR-2023", value: "01-MAR-2023" }
]

export const pre_check_status = [
    { label: "All", value: "All" },
    { label: "Passed", value: "Passed" },
    { label: "Failed", value: "Failed" }
]


export const ofaStage = { label: "PROD", value: "PROD" }

export const AWSoptions = [{ label: "Non-AWS-Only" }, { label: "AWS-Only" }, { label: "All" }]

export const reportsDashboardLink = {
    "beta": "https://us-east-1.quicksight.aws.amazon.com/sn/dashboards/c32b20a9-a651-4d48-b5c2-54cf1625dc9b",
    "prod": ""
}

export const PROCESS_KEYS = {
    mapping_id: "mapping_id",
    group_id: "group_id",
    next_step: "next_step",
    process_id: "process_id",
    app_id: "app_id",
    process_type: "process_type",
    description: "description",
    created_time_stamp: "created_time_stamp",
    last_updated_time_stamp: "last_updated_time_stamp",
    created_by: "created_by",
    last_updated_by: "last_updated_by",
    status: "status",
    glue_job_name: "glue_job_name",
    step_function_name: "step_function_name",
    process_name: "process_name",
    approval_required: "approval_required",
    sox_approved: "sox_approved",
    critical_data: "critical_data",
    sox_evidence: "sox_evidence",
    action: "action",
    file_types: "file_types",
    team: "team",
    business: "business",
    dataset_name: "dataset_name",
    multipart_allowed: "multipart_allowed",
    approvers: "approvers",
    uploaders: "uploaders",
    environment: "environment",
    clear_table_data_on_update: "clear_table_data_on_update",
    schema: "schema",
    label: "label",
    redshift_table_name: "redshift_table_name",
    APPROVER_KEYS: {
        alias: "alias",
        type: "type"
    },
    UPLOADER_KEYS: {
        alias: "alias",
        type: "type"
    },
    SCHEMA_KEYS: {
        column_name: "column_name",
        data_type: "data_type",
        mandatory: "mandatory",
        scale: "scale",
        precision: "precision",
        length: "length",
        last_updated_time_stamp: "last_updated_time_stamp",
        delsert_key: "delsert_key",
        column_index: "column_index",
        prev_column_name: "prev_column_name"

    },
    process_id: "process_id",
    app_id: "app_id",
    process_type: "process_type",
    description: "description",
    created_time_stamp: "created_time_stamp",
    last_updated_time_stamp: "last_updated_time_stamp",
    created_by: "created_by",
    last_updated_by: "last_updated_by",
    status: "status",
    glue_job_name: "glue_job_name",
    step_function_name: "step_function_name",
    process_name: "process_name",
    approval_required: "approval_required",
    sox_approved: "sox_approved",
    sox_evidence: "sox_evidence",
    action: "action",
    file_types: "file_types",
    team: "team",
    business: "business",
    dataset_name: "dataset_name",
    multipart_allowed: "multipart_allowed",
    approvers: "approvers",
    uploaders: "uploaders",
    environment: "environment",
    schema: "schema",
    label: "label",
    redshift_table_name: "redshift_table_name",
    APPROVER_KEYS: {
        alias: "alias",
        type: "type",
        column_index: "column_index"
    },
    UPLOADER_KEYS: {
        alias: "alias",
        type: "type"
    },
    SCHEMA_KEYS: {
        column_name: "column_name",
        data_type: "data_type",
        mandatory: "mandatory",
        scale: "scale",
        precision: "precision",
        length: "length",
        last_updated_time_stamp: "last_updated_time_stamp",
        delsert_key: "delsert_key",
        column_index: "column_index",
        prev_column_name: "prev_column_name"
    },
    CUSTOM_ATTRIBUTES_KEYS: {
        sub_type: 'sub_type',
        attribute: 'attribute',
        attribute_type: 'attribute_type'
    },
    custom_attributes: 'custom_attributes'
}

export const FRISCO_DROPDOWN_METADATA_KEYS = {
    TEAMS: 'Frisco process teams',
    BUSINESSES: 'Frisco process businesses',
    ACTIONS: 'Frisco process actions',
    FILE_TYPES: 'Frisco process file types',
    ENVIRONMENTS: 'Frisco process environments',
    QUARTER: 'Quarter',
}

export const APPROVER_TYPES = {
    APPROVER: "APPROVER",
    APPROVER_LDAP: "APPROVER_LDAP"
}

export const UPLOADER_TYPES = {
    UPLOADER: "UPLOADER",
    UPLOADER_LDAP: "UPLOADER_LDAP"
}


export const FRISCO_STATUS = {
    REDSHIFT_LOAD_COMPLETE: "Redshift Load Complete",
    REDSHIFT_LOAD_FAILED: "Redshift Load Failed",
    COMPLETED: "File Loaded to FAST Redshift",
    COMPLETE_NOTIFIED: "User Notified for FAST Redshift Load",
    FAILED: "File Upload Failed",
    APPROVAL_PENDING: "File Approval Pending",
    APPROVED: "File Approval Complete",
    PROCESS_INITIATED: "File Upload Process Initiated",
    UPLOADED: "File Uploaded",
    PREPARING_DATA_FOR_APPROVAL: "Preparing Data for Admin Approval",
    DATA_PREPARED_FOR_APPROVAL: "Data Prepared for Admin Approval",
    ADMIN_APPROVAL_PENDING: "Admin Approval Pending",
    ADMIN_APPROVAL_COMPLETE: "Admin Approval Complete",
    ADMIN_APPROVAL_REJECTED: "Admin Approval Rejected",
    DATA_PREPARING_FOR_APPROVAL_FAILED: "Data Preparation Failed for Admin Approval",
    DATA_PREPARING_FOR_APPROVAL: "Data Preparation for Admin in Progress",
    MOVED_TO_FDV: "Data Copied to FDV",
    APPROVAL_CANCELLED: "File Approval Cancelled",
    APPROVAL_DENIED: "File Approval Denied",
    SHARED_TO_CATALOG: "Shared to catalog",
    CATALOG_DATA_WRITE_COMPLETE: "Data Write to Glue Catalog Complete",
    REPORTING_CENTRAL_APPROVAL_STATUS_SUCCESS: "Reporting status approval success",
    REPORTING_CENTRAL_APPROVAL_STATUS_PENDING: "Reporting status approval pending",
    SHARING_TO_CATALOG: "Sharing to catalog",
    REPORTING_CENTRAL_APPROVAL_STATUS_REQUESTED: "Reporting status approval requested"
}

export const FRISCO_TRACK_CURRENT_STATUS = {
    RELOAD_INPUT_FILE_APPROVAL_DENIED: "File Approval Denied Reload Input",
    RELOAD_INPUT_ADMIN_APPROVAL_DENIED: "Admin Approval Denied Reload Input",
    POST_WB: "Reload input file post WB",
    UPLOAD_INPUT_FILE: "Load input data",
    ALLOW_POST_WB_INPUT_FILE: "Allow post WB input file"
}

export const FRISCO_TRACK_OVERALL_STATUS = {
    INCOMPLETE: "INCOMPLETE"
}

export const FRISCO_TABLE_STATUS = {
    CREATING_TABLE: 'Creating table',
    TABLE_SCHEMA_UPDATED: 'Table Schema Updated',
    TABLE_DROPPED: 'Table Dropped',
    TABLE_CREATED: 'Table Created',
    TABLE_CREATION_FAILED: 'Table Creation Failed',
    TABLE_SCHEMA_UPDATE_FAILED: 'Table Schema Update Failed',
    TABLE_DROP_FAILED: 'Table Drop Failed',
    UPDATE_IN_PROGESS: 'Update In Progress'
}

export const FRISCO_POSITIVE_STATUS = [FRISCO_TABLE_STATUS.TABLE_SCHEMA_UPDATED, FRISCO_TABLE_STATUS.TABLE_DROPPED, FRISCO_TABLE_STATUS.TABLE_CREATED, 'Data Write to Glue Catalog Complete']
export const FRISCO_NEGATIVE_STATUS = [FRISCO_TABLE_STATUS.TABLE_CREATION_FAILED, FRISCO_TABLE_STATUS.TABLE_SCHEMA_UPDATE_FAILED, FRISCO_TABLE_STATUS.TABLE_DROP_FAILED, 'Data Write to Glue Catalog Failed']

export const FRISCO_NEGATIVE_STATUS_FOR_UPLOAD = [FRISCO_TABLE_STATUS.CREATING_TABLE, FRISCO_TABLE_STATUS.TABLE_CREATION_FAILED, FRISCO_TABLE_STATUS.TABLE_DROPPED, FRISCO_TABLE_STATUS.UPDATE_IN_PROGESS]
