import { createSlice } from "@reduxjs/toolkit";



const initialState = {
    BoffinComputeId    : '',
    BoffinRulesMode : 'New',
    BoffinRulesWarnings: 'Rule',
    BoffinRulesWarningsSortOrder: 'R001',
}

const userBoffinSlice = createSlice({
    'name': 'userBoffin',
    initialState,
    reducers:{
        setCurrentComputeId(state, action){
            state.BoffinComputeId = action.payload
        },
        setBoffinRulesMode(state, action){
            state.BoffinRulesMode = action.payload
        },
        setBoffinRulesWarnings(state, action){
            state.BoffinRulesWarnings = action.payload
        },
        setBoffinRulesWarningsSortOrder(state, action){
            state.BoffinRulesWarningsSortOrder = action.payload
        }
    }
})

export const { setCurrentComputeId, setBoffinRulesMode, setBoffinRulesWarnings, setBoffinRulesWarningsSortOrder   } = userBoffinSlice.actions

export default userBoffinSlice.reducer
