import React, { useState, useEffect, useRef } from "react";

import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";

import {
  AppLayout,
  BreadcrumbGroup,
  HelpPanel,
  Icon,
  Box,
  SpaceBetween,
  Container,
  Header,
  ColumnLayout,
  ExpandableSection,
  Button,
  Spinner,
  Alert,
  Tabs,
} from "@amzn/awsui-components-react";
import axios from "axios";

import SideNav from "./SideNav";
import appSettings from "./app_settings";
import { reportsDashboardLink } from "./Constants";

import * as d3 from "d3";

import { AgGridReact } from "ag-grid-react";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";

import { setCurrentAppId } from "../../store/userAuthSlice";

import checkPageAccess from "../../checkPageAccess";
import AccessDenied from "../../AccessDenied";
import WaitForPageAccess from "../../WaitForPageAccess";
import { Storage } from "aws-amplify";
import {numberFilterParams, dateFilterParams} from '../../utilities/Factory'
import {Board, BoardItem} from "@amzn/awsui-board-components";
import { boardI18nStrings, boardItemI18nStrings } from 'src/i18n-strings/board-I18nStrings';



const PostingSummary = () => {
  const history = useHistory();
  const currentAppId = useSelector(
    (globalState) => globalState.auth.currentAppId
  );
  const isCurrentAppAuthorised = useSelector(
    (globalState) => globalState.auth.isCurrentAppAuthorized
  );
  const [isCheckingAccess, setIsCheckingAccess] = useState(true);
  const [isAuthorized, setIsAuthorized] = useState();
  const dispatch = useDispatch();
  const gridRef = useRef();
  const [dsInfo, setDsInfo] = useState([]);
  const [detailedDSInfo, setDetailedDSInfo] = useState([]);
  const [showLoadingIcon, setShowLoadingIcon] = useState(true);
  const [boardItems, setBoardItems] = useState([
    { id: "1", rowSpan: 5, columnSpan: 6,data:{}}
  ]);

  useEffect(() => {
    if (currentAppId !== appSettings.appId) {
      dispatch(setCurrentAppId(appSettings.appId));
    }
    setIsAuthorized(isCurrentAppAuthorised);
    loadDatasetsInfo();

    return () => {
      // this now gets called when the component unmounts
    };
  }, [ isCurrentAppAuthorised]);

  useEffect(() => {
    if(isAuthorized === true || isAuthorized === false){
      setIsCheckingAccess(!isCheckingAccess);
    } 
  }, [isAuthorized]);

  const breadItems = [
    { text: "Home", href: "/Home" },
    { text: "ATeam BI", href: "/ATeamBIHome" },
    { text: "Boffin", href: "/BoffinHome" },
    { text: "Posting Summary", href: "/" },
  ];

  const onFollowHandler = (ev) => {
    ev.preventDefault();
    if (ev.detail.href) {
      history.push(ev.detail.href.substring(1));
    }
  };
  const helpContent = (
    <HelpPanel
      footer={
        <div>
          <h3>
            Learn more <Icon name="external" />
          </h3>
          <Box>
            <span>
              <Icon variant="subtle" name="external"></Icon> &nbsp;
              <a href="https://w.amazon.com/bin/view/ProjectBoffin/FAQ/#HFAQ-Processing2FPostingtoOFA">
                FAQ
              </a>
            </span>
          </Box>
        </div>
      }
      header={
        <div>
          <div></div>
          <h2>Additional Info </h2>
        </div>
      }
    >
      <Box variant="small">
        <ul>
          <li>GLAP = General Ledger with AP details</li>
          <li>GLTD = General Ledger with Transaction details</li>
        </ul>
      </Box>
      <Box></Box>
    </HelpPanel>
  );

  const loadDatasetsInfo = async () => {
    setShowLoadingIcon(true)
    let folder = "Boffin/datasets/";
    let fn = folder + "posting_summary.csv";
    const fileURL = await Storage.get(fn, { level: "public", expires: 60 });
    let csvdata = await d3.csv(fileURL).then((result) => {
      setDsInfo(result);
      // setState here..
    });
    setShowLoadingIcon(false)
  };

  const DownloadInfo = async () =>{

    let folder = 'Boffin/datasets/'
    let fn = folder + 'posting_summary.csv'
    const fileURL = await Storage.get(fn, { level: 'public', expires: 60});
    window.open(fileURL);

  }

  const defaultColDef = {
    // set filtering on for all columns
    filter: true,
    filterParams: {
      buttons: ["reset"],
      debounceMs: 200,
    },
  };

  const [columnDefs] = useState([

    { field: 'periodname' ,width: '160px', resizable: true, sortable: true,headerName: "Period Name" },
    { field: 'id' ,width: '160px', resizable: true, sortable: true,headerName: "Id" },
    { field: 'company' ,width: '160px', resizable: true, sortable: true,headerName: "Company" },
    { field: 'location' ,width: '160px', resizable: true, sortable: true,headerName: "Location" },
    { field: 'costcenter' ,width: '160px', resizable: true, sortable: true,headerName: "Cost Center" },
    { field: 'project' ,width: '160px', resizable: true, sortable: true,headerName: "Project" },
    { field: 'account' ,width: '160px', resizable: true, sortable: true,headerName: "Account" },
    { field: 'dataset' ,width: '160px', resizable: true, sortable: true,headerName: "Dataset" },
    { field: 'journalname' ,width: '160px', resizable: true, sortable: true,headerName: "Journal Name" },
    { field: 'linedescription' ,width: '160px', resizable: true, sortable: true,headerName: "Line Description" },
    { field: 'JE_account' ,width: '160px', resizable: true, sortable: true,headerName: "JE Account" },
    { field: 'final_date_for_comparison' ,width: '160px', resizable: true, sortable: true,headerName: "Final Date For Comparison",
    filter: 'agDateColumnFilter', filterParams: dateFilterParams },
    { field: 'dep_in_months' ,width: '160px', resizable: true, sortable: true,headerName: "Dep In Months" },
    {field:'final_maj_cat' ,width: '150px', sortable: true, resizable: true },
    { field: 'reference_id' , width: '160px',  sortable: true, resizable: true, headerName: "Reference Id"},
    { field: 'invoicenumber' ,width: '160px', resizable: true, sortable: true,headerName: "Invoice Number" },
    { field: 'suppliernumber' , width: '160px', sortable: true, resizable: true, headerName: "Supplier Number"},
    { field: 'ponumber' ,width: '160px', resizable: true, sortable: true,headerName: "PO Number" },
    { field: 'debit' ,width: '160px', resizable: true, sortable: true,headerName: "Debit",
    filterParams: numberFilterParams, comparator: (a,b) => (customSort(a,b)) },
    { field: 'credit' ,width: '160px', resizable: true, sortable: true,headerName: "Credit",
    filterParams: numberFilterParams, comparator: (a,b) => (customSort(a,b)) },
    {field:'dep_amount' , width: '150px',sortable: true, resizable: true, filterParams: numberFilterParams, comparator: (a,b) => (customSort(a,b))},
    { field: '' }
     
  ]);

  const customSort = (a,b) => {
    const numA = Number(a.replaceAll(",",""))
    const numB = Number(b.replaceAll(",",""))
    if(numA === numB) return 0;
    return (numA > numB) ? 1: -1;
}

  const content = (
    <div>
      {isAuthorized ? (
        <Box>
          <Container
            header={
              <Header
                actions={
                  <SpaceBetween direction="horizontal" size="xs">
                    {/* <Button variant='normal' onClick={()=>openButtonClick()}>View Details</Button> */}
                    {showLoadingIcon ? (
                      <Box>
                        <Spinner size="normal"></Spinner>
                      </Box>
                    ) : (
                      <Box></Box>
                    )}
                    <Button
                      variant="normal"
                      iconName="download"
                      onClick={() => DownloadInfo()}
                    >
                      Download
                    </Button>
                  </SpaceBetween>
                }
                description="The following table shows the Posting Summary."
              >
                Posting Summary
              </Header>
            }
          >
            <Board
              renderItem={(item) => (
                <BoardItem i18nStrings={boardItemI18nStrings}>
                  <div
                    className="ag-theme-alpine"
                    style={{ height: "92%", width: "100%" }}
                  >
                    <AgGridReact
                      ref={gridRef}
                      rowData={dsInfo}
                      defaultColDef={defaultColDef}
                      columnDefs={columnDefs}
                      rowSelection="single"
                      animateRows={true}
                      rowHeight={30}
                      enableCellTextSelection={true}
                    ></AgGridReact>
                  </div>
                </BoardItem>
              )}
              onItemsChange={(event) => setBoardItems(event.detail.items)}
              items={boardItems}
              i18nStrings={boardI18nStrings}
            />
          </Container>
          <br />
        </Box>
      ) : isCheckingAccess ? (
        <div>
          <WaitForPageAccess></WaitForPageAccess>
        </div>
      ) : (
        <div>
          <AccessDenied appName={appSettings.appName}></AccessDenied>
        </div>
      )}
    </div>
  );

  return (
    <AppLayout
      breadcrumbs={
        <BreadcrumbGroup
          items={breadItems}
          onFollow={(event) => onFollowHandler(event)}
        ></BreadcrumbGroup>
      }
      content={content}
      navigation={<SideNav activeHref="/BoffinPostingSummary" />}
      headerSelector="#TopBar"
      navigationHide={false}
      toolsHide={true}
      maxContentWidth = {Number.MAX_VALUE}
      headerVariant="high-contrast"
    ></AppLayout>
  );
};

export default PostingSummary;
