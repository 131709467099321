
export interface UserAuthContext {
  Alias: string;
  DisplayName: string;
  GivenName: string;
  Email: string;
  error: string;
  JobCode: string,
  Description: string,
  AmznDeptName: string,
  EmployeeId: string,
  userAuthDataLoadingStatus: LoadingStatus,
  USER_LDAP_GROUPS: string
}

export interface SecretKeyConfig {
  environment: string;
  secretARN: string;
}

export enum LoadingStatus {
  NotInitiated = 'Not Initiated',
  Loading = 'Loading',
  Completed = 'Completed',
  Failed = 'Failed'
}

export interface graphQLConfig {
  environment: string;
  secretName: string;
  grapQLEndPoint: string
}

export interface s3Config {
  environment: string;
  bucket: string;
  region: string;
}

export interface APIGateWayConfig {
  environment: string;
  ApiGateWayEndpoint: string;
}

export interface QuickSightDashboardConfig {
  environment: string;
  url: string;
}

export interface StepFunctionConfig {
  environment: string;
  arn: string;
}

export interface GlueJobConfig {
  environment: string;
  name: string;
}