export const headings = {

    "JournalPostings" : "Journal Postings",
    "PoliciesAndProcedures" : "Policies & Procedures",
    "us-gapp": "US GAAP",
    "FunctionalAccountingClose": "Functional Accounting Close",
    "StationaryAccountingClose" : "Stationary Accounting Close",
    "Analytics" : "Analytics",
    "Reporting" : "Reporting"

}

export const toolsData = 
[
    {
      "tool": "JournalPostings",
      "app": "Scribe - Manual Journal Entry",
      "description": "Over 1000+ Amazonians across Accounting, Finance, Tax, and FinOps prepare, validate and book an average of 17K+ MJEs each month in Oracle General Ledger via a third-party tool called Oracle Web Application Desktop Integrator (WebADI). The dependency on this third-party sub optimal tool with limited features and customization options, causes users to spend additional hours during the close critical timelines. Scribe is a self-service tool that will replace the Oracle Web ADI tool and streamlines the Journal upload process and enriches the user experience in preparing and upload the manual Journal Entries and eliminates pain points with OFA Web ADI tools",
      "buttons": [
        {
          "label": "visit app",
          "url": "https://scribe.fintech.amazon.dev/"
        },
        {
          "label": "wiki page manual journal entry",
          "url": "https://w.amazon.com/bin/view/FinanceTechnology/FinTechScribe/Home/"
        }
      ]
    },
    {
      "tool": "JournalPostings",
      "app": "WebMec",
      "description": "WebMEC provides an ecosystem to automate and simplify month and close activities. It is an end to end journal automation product that pulls data, calculates journal entries, supports analysis and review, and posts to OFA. WebMEC provides 1) automation for accruals, reclasses, and allocations, 2) Review and approval flows, 3) Validation 4) Posting to OFA, and 5) GL reviewing, and 6) uploading of offline journal entries. WebMEC is a proven tool for CAPEX and OPEX close activities and brings a high level of controllership and consistency to the use cases it delivers. The product is Anvil and SOX certified and designed to solve major deficiencies in month end close processes. The product is extensible to new geographies and use cases. In 2021, WebMEC facilitated $4.2B in OPEX accruals and in Q3 of 2022 it will begin calculating CAPEX accruals for real estate and start up accruals",
      "buttons": [
        {
          "label": "visit app",
          "url": "https://w.amazon.com/bin/view/WWCFT/Products/WebMEC/ProdOpsDashboard"
        },
        {
          "label": "webMec wiki page",
          "url": "https://w.amazon.com/bin/view/WWCFT/Products/WebMEC/Home/"
        }
      ]
    },
    {
      "tool": "JournalPostings",
      "app": "Payroll Badge",
      "description": "Global Bonus Amortization and Deferral Engine (BADGE) team manages global employee bonus deferment, amortization and accounting. BADGE will be the authoritative source for bonus amortization and sub-ledger for bonus accounting entries. BADGE delivers automation of accounting entries, provides accurate automated amortization calculations, and provides analytical capabilities on deferred bonus and related activity. BADGE will deliver the following key functions: (a) Compute monthly bonus amortizations based on the bonus earning schedules, (b) Re-class deferred bonus upon employee transfers across companies (c) Allocate bonus amortization based on employee company, cost center and location (d) Transfer deferred bonus remaining balance into bonus expense account upon employee termination or bonus deletion in PeopleSoft (e) Book bonus accrual in case of bonus disbursement delay and (f) generate and record accounting entries for all the above events.",
      "buttons": [
        {
          "label": "Payroll Badge Wiki",
          "url": "https://w.amazon.com/bin/view/AccountingAutomation/BADGE"
        }
      ]
    },
    {
      "tool": "JournalPostings",
      "app": "Spectra",
      "description": "Spectra is a FinTech designed application, which allows the automation of allocation entries among all 7-segments, to facilitate accurate distribution of expenses among all sites and businesses.",
      "buttons": [
        {
          "label": "visit app",
          "url": "https://website.prod.iad.spectra.fintech.amazon.dev/#/"
        },
        {
          "label": "wiki page",
          "url": "https://w.amazon.com/bin/view/FinanceTechnology/Spectra/"
        },
        {
          "label": "spectra training",
          "url": "https://w.amazon.com/bin/view/FinanceTechnology/Spectra/Onboarding"
        }
      ]
    },
    {
      "tool": "JournalPostings",
      "app": "ACE",
      "description": "The Accrual Central, which will provide one centralized end to end accrual booking application for accounting and finance teams. We will provide the ability to define, edit and review custom mapping and business rules for the transformation of multiple accrual source data types. We will enable ingestion and transformation of these source data types resulting in the generation, validation and posting of accrual journal entries to GL. Our goal is to build end to end automated solutions which massively reduce or eliminate manual processes and improve controllership.",
      "buttons": [
        {
          "label": "visit app",
          "url": "https://prod.ace.fintech.a2z.com/#/"
        },
        {
          "label": "wiki page",
          "url": "https://prod.ace.fintech.a2z.com/#/"
        },
        {
          "label": "Ace setup",
          "url": "https://w.amazon.com/bin/view/FinanceTechnology/ACE/UseCaseSetupGuide"
        }
      ]
    },
    {
      "tool": "JournalPostings",
      "app": "Intercompany MJE Pillar",
      "description": "he Intercompany MJE tool is an Amazon-built application for posting manual journal entries specifically for intercompany transactions. It replaces the 3rd party Virtual Trader MJE tool. Improvements include an intuitive UI, better and more understandable error checking/messaging, addition of an intercompany transaction identifier and other key attributes to aid in matching/reporting, automation of some indirect tax calculations and journal line creation, and capturing of more reporting attributes via integrations with other Amazon systems. Accounting and Tax partners use Intercompany MJE to save significant labor hours and receive timely insight into intercompany manual journal entries",
      "buttons": [
        {
          "label": "visit app",
          "url": "https://w.amazon.com/bin/view/Manual_Journal_Entry_1.0_Tool/"
        },
        {
          "label": "wiki page",
          "url": "https://w.amazon.com/bin/view/Intercompany_Accounting/ICSE/"
        },
        {
          "label": "MJE trainings",
          "url": "https://w.amazon.com/bin/view/Manual_Journal_Entry_1.0_Tool/Overview"
        }
      ]
    },
    {
      "tool": "JournalPostings",
      "app": "Otto",
      "description": "FinTech Otto will enable Amazon FGBS to scale automation of MJEs of all categories in one place. With Otto, Accounting and Finance users can self-service processes required to record, review and post journal entries for all MJE categories during financial close. With its centralized journal automation, self-service capabilities, and direct integration with reconciliation tools, Otto enables Accountants and Financial Analysts to automate over 10K MJEs monthly and eliminate over 79K hours of manual effort annually",
      "buttons": [
        {
          "label": "visit app",
          "url": "https://prod.otto.ats.fintech.amazon.dev/#/"
        },
        {
          "label": "wiki page",
          "url": "https://w.amazon.com/bin/view/FinanceTechnology/Otto/#"
        },
        {
          "label": "otto access",
          "url": "https://w.amazon.com/bin/view/FinanceTechnology/Otto/UserGuide#temp:C:SZIca79c72d11df4e28878463b4"
        }
      ]
    },
    {
      "tool": "Reporting",
      "app": "Tango (Stores Lease Invoices)",
      "description": "Tango will manage leases and execute rent payments across 56 countries for AWS, GREF & OPS business line. The goal of this project it to onboard Tango onto External Party Integration (EPIC) for Invoice processing in Oracle Financial Application (OFA) - We will use Amazon link to retrieve files from Tango server. We have setup Amazon link for pulling Tango files and shared SSH keys with Tango which will be used for secure file transfer between two servers. Amazon link will continuously ping Tango server and pull the file once file is available on Tango server at a designated folder. After pulling file amazon link will delete the file in that folder and place the file on our S3 bucket. - Once file is placed on S3 bucket an SQS event is generated and this triggers file processing. We use JSON parser to parse the file and retrieve the required attributes. - Validation and null check is done for mandatory header attributes. - Validation and null check is done for mandatory line attributes. - These attributes are then passed to CIP's Invoice header and Invoice line class. - CIP perform some processing and call MINT API to send payload to OFA. - In MINT we will use AP invoice API to post these invoices to OFA. - We also pass back any response by creating a s3 object with response content and pass it back to Tango using sedi.",
      "buttons": [
        {
          "label": "visit app",
          "url": "https://amzn.tangoanalytics.com/tmcadfv01/faces/login.jspx"
        },
        {
          "label": "wiki page",
          "url": "https://w.amazon.com/bin/view/FinanceAutomation/Team_FDI/Projects/Tango/"
        }
      ]
    },
    {
      "tool": "Reporting",
      "app": "W Desk",
      "description": "Wdesk is a cloud-based enterprise software-as-a-service platform that enables companies to collect, manage, report and analyse critical business data in real time. It is also used tomanage and file financial and compliance documents to regulatory agencies.",
      "buttons": [
        {
          "label": "visit app",
          "url": "https://www.compliance-central.fintech.amazon.dev/"
        },
        {
          "label": "W Desk trainings",
          "url": "https://w.amazon.com/bin/view/Sec-Reporting/Resources/Workiva/"
        }
      ]
    },
    {
      "tool": "Reporting",
      "app": "Compliance Central",
      "description": "Compliance Central is a self-serve product that enables Accounting and Finance users to generate reports, through a rich UI, encapsulating tech heavy-lifting behind the scenes. Compliance Central’s vision is to be end to end hub for Amazon Finance and Accounting needs. The platform is certified to handle red data and team leads can easily grant and restrict access to their reports via native Amazon tools such as Teams",
      "buttons": [
        {
          "label": "visit app",
          "url": "https://www.compliance-central.fintech.amazon.dev/#/reporting"
        },
        {
          "label": "wiki page",
          "url": "https://w.amazon.com/bin/view/Accounting_BI/Statutory_and_Compliance/Compliance_Central/"
        },
        {
          "label": "user guide",
          "url": "https://w.amazon.com/bin/view/Accounting_BI/Statutory_and_Compliance/Compliance_Central/User_Guide"
        }
      ]
    },
    {
      "tool": "Reporting",
      "app": "Alteryx",
      "description": "o Alteryx (multi-purpose eg use in OPEX to analyse prepaids in P&L accounts by merging GL downloads with service period dates from AP system Payee Central to indicate prepaid invoices)",
      "buttons": [
        {
          "label": "visit app",
          "url": "http://alteryx-uat-server.corp.amazon.com/gallery/#!apps/"
        },
        {
          "label": "wiki page",
          "url": "https://w.amazon.com/bin/view/AlteryxHome/"
        }
      ]
    },
    {
      "tool": "Reporting",
      "app": "HFM",
      "description": "Amazon utilizes Hyperion Financial Management (HFM) product suite and its applications AMZNHFM (USGAAP) and STATHFM (Local GAAP) respectively. With Amazon’s organic growth including merger and acquisitions, the existing infrastructure and process are not optimized, streamlined and scaled to meet the US and global statutory needs. As a result, Accounting faces challenges to accurately complete financial reporting, variance analysis and reconciliation during critical close schedule and results in close risks. The HFM NEO project aims to address these challenges by implementing the following enhancements by May 31, 2021 (except for India, which is planned to go live June 30, 2021): * Metadata Improvements: simplifying the data load to include only the data that is required for external reporting * Data Integration: updating technical components to accelerate the data load into the financial reporting systems while reducing burden on maintaining the data load * Business Rules: Removing the Fixed Assets/Goodwill rule from STATHFM once the JE Automation Initiative enables this rule upstream in OFA and also expanding the use of account reclassification rules",
      "buttons": [
        {
          "label": "visit app",
          "url": "https://hyperion.corp.amazon.com/workspace/index.jsp"
        },
        {
          "label": "wiki page",
          "url": "https://w.amazon.com/bin/view/CRS_Team_Wiki/HFM/"
        }
      ]
    },
    {
      "tool": "Reporting",
      "app": "Workiva",
      "description": "Workiva, Inc. is a global software-as-a-service (SaaS) company. It provides a cloud-based connected and reporting compliance platform that enables the use of connected data and automation of reporting across finance, accounting, risk, and compliance. Key Functions: Sarbanes–Oxley reporting and Recovery and Resolution Plans (RRP) - Workiva integrates documentation required for financial statements, risk assessment and forecasts required by the Sarbanes–Oxley Act into a single view. SEC Filing - including EDGAR HTML conversion and XBRL mapping, creating and filing documentation for IPOs, S-1s, 10Ks, 10-Qs, 20-Fs, as well as the reports needed to meet Investor Relations, Mutual Fund reporting regulations. Internal Reporting - these tasks include compiling management reports, risk assessments, board reports, among others.[13] Statutory Reporting – Report across multiple entities and geographies in one central platform. Provides transparency and traceability in compliance with local regulations.",
      "buttons": [
        {
          "label": "visit app",
          "url": "https://w.amazon.com/bin/view/Workiva/Access/"
        },
        {
          "label": "wiki page",
          "url": "https://w.amazon.com/bin/view/Workiva/"
        },
        {
          "label": "Training",
          "url": "https://support.workiva.com/hc/en-us?_gl=1*vpqo1s*_ga*Njg0ODcyNTk3LjE2NDQ1OTM2NTA.*_ga_FCSED18S5P*MTY2NDU2MzY5OC4xNS4wLjE2NjQ1NjM2OTguMC4wLjA.&_ga=2.73156371.1802230730.1664563702-684872597.1644593650&_gac=1.137684612.1664563702.EAIaIQobChMIicCA-5a9-gIV2QqtBh2m-wgZEAAYASAAEgIpN_D_BwE"
        }
      ]
    },
    {
      "tool": "Reporting",
      "app": "Gutenburg",
      "description": "The objectives of Gutenburg is • Financial statement acceleration by driving automation needed to achieve both no-touch and accelerated statutory financial statement (SFS) preparation. o Create data integration with all sources onboarded to FDV. o Create a centralized data catalog mechanism for ingesting data not onboarded to FDV. o Remove financial statement blockers under Gutenberg scope as identified by the STATMEC Pillar. • Create standardized templates with common functionality that align FS content across all entities within a GAAP, are globally scalable, and can be centrally managed. • Reduce manual effort and increase controllership.",
      "buttons": [
        {
          "label": "wiki page",
          "url": "https://w.amazon.com/bin/view/ProjectGutenberg/"
        }
      ]
    },
    {
      "tool": "Reporting",
      "app": "Payee Central (Stores AP Invoices)",
      "description": "Payee Central, provides Corporate Accounts Payable (Corp AP) payees with a secure, self-service means of managing their banking, contact information, purchase order email addresses, and tax information for use in the Corp AP Procure-to-Pay cycle. Once Payee Setup is completed, Payee Central provides payees with ways to submit invoices electronically via Web UI or EDI, search invoice and payment status, and contact Amazon Accounts Payable for assistance.",
      "buttons": [
        {
          "label": "visit app",
          "url": "https://finopscentral-na.amazon.com/payeecentral"
        },
        {
          "label": "wiki page",
          "url": "https://w.amazon.com/bin/view/Payee_Central/About_Payee_Central/"
        }
      ]
    },
    {
      "tool": "Reporting",
      "app": "Asana",
      "description": "Asana is used by Team members to track projects but also day to day tasks , particularly helpful when tracking the steps for the setup of a new process in new entities",
      "buttons": [
        {
          "label": "visit app",
          "url": "https://app.asana.com/-/login?u=%2F0%2Finbox%2F1203978878915747"
        }
      ]
    },
    {
      "tool": "Reporting",
      "app": "ORCA",
      "description": "ORCA is an OFA report downloader and categorizer. You can quickly and easily download the General Ledger Accounts Payable (GL AP), Trial Balance (TB) and GL Balance by Currency reports - Primary and Secondary. The reports are fully customizable, allowing you to pre-filter by any field, select only the fields you want to include and the order with which to include them. There’s an optional categorization functionality which allows users to categorize / label each row in the GL AP report with flexible, user-built rules. You can build as many rules as you want based on any field in the GL AP report and then create bespoke analyses to suit your use case. This categorization functionality critically allows users to compare period over period data in an apples to apples comparison which was difficult to achieve before the release of ORCA. ORCA was originally conceptualized and developed for the EMEA accounting team in 2020 by Stephen Stenson (sstenson@). In 2021, ORCA was used on average over 5,000 times per month in EMEA alone by 307 unique users. ORCA is both SOX approved and Red Certified. With the help of the A-Team Data Engineering team, ORCA leverages **Finance & Accounting Supplemental Data Mart as it's data source through AWS Athena. AWS Athena is a serverless query service which allows for solutions to scale massively to thousands of concurrent users without any impact on performance.",
      "buttons": [
        {
          "label": "visit app",
          "url": "https://amazon.awsapps.com/workdocs/loginv2/index.html#/emailSelect?sitename=amazon"
        },
        {
          "label": "wiki page",
          "url": "https://w.amazon.com/bin/view/ATEAM-Technology/ATEAM-Technology/Tools%20and%20Functionalities/Orca/"
        },
        {
          "label": "dashboard",
          "url": "https://amazon.awsapps.com/workdocs/loginv2/index.html#/emailSelect?sitename=amazon"
        }
      ]
    },
    {
      "tool": "Reporting",
      "app": "Laserfische (Stores AP Invoices)",
      "description": "Laserfiche is an Enterprise Content Management system. Laserfiche provides the ability to establish and deploy information management standards throughout the enterprise while giving individual business units tools to customize the system to meet their specific needs. With document imaging, document management, business process management and records management baked into the core system architecture, Laserfiche makes it possible to standardize on a single ECM system – revolutionising the way information is managed, shared and presented. The purpose of using Laserfiche is to go efficiently paper less. As part of Trend, the scope is defined to the Global Finance operations, Payroll and related processes. Amazon handles paper based transactions such as documents from Vendors (Invoices and so on...) and other business related content which needs to be archived and retrieved in an eloquent fashion. To have the content scaled at a large business scale operations, there is an absolute requirement for a content management system to be in place. As a result Laserfiche is used expansively in the operations. In addition to the storing of the content, Laserfiche facilitates a streamlined document flow of the documents making in a way for the business to build robust process. The workflows application within Laserfiche facilitates the document flow and archiving function",
      "buttons": [
        {
          "label": "visit app",
          "url": "https://docs.corp.amazon.com/userhome/?code=849c9e7d-389a-43e5-932a-4e06de6c0923%40eu-west-1&state=OpenIdConnect.AuthenticationProperties%3DqwQlh9lAjHSXmDC-uNvDvjBYGrrFPHsVLuUnASYBr04qg2Z3EgIQHUfcXdiUVlQd-CF23AtPaLVQtMh7BWvdAry4MPbG7loFwm_N4oPtGYXXD7xZ8kWG39j1iiMGr2RvHosbxypv5rZQ9oGAB_DutgoAV62XaL2l2ruN5PmuU7xSoUERg9DwYk3KitWqfJjiuXjpZnnix1uqQrRF1ww_T2UKgNvXz6LWdUOUi8JJLbUDdFjPrEHJ0XmpgN3gN3VPlj2eAITk0-9tqb3ZVE4HPg#/"
        }
      ]
    },
    {
      "tool": "Reporting",
      "app": "Magnify",
      "description": "Magnify is a month close analytical tool to systematically analyze transactions, identify variances across time, and equip our customers to dive deep and investigate root causes. Magnify provisions a systematic way to summarize annotations for leadership reviews. We partner with finance users to create tools and services that speed up month close activities while raising the bar on accuracy and reliability. Our tools pinpoint issues and identify anomalies that accelerate period close as well as provide a centralized data platform to collaboratively address issues. Our aim is to reduce our customer’s manual effort involved in finalizing financial reports for internal and external stakeholders and expand the possible SLAs for our customers.",
      "buttons": [
        {
          "label": "visit app",
          "url": "https://www.magnify.finance-tech.a2z.com/#/"
        },
        {
          "label": "wiki page",
          "url": "https://w.amazon.com/bin/view/FinanceTechnology/KnowledgeServices/MonthCloseAnalytics/Magnify/"
        },
        {
          "label": "request access",
          "url": "https://w.amazon.com/bin/view/FinanceTechnology/KnowledgeServices/MonthCloseAnalytics/Magnify/Access/"
        }
      ]
    },
    {
      "tool": "Reporting",
      "app": "Tririga",
      "description": "Tririga is an IBM application that runs on WebSphere. Although Tririga has many modules, Amazon has deployed it to handle financial data and calculations for real estate assets. The project is in service of the FinTech Accounting team as part of Project Connor, a lease accounting initiative , and operated by the FinTech SSE team.",
      "buttons": [
        {
          "label": "visit app",
          "url": "https://access.amazon.com/aws/accounts/show/FinTech-3PE-Tririga"
        },
        {
          "label": "wiki page",
          "url": "https://w.amazon.com/bin/view/FinanceTechnology/SSE/Solutions/Tririga/#HAccounts"
        }
      ]
    },
    {
      "tool": "Reporting",
      "app": "Leasing",
      "description": "The Quicksight Dashboard supports and assists with review cashflows, tagging secondary ledger leasing activity",
      "buttons": [
        {
          "label": "visit app",
          "url": "https://beta.console.harmony.a2z.com/ateam-bi-tools/FaLeaseRFHome"
        }
      ]
    },
    {
      "tool": "Reporting",
      "app": "Mace",
      "description": "Our vision is to create an ‘any to any’ matching tool which is abstract enough to compare any two (or three) data sets through user configurable rules. Users can select from a list of available data sources or simply upload their data files, build the matching rules and schedule future executions. We have also created capabilities like identifying missing transactions, computing the difference in dollar values, matching under a threshold, data preparation features like concatenate, trim, append, derive, suffix, prefix etc. which enable the users to on-board their use cases without relying on a technology team. MACE calculates variances in user data and provides post-matching options to investigate and correct variances. A few examples of these actions are booking an adjusting journal entry, emailing action owners, creating trouble tickets, adding custom commentary, and archiving results.",
      "buttons": [
        {
          "label": "visit app",
          "url": "https://mace.finance-tech.a2z.com/dashboard"
        },
        {
          "label": "wiki page",
          "url": "https://w.amazon.com/bin/view/Fintech_Financial_Close_Systems/MACE/"
        },
        {
          "label": "Mace training links",
          "url": "https://w.amazon.com/bin/view/Fintech_Financial_Close_Systems/MACE/Getting_Started/"
        }
      ]
    },
    {
      "tool": "Reporting",
      "app": "Oracle",
      "description": "Oracle database is a relational database management system (RDBMS) from Oracle Corporation",
      "buttons": [
        {
          "label": "visit app",
          "url": "https://ofa.fin.corp.amazon.com/OA_HTML/OA.jsp?OAFunc=OAHOMEPAGE"
        }
      ]
    },
    {
      "tool": "Reporting",
      "app": "Coupa (Stores Invoices)",
      "description": "Coupa is a software that Amazon uses to buy the things we need to do our jobs. You can purchase almost anything through this application. There are a couple of key things to know before you get started: 1) There's a strict policy! Please read the Spending & Transaction Policy - it is every employee(s) responsibility to understand this policy 2) Someone has to pay! You need to know who is going to pay for what you're buying. This means contacting your finance partner before you get started to know the accounting string (Company Code-Location Code-Cost Center-Account Code-Product Line-Channel-Project Code). 3) Who will fulfill your order!? If you don't know if your supplier has been setup yet, you need to do so. Go to Payee Central to see if your supplier exists and is ready to receive your order",
      "buttons": [
        {
          "label": "visit app",
          "url": "https://amazon.coupahost.com/user/home"
        },
        {
          "label": "wiki page",
          "url": "https://w.amazon.com/bin/view/Buy_stuff_at_amazon"
        }
      ]
    },
    {
      "tool": "Reporting",
      "app": "Hyperion",
      "description": "An Oracle product suite implemented by Amazon financial reporting (in May 2015) consisting of multiple products, including: Hyperion Financial Management (HFM) which is a Consolidation and reporting application. Financial Data Management Enterprise edition (FDMEE) Used to import and validate source G/L data (from OFA) before transformation into HFM dimensionality",
      "buttons": [
        {
          "label": "visit app",
          "url": "https://hyperion.corp.amazon.com/workspace/index.jsp"
        },
        {
          "label": "wiki page",
          "url": "https://w.amazon.com/bin/view/HYPERION/"
        },
        {
          "label": "Hyperion trainings",
          "url": "https://w.amazon.com/bin/view/CorporateReporting#OFA_General_Ledger_Best_Practices"
        }
      ]
    },
    {
      "tool": "Reporting",
      "app": "USL",
      "description": "The Unified Sub-Ledger (USL) persists the details of financially-relevant events for sources & businesses onboarded to the Financial Ledger and Accounting Systems Hub (FLASH), and is the authoritative source for transaction detail to substantiate the General Ledger. USL aggregates transaction details into journal entries that are posted to the General Ledger. USL posts transaction details, summaries, and GL journal entry metadata to FinTech BI for generating reports. USL scales up the processing pipelines and supports the strategy of scaling for Amazon. Taking an Example USL outbound freight cost details integrates to sub ledger accounts in real time, to track the liabilities and payments made by Amazon to carriers for outbound shipments accurately. This process would involve sending different transactions like * Manifest/Liability Transactions * Invoice/Payment Transactions * Adjustment Transactions All these transactions would be sent at a shipment item level",
      "buttons": [
        {
          "label": "wiki page",
          "url": "https://w.amazon.com/bin/view/UnifiedSubledger/"
        },
        {
          "label": "USL training",
          "url": "https://w.amazon.com/bin/view/AFFS-Home/AFFS-Help/USL/User-Guide/"
        },
        {
          "label": "ledger closing schedule",
          "url": "https://usl-flexclose.aka.amazon.com/schedule/2021-10"
        }
      ]
    },
    {
      "tool": "Reporting",
      "app": "BlackLine",
      "description": "Blackline is a 3rd-party hosted software (web-based Finance & Accounting tool). As part of program TRACE, Amazon purchased five modules of Blackline software (Account Reconciliations, Task Management, Transaction Matcher, Variance Analysis and Journal Entries). The global implementation of Blackline serves as the technology backbone of TRACE, the program will accomplish significantly more than simply the implementation of replacement software for Account Reconciliation Manager (ARM) and Hyperion Financial Close Manager (HCM). 1. TRACE will provide a global view of the close process to include both US GAAP close and a standardized view of local close calendars via Project North Star. 2. TRACE will enable manual effort reduction, risk reduction, and controllership by driving or supporting projects such as One Team, Spearwood, the MACE implementation, and the Whiteboard automation. 3. TRACE partnered with and is heavily influencing the Worldwide Balance Sheet Reconciliation Committee (WBRC) 2021 Roadmap by providing a list of proposals to modify policies, improve processes, and enhance Accounting's data governance model. * The goal of Project One Team is to standardize the workflow and reduce the amount of manual effort related to the review and tracking of documents linked to statutory reporting including ETBs, financial statements, corporate governance documents and other reports involving cross functional reviews * The goal of Project Spearwood is for local accounting teams to submit extended trial balances (ETBs) eight times a year to align with the frequency of the US GAAP global tax provision process, rather than the current interim and year-end basis. By preparing ETBs more frequently, transactions can be analyzed timelier, and Ernst & Young can accelerate their audit procedures, reducing the concentration of work at interim and year-end and accelerating the preparation and review procedures to enable a faster year-end close process",
      "buttons": [
        {
          "label": "visit app",
          "url": "https://hyperion.corp.amazon.com/workspace/index.jsp"
        },
        {
          "label": "wiki page",
          "url": "https://amazon.us2.blackline.com/"
        },
        {
          "label": "BlackLine training",
          "url": "https://amazon.us2.blackline.com/"
        },
        {
          "label": "BlackLine training",
          "url": "https://w.amazon.com/bin/view/CRS_Team_Wiki/Blackline/Whiteboards/Training"
        }
      ]
    },
    {
      "tool": "Reporting",
      "app": "FDV",
      "description": "Teams across Amazon relies on financial datasets for their operational, analytical, and compliance need. Today, these datasets are maintained in siloed repositories [e.g. Finance Data Warehouse (FDW), Tax Data Warehouse (TDW), USL-BI], and often have significant functional overlaps. In order to maintain these data silos, multiple technology team builds similar data processing channels introducing challenges with controllership and compliance. To address these problems, FinTech Knowledge Service team incepted Finance Data Vault (FDV), a scalable data product to publish, govern, discover and consume raw (transactional) and processed finance datasets. Replacing existing data repositories with FDV will provision a unified data repository to host finance data from heterogeneous applications; ensure data governance; and equip our customers to discover and consume information, without much engineering interventions.",
      "buttons": [
        {
          "label": "wiki page",
          "url": "https://w.amazon.com/bin/view/FinanceTechnology/KnowledgeServices/FDV"
        },
        {
          "label": "FDV training page",
          "url": "https://w.amazon.com/bin/view/FinanceTechnology/KnowledgeServices/FDV/FDVUIUserGuides"
        }
      ]
    },
    {
      "tool": "Reporting",
      "app": "Fixed Assets",
      "description": "Fixed Assets",
      "buttons": [
        {
          "label": "visit app",
          "url": "https://us-east-1.quicksight.aws.amazon.com/sn/dashboards/415a4713-35b2-4223-8839-5cdf5c3e69a6/sheets/415a4713-35b2-4223-8839-5cdf5c3e69a6_abbd6654-f4e7-499e-8f39-c1cb550243ca"
        }
      ]
    },
    {
      "tool": "Analytics",
      "app": "Exception Report",
      "description": "The Exception Report was designed with the idea that it would support the local Accounting Teams in reviewing the statutory close . Local Teams today have to dive deep into multiple systems when reviewing financial statements. This can be time consuming and reduces the time available to actually review numbers. The Exception Report will have all these checks available to users in one location - AWS Quicksight. In the dashboard, data can be generated by selecting key reporting parameters including relevant company or entity code (s), Local GAAP applicable and the needed reporting period. Users have the option to select multiple entities to be shown on a single report, as well as the ability to deselect from a dropdown menu the schedules they would like to exclude from the report. All reports can be downloaded in Excel. Given below is a quick visual of the Exception Report Quicksight dashboard. It has various radio buttons and features in each of the six tabs. Please refer to the Exception Report User Guide to learn more about these features and navigate across the dashboard.",
      "buttons": [
        {
          "label": "visit app",
          "url": "https://us-east-1.quicksight.aws.amazon.com/sn/dashboards/a1cfa14e-9907-4417-ba59-9f83fbb70e25"
        },
        {
          "label": "wiki page",
          "url": "https://w.amazon.com/bin/view/Statpack/ExceptionReport/"
        },
        {
          "label": "Exception Report user guide/training page",
          "url": "https://amazon.awsapps.com/workdocs/index.html#/document/34bdddacd3968fc00051b3cb492bf8976b875921ae7f05f73d8082f4745ff283"
        }
      ]
    },
    {
      "tool": "Analytics",
      "app": "Statpack",
      "description": "STATPACK dashboard using AWS Quicksight entails the generation of a consolidated reporting package, with a prescribed suite of reports with enhanced review capabilities. The suite of reports will help teams to review the bridge between US GAAP and Local GAAP balances through the Extended Trial Balance report ('ETB'), analytical views of Local GAAP primary statements, month over month local GAAP balance movements, drilldown to the journals. New summarized bridge and reconciliation reports (with prior period comparatives) should enable reviewers to pinpoint potential material issues or inconsistencies in statutory adjustments more easily and efficiently, and to complete their reviews expeditiously. Overall this design/solution is aimed at replacing the laborious activities associated with data downloads, manipulation, validation with a reliable and automated, exception-based solution, effectively improving controllership by identifying mis-matches, data sanity issues. Given below is a quick visual of the STATPACK Quicksight dashboard. It has various radio buttons and features in each of the six tabs. Please refer to the STATPACK User Guide to learn more about these features and navigate across the dashboard.",
      "buttons": [
        {
          "label": "visit app",
          "url": "https://us-east-1.quicksight.aws.amazon.com/sn/dashboards/eae70903-b400-40d8-8993-4c317e67142a?edap=true#"
        },
        {
          "label": "Statpack wiki page",
          "url": "https://w.amazon.com/bin/view/Statpack/"
        },
        {
          "label": "Statpack user guide/training",
          "url": "https://amazon.awsapps.com/workdocs/index.html#/document/be2596c444a346b1caf6657b6a9a596a115b10657f1f77a1692eb49f313bfc5d"
        }
      ]
    },
    {
      "tool": "Analytics",
      "app": "Quicksight",
      "description": "Amazon's Finance org (FGBS) migrated all BI reporting from Oracle BI (OBIEE) to AWS QuickSight (QS). QS is a native AWS-based fast, cloud-powered business intelligence service. QS lets users easily create and publish interactive dashboards that include ML Insights. Dashboards can be accessed from any device, and embedded into other applications, portals, and websites, making it easy to deliver insights across teams and users. QS is the reporting/analysis UI that sits on top of data sources in Redshift. All FGBS reporting takes place in the FGBSBI QS account. FGBSBI is separate from the Amazon-wide QS account (amazonbi). FGBSBI has the necessary controls to be GRC-certified and serves as a source of truth for Amazon's financial reporting.",
      "buttons": [
        {
          "label": "visit app",
          "url": "https://us-east-1.quicksight.aws.amazon.com/sn/start/analyses"
        },
        {
          "label": "quicksight access request",
          "url": "https://w.amazon.com/bin/view/FGBSBI_QuickSight_Access_FGBS_users/"
        }
      ]
    },
    {
      "tool": "PoliciesAndProcedures",
      "app": "MER Reporting & Training",
      "description": "Manual Effort Reduction Planning (MERP) Committee mobilizes the broader Accounting-WW organization to reduce manual effort and improve productivity through system automation or process improvements.",
      "buttons": [
        {
          "label": "MER",
          "url": "https://w.amazon.com/bin/view/MERP-Reporting_Tools_and_Training/Home/"
        }
      ]
    },
    {
      "tool": "PoliciesAndProcedures",
      "app": "Exchange Rates",
      "description": "The source of the rates is Bloomberg provided by Amazon Treasury department. Monthly FX rates are loaded to Oracle Financial Application (OFA) around midnight of the last business day of the month, and monthly FX rates are published on this site in the morning of the first business day of following month.",
      "buttons": [
        {
          "label": "Latest Rates",
          "url": "https://share.amazon.com/sites/Corporate_Accounting/global_accounting/us/Pages/FX.aspx"
        }
      ]
    },
    {
      "tool": "PoliciesAndProcedures",
      "app": "Accounting Policies",
      "description": "Latest Policy Announcements or Changes",
      "buttons": [
        {
          "label": "Accounting Policies",
          "url": "https://share.amazon.com/sites/accountingpolicies/default.aspx"
        }
      ]
    },
    {
      "tool": "PoliciesAndProcedures",
      "app": "Workfront",
      "description": "Workfront is a configurable SAAS enterprise work management solution that helps customers manage the entire lifecycle of work in one place. Built for the way people work, the platform is intuitive, flexible, and customizable and provides a 360-degree view of all workplace activities, helping both team members and administration alike to better understand and organize their work. With Workfront, project managers gain the power to streamline requests, plan and prioritize projects, manage processes, digitally review assets, and report on work efforts—all in one place. Working teams get a central application platform to share ideas, create content, manage complex processes, and do their best work. Some features include portfolio and project management, issue and task tracking, document review and approval, time tracking and reporting.",
      "buttons": [
        {
          "label": "visit app",
          "url": "https://amazonaccounting.my.workfront.com/"
        },
        {
          "label": "Wiki page",
          "url": "https://w.amazon.com/bin/view/Workfront/"
        }
      ]
    },
    {
      "tool": "PoliciesAndProcedures",
      "app": "Close Calendar 2023",
      "description": "Close Calendar for 2023 for all Regions",
      "buttons": [
        {
          "label": "Accounting calendar 2023",
          "url": "https://share.amazon.com/sites/Corporate_Accounting/global_accounting/Documents/2023%20Close%20Calendar.pdf"
        }
      ]
    },
    {
      "tool": "PoliciesAndProcedures",
      "app": "Chart of Accounts",
      "description": "The purpose of this site is provide you with the essential resources necessary to understand and make potential changes to Amazon's Chart of Accounts (COA) within Oracle. The COA is composed of seven segments: Company, Location, Cost Center, GL Account, Product Line, Channel, and Project. The seven segments are arranged in the following sequence: CO.LOCA.COST.ACCOU.PROD.CHAN.PROJ Each section in the sequence represents the following COA segment: CO - 4-digit alphanumeric Company segment LOCA - 4-digit numeric Location segment COST - 4-digit numeric Cost Center segment ACCOU - 5-digit numeric GL Account segment PROD - 4-digit numeric Product Line segment CHAN - 4-digit numeric Channel segment PROJ - 4-digit alphanumeric Project segment",
      "buttons": [
        {
          "label": "Chart of Accounts",
          "url": "https://share.amazon.com/sites/Corporate_Accounting/global_accounting/COA/Pages/Chart%20of%20Accounts.aspx"
        }
      ]
    },
    {
      "tool": "PoliciesAndProcedures",
      "app": "GRC Controls",
      "description": "GRC Controls that are currently open for Users",
      "buttons": [
        {
          "label": "GRC Controls Open tasks",
          "url": "https://grc.amazon.com/"
        }
      ]
    },
    {
      "tool": "us-gapp",
      "app": "Alteryx",
      "description": "o Alteryx (multi-purpose eg use in OPEX to analyse prepaids in P&L accounts by merging GL downloads with service period dates from AP system Payee Central to indicate prepaid invoices)",
      "buttons": [
        {
          "label": "visit app",
          "url": "http://alteryx-uat-server.corp.amazon.com/gallery/#!apps/"
        },
        {
          "label": "wiki page",
          "url": "https://w.amazon.com/bin/view/AlteryxHome/"
        }
      ]
    },
    {
      "tool": "us-gapp",
      "app": "Exchange Rates",
      "description": "The source of the rates is Bloomberg provided by Amazon Treasury department. Monthly FX rates are loaded to Oracle Financial Application (OFA) around midnight of the last business day of the month, and monthly FX rates are published on this site in the morning of the first business day of following month.",
      "buttons": [
        {
          "label": "Latest rates",
          "url": "https://share.amazon.com/sites/Corporate_Accounting/global_accounting/us/Pages/FX.aspx"
        }
      ]
    },
    {
      "tool": "us-gapp",
      "app": "Magnify",
      "description": "Magnify is a month close analytical tool to systematically analyze transactions, identify variances across time, and equip our customers to dive deep and investigate root causes. Magnify provisions a systematic way to summarize annotations for leadership reviews. We partner with finance users to create tools and services that speed up month close activities while raising the bar on accuracy and reliability. Our tools pinpoint issues and identify anomalies that accelerate period close as well as provide a centralized data platform to collaboratively address issues. Our aim is to reduce our customer’s manual effort involved in finalizing financial reports for internal and external stakeholders and expand the possible SLAs for our customers",
      "buttons": [
        {
          "label": "visit app",
          "url": "https://www.magnify.finance-tech.a2z.com/#/magnify/annotations"
        },
        {
          "label": "wiki page",
          "url": "https://w.amazon.com/bin/view/FinanceTechnology/KnowledgeServices/MonthCloseAnalytics/Magnify/"
        },
        {
          "label": "request access",
          "url": "https://w.amazon.com/bin/view/FinanceTechnology/KnowledgeServices/MonthCloseAnalytics/Magnify/Access/"
        }
      ]
    },
    {
      "tool": "us-gapp",
      "app": "Oracle",
      "description": "Oracle database is a relational database management system (RDBMS) from Oracle Corporation",
      "buttons": [
        {
          "label": "visit app",
          "url": "https://ofa.fin.corp.amazon.com/OA_HTML/OA.jsp?OAFunc=OAHOMEPAGE"
        }
      ]
    },
    {
      "tool": "us-gapp",
      "app": "Quicksight",
      "description": "Amazon's Finance org (FGBS) migrated all BI reporting from Oracle BI (OBIEE) to AWS QuickSight (QS). QS is a native AWS-based fast, cloud-powered business intelligence service. QS lets users easily create and publish interactive dashboards that include ML Insights. Dashboards can be accessed from any device, and embedded into other applications, portals, and websites, making it easy to deliver insights across teams and users. QS is the reporting/analysis UI that sits on top of data sources in Redshift. All FGBS reporting takes place in the FGBSBI QS account. FGBSBI is separate from the Amazon-wide QS account (amazonbi). FGBSBI has the necessary controls to be GRC-certified and serves as a source of truth for Amazon's financial reporting.",
      "buttons": [
        {
          "label": "visit app",
          "url": "https://us-east-1.quicksight.aws.amazon.com/sn/start/analyses"
        },
        {
          "label": "quicksight access request",
          "url": "https://w.amazon.com/bin/view/FGBSBI_QuickSight_Access_FGBS_users/"
        }
      ]
    },
    {
      "tool": "us-gapp",
      "app": "BlackLine",
      "description": "Blackline is a 3rd-party hosted software (web-based Finance & Accounting tool). As part of program TRACE, Amazon purchased five modules of Blackline software (Account Reconciliations, Task Management, Transaction Matcher, Variance Analysis and Journal Entries). The global implementation of Blackline serves as the technology backbone of TRACE, the program will accomplish significantly more than simply the implementation of replacement software for Account Reconciliation Manager (ARM) and Hyperion Financial Close Manager (HCM). 1. TRACE will provide a global view of the close process to include both US GAAP close and a standardized view of local close calendars via Project North Star. 2. TRACE will enable manual effort reduction, risk reduction, and controllership by driving or supporting projects such as One Team, Spearwood, the MACE implementation, and the Whiteboard automation. 3. TRACE partnered with and is heavily influencing the Worldwide Balance Sheet Reconciliation Committee (WBRC) 2021 Roadmap by providing a list of proposals to modify policies, improve processes, and enhance Accounting's data governance model. * The goal of Project One Team is to standardize the workflow and reduce the amount of manual effort related to the review and tracking of documents linked to statutory reporting including ETBs, financial statements, corporate governance documents and other reports involving cross functional reviews * The goal of Project Spearwood is for local accounting teams to submit extended trial balances (ETBs) eight times a year to align with the frequency of the US GAAP global tax provision process, rather than the current interim and year-end basis. By preparing ETBs more frequently, transactions can be analyzed timelier, and Ernst & Young can accelerate their audit procedures, reducing the concentration of work at interim and year-end and accelerating the preparation and review procedures to enable a faster year-end close process",
      "buttons": [
        {
          "label": "visit app",
          "url": "https://hyperion.corp.amazon.com/workspace/index.jsp"
        },
        {
          "label": "wiki page",
          "url": "https://amazon.us2.blackline.com/"
        },
        {
          "label": "BlackLine training",
          "url": "https://amazon.us2.blackline.com/"
        },
        {
          "label": "BlackLine training",
          "url": "https://w.amazon.com/bin/view/CRS_Team_Wiki/Blackline/Whiteboards/Training"
        }
      ]
    },
    {
      "tool": "FunctionalAccountingClose",
      "app": "Alteryx",
      "description": "o Alteryx (multi-purpose eg use in OPEX to analyse prepaids in P&L accounts by merging GL downloads with service period dates from AP system Payee Central to indicate prepaid invoices)",
      "buttons": [
        {
          "label": "visit app",
          "url": "http://alteryx-uat-server.corp.amazon.com/gallery/#!apps/"
        },
        {
          "label": "wiki page",
          "url": "https://w.amazon.com/bin/view/AlteryxHome/"
        }
      ]
    },
    {
      "tool": "FunctionalAccountingClose",
      "app": "Cashflow Accounting",
      "description": "Project Rising is the automation of the cash flow roll-forward data which is used to create the Consolidated Statement of Cash Flows (SOCF) for reporting purposes. The concept of the automation is simple and complete. Every journal entry is tagged with a statement of cash flows (SOCF) code representing a cash or non-cash transaction or activity. These activities are validated, aggregated and systematically mapped, based on tagging, to specific line items within the operating, investing and financing sections of the SOCF in Hyperion (HFM), our web-based reporting platform. The SOCF can now be generated at the consolidated level or by company and region through HFM. The automation has enabled Amazon to significantly enhance controllership of the preparation and review of the SOCF and has provided further transparency of cash flow activities amongst cross-organizational teams. Project Rising Phase 1 USGAAP Cash Flow automation was fully launched in August 2020. We are currently driving a path to automation with Project Rising - Phase 2 IFRS SOCF automation and Phase 3 Local GAAP (non-IFRS) SOCF automation with target launch in 2022. IFRS/Local GAAP SOCF automation aligns with the initiative to standardize the statement of cash flows and disclosures worldwide.",
      "buttons": [
        {
          "label": "Cashflow wiki page",
          "url": "https://w.amazon.com/bin/view/Cash-Flow-Accounting/Project-Rising-USGAAP"
        }
      ]
    },
    {
      "tool": "FunctionalAccountingClose",
      "app": "Tririga",
      "description": "Tririga is an IBM application that runs on WebSphere. Although Tririga has many modules, Amazon has deployed it to handle financial data and calculations for real estate assets. The project is in service of the FinTech Accounting team as part of Project Connor, a lease accounting initiative , and operated by the FinTech SSE team.",
      "buttons": [
        {
          "label": "visit app",
          "url": "https://access.amazon.com/aws/accounts/show/FinTech-3PE-Tririga"
        },
        {
          "label": "wiki page",
          "url": "https://w.amazon.com/bin/view/FinanceTechnology/SSE/Solutions/Tririga/#HAccounts"
        }
      ]
    },
    {
      "tool": "FunctionalAccountingClose",
      "app": "Leasing",
      "description": "The Quicksight Dashboard supports and assists with review cashflows, tagging secondary ledger leasing activity",
      "buttons": [
        {
          "label": "visit app",
          "url": "https://beta.console.harmony.a2z.com/ateam-bi-tools/FaLeaseRFHome"
        }
      ]
    },
    {
      "tool": "FunctionalAccountingClose",
      "app": "Quicksight",
      "description": "Amazon's Finance org (FGBS) migrated all BI reporting from Oracle BI (OBIEE) to AWS QuickSight (QS). QS is a native AWS-based fast, cloud-powered business intelligence service. QS lets users easily create and publish interactive dashboards that include ML Insights. Dashboards can be accessed from any device, and embedded into other applications, portals, and websites, making it easy to deliver insights across teams and users. QS is the reporting/analysis UI that sits on top of data sources in Redshift. All FGBS reporting takes place in the FGBSBI QS account. FGBSBI is separate from the Amazon-wide QS account (amazonbi). FGBSBI has the necessary controls to be GRC-certified and serves as a source of truth for Amazon's financial reporting",
      "buttons": [
        {
          "label": "visit app",
          "url": "https://us-east-1.quicksight.aws.amazon.com/sn/start/analyses"
        },
        {
          "label": "quicksight access request",
          "url": "https://w.amazon.com/bin/view/FGBSBI_QuickSight_Access_FGBS_users/"
        }
      ]
    },
    {
      "tool": "FunctionalAccountingClose",
      "app": "Fixed Assets",
      "description": "Fixed Assets",
      "buttons": [
        {
          "label": "visit app",
          "url": "https://us-east-1.quicksight.aws.amazon.com/sn/dashboards/415a4713-35b2-4223-8839-5cdf5c3e69a6/sheets/415a4713-35b2-4223-8839-5cdf5c3e69a6_abbd6654-f4e7-499e-8f39-c1cb550243ca"
        }
      ]
    },
    {
      "tool": "StationaryAccountingClose",
      "app": "Compliance Central",
      "description": "Compliance Central is a self-serve product that enables Accounting and Finance users to generate reports, through a rich UI, encapsulating tech heavy-lifting behind the scenes. Compliance Central’s vision is to be end to end hub for Amazon Finance and Accounting needs. The platform is certified to handle red data and team leads can easily grant and restrict access to their reports via native Amazon tools such as Teams.",
      "buttons": [
        {
          "label": "visit app",
          "url": "https://www.compliance-central.fintech.amazon.dev/"
        },
        {
          "label": "wiki page",
          "url": "https://w.amazon.com/bin/view/Accounting_BI/Statutory_and_Compliance/Compliance_Central/"
        },
        {
          "label": "user guide",
          "url": "https://w.amazon.com/bin/view/Accounting_BI/Statutory_and_Compliance/Compliance_Central/User_Guide"
        }
      ]
    },
    {
      "tool": "StationaryAccountingClose",
      "app": "Exception Report",
      "description": "The Exception Report was designed with the idea that it would support the local Accounting Teams in reviewing the statutory close . Local Teams today have to dive deep into multiple systems when reviewing financial statements. This can be time consuming and reduces the time available to actually review numbers. The Exception Report will have all these checks available to users in one location - AWS Quicksight. In the dashboard, data can be generated by selecting key reporting parameters including relevant company or entity code (s), Local GAAP applicable and the needed reporting period. Users have the option to select multiple entities to be shown on a single report, as well as the ability to deselect from a dropdown menu the schedules they would like to exclude from the report. All reports can be downloaded in Excel. Given below is a quick visual of the Exception Report Quicksight dashboard. It has various radio buttons and features in each of the six tabs. Please refer to the Exception Report User Guide to learn more about these features and navigate across the dashboard.",
      "buttons": [
        {
          "label": "visit app",
          "url": "https://us-east-1.quicksight.aws.amazon.com/sn/dashboards/a1cfa14e-9907-4417-ba59-9f83fbb70e25"
        },
        {
          "label": "wiki page",
          "url": "https://w.amazon.com/bin/view/Statpack/ExceptionReport/"
        },
        {
          "label": "Exception Report user guide/ training page",
          "url": "https://amazon.awsapps.com/workdocs/index.html#/document/34bdddacd3968fc00051b3cb492bf8976b875921ae7f05f73d8082f4745ff283"
        }
      ]
    },
    {
      "tool": "StationaryAccountingClose",
      "app": "Gutenburg",
      "description": "The objectives of Gutenburg is • Financial statement acceleration by driving automation needed to achieve both no-touch and accelerated statutory financial statement (SFS) preparation. o Create data integration with all sources onboarded to FDV. o Create a centralized data catalog mechanism for ingesting data not onboarded to FDV. o Remove financial statement blockers under Gutenberg scope as identified by the STATMEC Pillar. • Create standardized templates with common functionality that align FS content across all entities within a GAAP, are globally scalable, and can be centrally managed. • Reduce manual effort and increase controllership.",
      "buttons": [
        {
          "label": "wiki page",
          "url": "https://w.amazon.com/bin/view/ProjectGutenberg/"
        }
      ]
    },
    {
      "tool": "StationaryAccountingClose",
      "app": "Statpack",
      "description": "STATPACK dashboard using AWS Quicksight entails the generation of a consolidated reporting package, with a prescribed suite of reports with enhanced review capabilities. The suite of reports will help teams to review the bridge between US GAAP and Local GAAP balances through the Extended Trial Balance report ('ETB'), analytical views of Local GAAP primary statements, month over month local GAAP balance movements, drilldown to the journals. New summarized bridge and reconciliation reports (with prior period comparatives) should enable reviewers to pinpoint potential material issues or inconsistencies in statutory adjustments more easily and efficiently, and to complete their reviews expeditiously. Overall this design/solution is aimed at replacing the laborious activities associated with data downloads, manipulation, validation with a reliable and automated, exception-based solution, effectively improving controllership by identifying mis-matches, data sanity issues. Given below is a quick visual of the STATPACK Quicksight dashboard. It has various radio buttons and features in each of the six tabs. Please refer to the STATPACK User Guide to learn more about these features and navigate across the dashboard.",
      "buttons": [
        {
          "label": "visit app",
          "url": "https://us-east-1.quicksight.aws.amazon.com/sn/dashboards/eae70903-b400-40d8-8993-4c317e67142a?edap=true#"
        },
        {
          "label": "Statpack wiki page",
          "url": "https://w.amazon.com/bin/view/Statpack/"
        },
        {
          "label": "Statpack user guide/ training",
          "url": "https://amazon.awsapps.com/workdocs/index.html#/document/be2596c444a346b1caf6657b6a9a596a115b10657f1f77a1692eb49f313bfc5d"
        }
      ]
    }
  ]