import React, { useState, useEffect } from "react";
import {
    Box,
    Input,
    Button,
    Select,
    SpaceBetween,
    Checkbox,
    ColumnLayout,
    Container
} from "@amzn/awsui-components-react";
import './style.scss'

const CustomProcessSchemaInput = (props) => {
    var [rows, setRows] = useState(props.getValues(props.field) || []);
    var [errorMessage, setErrorMessage] = useState('');
    const defScaleValue = "36"
    const defPrecisionValue = "8"
    const defLengthValue = "2000"
    useEffect(() => {
        loadRows()
        return () => {
        };
    }, []);

    useEffect(()=>{
        loadRows()
    },[props?.delsertRadioKeyVisible])

    useEffect(() => {
        if (isInValid()) {
            let msg = ''
            if (props?.errorMessage) {
                msg = props?.errorMessage
            }
            setErrorMessage(msg)
        } else {
            setErrorMessage('')
            props.updateValue(props.field, getMergedInputValues())
        }
    }, [rows])
    

    const getMergedInputValues = () => {
        var filtered = rows?.filter(function (row) {
            return row != null;
        });
        for(let i=0;i<filtered.length;i++){
            if(filtered[i][props.selectKey] == 'DECIMAL'){
                filtered[i][props.lengthKey] = null
                if(!filtered[i].hasOwnProperty(props.scaleKey) || filtered[i][props.scaleKey] == null){
                    filtered[i][props.scaleKey] = defScaleValue
                }
                if(!filtered[i].hasOwnProperty(props.precisionKey) || filtered[i][props.precisionKey] == null){
                    filtered[i][props.precisionKey] = defPrecisionValue
                }
            }else if(filtered[i][props.selectKey] == 'VARCHAR'){
                filtered[i][props.scaleKey] = null
                filtered[i][props.precisionKey] = null
                if(!filtered[i].hasOwnProperty(props.lengthKey) || filtered[i][props.lengthKey] == null){
                    filtered[i][props.lengthKey] = defLengthValue
                }
            }else{
                filtered[i][props.lengthKey] = null
                filtered[i][props.scaleKey] = null
                filtered[i][props.precisionKey] = null
            }
        }
        return filtered
    }

    const isInValid = () => {
        return props.validator && !props.validator(getMergedInputValues())
    }

    const onEdit = (value, index, key) => {
        rows[index][key] = value
        rows[index][props.lastUpdatedTimeKey]=null
        setRows([...rows])
    }

    const getInputItem = (value, index) => {
        return <div className='rows' key={index}>
            <Container>
            <SpaceBetween direction="horizontal" size="xs" key={index}>
                <SpaceBetween direction="vertical">
                    <Box variant="h4">Column name</Box>
                    <Input
                        onChange={({ detail }) => {
                            let val = detail.value
                            if (props?.postProcess) {
                                val = props.postProcess(detail.value)
                            }
                            onEdit(val, index, props.inputKey)
                        }}
                        placeholder={props.placeholder}
                        value={value.hasOwnProperty(props.inputKey) ? value[props.inputKey] : ''}
                    />
                </SpaceBetween>

                <SpaceBetween direction="vertical">
                    <Box variant="h4">Data type</Box>
                    <Select
                        selectedOption={value[props.selectKey] ? { value: value[props.selectKey] } : null}
                        onChange={({ detail }) =>
                            onEdit(detail.selectedOption.value, index, props.selectKey)
                        }
                        options={props.getOptions(props.field, props.selectKey)}
                        placeholder={props?.selectHint ? props.selectHint : "Choose options"}
                    />
                </SpaceBetween>
                {
                    value[props.selectKey] == 'DECIMAL' ?
                        <SpaceBetween direction="vertical">
                            <Box variant="h4">Scale</Box>
                            <Input
                                onChange={({ detail }) => {
                                    let val = detail.value
                                    if(isNaN(Number(val))){
                                        setErrorMessage("scale can only be a number")
                                    }else if(Number(val) % 1 != 0){
                                        setErrorMessage("scale can only be an integer")
                                    }else{   
                                        onEdit(val, index, props.scaleKey)
                                    }
                                    
                                }}
                                placeholder="scale"
                                value={value.hasOwnProperty(props.scaleKey) && value[props.scaleKey]!=null ? value[props.scaleKey] : defScaleValue}
                            />
                        </SpaceBetween> :
                        <></>
                }
            
                {
                    value[props.selectKey] == 'DECIMAL' ?
                        <SpaceBetween direction="vertical">
                            <Box variant="h4">Precision</Box>
                            <Input
                                onChange={({ detail }) => {
                                    let val = detail.value
                                    if(isNaN(Number(val))){
                                        setErrorMessage("precision can only be a number")
                                    }else if(Number(val) % 1 != 0){
                                        setErrorMessage("precision can only be an integer")
                                    }else{   
                                        onEdit(val, index, props.precisionKey)
                                    }
                                }}
                                placeholder="precision"
                                value={value.hasOwnProperty(props.precisionKey) && value[props.precisionKey]!=null ? value[props.precisionKey] : defPrecisionValue}
                            />
                        </SpaceBetween> :
                        <></>
                }
                {
                    value[props.selectKey] == 'VARCHAR' ?
                        <SpaceBetween direction="vertical">
                            <Box variant="h4">Length</Box>
                            <Input
                                onChange={({ detail }) => {
                                    let val = detail.value
                                    if(isNaN(Number(val))){
                                        setErrorMessage("length can only be a number")
                                    }else if(Number(val) % 1 != 0){
                                        setErrorMessage("length can only be an integer")
                                    }else{   
                                        onEdit(val, index, props.lengthKey)
                                    }
                                }}
                                placeholder="length"
                                value={value.hasOwnProperty(props.lengthKey) && value[props.lengthKey] != null ? value[props.lengthKey] : defLengthValue}
                            />
                        </SpaceBetween> : <></>

                }
                 {
                    props?.radioKeyVisible ? 
                    <Checkbox
                        onChange={({ detail }) =>
                        onEdit(detail.checked, index, props.radioKey)
                        }
                        checked={value[props.radioKey]}
                    >
                        {props.radioText}
                    </Checkbox>:
                    ''
                }

                {
                    props?.delsertRadioKeyVisible ?
                    <Box>
                        <br/>
                    <Checkbox
                        onChange={({ detail }) =>
                            onEdit(detail.checked, index, props.delsertKey)
                        }
                        checked={value[props.delsertKey]}
                    >
                        {props.delsertRadioText}
                    </Checkbox>
                    </Box> :
                    ''
                }

            <div className="row"><Button iconName="close" variant="icon" onClick={() => {
                    deleteRow(index)
                }} />
                    
                </div>
                </SpaceBetween>
            </Container>
        </div>
    }

    const deleteRow = (index) => {
        rows.splice(index, 1)
        setRows([...rows])
    }

    const addRow = () => {
        var newRow = {}
        newRow[props.selectKey] = null
        newRow[props.inputKey] = ''
        newRow[props.radioKey] = props.radioKeyDefault
        newRow[props.delsertKey] = props.delsertRadioKeyDefault
        newRow[props.scaleKey] = defScaleValue
        newRow[props.precisionKey] = defPrecisionValue
        newRow[props.lengthKey] = defLengthValue
        newRow[props.lastUpdatedTimeKey] = null
        setRows([...rows, newRow])
    }

    const getAddButton = () => {
        return <div> <Button onClick={addRow}>Add</Button> </div>
    }

    const loadRows = () => {
        let values = props.getValues(props.field)
        if (values == null) {
            return
        }
        setRows(JSON.parse(JSON.stringify(values)))
        
    }

    return (
        <ColumnLayout columns={1}>
            <Box></Box>
            <SpaceBetween size="m">
                {props.info && props.info != ' ' && props.info != '' ? <><span className="info">{props.info}</span> <br></br> </> : ''}
                {errorMessage && errorMessage != '' ? <><span className="error"> {errorMessage} </span><br></br></> : ''}
                {rows ? (Array.from(rows).map((value, index) => {
                    return getInputItem(value, index);
                })) : ''}

                {
                    getAddButton()
                }
            </SpaceBetween>
        </ColumnLayout>
    )
}

export default CustomProcessSchemaInput;