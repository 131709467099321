import React from 'react';
import { withRouter } from 'react-router-dom';
import { SideNavigation } from '@amzn/awsui-components-react';

// Class ServiceNavigation is the Side Navigation component that is used in BasicLayout, CreateForm, ServiceHomepage, and Table flows.
// Implement like this: <ServiceNavigation />

class SideNav extends React.Component {
    
    onFollowHandler(ev) {
        ev.preventDefault();
        if (ev.detail.href) {
            this.props.history.push(ev.detail.href.substring(1));
        }
    }


    render() {
        return (
            <SideNavigation
                header={{ text: 'MJE Montoring', href: '/' }}
                items={items}
                activeHref={this.props.activeHref}
                onFollow={this.onFollowHandler.bind(this)}

            />
        );
    }
}

const items = [
    {
        "type": "link",
        "text": "Introduction",
        "href": "#/MJEMonitoringHome"
    },

    {
        "type": "section",
        "text": "Actions",
        "defaultExpanded": true,
        "items": [
            {
                "type": "link",
                "text": "Upload File",
                "href": "#/MJEMonitoringNewUpload"
            },
            
            {
                "type": "link",
                "text": "History",
                "href": "#/MJEMonitoringHistory"
                
            }
            
            
            
        ]

    },


    { "type": "divider" },

    
    {
        "type": "link",
        "text": "Back",
        "href": "#/ATeamBIHome"
    },


];

export default withRouter(SideNav);
