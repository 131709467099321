import React, { useState, useEffect, useRef, useCallback } from "react";

import {
  AppLayout,
  Badge,
  Box,
  BreadcrumbGroup,
  Button,
  ColumnLayout,
  Container,
  Tabs,
  Grid,
  Header,
  HelpPanel,
  Icon,
  Modal,
  SpaceBetween,
  Spinner,
  Alert,
  Table,
  ExpandableSection,
  Flashbar,
  StatusIndicator,
  Link,
  Multiselect,
  Textarea,
  FormField
} from "@amzn/awsui-components-react";
import { useHistory, useParams } from "react-router-dom";
import * as XLSX from "xlsx";

import * as d3 from "d3";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";

import { API, graphqlOperation } from "aws-amplify";
import { Storage } from "aws-amplify";
import SideNav from "./SideNav";
import { getWasWorkflowById, performActionOnWorkflow, getAllowedActions } from "../utilities/wasApprovalAPIs"
import { useSelector, useDispatch } from "react-redux";
import moment from 'moment';
import Feedback from "src/generic-components/UserFeedback/Feedback";
import { configureDynamicS3, configureGenericS3 } from "src/context/AuthContextUtility";
import { S3_CONFIG } from "src/constants/AppConstants";
import { setCurrentAppId } from "src/store/userAuthSlice";
import { isMemberOf } from "src/checkPageAccess";


const WorkflowInstance = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [item, setItem] = useState([]);
  const [ShowLoadingIcon, setShowLoadingIcon] = useState(false);

  const [activeTabId, setActiveTabId] = useState("details");
  const currentStage = useSelector((globalState) => globalState.auth.currentStage)

  const { workflowInstanceId } = useParams()
  const [title, setTitle] = useState()
  const [description, setDescription] = useState()
  const [status, setStatus] = useState()
  const [createdAt, setCreatedAt] = useState()
  const [requestedBy, setRequestedBy] = useState()
  const [approvers, setApprovers] = useState([])
  const [allowedActions, setAllowedActions] = useState([])
  const [s3FilePath, setS3FilePath] = useState()
  const [s3FileBucket, setS3FileBucket] = useState()
  const [s3FileBucketRegion, setS3FileBucketRegion] = useState()
  const [sourceType, setSourceType] = useState()
  const [workflowLifecycle, setWorkflowLifecycle] = useState([])
  const [approvalsRequired, setApprovalsRequired] = useState()
  const [dsInfo, setDsInfo] = useState()
  const [tableItems, setTableItems] = useState([])
  const [columnsList, setColumnsList] = useState([])
  const [showViewFileModal, setShowViewFileModal] = useState(false)
  const [showApproveModal, setShowApproveModal] = useState(false)
  const [showRejectModal, setShowRejectModal] = useState(false)
  const [showDownloadAndView, setShowDownloadAndView] = useState(false)
  const [actionComments, setActionComments] = useState('')

  const currentUser = useSelector((globalState) => globalState.auth.userId)

  const helpContent = (
    <HelpPanel
      header={
        <div>
          <div></div>
          <h2>Additional Info </h2>
        </div>
      }
    ></HelpPanel>
  );

  useEffect(() => {
    loadData();
    return () => {
      configureGenericS3()
    };
  }, [currentStage]);

  useEffect(() => {

    generateFileContent(s3FilePath, s3FileBucket)

  }, [s3FilePath, s3FileBucket])

  useEffect(() => {

    if (dsInfo?.columns) {
      const definitions = dsInfo?.columns.map(colName => ({
        id: colName,
        header: colName,
        cell: item => item[colName] || '-'
      }))
      setColumnsList(definitions)
    }

  }, [dsInfo])

  useEffect(() => {

    checkIfUserCanDownloadAndView(approvers, requestedBy)

  }, [approvers, requestedBy])

  const checkIfUserCanDownloadAndView = async(approvers, requestedBy) => {
    let canShow = false
    if(requestedBy == currentUser){
      canShow = true
    }else{
      for(let i=0;i<approvers.length;i++){
        let approverItem = approvers[i]
        for(let j=0;j<approverItem.actors?.length;j++){
          if(approverItem.actors[j].type == 'USER' && approverItem.actors[j].identifier == currentUser){
            canShow = true
            break
          }else if(approverItem.actors[j].type == 'GROUP'){
              let alias = approverItem.actors[j].identifier.split('(')[0]
              if(await isMemberOf(currentUser, alias, currentStage)){
                canShow = true
                break
              }
          }
        }
        if(canShow){
          break
        }
      }
    }
    
    setShowDownloadAndView(canShow)
  }

  const setAppIdFromTags = (response) => {
    try {
      let tags = response['workflowInstances'][0]['tags']
      tags.forEach((tag) => {
        if (tag?.key && tag.key == 'app_id') {
          dispatch(setCurrentAppId(tag.value))
        }
      })
    } catch (err) {
    }
  }
  const loadData = async () => {
    setShowLoadingIcon(true);


    const details = {
      workflowInstanceId: workflowInstanceId,
      stage: currentStage,
      userAlias: currentUser
    }
    const response = await getWasWorkflowById(details)
    setAppIdFromTags(response)
    const workflowInstance = response["workflowInstances"][0]
    setTitle(workflowInstance.name)
    setDescription(workflowInstance.description)
    setStatus(workflowInstance.status)
    setCreatedAt(workflowInstance.createdOn)
    setRequestedBy(workflowInstance.createdBy)
    setWorkflowLifecycle(workflowInstance.response_data)
    const roles = workflowInstance.roles

    const approverLevels = roles.filter((item) => item.name.startsWith('Approver')).map((i) => i.actors)
    const approveStates = workflowInstance.states.map((i) => i.nextStates.filter((item) => (item.action === "Approve"))).map((j) => j[0])
    const approvers = []
    approverLevels.forEach((item, index) => {
      const result = {
        actors: approverLevels[index],
        responsesNeeded: Object.values(approveStates[index].responsesNeeded).reduce((total, currentVal) => total + parseInt(currentVal), 0),
        title: `Level - ${index + 1}${index + 1 === approverLevels.length ? ' (Final Level)' : ''}`
      }
      approvers.push(result)
    })

    setApprovers(approvers)
    const tags = workflowInstance.tags
    setS3FilePath(tags.find((item) => (item.key === 'file_path'))?.value)
    setS3FileBucket(tags.find((item) => (item.key === 'file_bucket_name'))?.value)
    setS3FileBucketRegion(tags.find((item) => (item.key === 'file_bucket_region'))?.value)
    setSourceType(tags.find((item) => (item.key === 'source_type'))?.value)
    const actionsResponse = await getAllowedActions(details)
    setAllowedActions(actionsResponse)
    setShowLoadingIcon(false);
  };


  const performAction = async (action) => {

    setShowLoadingIcon(true)
    const details = {
      eventTriggeredBy: currentUser,
      stage: currentStage,
      eventType: action,    // Possible values: Approve, Reject, Cancel
      workflowInstanceId: workflowInstanceId
    }
    if (actionComments.trim().length >= 1) {
      details['comments'] = actionComments
    }
    const response = await performActionOnWorkflow(details)
    setShowApproveModal(false)
    setShowRejectModal(false)
    loadData()

  };

  const determineModal = (action) => {

    setActionComments('')
    if (action === 'Approve') {
      setShowApproveModal(true)

    } else if (action === 'Reject') {
      setShowRejectModal(true)
    } else if (action === 'Cancel') {
      performAction(action)
    }

  }


  const downloadFile = async (path, bucket) => {
    if (bucket) {
      let region = s3FileBucketRegion ? s3FileBucketRegion : S3_CONFIG.find((c) => c.environment === currentStage)?.region
      configureDynamicS3(bucket, region)
    }
    if (path) {
      let fn = path
      let prefix = 'public/'
      if (sourceType && sourceType.includes("FRISCO")) {
        fn = fn.replace('frisco/', '')
        prefix = 'frisco/'
      }else if (sourceType && sourceType.includes("FLEXICO_LOAD_APPROVAL")) {
        fn = fn.replace('flexico/', '')
        prefix = 'flexico/'
      } else {
        fn = fn.replace('public/', '')
      }

      const result = await Storage.get(fn, { level: 'public', customPrefix: { public: prefix }, expires: 60 });
      window.open(result);
    }
    else {
      window.open("");
    }

  }

  const generateFileContent = async (path, bucket) => {
    if (bucket) {
      let region = s3FileBucketRegion ? s3FileBucketRegion : S3_CONFIG.find((c) => c.environment === currentStage)?.region
      configureDynamicS3(bucket, region)
    }
    if (path) {
      let fn = path
      let prefix = 'public/'
      if (sourceType && sourceType.includes("FRISCO")) {
        fn = fn.replace('frisco/', '')
        prefix = 'frisco/'
      } else if (sourceType && sourceType.includes("FLEXICO_LOAD_APPROVAL")) {
        fn = fn.replace('flexico/', '')
        prefix = 'flexico/'
      }else {
        fn = fn.replace('public/', '')
      }

      const fileURL = await Storage.get(fn, { level: 'public', customPrefix: { public: prefix }, expires: 60 });
      let csvdata = await d3.csv(fileURL).then((result) => {
        setDsInfo(result);

        // setState here..
      });
    }
  }

  const viewFile = () => {
    setShowViewFileModal(true)
  }

  const content = (
    <div>
      <Box>
        <Box>
          <Grid
            gridDefinition={[{ colspan: 10 },
            { colspan: 2 }]}
          >
            <SpaceBetween size="xxl">
              <div>
                <Container
                  header={
                    <Header
                      variant="awsui-h1-sticky"
                      actions={
                        <SpaceBetween direction="horizontal" size="xs">
                          <Button
                            variant="normal"
                            iconName="close"
                            onClick={() => {
                              history.push("/");
                            }}
                          ></Button>
                        </SpaceBetween>
                      }
                    >
                      Workflow Details
                    </Header>
                  }
                >
                  <ColumnLayout columns={3}>
                    <Box>
                      <Box variant="awsui-key-label">Workflow Instance Id</Box>
                      <Box>{workflowInstanceId}</Box>
                    </Box>
                    <Box>
                      <Box variant="awsui-key-label">Workflow Title</Box>
                      <Box>{title}</Box>
                    </Box>
                    <Box>
                      <Box variant="awsui-key-label">Description</Box>
                      <Box>{description}</Box>
                    </Box>
                    <Box>
                      <Box variant="awsui-key-label">Status</Box>
                      <Box>{status} &nbsp;
                        {status === 'completed' ? <Icon name="thumbs-up" variant="success" /> :
                          status === 'rejected' ? <Icon name="thumbs-down" variant="error" /> :
                            status === 'cancelled' ? <Icon name="status-negative" /> :
                              status === 'pending' ? <Icon name="status-in-progress" /> :
                                <></>
                        }
                      </Box>
                    </Box>
                    <Box>
                      <Box variant="awsui-key-label">Created At</Box>
                      <Box>{moment(new Date(Number(createdAt))).format()}</Box>
                    </Box>
                    <Box>
                      <Box variant="awsui-key-label">Requested By</Box>
                      <img
                        alt=""
                        className="rounded-circle"
                        src={
                          "https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=" +
                          `${requestedBy}`
                        }
                        width={20}
                        height={20}
                      />
                      &nbsp;
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={"https://phonetool.amazon.com/users/" + requestedBy}
                      >
                        {requestedBy}
                      </a>
                    </Box>
                  </ColumnLayout>
                </Container>
              </div>
              <div>
                <Box>
                  <br></br>

                  <SpaceBetween direction="horizontal" size="xs">
                    <Button
                      variant="normal"
                      iconName="refresh"
                      onClick={() => loadData()}
                    >
                      Refresh
                    </Button>
                    {ShowLoadingIcon ? (
                      <Modal visible={ShowLoadingIcon}>
                        <Box>
                          <Spinner></Spinner>
                          Loading...
                        </Box>
                      </Modal>
                    ) : (
                      <Box></Box>
                    )}
                  </SpaceBetween>
                  <br />

                  <Tabs
                    variant="container"
                    activeTabId={activeTabId}
                    onChange={({ detail }) => setActiveTabId(detail.activeTabId)}
                    tabs={[
                      {
                        label: "Next Steps",
                        id: "details",
                        content: (
                          <>
                            <Modal
                              onDismiss={() => { setShowViewFileModal(false) }}
                              visible={showViewFileModal}
                              size="max"
                              header="Review File"
                              footer={
                                <Box float="right">
                                  <>
                                    <Button variant={"primary"} onClick={() => setShowViewFileModal(false)}>Close</Button>
                                  </>
                                </Box>

                              }
                            >
                              <Table
                                columnDefinitions={columnsList}
                                items={dsInfo}
                                stickyHeader={true}
                                resizableColumns={true}
                              >

                              </Table>
                            </Modal>

                            <Modal
                              onDismiss={() => {
                                setShowApproveModal(false)
                                setActionComments('')
                              }}
                              visible={showApproveModal}
                              header={<Header
                                variant="h3"
                                description="Provide any comments you may have"
                              >
                                Approve <Icon name="thumbs-up-filled" variant="success" />
                              </Header>}
                              footer={
                                <Box float="right">
                                  <SpaceBetween direction="horizontal" size="xs">
                                    <Button onClick={() => {
                                      setShowApproveModal(false)
                                      setActionComments('')
                                    }}>Go Back</Button>
                                    <Button variant={"primary"}
                                      iconAlign="right"
                                      iconName="thumbs-up"
                                      onClick={() => { performAction('Approve') }}>Approve</Button>
                                  </SpaceBetween>
                                </Box>

                              }
                            >
                              <FormField>
                                <Textarea
                                  onChange={({ detail }) => setActionComments(detail.value)}
                                  value={actionComments}
                                  placeholder="Add Comments (Optional)"
                                />
                              </FormField>
                            </Modal>

                            <Modal
                              onDismiss={() => {
                                setShowRejectModal(false)
                                setActionComments('')
                              }}
                              visible={showRejectModal}
                              header={<Header
                                variant="h3"
                                description="Please note that rejecting this request will end workflow and prevent others from taking action.
                        After rejecting there is no way to reactivate/restart this request and a new request will need to be created. If you are sure you want to reject this request, please proceed below."
                              >
                                Reject <Icon name="thumbs-down-filled" variant="error" />
                              </Header>}
                              footer={
                                <Box float="right">
                                  <SpaceBetween direction="horizontal" size="xs">
                                    <Button onClick={() => {
                                      setShowRejectModal(false)
                                      setActionComments('')
                                    }}>Go Back</Button>
                                    <Button variant={"primary"}
                                      iconAlign="right"
                                      iconName="thumbs-down" onClick={() => { performAction('Reject') }}>Reject</Button>
                                  </SpaceBetween>
                                </Box>

                              }
                            >
                              <FormField>
                                <Textarea
                                  onChange={({ detail }) => setActionComments(detail.value)}
                                  value={actionComments}
                                  placeholder="Add Comments (Optional)"
                                />
                              </FormField>
                            </Modal>
                            <ColumnLayout columns={2} borders="vertical">
                              <Box>
                                  <Grid gridDefinition={[{ colspan: 2 }, { colspan: 10 }]}>
                                  <Box
                                    fontSize="display-l"
                                    fontWeight="bold"
                                    color="text-body-secondary"
                                  >
                                    1
                                  </Box>
                                  <Box>
                                    <SpaceBetween direction="vertical" size="l">
                                      <Box color="text-body-secondary">
                                        Download or view the file to review before taking an
                                        action
                                      </Box>
                                      <Box>
                                        <SpaceBetween direction="horizontal" size="l">
                                          <Button variant="primary"
                                            iconName="download"
                                            onClick={() => downloadFile(s3FilePath, s3FileBucket)}
                                            disabled={!showDownloadAndView}
                                          >
                                            Download
                                          </Button>
                                          {
                                            s3FilePath?.endsWith('.csv') ? <Button
                                              iconName="search"
                                              onClick={() => viewFile()}
                                              disabled={!showDownloadAndView}
                                            >
                                              View
                                            </Button> : <></>
                                          }
                                          
                                        </SpaceBetween>
                                      </Box>
                                    </SpaceBetween>

                                    <br></br>
                                  </Box>
                                </Grid>
                                
                              </Box>
                              <Box>
                                {
                                  status === 'pending' ? <Grid gridDefinition={[{ colspan: 2 }, { colspan: 10 }]}>
                                    <Box
                                      fontSize="display-l"
                                      fontWeight="bold"
                                      color="text-body-secondary"
                                    >
                                      2
                                    </Box>
                                    <Box>
                                      <SpaceBetween direction="vertical" size="l">
                                        <Box color="text-body-secondary">
                                          Perform Action on workflow after reviewing.
                                        </Box >
                                        {allowedActions.length === 0 ?
                                          <Box color="text-body-secondary">
                                            <br />
                                            You have no action pending or not authorized to peform action on this workflow!
                                          </Box>
                                          :
                                          <Box>
                                            <SpaceBetween direction="horizontal" size="l">

                                              {allowedActions.map((action) => (
                                                <Button
                                                  variant={action === 'Approve' ? "primary" : "normal"}
                                                  iconAlign="right"
                                                  iconName={action === 'Approve' ? "thumbs-up" : action === 'Reject' ? "thumbs-down" : "status-negative"}
                                                  onClick={() => { determineModal(action) }}
                                                >
                                                  {action}
                                                </Button>
                                              ))}
                                            </SpaceBetween>

                                          </Box>
                                        }

                                      </SpaceBetween>

                                      <br></br>
                                    </Box>
                                  </Grid> : <Box color="text-body-secondary" variant="h2">
                                    <Icon name="lock-private" />
                                    <br></br>
                                    Action on this workflow is taken, no further action required!
                                  </Box>
                                }

                              </Box>
                            </ColumnLayout>

                          </>
                        ),
                      },
                    ]}
                  />

                </Box>
              </div>

              <div>
                <Container
                  header={
                    <Header
                      variant="h3"
                    >
                      Workflow Lifecycle
                    </Header>
                  }
                >
                  <Box><ul style={{ listStyleType: "none", paddingLeft: "0px" }}><SpaceBetween direction="horizontal" size="m">{workflowLifecycle.map((action, index) => {
                    if (action.response === 'Approve') {
                      return (
                        <li key={index}>
                          <Alert
                            statusIconAriaLabel="success"
                            type="success"
                            header={`Approved`}
                          >
                            by&nbsp;
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href={"https://phonetool.amazon.com/users/" + action.userAlias}
                            >
                              {action.userAlias}
                            </a>
                            &nbsp;at {moment(new Date(Number(action.timeStamp))).format()}
                            {action?.comments ?
                              <ExpandableSection headerText="User comments" variant="navigation">
                                {action.comments}
                              </ExpandableSection>
                              :
                              <></>
                            }
                            {action?.resolvedGroups && action?.resolvedGroups.length >= 1 ?
                              <ExpandableSection headerText="Resolved groups" variant="navigation">
                                <Box> <ul style={{ listStyleType: "none", paddingLeft: "0px" }}>
                                  {action.resolvedGroups.map((item) => (
                                    <li key={item}>{item}</li>
                                  ))}
                                </ul></Box>
                              </ExpandableSection>
                              :
                              <></>
                            }
                          </Alert>
                          <br />
                        </li>
                      )
                    } else if (action.response === 'Reject') {
                      return (
                        <li key={index}>
                          <Alert
                            statusIconAriaLabel="error"
                            type="error"
                            header={`Rejected`}
                          >
                            by&nbsp;
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href={"https://phonetool.amazon.com/users/" + action.userAlias}
                            >
                              {action.userAlias}
                            </a>
                            &nbsp;at {moment(new Date(Number(action.timeStamp))).format()}
                            {action?.comments ?
                              <ExpandableSection headerText="User comments" variant="navigation">
                                {action.comments}
                              </ExpandableSection>
                              :
                              <></>
                            }
                            {action?.resolvedGroups && action?.resolvedGroups.length >= 1 ?
                              <ExpandableSection headerText="Resolved groups" variant="navigation">
                                <Box> <ul style={{ listStyleType: "none", paddingLeft: "0px" }}>
                                  {action.resolvedGroups.map((item) => (
                                    <li key={item}>{item}</li>
                                  ))}
                                </ul></Box>
                              </ExpandableSection>
                              :
                              <></>
                            }
                          </Alert>
                          <br />
                        </li>
                      )
                    } else if (action.response === 'Cancel') {
                      return (
                        <li key={index}>
                          <Alert
                            statusIconAriaLabel="warning"
                            type="warning"
                            header={`Canceled`}
                          >
                            by&nbsp;
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href={"https://phonetool.amazon.com/users/" + action.userAlias}
                            >
                              {action.userAlias}
                            </a>
                            &nbsp;at {moment(new Date(Number(action.timeStamp))).format()}
                          </Alert>
                          <br />
                        </li>
                      )
                    } else if (action.response === null) {
                      return (
                        <li key={index}>
                          <Alert
                            statusIconAriaLabel="info"
                            type="info"
                            header={`Created`}
                          >
                            by&nbsp;
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href={"https://phonetool.amazon.com/users/" + action.userAlias}
                            >
                              {action.userAlias}
                            </a>
                            &nbsp;at {moment(new Date(Number(action.timeStamp))).format()}
                          </Alert>
                          <br />
                        </li>
                      )
                    }

                  })}</SpaceBetween></ul></Box>
                </Container>
              </div>
            </SpaceBetween>
            <div>
              <Container
                header={
                  <Header
                    variant="h3"
                  >
                    Approver Details
                  </Header>
                }
              >

                <Box>
                  <SpaceBetween size="s">
                    {approvers.map((item) => {
                      return (<Alert
                        statusIconAriaLabel="info"
                        type="info"
                        header={item.title}
                      >
                        <>
                          <br />
                          <span style={{ fontWeight: "bold" }}>Approvers:</span>
                          <ul style={{ listStyleType: "none", padding: "0px", margin: "0px" }}>

                            {
                              item.actors.map((i, index) => {
                                if (i.type === 'USER') {
                                  return (
                                    <li key={index}>
                                      <a
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href={"https://phonetool.amazon.com/users/" + i.identifier}
                                      >
                                        {i.identifier}
                                      </a>
                                    </li>
                                  )
                                } else {
                                  return (<li key={index}>{i.identifier}</li>)
                                }
                              })
                            }

                          </ul>
                          <br />
                          <b>No of Approvals Required: </b> {item.responsesNeeded}

                        </>
                      </Alert>)

                    })}
                  </SpaceBetween>
                </Box>

              </Container>
            </div>
          </Grid>
        </Box>
        <Box>

        </Box>
        <br />
        <Feedback appId={'Generic'} pageId={`Approval_details_${workflowInstanceId}`} userId={currentUser} parentId={"null"} level={0} limit={1}></Feedback>

      </Box>
    </div>
  );
  return (
    <AppLayout
      content={content}
      headerSelector="#TopBar"
      navigationHide={true}
      tools={helpContent}
      maxContentWidth={Number.MAX_VALUE}
      headerVariant="high-contrast"
    ></AppLayout>
  );
};

export default WorkflowInstance;
