import React from "react";
import {
    Multiselect
} from "@amzn/awsui-components-react";

const CustomMultiSelect = (props) => {
    const mergeSelections = (selectedOptions) => {
        let values = []
        selectedOptions.forEach((option) => {
            values.push(option.value)
        })

        if(values && values.length > 0){
            return values
        }else{
            return null
        }
    }
    return (
        <Multiselect
            selectedOptions={props.getSelectedOptions(props.field)}
            onChange={({ detail }) =>{
                props.updateValue(props.field, mergeSelections(detail.selectedOptions))
            }
            }
            options={props.getValues(props.field)}
            placeholder="Choose options"
        />

    )
}

export default CustomMultiSelect;