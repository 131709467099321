import { APP_FAV_ABC } from '../constants/AppConstants'
import { API, graphqlOperation } from "aws-amplify";

export const addFavorite = async (appId, userId, label, onSuccess, onFailure) => {
  let qry = `mutation MyMutation {
        createUserPreferences(input: {label: "${label}", preference_identifier: "${appId}", user_and_preference: "${userId}#${APP_FAV_ABC}"}) {
          preference_identifier
        }
      }
      `
  await executeQuery(qry, onSuccess, onFailure)

}

export const delFavorite = async (appId, userId, onSuccess, onFailure) => {
  let qry = `mutation MyMutation {
    deleteUserPreferences(input: {preference_identifier: "${appId}", user_and_preference: "${userId}#${APP_FAV_ABC}"}) {
      preference_identifier
    }
  }
      `
  await executeQuery(qry, onSuccess, onFailure)

}

export const listFavorites = async (userId, onSuccess, onFailure) => {
  let qry = `query MyQuery {
    listUserPreferences(limit: 2000, filter: {user_and_preference: {eq: "${userId}#${APP_FAV_ABC}"}}) {
      items {
        preference_identifier
        label
      }
    }
  }
      `
  await executeQuery(qry, onSuccess, onFailure)
}

export const getFavorite = async (appId, userId, onSuccess, onFailure) => {
  let qry = `query MyQuery {
    getUserPreferences(preference_identifier: "${appId}", user_and_preference: "${userId}#${APP_FAV_ABC}") {
      preference_identifier
      label
      user_and_preference
    }
  }
      `
  await executeQuery(qry, onSuccess, onFailure)
}

const executeQuery = async (qry, onSuccess, onFailure) => {
  await API.graphql(graphqlOperation(qry)).then(result => {
    onSuccess(result)
  }, function (error) {
    onFailure(error)
  });
}

export const checkIsFavorite = async (appId, userId, onResult) => {
  await getFavorite(appId, userId, function onSuccess(result) {
    if (result?.data?.getUserPreferences?.preference_identifier === appId) {
      onResult(true)
    } else {
      onResult(false)
    }
  },
    function onFailure(result) {
      onResult(false)
    })
}

export const favorite = async (appId, userId, onAdded, onDeleted, onFailure) => {
  await checkIsFavorite(appId, userId, function (isFavorite){
    if (isFavorite) {
      delFavorite(appId, userId, function (result) {
        onDeleted(result)
      }, function (result) { 
        onFailure(result)
      })
    } else {
      addFavorite(appId, userId, "default", function (result) {
        onAdded(result)
      }, function (result) { 
        onFailure(result)
      })
    }
  })
}