import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { useHistory, useParams } from "react-router-dom";

import './styles.scss';

import {
    AppLayout, HelpPanel, Icon, BreadcrumbGroup, Box, Alert, ExpandableSection, Tiles,
    Container, Header, ColumnLayout, Spinner, Link, Tabs, Button, Table, SpaceBetween, Modal, FileUpload
} from '@amzn/awsui-components-react';
import SideNav from './SideNav';
import { useAuth } from "../../context/AuthContextProvider";
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import Amplify, { Storage } from 'aws-amplify';
import { getFriscoS3Config } from 'src/utilities/AWSServices';

import MJEmonitoringSampleFile from '../../images/MJEMonitoringSampleFile.png'
import { PORTAL } from "src/constants/AppConstants";
import { setCurrentAppId } from '../../store/userAuthSlice';
import * as XLSX from 'xlsx';
import { getMappingById, getProcessById, createFriscoTransactions, getNormalisedColumnName } from "src/Ateam-BI/Templates/Utils/AppActivityUtils"
import { FRISCO_NEGATIVE_STATUS_FOR_UPLOAD, FRISCO_POSITIVE_STATUS, FRISCO_STATUS, PROCESS_KEYS } from './Constants';
import { configureFriscoS3, configureGenericS3 } from 'src/context/AuthContextUtility'
import { isUploaderAllowed } from './AccessUtilities';
import {request as requestPlugin} from 'src/plugins/CorpReportingUploadAccess'
import { getQuarterForUpload } from '../CorpReporting/Utility';
export default (props) => {
    const userId = useSelector((globalState) => globalState.auth.userId)
    const currentStage = useSelector((globalState) => globalState.auth.currentStage)
    
    const history = useHistory();
    const userAuthenticationDetails = useAuth();
    const userLDAPS = userAuthenticationDetails.USER_LDAP_GROUPS;
    let { id } = useParams();
    let { pid } = useParams();
    const [processData, setProcessData] = useState({})
    const [isCheckingAccess, setIsCheckingAccess] = useState(true);
    const [isAuthorized, setIsAuthorized] = useState();
    const [mappingId, setMappingId] = useState('');
    const dispatch = useDispatch()
    const [activeTabId, setActiveTabId] = useState("first");
    const [isFileSelected, setIsFileSelected] = useState(false);
    const [showFileNotSeleted, setShowFileNotSeleted] = useState(false);
    const currentAppId = useSelector((globalState) => globalState.auth.currentAppId)
    const isCurrentAppAuthorised = useSelector((globalState) => globalState.auth.isCurrentAppAuthorized)
    const corpReportingQuarter = useSelector((globalState)=>globalState.auth.corpReportingQuarter)
    const [showLoadingIcon, setShowLoadingIcon] = useState(false);
    const [columnsList, setColumnsList] = useState({})
    const [items, setItems] = useState([])
    const [previewItems, setPreviewItems] = useState([])
    const [uploadedFileName, setUploadedFileName] = useState('');
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [mainErrorMessage, setMainErrorMessage] = useState('');
    const [missingFields, setMissingFields] = useState(new Set());
    const [colsNotInRequiredOrder, setColsNotInRequiredOrder] = useState(false);
    const [colCountMoreThanExpected, setColCountMoreThanExpected] = useState(false);
    const [inputFiles, setInputFiles] = useState([]);
    const [supportedFormats, setSupportedFormats] = useState('.csv');
    const [loadingMessage, setLoadingMessage] = useState('loading, please wait..');
    const [showUploadBtn, setShowUploadBtn] = useState(false)
    const [fileReference, setFileReference] = useState('');
    const [inputSheets, setInputSheets] = useState([]);
    const [selectedSheet, setSelectedSheet] = useState('');
    const [backUrl, setBackUrl] = useState(null)
    const [taskId, setTaskId] = useState(null)
    const [submitDisabled, setSubmitDisabled] = useState(false);
    const [uploadAccessForTheQuarter, setUploadAccessForTheQuarter] = useState(false);
    const [rawFile, setRawFile] = useState(null)
    const [rawFileData, setRawFileData] = useState(null)
    const [providedCoulumns, setProvidedCoulumns] = useState([])
    const [comments, setComments] = useState(null)
    
    useEffect(() => {
        configureFriscoS3()
        if (pid) {
            loadData();
        }
        if(id){
            dispatch(setCurrentAppId(id))
        }
        setBackUrl(getParam('back'))
        setTaskId(getParam('taskId'))
        return () => {
            // this now gets called when the component unmounts
            configureGenericS3()
        };
    }, []);



    useEffect(() => {
        configureFriscoS3()
        return () => {
            // this now gets called when the component unmounts
        };
    }, [isCurrentAppAuthorised]);

    useEffect(() => {
        if (isAuthorized === true || isAuthorized === false) {
            setIsCheckingAccess(!isCheckingAccess);
        }
    }, [isAuthorized]);

    
    const getParam= (key) => {
        const search = props.location.search;
        const params = new URLSearchParams(search);
        const value = params.get(key);
        return value;
      }
    const loadData = () => {
        setLoadingMessage("loading, please wait...")
        setShowLoadingIcon(true);
        setShowUploadBtn(false)
        // setProcessData()
        getProcessById(pid, async function onSuccess(response) {
            const r = response.data.getProcessByIDV2
            const sorted = r[PROCESS_KEYS.schema].sort((a,b) => a.column_index - b.column_index)
            setProcessData(r)
            setSupportedFormats(getSupportedFormats(r))

            if(FRISCO_NEGATIVE_STATUS_FOR_UPLOAD.includes(r[PROCESS_KEYS.status])){
                setShowLoadingIcon(false);
                setShowUploadBtn(false)
                setErrorMessage(`Cannot upload input file. The table creation status is : ${r[PROCESS_KEYS.status]}`)
                return
            }
            let isAllowed = await isUploaderAllowed(r, userId, currentStage)
            if(!isAllowed){
                setShowLoadingIcon(false);
                setShowUploadBtn(false)
                setErrorMessage("Cannot upload input file. Please check with the owner of the process for access.")
            } else {
                getMappingById(id, pid, function onSuccess(response) {
                    setMappingId(response.data.getMappingIDV2.mapping_id)
                    setShowLoadingIcon(false);
                    setShowUploadBtn(true)
                },
                    function onFailure(response) {
                        // setErrorMessage("Could not get app mapping information")
                        setShowLoadingIcon(false);
                        setShowUploadBtn(true)
                    })
                    let quarter = getQuarterForUpload()
                requestPlugin({ appId: id, processId:pid, stage: currentStage, group: response.data.getProcessByIDV2[PROCESS_KEYS.group_id], quarter: quarter}, function onResponse(response) {
                    setUploadAccessForTheQuarter(response.result)
                    if (!response.result) {
                        setErrorMessage(`Cannot upload input file. A process is either in-progress or complete. Current status is '${response.message}'`)
                    }
                    if (response.comments) {
                        setComments(response.comments)
                    }  
                })
            }
        }, function onFailure(response) {
            setErrorMessage("Could not load process information")
            setShowLoadingIcon(false);
            setShowUploadBtn(true)
        })
    }

    const getAllowedFileFormats = () => {
        if (processData && processData.hasOwnProperty(PROCESS_KEYS.file_types) && processData[PROCESS_KEYS.file_types]) {
            return processData[PROCESS_KEYS.file_types]
        }
        return []
    }
    const getMandatoryColumnNames = () => {
        let mandatoryColumns = new Set()

        if (processData && processData.hasOwnProperty(PROCESS_KEYS.schema)) {
            processData[PROCESS_KEYS.schema].forEach((item) => {
                mandatoryColumns.add(item['column_name'])
            })
        }
        return mandatoryColumns
    }

    const isAllowedFileFormat = (format, allowedFileFormats) => {
        let isAllowed = false
        // console.log(format)
        if (!format) {
            isAllowed = false
        }
        let supportedFormats = []
        for (let i = 0; i < allowedFileFormats.length; i++) {
            if (allowedFileFormats[i] == 'CSV') {
                supportedFormats.push('text/csv')
                supportedFormats.push('text/plain')
                supportedFormats.push('text/x-csv')
                supportedFormats.push('application/vnd.ms-excel')
                supportedFormats.push('application/csv')
                supportedFormats.push('application/x-csv')
                supportedFormats.push('text/comma-separated-values')
                supportedFormats.push('text/x-comma-separated-values')
                supportedFormats.push('text/tab-separated-values')
            } else if (allowedFileFormats[i] == 'Excel') {
                supportedFormats.push('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
                supportedFormats.push('application/vnd.ms-excel.sheet.macroenabled.12')
            }
        }
        isAllowed = supportedFormats.some(item => format === item);
        return isAllowed
    }
    const validateInputFileFormat = (file) => {
        let allowedFileFormats = getAllowedFileFormats()
        let isAllowed = isAllowedFileFormat(file?.type, allowedFileFormats)
        if (!file?.type) {
            throw new Error("Please upload an input file")
        }
        if (!isAllowed) {
            throw new Error(`File format is not allowed : ${file?.type}`);
        }
        return isAllowed
    }

    const validateFileInputColumnNames = (inputColumnNames) => {
        let normalisedColumns = []
        inputColumnNames.forEach((col) => {
            normalisedColumns.push(getNormalisedColumnName(col))
        })
        let missingFields = new Set()
        missingFields.clear()
        let columnsNotInRequiredOrder = false;
        let mandatoryColumns = Array.from(getMandatoryColumnNames())
        // let inputColumnNamesSet = new Set(normalisedColumns);
        mandatoryColumns.forEach((column, index) => {
            if (!normalisedColumns.includes(column)) {
                missingFields.add(column)
            }
            if(mandatoryColumns[index] !== normalisedColumns[index]) {
                columnsNotInRequiredOrder = true;
            }
        });
        let columnCountMoreThanExpected = normalisedColumns.length > mandatoryColumns.length 
            return {'missingFields': missingFields, 'columnsNotInRequiredOrder': columnsNotInRequiredOrder, 
            'columnCountMoreThanExpected': columnCountMoreThanExpected, 'normalisedColumns': normalisedColumns}
    }

    const validateEmptyContents = (rowData) => {
        if (!rowData || rowData.length == 0) {
            throw new Error("The inputs rows are empty")
        }
    }

    const resetValidationErrorLogs = () => {
        setProvidedCoulumns([])
        setMissingFields(new Set())
        setColCountMoreThanExpected(false)
        setColsNotInRequiredOrder(false)
    }
    
    const postDataSelection = (workbook, sheet_name) => {
        setLoadingMessage("validating the uploaded file, please wait...")
        setShowLoadingIcon(true)
        const worksheet = workbook.Sheets[sheet_name]
        var readOptions = { type: 'binary', raw: false, defval: null, cellNF: false, cellDates: true, cellText: false }
        const jsonData = XLSX.utils.sheet_to_json(worksheet, readOptions)
        const headerInfo = XLSX.utils.sheet_to_json(worksheet, { header: 1, defval: "" })

        const colNames = headerInfo[0]
        resetValidationErrorLogs()
        let validationResponse = validateFileInputColumnNames(colNames)
        setMissingFields(validationResponse.missingFields)
        setProvidedCoulumns(validationResponse.normalisedColumns)
        setMissingFields(validationResponse.missingFields)
        setColCountMoreThanExpected(validationResponse.columnCountMoreThanExpected)
        setColsNotInRequiredOrder(validationResponse.columnsNotInRequiredOrder)

        if (validationResponse.missingFields.size >= 1 || validationResponse.columnsNotInRequiredOrder || validationResponse.columnCountMoreThanExpected) {
            setShowLoadingIcon(false)
            return
        }
        const definitions = colNames.map(colName => ({
            id: colName,
            header: colName,
            cell: item => item[colName] || '-'
        }))

        validateEmptyContents(jsonData)

        setColumnsList(definitions)

        setItems(jsonData)
        const slicedArray = jsonData.slice(0, 100);
        setPreviewItems(slicedArray)
        setShowLoadingIcon(false)
        setActiveTabId('second')
    }

    const formatInputSheetsJsonArray = (workbook) => {
        let result = []
        let sheets = workbook.SheetNames
        sheets.forEach((sheet) => {
            try {
                const worksheet = workbook.Sheets[sheet]
                const headerInfo = XLSX.utils.sheet_to_json(worksheet, { header: 1, defval: "" })
                const colNames = headerInfo[0]
                let validationResponse = validateFileInputColumnNames(colNames)
                if (validationResponse.missingFields.size >= 1 || validationResponse.columnsNotInRequiredOrder || validationResponse.columnCountMoreThanExpected) {
                    //ignore
                }else{
                    result.push({ label: sheet, value: sheet })
                }

            } catch (e) {

            }
        })
        return result
    }

    const processExcelSheet = async () => {
        try {
            setErrorMessage('')
            const file = inputFiles[0]
            const data = await file.arrayBuffer();
            const workbook = XLSX.read(data)
            setRawFile(file)
            setRawFileData(data)
            postDataSelection(workbook, selectedSheet)
        } catch (error) {
            setErrorMessage(error?.message)
            setLoadingMessage("")
            setShowLoadingIcon(false)
        }
    }

    const validateFileSize = (fileDesc) => {
        if(fileDesc.size/1000000 >= 200){
            throw ({error: 'FILE MAX LIMIT', message:"Max file limit is 200MB"})
        }else{
            setErrorMessage()
        }
    }

    const changeHandler = async (inputFiles) => {
        try {
            setLoadingMessage("loading file, please wait...")
            setShowLoadingIcon(true);
            setErrorMessage('')
            setIsFileSelected(true)
            setShowFileNotSeleted(false)
            const file = inputFiles[0]
            validateFileSize(file)
            validateInputFileFormat(file)

            setUploadedFileName(file.name)
            const data = await file.arrayBuffer();
            const workbook = XLSX.read(data)
            resetValidationErrorLogs()
            if (workbook.SheetNames.length == 1) {
                postDataSelection(workbook, workbook.SheetNames[0])
            } else {
                // show sheets
                let sheetsWithValidSchema = formatInputSheetsJsonArray(workbook)
                setInputSheets(sheetsWithValidSchema)
                setShowLoadingIcon(false);
                if (sheetsWithValidSchema.length <= 0) {
                    setErrorMessage("There are no sheets that match the required schema. Please upload valid file.")
                }
            }

        } catch (error) {
            setErrorMessage(error?.message)
            setLoadingMessage("")
            setShowLoadingIcon(false)
        }

    }

    const onSubmitClick = async () => {
        if (isFileSelected) {
            setSubmitDisabled(true)
            setShowLoadingIcon(true)
            let sessionId = uuidv4()

            let folder = `source/${sessionId}/`
            let fileName = folder + sessionId + '.csv'
            var ws = XLSX.utils.json_to_sheet(items);
            var csv = XLSX.utils.sheet_to_csv(ws);
            let level = "public"
            let prefix = 'frisco/'
            let s3_config = getFriscoS3Config()
            let bucket_name = s3_config.S3.aws_user_files_s3_bucket
            let dt = moment().format();
            setLoadingMessage("uploading file for processing, please wait...")
            configureFriscoS3()
            Storage.vault.put(fileName, csv, {
                contentType: 'text/csv',
                tagging: `userId=${userId}&app_id:${id}&process_id:${pid}&mappingId=${mappingId}&type=csv`,
                level: level,
                customPrefix: { public: prefix },
                acl: 'bucket-owner-full-control'
            }).then(result => {
                let quarter = null
                if (processData?.next_step == 'CORP_ADMIN_APPROVAL') {
                    quarter = getQuarterForUpload()
                }
                // console.log(processData?.next_step, processData?.next_step=='CORP_ADMIN_APPROVAL', quarter)
                createFriscoTransactions(sessionId, id, pid, FRISCO_STATUS.UPLOADED, bucket_name, `${prefix}${result?.key}`, "csv", Date.now(), userId, uploadedFileName, quarter, taskId,
                    function onSuccess(response) {
                        setFileReference(sessionId)
                        setShowSuccessModal(true)
                        setShowLoadingIcon(false)
                        setSubmitDisabled(false)
                    }, function onFailure(response) {
                        setMainErrorMessage(`File was uploaded successfully. But, there was an issue processing, fileId : ${sessionId}`)
                        setShowLoadingIcon(false)
                        setSubmitDisabled(false)
                    })
            }, function (error) {
                setMainErrorMessage("File was not uploaded successfully")
                setShowLoadingIcon(false)
                setSubmitDisabled(false)
            })

            if(rawFile){
                let rawFileName = folder + 'raw/' +  `${rawFile.name}`
                Storage.vault.put(rawFileName, rawFileData, {
                    tagging: `userId=${userId}&app_id:${id}&process_id:${pid}&mappingId=${mappingId}&type=${rawFile.type}`,
                    level: level,
                    customPrefix: { public: prefix },
                    acl: 'bucket-owner-full-control'
                }).then(result => {
                    setRawFile(null)
                    setRawFileData(null)
                }, function (error) {
                    setRawFile(null)
                    setRawFileData(null)
                })
            }
            
        }

    }

    const prevButtonClick = () => {
        setActiveTabId('first')
        setShowSuccessModal(false)
    }

    const cancelButtonClick = () => {
        setActiveTabId('first')
        setShowSuccessModal(false)
        setIsFileSelected(false)
        setInputFiles([])
        setSelectedSheet('')
        setInputSheets([])
    }

    const next1Click = () => {

        setActiveTabId('third')

    }

    const ModalOkClick = () => {
        history.push(`/App/${id}/process/${pid}/history`)
    }


    const getSupportedFormats = (input = processData, defValue = '.csv') => {
        let returnValue = new Set()
        returnValue.add(defValue)
        if (input && input.hasOwnProperty(PROCESS_KEYS.file_types)) {
            input[PROCESS_KEYS.file_types].forEach((item) => {
                if (item == 'CSV') {
                    returnValue.add('.csv')
                } else if (item == 'Excel') {
                    returnValue.add('.xls')
                    returnValue.add('.xlsx')
                    // returnValue.add('.xlsb')
                    returnValue.add('.xlsm')
                    // returnValue.add('.xltx')
                    // returnValue.add('.xltm')
                } else {
                    returnValue.add(defValue)
                }
            })
        }
        return Array.from(returnValue).join(",")
    }
    const onFollowHandler = (ev) => {

        ev.preventDefault();
        if (ev.detail.href) {
            history.push(ev.detail.href.substring(1));
        }
    }
    const content = (
        <div>
            <div>
                <SpaceBetween size='l'>
                    <Container
                        header={
                            <Header
                                variant='h2'
                                actions={
                                    <SpaceBetween direction="horizontal" size="xs">
                                        <Button variant='normal' onClick={() => { history.push(`/App/${id}/process/${pid}/history`) }}>
                                            View Upload History
                                        </Button>
                                    </SpaceBetween>
                                }
                                description=""
                            > Upload file history


                            </Header>
                        }
                    >
                        {
                            processData?.hasOwnProperty(PROCESS_KEYS.team) && processData?.hasOwnProperty(PROCESS_KEYS.business)
                                && processData?.hasOwnProperty(PROCESS_KEYS.dataset_name) ?
                                <SpaceBetween size='s' direction='horizontal'>
                                    <Box variant="h4"> Load table : </Box>
                                    {
                                        processData[PROCESS_KEYS.redshift_table_name] ?
                                            <Box>{processData[PROCESS_KEYS.redshift_table_name]}</Box> :
                                            <Box variant="p"> {processData[PROCESS_KEYS.team]}_{processData[PROCESS_KEYS.business]}_{processData[PROCESS_KEYS.dataset_name]}</Box>
                                    }                            </SpaceBetween> : <></>
                        }
                    </Container>


                    {showLoadingIcon ? (
                        <Box>
                            <Spinner size="normal"></Spinner>
                            {loadingMessage}
                        </Box>) : ''}

                    <Tabs
                        activeTabId={activeTabId}
                        variant='container'
                        tabs={[
                            {
                                activeTabId: { activeTabId },
                                label: "Step 1 : Upload File",
                                id: "first",
                                content: (
                                    <div>
                                        <br></br>
                                        <ColumnLayout borders="vertical" columns={2} >
                                            <SpaceBetween direction='vertical' size='m'>
                                                {showFileNotSeleted ? <Alert type='error'>File Not Selected</Alert> : ''}
                                                {
                                                    uploadAccessForTheQuarter && showUploadBtn ? <FileUpload
                                                        onChange={({ detail }) => { setInputFiles(detail.value); setSelectedSheet(''); setInputSheets([]); changeHandler(detail.value) }}
                                                        value={inputFiles}
                                                        i18nStrings={{
                                                            uploadButtonText: e =>
                                                                e ? "Choose files" : "Choose file",
                                                            dropzoneText: e =>
                                                                e
                                                                    ? "Drop files to generate"
                                                                    : "Drop file to generate",
                                                            removeFileAriaLabel: e =>
                                                                `Remove file ${e + 1}`,
                                                            limitShowFewer: "Show fewer files",
                                                            limitShowMore: "Show more files",
                                                            errorIconAriaLabel: "Error"
                                                        }}
                                                        showFileLastModified
                                                        showFileSize
                                                        showFileThumbnail
                                                        tokenLimit={1}
                                                        accept={supportedFormats}
                                                        constraintText="upload input file"
                                                    /> : <Box></Box>
                                                }
                                                {inputSheets && inputSheets.length > 0 ?
                                                    <SpaceBetween size='s'>
                                                        <Box variant='h5'> Please choose a sheet from the uploaded file for processing, and click proceed</Box>
                                                        <Tiles
                                                            onChange={({ detail }) => {
                                                                setSelectedSheet(detail.value)
                                                            }}
                                                            value={selectedSheet}
                                                            items={inputSheets}
                                                        />
                                                        <Box float="right">
                                                            <Button variant="primary" disabled={!selectedSheet} onClick={processExcelSheet} >Proceed</Button>
                                                        </Box>
                                                    </SpaceBetween> : <></>}

                                                {
                                                    comments ? <Alert
                                                        statusIconAriaLabel="Info"
                                                        header="Dates"
                                                    >
                                                        {comments?.split("\n")?.map((t, key) => {
                                                            return <p key={key}>{t}</p>;
                                                        })}


                                                    </Alert> : ''
                                                }

                                                {errorMessage? <Alert statusIconAriaLabel="Error"
                                                type="error"
                                                header="Upload restricted"> 
                                                {errorMessage} </Alert>:''}

                                                { (missingFields.size >= 1 || colCountMoreThanExpected || colsNotInRequiredOrder) ? (
                                                    <>
                                                    <Box>
                                                    <h4>Validation checks:</h4>
                                                    <ul>
                                                        <li>
                                                        All required fields available? {missingFields.size >= 1 ? <Icon name="status-negative"  variant="error"/> : <Icon name="status-positive"  variant="success"/>}
                                                        </li>
                                                        <li>
                                                        Provided column(s) count is less than or equal to expected column count? {colCountMoreThanExpected ? <Icon name="status-negative"  variant="error"/> : <Icon name="status-positive"  variant="success"/>}
                                                        </li>
                                                        <li>
                                                        Column order as expected? (Please refer required column order on the right) {colsNotInRequiredOrder ? <Icon name="status-negative"  variant="error"/> : <Icon name="status-positive"  variant="success"/>}
                                                        </li>
                                                    </ul>
                                                    </Box>
                                                {
                                                    (missingFields.size >= 1 && 
                                                    
                                                    <Alert
                                                        statusIconAriaLabel="Error"
                                                        type="error"
                                                        header="Required fields are missing"
                                                    >
                                                    <SpaceBetween>
                                                     <Box><h4>Missing Fields:</h4>
                                                     <ul>
                                                        {Array.from(missingFields).map((field) => (
                                                            <li key={field}>{field}</li>
                                                        ))}
                                                     </ul>
                                                     </Box>  
                                                     <Box><h4>Provided Fields:</h4>
                                                     <ul>
                                                     {providedCoulumns.map((field) => (
                                                            <li key={field}>{field}</li>
                                                        ))}
                                                     </ul>
                                                     </Box>
                                                     </SpaceBetween>
                                                    </Alert>
                                                    )
                                                }
                                                
                                                </>
                                                ):<></>

                                                }
                                                
                                            </SpaceBetween>


                                


                                            <Box>
                                                <ExpandableSection header="The file should contain the following columns in below order." 
                                                defaultExpanded>
                                                    <ul>
                                                        {
                                                            Array.from(getMandatoryColumnNames()).map(
                                                                column => <li
                                                                    value={column}
                                                                    key={column}>
                                                                    <span>{column}</span>
                                                                    <span className='missing'> {(missingFields && missingFields.size > 0 && missingFields.has(column)) ? ' - required' : ''} </span>
                                                                </li>
                                                            )
                                                        }
                                                    </ul>
                                                </ExpandableSection>

                                            </Box>


                                        </ColumnLayout>

                                    </div>

                                )
                            },
                            {
                                label: "Step 2 : Review File",
                                id: "second",
                                content: (
                                    <Box>
                                        <Header
                                            variant="h2"
                                            description="File preview - Read Only"
                                            actions={
                                                <SpaceBetween
                                                    direction="horizontal"
                                                    size="xs"
                                                >
                                                    <Button variant="normal" onClick={prevButtonClick}>Previous</Button>

                                                    <Button variant="primary" onClick={next1Click}>Next</Button>
                                                    <Box></Box>
                                                </SpaceBetween>
                                            }
                                        />
                                        <Table
                                            columnDefinitions={columnsList}
                                            items={previewItems}
                                            stickyHeader={true}
                                            resizableColumns={true}
                                        >

                                        </Table>
                                    </Box>
                                )
                            },
                            {
                                label: "Step 3 : Submit",
                                id: "third",
                                content: (<div>
                                    <Header
                                        variant="h2"
                                        description="File Details"
                                        actions={
                                            <SpaceBetween
                                                direction="horizontal"
                                                size="xs"
                                            >
                                                <Button variant="normal" onClick={cancelButtonClick} disabled={submitDisabled}>Cancel</Button>
                                                <Button variant="primary" onClick={onSubmitClick} disabled={submitDisabled}>Submit</Button>
                                            </SpaceBetween>
                                        }
                                    />


                                    <ColumnLayout columns={3}>
                                        < Box>
                                            Filename: {uploadedFileName}
                                            {mainErrorMessage && (
                                                <div className="custom-new-upload__error-message"> {mainErrorMessage} </div>
                                            )}
                                        </Box>
                                    </ColumnLayout>
                                </div>),
                            }
                        ]}
                    />
                </SpaceBetween>

                <Modal
                    visible={showSuccessModal}
                    footer={
                        <Box float="right">
                            <SpaceBetween direction="horizontal" size="xs">
                                <Button variant="primary" onClick={ModalOkClick}>Ok</Button>
                            </SpaceBetween>
                        </Box>
                    }
                    header="File Upload Status"
                >
                    <Box>
                        <SpaceBetween size='m' direction='vertical'>
                            <p>
                                <Icon variant="success" size='medium' name='status-positive'></Icon>
                                &nbsp; &nbsp;
                                Your file has been uploaded for processing. <br></br>
                                File reference : {fileReference}
                            </p>

                        </SpaceBetween>

                    </Box>

                    <br></br>

                </Modal>
            </div>
        </div>
    )
    return (
        <AppLayout
            content={content}
            navigation={<SideNav activeHref={`/App/${id}/process/${pid}/upload`} id={id} pid={pid} process={processData} back={backUrl} />}
            headerSelector="#TopBar"
            navigationHide={false}
            disableBodyScroll={true}
            toolsHide={true}
            headerVariant="high-contrast"
        ></AppLayout>
    );
}