import React, { useState, useEffect, useMemo } from 'react';
import SideNav from './Home/SideNav';
import { Link } from 'react-router-dom'

import {
  AppLayout, BreadcrumbGroup,
  Container, Box, Cards, Icon, Header,
  PropertyFilter, Badge
} from '@amzn/awsui-components-react';

import './Home/homepage.scss';
import { useSelector, useDispatch } from 'react-redux'


import { useHistory } from 'react-router-dom';

import Favorite from 'src/generic-components/Favorite';

const Favorites = () => {
  const history = useHistory()
  const [NavOpen, setNavOpen] = useState(true);
  const userId = useSelector((globalState) => globalState.auth.userId);


  const onFollowHandler = (ev) => {
    ev.preventDefault();
    if (ev.detail.href) {
      history.push(ev.detail.href.substring(1));
    }
  }
  const breadItems = [
    { text: 'Home', href: '/Home' },
    { text: 'Apps', href: '' }

  ];

  const Content = (
    <Box>
      <br></br>

      <Container>
        <Favorite userId={userId} customSideNavitems={SideNav} />
      </Container>
    </Box>

  )
  return (
    <AppLayout
      breadcrumbs={<BreadcrumbGroup items={breadItems} onFollow={(event) => onFollowHandler(event)}></BreadcrumbGroup>}
      disableContentPaddings={false}
      navigation={<SideNav activeHref="/Favorites" />}
      navigationOpen={NavOpen}
      content={Content}
      contentType="default"
      headerSelector="#TopBar"
      toolsHide={true}
      onNavigationChange={() => {
        setNavOpen(!NavOpen);
      }}
      headerVariant="high-contrast"
    />
  )
}

export default Favorites

