import React, { useState, useEffect, useRef} from 'react'
import { useSelector , useDispatch} from 'react-redux'

import {  AppLayout, HelpPanel,  Box, SpaceBetween, Header, Button, BreadcrumbGroup,
            Container,  Alert, Icon, Spinner, ColumnLayout, ExpandableSection, Checkbox, Badge,   
            Wizard, Input, FormField,Tiles, Select, Table, Toggle

        } from '@amzn/awsui-components-react';


import SideNav from './SideNav';
import appSettings from './app_settings';
import moment from 'moment';


import { useHistory , useLocation} from "react-router-dom";
import AccessDenied from '../../AccessDenied'
import WaitForPageAccess from '../../WaitForPageAccess'
import { setCurrentAppId } from '../../store/userAuthSlice';

import { setBoffinRulesMode, setBoffinRulesWarningsSortOrder } from '../../store/userBoffinSlice';
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'


import {  ruleSearchColumns , ruleActionColumns, ruleOperations,
          ruleLogicals
        } from './Constants'
import { API, graphqlOperation } from 'aws-amplify';

import { useAuth } from "../../context/AuthContextProvider";


const RulesAndWarningsNewEdit = () => {
    const dispatch = useDispatch()

    const userAuthenticationDetails = useAuth();

    const userLDAPS = userAuthenticationDetails.USER_LDAP_GROUPS;

    const [isBoffinSuperUser, setIsBoffinSuperUser] = useState(userLDAPS.includes("boffin-super-users"))

    const history = useHistory();
    
    const currentAppId = useSelector((globalState)=>globalState.auth.currentAppId)
    const isCurrentAppAuthorised = useSelector((globalState)=>globalState.auth.isCurrentAppAuthorized)
    const [isCheckingAccess, setIsCheckingAccess] = useState(true);
    const [isAuthorized, setIsAuthorized] = useState();
    
    const userId  = useSelector((globalState)=>globalState.auth.userId)
    
    const BoffinRulesMode  = useSelector((globalState)=>globalState.boffin.BoffinRulesMode)
    const BoffinRulesWarnings  = useSelector((globalState)=>globalState.boffin.BoffinRulesWarnings)
    const BoffinRulesWarningsSortOrder  = useSelector((globalState)=>globalState.boffin.BoffinRulesWarningsSortOrder)

    const [items, setItems] = useState([]);
    const [toolsHide, setToolsHide] = useState(true);
    const [showLoadingIcon, setShowLoadingIcon] = useState(true);

    const [activeStepIndex,setActiveStepIndex] = useState(0);
    const [showError, setsShowError] = useState(false);
    


    const [type, setType] = useState(''); // Rules or Warning
    const [sortOrder, setSortOrder] = useState('');
    const [description, setDescription] = useState('');
    const [enabled, setEnabled] = useState(true);
    const [modifiedBy, setModifiedBy] = useState();
    const [approved, setApproved] = useState(false);
    const [approvedBy, setApprovedBy] = useState('');
    
    
    
    const [selectedCol1, setSelectedCol1] = useState({ label :"Cost Center" , value :"costcenter",  tags: ['text']}  );
    const [selectedOps1, setSelectedOps1] = useState({ label :"Equals to" , value :"=", }  );
    const [selectedVal1, setSelectedVal1] = useState('' );
    const [selectedLogic1, setSelectedLogic1] = useState( { label :"AND" , value :"AND"}  )
    const [selectedActionCol1, setSelectedActionCol1] = useState({ label :"Final Major Category" , value :"final_maj_cat", tags: ['text']});
    const [selectedActionVal1, setSelectedActionVal1] = useState('' );


    
    const [conditions, setConditions] = useState([]);
    const [actions, setActions] = useState([]);
    const [status, setStatus] = useState('Pending');

    const [saveAs, setSaveAs] = useState(false);
    
    const [errMsg, setErrMsg] = useState('');
    const [SQL, setSQL] = useState('');
    
    
 
    useEffect(() => {
    
        if (currentAppId !== appSettings.appId){
          dispatch(setCurrentAppId(appSettings.appId))
        }
        setIsAuthorized(isCurrentAppAuthorised)
        
        if (BoffinRulesMode === 'MODIFY'){
            loadData()
        } else {
            setShowLoadingIcon(false)
        }
    
        return () => {
          // this now gets called when the component unmounts
        };
      }, [isCurrentAppAuthorised]);
    
    // 
    useEffect(() => {
        if(isAuthorized === true || isAuthorized === false){
          setIsCheckingAccess(!isCheckingAccess);
        } 
      }, [isAuthorized]);
  
    const loadData = async () =>{
        setShowLoadingIcon(true)
        var mQry = `query MyQuery {
            getUI4BI_BoffinRulesWarnings(pk: "`+ BoffinRulesWarnings +`", sk: "`+ BoffinRulesWarningsSortOrder +`") {
              pk
              sk
              description
              enabled
              lastUpdatedBy
              lastUpdatedDate
              setActions
              tableName
              whereClause
              approved
              approvedBy
              modifiedBy
            }
          }
    `
        

    
        // console.log(mQry)
        let response = await API.graphql(graphqlOperation(mQry));
        let r1 = response.data.getUI4BI_BoffinRulesWarnings
        console.log(r1)
        setType(r1.pk)
        setSortOrder(r1.sk)
        setDescription(r1.description)
        setEnabled(r1.enabled)
        setModifiedBy(r1.modifiedBy)
        
        let mTxt1 = r1.setActions
        let mJ1 = mTxt1.replace(/'/g, '"')
        setActions(JSON.parse(mJ1))
        
        let mTxt2 = r1.whereClause
        let mJ2 = mTxt2.replace(/'/g, '"')
        setConditions(JSON.parse(mJ2))
        
        setShowLoadingIcon(false)
    }
   
    const onFollowHandler = (ev) => {
        ev.preventDefault();
        if (ev.detail.href) {
            history.push(ev.detail.href.substring(1));
        }
    }

   
    const breadItems = [
        { text: 'Home', href: '/Home' },
        { text: 'ATeam BI', href: '/ATeamBIHome' },
        { text: 'Boffin', href: '/BoffinHome' },
        { text: 'Rules & Warnings', href: '/BoffinRulesAndWarnings' },
        { text: 'New / Edit', href: '' },
      ];
    
    const cancelButtonOnClick =() =>{
        history.push('/BoffinRulesAndWarnings')
    }

    const showSQL = () => {
        let md = ''
        let sql = ''
        let cond = ''
        let sets = ''
        sql += "UPDATE boffin.compute_data" + "\n"
        sql += "SET " + "\n"
        actions.forEach(d => {
            sets = sets + "\t " + d.columnName + " = '" + d.value + "'," + "\n"
        }); 
        var lastChar = sets.slice(-2);
        if (lastChar == ',\n') {
            sets = sets.slice(0, -2);
        }
        sql = sql + sets + "\n"
        sql += "WHERE " + "\n"
        conditions.forEach(d => {
            cond = cond +  "\t " + d.logic + " " +  d.columnName + " = '" + d.value + "' " + "\n"
        }); 
        
        sql = sql + cond + "\n"

        md ="```" + "\n" + sql + "\n" + "```"
        setSQL(md)
    }
    const addConditonClick  = () =>{
        let condition = {}
        conditions.length > 0 ?
            condition = { "id":conditions.length, "columnName": selectedCol1.value, "operation": selectedOps1.value, "value" : selectedVal1, "logic": selectedLogic1.value}
        :   condition = { "id":conditions.length, "columnName": selectedCol1.value, "operation": selectedOps1.value, "value" : selectedVal1, "logic": ""}
      
        setConditions(list => (list ? list : []).concat(condition));
    }
    
    const deleteConditionClick = () =>{
        setConditions((condition) =>condition.reverse().splice(1).reverse())
    }

    const deleteConditionItem = (id)=>{
        let filtered = conditions.filter(function(value, index, arr) {
            return index != id;
        });
        setConditions(filtered)
    }
    

    const deleteAction = (id)=>{
        let filtered = actions.filter(function(value, index, arr) {
            return index != id;
        });
        setActions(filtered)
    }
    const deleteActionClick = () =>{
        setActions((action) =>action.reverse().splice(1).reverse())
    }
    const addActionClick =() =>{
        let action = { "id":actions.length, "columnName": selectedActionCol1.value, "value" : selectedActionVal1}
        setActions(list => (list ? list : []).concat(action));
    }

    const submitButtonClick = async () =>{
        // Error checks
            if (!isBoffinSuperUser) {
                setErrMsg('User Must be a MEMBER of "boffin-super-users" LDAP group, to edit or add a new rule or warning.')
                setsShowError(true)
                return
            }
            if (!sortOrder){
                setErrMsg('SortOrder is Required')
                setsShowError(true)
                return
            }
            if (!type){
                setErrMsg('Type is required')
                setsShowError(true)
                return
            }
            if (conditions.length===0){
                setErrMsg('Error in Condtions')
                setsShowError(true)
                return
            }
            if (actions.length===0){
                setErrMsg('Error in Actions')
                setsShowError(true)
                return
            }

        // End of Error Checks

        setShowLoadingIcon(true)
        if (BoffinRulesMode ==='NEW') {

        
            // Need to convert conditions to string
            let mTxt1 = JSON.stringify(conditions)
            mTxt1 = mTxt1.replace(/"/g, '\'')

            let mTxt2 = JSON.stringify(actions)
            mTxt2 = mTxt2.replace(/"/g, '\'')

            let dt = moment().format();
            var mQry = `
            mutation addRuleWarning {
                createUI4BI_BoffinRulesWarnings(input: {
                    pk              : "` + type +`", 
                    sk              : "` + sortOrder +`", 
                    description     : "` + description +`",
                    enabled         : ` + enabled +`,
                    tableName       : "boffin.compute_data",
                    whereClause     : "` + mTxt1 +`",
                    setActions      : "` + mTxt2 +`",
                    lastUpdatedBy   : "` + userId + `",
                    lastUpdatedDate : "` + dt + `",
                    modifiedBy      : "` + userId + `",
                    approved        : false,
                    approvedBy      : "pending approval"

                }){
                        pk
                        sk
                    }
            }
            
        `
            

        
            // console.log(mQry)
            let response = await API.graphql(graphqlOperation(mQry))
            .then(()=>{
                setStatus('saved')
                setShowLoadingIcon(false)
            });
            history.push('/BoffinRulesAndWarnings')
        }
        if (BoffinRulesMode ==='MODIFY') {

        
            // Need to convert conditions to string
            let mTxt1 = JSON.stringify(conditions)
            mTxt1 = mTxt1.replace(/"/g, '\'')

            let mTxt2 = JSON.stringify(actions)
            mTxt2 = mTxt2.replace(/"/g, '\'')

            let dt = moment().format();
            
            var mQry = `
            mutation updateRuleWarning {
                updateUI4BI_BoffinRulesWarnings(input: {
                    pk              : "` + type +`", 
                    sk              : "` + sortOrder +`", 
                    description     : "` + description +`",
                    enabled         : ` + enabled +`,
                    tableName       : "boffin.compute_data",
                    whereClause     : "` + mTxt1 +`",
                    setActions      : "` + mTxt2 +`",
                    lastUpdatedBy   : "` + userId + `",
                    lastUpdatedDate : "` + dt + `"
                    modifiedBy      : "` + userId + `",
                    approved        : false,
                    approvedBy      : "pending approval"

                }){
                        pk
                        sk
                    }
            }
            
        `
            

        
            // console.log(mQry)
            let response = await API.graphql(graphqlOperation(mQry))
            .then(()=>{
                setStatus('saved')
                setShowLoadingIcon(false)
            });
            history.push('/BoffinRulesAndWarnings')
        }
        

    }
    const content =(
        <SpaceBetween direction='vertical' size="m">
            {isAuthorized ? 
                <Box>
                    {showLoadingIcon ? 
                        <Box>
                            <Spinner size='normal'></Spinner>
                            Loading information
                        </Box>
                        :
                                        
                        <Box>
                           
                        
                            
                        <Wizard
                                onCancel={()=>{cancelButtonOnClick()}}
                                onSubmit = {()=>{submitButtonClick()}}
                                i18nStrings={{
                                    stepNumberLabel: stepNumber =>
                                    `Step ${stepNumber}`,
                                    collapsedStepsLabel: (stepNumber, stepsCount) =>
                                    `Step ${stepNumber} of ${stepsCount}`,
                                    skipToButtonLabel: (step, stepNumber) =>
                                    `Skip to ${step.title}`,
                                    navigationAriaLabel: "Steps",
                                    cancelButton: "Cancel",
                                    previousButton: "Previous",
                                    nextButton: "Next",
                                    submitButton: "Submit",
                                    optional: "optional"
                                }}
                                onNavigate={({ detail }) =>
                                    setActiveStepIndex(detail.requestedStepIndex)
                                }
                                activeStepIndex={activeStepIndex}
                                steps={[
                                    {
                                    title: "Define",
                                    description:
                                        "Specify what would you like to Add or Modify, is it a Rule or Warning message",
                                    content: (
                                        <Container>
                                        <SpaceBetween direction="vertical" size="l">
                                            {
                                                BoffinRulesMode ==='NEW' ?
                                                <Box>                                            
                                                    <FormField label="Type" >
                                                    <Tiles
                                                        onChange={({ detail }) => setType(detail.value)}
                                                        value={type}
                                                        items={[
                                                            { label: "Rule", value: "Rule" },
                                                            { label: "Warning", value: "Warning" },
                                                        ]}
                                                    />
                                                
                                                    </FormField>
                                                    <FormField label="Sort Order">
                                                        <Input value={sortOrder} placeholder='' onChange={({ detail }) => setSortOrder(detail.value)}></Input>
                                                        <SpaceBetween direction="horizontal" size="m">
                                                        <Icon name='status-info'></Icon>
                                                        <Box variant='small'>Sort Order defines the order of execution. For example R001, R002 or W001, W002</Box>
                                                        </SpaceBetween>
                                                    </FormField>
                                                </Box> :
                                                <Box>
                                                    <Alert type='info'>
                                                        Type & Sort order can't be modified.
                                                        <br></br>
                                                        Alternatively, you can disable an rule and create a new rule/warning
                                                    </Alert>
                                                    
                                                    <div>
                                                        <Box variant="awsui-key-label">
                                                        Type
                                                        </Box>
                                                    <div>{type}</div>
                                                    </div>
                                                    <br></br>
                                                    <div>
                                                        <Box variant="awsui-key-label">
                                                        Sort Order
                                                        </Box>
                                                        <div>{sortOrder}</div>
                                                    </div>
                                                </Box>

                                            }
                                            <FormField label="Description">
                                                <Input value={description} placeholder='' onChange={({ detail }) => setDescription(detail.value)}></Input>
                                            </FormField>
                                            <FormField label="Enabled">
                                            <Toggle
                                                onChange={({ detail }) =>
                                                    setEnabled(detail.checked)
                                                }
                                                checked={enabled}
                                                >
                                                {enabled}
                                            </Toggle>
                                            <Box variant='small'>Enabled determines, if the rule is required to be executed or not.</Box>
                                            </FormField>
                                            
                                        </SpaceBetween>
                                        </Container>
                                    )
                                    },
                                    {
                                    title: "Configure Rule",
                                    description:
                                        "This section enables you to specify the search parameters for the rule.",
                                    content: (
                                        <Container>
                                            <SpaceBetween direction='vertical' size='m'>
                                                    <ColumnLayout columns={3}>
                                                        <FormField label="Column Name">
                                                            <Select  selectedOption={selectedCol1} options={ruleSearchColumns} onChange={({detail})=>{setSelectedCol1(detail.selectedOption)}} filteringType="auto"></Select>
                                                        </FormField>
                                                        <FormField label="Operation">
                                                            <Select  selectedOption={selectedOps1} options={ruleOperations} onChange={({detail})=>{setSelectedOps1(detail.selectedOption)}} filteringType="auto"></Select>
                                                        </FormField>
                                                        <FormField label="Value">
                                                            <Input value={selectedVal1} type={selectedCol1.tags[0]}  placeholder='' onChange={({ detail }) => setSelectedVal1(detail.value)}></Input>
                                                            <Box variant='small'>expected input format: {selectedCol1.tags[0]} </Box>
                                                        </FormField>
                                                        {
                                                            conditions.length > 0 ? 
                                                            <FormField label="Another Condition">
                                                                <Select  selectedOption={selectedLogic1} options={ruleLogicals} onChange={({detail})=>{setSelectedLogic1(detail.selectedOption)}} filteringType="auto"></Select>
                                                            </FormField> : <div></div>
                                                        
                                                        }

                                                        <FormField label="Click here">
                                                            <Button onClick={()=>{addConditonClick()}}>Add Condition</Button>
                                                        </FormField>
                                                        
                                                    </ColumnLayout>
                                                    <Table
                                                        variant='embedded'
                                                        columnDefinitions={[
                                                            {
                                                                id: "logic",
                                                                header: "",
                                                                cell: item => item.logic,

                                                            },
                                                        {
                                                            id: "col",
                                                            header: "Column name",
                                                            cell: item => item.columnName ,
                                                        },
                                                        {
                                                            id: "operation",
                                                            header: "Operation",
                                                            cell: item => item.operation ,
                                                        },
                                                        {
                                                            id: "value",
                                                            header: "Value",
                                                            cell: item => item.value
                                                        },
                                                        {
                                                            id: "Remove",
                                                            header: "Remove",
                                                            cell: item => (
                                                                <Box color='text-status-error'>
                                                                    <Button variant='icon' iconName='close'
                                                                    onClick={()=>deleteConditionItem(item.id)}
                                                                    ></Button>
                                                                </Box>)
                                                        }
                                                    ]}
                                                    items={conditions}
                                                    loadingText="Loading resources"
                                                    sortingDisabled
                                                    
                                                    />
                                                    
                                            </SpaceBetween>
                                            <br></br>
                                            {
                                                conditions.length > 0 ? 
                                                <Button iconName='status-negative' 
                                                onClick={()=>{deleteConditionClick()}}
                                                >Remove condition</Button> : <div></div>
                                            
                                            }
                                                        
                                            
                                        </Container>
                                    ),
                                    },
                                    {
                                    title: "Configure Action",
                                    description:
                                        "This section enables you to specify the actions that need to be performed",
                                    content: (
                                        <Container                                        
                                        >
                                        <SpaceBetween direction='vertical' size='m'>
                                            
                                            
                                                <ColumnLayout columns={3}>
                                                    <FormField label="Column Name">
                                                        <Select  selectedOption={selectedActionCol1} options={ruleActionColumns} onChange={({detail})=>{setSelectedActionCol1(detail.selectedOption)}} filteringType="auto"></Select>
                                                    </FormField>                                                   
                                                    <FormField label="Value">
                                                        
                                                        <Input value={selectedActionVal1} type={selectedActionCol1.tags[0]} placeholder='' onChange={({ detail }) => setSelectedActionVal1(detail.value)}></Input>
                                                        <Box variant='small'>expected input format: {selectedActionCol1.tags[0]} </Box>
                                                    </FormField>

                                                    <FormField label="Click here">
                                                        <Button onClick={()=>{addActionClick()}}>Add Action</Button>
                                                    </FormField>
                                                </ColumnLayout>
                                        </SpaceBetween>
                                        <br></br>
                                                <Table
                                                        variant='embedded'
                                                        columnDefinitions={[
                                                            {
                                                                id: "A1",
                                                                header: "Column Name",
                                                                cell: item => item.columnName || "",

                                                            },
                                                        
                                                            {
                                                                id: "value",
                                                                header: "Value",
                                                                cell: item => item.value || "-"
                                                            },
                                                            {
                                                                id: "Remove",
                                                                header: "Remove",
                                                                cell: item => (
                                                                <Box color='text-status-error'>
                                                                    <Button  variant='icon' iconName='close' 
                                                                        onClick={()=>deleteAction(item.id)}
                                                                    ></Button>
                                                                </Box>)
                                                            }
                                                    ]}
                                                    items={actions}
                                                    loadingText="Loading resources"
                                                    sortingDisabled
                                                    
                                                />
                                                <br></br>
                                            {
                                                actions.length > 0 ? 
                                                <Button iconName='status-negative' 
                                                onClick={()=>{deleteActionClick()}}
                                                >Remove action</Button> : <div></div>
                                            
                                            }
                                            
                                        </Container>
                                    ),
                                    },
                                    {
                                    title: "Review and Submit",
                                    content: (
                                        <SpaceBetween size="xs">
                                            {showError ? 
                                                <Alert type='error'  dismissible={true} onDismiss={()=>setsShowError(false)} >{errMsg}</Alert>  :
                                                <Box></Box>
                                            } 
                                        <Header
                                            variant="h3"
                                            actions={
                                                <SpaceBetween direction='horizontal' size='l'>
                                               
                                                {/* <Toggle
                                                    onChange={({ detail }) =>{
                                                        setSaveAs(detail.checked)
                                                        dispatch(setBoffinRulesMode('NEW'))
                                                        dispatch(setBoffinRulesWarningsSortOrder(sortOrder))
                                                    }
                                                    }
                                                    checked={saveAs}
                                                    >
                                                    Duplicate this to new (save as)
                                                </Toggle> */}
                                                <Button
                                                    onClick={() => setActiveStepIndex(0)}
                                                >
                                                    Edit
                                                </Button>
                                            </SpaceBetween>

                                            }
                                        >
                                            Define
                                        </Header>
                                        <Container
                                            
                                        >
                                            <ColumnLayout
                                            columns={2}
                                            variant="text-grid"
                                            >
                                            <div>
                                                <Box variant="awsui-key-label">
                                                Type
                                                </Box>
                                                <div>{type}</div>
                                            </div>
                                            <div>
                                                <Box variant="awsui-key-label">
                                                Sort Order
                                                </Box>
                                                <div>{sortOrder}</div>
                                            </div>
                                            <div>
                                                <Box variant="awsui-key-label">
                                                Description
                                                </Box>
                                                <div>{description}</div>
                                            </div>
                                            <div>
                                                <Box variant="awsui-key-label">
                                                Enabled
                                                </Box>
                                                {enabled ? <Icon variant='success' name='status-positive'></Icon> :
                                                <Icon variant='error' name='status-stopped'></Icon> }
                                            </div>
                                            </ColumnLayout>
                                        </Container>
                                        <Header
                                            variant="h3"
                                            actions={
                                            <Button
                                                onClick={() => setActiveStepIndex(1)}
                                            >
                                                Edit
                                            </Button>
                                            }
                                        >
                                            Rule
                                        </Header>
                                            
                                            <Table
                                                
                                                columnDefinitions={[
                                                    {
                                                        id: "logic",
                                                        header: "",
                                                        cell: item => item.logic ,

                                                    },
                                                {
                                                    id: "col",
                                                    header: "Column name",
                                                    cell: item => item.columnName ,
                                                },
                                                {
                                                    id: "operation",
                                                    header: "Operation",
                                                    cell: item => item.operation ,
                                                },
                                                {
                                                    id: "value",
                                                    header: "Value",
                                                    cell: item => item.value 
                                                }
                                            ]}
                                            items={conditions}
                                            loadingText="Loading resources"
                                            sortingDisabled
                                            
                                            />
                                        <Header
                                            variant="h3"
                                            actions={
                                            <Button
                                                onClick={() => setActiveStepIndex(1)}
                                            >
                                                Edit
                                            </Button>
                                            }
                                        >
                                            Actions
                                        </Header>
                                            <Table
                                                    columnDefinitions={[
                                                        
                                                        {
                                                            id: "A1",
                                                            header: "Column Name",
                                                            cell: item => item.columnName ,

                                                        },
                                                    
                                                        {
                                                            id: "value",
                                                            header: "Value",
                                                            cell: item => item.value 
                                                        }
                                                ]}
                                                items={actions}
                                                loadingText="Loading resources"
                                                sortingDisabled
                                                
                                            />
                                            

                                                <Button onClick={()=>showSQL()}>Show SQL (optional)</Button>   
                                                <ReactMarkdown children={SQL} remarkPlugins={[remarkGfm]} /> 
                                        </SpaceBetween>
                                        
                                    )
                                    }
                                ]}
                            />
                        <br></br>
                        
                        </Box>
                    
                    }  
                   
                    
                </Box>
            :isCheckingAccess ? 
                <div>
                    <WaitForPageAccess></WaitForPageAccess>
                </div> :
                <div>
                    <AccessDenied appName={appSettings.appName}></AccessDenied>
                </div> 
            }
        </SpaceBetween>
    )
    
    const helpContent = (
        <HelpPanel
            
            header={<div>
                <div>
    
                </div>
                <Badge>{BoffinRulesMode}</Badge>
               
            </div>}

            footer= {
                <Alert statusIconAriaLabel="Warning" type="warning">
                    Changing the configuration would require approval from your team member.
                </Alert>
            }
        >
            
            <br/>
            <Box variant='h3'>
                What is a Rule?
            </Box>
            <Box>
            A rule is defined to change specific column values based on business logics. 
            <strong> For example:</strong> Search line description for the key word expenses and update major category column to EXPENSES.

            </Box>
            <hr></hr>
            <Box variant='h3'>
                What is a Warning?
            </Box>
            <Box>
                A Warning is displayed as a text message in the Excel Output Data tab (Column AT). 
                This is to enable the user identify potential risks associated with the transactions.
            </Box>
        </HelpPanel>
      )
    
    
  return (
    <AppLayout
        breadcrumbs={<BreadcrumbGroup items={breadItems} onFollow={(event)=>onFollowHandler(event)}></BreadcrumbGroup>}
        content={content}
        navigation={<SideNav activeHref="/BoffinRulesAndWarnings" />}
        headerSelector="#TopBar"
        navigationHide={false}
        onToolsChange={()=>{setToolsHide(!toolsHide)}}
        tools={isAuthorized ? helpContent : ''}
        toolsOpen={isAuthorized? toolsHide : false}
        headerVariant="high-contrast"

  ></AppLayout>
  )
}



export default RulesAndWarningsNewEdit
