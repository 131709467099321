import React, { useState, useEffect } from "react";
import { setCurrentAppId, setTemplateId } from "../../store/userAuthSlice";
import SideNav from "./SideNav";
import {
    AppLayout,
    Button,
    ColumnLayout,
    Input,
    Icon,
    Textarea,
    Container,
    Header,
    Box,
    Grid,
    SpaceBetween,
    Link,
    ExpandableSection,
    ContentLayout,
    Table,
    Modal,
    Spinner,
    RadioGroup,
    FileUpload,
    HelpPanel,
    Flashbar,
    Alert,
    Checkbox
} from "@amzn/awsui-components-react";

import { CodeView } from '@amzn/awsui-code-view';
import rubyHighlight from "@amzn/awsui-code-view/highlight/ruby";


import "../../index.scss";
import "./styles.scss"
import { useSelector, useDispatch } from "react-redux";
import { canAddEditDeleteApplication } from "../Templates/Utils/Permissions";
import { useHistory, useParams } from "react-router-dom";

import * as XLSX from 'xlsx';

import CustomInput from "../Templates/components/CustomInput";
import CustomSelect from "../Templates/components/CustomSelect";
import CustomMultiSelect from "../Templates/components/CustomMultiSelect";
import CustomCheckbox from "../Templates/components/CustomCheckbox";
import CustomMultiHybridInputAndSelect from "../Templates/components/CustomMultiHybridInputAndSelect";
import { getEditableFields } from "../Templates/Utils/FormMetadata";
import { createProcess, getProcessById, updateProcess, getAppPromise, deleteProcess, getNormalisedColumnName, createAuditLog } from "src/Ateam-BI/Templates/Utils/AppActivityUtils"
import { TEMPLATE_APP, APPS_WITH_APP_LEVEL_PERMISSIONS_IN_TEMPLATE } from "../Templates/Constants";
import { FLEXICO_STEP_FUNCTION_CONFIG, FLEXICO_GLUE_JOB_CONFIG } from 'src/constants/AppConstants';
import Feedback from "src/generic-components/UserFeedback/Feedback";
import { configureFlexicoAppSync, configureFlexicoS3, configureGenericAppSync, configureGenericS3 } from "src/context/AuthContextUtility";
import { createFlexicoTemplate, deleteFlexicoTemplate, getFlexicoTemplate, updateFlexicoTemplate } from "src/utilities/FlexicoAPIs";
import CustomMultiValuedParameter from "../Templates/components/CustomMultiValuedParameter";
import { APPROVER_TYPES, FLEXICO_DROPDOWN_METADATA_KEYS, FLEXICO_NEGATIVE_STATUS, FLEXICO_POSITIVE_STATUS, FLEXICO_TEMPLATE_STATUS, FLEXICO_TEMPLATE_KEYS, UPLOADER_TYPES } from "./Constants";

export default (props) => {
    const dispatch = useDispatch();
    const currentStage = useSelector((globalState) => globalState.auth.currentStage)
    const templateId = useSelector((globalState) => globalState.auth.templateId)
    const history = useHistory();
    const [NavOpen, setNavOpen] = useState(true);
    const [showLoadingIcon, setShowLoadingIcon] = useState(true);
    const userId = useSelector((globalState) => globalState.auth.userId);
    let { id } = useParams();
    let { fid } = useParams();
    const [isNewFlexicoTemplate, setIsNewFlexicoTemplate] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [errorMessage, setErrorMessage] = useState('')
    const [alertErrorMessage, setAlertErrorMessage] = useState(null)
    const [accessPerms, setAccessPerms] = useState(props.accessPerms);
    const [canDelete, setCanDelete] = useState(false)
    const [canEdit, setCanEdit] = useState(false)
    const [canAdd, setCanAdd] = useState(false)
    const [enableEditAction, setEnableEditAction] = useState(false)
    const [flexicoTemplateData, setFlexicoTemplateData] = useState({})
    const [flexicoTemplateDataEdited, setFlexicoTemplateDataEdited] = useState({})
    const [fieldMetadata, setFieldMetadata] = useState({});
    const [sampleFile, setSampleFile] = useState([]);
    const [deleteConfirmationModal, setDeleteConfirmationModal] = useState(false);
    const [updateNotificationModal, setUpdateNotificationModal] = useState(false);
    const [updateNotificationModalError, setUpdateNotificationModalError] = useState(false);
    const [deleteAppName, setDeleteAppName] = useState('');
    const [flashBarComments, setFlashBarComments] = useState();
    const [isApprovalDisabled, setIsApprovalDisabled] = useState(false);
    const [backUrl, setBackUrl] = useState(null)
    const [updateComments, setUpdateComments] = useState("")
    const [modalError, setModalError] = useState("")
    const [toolsOpen, setToolsOpen] = useState(false);
    const [mandatoryParameters, setMandatoryParameters] = useState([]);
    const [deleteDataOnUpdate, setDeleteDataOnUpdate] = useState(false)
    const [dataGenerationPlatform, setDataGenerationPlatform] = useState('')
    const [dataSharePlatform, setDataSharePlatform] = useState('')
    const [dataGenerationPlatformClass, setDataGenerationPlatformClass] = useState('')
    const [dataSharePlatformClass, setDataSharePlatformClass] = useState('')

    useEffect(() => {
        configureFlexicoS3()
        setInitView()
        setBackUrl(getParam('back'))
        return () => {
            // this now gets called when the component unmounts
            configureGenericAppSync()
            configureGenericS3()
        };
    }, []);

    useEffect(() => {
        if (flexicoTemplateDataEdited.hasOwnProperty(FLEXICO_TEMPLATE_KEYS.critical_data) && flexicoTemplateDataEdited.hasOwnProperty(FLEXICO_TEMPLATE_KEYS.sox_approved)) {

            if (flexicoTemplateDataEdited[FLEXICO_TEMPLATE_KEYS.critical_data] && flexicoTemplateDataEdited[FLEXICO_TEMPLATE_KEYS.sox_approved]) {
                setDataGenerationPlatform("Red")
                setDataSharePlatform("None")
                setDataGenerationPlatformClass("red_badge")
                setDataSharePlatformClass("grey_badge")
            } else if (!flexicoTemplateDataEdited[FLEXICO_TEMPLATE_KEYS.critical_data] && flexicoTemplateDataEdited[FLEXICO_TEMPLATE_KEYS.sox_approved]) {
                setDataGenerationPlatform("Red")
                setDataSharePlatform("Orange")
                setDataGenerationPlatformClass("red_badge")
                setDataSharePlatformClass("orange_badge")
            } else if (!flexicoTemplateDataEdited[FLEXICO_TEMPLATE_KEYS.critical_data] && !flexicoTemplateDataEdited[FLEXICO_TEMPLATE_KEYS.sox_approved]) {
                setDataGenerationPlatform("Orange")
                setDataSharePlatform("Red")
                setDataGenerationPlatformClass("orange_badge")
                setDataSharePlatformClass("red_badge")
            } else if (flexicoTemplateDataEdited[FLEXICO_TEMPLATE_KEYS.critical_data] && !flexicoTemplateDataEdited[FLEXICO_TEMPLATE_KEYS.sox_approved]) {
                setDataGenerationPlatform("Red")
                setDataSharePlatform("None")
                setDataGenerationPlatformClass("red_badge")
                setDataSharePlatformClass("grey_badge")
            }

        }

        if (flexicoTemplateDataEdited.hasOwnProperty(FLEXICO_TEMPLATE_KEYS.trigger_type)) {
            if ((flexicoTemplateDataEdited[FLEXICO_TEMPLATE_KEYS.trigger_type] == 'Parameters only'
            || flexicoTemplateDataEdited[FLEXICO_TEMPLATE_KEYS.trigger_type] == 'None')
            && (flexicoTemplateDataEdited[FLEXICO_TEMPLATE_KEYS.sox_approved] || flexicoTemplateDataEdited[FLEXICO_TEMPLATE_KEYS.approval_required]) ){
                flexicoTemplateDataEdited[FLEXICO_TEMPLATE_KEYS.approvers] = []
                flexicoTemplateDataEdited[FLEXICO_TEMPLATE_KEYS.sox_approved] = false
                flexicoTemplateDataEdited[FLEXICO_TEMPLATE_KEYS.approval_required] = false
                setIsApprovalDisabled(false)
                setFlexicoTemplateDataEdited({...flexicoTemplateDataEdited})
            }
        }
    }, [flexicoTemplateDataEdited])

    useEffect(() => {
        setFlexicoTemplateDataEdited(flexicoTemplateData)
        if (flexicoTemplateData.hasOwnProperty(FLEXICO_TEMPLATE_KEYS.status)
            && flexicoTemplateData[FLEXICO_TEMPLATE_KEYS.status]
            && flexicoTemplateData[FLEXICO_TEMPLATE_KEYS.status].toLowerCase().includes("failed")) {
            setFlashBarComments([{
                type: "error",
                dismissible: true,
                dismissLabel: "Dismiss message",
                onDismiss: () => setFlashBarComments([]),
                content: (
                    <>
                        {flexicoTemplateData[FLEXICO_TEMPLATE_KEYS.status]}
                    </>
                ),
                id: "comments"
            }])
        }

    }, [flexicoTemplateData])

    useEffect(() => {
        if (sampleFile.length > 0) {
            suggestSchema()
        }
    }, [sampleFile])

    useEffect(() => {
        checkIfFormIsSubmittable()
    }, [flexicoTemplateDataEdited, canEdit])

    const dismissDeleteDialog = () => {
        setDeleteAppName('')
        setDeleteConfirmationModal(false)
    }

    const dismissUpdateNotifyDialog = () => {
        setUpdateNotificationModal(false)
    }

    const getParam = (key) => {
        const search = props.location.search;
        const params = new URLSearchParams(search);
        const value = params.get(key);
        return value;
    }

    const getGroup = () => {
        return getParam('group')
    }

    const setInitView = async () => {
        let group_id = getGroup()
        if (group_id) {
            flexicoTemplateData[FLEXICO_TEMPLATE_KEYS.group_id] = group_id
        }
        let next_step = getParam('next_step')
        if (next_step) {
            flexicoTemplateData[FLEXICO_TEMPLATE_KEYS.next_step] = next_step
        }
        flexicoTemplateData[FLEXICO_TEMPLATE_KEYS.app_id] = id
        flexicoTemplateData[FLEXICO_TEMPLATE_KEYS.approval_required] = false
        flexicoTemplateData[FLEXICO_TEMPLATE_KEYS.critical_data] = false
        flexicoTemplateData[FLEXICO_TEMPLATE_KEYS.sox_approved] = false

        let params = []
        params.push({ 'label': 'contico_job', 'field_type': 'fixed_inputs', 'limit': 1 })
        setMandatoryParameters(params)

        loadEditableFields()
        if (fid) {
            dispatch(setCurrentAppId(id));
            loadData();
            setIsEditMode(false)
            setIsNewFlexicoTemplate(false)
        } else {
            setIsNewFlexicoTemplate(true)
            setShowLoadingIcon(false);
            (!isEditMode) ? setIsEditMode(true) : ''
        }
        let appTemplateID = templateId
        if (!templateId) {
            // fetch template from app id
            let result = await getAppPromise(id)
            setTemplateId(result.data.getAppsV2[0][TEMPLATE_APP.TEMPLATE_ID])
            appTemplateID = result.data.getAppsV2[0][TEMPLATE_APP.TEMPLATE_ID]
        }
        let accessPerms;
        if (id && APPS_WITH_APP_LEVEL_PERMISSIONS_IN_TEMPLATE[currentStage?.toUpperCase()].includes(id)) {
            accessPerms = await canAddEditDeleteApplication(userId, id, currentStage)
        }
        else {
            accessPerms = await canAddEditDeleteApplication(userId, appTemplateID, currentStage)
        }
        setCanDelete(accessPerms?.delete)
        setCanEdit(accessPerms?.edit)
        setCanAdd(accessPerms?.add)

        // !fid means isNewTemplate=true. Sometimes the useeffect take time, so relying on fid. fid is a path param
        if (!fid && !accessPerms?.add) {
            setAlertErrorMessage(
                {
                    "header": "Access denied",
                    "message": "No access to add a new process"
                }
            )
        } else {
            setAlertErrorMessage(null)
        }
    }

    const loadData = () => {
        // setProcessData()
        getFlexicoTemplate(fid, function onSuccess(response) {
            setFlexicoTemplateData(response.data.getFlexicoTemplate)
            setShowLoadingIcon(false);
        }, function onFailure(response) {
            setShowLoadingIcon(false);
        })
    }

    const loadEditableFields = async () => {
        let res = await getEditableFields()
        res[FLEXICO_TEMPLATE_KEYS.approvers] = { values: [{ label: "USER", value: APPROVER_TYPES.APPROVER }, { label: "LDAP", value: APPROVER_TYPES.APPROVER_LDAP }] }
        res[FLEXICO_TEMPLATE_KEYS.uploaders] = { values: [{ label: "USER", value: UPLOADER_TYPES.UPLOADER }, { label: "LDAP", value: UPLOADER_TYPES.UPLOADER_LDAP }] }
        res[FLEXICO_TEMPLATE_KEYS.file_types] = res[`flexico_${FLEXICO_TEMPLATE_KEYS.file_types}`]
        res[FLEXICO_TEMPLATE_KEYS.trigger_type] = res[`flexico_${FLEXICO_TEMPLATE_KEYS.trigger_type}`]
        setFieldMetadata(res)
    }

    const getSelectFieldValues = (fieldName, subKey = null) => {
        if (fieldMetadata && fieldMetadata[fieldName] && fieldMetadata[fieldName]['values']) {
            return fieldMetadata[fieldName]['values']
        } else if (subKey && fieldMetadata && fieldMetadata[fieldName][subKey] && fieldMetadata[fieldName][subKey]['values']) {
            return fieldMetadata[fieldName][subKey]['values']
        } else {
            return []
        }
    }

    const updateValue = (fieldName, value, subKey = null) => {
        if (subKey) {
            if (!flexicoTemplateDataEdited.hasOwnProperty(fieldName)) {
                flexicoTemplateDataEdited[fieldName] = {}
            }
            flexicoTemplateDataEdited[fieldName][subKey] = value
        } else {
            flexicoTemplateDataEdited[fieldName] = value
        }
        if (fieldName == FLEXICO_TEMPLATE_KEYS.sox_approved) {
            if (value) {
                // approval required MUST be true
                setIsApprovalDisabled(true)
                updateValue(FLEXICO_TEMPLATE_KEYS.approval_required, true)
            } else {
                setIsApprovalDisabled(false)
            }
        }


        setFlexicoTemplateDataEdited(JSON.parse(JSON.stringify(flexicoTemplateDataEdited)))
    }


    const getValue = (fieldName, subKey = null) => {
        if (subKey) {
            if (flexicoTemplateDataEdited.hasOwnProperty(fieldName)) {
                return flexicoTemplateDataEdited[fieldName][subKey]
            } else {
                return null
            }
        } else {
            return flexicoTemplateDataEdited[fieldName]
        }
    }

    const getMultiInputFieldValues = (fieldName) => {
        if (flexicoTemplateDataEdited && flexicoTemplateDataEdited[fieldName]) {
            return flexicoTemplateDataEdited[fieldName]
        } else {
            return []
        }
    }

    const getMultiSelectedOptions = (fieldName) => {
        let arr = flexicoTemplateDataEdited[fieldName]
        let selectedOptions = []
        if (arr) {
            arr.forEach((item) => {
                selectedOptions.push({ label: item, value: item })
            })
        }
        return selectedOptions
    }

    const postProcessTeamEmails = (value) => {
        return value.replaceAll("@amazon.com", "")
    }

    const approversValidation = (elements) => {
        let emptyValues = true
        elements?.forEach((element) => {
            if (!element.type || !element.alias) {
                emptyValues = false
            }
        })
        return emptyValues
    }

    const mandatoryFieldListener = (key, enableUpdate) => {
        // mandatoryFieldsMissing[key] = enableUpdate
    }

    const checkIfNull = (data, field, missingFields) => {
        if (data && (!data.hasOwnProperty(field) || !data[field])) {
            missingFields.push(field)
        } else if (Array.isArray(data[field]) && data[field].length <= 0) {
            missingFields.push(field)
        }
    }

    const getMissingFields = (data, fields) => {
        let missingFields = []
        fields.forEach((field) => {
            if (typeof field == 'string') {
                checkIfNull(data, field, missingFields)
            } else {
                if (field?.mainKey) {
                    field.subKeys.forEach(subKey => {
                        data[field.mainKey]?.forEach((item) => {
                            checkIfNull(item, subKey, missingFields)
                        })
                    })
                }
            }
        })
        return missingFields
    }

    const checkIfParameterAreAvailable = (data, fields) => {
        let dataFields = []
        for (let i = 0; i < data?.length; i++) {
            if (data[i]['field_values']?.length > 0) {
                dataFields.push(data[i]['label'])
            }
        }
        let missing = []
        for (let i = 0; i < fields?.length; i++) {
            if (!dataFields?.includes(fields[i])) {
                missing.push(fields[i])
            }
        }
        return missing
    }
    const checkIfParameterAreAvailableByType = (data, types) => {
        let missing = []
        for (let i = 0; i < data?.length; i++) {
            if (types.includes(data[i]['field_type']) && data[i]['field_values']?.length <= 0) {
                missing.push(data[i]['label'])
            }
        }
        return missing
    }

    const checkIfFormIsSubmittable = () => {
        let missingFields = getMissingFields(flexicoTemplateDataEdited, [FLEXICO_TEMPLATE_KEYS.template_name])
        if (missingFields.length <= 0) {
            let missingFields2 = getMissingFields(flexicoTemplateDataEdited, [])
            missingFields = [...missingFields, ...missingFields2]
        }
        let missingParameters = checkIfParameterAreAvailable(flexicoTemplateDataEdited[FLEXICO_TEMPLATE_KEYS.parameters], ['contico_job'])
        missingFields = [...missingFields, ...missingParameters]
        let missingParametersByType = checkIfParameterAreAvailableByType(flexicoTemplateDataEdited[FLEXICO_TEMPLATE_KEYS.parameters], ['fixed_inputs'])
        missingFields = [...missingFields, ...missingParametersByType]
        if (missingFields.length <= 0
            && flexicoTemplateDataEdited.hasOwnProperty(FLEXICO_TEMPLATE_KEYS.approval_required)
            && flexicoTemplateDataEdited[FLEXICO_TEMPLATE_KEYS.approval_required]) {
            if (!missingFields?.includes(FLEXICO_TEMPLATE_KEYS.approvers)
                && flexicoTemplateDataEdited?.hasOwnProperty(FLEXICO_TEMPLATE_KEYS.approvers) && flexicoTemplateDataEdited[FLEXICO_TEMPLATE_KEYS.approvers]?.length == 0) {
                missingFields = [...missingFields, FLEXICO_TEMPLATE_KEYS.approvers]
            }
            let missingFields2 = getMissingFields(flexicoTemplateDataEdited, [{ mainKey: FLEXICO_TEMPLATE_KEYS.approvers, subKeys: [FLEXICO_TEMPLATE_KEYS.APPROVER_KEYS.alias, FLEXICO_TEMPLATE_KEYS.APPROVER_KEYS.type] }])
            missingFields = [...missingFields, ...missingFields2]

        }

        let approvalDecision = true
        if (!validateSoxRequirements(flexicoTemplateDataEdited)) {
            approvalDecision = false
        } else if (flexicoTemplateDataEdited.hasOwnProperty(FLEXICO_TEMPLATE_KEYS.approval_required) && flexicoTemplateDataEdited[FLEXICO_TEMPLATE_KEYS.approval_required] && flexicoTemplateDataEdited.hasOwnProperty(FLEXICO_TEMPLATE_KEYS.approvers)
            && flexicoTemplateDataEdited[FLEXICO_TEMPLATE_KEYS.approvers] && flexicoTemplateDataEdited[FLEXICO_TEMPLATE_KEYS.approvers].length <= 0) {
            approvalDecision = false
        }
        setEnableEditAction(missingFields.length <= 0 && approvalDecision && ((isNewFlexicoTemplate && canAdd) || (isEditMode && canEdit)))
    }

    const validateSoxRequirements = (processDataEdited) => {
        if (processDataEdited.hasOwnProperty(FLEXICO_TEMPLATE_KEYS.sox_approved) && processDataEdited[FLEXICO_TEMPLATE_KEYS.sox_approved]) {
            // if(processDataEdited.hasOwnProperty(FLEXICO_TEMPLATE_KEYS.sox_evidence) && !processDataEdited[FLEXICO_TEMPLATE_KEYS.sox_evidence] && processDataEdited[FLEXICO_TEMPLATE_KEYS.sox_evidence] == ""){
            //   return false
            // }
            if (processDataEdited[FLEXICO_TEMPLATE_KEYS.approvers].length < 2) {
                let groupCount = 0
                processDataEdited[FLEXICO_TEMPLATE_KEYS.approvers].forEach((approver) => {
                    if (approver[FLEXICO_TEMPLATE_KEYS.APPROVER_KEYS.type] == APPROVER_TYPES.APPROVER_LDAP) {
                        groupCount = groupCount + 1
                    }
                })
                if (groupCount > 0) {
                    return true
                } else {
                    return false
                }
            } else {
                return true
            }
        } else {
            return true
        }
    }

    const addTemplateDetails = async () => {
        setErrorMessage("")
        let missingFields = getMissingFields(flexicoTemplateDataEdited, [FLEXICO_TEMPLATE_KEYS.template_name,FLEXICO_TEMPLATE_KEYS.trigger_type])
        if (missingFields && missingFields.length > 0) {
            setErrorMessage('missing mandatory fields : ' + missingFields.join(","))
        } else {
            setShowLoadingIcon(true)
            let group_id = getGroup()
            if (group_id) {
                flexicoTemplateDataEdited[FLEXICO_TEMPLATE_KEYS.group_id] = group_id
            }

            flexicoTemplateDataEdited[FLEXICO_TEMPLATE_KEYS.last_updated_by] = userId
            flexicoTemplateDataEdited[FLEXICO_TEMPLATE_KEYS.created_by] = userId
            flexicoTemplateDataEdited[FLEXICO_TEMPLATE_KEYS.step_function_name] = FLEXICO_STEP_FUNCTION_CONFIG.find((c) => c.environment === currentStage)?.arn;
            flexicoTemplateDataEdited[FLEXICO_TEMPLATE_KEYS.status] = FLEXICO_TEMPLATE_STATUS.TEMPLATE_CREATED
            if (!flexicoTemplateDataEdited.hasOwnProperty(FLEXICO_TEMPLATE_KEYS.parameters)) {
                flexicoTemplateDataEdited[FLEXICO_TEMPLATE_KEYS.parameters] = []
            }
            // let jobParameter = {}
            // jobParameter[FLEXICO_TEMPLATE_KEYS.PARAMETER_KEYS.field_type] = 'fixed_inputs'
            // jobParameter[FLEXICO_TEMPLATE_KEYS.PARAMETER_KEYS.field_values] = [FLEXICO_GLUE_JOB_CONFIG.find((c) => c.environment === currentStage)?.name]
            // jobParameter[FLEXICO_TEMPLATE_KEYS.PARAMETER_KEYS.label] = 'flexico_internal_job_name'
            // flexicoTemplateDataEdited[FLEXICO_TEMPLATE_KEYS.parameters].push(jobParameter)

            await configureGenericAppSync()
            createAuditLog(`FLEXICO`, `ADD_REQ#${getCurrentTime()}`, "Adding a template", JSON.stringify({}), JSON.stringify(flexicoTemplateDataEdited), "ADD REQ", userId, function onSuccess(result) {
                createFlexicoTemplate(flexicoTemplateDataEdited, function onSuccess(response) {
                    setFlexicoTemplateData(flexicoTemplateDataEdited)
                    setIsNewFlexicoTemplate(false)
                    setFlexicoTemplateData(JSON.parse(JSON.stringify(response.data.createFlexicoTemplate)))
                    setShowLoadingIcon(false)
                    history.push(`/App/${response.data.createFlexicoTemplate.app_id}/flexico/${response.data.createFlexicoTemplate.flex_id}`)
                    cancelEdition()
                }, function onFailure(error) {
                    setErrorMessage(error?.errors[0]?.message)
                    setShowLoadingIcon(false)
                })
            }, function onFailure(error) {
                setErrorMessage(`Could not log audit. Adding the template was not attempted ${error?.errors[0]?.message}`)
            })

        }
    }

    const updateTemplateDetails = () => {
        setErrorMessage("")
        let missingFields = getMissingFields(flexicoTemplateDataEdited, [FLEXICO_TEMPLATE_KEYS.flex_id, FLEXICO_TEMPLATE_KEYS.template_name, FLEXICO_TEMPLATE_KEYS.step_function_name])
        if (missingFields && missingFields.length > 0) {
            setErrorMessage('missing mandatory fields : ' + missingFields.join(","))
        } else {
            setShowLoadingIcon(true)
            let group_id = getGroup()
            if (group_id) {
                flexicoTemplateDataEdited[FLEXICO_TEMPLATE_KEYS.group_id] = group_id
            }
            let next_step = getParam('next_step')
            if (next_step) {
                flexicoTemplateDataEdited[FLEXICO_TEMPLATE_KEYS.next_step] = next_step
            }
            flexicoTemplateDataEdited[FLEXICO_TEMPLATE_KEYS.last_updated_by] = userId
            flexicoTemplateDataEdited[FLEXICO_TEMPLATE_KEYS.status] = FLEXICO_TEMPLATE_STATUS.TEMPLATE_UPDATED

            if (updateComments) {
                setErrorMessage("")
                // add an audit log
                createAuditLog(`FLEXICO#${fid}`, `${getCurrentTime()}`, updateComments, JSON.stringify(flexicoTemplateData), JSON.stringify(flexicoTemplateDataEdited), "UPDATE REQ", userId,
                    function onSuccess(result) {
                        setUpdateComments("")
                        updateFlexicoTemplate(flexicoTemplateDataEdited, function onSuccess(response) {
                            setIsNewFlexicoTemplate(false)
                            setShowLoadingIcon(false)
                            cancelEdition()
                            setUpdateNotificationModalError('')
                            setUpdateNotificationModal(true)
                            setFlexicoTemplateData(flexicoTemplateDataEdited)
                        }, function onFailure(error) {
                            setIsNewFlexicoTemplate(false)
                            setShowLoadingIcon(false)
                            cancelEdition()
                            setUpdateNotificationModalError(`Update failed ${error?.errors[0]?.message}`)
                            setUpdateNotificationModal(true)
                        })
                    }, function onFailure(error) {
                        setErrorMessage(`Could not log audit. Updating template was not attempted ${error?.errors[0]?.message}`)
                    })

            } else {
                setShowLoadingIcon(false)
                setErrorMessage("Please provide your comments for the update")
            }

        }
    }

    const cancelEdition = () => {
        setErrorMessage("")
        setIsEditMode(false)
        setFlexicoTemplateDataEdited(flexicoTemplateData)
    }

    const editProcess = () => {
        setIsEditMode(true)
        setFlexicoTemplateDataEdited({ ...flexicoTemplateData })
    }

    const delTemplate = async () => {
        if (updateComments) {
            setShowLoadingIcon(true)
            dismissDeleteDialog()
            setModalError("")
            await configureGenericAppSync()
            createAuditLog(`FLEXICO#${fid}`, `${getCurrentTime()}`, updateComments, JSON.stringify(flexicoTemplateData), JSON.stringify({}), "DELETE REQ", userId,
                function onSuccess(result) {
                    setUpdateComments("")
                    deleteFlexicoTemplate(fid, function onSuccess(response) {
                        setShowLoadingIcon(false)
                        setDeleteConfirmationModal(false)
                        history.push(`/App/${id}`)
                    }, function onFailure(error) {
                        setShowLoadingIcon(false)
                        setDeleteConfirmationModal(false)
                        setErrorMessage(`Deletion failed ${error?.errors[0]?.message}`)
                    })
                }, function onFailure(error) {
                    setErrorMessage(`Could not log audit. Deletion was not attempted. ${error?.errors[0]?.message}`)
                })

        } else {
            setModalError("Please provide comments with the reason for deletion")
        }


    }

    const getCurrentTime = () => {
        return Date.now();
    }

    const getDate = (value) => {
        var d = new Date(value)
        return d.toLocaleString()
    }

    const lastUpdatedContent = (<>
        {getValue(FLEXICO_TEMPLATE_KEYS.last_updated_time_stamp) ? <>

            <span className="last_updated"> Last updated : <span className="bold">{getDate(getValue(FLEXICO_TEMPLATE_KEYS.last_updated_time_stamp))}</span></span>
            {getValue(FLEXICO_TEMPLATE_KEYS.last_updated_by) ? <span className="last_updated"> &nbsp; by  <span className="bold">{getValue(FLEXICO_TEMPLATE_KEYS.last_updated_by)}</span></span> : ''}
        </> : ''}
    </>)

    const getStatusIcon = (status) => {
        const positiveStatuses = FLEXICO_POSITIVE_STATUS
        const negativeStatuses = FLEXICO_NEGATIVE_STATUS
        if (positiveStatuses.includes(status)) {
            return <Icon name='status-positive' variant='success' ></Icon>
        } else if (negativeStatuses.includes(status)) {
            return <Icon name='status-negative' variant='error' ></Icon>
        }
        else {
            return <Icon name='status-in-progress' variant='normal' ></Icon>
        }
    }

    const dataClasificationContent = (
        <Box>
            <SpaceBetween direction="horizontal" size="m">
                <Box>Data Generation Platform: <span className={dataGenerationPlatformClass}>{dataGenerationPlatform}</span></Box>
                <Box>Data Share Platform: <span className={dataSharePlatformClass}> {dataSharePlatform} </span></Box>
            </SpaceBetween>
        </Box>
    )
    const Content = (
        <>
            {showLoadingIcon ? <Modal visible={showLoadingIcon}>
                <Box>
                    <Spinner></Spinner>
                    Loading...
                </Box>
            </Modal> :
                <ContentLayout
                    headerVariant="high-contrast"
                    className="content"
                    header={
                        <SpaceBetween size="l">
                            <Header
                                variant="h1"
                            >

                            </Header>

                        </SpaceBetween>
                    }
                >

                    <SpaceBetween size="l" >
                        {
                            alertErrorMessage ? <Alert
                                statusIconAriaLabel="Error"
                                type="error"
                                header={alertErrorMessage?.header}
                            >
                                {alertErrorMessage?.message}
                            </Alert> : ''
                        }

                        <Container
                        >
                            <SpaceBetween direction="vertical">
                                <SpaceBetween direction="horizontal">
                                    <Box variant="h1">FlexiCo Details</Box>
                                    {
                                        !isEditMode && canEdit ?
                                            <Button iconName="edit" variant="icon" onClick={editProcess} /> : <></>
                                    }
                                    {
                                        !isEditMode && canDelete ?
                                            <Button iconName="remove" variant="icon" onClick={() => { setDeleteConfirmationModal(true) }} /> : <></>

                                    }
                                    <Modal
                                        onDismiss={dismissDeleteDialog}
                                        visible={deleteConfirmationModal}
                                        footer={
                                            <Box float="right">
                                                <SpaceBetween direction="horizontal" size="xs">
                                                    <Button variant="link" onClick={dismissDeleteDialog} >Cancel</Button>
                                                    <Button disabled={deleteAppName !== flexicoTemplateData[FLEXICO_TEMPLATE_KEYS.template_name]} variant="primary" onClick={delTemplate}  >Confirm</Button>
                                                </SpaceBetween>
                                            </Box>
                                        }
                                        header={
                                            <React.Fragment>
                                                <Box variant="h2">
                                                    Delete confirmation for '{flexicoTemplateData[FLEXICO_TEMPLATE_KEYS.template_name]}'
                                                </Box>
                                            </React.Fragment>
                                        }
                                    >
                                        <SpaceBetween direction="vertical" size="m">
                                            <Box>To confirm deletion, enter the name of the process in the text input field below</Box>
                                            <Box>
                                                <Input placeholder={flexicoTemplateData[FLEXICO_TEMPLATE_KEYS.template_name]} onChange={({ detail }) => setDeleteAppName(detail.value)} value={deleteAppName} />
                                            </Box>
                                            <Box>
                                                <Textarea
                                                    class="description"
                                                    onChange={({ detail }) => setUpdateComments(detail.value)}
                                                    value={updateComments}
                                                    placeholder="Comments"
                                                ></Textarea>
                                            </Box>
                                            <Box>
                                                {modalError && (
                                                    <Box variant="p" color="text-status-error">
                                                        {modalError}
                                                    </Box>
                                                )}
                                            </Box>
                                        </SpaceBetween>
                                    </Modal>

                                    <Modal
                                        onDismiss={dismissUpdateNotifyDialog}
                                        visible={updateNotificationModal}
                                        footer={
                                            <Box float="right">
                                                <SpaceBetween direction="horizontal" size="xs">
                                                    <Button variant="primary" onClick={dismissUpdateNotifyDialog}>Okay</Button>
                                                </SpaceBetween>
                                            </Box>
                                        }
                                        header={
                                            <React.Fragment>
                                                <Box variant="h2">
                                                    Update for '{flexicoTemplateData[FLEXICO_TEMPLATE_KEYS.template_name]}'
                                                </Box>
                                            </React.Fragment>
                                        }
                                    >
                                        <SpaceBetween direction="vertical" size="m">
                                            <Box><Icon name="status-positive" variant="success" /> FlexiCo template is updated.</Box>
                                            {
                                                updateNotificationModalError ? <Box variant="p" color="text-status-error">{updateNotificationModalError}</Box> : ''
                                            }
                                        </SpaceBetween>
                                    </Modal>
                                </SpaceBetween>
                                <Box >{lastUpdatedContent}</Box>
                                <br></br>
                            </SpaceBetween>

                            <SpaceBetween size="l">
                                {
                                    !isEditMode ?
                                        <SpaceBetween direction="horizontal" size="m">
                                            {
                                                flexicoTemplateData.hasOwnProperty(FLEXICO_TEMPLATE_KEYS.approval_required)
                                                    && flexicoTemplateData[FLEXICO_TEMPLATE_KEYS.approval_required] ?
                                                    <Box><span className='orange_badge'>Approval required</span></Box>
                                                    : <></>
                                            }
                                            {
                                                flexicoTemplateData.hasOwnProperty(FLEXICO_TEMPLATE_KEYS.sox_approved)
                                                    && flexicoTemplateData[FLEXICO_TEMPLATE_KEYS.sox_approved] ?
                                                    <Box><span className='green_badge'> 2 touch approval  </span></Box>
                                                    : <></>
                                            }

                                        </SpaceBetween>
                                        :
                                        <></>
                                }
                                {
                                    !isNewFlexicoTemplate ? <SpaceBetween direction="vertical" size="s">
                                        <SpaceBetween size="s" direction="horizontal">
                                            <Box>Status :</Box>

                                            {(flexicoTemplateData.hasOwnProperty(FLEXICO_TEMPLATE_KEYS.status) && flexicoTemplateData[FLEXICO_TEMPLATE_KEYS.status]) ?
                                                <SpaceBetween direction="horizontal" size="s"> {getStatusIcon(flexicoTemplateData[FLEXICO_TEMPLATE_KEYS.status])}
                                                    <span className="small_grey_text_italics">{flexicoTemplateData[FLEXICO_TEMPLATE_KEYS.status]}</span></SpaceBetween> : 'Unknown'
                                            }


                                        </SpaceBetween>

                                    </SpaceBetween> : ''
                                }


                                {flashBarComments ? <Flashbar items={flashBarComments} /> : <></>}
                                <ColumnLayout columns={2}>
                                    <div>
                                        <Box variant="h4">FlexiCo template name</Box>
                                        {
                                            isEditMode ?
                                                <CustomInput field={FLEXICO_TEMPLATE_KEYS.template_name} placeholder="FlexiCo template name" updateValue={updateValue} getValue={getValue}></CustomInput>
                                                : flexicoTemplateData?.hasOwnProperty(FLEXICO_TEMPLATE_KEYS.template_name) ? flexicoTemplateData[FLEXICO_TEMPLATE_KEYS.template_name] : ''
                                        }
                                    </div>
                                    <div>
                                        <Box variant="h4">Label - To display in the application page</Box>
                                        {
                                            isEditMode ?
                                                <CustomInput field={FLEXICO_TEMPLATE_KEYS.label} placeholder="label" updateValue={updateValue} getValue={getValue}></CustomInput>
                                                : flexicoTemplateData?.hasOwnProperty(FLEXICO_TEMPLATE_KEYS.label) ? flexicoTemplateData[FLEXICO_TEMPLATE_KEYS.label] : ''
                                        }
                                    </div>
                                    <div>
                                        <Box variant="h4">Description</Box>
                                        {
                                            isEditMode ?
                                                <Textarea
                                                    class="description"
                                                    onChange={({ detail }) => updateValue(FLEXICO_TEMPLATE_KEYS.description, detail.value)}
                                                    value={getValue(FLEXICO_TEMPLATE_KEYS.description)}
                                                    placeholder="Description"
                                                />
                                                : flexicoTemplateData?.hasOwnProperty(FLEXICO_TEMPLATE_KEYS.description) ? flexicoTemplateData[FLEXICO_TEMPLATE_KEYS.description] : ''
                                        }
                                    </div>
                                    <div>
                                        <Box variant="h4">Input type</Box>
                                        {
                                            isEditMode ?
                                                <CustomSelect field={FLEXICO_TEMPLATE_KEYS.trigger_type} getValue={getValue} updateValue={updateValue} getOptions={getSelectFieldValues} />
                                                :
                                                flexicoTemplateData.hasOwnProperty(FLEXICO_TEMPLATE_KEYS.trigger_type) ? flexicoTemplateData[FLEXICO_TEMPLATE_KEYS.trigger_type] : '-'
                                        }
                                    </div>

                                    {
                                        flexicoTemplateDataEdited.hasOwnProperty(FLEXICO_TEMPLATE_KEYS.trigger_type) &&
                                            (flexicoTemplateDataEdited[FLEXICO_TEMPLATE_KEYS.trigger_type] == 'Upload file with parameters' || flexicoTemplateDataEdited[FLEXICO_TEMPLATE_KEYS.trigger_type] == 'Upload file only') ? <div>
                                            <Box variant="h4">Load file type</Box>
                                            {
                                                isEditMode ?
                                                    <CustomMultiSelect field={FLEXICO_TEMPLATE_KEYS.file_types} updateValue={updateValue} getValues={getSelectFieldValues} getSelectedOptions={getMultiSelectedOptions} />
                                                    : flexicoTemplateData?.hasOwnProperty(FLEXICO_TEMPLATE_KEYS.file_types) ?
                                                        <ul>
                                                            {flexicoTemplateData[FLEXICO_TEMPLATE_KEYS.file_types]?.map((item) => (
                                                                <li key={item} className="list-item-data">
                                                                    {item}
                                                                </li>
                                                            ))}
                                                        </ul>
                                                        : ''
                                            }
                                        </div>
                                            : ''
                                    }
                                    
                                </ColumnLayout>
                            </SpaceBetween>

                            <SpaceBetween size="m">
                                <SpaceBetween>
                                    {
                                        flexicoTemplateDataEdited.hasOwnProperty(FLEXICO_TEMPLATE_KEYS.trigger_type) &&
                                        (flexicoTemplateDataEdited[FLEXICO_TEMPLATE_KEYS.trigger_type] == 'Upload file with parameters' || flexicoTemplateDataEdited[FLEXICO_TEMPLATE_KEYS.trigger_type] == 'Upload file only')?
                                        <ColumnLayout columns={2} >

                                        {isEditMode ?
                                            <>
                                                <div>
                                                    <Box variant="h4">&nbsp;</Box>
                                                    <SpaceBetween direction="horizontal" size="s">
                                                        <SpaceBetween direction="horizontal" size="s">
                                                            <CustomCheckbox field={FLEXICO_TEMPLATE_KEYS.sox_approved} getValue={getValue} updateValue={updateValue} text="Enable 2 touch approval" />
                                                            <Button variant="inline-link" onClick={() => { setToolsOpen(!toolsOpen) }}>info</Button>
                                                        </SpaceBetween>
                                                        {
                                                            flexicoTemplateDataEdited[FLEXICO_TEMPLATE_KEYS.sox_approved] ?
                                                                <Box>
                                                                    <Textarea
                                                                        class="Notes"
                                                                        onChange={({ detail }) => updateValue(FLEXICO_TEMPLATE_KEYS.sox_evidence, detail.value)}
                                                                        value={getValue(FLEXICO_TEMPLATE_KEYS.sox_evidence)}
                                                                        placeholder="notes"
                                                                    />
                                                                </Box> : ''
                                                        }
                                                    </SpaceBetween>
                                                    <CustomCheckbox field={FLEXICO_TEMPLATE_KEYS.approval_required} getValue={getValue} updateValue={updateValue} text="Approval required" disabled={isApprovalDisabled || (flexicoTemplateDataEdited?.hasOwnProperty(FLEXICO_TEMPLATE_KEYS.sox_approved) && flexicoTemplateDataEdited[FLEXICO_TEMPLATE_KEYS.sox_approved] )} />
                                                    <CustomCheckbox field={FLEXICO_TEMPLATE_KEYS.critical_data} getValue={getValue} updateValue={updateValue} text="Critical data" disabled={true} />
                                                    <Link external href="https://policy.a2z.com/docs/97/publication">Click here to review Amazon policy on Data Classification Standard</Link>
                                                    
                                                    <br />
                                                    <br />
                                                    {dataClasificationContent}
                                                </div></> : <>

                                                {dataClasificationContent}
                                            </>}
                                    </ColumnLayout>
                                        :<></>
                                    }
                                    
                                </SpaceBetween>

                                <SpaceBetween size="l">
                                    <div className="header_menu">
                                        <div className="header_left">
                                            <Box variant="h1">Parameter(s) Details</Box>
                                        </div>
                                    </div>
                                    {
                                        isEditMode ?
                                            <CustomMultiValuedParameter field={FLEXICO_TEMPLATE_KEYS.parameters} hideLabelPrefix="flexico_internal"  allowAddition={flexicoTemplateDataEdited.hasOwnProperty(FLEXICO_TEMPLATE_KEYS.trigger_type) && flexicoTemplateDataEdited[FLEXICO_TEMPLATE_KEYS.trigger_type] != 'None'}
                                                getValues={getValue} postProcess={postProcessTeamEmails} validator={approversValidation} mandatoryParameters={mandatoryParameters}
                                                updateValue={updateValue} getOptions={getSelectFieldValues} info='Parameters' errorMessage='type and alias input are mandatory' />
                                            :
                                            <div>
                                                {getValue(FLEXICO_TEMPLATE_KEYS.parameters) ? <Table
                                                    columnDefinitions={[
                                                        {
                                                            id: "alt",
                                                            header: "Type",
                                                            cell: item => item[FLEXICO_TEMPLATE_KEYS.PARAMETER_KEYS.field_type] || "-",
                                                            sortingField: "alt"
                                                        },
                                                        {
                                                            id: "variable",
                                                            header: "Label",
                                                            cell: item => item[FLEXICO_TEMPLATE_KEYS.PARAMETER_KEYS.label] || "-",
                                                            isRowHeader: true
                                                        },
                                                        {
                                                            id: "variable",
                                                            header: "Values",
                                                            cell: item => item[FLEXICO_TEMPLATE_KEYS.PARAMETER_KEYS.field_values]?.join() || item[FLEXICO_TEMPLATE_KEYS.PARAMETER_KEYS.field_values],
                                                            isRowHeader: true
                                                        },
                                                    ]}
                                                    items={getValue(FLEXICO_TEMPLATE_KEYS.parameters)}
                                                    loadingText="Loading parameters"
                                                    sortingDisabled
                                                    empty={
                                                        <Box
                                                            margin={{ vertical: "xs" }}
                                                            textAlign="center"
                                                            color="inherit"
                                                        >
                                                            <SpaceBetween size="m">
                                                                <b>No parameters</b>
                                                            </SpaceBetween>
                                                        </Box>
                                                    }
                                                    variant='embedded'
                                                /> : ''}
                                            </div>
                                    }

                                </SpaceBetween>

                                <SpaceBetween size="l">
                                    <ColumnLayout columns={2}>
                                        <div>
                                            <SpaceBetween direction="vertical">
                                                <div className="header_menu">
                                                    <div className="header_left">
                                                        <Box variant="h1">Trigger permissions</Box>
                                                    </div>
                                                </div>
                                                {
                                                    isEditMode ?
                                                        <CustomMultiHybridInputAndSelect field={FLEXICO_TEMPLATE_KEYS.uploaders}
                                                            inputKey={FLEXICO_TEMPLATE_KEYS.UPLOADER_KEYS.alias} selectKey={FLEXICO_TEMPLATE_KEYS.UPLOADER_KEYS.type} selectHint="Choose type"
                                                            placeholder="alias" getValues={getValue} postProcess={postProcessTeamEmails} validator={approversValidation} mandatoryFieldListener={mandatoryFieldListener}
                                                            updateValue={updateValue} getOptions={getSelectFieldValues} info=' ' errorMessage='type and alias input are mandatory' duplicatesNotAllowed={true} />
                                                        :
                                                        <div>
                                                            {getValue(FLEXICO_TEMPLATE_KEYS.uploaders) ? <Table
                                                                columnDefinitions={[
                                                                    {
                                                                        id: "variable",
                                                                        header: "Type",
                                                                        cell: item => (item[FLEXICO_TEMPLATE_KEYS.UPLOADER_KEYS.type] == UPLOADER_TYPES.UPLOADER ? 'USER' : 'LDAP') || "-",
                                                                        isRowHeader: true
                                                                    },
                                                                    {
                                                                        id: "alt",
                                                                        header: "Alias",
                                                                        cell: item => item[FLEXICO_TEMPLATE_KEYS.UPLOADER_KEYS.alias] || "-",
                                                                        sortingField: "alt"
                                                                    }
                                                                ]}
                                                                items={getValue(FLEXICO_TEMPLATE_KEYS.uploaders)}
                                                                loadingText="Loading uploaders details"
                                                                sortingDisabled
                                                                empty={
                                                                    <Box
                                                                        margin={{ vertical: "xs" }}
                                                                        textAlign="center"
                                                                        color="inherit"
                                                                    >
                                                                        <SpaceBetween size="m">
                                                                            <b>No uploaders yet</b>
                                                                        </SpaceBetween>
                                                                    </Box>
                                                                }
                                                                variant='embedded'
                                                            /> : ''}
                                                        </div>
                                                }

                                            </SpaceBetween>
                                        </div>

                                        {
                                            (flexicoTemplateData?.approval_required || flexicoTemplateDataEdited?.approval_required) ?
                                                <SpaceBetween>
                                                    <br></br>
                                                    <div>
                                                        <SpaceBetween direction="vertical">
                                                            <div className="header_menu">
                                                                <div className="header_left">
                                                                    <Box variant="h1">Approver(s) Details</Box>
                                                                </div>
                                                            </div>
                                                            {
                                                                isEditMode && flexicoTemplateDataEdited?.approval_required ?
                                                                    <CustomMultiHybridInputAndSelect field={FLEXICO_TEMPLATE_KEYS.approvers}
                                                                        inputKey={FLEXICO_TEMPLATE_KEYS.APPROVER_KEYS.alias} selectKey={FLEXICO_TEMPLATE_KEYS.APPROVER_KEYS.type} selectHint="Choose type"
                                                                        placeholder="alias" getValues={getValue} postProcess={postProcessTeamEmails} validator={approversValidation} mandatoryFieldListener={mandatoryFieldListener}
                                                                        updateValue={updateValue} getOptions={getSelectFieldValues} info=' ' errorMessage='type and alias input are mandatory' duplicatesNotAllowed={true}
                                                                        needIndex={true} indexKey={FLEXICO_TEMPLATE_KEYS.APPROVER_KEYS.column_index} indexPlaceHolder="Order of approval" defIndex={0}
                                                                    />
                                                                    :
                                                                    <div>
                                                                        {getValue(FLEXICO_TEMPLATE_KEYS.approvers) ? <Table
                                                                            columnDefinitions={[
                                                                                {
                                                                                    id: "variable",
                                                                                    header: "Type",
                                                                                    cell: item => (item[FLEXICO_TEMPLATE_KEYS.APPROVER_KEYS.type] == APPROVER_TYPES.APPROVER ? 'USER' : 'LDAP') || "-",
                                                                                    isRowHeader: true
                                                                                },
                                                                                {
                                                                                    id: "alt",
                                                                                    header: "Alias",
                                                                                    cell: item => item[FLEXICO_TEMPLATE_KEYS.APPROVER_KEYS.alias] || "-",
                                                                                    sortingField: "alt"
                                                                                }
                                                                            ]}
                                                                            items={getValue(FLEXICO_TEMPLATE_KEYS.approvers)}
                                                                            loadingText="Loading approver details"
                                                                            sortingDisabled
                                                                            empty={
                                                                                <Box
                                                                                    margin={{ vertical: "xs" }}
                                                                                    textAlign="center"
                                                                                    color="inherit"
                                                                                >
                                                                                    <SpaceBetween size="m">
                                                                                        <b>No approvers yet</b>
                                                                                    </SpaceBetween>
                                                                                </Box>
                                                                            }
                                                                            variant='embedded'
                                                                        /> : ''}
                                                                    </div>
                                                            }

                                                        </SpaceBetween>
                                                    </div>

                                                </SpaceBetween> : <></>
                                        }
                                    </ColumnLayout>

                                </SpaceBetween>

                            </SpaceBetween>

                        </Container>
                    </SpaceBetween>
                    <SpaceBetween size="m">
                        <Box>
                            {errorMessage && (
                                <Box variant="p" color="text-status-error">
                                    {errorMessage}
                                </Box>
                            )}
                        </Box>
                        {
                            (isNewFlexicoTemplate && canAdd) ?
                                <div className="buttons">
                                    <SpaceBetween direction="horizontal" size="l">
                                        <Button variant="primary" onClick={addTemplateDetails}
                                            disabled={!enableEditAction} >Save</Button>
                                    </SpaceBetween>
                                </div>
                                :
                                ''
                        }
                        {
                            !isNewFlexicoTemplate && isEditMode ?
                                <SpaceBetween direction="vertical" size="l">
                                    <Textarea
                                        class="description"
                                        onChange={({ detail }) => setUpdateComments(detail.value)}
                                        value={updateComments}
                                        placeholder="Comments"
                                    ></Textarea>
                                    <div className="buttons">
                                        <SpaceBetween direction="horizontal" size="l">
                                            <Button variant="primary" onClick={updateTemplateDetails} disabled={!enableEditAction} >Update</Button>

                                            <Button onClick={cancelEdition}>Cancel</Button>
                                        </SpaceBetween>
                                    </div>
                                </SpaceBetween>
                                :
                                ''
                        }
                    </SpaceBetween>

                    <br />
                    {
                        !isNewFlexicoTemplate && !isEditMode ?
                            <SpaceBetween size="l" >
                                <Feedback appId={id} pageId={`flexico_template_${id}_${fid}`} userId={userId} parentId={"null"} level={0} limit={1}></Feedback>
                            </SpaceBetween> : ''
                    }
                </ContentLayout>}

        </>
    )

    const helpContent = (
        <HelpPanel header={<Box variant="h2">Glossary</Box>}>
            <Box>
                <SpaceBetween direction="horizontal" size="s">
                    {/* <Box variant="strong">2 touch approval</Box> */}
                    {/* <Box>Check the box if the use case is 'Enable 2 touch approval' for the two touch approval workflow for SOX purposes or for other regulatory purposes (such as statutory disclosure generation or publicly published balances such as TTC).</Box> */}
                    <h4>2 touch approval required if</h4>
                    <ul>
                        <li>a) Ledger Impact = Primary Ledger (US GAAP) OR Secondary Ledger (Local GAAP) OR Both Primary and Secondary Ledger (US GAAP/Local GAAP/IFRS) AND</li>
                        <li>b) Financial Impact &gt; $500MM</li>
                    </ul>
                    <h4>2 touch approval NOT required if</h4>
                    <ul>
                        <li>a) Ledger Impact = Other regulations OR Operational AND</li>
                        <li>b) Financial Impact &lt; $500MM</li>
                    </ul>
                </SpaceBetween>
            </Box>
        </HelpPanel>
    );


    return (
        <AppLayout
            disableContentPaddings={true}
            content={Content}
            navigation={<SideNav activeHref={`/App/${id}/flexico/${fid}`} id={id} fid={fid} process={flexicoTemplateData} back={backUrl} />}
            contentType="default"
            navigationOpen={NavOpen}
            onNavigationChange={() => {
                setNavOpen(!NavOpen);
            }}
            tools={helpContent}
            toolsOpen={toolsOpen}
            onToolsChange={() => { setToolsOpen(!toolsOpen) }}
            headerVariant="high-contrast"
        />
    );
};