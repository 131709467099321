import React, { useState, useEffect, useRef, useCallback} from 'react'
import { useSelector , useDispatch} from 'react-redux'

import {  AppLayout, HelpPanel,  Box, SpaceBetween, Header, Button, BreadcrumbGroup,
            Modal,  Container, Icon, Spinner, Tabs, ExpandableSection,
            Grid, Select, ColumnLayout, Link
        } from '@amzn/awsui-components-react';
import SideNav from './SideNav';
import appSettings from './app_settings';

import { useHistory , useLocation} from "react-router-dom";
import AccessDenied from '../../AccessDenied'
import WaitForPageAccess from '../../WaitForPageAccess'

import { setCurrentAppId } from '../../store/userAuthSlice';

import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

import { AgGridReact } from 'ag-grid-react';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

import {  months, years, uploadDatasets, teamNames, loadTypes, dsColDefs } from './Constants'
import * as XLSX from 'xlsx';

import { API, graphqlOperation } from 'aws-amplify';
import { Storage } from 'aws-amplify';



const Inputs = () => {
    const dispatch = useDispatch()

    const history = useHistory();
    const userId  = useSelector((globalState)=>globalState.auth.userId)
    const gridRef = useRef();

    const currentAppId = useSelector((globalState)=>globalState.auth.currentAppId)
    const isCurrentAppAuthorised = useSelector((globalState)=>globalState.auth.isCurrentAppAuthorized)

    const [isCheckingAccess, setIsCheckingAccess] = useState(true);
    const [isAuthorized, setIsAuthorized] = useState();
    const [isLoading, setIsLoading] = useState(false);
    
    const [isFileSelected, setIsFileSelected] = useState(false);
    const [showFileNotSeleted, setShowFileNotSeleted] = useState(false);
    const [fileName, setFileName] = useState('');
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [columnsList, setColumnsList] =useState([])

    
    const [items, setItems] = useState([]);
    const [processingStatus, setProcessingStatus] = useState('info');
    const [currentStatusId, setCurrentStatusId] = useState('');
    
    const [statusUpdate, setStatusUpdate] = useState('No status update at the moment.');
    
    const [showError, setShowError] = useState(false);
    const [showLoadingIcon, setShowLoadingIcon] = useState(true);

   

    const [selectedTeamName, setSelectedTeamName] = useState({ label :"AWS-EMEA" , value :"AWS-EMEA"} );
    const [selectedMonth, setSelectedMonth] = useState({});
    const [selectedYear, setSelectedYear] = useState({});
    const [selectedDataset, setSelectedDataset] = useState({ label: "Additional-Dataset", value: "ADDITIONAL_DATASET"});
    
    const [selectedLoadType, setSelectedLoadType] = useState({ label: "Delete previous & Add New Data ", value: "TRIN"});
    
    const [selectedColDefs, setSelectedColDefs] = useState(dsColDefs[0].QUEUE_MANUAL);
    
    const [selectedFileName, setSelectedFileName] = useState('');
    const [selectedFileJson, setSelectedFileJson] = useState([]);
    const [selectedFileCSV, setSelectedFileCSV] = useState(null);
    const [selectedfileDetails, setSelectedFileDetails] = useState([]);
    

    const [toolsHide, setToolsHide] = useState(true);
    const [activeTabId, setActiveTabId] = useState('New');
    const [activeStep, setActiveStep] = useState('first');
    
    const [isUploading, setIsUploading] = useState(false);
    const [showUploadingModal, setShowUploadingModal] = useState(false);
    const [uploadStatus, setUploadStatus] = useState('');
    const [isUploadingCompleted, setIsUploadingCompleted] = useState(false);
    const [viewUploadHistory, setViewUploadHistory] = useState(false);
    const [fileDetails, setFileDetails] = useState([]);
    


    
    useEffect(() => {
      if(isAuthorized === true || isAuthorized === false){
        setIsCheckingAccess(!isCheckingAccess);
      } 
    }, [isAuthorized]); 

    
  useEffect(() => {
    if (currentAppId !== appSettings.appId){
      dispatch(setCurrentAppId(appSettings.appId))
    }
    setIsAuthorized(isCurrentAppAuthorised)

    let mecMonth = moment().subtract(1, 'months').format('MMM').toUpperCase();
    let mecYear = moment().subtract(1, 'months').format('YY');
    
    setSelectedMonth({ label: mecMonth, value: mecMonth, disabled: false })
    setSelectedYear({ label: mecYear, value: mecYear, disabled: false })


    loadData()
    
  

    return () => {
      // this now gets called when the component unmounts
      // subscription.unsubscribe()
    };
    
    
    },[statusUpdate, isCurrentAppAuthorised]);
    
    const defaultColDef = {
      // set filtering on for all columns
      filter: true,
      filterParams: {
          buttons: ['reset'],
          debounceMs: 200
      }
    };
  
    const cancelClick = () =>{
      history.push('/BoffinHome')
    }

    const ModalOkClick = () =>{
      setShowSuccessModal(false)
      history.push('/BoffinInputsHistory')
    }
    const onFollowHandler = (ev) => {
        ev.preventDefault();
        if (ev.detail.href) {
            history.push(ev.detail.href.substring(1));
        }
    }
    
    const breadItems = [
        { text: 'Home', href: '/Home' },
        { text: 'ATeam BI', href: '/ATeamBIHome' },
        { text: 'Boffin', href: '/BoffinHome' },
        { text: 'Inputs', href: '' },

    
      ];
    
      
    
      const  changeHandler = async (event) => {
        setIsFileSelected(true)
        setShowFileNotSeleted(false)
    
        const file = event.target.files[0]
        // console.log(file)
        setSelectedFileDetails(file)
        setFileName(file.name)
        const data = await file.arrayBuffer();
        const wb = XLSX.readFile(data, {sheetRows: 1})
        const workbook = XLSX.read(data)
        const worksheet = workbook.Sheets[workbook.SheetNames[0]]
        var readOptions = { type: 'binary', raw: false, defval: null, cellNF: false, cellDates: true, cellText: false, dateNF: 'yyyy-mm-dd;@' }
        const jsonData = XLSX.utils.sheet_to_json(worksheet, readOptions)
        
        const refinedResult = jsonData.map((item) => {
          const newItem = {}
          Object.keys(item).forEach((key) => {
            let replacedKey = key.trim()
              newItem[replacedKey] = item[key]
            }
          )
          return newItem;
        })

        refinedResult.map((item) => {
          Object.keys(item).forEach((key) => {
            if(key.toUpperCase() === 'DEBIT' || key.toUpperCase() === 'CREDIT') {
              if(typeof(item[key]) === 'string') {
                item[key] = item[key].trim().replaceAll(",","")
              }  
            }
          })
        })

        const headerInfo = XLSX.utils.sheet_to_json(worksheet, {header:1, defval:""})
        
        const colNames = headerInfo[0]
        const definitions = colNames.map(colName => ({
          id: colName,
          field: colName,
          sortable: true, 
          resizable: true,

          cell: item => item[colName] || '-'
       }))

       setColumnsList(definitions)
        // console.log(definitions)
        setItems(refinedResult)
        setIsLoading(false)
        setActiveStep('third')
        
    
      }
    
      const loadData = async () =>{
        setShowLoadingIcon(true)

        var mQry = `query MyQuery {
          listUI4BI_BoffinUploadFiles(limit: 1000) {
            items {
              fileId
              fileName
              loadType
              datasetYear
              datasetName
              datasetMonth
              createDate
              processingDetails
              processingStatus
              teamName
              userFileName
              userId
            }
          }
        }
        
        `
            // console.log(mQry)
            let response = await API.graphql(graphqlOperation(mQry));
            let qResultJSON = response.data.listUI4BI_BoffinUploadFiles.items
            qResultJSON.sort(function (a, b) {
                var dateA = new Date(a.createDate), dateB = new Date(b.createDate);
                return dateB - dateA;
            });
            setItems(qResultJSON)
            setShowLoadingIcon(false)
           
           
    }
      
      
      
      const onSubmitClick = async () =>{
    
        if(isFileSelected){
            // This code is upload an excel file
            setIsUploading(true)
            setShowSuccessModal(true)
            
            let newId = uuidv4()
    
            let folder = 'Boffin/uploads/pending/'
            let fn = folder + newId + '.csv'
    
            var ws =  XLSX.utils.json_to_sheet(items);
            var csv = XLSX.utils.sheet_to_csv(ws);
    
            Storage.put(fn, csv, {
                tagging: "userId=" + userId,
                level: 'public'
            }).then(result   => {
                // console.log(result)
                console.log('File has been uploaded')
                callContico(newId, fn)
            })
            
            
            
            
    
        }
      }
      const callContico = async (newId,fn ) =>{
        let dt = moment().format();

        let mQry1 = `
                        mutation MyMutation {
                          createUI4BI_BoffinUploadFiles(
                                input: {fileId: "` + newId +`", 
                                fileName    :"` + fn +`",
                                loadType    :"` + selectedLoadType.value +`",
                                datasetName :"` + selectedDataset.value +`",
                                datasetYear :"` + selectedYear.value +`",
                                datasetMonth:"` + selectedMonth.value +`",
                                createDate  : "` + dt +`",
                                processingStatus: "Processing",
                                processingDetails: "The file has been uploaded and is being processed.",
                                teamName:"` + selectedTeamName.value +`",
                                userFileName:"` + fileName +`",
                                userId:"` + userId +`"
                                }){
                                    fileId
                                }
                        }
            `
            // console.log(mQry)
            const data = await API.graphql(graphqlOperation(mQry1))
            .then(result => {
                console.log(result)
                //  lets drop the file into s3 bucket
                let newId = result.data.createUI4BI_BoffinUploadFiles.fileId
                let folder = 'contico_lambda_trigger/pending/'
                let fileName = folder + newId + '.json'
                let mTxt = `
                            {
                              "processName": "boffin",
                              "scriptName": "LoadManualDataset",
                              "parameters": {"computeId": "` + newId + `"}
                            }
                            `
      
                // console.log(mTxt)
                Storage.put(fileName, mTxt, {
                  tagging: "userId=" + userId,
                  level: 'public'
                  }).then(result   => {
                    setStatusUpdate('Processing is about to start')
                    // console.log(result)
                })
      
            });
           
            setIsUploading(false)
            setActiveStep('first')
      }  

    
   
      
    const tabs = [
                  {
                    label: "Step 1",
                    id: "first",
                    content: (
                      <Box>
                        <Box variant='h3'>
                          Dataset Information
                        </Box>
                        <br></br>
                        <ColumnLayout columns={4}>
                        <Box>
                          <Box variant='awsui-key-label'>TeamName</Box>
                          <Select  selectedOption={selectedTeamName} options={teamNames} onChange={({detail})=>{setSelectedTeamName(detail.selectedOption)}}></Select>
                        </Box>
                        <Box>
                          <Box variant='awsui-key-label'>Dataset Name</Box>
                          <Select  selectedOption={selectedDataset} options={uploadDatasets} onChange={({detail})=>{
                            setSelectedDataset(detail.selectedOption)
                            dsColDefs.map((d)=>{
                              // console.log(d[detail.selectedOption.value])
                              setSelectedColDefs(d[detail.selectedOption.value])
                            })

                          }}></Select>
                        </Box>
                        <Box>
                          <Box variant='awsui-key-label'>Period</Box>
                          <Grid gridDefinition={[{ colspan: 5 }, { colspan: 5 }]}>
                              <Select  selectedOption={selectedMonth} options={months} onChange={({detail})=>{setSelectedMonth(detail.selectedOption)}}></Select>
                              <Select  selectedOption={selectedYear} options={years} onChange={({detail})=>{setSelectedYear(detail.selectedOption)}}></Select>
                          </Grid>
                        </Box>
                        <Box>
                          <Box variant='awsui-key-label'> Load Option</Box>
                          <Select  selectedOption={selectedLoadType} options={loadTypes} onChange={({detail})=>{setSelectedLoadType(detail.selectedOption)}}></Select>
                          
                        </Box>
                        <Box textAlign="left">
                        
                        </Box>
                        <Box></Box>
                        <Box></Box>
                  
                        </ColumnLayout>
                        <br></br>
                        <SpaceBetween size='m' direction='horizontal'>
                          <Button variant='normal' onClick={()=>{history.push('/BoffinHome')}}>Cancel</Button>
                          <Button variant='primary' onClick={()=>{setActiveStep('second')}}>Proceed to Step 2</Button>
                        </SpaceBetween>
                  </Box>
                    )
                  },
                  {
                    label: "Step 2",
                    id: "second",
                    content: (
                      <Box>
                        <ColumnLayout columns={3}>
                        <Box variant='h3'>
                          File Information
                          <br></br>
                            <br></br>
                          <Box>
                            <input type="file"
                                accept=".xlsx, .csv"
                                style={{ padding: '0px' }}
                                onChange={changeHandler}
                            />
                            </Box>
                            
                        </Box>
                        <Box color="text-body-secondary">
                          Note: Excel or CSV files only (.xlsx or .csv)
                        </Box>
                        <Box>&nbsp;</Box>
                        <Box></Box>
                        </ColumnLayout>
                        <br></br><br></br><br></br>
                        <SpaceBetween size='m' direction='horizontal'>
                          <Button variant='normal' onClick={()=>{setActiveStep('first')}}>Back to Step 1</Button>
                          
                        </SpaceBetween>
                        
                      </Box>
                      

                    )
                  },
                  {
                    label: "Step 3",
                    id: "third",
                    content: (
                      <Box>
                        <Box variant='h3'>
                          Preview & Submit 
                        </Box>
                      <div className='ag-theme-alpine' 
                          style={{height: 400, width: '100%' }}>
                      <AgGridReact
                          
                          ref={gridRef}
                          rowData={items}
                          defaultColDef={defaultColDef}
                          columnDefs={columnsList}
                          rowSelection='single'
                          animateRows={true}
                          rowHeight={30}
                          
                          // onSelectionChanged={onSelectionChanged}
                      >
                              
                      </AgGridReact>
                    </div>
                    <br></br>
                      <ExpandableSection headerText="Dataset Info">
                        <ColumnLayout columns={4}>
                          <Box>
                            <Box variant='awsui-key-label' >TeamName</Box>
                            <Box>{selectedTeamName.value}</Box>
                          </Box>
                          <Box>
                            <Box variant='awsui-key-label' >DataSet</Box>
                            <Box>{selectedDataset.value}</Box>
                          </Box>
                          <Box>
                            <Box variant='awsui-key-label' >Period</Box>
                            <Box>{selectedMonth.value}-{selectedYear.value}</Box>
                          </Box>
                          <Box>
                            <Box variant='awsui-key-label'>Load Type</Box>
                            <Box>{selectedLoadType.value}</Box>
                          </Box>
                          <Box>
                            <Box variant='awsui-key-label'>Row Count</Box>
                            <Box>{items.length}</Box>
                          </Box>
                        </ColumnLayout>
                        

                      </ExpandableSection>
                    <br></br>
                    <SpaceBetween size='m' direction='horizontal'>
                    <Button variant='normal' onClick={()=>{setActiveStep('second')}}>Previous Step</Button>
                      <Button variant='primary'  onClick={()=>{onSubmitClick()}}>Submit</Button>
                      <Button variant='normal' onClick={()=>{cancelClick()}}>Cancel</Button>

                    </SpaceBetween>
                    </Box>

                    )
                  }
                ]
              

    const content =(
          <Box>
            <p/>
            {isAuthorized ? 
                <Box>
                  {
                    showSuccessModal ?
                    <Modal
                        onDismiss={ModalOkClick}
                        visible={showSuccessModal}
                        footer={
                            <Box float="right">
                            <SpaceBetween direction="horizontal" size="xs">
                                <Button variant="primary" onClick={ModalOkClick}>Ok</Button>
                            </SpaceBetween>
                            </Box>
                        }
                        header="File Upload Status"
                        >
                        <Box>
                            {
                              isUploading ? 
                              <Box>
                                <ColumnLayout columns={2}>
                                  <Box variant='awsui-key-label'>Status</Box>
                                  <Box>
                                    <Spinner visible={isUploading} ></Spinner> 
                                    Uploading file ...
                                  </Box>
                                  <Box variant='awsui-key-label'>Row Count</Box>
                                  <Box>{items.length}</Box>
                                  <Box variant='awsui-key-label'>File Name</Box>
                                  <Box>{fileName}</Box>
                                </ColumnLayout>
                                
                                
                              </Box>:
                                  <Box>
                                    <Icon variant="success" size='medium' name='status-positive'></Icon>
                                    &nbsp; &nbsp;
                                    Your file has been uploaded. Click ok to continue.
                                  </Box>
                                  
                            }
                            
                        </Box>
                        
                        <br></br>
                        
                    </Modal> : <div></div>
                  }
                  <p></p>
                  <Container
                   header={
                    <Header 
                        variant='h2'
                        actions= {
                            <SpaceBetween direction="horizontal" size="xs">
                                <Button variant='normal' onClick={()=>{history.push('/BoffinInputsHistory')}}>
                                  View Upload History
                                </Button>
                        </SpaceBetween>
                        }
                        description= "The following steps shall enable you to upload a new dataset."
                    >New Dataset
                       
                    </Header>
                    }
                  >

                    <Tabs  
                      
                      activeTabId={activeStep}
                      
                      tabs={tabs} 
                      >
                    </Tabs>
                  </Container>
                </Box>
            :isCheckingAccess ? 
                <div>
                    <WaitForPageAccess></WaitForPageAccess>
                </div> :
                <div>
                    <AccessDenied appName={appSettings.appName}></AccessDenied>
                </div> 
            }
          </Box>
    )
    
    const helpContent = (
        <HelpPanel
            
            header={<div>
                <div>
    
                </div>
                <h2>Additional Info </h2>
                <br></br>
                <Box>
                  <SpaceBetween direction='vertical' size='m'>
                      <Link
                        href='https://amazon.awsapps.com/workdocs/index.html#/folder/1d3d4263007e790c32f2501d1758ef100f4329cf83eb02a9511a476a4d74be81'
                        external 
                        >
                        Available Input Templates
                      </Link>

                      <ExpandableSection
                        headerText="Important Instructions for 16999 Queue Datatset">
                          <Box>
                            
                            <ol>
                                <li>Ensure all rows underneath the queue data is deleted.</li>
                                <li>Ensure all columns to the right of the queue data is deleted</li>
                                <li>Ensure the Mass addition ID is populated</li>
                                <li>Check the GL string column to ensure 7 segments are all entered correctly
                                    <ol>
                                        <li>Ensure each segment in Gl string separated by “-“</li>
                                        <li>Ensure company segment length is 2 or 4 and all other segment length is 4.</li>
                                        <li>If other segment are 0 ensure four 0s are entered. (e.g Channel should be “0000” instead of “0”)</li>
                                    </ol>
                                </li>
                                <li>Ensure columns 'FIXED_ASSETS_COST', 'MERGED_COSTS' and 'TOTAL_COSTS' are formatted as numbers without commas</li>
                            </ol>
                        </Box>
                      </ExpandableSection>

                      <ExpandableSection
                        headerText="Instructions for CIP Tracker"
                        >
                          Date Format should be YYYY-MM-DD
                          for Location and Project Go Live Date.
                        </ExpandableSection>

                  </SpaceBetween>
                   
                </Box>
               
            </div>}
        >
            
            <div>
    
            </div>
        </HelpPanel>
      )
    
  return (
    <AppLayout
        breadcrumbs={<BreadcrumbGroup items={breadItems} onFollow={(event)=>onFollowHandler(event)}></BreadcrumbGroup>}
        content={content}
        navigation={<SideNav activeHref="/BoffinInputs" />}
        headerSelector="#TopBar"
        navigationHide={false}
        onToolsChange={()=>{setToolsHide(!toolsHide)}}
        toolsWidth={350}
        tools={isAuthorized ? helpContent : ''}
        toolsOpen={isAuthorized? toolsHide : false}
        headerVariant="high-contrast"
  ></AppLayout>
  )
}



export default Inputs
