import React, { useState, useEffect, useRef } from "react";

import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";

import {
  AppLayout,
  BreadcrumbGroup,
  HelpPanel,
  Button,
  Box,
  Select,
  Spinner,
  Checkbox,
  SpaceBetween,
  Container,
  Header,
  Icon,
} from "@amzn/awsui-components-react";
import axios from "axios";

import SideNav from "./SideNav";
import appSettings from "./app_settings";

import { teamNames } from "./Constants";

import { AgGridReact } from "ag-grid-react";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";

import { setCurrentAppId } from "../../store/userAuthSlice";

import checkPageAccess from "../../checkPageAccess";
import AccessDenied from "../../AccessDenied";
import WaitForPageAccess from "../../WaitForPageAccess";

import { Auth, Amplify } from "aws-amplify";
import {Board, BoardItem} from "@amzn/awsui-board-components";
import { boardI18nStrings, boardItemI18nStrings } from 'src/i18n-strings/board-I18nStrings';

const BoffinTeamNameMapping = () => {
  const userId = useSelector((globalState) => globalState.auth.userId);
  const history = useHistory();
  const currentAppId = useSelector(
    (globalState) => globalState.auth.currentAppId
  );
  const isCurrentAppAuthorised = useSelector(
    (globalState) => globalState.auth.isCurrentAppAuthorized
  );
  const [isCheckingAccess, setIsCheckingAccess] = useState(true);
  const [isAuthorized, setIsAuthorized] = useState();
  const dispatch = useDispatch();
  const gridRef = useRef();
  const [dsInfo, setDsInfo] = useState([]);
  const [showLoadingIcon, setShowLoadingIcon] = useState(false);
  const [showUpdatingIcon, setShowUpdatingIcon] = useState(false);
  const [updateStatus, setUpdateStatus] = useState("");

  const [selectedTeamName, setSelectedTeamName] = useState({});
  const [isAWS, setIsAWS] = useState(false);

  const [selectedItem, setSelectedItem] = useState([]);
  const [boardItems, setBoardItems] = useState([
    { id: "1", rowSpan: 5, columnSpan: 6,data:{}}
  ]);

  useEffect(() => {
    if (currentAppId !== appSettings.appId) {
      dispatch(setCurrentAppId(appSettings.appId));
    }
    setIsAuthorized(isCurrentAppAuthorised);
    if (isCurrentAppAuthorised) {
      LoadData();
    }
    return () => {
      // this now gets called when the component unmounts
    };
  }, [isCurrentAppAuthorised]);

  useEffect(() => {
    if(isAuthorized === true || isAuthorized === false){
      setIsCheckingAccess(!isCheckingAccess);
    } 
  }, [isAuthorized]);

  const breadItems = [
    { text: "Home", href: "/Home" },
    { text: "ATeam BI", href: "/ATeamBIHome" },
    { text: "Boffin", href: "/BoffinHome" },
    { text: "Team Name Mapping", href: "/" },
  ];

  const onFollowHandler = (ev) => {
    ev.preventDefault();
    if (ev.detail.href) {
      history.push(ev.detail.href.substring(1));
    }
  };

  const LoadData = async () => {
    setShowLoadingIcon(true);
    // console.log('Need to execute sql here..')
    let mSQL =
      "SELECT Company_code, `Company Description` as Description, `Ledger Name` as Ledger_Name, isAWS, team_name,\
       case when `End Date` is NULL or `End Date` = '' THEN '' ELSE DATE_FORMAT(str_to_date(`End Date`, '%m/%d/%y'),'%d/%m/%Y') end as End_Date , Enabled, Parent, Currency, `Ledger ID` as Ledger_ID FROM boffin.map_teamname_comp;";
    let body =
      `{
          "processName" : "MySQL",
          "scriptName"  : "executeSQLonMySQL",
          "parameters"  : {"sql":"` +
      mSQL +
      `"}
        }`;

    // console.log(body)
    const ApiGateWayEndpoint = Amplify.Cache.config.ApiGateWayEndpoint;
    let url = ApiGateWayEndpoint + "/executeOnDemand";
    axios
      .post(url, body, {
        headers: {
          Authorization: (await Auth.currentSession())
            .getIdToken()
            .getJwtToken(),
        },
      })
      .then((response) => {
        //  console.log(response.data.data)
        setDsInfo(response.data.data);
        setShowLoadingIcon(false);
      });
  };

  const updateRecord = async () => {
    setShowUpdatingIcon(true);
    let mAWS = "";
    if (isAWS) {
      mAWS = "Yes";
    } else {
      mAWS = "No";
    }
    let mSQL =
      "UPDATE boffin.map_teamname_comp SET team_name = '" +
      selectedTeamName.value +
      "', isAWS = '" +
      mAWS +
      "' WHERE company_code ='" +
      selectedItem.Company_code +
      "';";
    let body =
      `{
          "processName" : "MySQL",
          "scriptName"  : "executeSQLonMySQL",
          "parameters"  : {"sql":"` +
      mSQL +
      `"}
        }`;

    // console.log(body)
    const ApiGateWayEndpoint = Amplify.Cache.config.ApiGateWayEndpoint;
    let url = ApiGateWayEndpoint + "/executeOnDemand";
    axios
      .post(url, body, {
        headers: {
          Authorization: (await Auth.currentSession())
            .getIdToken()
            .getJwtToken(),
        },
      })
      .then((response) => {
        //  console.log(response.data.records)
        if (response.data.records == "Updated") {
          setShowUpdatingIcon(false);
          setUpdateStatus(response.data.records);
          LoadData();
        }
      });
  };

  const defaultColDef = {
    // set filtering on for all columns
    filter: true,
    filterParams: {
      buttons: ["reset"],
      debounceMs: 200,
    },
  };

  const columnDefs = [
    {
      field: "Company_code",
      width: "150px",
      resizable: true,
      sortable: true,
      headerName: "Company Code",
    },
    {
      field: "Description",
      width: "250px",
      sortable: true,
      resizable: true,
      headerName: "Company Description",
    },
    {
      field: "End_Date",
      width: "250px",
      sortable: true,
      resizable: true,
      headerName: "End Date",
    },
    {
      field: "Enabled",
      width: "250px",
      sortable: true,
      resizable: true,
      headerName: "Enabled",
    },
    {
      field: "Parent",
      width: "250px",
      sortable: true,
      resizable: true,
      headerName: "Parent",
    },
    {
      field: "Ledger_ID",
      width: "250px",
      sortable: true,
      resizable: true,
      headerName: "Ledger ID",
    },
    {
      field: "Ledger_Name",
      width: "250px",
      sortable: true,
      sortable: true,
      resizable: true,
      headerName: "Ledger Name",
    },
    {
      field: "Currency",
      width: "250px",
      sortable: true,
      sortable: true,
      resizable: true,
      headerName: "Currency",
    },
    {
      field: "team_name",
      width: "250px",
      sortable: true,
      resizable: true,
      headerName: "team_name",
    },
    {
      field: "isAWS",
      width: "150px",
      sortable: true,
      resizable: true,
      headerName: "Is AWS",
    },
  ];
  const onSelectionChanged = () => {
    const selectedRows = gridRef.current.api.getSelectedRows();
    if (selectedRows.length > 0) {
      // console.log(selectedRows[0])
      setSelectedItem(selectedRows[0]);
      setSelectedTeamName({
        label: selectedRows[0].team_name,
        value: selectedRows[0].team_name,
      });
      selectedRows[0].isAWS == "Yes" ? setIsAWS(true) : setIsAWS(false);
    }
  };

  const downloadFileClick = () => {

    if(dsInfo.length >= 0) {
      let csv = '';

      for (let col of columnDefs) {
        csv += col.headerName + ','
      }
      csv += '\n'

      for(let row of dsInfo) {
        csv += `"${row.Company_code}"` + ','
        csv += `"${row.Description}"` + ','
        csv += `"${row.End_Date}"` + ','
        csv += `"${row.Enabled}"` + ','
        csv += `"${row.Parent}"` + ','
        csv += `"${row.Ledger_ID}"` + ','
        csv += `"${row.Ledger_Name}"` + ','
        csv += `"${row.Currency}"` + ','
        csv += `"${row.team_name}"` + ','
        csv += `"${row.isAWS}"` + ','
        csv += '\n'
      }
    
    let link = document.createElement('a')
    link.id = 'download-csv'
    link.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(csv));
    link.setAttribute('download', 'TeamNameMapping.csv');
    document.body.appendChild(link)
    document.querySelector('#download-csv').click()

    }

  }

  const helpContent = (
    <HelpPanel
      header={
        <div>
          <h2>Modify Info </h2>
          {selectedItem.length == 0 ? (
            <small>Select a Company to update values</small>
          ) : (
            <div></div>
          )}
        </div>
      }
    >
      <Box>
        <SpaceBetween size="m">
          <Box variant="awsui-key-label">{selectedItem.Description}</Box>
          <Box>
            <Box variant="awsui-key-label">TeamName</Box>
            <Select
              selectedOption={selectedTeamName}
              options={teamNames}
              onChange={({ detail }) => {
                setSelectedTeamName(detail.selectedOption);
              }}
            ></Select>
          </Box>
          <Box>
            <Box variant="awsui-key-label">isAWS (Yes / No)</Box>
            <Checkbox
              onChange={({ detail }) => setIsAWS(detail.checked)}
              checked={isAWS}
            >
              {isAWS}
            </Checkbox>
          </Box>
          <Box>
            <Button variant="primary" onClick={() => updateRecord()}>
              Update
            </Button>
          </Box>
          <Box>
            {showUpdatingIcon ? (
              <Box>
                {" "}
                <Spinner></Spinner> Updating...{" "}
              </Box>
            ) : (
              <Box>
                {updateStatus === "Updated" ? (
                  <Icon variant="success" name="status-positive"></Icon>
                ) : (
                  ""
                )}
                &nbsp; {updateStatus}
              </Box>
            )}
          </Box>
        </SpaceBetween>
      </Box>
    </HelpPanel>
  );

  const content = (
    <div>
      {isAuthorized ? (
        <Box>
          {showLoadingIcon ? (
            <Box>
              <Spinner></Spinner>
              <Box>Loading Team Name mapping...</Box>
              <Box>Please wait, takes upto a minute...</Box>
            </Box>
          ) : (
            <Box>
              <Container
                header={
                  <Header
                    actions={
                      <SpaceBetween direction="horizontal" size="xs">
                        <Button
                          iconName="download"
                          onClick={() => downloadFileClick()}
                        >
                          Download Team Name Mapping
                        </Button>
                        <Button
                          variant="normal"
                          iconName="refresh"
                          onClick={() => LoadData()}
                        >
                          Refresh
                        </Button>
                      </SpaceBetween>
                    }
                  >
                    Team Name Mapping
                  </Header>
                }
              >
                <Board
                  renderItem={(item) => (
                    <BoardItem i18nStrings={boardItemI18nStrings}>
                      <div
                        className="ag-theme-alpine"
                        style={{ height: "92%", width: "100%" }}
                      >
                        <AgGridReact
                          ref={gridRef}
                          rowData={dsInfo}
                          defaultColDef={defaultColDef}
                          columnDefs={columnDefs}
                          rowSelection="single"
                          animateRows={true}
                          rowHeight={30}
                          onSelectionChanged={onSelectionChanged}
                          enableCellTextSelection={true}
                        ></AgGridReact>
                      </div>
                    </BoardItem>
                  )}
                  onItemsChange={(event) => setBoardItems(event.detail.items)}
                  items={boardItems}
                  i18nStrings={boardI18nStrings}
                />
              </Container>
            </Box>
          )}
        </Box>
      ) : isCheckingAccess ? (
        <div>
          <WaitForPageAccess></WaitForPageAccess>
        </div>
      ) : (
        <div>
          <AccessDenied appName={appSettings.appName}></AccessDenied>
        </div>
      )}
    </div>
  );

  return (
    <AppLayout
      breadcrumbs={
        <BreadcrumbGroup
          items={breadItems}
          onFollow={(event) => onFollowHandler(event)}
        ></BreadcrumbGroup>
      }
      content={content}
      navigation={<SideNav activeHref="/BoffinTeamNameMapping" />}
      headerSelector="#TopBar"
      navigationHide={false}
      tools={isAuthorized ? helpContent : ''}
      toolsOpen={isAuthorized? true : false}
      maxContentWidth = {Number.MAX_VALUE}
      headerVariant="high-contrast"
    ></AppLayout>
  );
};

export default BoffinTeamNameMapping;
