import React, { useState, useEffect, useRef, useMemo } from "react";

import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";

import {
  AppLayout,
  BreadcrumbGroup,
  HelpPanel,
  Button,
  Box,
  Select,
  Spinner,
  Checkbox,
  SpaceBetween,
  Container,
  Header,
  Icon,
  Alert,
} from "@amzn/awsui-components-react";
import axios from "axios";

import SideNav from "./SideNav";
import appSettings from "./app_settings";

import { Amplify, Auth, API, graphqlOperation } from "aws-amplify";

import { AgGridReact } from "ag-grid-react";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";

import { setCurrentAppId } from "../../store/userAuthSlice";

import checkPageAccess from "../../checkPageAccess";
import AccessDenied from "../../AccessDenied";
import WaitForPageAccess from "../../WaitForPageAccess";
import {Board, BoardItem} from "@amzn/awsui-board-components";
import { boardI18nStrings, boardItemI18nStrings } from 'src/i18n-strings/board-I18nStrings';

const BoffinCurrentPeriod = () => {
  // const userId  = useSelector((globalState)=>globalState.auth.userId)
  const history = useHistory();
  const currentAppId = useSelector(
    (globalState) => globalState.auth.currentAppId
  );
  const isCurrentAppAuthorised = useSelector(
    (globalState) => globalState.auth.isCurrentAppAuthorized
  );
  const [isCheckingAccess, setIsCheckingAccess] = useState(true);
  const [isAuthorized, setIsAuthorized] = useState();
  const dispatch = useDispatch();
  const gridRef = useRef();
  const [dsInfo, setDsInfo] = useState([]);
  const [showLoadingIcon, setShowLoadingIcon] = useState(false);
  const [showUpdatingIcon, setShowUpdatingIcon] = useState(false);
  const [updateStatus, setUpdateStatus] = useState("");

  const [selectedItem, setSelectedItem] = useState([]);
  const [boardItems, setBoardItems] = useState([
    { id: "1", rowSpan: 5, columnSpan: 6,data:{}}
  ]);

  useEffect(() => {
    if (currentAppId !== appSettings.appId) {
      dispatch(setCurrentAppId(appSettings.appId));
    }
    setIsAuthorized(isCurrentAppAuthorised);
    if (isCurrentAppAuthorised) {
      LoadData();
    }
    return () => {
      // this now gets called when the component unmounts
    };
  }, [isCurrentAppAuthorised]);

  useEffect(() => {
    if(isAuthorized === true || isAuthorized === false){
      setIsCheckingAccess(!isCheckingAccess);
    } 
  }, [isAuthorized]);

  const breadItems = [
    { text: "Home", href: "/Home" },
    { text: "ATeam BI", href: "/ATeamBIHome" },
    { text: "Boffin", href: "/BoffinHome" },
    { text: "Current Period", href: "/" },
  ];

  const onFollowHandler = (ev) => {
    ev.preventDefault();
    if (ev.detail.href) {
      history.push(ev.detail.href.substring(1));
    }
  };

  const LoadData = async () => {
    setShowLoadingIcon(true);
    // console.log('Need to execute sql here..')
    let mSQL =
      "SELECT period_id, period_name, accounting_date, active, reversing_period_name, in_sec_period_name, in_sec_accounting_date, in_reversing_period_name FROM CURRENT_PERIOD;";
    let body =
      `{
          "processName" : "MySQL",
          "scriptName"  : "executeSQLonMySQL",
          "parameters"  : {"sql":"` +
      mSQL +
      `"}
        }`;

    // console.log(body)
    const ApiGateWayEndpoint = Amplify.Cache.config.ApiGateWayEndpoint;   
    const path = `${ApiGateWayEndpoint}/executeOnDemand`;
    axios
      .post(path, body, {
        headers: {
          Authorization: (await Auth.currentSession())
            .getIdToken()
            .getJwtToken(),
        },
      })
      .then((response) => {
        //  console.log(response.data.data)
        setDsInfo(response.data.data);
        setShowLoadingIcon(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  // const updateRecord = async () => {
  //   setShowUpdatingIcon(true);

  //   let mSQL = ";";
  //   let body =
  //     `{
  //         "processName" : "MySQL",
  //         "scriptName"  : "executeSQLonMySQL",
  //         "parameters"  : {"sql":"` +
  //     mSQL +
  //     `"}
  //       }`;

  //   // console.log(body)

  //   let url = ApiEndpoint + "/executeOnDemand";
  //   axios.post(url, body).then((response) => {
  //     //  console.log(response.data.records)
  //     if (response.data.records == "Updated") {
  //       setShowUpdatingIcon(false);
  //       setUpdateStatus(response.data.records);
  //       LoadData();
  //     }
  //   });
  // };

  const defaultColDef = {
    // set filtering on for all columns
    filter: true,
    filterParams: {
      buttons: ["reset"],
      debounceMs: 200,
    },
  };

  const columnDefs = [
    { field: "period_id", width: "150px", resizable: true, sortable: true },
    { field: "period_name", width: "150px", sortable: true, resizable: true },
    {
      field: "accounting_date",
      width: "150px",
      sortable: true,
      sortable: true,
    },
    { field: "active", width: "150px", sortable: true, resizable: true },
    {
      field: "reversing_period_name",
      width: "150px",
      sortable: true,
      resizable: true,
    },
    {
      field: "in_sec_period_name",
      width: "150px",
      sortable: true,
      resizable: true,
    },
    {
      field: "in_sec_accounting_date",
      width: "150px",
      sortable: true,
      resizable: true,
    },
    {
      field: "in_reversing_period_name",
      width: "150px",
      sortable: true,
      resizable: true,
    },
  ];

  const rowClassRules = useMemo(() => {
    return {
      // row style function

      "rag-green": (params) => {
        var rID = params.data.active;

        return rID == "Yes";

        // == '23435729';
      },
    };
  }, []);
  const onSelectionChanged = () => {
    const selectedRows = gridRef.current.api.getSelectedRows();
    if (selectedRows.length > 0) {
      // console.log(selectedRows[0])
      setSelectedItem(selectedRows[0]);
    }
  };
  const helpContent = (
    <HelpPanel
      header={
        <div>
          <h2>Modify Info </h2>
          {selectedItem.length == 0 ? (
            <small>Select a row to update values</small>
          ) : (
            <div></div>
          )}
        </div>
      }
    ></HelpPanel>
  );

  const content = (
    <div>
      {isAuthorized ? (
        <Box>
          {showLoadingIcon ? (
            <Box>
              <Spinner></Spinner>
              <Box>Loading Current Period...</Box>
              <Box>Please wait, takes upto a minute...</Box>
            </Box>
          ) : (
            <Box>
              <Container
                header={
                  <Header
                    description="The higlighed row is the active period. This table gets updated automatically on the last day of every month."
                    actions={
                      <SpaceBetween direction="horizontal" size="xs">
                        <Button
                          variant="inline-icon"
                          iconName="refresh"
                          onClick={() => LoadData()}
                        >
                          Refresh
                        </Button>
                      </SpaceBetween>
                    }
                  >
                    Current Period
                  </Header>
                }
              >
                <Board
                  renderItem={(item) => (
                    <BoardItem i18nStrings={boardItemI18nStrings}>
                      <div
                        className="ag-theme-alpine"
                        style={{ height: "92%", width: "100%" }}
                      >
                        <AgGridReact
                          ref={gridRef}
                          rowData={dsInfo}
                          defaultColDef={defaultColDef}
                          columnDefs={columnDefs}
                          rowSelection="single"
                          animateRows={true}
                          rowHeight={30}
                          rowClassRules={rowClassRules}
                          enableCellTextSelection={true}
                        ></AgGridReact>
                      </div>
                    </BoardItem>
                  )}
                  onItemsChange={(event) => setBoardItems(event.detail.items)}
                  items={boardItems}
                  i18nStrings={boardI18nStrings}
                />
              </Container>
              <Alert type="info">
                Change the required values after MAR-2024
              </Alert>
            </Box>
          )}
        </Box>
      ) : isCheckingAccess ? (
        <div>
          <WaitForPageAccess></WaitForPageAccess>
        </div>
      ) : (
        <div>
          <AccessDenied appName={appSettings.appName}></AccessDenied>
        </div>
      )}
    </div>
  );

  return (
    <AppLayout
      breadcrumbs={
        <BreadcrumbGroup
          items={breadItems}
          onFollow={(event) => onFollowHandler(event)}
        ></BreadcrumbGroup>
      }
      content={content}
      navigation={<SideNav activeHref="/BoffinCurrentPeriod" />}
      headerSelector="#TopBar"
      navigationHide={false}
      tools={isAuthorized ? helpContent : ''}
      toolsOpen={isAuthorized? true : false}
      maxContentWidth = {Number.MAX_VALUE}
      headerVariant="high-contrast"
    ></AppLayout>
  );
};

export default BoffinCurrentPeriod;
