import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './i18n';
import { Provider } from 'react-redux'
import '@amzn/awsui-global-styles/polaris.css';
import './index.scss';
// import globalStore from './store/store';
import { KatSpinner } from '@amzn/katal-react';
import { persistor, globalStore } from './store/store';
import { PersistGate } from 'redux-persist/integration/react';


// The Suspense component causes React to display the fallback KatSpinner until
// the i18n translation strings have been loaded.

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <React.Suspense fallback={<KatSpinner size="large" />}>
      <Provider store={globalStore}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
      </Provider>
    </React.Suspense>
  </React.StrictMode>
);
