/************************************************************************
                            DISCLAIMER

This is just a playground package. It does not comply with best practices
of using AWS-UI components. For production code, follow the integration
guidelines:

https://polaris.a2z.com/develop/integration/react/
************************************************************************/
import React, {useState, useEffect} from 'react';
import SideNav from './SideNav';
import appSettings from './app_settings';

import {
  AppLayout,  Button,  Tabs,  Icon,  
  Container,  Box,  Grid,  SpaceBetween, Link
} from '@amzn/awsui-components-react';

import './homepage.scss';
import { useSelector, useDispatch } from 'react-redux'


import AccessDenied from '../../AccessDenied'
import WaitForPageAccess from '../../WaitForPageAccess'
import { setCurrentAppId } from '../../store/userAuthSlice';
import { useHistory } from 'react-router-dom';

import favLogo from '../../images/star-regular-brown-for-home.svg'
import favLogoSolid from '../../images/star-solid-brown-for-home.svg'
import { checkIsFavorite, favorite } from '../../utilities/Favorites'
import Feedback from "src/generic-components/UserFeedback/Feedback";

// Component ServiceHomepage is a skeleton of a service's homepage using AWS-UI React components.
export default () => {
  const history = useHistory()
  const currentAppId = useSelector((globalState)=>globalState.auth.currentAppId)
  const isCurrentAppAuthorised = useSelector((globalState)=>globalState.auth.isCurrentAppAuthorized)

  const [isCheckingAccess, setIsCheckingAccess] = useState(true);
  const [isAuthorized, setIsAuthorized] = useState(false);

  const dispatch = useDispatch()
  const [navIsOpen, setNavIsOpen] = useState(false);
  const [isFavorite, setIsFavorite] = useState(false);
  const [favDisabled, setFavDisabled] = useState(false);
  const userId = useSelector((globalState) => globalState.auth.userId);

  const checkFavorite = (appId) => {
    checkIsFavorite(appId, userId, function onResult(response) {
      setIsFavorite(response)
    })
  }

  const markFavorite = async (appId) => {
    setFavDisabled(true)
    await favorite(appId, userId, function onAdded(result) {
      setIsFavorite(true)
    }, function onDeleted(result) {
      setIsFavorite(false)
    }, function onFailure(result) {
    })
    setFavDisabled(false)
  }
  

  useEffect(() => {
    
    if (currentAppId !== appSettings.appId){
      dispatch(setCurrentAppId(appSettings.appId))
    }
    setIsAuthorized(isCurrentAppAuthorised)
    checkFavorite(appSettings.appId)

    return () => {
      // this now gets called when the component unmounts
    };
  }, [isCurrentAppAuthorised]);

  const onGetStartedClick = () => {
    history.push('/MJEMonitoringNewUpload')
  }
  const onViewAllUploadsClick = () => {
    history.push('/MJEMonitoringHistory')
  }
  
  const Content =  (
  
    <div>
      <Box padding="s" margin={{ bottom: 'l' }}>
          <Grid className="custom-home__header" gridDefinition={[{ colspan: { xxs: 12 } }]}>
            <Box padding={{ vertical: 'xxxl' }}>
              <Grid
                gridDefinition={[
                  { offset: { l: '2', xxs: '1' }, colspan: { l: '8', xxs: '10' } },
                  { colspan: { xxs: 10, s: 6, l: 5, xl: 6 }, offset: { xxs: 1, l: 2 } },
                  { colspan: { xxs: 10, s: 4, l: 3, xl: 2 }, offset: { xxs: 1, s: 0 } }
                ]}
              >
                <Box fontWeight="heavy" padding={{ top: 'xs' }}>
                  <span className="custom-home__category">Override File Feature</span>
                </Box>
  
                <div className="custom-home__header-title">
                  <Box variant="h1" fontWeight="bold" padding="n" fontSize="display-l" color="inherit">
                    MJE Monitoring
                    <img className="favorite_icon" src={isFavorite? favLogoSolid : favLogo} onClick={() => markFavorite(appSettings.appId)} disabled={favDisabled} ></img>

                  </Box>
                  <Box fontWeight="light" padding={{ bottom: 's' }} fontSize="display-l" color="inherit">
                    User Interface to upload override file
                  </Box>
                  <Box variant="p" fontWeight="light">
                    <span className="custom-home__header-sub-title">
                          
                              MJE Monitoring process is to alert accountants via email 
                              when their MJE is rejected due to wrong SOCF code, 
                              this is part of project rising, which is critical CFO goal project.
                            
                            
                    </span>
                  </Box>
                </div>
  
                <Container header={<Box variant="h2" padding="n">
                      Get Started
                    </Box>}>
                  <SpaceBetween size="xl">
                    
                    <Box>
                      Click on the get started button to upload your file or navigate to Quicksight dashboard.

                    </Box>
                    <Box>
                    <SpaceBetween size="m" direction='horizontal'>
                      <Button  onClick={onGetStartedClick} variant="primary">
                        Get Started
                      </Button>
                      <Button 
                        href='https://us-east-1.quicksight.aws.amazon.com/sn/dashboards/6aa14a16-bd39-452c-ad1e-58bf87d24498/sheets/6aa14a16-bd39-452c-ad1e-58bf87d24498_3efa0b22-9411-4136-9c6a-138b9047c4ad'
                        target='_blank'
                      >
                        Quicksight - Dashboard
                      </Button>
                      </SpaceBetween>
                    </Box>
                  </SpaceBetween>
                </Container>
              </Grid>
            </Box>
            <br></br>
          </Grid>
  
          <Box padding={{ top: 'xxxl' }}>
            <Grid
              gridDefinition={[
                { colspan: { xxs: 10, s: 6, l: 6, xl: 6 }, offset: { xxs: 1, l: 2 } },
                { colspan: { xxs: 10, s: 4, l: 3, xl: 2 }, offset: { xxs: 1, s: 0 } }
              ]}
            >
              <div>
              <Tabs
                variant='container'
                tabs={[
                  {
                    label: "Inputs",
                    id: "1",
                    content: "This section should provide info about inputs"
                  },
                  {
                    label: "Processing",
                    id: "2",
                    content: "This section should provide info about processing"
                  },
                  {
                    label: "Outputs",
                    id: "3",
                    content: "This section should provide info about outputs"
                  },
                  {
                    label: "Accounting POC",
                    id: "4",
                    content: "This section should provide info about POC"
                  },
                  {
                    label: "Access / Issues Request",
                    id: "5",
                    content: (
                      <Box>
                        <ul className="custom-list-separator">
                          <li>
                            <span>
                              <Icon variant='subtle' name='user-profile'></Icon> &nbsp;
                              <Link href="https://t.corp.amazon.com/create/templates/23780a88-de99-4866-ae08-8e74fda6ff1a" external>
                                Access Request
                              </Link>
                            </span>
                          </li>
                          
                          <li>
                            <span>
                              <Icon variant='subtle' name='envelope'></Icon> &nbsp;
                              <Link href="https://t.corp.amazon.com/create/templates/9c41d7b9-b693-433e-950a-ec29fcfce9f5" external>
                                Oncall Support
                              </Link>
                            </span>
                          </li>
                        </ul>
                      </Box>
                    )
                  },
                  {
                    label: "How it works",
                    id: "6",
                    content: (
                      <Box>
                        <Box>
                          Process FLow Diagram
                        </Box>
                        <br></br>
                        <ul className="custom-list-separator">
                          <li>
                            <span>
                              <Icon variant='subtle' name='file-open'></Icon> &nbsp;
                              <Link href="" external  target="_blank">
                                PDD
                              </Link>
                            </span>
                          </li>
                          <li>
                            <span>
                              <Icon variant='subtle' name='file-open'></Icon> &nbsp;
                              <Link href="" external  target="_blank">
                                TDD
                              </Link>
                            </span>
                          </li>
                        
                        </ul>
                    </Box>
                    )
                  },
                ]}
              />
                
              </div>
            
              <div className="custom-home__sidebar">
                <SpaceBetween size="xxl">
                  
                  
                  
                </SpaceBetween>
              </div>
            </Grid>
          </Box>
          <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
          <SpaceBetween size="l" > 
          <Feedback appId={appSettings.appId} pageId={appSettings.appId} userId={userId} parentId={"null"} level={0} limit={1}></Feedback>
        </SpaceBetween>
      </Box> 
    </div>
  );
  
  
  
  return (
    <AppLayout
      headerSelector="#TopBar" 
      disableContentPaddings={true}
      navigation={<SideNav activeHref="#/MJEMonitoringHome" />} 
      content={Content}
      contentType="default"
      toolsHide={true} 
      disableBodyScroll={true}
      navigationOpen={navIsOpen}
      navigationHide={false}
      onNavigationChange={()=>setNavIsOpen(!navIsOpen)}
      headerVariant="high-contrast"
    />
  );
};

// The content in the main content area of the App layout
