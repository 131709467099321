
export const numberFilterParams = {
    filterOptions: [
        {
            displayKey: 'lessThanCustom',
            displayName: 'Less Than',
            predicate: ([filterValue], cellValue) => {
              const numFiltered = Number(filterValue.replaceAll(",","").replaceAll("%",""))
              const numCell = Number(cellValue.replaceAll(",","").replaceAll("%",""))
              return (numCell < numFiltered);
            }
        },
        {
            displayKey: 'greaterThanCustom',
            displayName: 'Greater Than',
            predicate: ([filterValue], cellValue) => {
              const numFiltered = Number(filterValue.replaceAll(",","").replaceAll("%",""))
              const numCell = Number(cellValue.replaceAll(",","").replaceAll("%",""))
              return (numCell > numFiltered);
            }
        },
        {
            displayKey: 'lessThanOrEqualCustom',
            displayName: 'Less Than or Equal',
            predicate: ([filterValue], cellValue) => {
              const numFiltered = Number(filterValue.replaceAll(",","").replaceAll("%",""))
              const numCell = Number(cellValue.replaceAll(",","").replaceAll("%",""))
              return (numCell <= numFiltered);
            }
        },
        {
            displayKey: 'greaterThanOrEqualCustom',
            displayName: 'Greater Than or Equal',
            predicate: ([filterValue], cellValue) => {
              const numFiltered = Number(filterValue.replaceAll(",","").replaceAll("%",""))
              const numCell = Number(cellValue.replaceAll(",","").replaceAll("%",""))
              return (numCell >= numFiltered);
            }
        },
        {
            displayKey: 'equalsCustom',
            displayName: 'Equals',
            predicate: ([filterValue], cellValue) => {
              const numFiltered = Number(filterValue.replaceAll(",","").replaceAll("%",""))
              const numCell = Number(cellValue.replaceAll(",","").replaceAll("%",""))
              return (numCell === numFiltered);
            }
        },
        {
            displayKey: 'notEqualCustom',
            displayName: 'Not Equals',
            predicate: ([filterValue], cellValue) => {
              const numFiltered = Number(filterValue.replaceAll(",","").replaceAll("%",""))
              const numCell = Number(cellValue.replaceAll(",","").replaceAll("%",""))
              return (numCell !== numFiltered);
            }
        },
        {
            displayKey: 'inRangeCustom',
            displayName: 'Between (Exclusive)',
            predicate: ([filterValue1,filterValue2], cellValue) => {
              const numFiltered1 = Number(filterValue1.replaceAll(",","").replaceAll("%",""))
              const numFiltered2 = Number(filterValue2.replaceAll(",","").replaceAll("%",""))
              const numCell = Number(cellValue.replaceAll(",","").replaceAll("%",""))
              return (numFiltered1 < numCell && numFiltered2 > numCell);
            },
            numberOfInputs: 2
        }
    ]
}

export const dateFilterParams = {
    comparator: (filterLocalDate, cellValue) => {
        let dateAsString = cellValue;

        if (dateAsString == null) {
            return 0;
        }

        dateAsString = dateAsString.split(' ')[0];
        const dateParts = dateAsString.split('-');
        const year = Number(dateParts[0]);
        const month = Number(dateParts[1]) - 1;
        const day = Number(dateParts[2]);
        const cellDate = new Date(year, month, day);

        // Now that both parameters are Date objects, we can compare
        if (cellDate < filterLocalDate) {
            return -1;
        } else if (cellDate > filterLocalDate) {
            return 1;
        }
        return 0;
    }
}

export const filterAPI = (items, filters, filterKeyMapper) => {
  
  let filteredItems = items
  let searchSuggestions = []

  switch (filterKeyMapper.filterOn ? filterKeyMapper.filterOn : "") { 
    case "apps":
      searchSuggestions = items.map((item) => ({
        value: item.app_name,
        description: item.app_description
      }))
      filteredItems = items.filter((item, itemIndex) => {

        let searchResult = true;
        let filterOptionsResult = true;
        const filterOptionsResultMap = {}
    
        Object.keys(filters).forEach((filter) => {
    
          if(filter === 'searchQuery') {
            let lowerCaseQuery = filters[filter].toLowerCase();
            if(lowerCaseQuery !== '') {
              lowerCaseQuery = quote(lowerCaseQuery)
            const regex = new RegExp(lowerCaseQuery);
            let capturedIndex = -1;
            searchResult = Object.values(item).find((value) => {

              if (regex.test(value ? value.toLowerCase() : value)) {
                searchSuggestions[itemIndex].description = value
                return true;
              }
              else {
                return false;
              }
            })
           

            } 
          } 
          else {
              const dynamoDBKey = filterKeyMapper[filter]
              const selected = filters[filter]
              if(selected.length > 0) {
                filterOptionsResultMap[filter] = false
              }                
                
              if(item[dynamoDBKey]) {
                const values = item[dynamoDBKey].split("|")
                values.forEach((val) => {
                  if(selected.find((element) => (val.toLowerCase().trim() === element.label.toLowerCase().trim()))) {
                    filterOptionsResultMap[filter] = true
                  }
                })


              }
    
          }
      
        })
        const filterBoolVals = Object.values(filterOptionsResultMap)

        filterOptionsResult = (filterBoolVals.length === 0 || filterBoolVals.every((val) => val)) ? true : false;
        return searchResult && filterOptionsResult;
      })
      break;
      default:
        console.log("Filter On Specified item Does not exist")

  }

  return [filteredItems,searchSuggestions];
}

function quote(regex) {
  return regex.replace(/([()[{*+.$^\\|?])/g, '\\$1');
}