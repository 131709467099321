import React, {useState, useEffect, useRef} from 'react';

import { useSelector, useDispatch } from 'react-redux'
import { Link, useHistory } from "react-router-dom";


import { AppLayout, BreadcrumbGroup, HelpPanel, Icon,
            Box, SpaceBetween, Container,
            Header,ColumnLayout, ExpandableSection, Button, Spinner, Alert
} from '@amzn/awsui-components-react';

import SideNav from './SideNav';
import appSettings from './app_settings';

import * as d3 from "d3";

import { AgGridReact } from 'ag-grid-react';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

import { setCurrentAppId } from '../../store/userAuthSlice';

import AccessDenied from '../../AccessDenied'
import WaitForPageAccess from '../../WaitForPageAccess'

import  { Amplify, Storage } from 'aws-amplify';

import {numberFilterParams, dateFilterParams} from '../../utilities/Factory'
import {Board, BoardItem} from "@amzn/awsui-board-components";
import { boardI18nStrings, boardItemI18nStrings } from 'src/i18n-strings/board-I18nStrings';


const BoffinCipTracker = () => {
    const history = useHistory();
    const currentAppId = useSelector((globalState)=>globalState.auth.currentAppId)
    const isCurrentAppAuthorised = useSelector((globalState)=>globalState.auth.isCurrentAppAuthorized)
    const [isCheckingAccess, setIsCheckingAccess] = useState(true);
    const [isAuthorized, setIsAuthorized] = useState();
    const dispatch = useDispatch()
    const gridRef = useRef();
    const [dsInfo, setDsInfo] = useState([]);
    const [showLoadingIcon, setShowLoadingIcon] = useState(true);
    const [boardItems, setBoardItems] = useState([
      { id: "1", rowSpan: 5, columnSpan: 6,data:{}}
    ]);
    

    useEffect(() => {
    
        if (currentAppId !== appSettings.appId){
          dispatch(setCurrentAppId(appSettings.appId))
        }
        setIsAuthorized(isCurrentAppAuthorised)
          loadDatasetsInfo() 
        
        return () => {
          // this now gets called when the component unmounts
        };
      }, [isCurrentAppAuthorised]);
    
      useEffect(() => {
        if(isAuthorized === true || isAuthorized === false){
          setIsCheckingAccess(!isCheckingAccess);
        } 
      }, [isAuthorized]);

      const breadItems = [
        { text: 'Home', href: '/Home' },
        { text: 'ATeam BI', href: '/ATeamBIHome' },
        { text: 'Boffin', href: '/BoffinHome' },
        { text: 'CIP Tracker', href: '/' },
        
    
      ];
      
      const onFollowHandler = (ev) => {
        ev.preventDefault();
        if (ev.detail.href) {
            history.push(ev.detail.href.substring(1));
        }
    }
      const helpContent = (
        <HelpPanel
            footer={
                <div>
                    <h3>
                        Learn more <Icon name="external" />
                    </h3>
                    <Box>
                    <span>
                      <Icon variant='subtle' name='external' ></Icon> &nbsp;
                      <a href='https://w.amazon.com/bin/view/ProjectBoffin/FAQ/#HFAQ-Processing2FPostingtoOFA'>
                          FAQ
                        </a>
                    </span>
                      
                      
                    </Box>
                    
                </div>
            }
            header={<div>
                <div>
    
                </div>
                <h2>Additional Info </h2>
               
            </div>}
        >
            <Box variant='small'>
                <ul>
                    <li>GLAP = General Ledger with AP details</li>
                    <li>GLTD = General Ledger with Transaction details</li>
                </ul>
            </Box>
            <Box>
             
            </Box>
        </HelpPanel>
      )
      
  
    
      
      
      const loadDatasetsInfo = async () =>{
        setShowLoadingIcon(true)
        let folder = 'Boffin/datasets/'
        let fn = folder + 'cip_tracker.csv'
        const fileURL = await Storage.get(fn, { level: 'public', expires: 60});
        let csvdata = await d3.csv(fileURL)
            .then(result => {
                // console.log(result)
                setDsInfo(result)
                // setState here..
            })

        setShowLoadingIcon(false);
      }
      const DownloadInfo = async () =>{

        let folder = 'Boffin/datasets/'
        let fn = folder + 'cip_tracker.csv'
        const fileURL = await Storage.get(fn, { level: 'public', expires: 60});
        window.open(fileURL);


      }
      
      const defaultColDef = {
        // set filtering on for all columns
        filter: true,
        filterParams: {
            buttons: ['reset'],
            debounceMs: 200
        }
    };
    
    const [columnDefs] = useState([
        { field: 'teamname' ,width: '160px', resizable: true, sortable: true },
        { field: 'company' , width: '160px',  sortable: true, resizable: true},
        { field: 'location' ,  width: '150px',sortable: true, sortable: true,resizable: true},
        { field: 'project' , width: '150px', sortable: true, resizable: true},
        { field: 'cip' , width: '150px', sortable: true, resizable: true},
        { field: 'location_go_live_date' , width: '180px', sortable: true, resizable: true,
        filter: 'agDateColumnFilter', filterParams: dateFilterParams},
        { field: 'project_go_live_date' , width: '180px', sortable: true, resizable: true,
        filter: 'agDateColumnFilter', filterParams: dateFilterParams},

    ])
    
      const content = (
        <div>
          {isAuthorized ? (
            <Box>
              <Container
                header={
                  <Header
                    actions={
                      <SpaceBetween direction="horizontal" size="xs">
                        {/* <Button variant='normal' onClick={()=>openButtonClick()}>View Details</Button> */}
                        {showLoadingIcon ? (
                          <Box>
                            <Spinner size="normal"></Spinner>
                          </Box>
                        ) : (
                          <Box></Box>
                        )}
                        <Button
                          variant="normal"
                          iconName="download"
                          onClick={() => DownloadInfo()}
                        >
                          Download
                        </Button>
                      </SpaceBetween>
                    }
                    description="The following table shows the CIP Tracker."
                  >
                    CIP Tracker
                  </Header>
                }
              >
                <Board
                  renderItem={(item) => (
                    <BoardItem
                      header={<Header></Header>}
                      i18nStrings={boardItemI18nStrings}
                    >
                      <div
                        className="ag-theme-alpine"
                        style={{ height: "92%", width: "100%" }}
                      >
                        <AgGridReact
                          ref={gridRef}
                          rowData={dsInfo}
                          defaultColDef={defaultColDef}
                          columnDefs={columnDefs}
                          rowSelection="single"
                          animateRows={true}
                          rowHeight={30}
                          enableCellTextSelection={true}
                        ></AgGridReact>
                      </div>
                    </BoardItem>
                  )}
                  onItemsChange={(event) => setBoardItems(event.detail.items)}
                  items={boardItems}
                  i18nStrings={boardI18nStrings}
                />

              </Container>

              <br />
            </Box>
          ) : isCheckingAccess ? (
            <div>
              <WaitForPageAccess></WaitForPageAccess>
            </div>
          ) : (
            <div>
              <AccessDenied appName={appSettings.appName}></AccessDenied>
            </div>
          )}
        </div>
      );

  return (
    <AppLayout
          breadcrumbs={<BreadcrumbGroup items={breadItems} onFollow={(event)=>onFollowHandler(event)}></BreadcrumbGroup>}
          content={content}
          navigation={<SideNav activeHref="/BoffinCipTracker" />}
          headerSelector="#TopBar"
          navigationHide={false}
          
          toolsHide={true}
          maxContentWidth = {Number.MAX_VALUE}
          headerVariant="high-contrast"
  
        ></AppLayout>
  )
}

export default BoffinCipTracker
