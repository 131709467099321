import React, { useState, useEffect } from "react";
import { glossary, TEMPLATE_APP, TEMPLATE_APP_ACCOUNTING, TEMPLATE_APP_CHART_OF_ACCOUNTS, TEMPLATE_APP_OUTPUT, TEMPLATE_APP_PERMISSION, TEMPLATE_APP_SOURCE_DATA_TABLES, TEMPLATE_APP_TEAM, TEMPLATE_APP_USE_CASE } from "../Templates/Constants";
import SideNav from "./SideNav";
import {
    AppLayout,
    Button,
    ColumnLayout,
    Input,
    Icon,
    Textarea,
    Container,
    Header,
    Box,
    Grid,
    SpaceBetween,
    Link,
    ExpandableSection,
    ContentLayout,
    Table,
    Modal,
    Spinner,
    Select,
    Popover,
    StatusIndicator,
    HelpPanel,
    Flashbar
} from "@amzn/awsui-components-react";

import "../../index.scss";
import "./styles.scss"
import { useSelector, useDispatch } from "react-redux";
import { API, graphqlOperation } from "aws-amplify";

import { useHistory, useParams } from "react-router-dom";

import CustomMultiInputAdminForSelect from "../Templates/components/CustomMultiInputAdminForSelect";
import { getEditableFields, getLabelToFieldTypeMappers, sortQuarters } from "../Templates/Utils/FormMetadata";
import { setMetadataValues, getAllAppsForConsoilidatedSheet, constructExcelSheetWithHeadersAndData } from "../Templates/Utils/AppActivityUtils";
import {checkIfPortalAdmin} from "src/checkPageAccess"
import { FRISCO_DROPDOWN_METADATA_KEYS, PROCESS_KEYS } from 'src/Ateam-BI/Frisco/Constants';
import AccessDenied from "../../AccessDenied";
import { FLEXICO_TEMPLATE_KEYS } from "../FlexiCo/Constants";


// Component ServiceHomepage is a skeleton of a service's homepage using AWS-UI React components.
export default (props) => {
    const dispatch = useDispatch();
    const currentStage = useSelector((globalState) => globalState.auth.currentStage)
    const history = useHistory();
    const [NavOpen, setNavOpen] = useState(true);
    const [showLoadingIcon, setShowLoadingIcon] = useState(true);
    const userId = useSelector((globalState) => globalState.auth.userId);

    const [toolsOpen, setToolsOpen] = useState(false);
    const [fieldMetadata, setFieldMetadata] = useState({});
    const [loaders, setLoaders] = useState({})
    const [errorMessages, setErrorMessages] = useState({})
    const [reloadNotifiers, setReloadNotifiers] = useState({})
    const [allApps, setAllApps] = useState()
    const [selectedQuarter, setSelectedQuarter] = useState()
    const [selectedYear, setSelectedYear] = useState()
    const [fieldTypes, setFieldTypes] = useState([
        {key : TEMPLATE_APP.ORGANIZATION, label: 'Organization'},
        {key : TEMPLATE_APP.CADENCE, label: 'Run frequency'},
        {key : TEMPLATE_APP.USE_CASE, label: 'Use case type'},
        {key : TEMPLATE_APP.DEVELOPER_TEAM, label: 'Developer teams'},
        {key : TEMPLATE_APP.ACCOUNTING_TEAM, label: 'Accounting teams'},
        {key : TEMPLATE_APP.INPUT_SOURCE_TYPE, label: 'Input source types'},
        {key : TEMPLATE_APP.AUTOMATION_TOOLS, label: 'Automation tools'},
        {key : TEMPLATE_APP.LEDGER_INFO, label: 'Ledger impacts'},
        {key : TEMPLATE_APP.REGION, label: 'Use case regions'},
        {key : TEMPLATE_APP.LAUNCH_QQ_YY, label: 'Launch dates'},
        {key : TEMPLATE_APP.OUTPUT, label: 'Process output type'},
        {key : TEMPLATE_APP.FINANCIAL_RELEVANCE, label: 'Financial relevance'},
        {key : TEMPLATE_APP.FINANCIAL_YEAR_IMPACT, label: 'Financial year impact'},
        {key: PROCESS_KEYS.team, label: 'Frisco teams'},
        {key: PROCESS_KEYS.business, label: 'Frisco businesses'},
        {key: PROCESS_KEYS.action, label: 'Frisco actions'},
        {key: PROCESS_KEYS.environment, label: 'Frisco Environments'},
        {key: PROCESS_KEYS.file_types, label: 'Frisco File types'},
        {key: `flexico_${FLEXICO_TEMPLATE_KEYS.file_types}`, label: 'FlexiCo File types'},
        {key: `flexico_${FLEXICO_TEMPLATE_KEYS.trigger_type}`, label: 'FlexiCo trigger type'}
    ]);
    const [hasAdminAccess, setHasAdminAccess] = useState(false)
    

    useEffect(() => {

        loadEditableFields()

        return () => {
            // this now gets called when the component unmounts
        };
    }, []);

    const getDate = (value) => {
        var d = new Date(parseFloat(value) * 1000)
        return d.toLocaleString()
      }

    const downloadAllapps = async () => {
        if (allApps?.length > 0) {
        const headers = [TEMPLATE_APP.APP_ID, 
            TEMPLATE_APP.APP_NAME, 
            TEMPLATE_APP.APP_DESCRIPTION,
            TEMPLATE_APP.TEMPLATE_ID, 
            TEMPLATE_APP.URL, 
            TEMPLATE_APP.PROCESS_OWNER,
            TEMPLATE_APP.GRC_CONTROL_OWNER,
            TEMPLATE_APP.FLASH_INFO_DESCRIPTION,
            TEMPLATE_APP.DEV_ALERT_MESSAGES,
            TEMPLATE_APP.TDD,
            TEMPLATE_APP.STATUS,
            TEMPLATE_APP.REGION,
            TEMPLATE_APP.CADENCE,
            TEMPLATE_APP.PROCESS_MJE_COUNT,
            TEMPLATE_APP.ANNUAL_MER_SAVINGS,
            TEMPLATE_APP.PDD,
            TEMPLATE_APP.LEDGER_INFO,
            TEMPLATE_APP.LAUNCH_MCM,
            TEMPLATE_APP.LAUNCH_QQ_YY,
            TEMPLATE_APP.INPUT_SOURCE_TYPE,
            TEMPLATE_APP.AUTOMATION_TOOLS,
            TEMPLATE_APP.ARD,
            TEMPLATE_APP.DATASOURCE,
            TEMPLATE_APP.ORGANIZATION,
            TEMPLATE_APP.FINANCIAL_RELEVANCE,
            TEMPLATE_APP.FINANCIAL_YEAR_IMPACT,
            TEMPLATE_APP.GRC_CONTROL_ID,
            TEMPLATE_APP.VP,
            TEMPLATE_APP.CREATE_TIME_STAMP,
            TEMPLATE_APP.CREATED_BY,
            TEMPLATE_APP.LAST_UPDATED_TIME_STAMP,
            TEMPLATE_APP.LAST_UPDATED_BY,
            TEMPLATE_APP.SIM_TICKET_URL,
            TEMPLATE_APP.SIM_TICKET_LABEL,
            TEMPLATE_APP.CHART_OF_ACCOUNT,
            TEMPLATE_APP.SOURCE_DATA_TABLES,
            TEMPLATE_APP.USE_CASE,
            TEMPLATE_APP.PERMISSION,
            TEMPLATE_APP.OUTPUT,
            TEMPLATE_APP.ACCOUNTING_TEAM,
            TEMPLATE_APP.DEVELOPER_TEAM
        ]

        constructExcelSheetWithHeadersAndData(headers, allApps)           
      }
    
    }

    const downloadEligibleGRCAppsToBePushed = async () => {
        if (allApps?.length > 0) {
        const headers = [TEMPLATE_APP.APP_ID, 
            TEMPLATE_APP.APP_NAME, 
            TEMPLATE_APP.APP_DESCRIPTION,
            TEMPLATE_APP.TEMPLATE_ID, 
            TEMPLATE_APP.URL, 
            TEMPLATE_APP.PROCESS_OWNER,
            TEMPLATE_APP.GRC_CONTROL_OWNER,
            TEMPLATE_APP.FLASH_INFO_DESCRIPTION,
            TEMPLATE_APP.DEV_ALERT_MESSAGES,
            TEMPLATE_APP.TDD,
            TEMPLATE_APP.STATUS,
            TEMPLATE_APP.REGION,
            TEMPLATE_APP.CADENCE,
            TEMPLATE_APP.PROCESS_MJE_COUNT,
            TEMPLATE_APP.ANNUAL_MER_SAVINGS,
            TEMPLATE_APP.PDD,
            TEMPLATE_APP.LEDGER_INFO,
            TEMPLATE_APP.LAUNCH_MCM,
            TEMPLATE_APP.LAUNCH_QQ_YY,
            TEMPLATE_APP.INPUT_SOURCE_TYPE,
            TEMPLATE_APP.AUTOMATION_TOOLS,
            TEMPLATE_APP.ARD,
            TEMPLATE_APP.DATASOURCE,
            TEMPLATE_APP.ORGANIZATION,
            TEMPLATE_APP.FINANCIAL_RELEVANCE,
            TEMPLATE_APP.FINANCIAL_YEAR_IMPACT,
            TEMPLATE_APP.GRC_CONTROL_ID,
            TEMPLATE_APP.VP,
            TEMPLATE_APP.CREATE_TIME_STAMP,
            TEMPLATE_APP.CREATED_BY,
            TEMPLATE_APP.LAST_UPDATED_TIME_STAMP,
            TEMPLATE_APP.LAST_UPDATED_BY,
            TEMPLATE_APP.SIM_TICKET_URL,
            TEMPLATE_APP.SIM_TICKET_LABEL,
            TEMPLATE_APP.CHART_OF_ACCOUNT,
            TEMPLATE_APP.SOURCE_DATA_TABLES,
            TEMPLATE_APP.USE_CASE,
            TEMPLATE_APP.PERMISSION,
            TEMPLATE_APP.OUTPUT,
            TEMPLATE_APP.ACCOUNTING_TEAM,
            TEMPLATE_APP.DEVELOPER_TEAM
        ]
        const eligibleApps = []
        let cutoffData;
        if (selectedQuarter?.value === "Q1") {
            cutoffData = new Date(selectedYear?.value, 0, 1)
        }
        else if (selectedQuarter?.value === "Q2") {
            cutoffData = new Date(selectedYear?.value, 3, 1)
        }
        else if (selectedQuarter?.value === "Q3") {
            cutoffData = new Date(selectedYear?.value, 6, 1)
        }
        else if (selectedQuarter?.value === "Q4") {
            cutoffData = new Date(selectedYear?.value, 9, 1)
        }

        allApps.forEach((app, index) => {

            if(app[TEMPLATE_APP.STATUS] === "Active" && !app[TEMPLATE_APP.GRC_CONTROL_ID]) {

                if(app[TEMPLATE_APP.LAUNCH_QQ_YY] && app[TEMPLATE_APP.LAUNCH_QQ_YY] !== "") {
                    let [launchQuarter, launchYear] = app[TEMPLATE_APP.LAUNCH_QQ_YY].split(" ")
                    launchYear = Number(launchYear)
                    if (launchYear < selectedYear?.value) {
                        eligibleApps.push(app)
                    } else if(launchYear === selectedYear?.value && launchQuarter < selectedQuarter?.value) {
                        eligibleApps.push(app)
                    }

                } else if(app[TEMPLATE_APP.CREATE_TIME_STAMP] && app[TEMPLATE_APP.CREATE_TIME_STAMP] !== "" && app[TEMPLATE_APP.CREATE_TIME_STAMP] !== "Invalid Date") {
                    const createdDate = new Date(app[TEMPLATE_APP.CREATE_TIME_STAMP])
                    if(createdDate < cutoffData) {
                        eligibleApps.push(app)
                    }
                }
            }

        })
       
        constructExcelSheetWithHeadersAndData(headers, eligibleApps, "GRCEligibleApps")           
      }
    
    }


    const loadEditableFields = async () => {
        let isAdmin = await checkIfPortalAdmin(userId, currentStage)
        setHasAdminAccess(isAdmin)
        if(isAdmin){
            let res = await getEditableFields()
            if(res.hasOwnProperty(TEMPLATE_APP.LAUNCH_QQ_YY)){
                sortQuarters(res[TEMPLATE_APP.LAUNCH_QQ_YY].values)
            }
            setFieldMetadata({...res})
            let apps = await getAllAppsForConsoilidatedSheet()
            apps?.forEach((app) => {
                app[TEMPLATE_APP.CREATE_TIME_STAMP] = getDate(app[TEMPLATE_APP.CREATE_TIME_STAMP])
                app[TEMPLATE_APP.LAST_UPDATED_TIME_STAMP] = getDate(app[TEMPLATE_APP.LAST_UPDATED_TIME_STAMP])
            })
            setAllApps(apps)
        }
        setShowLoadingIcon(false)
    }

    const updateValue = (fieldName, value) => {
        fieldMetadata[fieldName]['values'] = value
    }

    const getSelectFieldValues = (fieldName, subKey = null) => {
        if (fieldMetadata && fieldMetadata[fieldName] && fieldMetadata[fieldName]['values']) {
            return fieldMetadata[fieldName]['values']
        } else if (subKey && fieldMetadata && fieldMetadata[fieldName][subKey] && fieldMetadata[fieldName][subKey]['values']) {
            return fieldMetadata[fieldName][subKey]['values']
        } else {
            return []
        }
    }

    const setLoader = (key, value) => {
        let obj = {}
        obj[key] = value
        setLoaders(prevState => {
            return {...prevState, ...obj};
          });
    }

    const setReloaders = (key, value) => {
        let obj = {}
        obj[key] = value
        setReloadNotifiers(prevState => {
            return {...prevState, ...obj};
          });

    }

    const setError = (key, value) => {
        let obj = {}
        obj[key] = value
        setErrorMessages(prevState => {
            return {...prevState, ...obj};
          });
    }

    const loadMetadataForType =  async(key) =>{
        let res = await getEditableFields();
        fieldMetadata[key] = res[key]
        // setFieldMetadata(prevState => {
        //     return {...prevState, ...fieldMetadata};
        //   });
    }

    const updateMetadata = (key) => {
        setLoader(key, true)
        setMetadataValues({ type: getLabelToFieldTypeMappers()[key], values: fieldMetadata[key]['values'] },
            function onSuccess(response) { 
                setError(key, null)
                refresh(key)
                },
            function onFailure(errorResponse) { 
                setError(key,"Cannot update metadata values")
                errorResponse.errors.forEach((error) => {
                    if(error.message.includes("violates foreign key constraint")){
                        setError(key, `Cannot remove one of the ${key} because there is an app that is linked to this ${key}.\nHowever, you can update the values of an existing entry - This will reflect on the existing linked apps`)
                    }
                })
                refresh(key)
             })
    }

    const refresh = async (key) => {
        setLoader(key, true)
        setReloaders(key, false)
        await loadMetadataForType(key) 
        setLoader(key, false)
        setReloaders(key, true)
    }

    const getView = (label, type) => {
        return (
            <ExpandableSection
                variant="container"
                defaultExpanded={false}
                headerText={label}
                headerActions={<Button iconName="refresh" variant="icon" onClick={() =>{ 
                        refresh(type)
                }}></Button>}
            >
                
                {
                    hasAdminAccess ? <>
                    {
                     fieldMetadata[type] ?
                        <>
                            {loaders && loaders[type] ? <Box>
                                <span>Please wait...</span> <Spinner />
                            </Box> : ''}

                            {errorMessages[type] ? <Box>
                                <span className="errorMessage">{errorMessages[type]}</span>
                            </Box> : ''}

                            <CustomMultiInputAdminForSelect reloadNotifiers={reloadNotifiers} setReloadNotifier={setReloaders} 
                            field={type} updateValue={updateValue} getValues={getSelectFieldValues}></CustomMultiInputAdminForSelect>
                            <Box float="right">
                                <Button variant="primary" disabled={loaders[type] ? true : false} onClick={() => updateMetadata(type)}>Save</Button>
                            </Box>

                        </> : ''
                }
                    </> : <AccessDenied appName="Admin panel"></AccessDenied>
                }

            </ExpandableSection>
        )
    }

    const Content = (
        <>
            {showLoadingIcon ? (
                <Modal visible={showLoadingIcon}>
                    <Box>
                        <Spinner></Spinner>
                        Loading...
                    </Box>
                </Modal>
            ) : (
                <div className="mainPage">
                    <ContentLayout
                        headerVariant="high-contrast"
                        header={
                            <SpaceBetween size="m">
                                <Header
                                    variant="h1"
                                    description="Admin panel for editing the app metadata."

                                >
                                    Admin panel
                                </Header>
                            </SpaceBetween>
                        }
                    >


                        <Container>

                            <SpaceBetween size="l">
                                <ColumnLayout columns={3}>
                                    <>

                                    {fieldTypes? (Array.from(fieldTypes).map((value, index) => {
                                        return getView(value.label, value.key);
                                    })) : ''}
                                    </>

                                </ColumnLayout>

                            </SpaceBetween>
                        </Container>
                                <br></br>
                        <Container>

                            <SpaceBetween size="l">
                                <Button variant="primary" onClick={() => downloadAllapps()}>Export all apps to a sheet</Button>
                            </SpaceBetween>
                        </Container>
                        <br></br>
                        <Container>
                            
                            <h3>Select Quarter and Year</h3>
                            <SpaceBetween size="l" direction="horizontal">
                            <Select
                                selectedOption={selectedQuarter}
                                onChange={({ detail }) =>
                                    setSelectedQuarter(detail.selectedOption)
                                }
                                options={[
                                    { label: "Q1", value: "Q1" },
                                    { label: "Q2", value: "Q2" },
                                    { label: "Q3", value: "Q3" },
                                    { label: "Q4", value: "Q4" }
                                ]}
                                />
                            <Select
                                selectedOption={selectedYear}
                                onChange={({ detail }) =>
                                    setSelectedYear(detail.selectedOption)
                                }
                                options={[
                                    { label: "2020", value: 2020 },
                                    { label: "2021", value: 2021 },
                                    { label: "2022", value: 2022 },
                                    { label: "2023", value: 2023 },
                                    { label: "2024", value: 2024 },
                                    { label: "2025", value: 2025 },
                                    { label: "2026", value: 2026 },

                                ]}
                                />
                                <Button variant="primary" disabled = {!(selectedQuarter && selectedYear)} onClick={() => downloadEligibleGRCAppsToBePushed()}>Export Eligible apps to be pushed to GRC by Selected Quarter and Year</Button>
                            </SpaceBetween>
                        </Container>

                    </ContentLayout>
                </div>
            )
            }

        </>
    )


    return (
        <AppLayout
            disableContentPaddings={true}
            content={Content}
            navigation={<SideNav activeHref={`/Admin`} />}
            contentType="default"
            navigationOpen={NavOpen}
            onNavigationChange={() => {
                setNavOpen(!NavOpen);
            }}
            toolsHide={true}
            toolsOpen={toolsOpen}
            onToolsChange={() => { setToolsOpen(!toolsOpen) }}
            headerVariant="high-contrast"
        />
    );
};
