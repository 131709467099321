
export const historyData = () => {
    return [
        {
          "batch_id": 464,
          "entity": "630",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "19-June-2023 17:00",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "19-JUN-2023 04:00:17",
          "updated_time": "19-JUN-2023 04:00:17",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": null,
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 451,
          "entity": "1Y",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-June-2023 13:00",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "14-JUN-2023 12:00:05",
          "updated_time": "14-JUN-2023 12:00:05",
          "retrigger_request_time": "2023-06-14T00:00:00",
          "retrigger_status": "REQUESTED",
          "retrigger_by": "ravsbhar",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 462,
          "entity": "1Y",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "19-June-2023 10:15",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "MANUAL",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "19-JUN-2023 09:15:05",
          "updated_time": "19-JUN-2023 09:15:05",
          "retrigger_request_time": "2023-06-22T00:00:00",
          "retrigger_status": "REQUESTED",
          "retrigger_by": "ravsbhar",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 450,
          "entity": "CD_CNY",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-June-2023 12:00",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "14-JUN-2023 11:00:08",
          "updated_time": "14-JUN-2023 11:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": null,
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 467,
          "entity": "B115",
          "region": "LATAM_North",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "15",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "21-June-2023 13:00",
          "effective_trigger_day": "21-June-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "-0.08000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "-0.08000000",
          "transformation_time": "21-06-2023 20:02:05 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Failed",
          "created_time": "21-JUN-2023 12:00:08",
          "updated_time": "21-JUN-2023 12:00:08",
          "retrigger_request_time": "2023-07-14T00:00:00",
          "retrigger_status": "REQUESTED",
          "retrigger_by": "kargodoy",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 467,
          "entity": "MS_CONS",
          "region": "LATAM_North",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "15",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "21-June-2023 13:00",
          "effective_trigger_day": "21-June-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "21-JUN-2023 12:00:08",
          "updated_time": "21-JUN-2023 12:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": null,
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 467,
          "entity": "B423",
          "region": "LATAM_North",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "15",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "21-June-2023 13:00",
          "effective_trigger_day": "21-June-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.16000000",
          "us_gaap_etb_total": "0.16000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "21-06-2023 20:02:05 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Failed",
          "created_time": "21-JUN-2023 12:00:08",
          "updated_time": "21-JUN-2023 12:00:08",
          "retrigger_request_time": "2023-07-17T00:00:00",
          "retrigger_status": "REQUESTED",
          "retrigger_by": "laraenri",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 454,
          "entity": "1Y",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "15-June-2023 10:30",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "15-JUN-2023 09:30:06",
          "updated_time": "15-JUN-2023 09:30:06",
          "retrigger_request_time": "2023-06-16T00:00:00",
          "retrigger_status": "REQUESTED",
          "retrigger_by": "ravsbhar",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 454,
          "entity": "1I",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "15-June-2023 10:30",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "15-06-2023 17:32:05 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "15-JUN-2023 09:30:06",
          "updated_time": "15-JUN-2023 09:30:06",
          "retrigger_request_time": "2023-06-16T00:00:00",
          "retrigger_status": "REQUESTED",
          "retrigger_by": "ravsbhar",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 454,
          "entity": "2I",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "15-June-2023 10:30",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "15-06-2023 17:32:05 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "15-JUN-2023 09:30:06",
          "updated_time": "15-JUN-2023 09:30:06",
          "retrigger_request_time": "2023-06-16T00:00:00",
          "retrigger_status": "REQUESTED",
          "retrigger_by": "uyyogara",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 454,
          "entity": "4E",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "15-June-2023 10:30",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "15-06-2023 17:32:05 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "15-JUN-2023 09:30:06",
          "updated_time": "15-JUN-2023 09:30:06",
          "retrigger_request_time": "2023-06-16T00:00:00",
          "retrigger_status": "REQUESTED",
          "retrigger_by": "uyyogara",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 495,
          "entity": "1Y",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "19-July-2023 04:00",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "MANUAL",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "19-JUL-2023 03:00:09",
          "updated_time": "19-JUL-2023 03:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "uyyogara",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 503,
          "entity": "AQ (SG)",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "24-July-2023 16:30",
          "effective_trigger_day": "17-July-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "24-JUL-2023 03:30:08",
          "updated_time": "24-JUL-2023 03:30:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 467,
          "entity": "MF",
          "region": "LATAM_North",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "15",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "21-June-2023 13:00",
          "effective_trigger_day": "21-June-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "21-06-2023 20:02:05 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "21-JUN-2023 12:00:08",
          "updated_time": "21-JUN-2023 12:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": null,
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 467,
          "entity": "B622",
          "region": "LATAM_North",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "15",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "21-June-2023 13:00",
          "effective_trigger_day": "21-June-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "21-06-2023 20:02:05 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "21-JUN-2023 12:00:08",
          "updated_time": "21-JUN-2023 12:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": null,
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 467,
          "entity": "M2",
          "region": "LATAM_North",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "15",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "21-June-2023 13:00",
          "effective_trigger_day": "21-June-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "21-06-2023 20:02:05 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "21-JUN-2023 12:00:08",
          "updated_time": "21-JUN-2023 12:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": null,
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 450,
          "entity": "9T_CONS",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-June-2023 12:00",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "14-JUN-2023 11:00:08",
          "updated_time": "14-JUN-2023 11:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": null,
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 467,
          "entity": "GC",
          "region": "LATAM_North",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "15",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "21-June-2023 13:00",
          "effective_trigger_day": "21-June-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "21-06-2023 20:02:05 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "21-JUN-2023 12:00:08",
          "updated_time": "21-JUN-2023 12:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": null,
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 467,
          "entity": "B407",
          "region": "LATAM_North",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "15",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "21-June-2023 13:00",
          "effective_trigger_day": "21-June-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "21-06-2023 20:02:05 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "21-JUN-2023 12:00:08",
          "updated_time": "21-JUN-2023 12:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": null,
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 467,
          "entity": "B106",
          "region": "LATAM_North",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "15",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "21-June-2023 13:00",
          "effective_trigger_day": "21-June-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "21-06-2023 20:02:05 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "21-JUN-2023 12:00:08",
          "updated_time": "21-JUN-2023 12:00:08",
          "retrigger_request_time": "2023-07-11T00:00:00",
          "retrigger_status": "REQUESTED",
          "retrigger_by": "ngutina",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 467,
          "entity": "DW",
          "region": "LATAM_North",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "15",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "21-June-2023 13:00",
          "effective_trigger_day": "21-June-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "21-06-2023 20:02:05 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "21-JUN-2023 12:00:08",
          "updated_time": "21-JUN-2023 12:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": null,
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 467,
          "entity": "B437",
          "region": "LATAM_North",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "15",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "21-June-2023 13:00",
          "effective_trigger_day": "21-June-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "21-06-2023 20:02:05 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "21-JUN-2023 12:00:08",
          "updated_time": "21-JUN-2023 12:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": null,
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 469,
          "entity": "5C",
          "region": "LATAM_North",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "15",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "22-June-2023 06:30",
          "effective_trigger_day": "21-June-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "MANUAL",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "107.71000000",
          "lg_etb_total": "-107.71000000",
          "transformation_time": "22-06-2023 13:32:20 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Failed",
          "created_time": "22-JUN-2023 05:30:06",
          "updated_time": "22-JUN-2023 05:30:06",
          "retrigger_request_time": "2023-06-26T00:00:00",
          "retrigger_status": "REQUESTED",
          "retrigger_by": "uyyogara",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 457,
          "entity": "1I",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "16-June-2023 02:30",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "16-JUN-2023 01:30:05",
          "updated_time": "16-JUN-2023 01:30:05",
          "retrigger_request_time": "2023-06-22T00:00:00",
          "retrigger_status": "REQUESTED",
          "retrigger_by": "ravsbhar",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 472,
          "entity": "AU_55_CONS_LOCAL",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "22-June-2023 16:45",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "22-JUN-2023 03:45:05",
          "updated_time": "22-JUN-2023 03:45:05",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": null,
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 468,
          "entity": "5C",
          "region": "LATAM_North",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "15",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "21-June-2023 20:45",
          "effective_trigger_day": "21-June-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "MANUAL",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "107.71000000",
          "lg_etb_total": "-107.71000000",
          "transformation_time": "22-06-2023 03:47:10 AM",
          "transformation_status": "Completed",
          "pre_check_status": "Failed",
          "created_time": "21-JUN-2023 07:45:05",
          "updated_time": "21-JUN-2023 07:45:05",
          "retrigger_request_time": "2023-06-22T00:00:00",
          "retrigger_status": "REQUESTED",
          "retrigger_by": "uyyogara",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 450,
          "entity": "1Y",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-June-2023 12:00",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "14-JUN-2023 11:00:08",
          "updated_time": "14-JUN-2023 11:00:08",
          "retrigger_request_time": "2023-06-22T00:00:00",
          "retrigger_status": "REQUESTED",
          "retrigger_by": "3979",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 478,
          "entity": "63",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "26-June-2023 23:00",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "MANUAL",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "26-JUN-2023 10:00:10",
          "updated_time": "26-JUN-2023 10:00:10",
          "retrigger_request_time": "2023-06-27T00:00:00",
          "retrigger_status": "REQUESTED",
          "retrigger_by": "ngutina",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 469,
          "entity": "63",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "22-June-2023 06:30",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "MANUAL",
          "local_gaap_etb_total": "0.03000000",
          "us_gaap_etb_total": "-0.01000000",
          "lg_etb_total": "0.04000000",
          "transformation_time": "22-06-2023 13:32:20 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Failed",
          "created_time": "22-JUN-2023 05:30:06",
          "updated_time": "22-JUN-2023 05:30:06",
          "retrigger_request_time": "2023-06-27T00:00:00",
          "retrigger_status": "REQUESTED",
          "retrigger_by": "ngutina",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 474,
          "entity": "TEST",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "23-June-2023 10:15",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "23-JUN-2023 09:15:05",
          "updated_time": "23-JUN-2023 09:15:05",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": null,
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 474,
          "entity": "639",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "23-June-2023 10:15",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "23-JUN-2023 09:15:05",
          "updated_time": "23-JUN-2023 09:15:05",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": null,
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 450,
          "entity": "55_CONS",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-June-2023 12:00",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "14-JUN-2023 11:00:08",
          "updated_time": "14-JUN-2023 11:00:08",
          "retrigger_request_time": "2023-06-26T00:00:00",
          "retrigger_status": "REQUESTED",
          "retrigger_by": "uyyogara",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 467,
          "entity": "63",
          "region": "LATAM_North",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "15",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "21-June-2023 13:00",
          "effective_trigger_day": "21-June-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.03000000",
          "us_gaap_etb_total": "-0.01000000",
          "lg_etb_total": "0.04000000",
          "transformation_time": "21-06-2023 20:02:05 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Failed",
          "created_time": "21-JUN-2023 12:00:08",
          "updated_time": "21-JUN-2023 12:00:08",
          "retrigger_request_time": "2023-07-06T00:00:00",
          "retrigger_status": "REQUESTED",
          "retrigger_by": "ravsbhar",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 450,
          "entity": "4G",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-June-2023 12:00",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "14-06-2023 19:01:52 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "14-JUN-2023 11:00:08",
          "updated_time": "14-JUN-2023 11:00:08",
          "retrigger_request_time": "2023-06-27T00:00:00",
          "retrigger_status": "REQUESTED",
          "retrigger_by": "uyyogara",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 450,
          "entity": "55",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-June-2023 12:00",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "14-06-2023 19:01:52 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "14-JUN-2023 11:00:08",
          "updated_time": "14-JUN-2023 11:00:08",
          "retrigger_request_time": "2023-06-27T00:00:00",
          "retrigger_status": "REQUESTED",
          "retrigger_by": "uyyogara",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 452,
          "entity": "1I",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-June-2023 17:00",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "14-JUN-2023 04:00:15",
          "updated_time": "14-JUN-2023 04:00:15",
          "retrigger_request_time": "2023-06-15T00:00:00",
          "retrigger_status": "REQUESTED",
          "retrigger_by": "uyyogara",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 452,
          "entity": "1Y",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-June-2023 17:00",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "14-JUN-2023 04:00:15",
          "updated_time": "14-JUN-2023 04:00:15",
          "retrigger_request_time": "2023-06-15T00:00:00",
          "retrigger_status": "REQUESTED",
          "retrigger_by": "uyyogara",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 455,
          "entity": "1I",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "15-June-2023 20:45",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "15-JUN-2023 07:45:06",
          "updated_time": "15-JUN-2023 07:45:06",
          "retrigger_request_time": "2023-06-16T00:00:00",
          "retrigger_status": "REQUESTED",
          "retrigger_by": "uyyogara",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 450,
          "entity": "FW_CONSOL",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-June-2023 12:00",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "14-JUN-2023 11:00:08",
          "updated_time": "14-JUN-2023 11:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": null,
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 455,
          "entity": "1Y",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "15-June-2023 20:45",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "15-JUN-2023 07:45:06",
          "updated_time": "15-JUN-2023 07:45:06",
          "retrigger_request_time": "2023-06-19T00:00:00",
          "retrigger_status": "REQUESTED",
          "retrigger_by": "uyyogara",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 450,
          "entity": "63",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-June-2023 12:00",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "267855383697.46000000",
          "us_gaap_etb_total": "262339474999.03000000",
          "lg_etb_total": "5515908698.43000000",
          "transformation_time": "14-06-2023 19:01:52 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Failed",
          "created_time": "14-JUN-2023 11:00:08",
          "updated_time": "14-JUN-2023 11:00:08",
          "retrigger_request_time": "2023-06-19T00:00:00",
          "retrigger_status": "REQUESTED",
          "retrigger_by": "ravsbhar",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 465,
          "entity": "63RBK",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "19-June-2023 17:15",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "19-JUN-2023 04:15:05",
          "updated_time": "19-JUN-2023 04:15:05",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": null,
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 467,
          "entity": "MS",
          "region": "LATAM_North",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "15",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "21-June-2023 13:00",
          "effective_trigger_day": "21-June-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.40000000",
          "lg_etb_total": "-0.40000000",
          "transformation_time": "21-06-2023 20:02:05 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Failed",
          "created_time": "21-JUN-2023 12:00:08",
          "updated_time": "21-JUN-2023 12:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": null,
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 450,
          "entity": "CNICP",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-June-2023 12:00",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "14-JUN-2023 11:00:08",
          "updated_time": "14-JUN-2023 11:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": null,
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 467,
          "entity": "MR",
          "region": "LATAM_North",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "15",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "21-June-2023 13:00",
          "effective_trigger_day": "21-June-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "21-06-2023 20:02:05 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "21-JUN-2023 12:00:08",
          "updated_time": "21-JUN-2023 12:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": null,
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 467,
          "entity": "5C",
          "region": "LATAM_North",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "15",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "21-June-2023 13:00",
          "effective_trigger_day": "21-June-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "107.71000000",
          "lg_etb_total": "-107.71000000",
          "transformation_time": "21-06-2023 20:02:05 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Failed",
          "created_time": "21-JUN-2023 12:00:08",
          "updated_time": "21-JUN-2023 12:00:08",
          "retrigger_request_time": "2023-06-22T00:00:00",
          "retrigger_status": "REQUESTED",
          "retrigger_by": "ngutina",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 450,
          "entity": "CNWFOE",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-June-2023 12:00",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "14-JUN-2023 11:00:08",
          "updated_time": "14-JUN-2023 11:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": null,
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 450,
          "entity": "PK",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-June-2023 12:00",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "14-JUN-2023 11:00:08",
          "updated_time": "14-JUN-2023 11:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": null,
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 468,
          "entity": "63",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "21-June-2023 20:45",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "MANUAL",
          "local_gaap_etb_total": "0.03000000",
          "us_gaap_etb_total": "-0.01000000",
          "lg_etb_total": "0.04000000",
          "transformation_time": "22-06-2023 03:47:10 AM",
          "transformation_status": "Completed",
          "pre_check_status": "Failed",
          "created_time": "21-JUN-2023 07:45:05",
          "updated_time": "21-JUN-2023 07:45:05",
          "retrigger_request_time": "2023-06-22T00:00:00",
          "retrigger_status": "REQUESTED",
          "retrigger_by": "uyyogara",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 470,
          "entity": "1I",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "22-June-2023 10:30",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "MANUAL",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "22-JUN-2023 09:30:07",
          "updated_time": "22-JUN-2023 09:30:07",
          "retrigger_request_time": "2023-06-23T00:00:00",
          "retrigger_status": "REQUESTED",
          "retrigger_by": "uyyogara",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 475,
          "entity": "55_CONS",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "26-June-2023 07:07",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "MANUAL",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "26-JUN-2023 05:07:50",
          "updated_time": "26-JUN-2023 05:07:50",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "uyyogara",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 477,
          "entity": "TEST1",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "26-June-2023 11:30",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "26-JUN-2023 10:30:06",
          "updated_time": "26-JUN-2023 10:30:06",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": null,
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 477,
          "entity": "63ABC",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "26-June-2023 11:30",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "26-JUN-2023 10:30:06",
          "updated_time": "26-JUN-2023 10:30:06",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": null,
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 470,
          "entity": "1Y",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "22-June-2023 10:30",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "MANUAL",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "22-JUN-2023 09:30:07",
          "updated_time": "22-JUN-2023 09:30:07",
          "retrigger_request_time": "2023-07-13T00:00:00",
          "retrigger_status": "REQUESTED",
          "retrigger_by": "huitingt",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 480,
          "entity": "55",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "27-June-2023 11:44",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "COMPLETED",
          "trigger_request_type": "MANUAL",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "27-06-2023 18:46:57 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "27-JUN-2023 10:44:45",
          "updated_time": "27-JUN-2023 10:44:45",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "uyyogara",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 467,
          "entity": "75",
          "region": "LATAM_North",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "15",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "21-June-2023 13:00",
          "effective_trigger_day": "21-June-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "21-06-2023 20:02:05 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "21-JUN-2023 12:00:08",
          "updated_time": "21-JUN-2023 12:00:08",
          "retrigger_request_time": "2023-07-11T00:00:00",
          "retrigger_status": "REQUESTED",
          "retrigger_by": "ngutina",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 483,
          "entity": "SG_AAPH_LOCAL",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "30-June-2023 06:00",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.01000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "30-06-2023 13:02:16 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "30-JUN-2023 05:00:09",
          "updated_time": "30-JUN-2023 05:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 473,
          "entity": "1I",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "23-June-2023 01:00",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "MANUAL",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "23-JUN-2023 12:00:10",
          "updated_time": "23-JUN-2023 12:00:10",
          "retrigger_request_time": "2023-06-27T00:00:00",
          "retrigger_status": "REQUESTED",
          "retrigger_by": "ravsbhar",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 482,
          "entity": "1I",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "27-June-2023 12:30",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "COMPLETED",
          "trigger_request_type": "MANUAL",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "27-06-2023 19:32:20 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "27-JUN-2023 11:30:08",
          "updated_time": "27-JUN-2023 11:30:08",
          "retrigger_request_time": "2023-07-06T00:00:00",
          "retrigger_status": "REQUESTED",
          "retrigger_by": "uyyogara",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 483,
          "entity": "B162",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "30-June-2023 06:00",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "30-06-2023 13:02:16 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "30-JUN-2023 05:00:09",
          "updated_time": "30-JUN-2023 05:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 481,
          "entity": "1I",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "27-June-2023 12:15",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "COMPLETED",
          "trigger_request_type": "MANUAL",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "27-06-2023 19:17:29 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "27-JUN-2023 11:15:05",
          "updated_time": "27-JUN-2023 11:15:05",
          "retrigger_request_time": "2023-06-27T00:00:00",
          "retrigger_status": "REQUESTED",
          "retrigger_by": "ravsbhar",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 483,
          "entity": "CN_CNAICSCL_LOCAL",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "30-June-2023 06:00",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "30-06-2023 13:02:16 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "30-JUN-2023 05:00:09",
          "updated_time": "30-JUN-2023 05:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 479,
          "entity": "63",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "26-June-2023 23:15",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "COMPLETED",
          "trigger_request_type": "MANUAL",
          "local_gaap_etb_total": "0.03000000",
          "us_gaap_etb_total": "-0.01000000",
          "lg_etb_total": "0.04000000",
          "transformation_time": "27-06-2023 16:54:22 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "26-JUN-2023 10:15:06",
          "updated_time": "26-JUN-2023 10:15:06",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": null,
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 450,
          "entity": "CNJV",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-June-2023 12:00",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "14-JUN-2023 11:00:08",
          "updated_time": "14-JUN-2023 11:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": null,
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 473,
          "entity": "123",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "23-June-2023 01:00",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "MANUAL",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "23-JUN-2023 12:00:10",
          "updated_time": "23-JUN-2023 12:00:10",
          "retrigger_request_time": "2023-07-06T00:00:00",
          "retrigger_status": "REQUESTED",
          "retrigger_by": "uyyogara",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 483,
          "entity": "5C",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "30-June-2023 06:00",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "107.71000000",
          "lg_etb_total": "-107.71000000",
          "transformation_time": "30-06-2023 13:02:16 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Failed",
          "created_time": "30-JUN-2023 05:00:09",
          "updated_time": "30-JUN-2023 05:00:09",
          "retrigger_request_time": "2023-07-06T00:00:00",
          "retrigger_status": "REQUESTED",
          "retrigger_by": "ravsbhar",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 467,
          "entity": "CP",
          "region": "LATAM_North",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "15",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "21-June-2023 13:00",
          "effective_trigger_day": "21-June-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "21-06-2023 20:02:05 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "21-JUN-2023 12:00:08",
          "updated_time": "21-JUN-2023 12:00:08",
          "retrigger_request_time": "2023-07-11T00:00:00",
          "retrigger_status": "REQUESTED",
          "retrigger_by": "ngutina",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 480,
          "entity": "4G",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "27-June-2023 11:44",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "COMPLETED",
          "trigger_request_type": "MANUAL",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "27-06-2023 18:46:57 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "27-JUN-2023 10:44:45",
          "updated_time": "27-JUN-2023 10:44:45",
          "retrigger_request_time": "2023-07-13T00:00:00",
          "retrigger_status": "REQUESTED",
          "retrigger_by": "ngutina",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 490,
          "entity": "B115",
          "region": "LATAM_North",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "15",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-July-2023 10:30",
          "effective_trigger_day": "21-June-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "MANUAL",
          "local_gaap_etb_total": "-0.08000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "-0.08000000",
          "transformation_time": "14-07-2023 17:31:50 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "14-JUL-2023 09:30:05",
          "updated_time": "14-JUL-2023 09:30:05",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "kargodoy",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 492,
          "entity": "B423",
          "region": "LATAM_North",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "15",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "17-July-2023 14:45",
          "effective_trigger_day": "21-June-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "MANUAL",
          "local_gaap_etb_total": "0.16000000",
          "us_gaap_etb_total": "0.16000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "17-07-2023 21:47:10 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "17-JUL-2023 01:45:05",
          "updated_time": "17-JUL-2023 01:45:05",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "laraenri",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 450,
          "entity": "65",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-June-2023 12:00",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "-132246566333.85000000",
          "us_gaap_etb_total": "-149156132653.40000000",
          "lg_etb_total": "16909566319.55000000",
          "transformation_time": "14-06-2023 19:01:52 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Failed",
          "created_time": "14-JUN-2023 11:00:08",
          "updated_time": "14-JUN-2023 11:00:08",
          "retrigger_request_time": "2023-06-19T00:00:00",
          "retrigger_status": "REQUESTED",
          "retrigger_by": "ravsbhar",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 489,
          "entity": "630",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "13-July-2023 13:00",
          "effective_trigger_day": "13-July-2023 12:00",
          "status": "DELETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "13-JUL-2023 12:00:09",
          "updated_time": "13-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": "",
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 450,
          "entity": "B451",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-June-2023 12:00",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "14-06-2023 19:01:52 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "14-JUN-2023 11:00:08",
          "updated_time": "14-JUN-2023 11:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": null,
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 450,
          "entity": "B757",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-June-2023 12:00",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "14-06-2023 19:01:52 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "14-JUN-2023 11:00:08",
          "updated_time": "14-JUN-2023 11:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": null,
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 450,
          "entity": "B860",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-June-2023 12:00",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "14-06-2023 19:01:52 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "14-JUN-2023 11:00:08",
          "updated_time": "14-JUN-2023 11:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": null,
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 450,
          "entity": "EQ",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-June-2023 12:00",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "14-06-2023 19:01:52 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "14-JUN-2023 11:00:08",
          "updated_time": "14-JUN-2023 11:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": null,
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 450,
          "entity": "69",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-June-2023 12:00",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "14-06-2023 19:01:52 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "14-JUN-2023 11:00:08",
          "updated_time": "14-JUN-2023 11:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": null,
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 450,
          "entity": "HS",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-June-2023 12:00",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "-0.01000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "-0.02000000",
          "transformation_time": "14-06-2023 19:01:52 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "14-JUN-2023 11:00:08",
          "updated_time": "14-JUN-2023 11:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": null,
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 489,
          "entity": "55_CONS",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "13-July-2023 13:00",
          "effective_trigger_day": "13-July-2023 12:00",
          "status": "DELETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "13-JUL-2023 12:00:09",
          "updated_time": "13-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": "",
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 450,
          "entity": "B745",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-June-2023 12:00",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "14-06-2023 19:01:52 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "14-JUN-2023 11:00:08",
          "updated_time": "14-JUN-2023 11:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": null,
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 450,
          "entity": "B872",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-June-2023 12:00",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "14-06-2023 19:01:52 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "14-JUN-2023 11:00:08",
          "updated_time": "14-JUN-2023 11:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": null,
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 450,
          "entity": "B869",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-June-2023 12:00",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "14-06-2023 19:01:52 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "14-JUN-2023 11:00:08",
          "updated_time": "14-JUN-2023 11:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": null,
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 450,
          "entity": "B110",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-June-2023 12:00",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "14-06-2023 19:01:52 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "14-JUN-2023 11:00:08",
          "updated_time": "14-JUN-2023 11:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": null,
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 450,
          "entity": "B743",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-June-2023 12:00",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "14-06-2023 19:01:52 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "14-JUN-2023 11:00:08",
          "updated_time": "14-JUN-2023 11:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": null,
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 450,
          "entity": "KA",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-June-2023 12:00",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "14-06-2023 19:01:52 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "14-JUN-2023 11:00:08",
          "updated_time": "14-JUN-2023 11:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": null,
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 450,
          "entity": "QK",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-June-2023 12:00",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "52793895.75000000",
          "us_gaap_etb_total": "53566149.97000000",
          "lg_etb_total": "-772254.22000000",
          "transformation_time": "14-06-2023 19:01:52 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Failed",
          "created_time": "14-JUN-2023 11:00:08",
          "updated_time": "14-JUN-2023 11:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": null,
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 450,
          "entity": "RT",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-June-2023 12:00",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "17294881296.65000000",
          "us_gaap_etb_total": "23960379035.99000000",
          "lg_etb_total": "-6665497739.34000000",
          "transformation_time": "14-06-2023 19:01:52 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Failed",
          "created_time": "14-JUN-2023 11:00:08",
          "updated_time": "14-JUN-2023 11:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": null,
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 450,
          "entity": "9T",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-June-2023 12:00",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "-196.58000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "-196.58000000",
          "transformation_time": "14-06-2023 19:01:52 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Failed",
          "created_time": "14-JUN-2023 11:00:08",
          "updated_time": "14-JUN-2023 11:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": null,
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 450,
          "entity": "B427",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-June-2023 12:00",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "14-06-2023 19:01:52 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "14-JUN-2023 11:00:08",
          "updated_time": "14-JUN-2023 11:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": null,
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 450,
          "entity": "DX",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-June-2023 12:00",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "14-06-2023 19:01:52 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "14-JUN-2023 11:00:08",
          "updated_time": "14-JUN-2023 11:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": null,
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 450,
          "entity": "8O",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-June-2023 12:00",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "14-06-2023 19:01:52 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "14-JUN-2023 11:00:08",
          "updated_time": "14-JUN-2023 11:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": null,
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 450,
          "entity": "B928",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-June-2023 12:00",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "14-06-2023 19:01:52 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "14-JUN-2023 11:00:08",
          "updated_time": "14-JUN-2023 11:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": null,
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 450,
          "entity": "ML",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-June-2023 12:00",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "14-06-2023 19:01:52 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "14-JUN-2023 11:00:08",
          "updated_time": "14-JUN-2023 11:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": null,
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 450,
          "entity": "CW",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-June-2023 12:00",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "14-06-2023 19:01:52 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "14-JUN-2023 11:00:08",
          "updated_time": "14-JUN-2023 11:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": null,
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 450,
          "entity": "B180",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-June-2023 12:00",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "14-06-2023 19:01:52 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "14-JUN-2023 11:00:08",
          "updated_time": "14-JUN-2023 11:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": null,
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 450,
          "entity": "B583",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-June-2023 12:00",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "14-06-2023 19:01:52 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "14-JUN-2023 11:00:08",
          "updated_time": "14-JUN-2023 11:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": null,
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 450,
          "entity": "B386",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-June-2023 12:00",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "14-06-2023 19:01:52 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "14-JUN-2023 11:00:08",
          "updated_time": "14-JUN-2023 11:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": null,
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 450,
          "entity": "PN",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-June-2023 12:00",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "14-06-2023 19:01:52 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "14-JUN-2023 11:00:08",
          "updated_time": "14-JUN-2023 11:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": null,
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 450,
          "entity": "B199",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-June-2023 12:00",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "14-06-2023 19:01:52 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "14-JUN-2023 11:00:08",
          "updated_time": "14-JUN-2023 11:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": null,
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 453,
          "entity": "1Y",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "15-June-2023 08:30",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "15-JUN-2023 07:30:06",
          "updated_time": "15-JUN-2023 07:30:06",
          "retrigger_request_time": "2023-06-15T00:00:00",
          "retrigger_status": "REQUESTED",
          "retrigger_by": "ravsbhar",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 466,
          "entity": "66RBK",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "19-June-2023 17:30",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "19-JUN-2023 04:30:07",
          "updated_time": "19-JUN-2023 04:30:07",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": null,
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 466,
          "entity": "65RBK",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "19-June-2023 17:30",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "19-JUN-2023 04:30:07",
          "updated_time": "19-JUN-2023 04:30:07",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": null,
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 450,
          "entity": "B776",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-June-2023 12:00",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "14-06-2023 19:01:52 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "14-JUN-2023 11:00:08",
          "updated_time": "14-JUN-2023 11:00:08",
          "retrigger_request_time": "2023-07-11T00:00:00",
          "retrigger_status": "REQUESTED",
          "retrigger_by": "ngutina",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 450,
          "entity": "HA",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-June-2023 12:00",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "14-06-2023 19:01:52 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "14-JUN-2023 11:00:08",
          "updated_time": "14-JUN-2023 11:00:08",
          "retrigger_request_time": "2023-07-11T00:00:00",
          "retrigger_status": "REQUESTED",
          "retrigger_by": "ngutina",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 450,
          "entity": "B581",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-June-2023 12:00",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "14-06-2023 19:01:52 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "14-JUN-2023 11:00:08",
          "updated_time": "14-JUN-2023 11:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": null,
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 450,
          "entity": "Z4",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-June-2023 12:00",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "14-06-2023 19:01:52 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "14-JUN-2023 11:00:08",
          "updated_time": "14-JUN-2023 11:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": null,
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 450,
          "entity": "B645",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-June-2023 12:00",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "14-06-2023 19:01:52 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "14-JUN-2023 11:00:08",
          "updated_time": "14-JUN-2023 11:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": null,
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 496,
          "entity": "1Y",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "19-July-2023 16:30",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "MANUAL",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "19-JUL-2023 03:30:08",
          "updated_time": "19-JUL-2023 03:30:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "marigala",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 450,
          "entity": "B400",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-June-2023 12:00",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "14-06-2023 19:01:52 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "14-JUN-2023 11:00:08",
          "updated_time": "14-JUN-2023 11:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": null,
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 450,
          "entity": "BI",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-June-2023 12:00",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "-0.75000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "14-06-2023 19:01:52 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Failed",
          "created_time": "14-JUN-2023 11:00:08",
          "updated_time": "14-JUN-2023 11:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": null,
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 450,
          "entity": "8F",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-June-2023 12:00",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "14-06-2023 19:01:52 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "14-JUN-2023 11:00:08",
          "updated_time": "14-JUN-2023 11:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": null,
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 450,
          "entity": "B644",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-June-2023 12:00",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "14-06-2023 19:01:52 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "14-JUN-2023 11:00:08",
          "updated_time": "14-JUN-2023 11:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": null,
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 450,
          "entity": "AQ",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-June-2023 12:00",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "14-06-2023 19:01:52 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "14-JUN-2023 11:00:08",
          "updated_time": "14-JUN-2023 11:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": null,
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 450,
          "entity": "B900",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-June-2023 12:00",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "14-06-2023 19:01:52 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "14-JUN-2023 11:00:08",
          "updated_time": "14-JUN-2023 11:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": null,
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 450,
          "entity": "V8",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-June-2023 12:00",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "14-06-2023 19:01:52 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "14-JUN-2023 11:00:08",
          "updated_time": "14-JUN-2023 11:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": null,
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 450,
          "entity": "8R",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-June-2023 12:00",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "14-06-2023 19:01:52 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "14-JUN-2023 11:00:08",
          "updated_time": "14-JUN-2023 11:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": null,
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 450,
          "entity": "NX",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-June-2023 12:00",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "14-06-2023 19:01:52 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "14-JUN-2023 11:00:08",
          "updated_time": "14-JUN-2023 11:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": null,
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 450,
          "entity": "HW",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-June-2023 12:00",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "14-06-2023 19:01:52 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "14-JUN-2023 11:00:08",
          "updated_time": "14-JUN-2023 11:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": null,
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 450,
          "entity": "FW",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-June-2023 12:00",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "14-06-2023 19:01:52 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "14-JUN-2023 11:00:08",
          "updated_time": "14-JUN-2023 11:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": null,
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 450,
          "entity": "8E",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-June-2023 12:00",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "14-06-2023 19:01:52 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "14-JUN-2023 11:00:08",
          "updated_time": "14-JUN-2023 11:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": null,
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 450,
          "entity": "DG",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-June-2023 12:00",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "-528104318.33000000",
          "us_gaap_etb_total": "-528104511.73000000",
          "lg_etb_total": "193.40000000",
          "transformation_time": "14-06-2023 19:01:52 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Failed",
          "created_time": "14-JUN-2023 11:00:08",
          "updated_time": "14-JUN-2023 11:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": null,
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 450,
          "entity": "B856",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-June-2023 12:00",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "14-06-2023 19:01:52 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "14-JUN-2023 11:00:08",
          "updated_time": "14-JUN-2023 11:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": null,
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 503,
          "entity": "HA",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "24-July-2023 16:30",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "MANUAL",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "24-JUL-2023 03:30:08",
          "updated_time": "24-JUL-2023 03:30:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "huitingt",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 450,
          "entity": "8D",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-June-2023 12:00",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "-0.01000000",
          "lg_etb_total": "0.01000000",
          "transformation_time": "14-06-2023 19:01:52 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "14-JUN-2023 11:00:08",
          "updated_time": "14-JUN-2023 11:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": null,
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 450,
          "entity": "B565",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-June-2023 12:00",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "14-06-2023 19:01:52 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "14-JUN-2023 11:00:08",
          "updated_time": "14-JUN-2023 11:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": null,
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 450,
          "entity": "8W",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-June-2023 12:00",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "14-06-2023 19:01:52 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "14-JUN-2023 11:00:08",
          "updated_time": "14-JUN-2023 11:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": null,
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 450,
          "entity": "B9",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-June-2023 12:00",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "14-06-2023 19:01:52 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "14-JUN-2023 11:00:08",
          "updated_time": "14-JUN-2023 11:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": null,
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 450,
          "entity": "B672",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-June-2023 12:00",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "14-06-2023 19:01:52 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "14-JUN-2023 11:00:08",
          "updated_time": "14-JUN-2023 11:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": null,
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 450,
          "entity": "B436",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-June-2023 12:00",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "14-06-2023 19:01:52 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "14-JUN-2023 11:00:08",
          "updated_time": "14-JUN-2023 11:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": null,
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 450,
          "entity": "7G",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-June-2023 12:00",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "14-06-2023 19:01:52 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "14-JUN-2023 11:00:08",
          "updated_time": "14-JUN-2023 11:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": null,
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 450,
          "entity": "BC61",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-June-2023 12:00",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "14-06-2023 19:01:52 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "14-JUN-2023 11:00:08",
          "updated_time": "14-JUN-2023 11:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": null,
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 450,
          "entity": "B112",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-June-2023 12:00",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "14-06-2023 19:01:52 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "14-JUN-2023 11:00:08",
          "updated_time": "14-JUN-2023 11:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": null,
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 450,
          "entity": "7J",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-June-2023 12:00",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "14-06-2023 19:01:52 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "14-JUN-2023 11:00:08",
          "updated_time": "14-JUN-2023 11:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": null,
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 450,
          "entity": "BW",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-June-2023 12:00",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "14-06-2023 19:01:52 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "14-JUN-2023 11:00:08",
          "updated_time": "14-JUN-2023 11:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": null,
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 450,
          "entity": "64",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-June-2023 12:00",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "14-06-2023 19:01:52 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "14-JUN-2023 11:00:08",
          "updated_time": "14-JUN-2023 11:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": null,
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 450,
          "entity": "J3",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-June-2023 12:00",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "14-06-2023 19:01:52 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "14-JUN-2023 11:00:08",
          "updated_time": "14-JUN-2023 11:00:08",
          "retrigger_request_time": "2023-07-11T00:00:00",
          "retrigger_status": "REQUESTED",
          "retrigger_by": "ngutina",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 471,
          "entity": "123",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "22-June-2023 16:30",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "22-JUN-2023 03:30:05",
          "updated_time": "22-JUN-2023 03:30:05",
          "retrigger_request_time": "2023-06-23T00:00:00",
          "retrigger_status": "REQUESTED",
          "retrigger_by": "uyyogara",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 450,
          "entity": "JG",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-June-2023 12:00",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "14-06-2023 19:01:52 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "14-JUN-2023 11:00:08",
          "updated_time": "14-JUN-2023 11:00:08",
          "retrigger_request_time": "2023-07-11T00:00:00",
          "retrigger_status": "REQUESTED",
          "retrigger_by": "ngutina",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 450,
          "entity": "B859",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-June-2023 12:00",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "14-06-2023 19:01:52 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "14-JUN-2023 11:00:08",
          "updated_time": "14-JUN-2023 11:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": null,
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 450,
          "entity": "AP",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-June-2023 12:00",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "14-06-2023 19:01:52 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "14-JUN-2023 11:00:08",
          "updated_time": "14-JUN-2023 11:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": null,
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 450,
          "entity": "B676",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-June-2023 12:00",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "14-06-2023 19:01:52 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "14-JUN-2023 11:00:08",
          "updated_time": "14-JUN-2023 11:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": null,
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 450,
          "entity": "MJ",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-June-2023 12:00",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "14-06-2023 19:01:52 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "14-JUN-2023 11:00:08",
          "updated_time": "14-JUN-2023 11:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": null,
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 450,
          "entity": "FX",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-June-2023 12:00",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "14-06-2023 19:01:52 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "14-JUN-2023 11:00:08",
          "updated_time": "14-JUN-2023 11:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": null,
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 450,
          "entity": "QM",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-June-2023 12:00",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "14-06-2023 19:01:52 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "14-JUN-2023 11:00:08",
          "updated_time": "14-JUN-2023 11:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": null,
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 450,
          "entity": "B422",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-June-2023 12:00",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "14-06-2023 19:01:52 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "14-JUN-2023 11:00:08",
          "updated_time": "14-JUN-2023 11:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": null,
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 450,
          "entity": "6H",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-June-2023 12:00",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "5837151130.45000000",
          "us_gaap_etb_total": "5854459639.09000000",
          "lg_etb_total": "-17308508.64000000",
          "transformation_time": "14-06-2023 19:01:52 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Failed",
          "created_time": "14-JUN-2023 11:00:08",
          "updated_time": "14-JUN-2023 11:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": null,
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 450,
          "entity": "E3",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-June-2023 12:00",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "-0.01000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "-0.01000000",
          "transformation_time": "14-06-2023 19:01:52 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "14-JUN-2023 11:00:08",
          "updated_time": "14-JUN-2023 11:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": null,
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 450,
          "entity": "EA",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-June-2023 12:00",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "14-06-2023 19:01:52 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "14-JUN-2023 11:00:08",
          "updated_time": "14-JUN-2023 11:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": null,
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 450,
          "entity": "DZ",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-June-2023 12:00",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "14-06-2023 19:01:52 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "14-JUN-2023 11:00:08",
          "updated_time": "14-JUN-2023 11:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": null,
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 450,
          "entity": "BC11",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-June-2023 12:00",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "14-06-2023 19:01:52 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "14-JUN-2023 11:00:08",
          "updated_time": "14-JUN-2023 11:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": null,
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 450,
          "entity": "B141",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-June-2023 12:00",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "-0.01000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "-0.01000000",
          "transformation_time": "14-06-2023 19:01:52 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "14-JUN-2023 11:00:08",
          "updated_time": "14-JUN-2023 11:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": null,
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 450,
          "entity": "L8",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-June-2023 12:00",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "-0.01000000",
          "lg_etb_total": "0.01000000",
          "transformation_time": "14-06-2023 19:01:52 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "14-JUN-2023 11:00:08",
          "updated_time": "14-JUN-2023 11:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": null,
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 450,
          "entity": "LK",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-June-2023 12:00",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "155460.16000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "155460.16000000",
          "transformation_time": "14-06-2023 19:01:52 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Failed",
          "created_time": "14-JUN-2023 11:00:08",
          "updated_time": "14-JUN-2023 11:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": null,
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 450,
          "entity": "B184",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-June-2023 12:00",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "14-06-2023 19:01:52 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "14-JUN-2023 11:00:08",
          "updated_time": "14-JUN-2023 11:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": null,
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 450,
          "entity": "R6",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-June-2023 12:00",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "-292942272.39000000",
          "us_gaap_etb_total": "-292906641.58000000",
          "lg_etb_total": "-35630.80000000",
          "transformation_time": "14-06-2023 19:01:52 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Failed",
          "created_time": "14-JUN-2023 11:00:08",
          "updated_time": "14-JUN-2023 11:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": null,
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 450,
          "entity": "KB",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-June-2023 12:00",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "14-06-2023 19:01:52 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "14-JUN-2023 11:00:08",
          "updated_time": "14-JUN-2023 11:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": null,
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 450,
          "entity": "8V",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-June-2023 12:00",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "14-06-2023 19:01:52 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "14-JUN-2023 11:00:08",
          "updated_time": "14-JUN-2023 11:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": null,
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 497,
          "entity": "CNWFOE",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "24-July-2023 07:00",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "MANUAL",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "24-JUL-2023 06:00:08",
          "updated_time": "24-JUL-2023 06:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "koaysiu",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 450,
          "entity": "TM",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-June-2023 12:00",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.01000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.01000000",
          "transformation_time": "14-06-2023 19:01:52 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "14-JUN-2023 11:00:08",
          "updated_time": "14-JUN-2023 11:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": null,
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 450,
          "entity": "1I",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-June-2023 12:00",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "14-06-2023 19:01:52 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "14-JUN-2023 11:00:08",
          "updated_time": "14-JUN-2023 11:00:08",
          "retrigger_request_time": "2023-06-14T00:00:00",
          "retrigger_status": "REQUESTED",
          "retrigger_by": "ravsbhar",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 453,
          "entity": "1I",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "15-June-2023 08:30",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "15-JUN-2023 07:30:06",
          "updated_time": "15-JUN-2023 07:30:06",
          "retrigger_request_time": "2023-06-15T00:00:00",
          "retrigger_status": "REQUESTED",
          "retrigger_by": "ravsbhar",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 456,
          "entity": "2I",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "16-June-2023 01:00",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "16-06-2023 08:02:10 AM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "16-JUN-2023 12:00:05",
          "updated_time": "16-JUN-2023 12:00:05",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": null,
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 450,
          "entity": "2I",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-June-2023 12:00",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "14-06-2023 19:01:52 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "14-JUN-2023 11:00:08",
          "updated_time": "14-JUN-2023 11:00:08",
          "retrigger_request_time": "2023-06-15T00:00:00",
          "retrigger_status": "REQUESTED",
          "retrigger_by": "ravsbhar",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 450,
          "entity": "4E",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-June-2023 12:00",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "14-06-2023 19:01:52 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "14-JUN-2023 11:00:08",
          "updated_time": "14-JUN-2023 11:00:08",
          "retrigger_request_time": "2023-06-15T00:00:00",
          "retrigger_status": "REQUESTED",
          "retrigger_by": "ravsbhar",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 463,
          "entity": "65",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "19-June-2023 10:45",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "MANUAL",
          "local_gaap_etb_total": "0.01000000",
          "us_gaap_etb_total": "0.01000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "19-06-2023 17:46:58 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "19-JUN-2023 09:45:05",
          "updated_time": "19-JUN-2023 09:45:05",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": null,
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 466,
          "entity": "64RBK",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "19-June-2023 17:30",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "19-JUN-2023 04:30:07",
          "updated_time": "19-JUN-2023 04:30:07",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": null,
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 466,
          "entity": "63A",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "19-June-2023 17:30",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "19-JUN-2023 04:30:07",
          "updated_time": "19-JUN-2023 04:30:07",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": null,
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 463,
          "entity": "63",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "19-June-2023 10:45",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "MANUAL",
          "local_gaap_etb_total": "0.03000000",
          "us_gaap_etb_total": "-0.01000000",
          "lg_etb_total": "0.04000000",
          "transformation_time": "19-06-2023 17:46:58 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Failed",
          "created_time": "19-JUN-2023 09:45:05",
          "updated_time": "19-JUN-2023 09:45:05",
          "retrigger_request_time": "2023-06-22T00:00:00",
          "retrigger_status": "REQUESTED",
          "retrigger_by": "ngutina",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 503,
          "entity": "AQ (SG)",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "24-July-2023 16:30",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "24-JUL-2023 03:30:08",
          "updated_time": "24-JUL-2023 03:30:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 450,
          "entity": "QL",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-June-2023 12:00",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.24000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.24000000",
          "transformation_time": "14-06-2023 19:01:52 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Failed",
          "created_time": "14-JUN-2023 11:00:08",
          "updated_time": "14-JUN-2023 11:00:08",
          "retrigger_request_time": "2023-06-30T00:00:00",
          "retrigger_status": "REQUESTED",
          "retrigger_by": "uyyogara",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 450,
          "entity": "B179",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-June-2023 12:00",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "14-06-2023 19:01:52 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "14-JUN-2023 11:00:08",
          "updated_time": "14-JUN-2023 11:00:08",
          "retrigger_request_time": "2023-07-11T00:00:00",
          "retrigger_status": "REQUESTED",
          "retrigger_by": "ngutina",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 456,
          "entity": "4E",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "16-June-2023 01:00",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "16-06-2023 08:02:10 AM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "16-JUN-2023 12:00:05",
          "updated_time": "16-JUN-2023 12:00:05",
          "retrigger_request_time": "2023-07-11T00:00:00",
          "retrigger_status": "REQUESTED",
          "retrigger_by": "ngutina",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 483,
          "entity": "B423",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "30-June-2023 06:00",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.16000000",
          "us_gaap_etb_total": "0.16000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "30-06-2023 13:02:16 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "30-JUN-2023 05:00:09",
          "updated_time": "30-JUN-2023 05:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 483,
          "entity": "CN_CNICP_LOCAL",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "30-June-2023 06:00",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "-0.02000000",
          "us_gaap_etb_total": "-0.03000000",
          "lg_etb_total": "0.01000000",
          "transformation_time": "30-06-2023 13:02:16 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "30-JUN-2023 05:00:09",
          "updated_time": "30-JUN-2023 05:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 483,
          "entity": "B115",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "30-June-2023 06:00",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "-0.08000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "-0.08000000",
          "transformation_time": "30-06-2023 13:02:16 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "30-JUN-2023 05:00:09",
          "updated_time": "30-JUN-2023 05:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 483,
          "entity": "SG_AAPR_LOCAL",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "30-June-2023 06:00",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "-0.01000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "-0.01000000",
          "transformation_time": "30-06-2023 13:02:16 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "30-JUN-2023 05:00:09",
          "updated_time": "30-JUN-2023 05:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 483,
          "entity": "CN_CNWFOE_LOCAL",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "30-June-2023 06:00",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "-0.01000000",
          "lg_etb_total": "0.01000000",
          "transformation_time": "30-06-2023 13:02:16 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "30-JUN-2023 05:00:09",
          "updated_time": "30-JUN-2023 05:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 483,
          "entity": "MS",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "30-June-2023 06:00",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.40000000",
          "lg_etb_total": "-0.40000000",
          "transformation_time": "30-06-2023 13:02:16 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "30-JUN-2023 05:00:09",
          "updated_time": "30-JUN-2023 05:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 483,
          "entity": "CN_CNJV_LOCAL",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "30-June-2023 06:00",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "30-06-2023 13:02:16 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "30-JUN-2023 05:00:09",
          "updated_time": "30-JUN-2023 05:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 483,
          "entity": "CN_FW_CONSOL_LOCAL",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "30-June-2023 06:00",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "30-06-2023 13:02:16 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "30-JUN-2023 05:00:09",
          "updated_time": "30-JUN-2023 05:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 483,
          "entity": "5Z",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "30-June-2023 06:00",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "30-06-2023 13:02:16 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "30-JUN-2023 05:00:09",
          "updated_time": "30-JUN-2023 05:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 483,
          "entity": "PH_AOSP_LOCAL",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "30-June-2023 06:00",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "-0.02000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "-0.02000000",
          "transformation_time": "30-06-2023 13:02:16 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "30-JUN-2023 05:00:09",
          "updated_time": "30-JUN-2023 05:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 484,
          "entity": "QL",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "30-June-2023 07:30",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "COMPLETED",
          "trigger_request_type": "MANUAL",
          "local_gaap_etb_total": "0.24000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.24000000",
          "transformation_time": "30-06-2023 14:31:59 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "30-JUN-2023 06:30:06",
          "updated_time": "30-JUN-2023 06:30:06",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "uyyogara",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 493,
          "entity": "HS",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "18-July-2023 13:00",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "-0.01000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "-0.02000000",
          "transformation_time": "19-07-2023 07:33:33 AM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "18-JUL-2023 12:00:09",
          "updated_time": "18-JUL-2023 12:00:09",
          "retrigger_request_time": "2023-07-24T00:00:00",
          "retrigger_status": "REQUESTED",
          "retrigger_by": "koaysiu",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 476,
          "entity": "5C",
          "region": "LATAM_North",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "15",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "26-June-2023 07:16",
          "effective_trigger_day": "21-June-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "MANUAL",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "107.71000000",
          "lg_etb_total": "-107.71000000",
          "transformation_time": "26-06-2023 13:19:36 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Failed",
          "created_time": "26-JUN-2023 05:16:03",
          "updated_time": "26-JUN-2023 05:16:03",
          "retrigger_request_time": "2023-07-06T00:00:00",
          "retrigger_status": "REQUESTED",
          "retrigger_by": "ravsbhar",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 486,
          "entity": "63",
          "region": "LATAM_North",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "15",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "06-July-2023 12:15",
          "effective_trigger_day": "21-June-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "MANUAL",
          "local_gaap_etb_total": "0.03000000",
          "us_gaap_etb_total": "-0.01000000",
          "lg_etb_total": "0.04000000",
          "transformation_time": "06-07-2023 19:17:13 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "06-JUL-2023 11:15:05",
          "updated_time": "06-JUL-2023 11:15:05",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "ravsbhar",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 486,
          "entity": "5C",
          "region": "LATAM_North",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "15",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "06-July-2023 12:15",
          "effective_trigger_day": "21-June-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "MANUAL",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "215.42000000",
          "lg_etb_total": "-215.42000000",
          "transformation_time": "06-07-2023 19:17:13 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Failed",
          "created_time": "06-JUL-2023 11:15:05",
          "updated_time": "06-JUL-2023 11:15:05",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "ravsbhar",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 486,
          "entity": "5C",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "06-July-2023 12:15",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "FAILED",
          "trigger_request_type": "MANUAL",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "06-JUL-2023 11:15:05",
          "updated_time": "06-JUL-2023 11:15:05",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "ravsbhar",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 450,
          "entity": "B439",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-June-2023 12:00",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "14-06-2023 19:01:52 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "14-JUN-2023 11:00:08",
          "updated_time": "14-JUN-2023 11:00:08",
          "retrigger_request_time": "2023-07-11T00:00:00",
          "retrigger_status": "REQUESTED",
          "retrigger_by": "ngutina",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 450,
          "entity": "6C",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-June-2023 12:00",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "20286386162.61000000",
          "us_gaap_etb_total": "21444177317.67000000",
          "lg_etb_total": "-1157791155.05000000",
          "transformation_time": "14-06-2023 19:01:52 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Failed",
          "created_time": "14-JUN-2023 11:00:08",
          "updated_time": "14-JUN-2023 11:00:08",
          "retrigger_request_time": "2023-07-11T00:00:00",
          "retrigger_status": "REQUESTED",
          "retrigger_by": "ngutina",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 487,
          "entity": "75",
          "region": "LATAM_North",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "15",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "10-July-2023 19:45",
          "effective_trigger_day": "21-June-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "MANUAL",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "11-07-2023 02:47:23 AM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "10-JUL-2023 06:45:05",
          "updated_time": "10-JUL-2023 06:45:05",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "ngutina",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 487,
          "entity": "J3",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "10-July-2023 19:45",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "COMPLETED",
          "trigger_request_type": "MANUAL",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "11-07-2023 02:47:23 AM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "10-JUL-2023 06:45:05",
          "updated_time": "10-JUL-2023 06:45:05",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "ngutina",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 487,
          "entity": "B776",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "10-July-2023 19:45",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "COMPLETED",
          "trigger_request_type": "MANUAL",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "11-07-2023 02:47:23 AM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "10-JUL-2023 06:45:05",
          "updated_time": "10-JUL-2023 06:45:05",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "ngutina",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 487,
          "entity": "B439",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "10-July-2023 19:45",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "COMPLETED",
          "trigger_request_type": "MANUAL",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "11-07-2023 02:47:23 AM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "10-JUL-2023 06:45:05",
          "updated_time": "10-JUL-2023 06:45:05",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "ngutina",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 487,
          "entity": "B106",
          "region": "LATAM_North",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "15",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "10-July-2023 19:45",
          "effective_trigger_day": "21-June-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "MANUAL",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "11-07-2023 02:47:23 AM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "10-JUL-2023 06:45:05",
          "updated_time": "10-JUL-2023 06:45:05",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "ngutina",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 485,
          "entity": "1I",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "06-July-2023 02:10",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "COMPLETED",
          "trigger_request_type": "MANUAL",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "06-07-2023 09:12:15 AM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "06-JUL-2023 01:10:13",
          "updated_time": "06-JUL-2023 01:10:13",
          "retrigger_request_time": "2023-07-13T00:00:00",
          "retrigger_status": "REQUESTED",
          "retrigger_by": "huitingt",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 504,
          "entity": "B645",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "24-July-2023 16:45",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "MANUAL",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "24-JUL-2023 03:45:07",
          "updated_time": "24-JUL-2023 03:45:07",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "huitingt",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 504,
          "entity": "B439",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "24-July-2023 16:45",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "MANUAL",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "24-JUL-2023 03:45:07",
          "updated_time": "24-JUL-2023 03:45:07",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "huitingt",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 504,
          "entity": "DZ",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "24-July-2023 16:45",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "MANUAL",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "24-JUL-2023 03:45:07",
          "updated_time": "24-JUL-2023 03:45:07",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "huitingt",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 628,
          "entity": "5C",
          "region": "LATAM_North",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "16",
          "trigger_time_pst": "12:00",
          "etb_version": "Post-Tax, Post-WB",
          "actual_trigger_day": "28-July-2023 11:35",
          "effective_trigger_day": "25-July-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "MANUAL",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "28-JUL-2023 10:35:35",
          "updated_time": "28-JUL-2023 10:35:35",
          "retrigger_request_time": "2023-07-28T00:00:00",
          "retrigger_status": "REQUESTED",
          "retrigger_by": "uyyogara",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 661,
          "entity": "FX",
          "region": "APAC",
          "report_period": "01-JUL-2023",
          "report_month": "AUG",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-August-2023 13:00",
          "effective_trigger_day": "14-August-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "14-AUG-2023 12:00:10",
          "updated_time": "14-AUG-2023 12:00:10",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 661,
          "entity": "8V",
          "region": "APAC",
          "report_period": "01-JUL-2023",
          "report_month": "AUG",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-August-2023 13:00",
          "effective_trigger_day": "14-August-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "14-AUG-2023 12:00:10",
          "updated_time": "14-AUG-2023 12:00:10",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 661,
          "entity": "B860",
          "region": "APAC",
          "report_period": "01-JUL-2023",
          "report_month": "AUG",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-August-2023 13:00",
          "effective_trigger_day": "14-August-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "14-AUG-2023 12:00:10",
          "updated_time": "14-AUG-2023 12:00:10",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 661,
          "entity": "RT",
          "region": "APAC",
          "report_period": "01-JUL-2023",
          "report_month": "AUG",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-August-2023 13:00",
          "effective_trigger_day": "14-August-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "14-AUG-2023 12:00:10",
          "updated_time": "14-AUG-2023 12:00:10",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 661,
          "entity": "CN_FW_CONSOL_LOCAL",
          "region": "APAC",
          "report_period": "01-JUL-2023",
          "report_month": "AUG",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-August-2023 13:00",
          "effective_trigger_day": "14-August-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "14-AUG-2023 12:00:10",
          "updated_time": "14-AUG-2023 12:00:10",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 487,
          "entity": "HA",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "10-July-2023 19:45",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "COMPLETED",
          "trigger_request_type": "MANUAL",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "11-07-2023 02:47:23 AM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "10-JUL-2023 06:45:05",
          "updated_time": "10-JUL-2023 06:45:05",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "ngutina",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 487,
          "entity": "JG",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "10-July-2023 19:45",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "COMPLETED",
          "trigger_request_type": "MANUAL",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "11-07-2023 02:47:23 AM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "10-JUL-2023 06:45:05",
          "updated_time": "10-JUL-2023 06:45:05",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "ngutina",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 487,
          "entity": "CP",
          "region": "LATAM_North",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "15",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "10-July-2023 19:45",
          "effective_trigger_day": "21-June-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "MANUAL",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "11-07-2023 02:47:23 AM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "10-JUL-2023 06:45:05",
          "updated_time": "10-JUL-2023 06:45:05",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "ngutina",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 487,
          "entity": "6C",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "10-July-2023 19:45",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "COMPLETED",
          "trigger_request_type": "MANUAL",
          "local_gaap_etb_total": "0.02000000",
          "us_gaap_etb_total": "0.01000000",
          "lg_etb_total": "0.01000000",
          "transformation_time": "11-07-2023 02:47:23 AM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "10-JUL-2023 06:45:05",
          "updated_time": "10-JUL-2023 06:45:05",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "ngutina",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 487,
          "entity": "B179",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "10-July-2023 19:45",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "COMPLETED",
          "trigger_request_type": "MANUAL",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "11-07-2023 02:47:23 AM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "10-JUL-2023 06:45:05",
          "updated_time": "10-JUL-2023 06:45:05",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "ngutina",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 487,
          "entity": "4E",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "10-July-2023 19:45",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "COMPLETED",
          "trigger_request_type": "MANUAL",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "11-07-2023 02:47:23 AM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "10-JUL-2023 06:45:05",
          "updated_time": "10-JUL-2023 06:45:05",
          "retrigger_request_time": "2023-07-13T00:00:00",
          "retrigger_status": "REQUESTED",
          "retrigger_by": "ngutina",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 488,
          "entity": "1I",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "12-July-2023 23:00",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "COMPLETED",
          "trigger_request_type": "MANUAL",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "13-07-2023 06:02:03 AM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "12-JUL-2023 10:00:07",
          "updated_time": "12-JUL-2023 10:00:07",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "huitingt",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 488,
          "entity": "4E",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "12-July-2023 23:00",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "COMPLETED",
          "trigger_request_type": "MANUAL",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "13-07-2023 06:02:03 AM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "12-JUL-2023 10:00:07",
          "updated_time": "12-JUL-2023 10:00:07",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "ngutina",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 488,
          "entity": "4G",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "12-July-2023 23:00",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "COMPLETED",
          "trigger_request_type": "MANUAL",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "13-07-2023 06:02:03 AM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "12-JUL-2023 10:00:07",
          "updated_time": "12-JUL-2023 10:00:07",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "ngutina",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 488,
          "entity": "1Y",
          "region": "APAC",
          "report_period": "01-MAY-2023",
          "report_month": "JUN",
          "trigger_day": "10",
          "trigger_time_pst": "11:59",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "12-July-2023 23:00",
          "effective_trigger_day": "14-June-2023 11:00",
          "status": "FAILED",
          "trigger_request_type": "MANUAL",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "12-JUL-2023 10:00:07",
          "updated_time": "12-JUL-2023 10:00:07",
          "retrigger_request_time": "2023-07-19T00:00:00",
          "retrigger_status": "REQUESTED",
          "retrigger_by": "marigala",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 493,
          "entity": "RT",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "18-July-2023 13:00",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "19-07-2023 07:33:33 AM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "18-JUL-2023 12:00:09",
          "updated_time": "18-JUL-2023 12:00:09",
          "retrigger_request_time": "2023-07-24T00:00:00",
          "retrigger_status": "REQUESTED",
          "retrigger_by": "koaysiu",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 505,
          "entity": "MH",
          "region": "LATAM_North",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "24-July-2023 18:45",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "24-JUL-2023 05:45:07",
          "updated_time": "24-JUL-2023 05:45:07",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 505,
          "entity": "MH",
          "region": "LATAM_North",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "24-July-2023 18:45",
          "effective_trigger_day": "17-July-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "24-JUL-2023 05:45:07",
          "updated_time": "24-JUL-2023 05:45:07",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 629,
          "entity": "5C",
          "region": "LATAM_North",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "16",
          "trigger_time_pst": "12:00",
          "etb_version": "Post-Tax, Post-WB",
          "actual_trigger_day": "28-July-2023 11:59",
          "effective_trigger_day": "25-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "MANUAL",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.32000000",
          "lg_etb_total": "-0.32000000",
          "transformation_time": "28-07-2023 19:01:12 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "28-JUL-2023 10:59:55",
          "updated_time": "28-JUL-2023 10:59:55",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "uyyogara",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 661,
          "entity": "6H",
          "region": "APAC",
          "report_period": "01-JUL-2023",
          "report_month": "AUG",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-August-2023 13:00",
          "effective_trigger_day": "14-August-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "14-AUG-2023 12:00:10",
          "updated_time": "14-AUG-2023 12:00:10",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 661,
          "entity": "DX",
          "region": "APAC",
          "report_period": "01-JUL-2023",
          "report_month": "AUG",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-August-2023 13:00",
          "effective_trigger_day": "14-August-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "14-AUG-2023 12:00:10",
          "updated_time": "14-AUG-2023 12:00:10",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 661,
          "entity": "DZ",
          "region": "APAC",
          "report_period": "01-JUL-2023",
          "report_month": "AUG",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-August-2023 13:00",
          "effective_trigger_day": "14-August-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "14-AUG-2023 12:00:10",
          "updated_time": "14-AUG-2023 12:00:10",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 661,
          "entity": "Z4",
          "region": "APAC",
          "report_period": "01-JUL-2023",
          "report_month": "AUG",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-August-2023 13:00",
          "effective_trigger_day": "14-August-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "14-AUG-2023 12:00:10",
          "updated_time": "14-AUG-2023 12:00:10",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 661,
          "entity": "B872",
          "region": "APAC",
          "report_period": "01-JUL-2023",
          "report_month": "AUG",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-August-2023 13:00",
          "effective_trigger_day": "14-August-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "14-AUG-2023 12:00:10",
          "updated_time": "14-AUG-2023 12:00:10",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 661,
          "entity": "B745",
          "region": "APAC",
          "report_period": "01-JUL-2023",
          "report_month": "AUG",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-August-2023 13:00",
          "effective_trigger_day": "14-August-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "14-AUG-2023 12:00:10",
          "updated_time": "14-AUG-2023 12:00:10",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 661,
          "entity": "KA",
          "region": "APAC",
          "report_period": "01-JUL-2023",
          "report_month": "AUG",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-August-2023 13:00",
          "effective_trigger_day": "14-August-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "14-AUG-2023 12:00:10",
          "updated_time": "14-AUG-2023 12:00:10",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 661,
          "entity": "B644",
          "region": "APAC",
          "report_period": "01-JUL-2023",
          "report_month": "AUG",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-August-2023 13:00",
          "effective_trigger_day": "14-August-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "14-AUG-2023 12:00:10",
          "updated_time": "14-AUG-2023 12:00:10",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 661,
          "entity": "B676",
          "region": "APAC",
          "report_period": "01-JUL-2023",
          "report_month": "AUG",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-August-2023 13:00",
          "effective_trigger_day": "14-August-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "14-AUG-2023 12:00:10",
          "updated_time": "14-AUG-2023 12:00:10",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 489,
          "entity": "B180",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "13-July-2023 13:00",
          "effective_trigger_day": "13-July-2023 12:00",
          "status": "DELETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "13-07-2023 20:02:31 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "13-JUL-2023 12:00:09",
          "updated_time": "13-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": "",
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 489,
          "entity": "HA",
          "region": "LATAM_North",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "13-July-2023 13:00",
          "effective_trigger_day": "13-July-2023 12:00",
          "status": "DELETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "13-JUL-2023 12:00:09",
          "updated_time": "13-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": "",
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 489,
          "entity": "B106",
          "region": "LATAM_North",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "13-July-2023 13:00",
          "effective_trigger_day": "13-July-2023 12:00",
          "status": "DELETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "13-07-2023 20:02:31 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "13-JUL-2023 12:00:09",
          "updated_time": "13-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": "",
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 489,
          "entity": "SG_AAPR_LOCAL",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "13-July-2023 13:00",
          "effective_trigger_day": "13-July-2023 12:00",
          "status": "DELETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "-206.60000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "-206.60000000",
          "transformation_time": "13-07-2023 20:02:31 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Failed",
          "created_time": "13-JUL-2023 12:00:09",
          "updated_time": "13-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": "",
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 489,
          "entity": "DW",
          "region": "LATAM_North",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "13-July-2023 13:00",
          "effective_trigger_day": "13-July-2023 12:00",
          "status": "DELETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "13-07-2023 20:02:31 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "13-JUL-2023 12:00:09",
          "updated_time": "13-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": "",
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 661,
          "entity": "CN_CNJV_LOCAL",
          "region": "APAC",
          "report_period": "01-JUL-2023",
          "report_month": "AUG",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-August-2023 13:00",
          "effective_trigger_day": "14-August-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "14-AUG-2023 12:00:10",
          "updated_time": "14-AUG-2023 12:00:10",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 661,
          "entity": "63",
          "region": "APAC",
          "report_period": "01-JUL-2023",
          "report_month": "AUG",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-August-2023 13:00",
          "effective_trigger_day": "14-August-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "14-AUG-2023 12:00:10",
          "updated_time": "14-AUG-2023 12:00:10",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 661,
          "entity": "KB",
          "region": "APAC",
          "report_period": "01-JUL-2023",
          "report_month": "AUG",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-August-2023 13:00",
          "effective_trigger_day": "14-August-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "14-AUG-2023 12:00:10",
          "updated_time": "14-AUG-2023 12:00:10",
          "retrigger_request_time": "2023-08-24T00:00:00",
          "retrigger_status": "REQUESTED",
          "retrigger_by": "tezkhamz",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 489,
          "entity": "CP",
          "region": "LATAM_North",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "13-July-2023 13:00",
          "effective_trigger_day": "13-July-2023 12:00",
          "status": "DELETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "13-07-2023 20:02:31 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "13-JUL-2023 12:00:09",
          "updated_time": "13-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": "",
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 489,
          "entity": "4E",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "13-July-2023 13:00",
          "effective_trigger_day": "13-July-2023 12:00",
          "status": "DELETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "13-07-2023 20:02:31 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "13-JUL-2023 12:00:09",
          "updated_time": "13-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": "",
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 489,
          "entity": "HA",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "13-July-2023 13:00",
          "effective_trigger_day": "13-July-2023 12:00",
          "status": "DELETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "-0.01000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "-0.01000000",
          "transformation_time": "13-07-2023 20:02:31 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "13-JUL-2023 12:00:09",
          "updated_time": "13-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": "",
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 489,
          "entity": "B184",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "13-July-2023 13:00",
          "effective_trigger_day": "13-July-2023 12:00",
          "status": "DELETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "13-07-2023 20:02:31 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "13-JUL-2023 12:00:09",
          "updated_time": "13-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": "",
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 489,
          "entity": "MS",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "13-July-2023 13:00",
          "effective_trigger_day": "13-July-2023 12:00",
          "status": "DELETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "13-JUL-2023 12:00:09",
          "updated_time": "13-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": "",
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 498,
          "entity": "RT",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "24-July-2023 07:15",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "MANUAL",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "24-JUL-2023 06:15:08",
          "updated_time": "24-JUL-2023 06:15:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "koaysiu",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 498,
          "entity": "B872",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "24-July-2023 07:15",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "MANUAL",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "24-JUL-2023 06:15:08",
          "updated_time": "24-JUL-2023 06:15:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "koaysiu",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 498,
          "entity": "HS",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "24-July-2023 07:15",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "MANUAL",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "24-JUL-2023 06:15:08",
          "updated_time": "24-JUL-2023 06:15:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "koaysiu",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 493,
          "entity": "TM",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "18-July-2023 13:00",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.01000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.01000000",
          "transformation_time": "19-07-2023 07:33:33 AM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "18-JUL-2023 12:00:09",
          "updated_time": "18-JUL-2023 12:00:09",
          "retrigger_request_time": "2023-07-25T00:00:00",
          "retrigger_status": "REQUESTED",
          "retrigger_by": "elleyang",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 630,
          "entity": "MS",
          "region": "LATAM_North",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "16",
          "trigger_time_pst": "12:00",
          "etb_version": "Post-Tax, Post-WB",
          "actual_trigger_day": "28-July-2023 14:00",
          "effective_trigger_day": "25-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "MANUAL",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.40000000",
          "lg_etb_total": "-0.40000000",
          "transformation_time": "28-07-2023 21:01:28 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "28-JUL-2023 01:00:10",
          "updated_time": "28-JUL-2023 01:00:10",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "uyyogara",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 661,
          "entity": "PN",
          "region": "APAC",
          "report_period": "01-JUL-2023",
          "report_month": "AUG",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-August-2023 13:00",
          "effective_trigger_day": "14-August-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "14-AUG-2023 12:00:10",
          "updated_time": "14-AUG-2023 12:00:10",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 661,
          "entity": "B9",
          "region": "APAC",
          "report_period": "01-JUL-2023",
          "report_month": "AUG",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-August-2023 13:00",
          "effective_trigger_day": "14-August-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "14-AUG-2023 12:00:10",
          "updated_time": "14-AUG-2023 12:00:10",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 661,
          "entity": "JG",
          "region": "APAC",
          "report_period": "01-JUL-2023",
          "report_month": "AUG",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-August-2023 13:00",
          "effective_trigger_day": "14-August-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "14-AUG-2023 12:00:10",
          "updated_time": "14-AUG-2023 12:00:10",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 661,
          "entity": "B436",
          "region": "APAC",
          "report_period": "01-JUL-2023",
          "report_month": "AUG",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-August-2023 13:00",
          "effective_trigger_day": "14-August-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "14-AUG-2023 12:00:10",
          "updated_time": "14-AUG-2023 12:00:10",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 661,
          "entity": "7J",
          "region": "APAC",
          "report_period": "01-JUL-2023",
          "report_month": "AUG",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-August-2023 13:00",
          "effective_trigger_day": "14-August-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "14-AUG-2023 12:00:10",
          "updated_time": "14-AUG-2023 12:00:10",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 661,
          "entity": "B439",
          "region": "APAC",
          "report_period": "01-JUL-2023",
          "report_month": "AUG",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-August-2023 13:00",
          "effective_trigger_day": "14-August-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "14-AUG-2023 12:00:10",
          "updated_time": "14-AUG-2023 12:00:10",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 661,
          "entity": "B422",
          "region": "APAC",
          "report_period": "01-JUL-2023",
          "report_month": "AUG",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-August-2023 13:00",
          "effective_trigger_day": "14-August-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "14-AUG-2023 12:00:10",
          "updated_time": "14-AUG-2023 12:00:10",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 661,
          "entity": "HA",
          "region": "APAC",
          "report_period": "01-JUL-2023",
          "report_month": "AUG",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-August-2023 13:00",
          "effective_trigger_day": "14-August-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "14-AUG-2023 12:00:10",
          "updated_time": "14-AUG-2023 12:00:10",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 661,
          "entity": "EA",
          "region": "APAC",
          "report_period": "01-JUL-2023",
          "report_month": "AUG",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-August-2023 13:00",
          "effective_trigger_day": "14-August-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "14-AUG-2023 12:00:10",
          "updated_time": "14-AUG-2023 12:00:10",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 661,
          "entity": "B869",
          "region": "APAC",
          "report_period": "01-JUL-2023",
          "report_month": "AUG",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-August-2023 13:00",
          "effective_trigger_day": "14-August-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "14-AUG-2023 12:00:10",
          "updated_time": "14-AUG-2023 12:00:10",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 661,
          "entity": "CN_CNAICSCL_LOCAL",
          "region": "APAC",
          "report_period": "01-JUL-2023",
          "report_month": "AUG",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-August-2023 13:00",
          "effective_trigger_day": "14-August-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "14-AUG-2023 12:00:10",
          "updated_time": "14-AUG-2023 12:00:10",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 661,
          "entity": "B184",
          "region": "APAC",
          "report_period": "01-JUL-2023",
          "report_month": "AUG",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-August-2023 13:00",
          "effective_trigger_day": "14-August-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "14-AUG-2023 12:00:10",
          "updated_time": "14-AUG-2023 12:00:10",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 661,
          "entity": "NX",
          "region": "APAC",
          "report_period": "01-JUL-2023",
          "report_month": "AUG",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-August-2023 13:00",
          "effective_trigger_day": "14-August-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "14-AUG-2023 12:00:10",
          "updated_time": "14-AUG-2023 12:00:10",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 661,
          "entity": "B743",
          "region": "APAC",
          "report_period": "01-JUL-2023",
          "report_month": "AUG",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-August-2023 13:00",
          "effective_trigger_day": "14-August-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "14-AUG-2023 12:00:10",
          "updated_time": "14-AUG-2023 12:00:10",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 661,
          "entity": "HS",
          "region": "APAC",
          "report_period": "01-JUL-2023",
          "report_month": "AUG",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-August-2023 13:00",
          "effective_trigger_day": "14-August-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "14-AUG-2023 12:00:10",
          "updated_time": "14-AUG-2023 12:00:10",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 661,
          "entity": "6C",
          "region": "APAC",
          "report_period": "01-JUL-2023",
          "report_month": "AUG",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-August-2023 13:00",
          "effective_trigger_day": "14-August-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "14-AUG-2023 12:00:10",
          "updated_time": "14-AUG-2023 12:00:10",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 661,
          "entity": "L8",
          "region": "APAC",
          "report_period": "01-JUL-2023",
          "report_month": "AUG",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-August-2023 13:00",
          "effective_trigger_day": "14-August-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "14-AUG-2023 12:00:10",
          "updated_time": "14-AUG-2023 12:00:10",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 661,
          "entity": "DG",
          "region": "APAC",
          "report_period": "01-JUL-2023",
          "report_month": "AUG",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-August-2023 13:00",
          "effective_trigger_day": "14-August-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "14-AUG-2023 12:00:10",
          "updated_time": "14-AUG-2023 12:00:10",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 661,
          "entity": "ML",
          "region": "APAC",
          "report_period": "01-JUL-2023",
          "report_month": "AUG",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-August-2023 13:00",
          "effective_trigger_day": "14-August-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "14-AUG-2023 12:00:10",
          "updated_time": "14-AUG-2023 12:00:10",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 661,
          "entity": "8W",
          "region": "APAC",
          "report_period": "01-JUL-2023",
          "report_month": "AUG",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-August-2023 13:00",
          "effective_trigger_day": "14-August-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "14-AUG-2023 12:00:10",
          "updated_time": "14-AUG-2023 12:00:10",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 661,
          "entity": "CN_CNWFOE_LOCAL",
          "region": "APAC",
          "report_period": "01-JUL-2023",
          "report_month": "AUG",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-August-2023 13:00",
          "effective_trigger_day": "14-August-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "14-AUG-2023 12:00:10",
          "updated_time": "14-AUG-2023 12:00:10",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 661,
          "entity": "B757",
          "region": "APAC",
          "report_period": "01-JUL-2023",
          "report_month": "AUG",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-August-2023 13:00",
          "effective_trigger_day": "14-August-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "14-AUG-2023 12:00:10",
          "updated_time": "14-AUG-2023 12:00:10",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 661,
          "entity": "V8",
          "region": "APAC",
          "report_period": "01-JUL-2023",
          "report_month": "AUG",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-August-2023 13:00",
          "effective_trigger_day": "14-August-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "14-AUG-2023 12:00:10",
          "updated_time": "14-AUG-2023 12:00:10",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 661,
          "entity": "B112",
          "region": "APAC",
          "report_period": "01-JUL-2023",
          "report_month": "AUG",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-August-2023 13:00",
          "effective_trigger_day": "14-August-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "14-AUG-2023 12:00:10",
          "updated_time": "14-AUG-2023 12:00:10",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 661,
          "entity": "65",
          "region": "APAC",
          "report_period": "01-JUL-2023",
          "report_month": "AUG",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-August-2023 13:00",
          "effective_trigger_day": "14-August-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "14-AUG-2023 12:00:10",
          "updated_time": "14-AUG-2023 12:00:10",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 661,
          "entity": "SG_AAPR_LOCAL",
          "region": "APAC",
          "report_period": "01-JUL-2023",
          "report_month": "AUG",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-August-2023 13:00",
          "effective_trigger_day": "14-August-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "14-AUG-2023 12:00:10",
          "updated_time": "14-AUG-2023 12:00:10",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 661,
          "entity": "PH_AOSP_LOCAL",
          "region": "APAC",
          "report_period": "01-JUL-2023",
          "report_month": "AUG",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-August-2023 13:00",
          "effective_trigger_day": "14-August-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "14-AUG-2023 12:00:10",
          "updated_time": "14-AUG-2023 12:00:10",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 661,
          "entity": "B583",
          "region": "APAC",
          "report_period": "01-JUL-2023",
          "report_month": "AUG",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-August-2023 13:00",
          "effective_trigger_day": "14-August-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "14-AUG-2023 12:00:10",
          "updated_time": "14-AUG-2023 12:00:10",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 661,
          "entity": "2I",
          "region": "APAC",
          "report_period": "01-JUL-2023",
          "report_month": "AUG",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-August-2023 13:00",
          "effective_trigger_day": "14-August-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "14-AUG-2023 12:00:10",
          "updated_time": "14-AUG-2023 12:00:10",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 661,
          "entity": "TM",
          "region": "APAC",
          "report_period": "01-JUL-2023",
          "report_month": "AUG",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-August-2023 13:00",
          "effective_trigger_day": "14-August-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "14-AUG-2023 12:00:10",
          "updated_time": "14-AUG-2023 12:00:10",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 661,
          "entity": "LK",
          "region": "APAC",
          "report_period": "01-JUL-2023",
          "report_month": "AUG",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-August-2023 13:00",
          "effective_trigger_day": "14-August-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "14-AUG-2023 12:00:10",
          "updated_time": "14-AUG-2023 12:00:10",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 661,
          "entity": "69",
          "region": "APAC",
          "report_period": "01-JUL-2023",
          "report_month": "AUG",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-August-2023 13:00",
          "effective_trigger_day": "14-August-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "14-AUG-2023 12:00:10",
          "updated_time": "14-AUG-2023 12:00:10",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 661,
          "entity": "CN_CNICP_LOCAL",
          "region": "APAC",
          "report_period": "01-JUL-2023",
          "report_month": "AUG",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-August-2023 13:00",
          "effective_trigger_day": "14-August-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "14-AUG-2023 12:00:10",
          "updated_time": "14-AUG-2023 12:00:10",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 661,
          "entity": "B180",
          "region": "APAC",
          "report_period": "01-JUL-2023",
          "report_month": "AUG",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-August-2023 13:00",
          "effective_trigger_day": "14-August-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "14-AUG-2023 12:00:10",
          "updated_time": "14-AUG-2023 12:00:10",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 661,
          "entity": "9T",
          "region": "APAC",
          "report_period": "01-JUL-2023",
          "report_month": "AUG",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-August-2023 13:00",
          "effective_trigger_day": "14-August-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "14-AUG-2023 12:00:10",
          "updated_time": "14-AUG-2023 12:00:10",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 529,
          "entity": "B162",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "16",
          "trigger_time_pst": "12:00",
          "etb_version": "Post-Tax, Post-WB",
          "actual_trigger_day": "25-July-2023 13:00",
          "effective_trigger_day": "25-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "27-07-2023 21:25:23 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "25-JUL-2023 12:00:08",
          "updated_time": "25-JUL-2023 12:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 493,
          "entity": "7G",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "18-July-2023 13:00",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "19-07-2023 07:33:33 AM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "18-JUL-2023 12:00:09",
          "updated_time": "18-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 499,
          "entity": "FW",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "24-July-2023 07:30",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "24-JUL-2023 06:30:08",
          "updated_time": "24-JUL-2023 06:30:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 499,
          "entity": "FW",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "24-July-2023 07:30",
          "effective_trigger_day": "17-July-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "24-JUL-2023 06:30:08",
          "updated_time": "24-JUL-2023 06:30:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 506,
          "entity": "Z4",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "25-July-2023 00:45",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "MANUAL",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "24-JUL-2023 11:45:07",
          "updated_time": "24-JUL-2023 11:45:07",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "elleyang",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 506,
          "entity": "KB",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "25-July-2023 00:45",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "MANUAL",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "24-JUL-2023 11:45:07",
          "updated_time": "24-JUL-2023 11:45:07",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "elleyang",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 506,
          "entity": "9T_CONS",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "25-July-2023 00:45",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "MANUAL",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "24-JUL-2023 11:45:07",
          "updated_time": "24-JUL-2023 11:45:07",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "elleyang",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 506,
          "entity": "TM",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "25-July-2023 00:45",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "MANUAL",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "24-JUL-2023 11:45:07",
          "updated_time": "24-JUL-2023 11:45:07",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "elleyang",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 506,
          "entity": "55_CONS",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "25-July-2023 00:45",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "MANUAL",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "24-JUL-2023 11:45:07",
          "updated_time": "24-JUL-2023 11:45:07",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "elleyang",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 529,
          "entity": "GC",
          "region": "LATAM_North",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "16",
          "trigger_time_pst": "12:00",
          "etb_version": "Post-Tax, Post-WB",
          "actual_trigger_day": "25-July-2023 13:00",
          "effective_trigger_day": "25-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "27-07-2023 21:25:23 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "25-JUL-2023 12:00:08",
          "updated_time": "25-JUL-2023 12:00:08",
          "retrigger_request_time": "2023-07-28T00:00:00",
          "retrigger_status": "REQUESTED",
          "retrigger_by": "laraenri",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 661,
          "entity": "SG_AAPH_LOCAL",
          "region": "APAC",
          "report_period": "01-JUL-2023",
          "report_month": "AUG",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-August-2023 13:00",
          "effective_trigger_day": "14-August-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "14-AUG-2023 12:00:10",
          "updated_time": "14-AUG-2023 12:00:10",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 661,
          "entity": "B645",
          "region": "APAC",
          "report_period": "01-JUL-2023",
          "report_month": "AUG",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-August-2023 13:00",
          "effective_trigger_day": "14-August-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "14-AUG-2023 12:00:10",
          "updated_time": "14-AUG-2023 12:00:10",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 661,
          "entity": "B581",
          "region": "APAC",
          "report_period": "01-JUL-2023",
          "report_month": "AUG",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-August-2023 13:00",
          "effective_trigger_day": "14-August-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "14-AUG-2023 12:00:10",
          "updated_time": "14-AUG-2023 12:00:10",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 661,
          "entity": "B199",
          "region": "APAC",
          "report_period": "01-JUL-2023",
          "report_month": "AUG",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-August-2023 13:00",
          "effective_trigger_day": "14-August-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "14-AUG-2023 12:00:10",
          "updated_time": "14-AUG-2023 12:00:10",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 661,
          "entity": "B672",
          "region": "APAC",
          "report_period": "01-JUL-2023",
          "report_month": "AUG",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-August-2023 13:00",
          "effective_trigger_day": "14-August-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "14-AUG-2023 12:00:10",
          "updated_time": "14-AUG-2023 12:00:10",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 661,
          "entity": "QM",
          "region": "APAC",
          "report_period": "01-JUL-2023",
          "report_month": "AUG",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-August-2023 13:00",
          "effective_trigger_day": "14-August-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "14-AUG-2023 12:00:10",
          "updated_time": "14-AUG-2023 12:00:10",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 661,
          "entity": "QL",
          "region": "APAC",
          "report_period": "01-JUL-2023",
          "report_month": "AUG",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-August-2023 13:00",
          "effective_trigger_day": "14-August-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "14-AUG-2023 12:00:10",
          "updated_time": "14-AUG-2023 12:00:10",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 661,
          "entity": "B427",
          "region": "APAC",
          "report_period": "01-JUL-2023",
          "report_month": "AUG",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-August-2023 13:00",
          "effective_trigger_day": "14-August-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "14-AUG-2023 12:00:10",
          "updated_time": "14-AUG-2023 12:00:10",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 661,
          "entity": "1I",
          "region": "APAC",
          "report_period": "01-JUL-2023",
          "report_month": "AUG",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-August-2023 13:00",
          "effective_trigger_day": "14-August-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "14-AUG-2023 12:00:10",
          "updated_time": "14-AUG-2023 12:00:10",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 661,
          "entity": "B400",
          "region": "APAC",
          "report_period": "01-JUL-2023",
          "report_month": "AUG",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-August-2023 13:00",
          "effective_trigger_day": "14-August-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "14-AUG-2023 12:00:10",
          "updated_time": "14-AUG-2023 12:00:10",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 661,
          "entity": "AU_55_CONS_LOCAL",
          "region": "APAC",
          "report_period": "01-JUL-2023",
          "report_month": "AUG",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-August-2023 13:00",
          "effective_trigger_day": "14-August-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "14-AUG-2023 12:00:10",
          "updated_time": "14-AUG-2023 12:00:10",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 661,
          "entity": "8O",
          "region": "APAC",
          "report_period": "01-JUL-2023",
          "report_month": "AUG",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-August-2023 13:00",
          "effective_trigger_day": "14-August-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "14-AUG-2023 12:00:10",
          "updated_time": "14-AUG-2023 12:00:10",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 661,
          "entity": "E3",
          "region": "APAC",
          "report_period": "01-JUL-2023",
          "report_month": "AUG",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-August-2023 13:00",
          "effective_trigger_day": "14-August-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "14-AUG-2023 12:00:10",
          "updated_time": "14-AUG-2023 12:00:10",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 661,
          "entity": "4E",
          "region": "APAC",
          "report_period": "01-JUL-2023",
          "report_month": "AUG",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-August-2023 13:00",
          "effective_trigger_day": "14-August-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "14-AUG-2023 12:00:10",
          "updated_time": "14-AUG-2023 12:00:10",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 661,
          "entity": "HW",
          "region": "APAC",
          "report_period": "01-JUL-2023",
          "report_month": "AUG",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-August-2023 13:00",
          "effective_trigger_day": "14-August-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "14-AUG-2023 12:00:10",
          "updated_time": "14-AUG-2023 12:00:10",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 661,
          "entity": "B776",
          "region": "APAC",
          "report_period": "01-JUL-2023",
          "report_month": "AUG",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-August-2023 13:00",
          "effective_trigger_day": "14-August-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "14-AUG-2023 12:00:10",
          "updated_time": "14-AUG-2023 12:00:10",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 661,
          "entity": "R6",
          "region": "APAC",
          "report_period": "01-JUL-2023",
          "report_month": "AUG",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-August-2023 13:00",
          "effective_trigger_day": "14-August-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "14-AUG-2023 12:00:10",
          "updated_time": "14-AUG-2023 12:00:10",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 661,
          "entity": "MJ",
          "region": "APAC",
          "report_period": "01-JUL-2023",
          "report_month": "AUG",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-August-2023 13:00",
          "effective_trigger_day": "14-August-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "14-AUG-2023 12:00:10",
          "updated_time": "14-AUG-2023 12:00:10",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 661,
          "entity": "B928",
          "region": "APAC",
          "report_period": "01-JUL-2023",
          "report_month": "AUG",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-August-2023 13:00",
          "effective_trigger_day": "14-August-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "14-AUG-2023 12:00:10",
          "updated_time": "14-AUG-2023 12:00:10",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 661,
          "entity": "B859",
          "region": "APAC",
          "report_period": "01-JUL-2023",
          "report_month": "AUG",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-August-2023 13:00",
          "effective_trigger_day": "14-August-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "14-AUG-2023 12:00:10",
          "updated_time": "14-AUG-2023 12:00:10",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 661,
          "entity": "CN_CD_CNY_LOCAL",
          "region": "APAC",
          "report_period": "01-JUL-2023",
          "report_month": "AUG",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-August-2023 13:00",
          "effective_trigger_day": "14-August-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "14-AUG-2023 12:00:10",
          "updated_time": "14-AUG-2023 12:00:10",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 661,
          "entity": "AQ",
          "region": "APAC",
          "report_period": "01-JUL-2023",
          "report_month": "AUG",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-August-2023 13:00",
          "effective_trigger_day": "14-August-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "14-AUG-2023 12:00:10",
          "updated_time": "14-AUG-2023 12:00:10",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 489,
          "entity": "B757",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "13-July-2023 13:00",
          "effective_trigger_day": "13-July-2023 12:00",
          "status": "DELETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "13-07-2023 20:02:31 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "13-JUL-2023 12:00:09",
          "updated_time": "13-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": "",
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 489,
          "entity": "RT",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "13-July-2023 13:00",
          "effective_trigger_day": "13-July-2023 12:00",
          "status": "DELETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "13-07-2023 20:02:31 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "13-JUL-2023 12:00:09",
          "updated_time": "13-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": "",
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 489,
          "entity": "B859",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "13-July-2023 13:00",
          "effective_trigger_day": "13-July-2023 12:00",
          "status": "DELETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "13-07-2023 20:02:31 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "13-JUL-2023 12:00:09",
          "updated_time": "13-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": "",
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 489,
          "entity": "9T_CONS",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "13-July-2023 13:00",
          "effective_trigger_day": "13-July-2023 12:00",
          "status": "DELETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "13-JUL-2023 12:00:09",
          "updated_time": "13-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": "",
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 489,
          "entity": "GC",
          "region": "LATAM_North",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "13-July-2023 13:00",
          "effective_trigger_day": "13-July-2023 12:00",
          "status": "DELETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "13-07-2023 20:02:31 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Failed",
          "created_time": "13-JUL-2023 12:00:09",
          "updated_time": "13-JUL-2023 12:00:09",
          "retrigger_request_time": "2023-07-14T00:00:00",
          "retrigger_status": "",
          "retrigger_by": "uyyogara",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 661,
          "entity": "B451",
          "region": "APAC",
          "report_period": "01-JUL-2023",
          "report_month": "AUG",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-August-2023 13:00",
          "effective_trigger_day": "14-August-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "14-AUG-2023 12:00:10",
          "updated_time": "14-AUG-2023 12:00:10",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 661,
          "entity": "B110",
          "region": "APAC",
          "report_period": "01-JUL-2023",
          "report_month": "AUG",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-August-2023 13:00",
          "effective_trigger_day": "14-August-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "14-AUG-2023 12:00:10",
          "updated_time": "14-AUG-2023 12:00:10",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 661,
          "entity": "B900",
          "region": "APAC",
          "report_period": "01-JUL-2023",
          "report_month": "AUG",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-August-2023 13:00",
          "effective_trigger_day": "14-August-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "14-AUG-2023 12:00:10",
          "updated_time": "14-AUG-2023 12:00:10",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 661,
          "entity": "7G",
          "region": "APAC",
          "report_period": "01-JUL-2023",
          "report_month": "AUG",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-August-2023 13:00",
          "effective_trigger_day": "14-August-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "14-AUG-2023 12:00:10",
          "updated_time": "14-AUG-2023 12:00:10",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 661,
          "entity": "J3",
          "region": "APAC",
          "report_period": "01-JUL-2023",
          "report_month": "AUG",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-August-2023 13:00",
          "effective_trigger_day": "14-August-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "14-AUG-2023 12:00:10",
          "updated_time": "14-AUG-2023 12:00:10",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 661,
          "entity": "BI",
          "region": "APAC",
          "report_period": "01-JUL-2023",
          "report_month": "AUG",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-August-2023 13:00",
          "effective_trigger_day": "14-August-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "14-AUG-2023 12:00:10",
          "updated_time": "14-AUG-2023 12:00:10",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 661,
          "entity": "4G",
          "region": "APAC",
          "report_period": "01-JUL-2023",
          "report_month": "AUG",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-August-2023 13:00",
          "effective_trigger_day": "14-August-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "14-AUG-2023 12:00:10",
          "updated_time": "14-AUG-2023 12:00:10",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 500,
          "entity": "GC",
          "region": "LATAM_North",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "24-July-2023 08:15",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "MANUAL",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "24-JUL-2023 07:15:07",
          "updated_time": "24-JUL-2023 07:15:07",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "elsane",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 491,
          "entity": "HS",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "17-July-2023 13:00",
          "effective_trigger_day": "17-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "-0.01000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "-0.02000000",
          "transformation_time": "17-07-2023 20:02:18 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "17-JUL-2023 12:00:09",
          "updated_time": "17-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 491,
          "entity": "8V",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "17-July-2023 13:00",
          "effective_trigger_day": "17-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "17-07-2023 20:02:18 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "17-JUL-2023 12:00:09",
          "updated_time": "17-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 491,
          "entity": "6C",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "17-July-2023 13:00",
          "effective_trigger_day": "17-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.02000000",
          "us_gaap_etb_total": "0.01000000",
          "lg_etb_total": "0.01000000",
          "transformation_time": "17-07-2023 20:02:18 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "17-JUL-2023 12:00:09",
          "updated_time": "17-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 491,
          "entity": "TM",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "17-July-2023 13:00",
          "effective_trigger_day": "17-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.01000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.01000000",
          "transformation_time": "17-07-2023 20:02:18 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "17-JUL-2023 12:00:09",
          "updated_time": "17-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 491,
          "entity": "B115",
          "region": "LATAM_North",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "17-July-2023 13:00",
          "effective_trigger_day": "17-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "-0.16000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "-0.16000000",
          "transformation_time": "17-07-2023 20:02:18 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "17-JUL-2023 12:00:09",
          "updated_time": "17-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 491,
          "entity": "B900",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "17-July-2023 13:00",
          "effective_trigger_day": "17-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "17-07-2023 20:02:18 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "17-JUL-2023 12:00:09",
          "updated_time": "17-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 491,
          "entity": "NX",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "17-July-2023 13:00",
          "effective_trigger_day": "17-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "17-07-2023 20:02:18 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "17-JUL-2023 12:00:09",
          "updated_time": "17-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 491,
          "entity": "Z4",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "17-July-2023 13:00",
          "effective_trigger_day": "17-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "17-07-2023 20:02:18 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "17-JUL-2023 12:00:09",
          "updated_time": "17-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 491,
          "entity": "B451",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "17-July-2023 13:00",
          "effective_trigger_day": "17-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "17-07-2023 20:02:18 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "17-JUL-2023 12:00:09",
          "updated_time": "17-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 661,
          "entity": "BW",
          "region": "APAC",
          "report_period": "01-JUL-2023",
          "report_month": "AUG",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-August-2023 13:00",
          "effective_trigger_day": "14-August-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "14-AUG-2023 12:00:10",
          "updated_time": "14-AUG-2023 12:00:10",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 489,
          "entity": "R6",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "13-July-2023 13:00",
          "effective_trigger_day": "13-July-2023 12:00",
          "status": "DELETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "13-07-2023 20:02:31 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "13-JUL-2023 12:00:09",
          "updated_time": "13-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": "",
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 489,
          "entity": "B583",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "13-July-2023 13:00",
          "effective_trigger_day": "13-July-2023 12:00",
          "status": "DELETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "13-07-2023 20:02:31 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "13-JUL-2023 12:00:09",
          "updated_time": "13-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": "",
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 489,
          "entity": "B928",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "13-July-2023 13:00",
          "effective_trigger_day": "13-July-2023 12:00",
          "status": "DELETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "13-07-2023 20:02:31 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "13-JUL-2023 12:00:09",
          "updated_time": "13-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": "",
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 489,
          "entity": "HW",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "13-July-2023 13:00",
          "effective_trigger_day": "13-July-2023 12:00",
          "status": "DELETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "13-07-2023 20:02:31 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "13-JUL-2023 12:00:09",
          "updated_time": "13-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": "",
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 489,
          "entity": "E3",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "13-July-2023 13:00",
          "effective_trigger_day": "13-July-2023 12:00",
          "status": "DELETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "-0.01000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "-0.01000000",
          "transformation_time": "13-07-2023 20:02:31 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "13-JUL-2023 12:00:09",
          "updated_time": "13-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": "",
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 489,
          "entity": "B112",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "13-July-2023 13:00",
          "effective_trigger_day": "13-July-2023 12:00",
          "status": "DELETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "13-07-2023 20:02:31 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "13-JUL-2023 12:00:09",
          "updated_time": "13-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": "",
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 489,
          "entity": "PH_AOSP_LOCAL",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "13-July-2023 13:00",
          "effective_trigger_day": "13-July-2023 12:00",
          "status": "DELETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "-0.02000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "-0.02000000",
          "transformation_time": "13-07-2023 20:02:31 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "13-JUL-2023 12:00:09",
          "updated_time": "13-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": "",
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 489,
          "entity": "B776",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "13-July-2023 13:00",
          "effective_trigger_day": "13-July-2023 12:00",
          "status": "DELETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "13-07-2023 20:02:31 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "13-JUL-2023 12:00:09",
          "updated_time": "13-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": "",
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 489,
          "entity": "2I",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "13-July-2023 13:00",
          "effective_trigger_day": "13-July-2023 12:00",
          "status": "DELETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "13-07-2023 20:02:31 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "13-JUL-2023 12:00:09",
          "updated_time": "13-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": "",
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 489,
          "entity": "7J",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "13-July-2023 13:00",
          "effective_trigger_day": "13-July-2023 12:00",
          "status": "DELETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "13-07-2023 20:02:31 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "13-JUL-2023 12:00:09",
          "updated_time": "13-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": "",
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 489,
          "entity": "B199",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "13-July-2023 13:00",
          "effective_trigger_day": "13-July-2023 12:00",
          "status": "DELETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "13-07-2023 20:02:31 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "13-JUL-2023 12:00:09",
          "updated_time": "13-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": "",
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 489,
          "entity": "MF",
          "region": "LATAM_North",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "13-July-2023 13:00",
          "effective_trigger_day": "13-July-2023 12:00",
          "status": "DELETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "13-07-2023 20:02:31 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "13-JUL-2023 12:00:09",
          "updated_time": "13-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": "",
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 489,
          "entity": "EA",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "13-July-2023 13:00",
          "effective_trigger_day": "13-July-2023 12:00",
          "status": "DELETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "13-07-2023 20:02:31 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "13-JUL-2023 12:00:09",
          "updated_time": "13-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": "",
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 489,
          "entity": "B427",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "13-July-2023 13:00",
          "effective_trigger_day": "13-July-2023 12:00",
          "status": "DELETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "13-07-2023 20:02:31 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "13-JUL-2023 12:00:09",
          "updated_time": "13-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": "",
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 489,
          "entity": "65",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "13-July-2023 13:00",
          "effective_trigger_day": "13-July-2023 12:00",
          "status": "DELETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.01000000",
          "us_gaap_etb_total": "0.01000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "13-07-2023 20:02:31 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "13-JUL-2023 12:00:09",
          "updated_time": "13-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": "",
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 489,
          "entity": "SG_AAPH_LOCAL",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "13-July-2023 13:00",
          "effective_trigger_day": "13-July-2023 12:00",
          "status": "DELETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.01000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "13-07-2023 20:02:31 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "13-JUL-2023 12:00:09",
          "updated_time": "13-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": "",
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 489,
          "entity": "HS",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "13-July-2023 13:00",
          "effective_trigger_day": "13-July-2023 12:00",
          "status": "DELETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "-0.01000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "-0.02000000",
          "transformation_time": "13-07-2023 20:02:31 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "13-JUL-2023 12:00:09",
          "updated_time": "13-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": "",
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 489,
          "entity": "8V",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "13-July-2023 13:00",
          "effective_trigger_day": "13-July-2023 12:00",
          "status": "DELETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "13-07-2023 20:02:31 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "13-JUL-2023 12:00:09",
          "updated_time": "13-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": "",
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 489,
          "entity": "6C",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "13-July-2023 13:00",
          "effective_trigger_day": "13-July-2023 12:00",
          "status": "DELETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.02000000",
          "us_gaap_etb_total": "0.01000000",
          "lg_etb_total": "0.01000000",
          "transformation_time": "13-07-2023 20:02:31 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "13-JUL-2023 12:00:09",
          "updated_time": "13-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": "",
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 489,
          "entity": "TM",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "13-July-2023 13:00",
          "effective_trigger_day": "13-July-2023 12:00",
          "status": "DELETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.01000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.01000000",
          "transformation_time": "13-07-2023 20:02:31 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "13-JUL-2023 12:00:09",
          "updated_time": "13-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": "",
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 489,
          "entity": "B115",
          "region": "LATAM_North",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "13-July-2023 13:00",
          "effective_trigger_day": "13-July-2023 12:00",
          "status": "DELETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "-0.16000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "-0.16000000",
          "transformation_time": "13-07-2023 20:02:31 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "13-JUL-2023 12:00:09",
          "updated_time": "13-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": "",
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 489,
          "entity": "B900",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "13-July-2023 13:00",
          "effective_trigger_day": "13-July-2023 12:00",
          "status": "DELETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "13-07-2023 20:02:31 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "13-JUL-2023 12:00:09",
          "updated_time": "13-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": "",
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 491,
          "entity": "B112",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "17-July-2023 13:00",
          "effective_trigger_day": "17-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "17-07-2023 20:02:18 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "17-JUL-2023 12:00:09",
          "updated_time": "17-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 489,
          "entity": "NX",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "13-July-2023 13:00",
          "effective_trigger_day": "13-July-2023 12:00",
          "status": "DELETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "13-07-2023 20:02:31 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "13-JUL-2023 12:00:09",
          "updated_time": "13-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": "",
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 489,
          "entity": "Z4",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "13-July-2023 13:00",
          "effective_trigger_day": "13-July-2023 12:00",
          "status": "DELETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "13-07-2023 20:02:31 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "13-JUL-2023 12:00:09",
          "updated_time": "13-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": "",
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 489,
          "entity": "B451",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "13-July-2023 13:00",
          "effective_trigger_day": "13-July-2023 12:00",
          "status": "DELETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "13-07-2023 20:02:31 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "13-JUL-2023 12:00:09",
          "updated_time": "13-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": "",
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 489,
          "entity": "B676",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "13-July-2023 13:00",
          "effective_trigger_day": "13-July-2023 12:00",
          "status": "DELETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "13-07-2023 20:02:31 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "13-JUL-2023 12:00:09",
          "updated_time": "13-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": "",
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 489,
          "entity": "M2",
          "region": "LATAM_North",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "13-July-2023 13:00",
          "effective_trigger_day": "13-July-2023 12:00",
          "status": "DELETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "13-07-2023 20:02:31 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "13-JUL-2023 12:00:09",
          "updated_time": "13-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": "",
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 489,
          "entity": "J3",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "13-July-2023 13:00",
          "effective_trigger_day": "13-July-2023 12:00",
          "status": "DELETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "13-07-2023 20:02:31 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "13-JUL-2023 12:00:09",
          "updated_time": "13-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": "",
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 489,
          "entity": "5C",
          "region": "LATAM_North",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "13-July-2023 13:00",
          "effective_trigger_day": "13-July-2023 12:00",
          "status": "DELETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "25713.42000000",
          "lg_etb_total": "-25713.42000000",
          "transformation_time": "13-07-2023 20:02:31 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Failed",
          "created_time": "13-JUL-2023 12:00:09",
          "updated_time": "13-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": "",
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 489,
          "entity": "FX",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "13-July-2023 13:00",
          "effective_trigger_day": "13-July-2023 12:00",
          "status": "DELETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "13-07-2023 20:02:31 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "13-JUL-2023 12:00:09",
          "updated_time": "13-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": "",
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 489,
          "entity": "5Z",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "13-July-2023 13:00",
          "effective_trigger_day": "13-July-2023 12:00",
          "status": "DELETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "13-07-2023 20:02:31 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "13-JUL-2023 12:00:09",
          "updated_time": "13-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": "",
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 489,
          "entity": "65RBK",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "13-July-2023 13:00",
          "effective_trigger_day": "13-July-2023 12:00",
          "status": "DELETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "13-JUL-2023 12:00:09",
          "updated_time": "13-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": "",
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 489,
          "entity": "B179",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "13-July-2023 13:00",
          "effective_trigger_day": "13-July-2023 12:00",
          "status": "DELETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "13-07-2023 20:02:31 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "13-JUL-2023 12:00:09",
          "updated_time": "13-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": "",
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 489,
          "entity": "B622",
          "region": "LATAM_North",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "13-July-2023 13:00",
          "effective_trigger_day": "13-July-2023 12:00",
          "status": "DELETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "13-07-2023 20:02:31 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "13-JUL-2023 12:00:09",
          "updated_time": "13-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": "",
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 489,
          "entity": "B869",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "13-July-2023 13:00",
          "effective_trigger_day": "13-July-2023 12:00",
          "status": "DELETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "13-07-2023 20:02:31 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "13-JUL-2023 12:00:09",
          "updated_time": "13-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": "",
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 489,
          "entity": "B162",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "13-July-2023 13:00",
          "effective_trigger_day": "13-July-2023 12:00",
          "status": "DELETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "13-07-2023 20:02:31 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "13-JUL-2023 12:00:09",
          "updated_time": "13-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": "",
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 489,
          "entity": "BW",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "13-July-2023 13:00",
          "effective_trigger_day": "13-July-2023 12:00",
          "status": "DELETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "13-07-2023 20:02:31 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "13-JUL-2023 12:00:09",
          "updated_time": "13-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": "",
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 489,
          "entity": "B672",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "13-July-2023 13:00",
          "effective_trigger_day": "13-July-2023 12:00",
          "status": "DELETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "13-07-2023 20:02:31 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "13-JUL-2023 12:00:09",
          "updated_time": "13-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": "",
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 489,
          "entity": "CN_CNAICSCL_LOCAL",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "13-July-2023 13:00",
          "effective_trigger_day": "13-July-2023 12:00",
          "status": "DELETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "13-07-2023 20:02:31 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "13-JUL-2023 12:00:09",
          "updated_time": "13-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": "",
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 489,
          "entity": "6H",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "13-July-2023 13:00",
          "effective_trigger_day": "13-July-2023 12:00",
          "status": "DELETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "13-07-2023 20:02:31 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "13-JUL-2023 12:00:09",
          "updated_time": "13-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": "",
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 489,
          "entity": "KA",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "13-July-2023 13:00",
          "effective_trigger_day": "13-July-2023 12:00",
          "status": "DELETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "13-07-2023 20:02:31 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "13-JUL-2023 12:00:09",
          "updated_time": "13-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": "",
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 489,
          "entity": "7G",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "13-July-2023 13:00",
          "effective_trigger_day": "13-July-2023 12:00",
          "status": "DELETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "13-07-2023 20:02:31 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "13-JUL-2023 12:00:09",
          "updated_time": "13-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": "",
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 489,
          "entity": "CN_CNJV_LOCAL",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "13-July-2023 13:00",
          "effective_trigger_day": "13-July-2023 12:00",
          "status": "DELETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "13-07-2023 20:02:31 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "13-JUL-2023 12:00:09",
          "updated_time": "13-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": "",
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 489,
          "entity": "MS",
          "region": "LATAM_North",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "13-July-2023 13:00",
          "effective_trigger_day": "13-July-2023 12:00",
          "status": "DELETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.80000000",
          "lg_etb_total": "-0.80000000",
          "transformation_time": "13-07-2023 20:02:31 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Failed",
          "created_time": "13-JUL-2023 12:00:09",
          "updated_time": "13-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": "",
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 489,
          "entity": "BC61",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "13-July-2023 13:00",
          "effective_trigger_day": "13-July-2023 12:00",
          "status": "DELETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "13-07-2023 20:02:31 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "13-JUL-2023 12:00:09",
          "updated_time": "13-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": "",
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 489,
          "entity": "QM",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "13-July-2023 13:00",
          "effective_trigger_day": "13-July-2023 12:00",
          "status": "DELETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "13-07-2023 20:02:31 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "13-JUL-2023 12:00:09",
          "updated_time": "13-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": "",
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 489,
          "entity": "B9",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "13-July-2023 13:00",
          "effective_trigger_day": "13-July-2023 12:00",
          "status": "DELETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "13-07-2023 20:02:31 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "13-JUL-2023 12:00:09",
          "updated_time": "13-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": "",
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 489,
          "entity": "LK",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "13-July-2023 13:00",
          "effective_trigger_day": "13-July-2023 12:00",
          "status": "DELETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "142113.23000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "142113.23000000",
          "transformation_time": "13-07-2023 20:02:31 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Failed",
          "created_time": "13-JUL-2023 12:00:09",
          "updated_time": "13-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": "",
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 489,
          "entity": "B644",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "13-July-2023 13:00",
          "effective_trigger_day": "13-July-2023 12:00",
          "status": "DELETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "13-07-2023 20:02:31 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "13-JUL-2023 12:00:09",
          "updated_time": "13-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": "",
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 489,
          "entity": "B437",
          "region": "LATAM_North",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "13-July-2023 13:00",
          "effective_trigger_day": "13-July-2023 12:00",
          "status": "DELETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "13-07-2023 20:02:31 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "13-JUL-2023 12:00:09",
          "updated_time": "13-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": "",
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 489,
          "entity": "DX",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "13-July-2023 13:00",
          "effective_trigger_day": "13-July-2023 12:00",
          "status": "DELETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "13-07-2023 20:02:31 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "13-JUL-2023 12:00:09",
          "updated_time": "13-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": "",
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 489,
          "entity": "B581",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "13-July-2023 13:00",
          "effective_trigger_day": "13-July-2023 12:00",
          "status": "DELETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "13-07-2023 20:02:31 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "13-JUL-2023 12:00:09",
          "updated_time": "13-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": "",
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 489,
          "entity": "63",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "13-July-2023 13:00",
          "effective_trigger_day": "13-July-2023 12:00",
          "status": "DELETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.06000000",
          "us_gaap_etb_total": "-0.01000000",
          "lg_etb_total": "0.07000000",
          "transformation_time": "13-07-2023 20:02:31 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "13-JUL-2023 12:00:09",
          "updated_time": "13-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": "",
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 489,
          "entity": "BI",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "13-July-2023 13:00",
          "effective_trigger_day": "13-July-2023 12:00",
          "status": "DELETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "13-07-2023 20:02:31 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "13-JUL-2023 12:00:09",
          "updated_time": "13-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": "",
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 489,
          "entity": "L8",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "13-July-2023 13:00",
          "effective_trigger_day": "13-July-2023 12:00",
          "status": "DELETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "-0.01000000",
          "lg_etb_total": "0.01000000",
          "transformation_time": "13-07-2023 20:02:31 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "13-JUL-2023 12:00:09",
          "updated_time": "13-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": "",
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 489,
          "entity": "AQ",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "13-July-2023 13:00",
          "effective_trigger_day": "13-July-2023 12:00",
          "status": "DELETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "13-07-2023 20:02:31 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "13-JUL-2023 12:00:09",
          "updated_time": "13-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": "",
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 489,
          "entity": "DG",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "13-July-2023 13:00",
          "effective_trigger_day": "13-July-2023 12:00",
          "status": "DELETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "13-07-2023 20:02:31 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "13-JUL-2023 12:00:09",
          "updated_time": "13-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": "",
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 489,
          "entity": "QL",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "13-July-2023 13:00",
          "effective_trigger_day": "13-July-2023 12:00",
          "status": "DELETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.24000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.24000000",
          "transformation_time": "13-07-2023 20:02:31 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "13-JUL-2023 12:00:09",
          "updated_time": "13-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": "",
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 489,
          "entity": "69",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "13-July-2023 13:00",
          "effective_trigger_day": "13-July-2023 12:00",
          "status": "DELETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "13-07-2023 20:02:31 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "13-JUL-2023 12:00:09",
          "updated_time": "13-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": "",
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 489,
          "entity": "MJ",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "13-July-2023 13:00",
          "effective_trigger_day": "13-July-2023 12:00",
          "status": "DELETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "13-07-2023 20:02:31 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "13-JUL-2023 12:00:09",
          "updated_time": "13-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": "",
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 489,
          "entity": "B439",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "13-July-2023 13:00",
          "effective_trigger_day": "13-July-2023 12:00",
          "status": "DELETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "13-07-2023 20:02:31 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "13-JUL-2023 12:00:09",
          "updated_time": "13-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": "",
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 489,
          "entity": "QK",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "13-July-2023 13:00",
          "effective_trigger_day": "13-July-2023 12:00",
          "status": "DELETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "13-07-2023 20:02:31 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "13-JUL-2023 12:00:09",
          "updated_time": "13-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": "",
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 489,
          "entity": "8O",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "13-July-2023 13:00",
          "effective_trigger_day": "13-July-2023 12:00",
          "status": "DELETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "13-07-2023 20:02:31 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "13-JUL-2023 12:00:09",
          "updated_time": "13-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": "",
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 489,
          "entity": "CNWFOE",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "13-July-2023 13:00",
          "effective_trigger_day": "13-July-2023 12:00",
          "status": "DELETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "13-JUL-2023 12:00:09",
          "updated_time": "13-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": "",
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 489,
          "entity": "64",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "13-July-2023 13:00",
          "effective_trigger_day": "13-July-2023 12:00",
          "status": "DELETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "13-07-2023 20:02:31 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "13-JUL-2023 12:00:09",
          "updated_time": "13-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": "",
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 489,
          "entity": "B436",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "13-July-2023 13:00",
          "effective_trigger_day": "13-July-2023 12:00",
          "status": "DELETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "13-07-2023 20:02:31 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "13-JUL-2023 12:00:09",
          "updated_time": "13-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": "",
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 489,
          "entity": "KB",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "13-July-2023 13:00",
          "effective_trigger_day": "13-July-2023 12:00",
          "status": "DELETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "13-07-2023 20:02:31 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "13-JUL-2023 12:00:09",
          "updated_time": "13-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": "",
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 489,
          "entity": "CN_CNWFOE_LOCAL",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "13-July-2023 13:00",
          "effective_trigger_day": "13-July-2023 12:00",
          "status": "DELETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "-0.01000000",
          "lg_etb_total": "0.01000000",
          "transformation_time": "13-07-2023 20:02:31 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "13-JUL-2023 12:00:09",
          "updated_time": "13-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": "",
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 489,
          "entity": "B110",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "13-July-2023 13:00",
          "effective_trigger_day": "13-July-2023 12:00",
          "status": "DELETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "13-07-2023 20:02:31 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "13-JUL-2023 12:00:09",
          "updated_time": "13-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": "",
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 489,
          "entity": "64RBK",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "13-July-2023 13:00",
          "effective_trigger_day": "13-July-2023 12:00",
          "status": "DELETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "13-JUL-2023 12:00:09",
          "updated_time": "13-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": "",
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 489,
          "entity": "63A",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "13-July-2023 13:00",
          "effective_trigger_day": "13-July-2023 12:00",
          "status": "DELETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "13-JUL-2023 12:00:09",
          "updated_time": "13-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": "",
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 489,
          "entity": "MS_CONS",
          "region": "LATAM_North",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "13-July-2023 13:00",
          "effective_trigger_day": "13-July-2023 12:00",
          "status": "DELETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "13-JUL-2023 12:00:09",
          "updated_time": "13-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": "",
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 489,
          "entity": "CNJV",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "13-July-2023 13:00",
          "effective_trigger_day": "13-July-2023 12:00",
          "status": "DELETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "13-JUL-2023 12:00:09",
          "updated_time": "13-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": "",
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 489,
          "entity": "B115",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "13-July-2023 13:00",
          "effective_trigger_day": "13-July-2023 12:00",
          "status": "DELETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "13-JUL-2023 12:00:09",
          "updated_time": "13-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": "",
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 489,
          "entity": "CN_CNICP_LOCAL",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "13-July-2023 13:00",
          "effective_trigger_day": "13-July-2023 12:00",
          "status": "DELETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "-0.02000000",
          "us_gaap_etb_total": "-0.03000000",
          "lg_etb_total": "0.01000000",
          "transformation_time": "13-07-2023 20:02:31 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "13-JUL-2023 12:00:09",
          "updated_time": "13-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": "",
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 489,
          "entity": "55",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "13-July-2023 13:00",
          "effective_trigger_day": "13-July-2023 12:00",
          "status": "DELETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "13-07-2023 20:02:31 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "13-JUL-2023 12:00:09",
          "updated_time": "13-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": "",
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 489,
          "entity": "B860",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "13-July-2023 13:00",
          "effective_trigger_day": "13-July-2023 12:00",
          "status": "DELETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "13-07-2023 20:02:31 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "13-JUL-2023 12:00:09",
          "updated_time": "13-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": "",
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 489,
          "entity": "AU_55_CONS_LOCAL",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "13-July-2023 13:00",
          "effective_trigger_day": "13-July-2023 12:00",
          "status": "DELETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "13-07-2023 20:02:31 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "13-JUL-2023 12:00:09",
          "updated_time": "13-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": "",
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 489,
          "entity": "B872",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "13-July-2023 13:00",
          "effective_trigger_day": "13-July-2023 12:00",
          "status": "DELETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "13-07-2023 20:02:31 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "13-JUL-2023 12:00:09",
          "updated_time": "13-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": "",
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 489,
          "entity": "JG",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "13-July-2023 13:00",
          "effective_trigger_day": "13-July-2023 12:00",
          "status": "DELETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "13-07-2023 20:02:31 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "13-JUL-2023 12:00:09",
          "updated_time": "13-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": "",
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 489,
          "entity": "9T",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "13-July-2023 13:00",
          "effective_trigger_day": "13-July-2023 12:00",
          "status": "DELETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "-206.60000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "-206.60000000",
          "transformation_time": "13-07-2023 20:02:31 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Failed",
          "created_time": "13-JUL-2023 12:00:09",
          "updated_time": "13-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": "",
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 489,
          "entity": "DZ",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "13-July-2023 13:00",
          "effective_trigger_day": "13-July-2023 12:00",
          "status": "DELETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "13-07-2023 20:02:31 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "13-JUL-2023 12:00:09",
          "updated_time": "13-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": "",
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 489,
          "entity": "V8",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "13-July-2023 13:00",
          "effective_trigger_day": "13-July-2023 12:00",
          "status": "DELETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "13-07-2023 20:02:31 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "13-JUL-2023 12:00:09",
          "updated_time": "13-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": "",
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 489,
          "entity": "B422",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "13-July-2023 13:00",
          "effective_trigger_day": "13-July-2023 12:00",
          "status": "DELETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "13-07-2023 20:02:31 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "13-JUL-2023 12:00:09",
          "updated_time": "13-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": "",
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 489,
          "entity": "B645",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "13-July-2023 13:00",
          "effective_trigger_day": "13-July-2023 12:00",
          "status": "DELETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "13-07-2023 20:02:31 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "13-JUL-2023 12:00:09",
          "updated_time": "13-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": "",
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 491,
          "entity": "B872",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "17-July-2023 13:00",
          "effective_trigger_day": "17-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "17-07-2023 20:02:18 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "17-JUL-2023 12:00:09",
          "updated_time": "17-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 491,
          "entity": "JG",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "17-July-2023 13:00",
          "effective_trigger_day": "17-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "17-07-2023 20:02:18 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "17-JUL-2023 12:00:09",
          "updated_time": "17-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 491,
          "entity": "9T",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "17-July-2023 13:00",
          "effective_trigger_day": "17-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "-0.01000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "-0.01000000",
          "transformation_time": "17-07-2023 20:02:18 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "17-JUL-2023 12:00:09",
          "updated_time": "17-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 501,
          "entity": "MS",
          "region": "LATAM_North",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "24-July-2023 08:30",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "MANUAL",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "24-JUL-2023 07:30:07",
          "updated_time": "24-JUL-2023 07:30:07",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "elsane",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 489,
          "entity": "ML",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "13-July-2023 13:00",
          "effective_trigger_day": "13-July-2023 12:00",
          "status": "DELETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "13-07-2023 20:02:31 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "13-JUL-2023 12:00:09",
          "updated_time": "13-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": "",
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 489,
          "entity": "4G",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "13-July-2023 13:00",
          "effective_trigger_day": "13-July-2023 12:00",
          "status": "DELETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "13-07-2023 20:02:31 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "13-JUL-2023 12:00:09",
          "updated_time": "13-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": "",
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 489,
          "entity": "MR",
          "region": "LATAM_North",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "13-July-2023 13:00",
          "effective_trigger_day": "13-July-2023 12:00",
          "status": "DELETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "13-07-2023 20:02:31 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "13-JUL-2023 12:00:09",
          "updated_time": "13-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": "",
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 489,
          "entity": "8W",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "13-July-2023 13:00",
          "effective_trigger_day": "13-July-2023 12:00",
          "status": "DELETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "13-07-2023 20:02:31 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "13-JUL-2023 12:00:09",
          "updated_time": "13-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": "",
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 489,
          "entity": "B743",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "13-July-2023 13:00",
          "effective_trigger_day": "13-July-2023 12:00",
          "status": "DELETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "13-07-2023 20:02:31 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "13-JUL-2023 12:00:09",
          "updated_time": "13-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": "",
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 489,
          "entity": "CNICP",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "13-July-2023 13:00",
          "effective_trigger_day": "13-July-2023 12:00",
          "status": "DELETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "13-JUL-2023 12:00:09",
          "updated_time": "13-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": "",
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 489,
          "entity": "75",
          "region": "LATAM_North",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "13-July-2023 13:00",
          "effective_trigger_day": "13-July-2023 12:00",
          "status": "DELETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "13-07-2023 20:02:31 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "13-JUL-2023 12:00:09",
          "updated_time": "13-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": "",
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 489,
          "entity": "B400",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "13-July-2023 13:00",
          "effective_trigger_day": "13-July-2023 12:00",
          "status": "DELETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "13-07-2023 20:02:31 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "13-JUL-2023 12:00:09",
          "updated_time": "13-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": "",
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 489,
          "entity": "PN",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "13-July-2023 13:00",
          "effective_trigger_day": "13-July-2023 12:00",
          "status": "DELETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "13-07-2023 20:02:31 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "13-JUL-2023 12:00:09",
          "updated_time": "13-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": "",
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 489,
          "entity": "B745",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "13-July-2023 13:00",
          "effective_trigger_day": "13-July-2023 12:00",
          "status": "DELETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "13-07-2023 20:02:31 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "13-JUL-2023 12:00:09",
          "updated_time": "13-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": "",
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 489,
          "entity": "B423",
          "region": "LATAM_North",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "13-July-2023 13:00",
          "effective_trigger_day": "13-July-2023 12:00",
          "status": "DELETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.32000000",
          "us_gaap_etb_total": "0.32000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "13-07-2023 20:02:31 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "13-JUL-2023 12:00:09",
          "updated_time": "13-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": "",
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 489,
          "entity": "1I",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "13-July-2023 13:00",
          "effective_trigger_day": "13-July-2023 12:00",
          "status": "DELETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "13-07-2023 20:02:31 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "13-JUL-2023 12:00:09",
          "updated_time": "13-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": "",
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 489,
          "entity": "B407",
          "region": "LATAM_North",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "13-July-2023 13:00",
          "effective_trigger_day": "13-July-2023 12:00",
          "status": "DELETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "13-07-2023 20:02:31 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "13-JUL-2023 12:00:09",
          "updated_time": "13-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": "",
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 489,
          "entity": "CN_FW_CONSOL_LOCAL",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "13-July-2023 13:00",
          "effective_trigger_day": "13-July-2023 12:00",
          "status": "DELETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "13-07-2023 20:02:31 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "13-JUL-2023 12:00:09",
          "updated_time": "13-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": "",
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 491,
          "entity": "DZ",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "17-July-2023 13:00",
          "effective_trigger_day": "17-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "17-07-2023 20:02:18 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "17-JUL-2023 12:00:09",
          "updated_time": "17-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 491,
          "entity": "V8",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "17-July-2023 13:00",
          "effective_trigger_day": "17-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "17-07-2023 20:02:18 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "17-JUL-2023 12:00:09",
          "updated_time": "17-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 491,
          "entity": "B422",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "17-July-2023 13:00",
          "effective_trigger_day": "17-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "17-07-2023 20:02:18 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "17-JUL-2023 12:00:09",
          "updated_time": "17-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 491,
          "entity": "B180",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "17-July-2023 13:00",
          "effective_trigger_day": "17-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "17-07-2023 20:02:18 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "17-JUL-2023 12:00:09",
          "updated_time": "17-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 491,
          "entity": "64",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "17-July-2023 13:00",
          "effective_trigger_day": "17-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "17-07-2023 20:02:18 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "17-JUL-2023 12:00:09",
          "updated_time": "17-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 491,
          "entity": "B436",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "17-July-2023 13:00",
          "effective_trigger_day": "17-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "17-07-2023 20:02:18 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "17-JUL-2023 12:00:09",
          "updated_time": "17-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 491,
          "entity": "KB",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "17-July-2023 13:00",
          "effective_trigger_day": "17-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "17-07-2023 20:02:18 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "17-JUL-2023 12:00:09",
          "updated_time": "17-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 491,
          "entity": "CN_CNWFOE_LOCAL",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "17-July-2023 13:00",
          "effective_trigger_day": "17-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "-0.01000000",
          "lg_etb_total": "0.01000000",
          "transformation_time": "17-07-2023 20:02:18 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "17-JUL-2023 12:00:09",
          "updated_time": "17-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 491,
          "entity": "ML",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "17-July-2023 13:00",
          "effective_trigger_day": "17-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "17-07-2023 20:02:18 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "17-JUL-2023 12:00:09",
          "updated_time": "17-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 491,
          "entity": "4G",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "17-July-2023 13:00",
          "effective_trigger_day": "17-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "17-07-2023 20:02:18 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "17-JUL-2023 12:00:09",
          "updated_time": "17-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 491,
          "entity": "MR",
          "region": "LATAM_North",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "17-July-2023 13:00",
          "effective_trigger_day": "17-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "17-07-2023 20:02:18 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "17-JUL-2023 12:00:09",
          "updated_time": "17-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 491,
          "entity": "8W",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "17-July-2023 13:00",
          "effective_trigger_day": "17-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "17-07-2023 20:02:18 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "17-JUL-2023 12:00:09",
          "updated_time": "17-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 491,
          "entity": "B743",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "17-July-2023 13:00",
          "effective_trigger_day": "17-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "17-07-2023 20:02:18 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "17-JUL-2023 12:00:09",
          "updated_time": "17-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 491,
          "entity": "R6",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "17-July-2023 13:00",
          "effective_trigger_day": "17-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "17-07-2023 20:02:18 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "17-JUL-2023 12:00:09",
          "updated_time": "17-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 491,
          "entity": "B583",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "17-July-2023 13:00",
          "effective_trigger_day": "17-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "17-07-2023 20:02:18 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "17-JUL-2023 12:00:09",
          "updated_time": "17-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 491,
          "entity": "B928",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "17-July-2023 13:00",
          "effective_trigger_day": "17-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "17-07-2023 20:02:18 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "17-JUL-2023 12:00:09",
          "updated_time": "17-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 491,
          "entity": "HW",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "17-July-2023 13:00",
          "effective_trigger_day": "17-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "17-07-2023 20:02:18 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "17-JUL-2023 12:00:09",
          "updated_time": "17-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 491,
          "entity": "E3",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "17-July-2023 13:00",
          "effective_trigger_day": "17-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "-0.01000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "-0.01000000",
          "transformation_time": "17-07-2023 20:02:18 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "17-JUL-2023 12:00:09",
          "updated_time": "17-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 491,
          "entity": "CN_CNICP_LOCAL",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "17-July-2023 13:00",
          "effective_trigger_day": "17-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "-0.02000000",
          "us_gaap_etb_total": "-0.03000000",
          "lg_etb_total": "0.01000000",
          "transformation_time": "17-07-2023 20:02:18 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "17-JUL-2023 12:00:09",
          "updated_time": "17-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 489,
          "entity": "5C",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "13-July-2023 13:00",
          "effective_trigger_day": "13-July-2023 12:00",
          "status": "DELETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "13-JUL-2023 12:00:09",
          "updated_time": "13-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": "",
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 489,
          "entity": "PK",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "13-July-2023 13:00",
          "effective_trigger_day": "13-July-2023 12:00",
          "status": "DELETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "13-JUL-2023 12:00:09",
          "updated_time": "13-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": "",
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 489,
          "entity": "66RBK",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "13-July-2023 13:00",
          "effective_trigger_day": "13-July-2023 12:00",
          "status": "DELETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "13-JUL-2023 12:00:09",
          "updated_time": "13-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": "",
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 489,
          "entity": "FW_CONSOL",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "13-July-2023 13:00",
          "effective_trigger_day": "13-July-2023 12:00",
          "status": "DELETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "13-JUL-2023 12:00:09",
          "updated_time": "13-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": "",
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 489,
          "entity": "B423",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "13-July-2023 13:00",
          "effective_trigger_day": "13-July-2023 12:00",
          "status": "DELETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "13-JUL-2023 12:00:09",
          "updated_time": "13-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": "",
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 489,
          "entity": "1Y",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "13-July-2023 13:00",
          "effective_trigger_day": "13-July-2023 12:00",
          "status": "DELETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "13-JUL-2023 12:00:09",
          "updated_time": "13-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": "",
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 489,
          "entity": "63RBK",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "13-July-2023 13:00",
          "effective_trigger_day": "13-July-2023 12:00",
          "status": "DELETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "13-JUL-2023 12:00:09",
          "updated_time": "13-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": "",
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 489,
          "entity": "63",
          "region": "LATAM_North",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "13-July-2023 13:00",
          "effective_trigger_day": "13-July-2023 12:00",
          "status": "DELETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "13-JUL-2023 12:00:09",
          "updated_time": "13-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": "",
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 489,
          "entity": "CD_CNY",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "13-July-2023 13:00",
          "effective_trigger_day": "13-July-2023 12:00",
          "status": "DELETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "13-JUL-2023 12:00:09",
          "updated_time": "13-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": "",
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 491,
          "entity": "55",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "17-July-2023 13:00",
          "effective_trigger_day": "17-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "17-07-2023 20:02:18 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "17-JUL-2023 12:00:09",
          "updated_time": "17-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 491,
          "entity": "B860",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "17-July-2023 13:00",
          "effective_trigger_day": "17-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "17-07-2023 20:02:18 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "17-JUL-2023 12:00:09",
          "updated_time": "17-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 491,
          "entity": "AU_55_CONS_LOCAL",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "17-July-2023 13:00",
          "effective_trigger_day": "17-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "17-07-2023 20:02:18 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "17-JUL-2023 12:00:09",
          "updated_time": "17-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 491,
          "entity": "B106",
          "region": "LATAM_North",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "17-July-2023 13:00",
          "effective_trigger_day": "17-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "17-07-2023 20:02:18 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "17-JUL-2023 12:00:09",
          "updated_time": "17-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 491,
          "entity": "SG_AAPR_LOCAL",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "17-July-2023 13:00",
          "effective_trigger_day": "17-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "-0.01000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "-0.01000000",
          "transformation_time": "17-07-2023 20:02:18 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "17-JUL-2023 12:00:09",
          "updated_time": "17-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 491,
          "entity": "DW",
          "region": "LATAM_North",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "17-July-2023 13:00",
          "effective_trigger_day": "17-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "17-07-2023 20:02:18 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "17-JUL-2023 12:00:09",
          "updated_time": "17-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 491,
          "entity": "CP",
          "region": "LATAM_North",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "17-July-2023 13:00",
          "effective_trigger_day": "17-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "17-07-2023 20:02:18 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "17-JUL-2023 12:00:09",
          "updated_time": "17-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 491,
          "entity": "75",
          "region": "LATAM_North",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "17-July-2023 13:00",
          "effective_trigger_day": "17-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "17-07-2023 20:02:18 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "17-JUL-2023 12:00:09",
          "updated_time": "17-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 491,
          "entity": "4E",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "17-July-2023 13:00",
          "effective_trigger_day": "17-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "17-07-2023 20:02:18 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "17-JUL-2023 12:00:09",
          "updated_time": "17-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 491,
          "entity": "HA",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "17-July-2023 13:00",
          "effective_trigger_day": "17-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "-0.01000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "-0.01000000",
          "transformation_time": "17-07-2023 20:02:18 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "17-JUL-2023 12:00:09",
          "updated_time": "17-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 491,
          "entity": "B184",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "17-July-2023 13:00",
          "effective_trigger_day": "17-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "17-07-2023 20:02:18 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "17-JUL-2023 12:00:09",
          "updated_time": "17-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 491,
          "entity": "B757",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "17-July-2023 13:00",
          "effective_trigger_day": "17-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "17-07-2023 20:02:18 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "17-JUL-2023 12:00:09",
          "updated_time": "17-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 491,
          "entity": "GC",
          "region": "LATAM_North",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "17-July-2023 13:00",
          "effective_trigger_day": "17-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "17-07-2023 20:02:18 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Failed",
          "created_time": "17-JUL-2023 12:00:09",
          "updated_time": "17-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 491,
          "entity": "RT",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "17-July-2023 13:00",
          "effective_trigger_day": "17-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "17-07-2023 20:02:18 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "17-JUL-2023 12:00:09",
          "updated_time": "17-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 491,
          "entity": "B859",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "17-July-2023 13:00",
          "effective_trigger_day": "17-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "17-07-2023 20:02:18 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "17-JUL-2023 12:00:09",
          "updated_time": "17-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 491,
          "entity": "B645",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "17-July-2023 13:00",
          "effective_trigger_day": "17-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "17-07-2023 20:02:18 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "17-JUL-2023 12:00:09",
          "updated_time": "17-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 491,
          "entity": "PH_AOSP_LOCAL",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "17-July-2023 13:00",
          "effective_trigger_day": "17-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "-0.02000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "-0.02000000",
          "transformation_time": "17-07-2023 20:02:18 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "17-JUL-2023 12:00:09",
          "updated_time": "17-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 491,
          "entity": "B776",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "17-July-2023 13:00",
          "effective_trigger_day": "17-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "17-07-2023 20:02:18 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "17-JUL-2023 12:00:09",
          "updated_time": "17-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 491,
          "entity": "2I",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "17-July-2023 13:00",
          "effective_trigger_day": "17-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "17-07-2023 20:02:18 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "17-JUL-2023 12:00:09",
          "updated_time": "17-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 491,
          "entity": "7J",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "17-July-2023 13:00",
          "effective_trigger_day": "17-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "17-07-2023 20:02:18 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "17-JUL-2023 12:00:09",
          "updated_time": "17-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 491,
          "entity": "B199",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "17-July-2023 13:00",
          "effective_trigger_day": "17-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "17-07-2023 20:02:18 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "17-JUL-2023 12:00:09",
          "updated_time": "17-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 491,
          "entity": "MF",
          "region": "LATAM_North",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "17-July-2023 13:00",
          "effective_trigger_day": "17-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "17-07-2023 20:02:18 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "17-JUL-2023 12:00:09",
          "updated_time": "17-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 491,
          "entity": "EA",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "17-July-2023 13:00",
          "effective_trigger_day": "17-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "17-07-2023 20:02:18 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "17-JUL-2023 12:00:09",
          "updated_time": "17-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 491,
          "entity": "B427",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "17-July-2023 13:00",
          "effective_trigger_day": "17-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "17-07-2023 20:02:18 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "17-JUL-2023 12:00:09",
          "updated_time": "17-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 491,
          "entity": "65",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "17-July-2023 13:00",
          "effective_trigger_day": "17-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.01000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "17-07-2023 20:02:18 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "17-JUL-2023 12:00:09",
          "updated_time": "17-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 491,
          "entity": "SG_AAPH_LOCAL",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "17-July-2023 13:00",
          "effective_trigger_day": "17-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.01000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "17-07-2023 20:02:18 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "17-JUL-2023 12:00:09",
          "updated_time": "17-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 491,
          "entity": "B676",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "17-July-2023 13:00",
          "effective_trigger_day": "17-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "17-07-2023 20:02:18 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "17-JUL-2023 12:00:09",
          "updated_time": "17-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 491,
          "entity": "M2",
          "region": "LATAM_North",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "17-July-2023 13:00",
          "effective_trigger_day": "17-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "17-07-2023 20:02:18 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "17-JUL-2023 12:00:09",
          "updated_time": "17-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 491,
          "entity": "J3",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "17-July-2023 13:00",
          "effective_trigger_day": "17-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "17-07-2023 20:02:18 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "17-JUL-2023 12:00:09",
          "updated_time": "17-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 491,
          "entity": "5C",
          "region": "LATAM_North",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "17-July-2023 13:00",
          "effective_trigger_day": "17-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "25713.42000000",
          "lg_etb_total": "-25713.42000000",
          "transformation_time": "17-07-2023 20:02:18 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Failed",
          "created_time": "17-JUL-2023 12:00:09",
          "updated_time": "17-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 491,
          "entity": "FX",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "17-July-2023 13:00",
          "effective_trigger_day": "17-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "17-07-2023 20:02:18 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "17-JUL-2023 12:00:09",
          "updated_time": "17-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 491,
          "entity": "5Z",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "17-July-2023 13:00",
          "effective_trigger_day": "17-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "17-07-2023 20:02:18 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "17-JUL-2023 12:00:09",
          "updated_time": "17-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 491,
          "entity": "B179",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "17-July-2023 13:00",
          "effective_trigger_day": "17-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "17-07-2023 20:02:18 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "17-JUL-2023 12:00:09",
          "updated_time": "17-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 491,
          "entity": "B622",
          "region": "LATAM_North",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "17-July-2023 13:00",
          "effective_trigger_day": "17-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "17-07-2023 20:02:18 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "17-JUL-2023 12:00:09",
          "updated_time": "17-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 491,
          "entity": "B869",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "17-July-2023 13:00",
          "effective_trigger_day": "17-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "17-07-2023 20:02:18 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "17-JUL-2023 12:00:09",
          "updated_time": "17-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 491,
          "entity": "B162",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "17-July-2023 13:00",
          "effective_trigger_day": "17-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "17-07-2023 20:02:18 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "17-JUL-2023 12:00:09",
          "updated_time": "17-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 491,
          "entity": "BW",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "17-July-2023 13:00",
          "effective_trigger_day": "17-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "17-07-2023 20:02:18 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "17-JUL-2023 12:00:09",
          "updated_time": "17-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 491,
          "entity": "B672",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "17-July-2023 13:00",
          "effective_trigger_day": "17-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "17-07-2023 20:02:18 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "17-JUL-2023 12:00:09",
          "updated_time": "17-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 491,
          "entity": "CN_CNAICSCL_LOCAL",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "17-July-2023 13:00",
          "effective_trigger_day": "17-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "17-07-2023 20:02:18 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "17-JUL-2023 12:00:09",
          "updated_time": "17-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 491,
          "entity": "6H",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "17-July-2023 13:00",
          "effective_trigger_day": "17-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "17-07-2023 20:02:18 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "17-JUL-2023 12:00:09",
          "updated_time": "17-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 491,
          "entity": "KA",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "17-July-2023 13:00",
          "effective_trigger_day": "17-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "17-07-2023 20:02:18 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "17-JUL-2023 12:00:09",
          "updated_time": "17-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 491,
          "entity": "7G",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "17-July-2023 13:00",
          "effective_trigger_day": "17-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "17-07-2023 20:02:18 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "17-JUL-2023 12:00:09",
          "updated_time": "17-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 491,
          "entity": "CN_CNJV_LOCAL",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "17-July-2023 13:00",
          "effective_trigger_day": "17-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "17-07-2023 20:02:18 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "17-JUL-2023 12:00:09",
          "updated_time": "17-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 491,
          "entity": "MS",
          "region": "LATAM_North",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "17-July-2023 13:00",
          "effective_trigger_day": "17-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.80000000",
          "lg_etb_total": "-0.80000000",
          "transformation_time": "17-07-2023 20:02:18 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Failed",
          "created_time": "17-JUL-2023 12:00:09",
          "updated_time": "17-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 491,
          "entity": "BC61",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "17-July-2023 13:00",
          "effective_trigger_day": "17-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "17-07-2023 20:02:18 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "17-JUL-2023 12:00:09",
          "updated_time": "17-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 491,
          "entity": "QM",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "17-July-2023 13:00",
          "effective_trigger_day": "17-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "17-07-2023 20:02:18 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "17-JUL-2023 12:00:09",
          "updated_time": "17-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 491,
          "entity": "B9",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "17-July-2023 13:00",
          "effective_trigger_day": "17-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "17-07-2023 20:02:18 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "17-JUL-2023 12:00:09",
          "updated_time": "17-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 491,
          "entity": "LK",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "17-July-2023 13:00",
          "effective_trigger_day": "17-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.16000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.16000000",
          "transformation_time": "17-07-2023 20:02:18 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "17-JUL-2023 12:00:09",
          "updated_time": "17-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 491,
          "entity": "B644",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "17-July-2023 13:00",
          "effective_trigger_day": "17-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "17-07-2023 20:02:18 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "17-JUL-2023 12:00:09",
          "updated_time": "17-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 491,
          "entity": "B437",
          "region": "LATAM_North",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "17-July-2023 13:00",
          "effective_trigger_day": "17-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "17-07-2023 20:02:18 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "17-JUL-2023 12:00:09",
          "updated_time": "17-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 491,
          "entity": "DX",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "17-July-2023 13:00",
          "effective_trigger_day": "17-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "17-07-2023 20:02:18 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "17-JUL-2023 12:00:09",
          "updated_time": "17-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 491,
          "entity": "B581",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "17-July-2023 13:00",
          "effective_trigger_day": "17-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "17-07-2023 20:02:18 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "17-JUL-2023 12:00:09",
          "updated_time": "17-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 491,
          "entity": "63",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "17-July-2023 13:00",
          "effective_trigger_day": "17-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.06000000",
          "us_gaap_etb_total": "-0.02000000",
          "lg_etb_total": "0.07000000",
          "transformation_time": "17-07-2023 20:02:18 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "17-JUL-2023 12:00:09",
          "updated_time": "17-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 491,
          "entity": "BI",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "17-July-2023 13:00",
          "effective_trigger_day": "17-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "17-07-2023 20:02:18 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "17-JUL-2023 12:00:09",
          "updated_time": "17-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 491,
          "entity": "L8",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "17-July-2023 13:00",
          "effective_trigger_day": "17-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "-0.01000000",
          "lg_etb_total": "0.01000000",
          "transformation_time": "17-07-2023 20:02:18 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "17-JUL-2023 12:00:09",
          "updated_time": "17-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 491,
          "entity": "AQ",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "17-July-2023 13:00",
          "effective_trigger_day": "17-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "17-07-2023 20:02:18 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "17-JUL-2023 12:00:09",
          "updated_time": "17-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 491,
          "entity": "DG",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "17-July-2023 13:00",
          "effective_trigger_day": "17-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "17-07-2023 20:02:18 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "17-JUL-2023 12:00:09",
          "updated_time": "17-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 491,
          "entity": "QL",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "17-July-2023 13:00",
          "effective_trigger_day": "17-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.24000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.24000000",
          "transformation_time": "17-07-2023 20:02:18 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "17-JUL-2023 12:00:09",
          "updated_time": "17-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 491,
          "entity": "69",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "17-July-2023 13:00",
          "effective_trigger_day": "17-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "17-07-2023 20:02:18 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "17-JUL-2023 12:00:09",
          "updated_time": "17-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 491,
          "entity": "MJ",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "17-July-2023 13:00",
          "effective_trigger_day": "17-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "17-07-2023 20:02:18 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "17-JUL-2023 12:00:09",
          "updated_time": "17-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 491,
          "entity": "B439",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "17-July-2023 13:00",
          "effective_trigger_day": "17-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "17-07-2023 20:02:18 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "17-JUL-2023 12:00:09",
          "updated_time": "17-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 491,
          "entity": "QK",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "17-July-2023 13:00",
          "effective_trigger_day": "17-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "17-07-2023 20:02:18 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "17-JUL-2023 12:00:09",
          "updated_time": "17-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 491,
          "entity": "8O",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "17-July-2023 13:00",
          "effective_trigger_day": "17-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "17-07-2023 20:02:18 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "17-JUL-2023 12:00:09",
          "updated_time": "17-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 491,
          "entity": "B400",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "17-July-2023 13:00",
          "effective_trigger_day": "17-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "17-07-2023 20:02:18 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "17-JUL-2023 12:00:09",
          "updated_time": "17-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 491,
          "entity": "PN",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "17-July-2023 13:00",
          "effective_trigger_day": "17-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "17-07-2023 20:02:18 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "17-JUL-2023 12:00:09",
          "updated_time": "17-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 491,
          "entity": "B745",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "17-July-2023 13:00",
          "effective_trigger_day": "17-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "17-07-2023 20:02:18 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "17-JUL-2023 12:00:09",
          "updated_time": "17-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 491,
          "entity": "B423",
          "region": "LATAM_North",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "17-July-2023 13:00",
          "effective_trigger_day": "17-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.32000000",
          "us_gaap_etb_total": "0.32000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "17-07-2023 20:02:18 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "17-JUL-2023 12:00:09",
          "updated_time": "17-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 491,
          "entity": "1I",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "17-July-2023 13:00",
          "effective_trigger_day": "17-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "17-07-2023 20:02:18 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "17-JUL-2023 12:00:09",
          "updated_time": "17-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 491,
          "entity": "B407",
          "region": "LATAM_North",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "17-July-2023 13:00",
          "effective_trigger_day": "17-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "17-07-2023 20:02:18 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "17-JUL-2023 12:00:09",
          "updated_time": "17-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 491,
          "entity": "CN_FW_CONSOL_LOCAL",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "17-July-2023 13:00",
          "effective_trigger_day": "17-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "17-07-2023 20:02:18 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "17-JUL-2023 12:00:09",
          "updated_time": "17-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 491,
          "entity": "B110",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "17-July-2023 13:00",
          "effective_trigger_day": "17-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "17-07-2023 20:02:18 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "17-JUL-2023 12:00:09",
          "updated_time": "17-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 491,
          "entity": "HA",
          "region": "LATAM_North",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "17-July-2023 13:00",
          "effective_trigger_day": "17-July-2023 12:00",
          "status": "FAILED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "17-JUL-2023 12:00:09",
          "updated_time": "17-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 491,
          "entity": "64RBK",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "17-July-2023 13:00",
          "effective_trigger_day": "17-July-2023 12:00",
          "status": "FAILED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "17-JUL-2023 12:00:09",
          "updated_time": "17-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 491,
          "entity": "63A",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "17-July-2023 13:00",
          "effective_trigger_day": "17-July-2023 12:00",
          "status": "FAILED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "17-JUL-2023 12:00:09",
          "updated_time": "17-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 491,
          "entity": "MS_CONS",
          "region": "LATAM_North",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "17-July-2023 13:00",
          "effective_trigger_day": "17-July-2023 12:00",
          "status": "FAILED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "17-JUL-2023 12:00:09",
          "updated_time": "17-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 491,
          "entity": "CNJV",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "17-July-2023 13:00",
          "effective_trigger_day": "17-July-2023 12:00",
          "status": "FAILED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "17-JUL-2023 12:00:09",
          "updated_time": "17-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 491,
          "entity": "B115",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "17-July-2023 13:00",
          "effective_trigger_day": "17-July-2023 12:00",
          "status": "FAILED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "17-JUL-2023 12:00:09",
          "updated_time": "17-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 491,
          "entity": "630",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "17-July-2023 13:00",
          "effective_trigger_day": "17-July-2023 12:00",
          "status": "FAILED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "17-JUL-2023 12:00:09",
          "updated_time": "17-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 491,
          "entity": "55_CONS",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "17-July-2023 13:00",
          "effective_trigger_day": "17-July-2023 12:00",
          "status": "FAILED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "17-JUL-2023 12:00:09",
          "updated_time": "17-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 491,
          "entity": "MS",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "17-July-2023 13:00",
          "effective_trigger_day": "17-July-2023 12:00",
          "status": "FAILED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "17-JUL-2023 12:00:09",
          "updated_time": "17-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 491,
          "entity": "9T_CONS",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "17-July-2023 13:00",
          "effective_trigger_day": "17-July-2023 12:00",
          "status": "FAILED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "17-JUL-2023 12:00:09",
          "updated_time": "17-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 491,
          "entity": "65RBK",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "17-July-2023 13:00",
          "effective_trigger_day": "17-July-2023 12:00",
          "status": "FAILED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "17-JUL-2023 12:00:09",
          "updated_time": "17-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 491,
          "entity": "CNWFOE",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "17-July-2023 13:00",
          "effective_trigger_day": "17-July-2023 12:00",
          "status": "FAILED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "17-JUL-2023 12:00:09",
          "updated_time": "17-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 491,
          "entity": "5C",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "17-July-2023 13:00",
          "effective_trigger_day": "17-July-2023 12:00",
          "status": "FAILED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "17-JUL-2023 12:00:09",
          "updated_time": "17-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 491,
          "entity": "PK",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "17-July-2023 13:00",
          "effective_trigger_day": "17-July-2023 12:00",
          "status": "FAILED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "17-JUL-2023 12:00:09",
          "updated_time": "17-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 491,
          "entity": "66RBK",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "17-July-2023 13:00",
          "effective_trigger_day": "17-July-2023 12:00",
          "status": "FAILED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "17-JUL-2023 12:00:09",
          "updated_time": "17-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 491,
          "entity": "FW_CONSOL",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "17-July-2023 13:00",
          "effective_trigger_day": "17-July-2023 12:00",
          "status": "FAILED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "17-JUL-2023 12:00:09",
          "updated_time": "17-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 491,
          "entity": "B423",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "17-July-2023 13:00",
          "effective_trigger_day": "17-July-2023 12:00",
          "status": "FAILED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "17-JUL-2023 12:00:09",
          "updated_time": "17-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 491,
          "entity": "1Y",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "17-July-2023 13:00",
          "effective_trigger_day": "17-July-2023 12:00",
          "status": "FAILED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "17-JUL-2023 12:00:09",
          "updated_time": "17-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 491,
          "entity": "63RBK",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "17-July-2023 13:00",
          "effective_trigger_day": "17-July-2023 12:00",
          "status": "FAILED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "17-JUL-2023 12:00:09",
          "updated_time": "17-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 491,
          "entity": "63",
          "region": "LATAM_North",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "17-July-2023 13:00",
          "effective_trigger_day": "17-July-2023 12:00",
          "status": "FAILED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "17-JUL-2023 12:00:09",
          "updated_time": "17-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 491,
          "entity": "CNICP",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "17-July-2023 13:00",
          "effective_trigger_day": "17-July-2023 12:00",
          "status": "FAILED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "17-JUL-2023 12:00:09",
          "updated_time": "17-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 491,
          "entity": "CD_CNY",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "17-July-2023 13:00",
          "effective_trigger_day": "17-July-2023 12:00",
          "status": "FAILED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "17-JUL-2023 12:00:09",
          "updated_time": "17-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 494,
          "entity": "1I",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "18-July-2023 20:15",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "MANUAL",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "19-07-2023 08:13:22 AM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "18-JUL-2023 07:15:05",
          "updated_time": "18-JUL-2023 07:15:05",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "ngutina",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 501,
          "entity": "5C",
          "region": "LATAM_North",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "24-July-2023 08:30",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "MANUAL",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "24-JUL-2023 07:30:07",
          "updated_time": "24-JUL-2023 07:30:07",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "elsane",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 631,
          "entity": "GC",
          "region": "LATAM_North",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "16",
          "trigger_time_pst": "12:00",
          "etb_version": "Post-Tax, Post-WB",
          "actual_trigger_day": "28-July-2023 14:15",
          "effective_trigger_day": "25-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "MANUAL",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "-11010.88000000",
          "lg_etb_total": "11010.88000000",
          "transformation_time": "28-07-2023 21:16:27 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Failed",
          "created_time": "28-JUL-2023 01:15:08",
          "updated_time": "28-JUL-2023 01:15:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "laraenri",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 661,
          "entity": "QK",
          "region": "APAC",
          "report_period": "01-JUL-2023",
          "report_month": "AUG",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-August-2023 13:00",
          "effective_trigger_day": "14-August-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "14-AUG-2023 12:00:10",
          "updated_time": "14-AUG-2023 12:00:10",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 493,
          "entity": "1I",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "18-July-2023 13:00",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "19-07-2023 07:33:33 AM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "18-JUL-2023 12:00:09",
          "updated_time": "18-JUL-2023 12:00:09",
          "retrigger_request_time": "2023-07-19T00:00:00",
          "retrigger_status": "REQUESTED",
          "retrigger_by": "ngutina",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 493,
          "entity": "MS_CONS",
          "region": "LATAM_North",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "18-July-2023 13:00",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "FAILED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "18-JUL-2023 12:00:09",
          "updated_time": "18-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 493,
          "entity": "PN",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "18-July-2023 13:00",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "19-07-2023 07:33:33 AM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "18-JUL-2023 12:00:09",
          "updated_time": "18-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 493,
          "entity": "B112",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "18-July-2023 13:00",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "19-07-2023 07:33:33 AM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "18-JUL-2023 12:00:09",
          "updated_time": "18-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 493,
          "entity": "B180",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "18-July-2023 13:00",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "19-07-2023 07:33:33 AM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "18-JUL-2023 12:00:09",
          "updated_time": "18-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 493,
          "entity": "75",
          "region": "LATAM_North",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "18-July-2023 13:00",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "19-07-2023 07:33:33 AM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "18-JUL-2023 12:00:09",
          "updated_time": "18-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 493,
          "entity": "MF",
          "region": "LATAM_North",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "18-July-2023 13:00",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "19-07-2023 07:33:33 AM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "18-JUL-2023 12:00:09",
          "updated_time": "18-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 493,
          "entity": "FX",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "18-July-2023 13:00",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "19-07-2023 07:33:33 AM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "18-JUL-2023 12:00:09",
          "updated_time": "18-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 493,
          "entity": "CN_CNWFOE_LOCAL",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "18-July-2023 13:00",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "-0.01000000",
          "lg_etb_total": "0.01000000",
          "transformation_time": "19-07-2023 07:33:33 AM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "18-JUL-2023 12:00:09",
          "updated_time": "18-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 493,
          "entity": "CN_CNAICSCL_LOCAL",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "18-July-2023 13:00",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "19-07-2023 07:33:33 AM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "18-JUL-2023 12:00:09",
          "updated_time": "18-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 493,
          "entity": "B869",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "18-July-2023 13:00",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "19-07-2023 07:33:33 AM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "18-JUL-2023 12:00:09",
          "updated_time": "18-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 493,
          "entity": "6C",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "18-July-2023 13:00",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.02000000",
          "us_gaap_etb_total": "0.01000000",
          "lg_etb_total": "0.01000000",
          "transformation_time": "19-07-2023 07:33:33 AM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "18-JUL-2023 12:00:09",
          "updated_time": "18-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 493,
          "entity": "B451",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "18-July-2023 13:00",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "19-07-2023 07:33:33 AM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "18-JUL-2023 12:00:09",
          "updated_time": "18-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 493,
          "entity": "KA",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "18-July-2023 13:00",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "19-07-2023 07:33:33 AM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "18-JUL-2023 12:00:09",
          "updated_time": "18-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 493,
          "entity": "B400",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "18-July-2023 13:00",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "19-07-2023 07:33:33 AM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "18-JUL-2023 12:00:09",
          "updated_time": "18-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 493,
          "entity": "B583",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "18-July-2023 13:00",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "19-07-2023 07:33:33 AM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "18-JUL-2023 12:00:09",
          "updated_time": "18-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 493,
          "entity": "B672",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "18-July-2023 13:00",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "19-07-2023 07:33:33 AM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "18-JUL-2023 12:00:09",
          "updated_time": "18-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 493,
          "entity": "DX",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "18-July-2023 13:00",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "19-07-2023 07:33:33 AM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "18-JUL-2023 12:00:09",
          "updated_time": "18-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 493,
          "entity": "CN_CNICP_LOCAL",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "18-July-2023 13:00",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "-0.02000000",
          "us_gaap_etb_total": "-0.03000000",
          "lg_etb_total": "0.01000000",
          "transformation_time": "19-07-2023 07:33:33 AM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "18-JUL-2023 12:00:09",
          "updated_time": "18-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 493,
          "entity": "B423",
          "region": "LATAM_North",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "18-July-2023 13:00",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.32000000",
          "us_gaap_etb_total": "0.32000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "19-07-2023 07:33:33 AM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "18-JUL-2023 12:00:09",
          "updated_time": "18-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 493,
          "entity": "4E",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "18-July-2023 13:00",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "19-07-2023 07:33:33 AM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "18-JUL-2023 12:00:09",
          "updated_time": "18-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 493,
          "entity": "DW",
          "region": "LATAM_North",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "18-July-2023 13:00",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "19-07-2023 07:33:33 AM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "18-JUL-2023 12:00:09",
          "updated_time": "18-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 493,
          "entity": "L8",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "18-July-2023 13:00",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "-0.01000000",
          "lg_etb_total": "0.01000000",
          "transformation_time": "19-07-2023 07:33:33 AM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "18-JUL-2023 12:00:09",
          "updated_time": "18-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 493,
          "entity": "B757",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "18-July-2023 13:00",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "19-07-2023 07:33:33 AM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "18-JUL-2023 12:00:09",
          "updated_time": "18-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 493,
          "entity": "DG",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "18-July-2023 13:00",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "19-07-2023 07:33:33 AM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "18-JUL-2023 12:00:09",
          "updated_time": "18-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 493,
          "entity": "AQ",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "18-July-2023 13:00",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "19-07-2023 07:33:33 AM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "18-JUL-2023 12:00:09",
          "updated_time": "18-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 493,
          "entity": "B622",
          "region": "LATAM_North",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "18-July-2023 13:00",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "19-07-2023 07:33:33 AM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "18-JUL-2023 12:00:09",
          "updated_time": "18-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 661,
          "entity": "B179",
          "region": "APAC",
          "report_period": "01-JUL-2023",
          "report_month": "AUG",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-August-2023 13:00",
          "effective_trigger_day": "14-August-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "14-AUG-2023 12:00:10",
          "updated_time": "14-AUG-2023 12:00:10",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 493,
          "entity": "E3",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "18-July-2023 13:00",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "-0.01000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "-0.01000000",
          "transformation_time": "19-07-2023 07:33:33 AM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "18-JUL-2023 12:00:09",
          "updated_time": "18-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 493,
          "entity": "B184",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "18-July-2023 13:00",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "19-07-2023 07:33:33 AM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "18-JUL-2023 12:00:09",
          "updated_time": "18-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 493,
          "entity": "V8",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "18-July-2023 13:00",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "19-07-2023 07:33:33 AM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "18-JUL-2023 12:00:09",
          "updated_time": "18-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 493,
          "entity": "55",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "18-July-2023 13:00",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "19-07-2023 07:33:33 AM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "18-JUL-2023 12:00:09",
          "updated_time": "18-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 493,
          "entity": "B407",
          "region": "LATAM_North",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "18-July-2023 13:00",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "19-07-2023 07:33:33 AM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "18-JUL-2023 12:00:09",
          "updated_time": "18-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 493,
          "entity": "64",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "18-July-2023 13:00",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "19-07-2023 07:33:33 AM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "18-JUL-2023 12:00:09",
          "updated_time": "18-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 661,
          "entity": "55",
          "region": "APAC",
          "report_period": "01-JUL-2023",
          "report_month": "AUG",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-August-2023 13:00",
          "effective_trigger_day": "14-August-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "14-AUG-2023 12:00:10",
          "updated_time": "14-AUG-2023 12:00:10",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 493,
          "entity": "8W",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "18-July-2023 13:00",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "19-07-2023 07:33:33 AM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "18-JUL-2023 12:00:09",
          "updated_time": "18-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 493,
          "entity": "R6",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "18-July-2023 13:00",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "19-07-2023 07:33:33 AM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "18-JUL-2023 12:00:09",
          "updated_time": "18-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 493,
          "entity": "AU_55_CONS_LOCAL",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "18-July-2023 13:00",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "19-07-2023 07:33:33 AM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "18-JUL-2023 12:00:09",
          "updated_time": "18-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 493,
          "entity": "69",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "18-July-2023 13:00",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "19-07-2023 07:33:33 AM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "18-JUL-2023 12:00:09",
          "updated_time": "18-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 493,
          "entity": "B422",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "18-July-2023 13:00",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "19-07-2023 07:33:33 AM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "18-JUL-2023 12:00:09",
          "updated_time": "18-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 493,
          "entity": "BC61",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "18-July-2023 13:00",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "19-07-2023 07:33:33 AM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "18-JUL-2023 12:00:09",
          "updated_time": "18-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 493,
          "entity": "B427",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "18-July-2023 13:00",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "19-07-2023 07:33:33 AM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "18-JUL-2023 12:00:09",
          "updated_time": "18-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 493,
          "entity": "6H",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "18-July-2023 13:00",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "19-07-2023 07:33:33 AM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "18-JUL-2023 12:00:09",
          "updated_time": "18-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 493,
          "entity": "B676",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "18-July-2023 13:00",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "19-07-2023 07:33:33 AM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "18-JUL-2023 12:00:09",
          "updated_time": "18-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 493,
          "entity": "B859",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "18-July-2023 13:00",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "19-07-2023 07:33:33 AM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "18-JUL-2023 12:00:09",
          "updated_time": "18-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 493,
          "entity": "MJ",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "18-July-2023 13:00",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "19-07-2023 07:33:33 AM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "18-JUL-2023 12:00:09",
          "updated_time": "18-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 493,
          "entity": "M2",
          "region": "LATAM_North",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "18-July-2023 13:00",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "19-07-2023 07:33:33 AM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "18-JUL-2023 12:00:09",
          "updated_time": "18-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 493,
          "entity": "B110",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "18-July-2023 13:00",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "19-07-2023 07:33:33 AM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "18-JUL-2023 12:00:09",
          "updated_time": "18-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 493,
          "entity": "SG_AAPR_LOCAL",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "18-July-2023 13:00",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "-0.01000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "-0.01000000",
          "transformation_time": "19-07-2023 07:33:33 AM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "18-JUL-2023 12:00:09",
          "updated_time": "18-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 493,
          "entity": "QL",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "18-July-2023 13:00",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.24000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.24000000",
          "transformation_time": "19-07-2023 07:33:33 AM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "18-JUL-2023 12:00:09",
          "updated_time": "18-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 493,
          "entity": "B106",
          "region": "LATAM_North",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "18-July-2023 13:00",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "19-07-2023 07:33:33 AM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "18-JUL-2023 12:00:09",
          "updated_time": "18-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 493,
          "entity": "B743",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "18-July-2023 13:00",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "19-07-2023 07:33:33 AM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "18-JUL-2023 12:00:09",
          "updated_time": "18-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 493,
          "entity": "CN_FW_CONSOL_LOCAL",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "18-July-2023 13:00",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "19-07-2023 07:33:33 AM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "18-JUL-2023 12:00:09",
          "updated_time": "18-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 493,
          "entity": "B900",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "18-July-2023 13:00",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "19-07-2023 07:33:33 AM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "18-JUL-2023 12:00:09",
          "updated_time": "18-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 493,
          "entity": "QM",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "18-July-2023 13:00",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "19-07-2023 07:33:33 AM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "18-JUL-2023 12:00:09",
          "updated_time": "18-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 493,
          "entity": "LK",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "18-July-2023 13:00",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.16000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.16000000",
          "transformation_time": "19-07-2023 07:33:33 AM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "18-JUL-2023 12:00:09",
          "updated_time": "18-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 493,
          "entity": "BI",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "18-July-2023 13:00",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "19-07-2023 07:33:33 AM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "18-JUL-2023 12:00:09",
          "updated_time": "18-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 661,
          "entity": "BC61",
          "region": "APAC",
          "report_period": "01-JUL-2023",
          "report_month": "AUG",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "14-August-2023 13:00",
          "effective_trigger_day": "14-August-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "14-AUG-2023 12:00:10",
          "updated_time": "14-AUG-2023 12:00:10",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 493,
          "entity": "8V",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "18-July-2023 13:00",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "19-07-2023 07:33:33 AM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "18-JUL-2023 12:00:09",
          "updated_time": "18-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 493,
          "entity": "B9",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "18-July-2023 13:00",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "19-07-2023 07:33:33 AM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "18-JUL-2023 12:00:09",
          "updated_time": "18-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 493,
          "entity": "ML",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "18-July-2023 13:00",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "19-07-2023 07:33:33 AM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "18-JUL-2023 12:00:09",
          "updated_time": "18-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 493,
          "entity": "B115",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "18-July-2023 13:00",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "-0.16000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "-0.16000000",
          "transformation_time": "19-07-2023 07:33:33 AM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "18-JUL-2023 12:00:09",
          "updated_time": "18-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 493,
          "entity": "B581",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "18-July-2023 13:00",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "19-07-2023 07:33:33 AM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "18-JUL-2023 12:00:09",
          "updated_time": "18-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 493,
          "entity": "B872",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "18-July-2023 13:00",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "19-07-2023 07:33:33 AM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "18-JUL-2023 12:00:09",
          "updated_time": "18-JUL-2023 12:00:09",
          "retrigger_request_time": "2023-07-24T00:00:00",
          "retrigger_status": "REQUESTED",
          "retrigger_by": "koaysiu",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 493,
          "entity": "GC",
          "region": "LATAM_North",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "18-July-2023 13:00",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "19-07-2023 07:33:33 AM",
          "transformation_status": "Completed",
          "pre_check_status": "Failed",
          "created_time": "18-JUL-2023 12:00:09",
          "updated_time": "18-JUL-2023 12:00:09",
          "retrigger_request_time": "2023-07-24T00:00:00",
          "retrigger_status": "REQUESTED",
          "retrigger_by": "elsane",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 493,
          "entity": "B439",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "18-July-2023 13:00",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "19-07-2023 07:33:33 AM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "18-JUL-2023 12:00:09",
          "updated_time": "18-JUL-2023 12:00:09",
          "retrigger_request_time": "2023-07-24T00:00:00",
          "retrigger_status": "REQUESTED",
          "retrigger_by": "huitingt",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 493,
          "entity": "B645",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "18-July-2023 13:00",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "19-07-2023 07:33:33 AM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "18-JUL-2023 12:00:09",
          "updated_time": "18-JUL-2023 12:00:09",
          "retrigger_request_time": "2023-07-24T00:00:00",
          "retrigger_status": "REQUESTED",
          "retrigger_by": "huitingt",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 493,
          "entity": "BW",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "18-July-2023 13:00",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "19-07-2023 07:33:33 AM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "18-JUL-2023 12:00:09",
          "updated_time": "18-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 493,
          "entity": "EA",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "18-July-2023 13:00",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "19-07-2023 07:33:33 AM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "18-JUL-2023 12:00:09",
          "updated_time": "18-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 493,
          "entity": "B860",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "18-July-2023 13:00",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "19-07-2023 07:33:33 AM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "18-JUL-2023 12:00:09",
          "updated_time": "18-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 493,
          "entity": "CN_CNJV_LOCAL",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "18-July-2023 13:00",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "19-07-2023 07:33:33 AM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "18-JUL-2023 12:00:09",
          "updated_time": "18-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 493,
          "entity": "5Z",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "18-July-2023 13:00",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "19-07-2023 07:33:33 AM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "18-JUL-2023 12:00:09",
          "updated_time": "18-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 493,
          "entity": "4G",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "18-July-2023 13:00",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "19-07-2023 07:33:33 AM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "18-JUL-2023 12:00:09",
          "updated_time": "18-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 493,
          "entity": "NX",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "18-July-2023 13:00",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "19-07-2023 07:33:33 AM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "18-JUL-2023 12:00:09",
          "updated_time": "18-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 493,
          "entity": "65",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "18-July-2023 13:00",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.01000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "19-07-2023 07:33:33 AM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "18-JUL-2023 12:00:09",
          "updated_time": "18-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 493,
          "entity": "HW",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "18-July-2023 13:00",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "19-07-2023 07:33:33 AM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "18-JUL-2023 12:00:09",
          "updated_time": "18-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 493,
          "entity": "SG_AAPH_LOCAL",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "18-July-2023 13:00",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.01000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "19-07-2023 07:33:33 AM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "18-JUL-2023 12:00:09",
          "updated_time": "18-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 493,
          "entity": "CP",
          "region": "LATAM_North",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "18-July-2023 13:00",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "19-07-2023 07:33:33 AM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "18-JUL-2023 12:00:09",
          "updated_time": "18-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 493,
          "entity": "B745",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "18-July-2023 13:00",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "19-07-2023 07:33:33 AM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "18-JUL-2023 12:00:09",
          "updated_time": "18-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 493,
          "entity": "B199",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "18-July-2023 13:00",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "19-07-2023 07:33:33 AM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "18-JUL-2023 12:00:09",
          "updated_time": "18-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 493,
          "entity": "B928",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "18-July-2023 13:00",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "19-07-2023 07:33:33 AM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "18-JUL-2023 12:00:09",
          "updated_time": "18-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 493,
          "entity": "QK",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "18-July-2023 13:00",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "19-07-2023 07:33:33 AM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "18-JUL-2023 12:00:09",
          "updated_time": "18-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 493,
          "entity": "PH_AOSP_LOCAL",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "18-July-2023 13:00",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "-0.02000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "-0.02000000",
          "transformation_time": "19-07-2023 07:33:33 AM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "18-JUL-2023 12:00:09",
          "updated_time": "18-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 493,
          "entity": "B162",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "18-July-2023 13:00",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "19-07-2023 07:33:33 AM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "18-JUL-2023 12:00:09",
          "updated_time": "18-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 662,
          "entity": "75",
          "region": "LATAM_North",
          "report_period": "01-JUL-2023",
          "report_month": "AUG",
          "trigger_day": "15",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "21-August-2023 13:00",
          "effective_trigger_day": "21-August-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "21-AUG-2023 12:00:13",
          "updated_time": "21-AUG-2023 12:00:13",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 493,
          "entity": "MR",
          "region": "LATAM_North",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "18-July-2023 13:00",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "19-07-2023 07:33:33 AM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "18-JUL-2023 12:00:09",
          "updated_time": "18-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 493,
          "entity": "B776",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "18-July-2023 13:00",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "19-07-2023 07:33:33 AM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "18-JUL-2023 12:00:09",
          "updated_time": "18-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 493,
          "entity": "63",
          "region": "LATAM_North",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "18-July-2023 13:00",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.06000000",
          "us_gaap_etb_total": "-0.01000000",
          "lg_etb_total": "0.07000000",
          "transformation_time": "19-07-2023 07:33:33 AM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "18-JUL-2023 12:00:09",
          "updated_time": "18-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 493,
          "entity": "2I",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "18-July-2023 13:00",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "19-07-2023 07:33:33 AM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "18-JUL-2023 12:00:09",
          "updated_time": "18-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 493,
          "entity": "9T",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "18-July-2023 13:00",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "-0.01000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "-0.01000000",
          "transformation_time": "19-07-2023 07:33:33 AM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "18-JUL-2023 12:00:09",
          "updated_time": "18-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 493,
          "entity": "J3",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "18-July-2023 13:00",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "19-07-2023 07:33:33 AM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "18-JUL-2023 12:00:09",
          "updated_time": "18-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 493,
          "entity": "B644",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "18-July-2023 13:00",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "19-07-2023 07:33:33 AM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "18-JUL-2023 12:00:09",
          "updated_time": "18-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 493,
          "entity": "B179",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "18-July-2023 13:00",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "19-07-2023 07:33:33 AM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "18-JUL-2023 12:00:09",
          "updated_time": "18-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 493,
          "entity": "8O",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "18-July-2023 13:00",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "19-07-2023 07:33:33 AM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "18-JUL-2023 12:00:09",
          "updated_time": "18-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 493,
          "entity": "MS",
          "region": "LATAM_North",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "18-July-2023 13:00",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.80000000",
          "lg_etb_total": "-0.80000000",
          "transformation_time": "19-07-2023 07:33:33 AM",
          "transformation_status": "Completed",
          "pre_check_status": "Failed",
          "created_time": "18-JUL-2023 12:00:09",
          "updated_time": "18-JUL-2023 12:00:09",
          "retrigger_request_time": "2023-07-24T00:00:00",
          "retrigger_status": "REQUESTED",
          "retrigger_by": "elsane",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 493,
          "entity": "5C",
          "region": "LATAM_North",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "18-July-2023 13:00",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "25713.42000000",
          "lg_etb_total": "-25713.42000000",
          "transformation_time": "19-07-2023 07:33:33 AM",
          "transformation_status": "Completed",
          "pre_check_status": "Failed",
          "created_time": "18-JUL-2023 12:00:09",
          "updated_time": "18-JUL-2023 12:00:09",
          "retrigger_request_time": "2023-07-24T00:00:00",
          "retrigger_status": "REQUESTED",
          "retrigger_by": "elsane",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 493,
          "entity": "HA",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "18-July-2023 13:00",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "-0.01000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "-0.01000000",
          "transformation_time": "19-07-2023 07:33:33 AM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "18-JUL-2023 12:00:09",
          "updated_time": "18-JUL-2023 12:00:09",
          "retrigger_request_time": "2023-07-24T00:00:00",
          "retrigger_status": "REQUESTED",
          "retrigger_by": "huitingt",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 493,
          "entity": "DZ",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "18-July-2023 13:00",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "19-07-2023 07:33:33 AM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "18-JUL-2023 12:00:09",
          "updated_time": "18-JUL-2023 12:00:09",
          "retrigger_request_time": "2023-07-24T00:00:00",
          "retrigger_status": "REQUESTED",
          "retrigger_by": "huitingt",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 493,
          "entity": "Z4",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "18-July-2023 13:00",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "19-07-2023 07:33:33 AM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "18-JUL-2023 12:00:09",
          "updated_time": "18-JUL-2023 12:00:09",
          "retrigger_request_time": "2023-07-25T00:00:00",
          "retrigger_status": "REQUESTED",
          "retrigger_by": "elleyang",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 493,
          "entity": "B437",
          "region": "LATAM_North",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "18-July-2023 13:00",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "19-07-2023 07:33:33 AM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "18-JUL-2023 12:00:09",
          "updated_time": "18-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 493,
          "entity": "JG",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "18-July-2023 13:00",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "19-07-2023 07:33:33 AM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "18-JUL-2023 12:00:09",
          "updated_time": "18-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 493,
          "entity": "B436",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "18-July-2023 13:00",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "19-07-2023 07:33:33 AM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "18-JUL-2023 12:00:09",
          "updated_time": "18-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 493,
          "entity": "7J",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "18-July-2023 13:00",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "19-07-2023 07:33:33 AM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "18-JUL-2023 12:00:09",
          "updated_time": "18-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 493,
          "entity": "63",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "18-July-2023 13:00",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "FAILED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "18-JUL-2023 12:00:09",
          "updated_time": "18-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 493,
          "entity": "MS",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "18-July-2023 13:00",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "FAILED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "18-JUL-2023 12:00:09",
          "updated_time": "18-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 493,
          "entity": "B423",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "18-July-2023 13:00",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "FAILED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "18-JUL-2023 12:00:09",
          "updated_time": "18-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 493,
          "entity": "5C",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "18-July-2023 13:00",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "FAILED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "18-JUL-2023 12:00:09",
          "updated_time": "18-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 493,
          "entity": "CNJV",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "18-July-2023 13:00",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "FAILED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "18-JUL-2023 12:00:09",
          "updated_time": "18-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 493,
          "entity": "PK",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "18-July-2023 13:00",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "FAILED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "18-JUL-2023 12:00:09",
          "updated_time": "18-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 493,
          "entity": "63A",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "18-July-2023 13:00",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "FAILED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "18-JUL-2023 12:00:09",
          "updated_time": "18-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 493,
          "entity": "B115",
          "region": "LATAM_North",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "18-July-2023 13:00",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "FAILED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "18-JUL-2023 12:00:09",
          "updated_time": "18-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 493,
          "entity": "CD_CNY",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "18-July-2023 13:00",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "FAILED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "18-JUL-2023 12:00:09",
          "updated_time": "18-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 493,
          "entity": "FW_CONSOL",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "18-July-2023 13:00",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "FAILED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "18-JUL-2023 12:00:09",
          "updated_time": "18-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 493,
          "entity": "66RBK",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "18-July-2023 13:00",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "FAILED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "18-JUL-2023 12:00:09",
          "updated_time": "18-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 493,
          "entity": "630",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "18-July-2023 13:00",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "FAILED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "18-JUL-2023 12:00:09",
          "updated_time": "18-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 493,
          "entity": "63RBK",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "18-July-2023 13:00",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "FAILED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "18-JUL-2023 12:00:09",
          "updated_time": "18-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 493,
          "entity": "65RBK",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "18-July-2023 13:00",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "FAILED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "18-JUL-2023 12:00:09",
          "updated_time": "18-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 493,
          "entity": "CNICP",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "18-July-2023 13:00",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "FAILED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "18-JUL-2023 12:00:09",
          "updated_time": "18-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 493,
          "entity": "64RBK",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "18-July-2023 13:00",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "FAILED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "18-JUL-2023 12:00:09",
          "updated_time": "18-JUL-2023 12:00:09",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 493,
          "entity": "1Y",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "18-July-2023 13:00",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "FAILED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "18-JUL-2023 12:00:09",
          "updated_time": "18-JUL-2023 12:00:09",
          "retrigger_request_time": "2023-07-19T00:00:00",
          "retrigger_status": "REQUESTED",
          "retrigger_by": "uyyogara",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 493,
          "entity": "CNWFOE",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "18-July-2023 13:00",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "FAILED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "18-JUL-2023 12:00:09",
          "updated_time": "18-JUL-2023 12:00:09",
          "retrigger_request_time": "2023-07-24T00:00:00",
          "retrigger_status": "REQUESTED",
          "retrigger_by": "koaysiu",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 493,
          "entity": "HA",
          "region": "LATAM_North",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "18-July-2023 13:00",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "FAILED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "18-JUL-2023 12:00:09",
          "updated_time": "18-JUL-2023 12:00:09",
          "retrigger_request_time": "2023-07-24T00:00:00",
          "retrigger_status": "REQUESTED",
          "retrigger_by": "huitingt",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 502,
          "entity": "HA",
          "region": "LATAM_North",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "24-July-2023 16:15",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "MANUAL",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "24-JUL-2023 03:15:07",
          "updated_time": "24-JUL-2023 03:15:07",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "huitingt",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 502,
          "entity": "HA",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "24-July-2023 16:15",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "MANUAL",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "24-JUL-2023 03:15:07",
          "updated_time": "24-JUL-2023 03:15:07",
          "retrigger_request_time": "2023-07-24T00:00:00",
          "retrigger_status": "REQUESTED",
          "retrigger_by": "huitingt",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 493,
          "entity": "55_CONS",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "18-July-2023 13:00",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "FAILED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "18-JUL-2023 12:00:09",
          "updated_time": "18-JUL-2023 12:00:09",
          "retrigger_request_time": "2023-07-25T00:00:00",
          "retrigger_status": "REQUESTED",
          "retrigger_by": "elleyang",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 493,
          "entity": "9T_CONS",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "18-July-2023 13:00",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "FAILED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "18-JUL-2023 12:00:09",
          "updated_time": "18-JUL-2023 12:00:09",
          "retrigger_request_time": "2023-07-25T00:00:00",
          "retrigger_status": "REQUESTED",
          "retrigger_by": "elleyang",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 493,
          "entity": "KB",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "18-July-2023 13:00",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "19-07-2023 07:33:33 AM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "18-JUL-2023 12:00:09",
          "updated_time": "18-JUL-2023 12:00:09",
          "retrigger_request_time": "2023-07-25T00:00:00",
          "retrigger_status": "REQUESTED",
          "retrigger_by": "elleyang",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 662,
          "entity": "GC",
          "region": "LATAM_North",
          "report_period": "01-JUL-2023",
          "report_month": "AUG",
          "trigger_day": "15",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "21-August-2023 13:00",
          "effective_trigger_day": "21-August-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "21-AUG-2023 12:00:13",
          "updated_time": "21-AUG-2023 12:00:13",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 662,
          "entity": "MS",
          "region": "LATAM_North",
          "report_period": "01-JUL-2023",
          "report_month": "AUG",
          "trigger_day": "15",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "21-August-2023 13:00",
          "effective_trigger_day": "21-August-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "21-AUG-2023 12:00:13",
          "updated_time": "21-AUG-2023 12:00:13",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 662,
          "entity": "B622",
          "region": "LATAM_North",
          "report_period": "01-JUL-2023",
          "report_month": "AUG",
          "trigger_day": "15",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "21-August-2023 13:00",
          "effective_trigger_day": "21-August-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "21-AUG-2023 12:00:13",
          "updated_time": "21-AUG-2023 12:00:13",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 529,
          "entity": "B776",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "16",
          "trigger_time_pst": "12:00",
          "etb_version": "Post-Tax, Post-WB",
          "actual_trigger_day": "25-July-2023 13:00",
          "effective_trigger_day": "25-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "27-07-2023 21:25:23 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "25-JUL-2023 12:00:08",
          "updated_time": "25-JUL-2023 12:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 529,
          "entity": "CN_CNICP_LOCAL",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "16",
          "trigger_time_pst": "12:00",
          "etb_version": "Post-Tax, Post-WB",
          "actual_trigger_day": "25-July-2023 13:00",
          "effective_trigger_day": "25-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "-0.02000000",
          "us_gaap_etb_total": "-0.03000000",
          "lg_etb_total": "0.01000000",
          "transformation_time": "27-07-2023 21:25:23 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "25-JUL-2023 12:00:08",
          "updated_time": "25-JUL-2023 12:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 529,
          "entity": "75",
          "region": "LATAM_North",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "16",
          "trigger_time_pst": "12:00",
          "etb_version": "Post-Tax, Post-WB",
          "actual_trigger_day": "25-July-2023 13:00",
          "effective_trigger_day": "25-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "27-07-2023 21:25:23 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "25-JUL-2023 12:00:08",
          "updated_time": "25-JUL-2023 12:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 529,
          "entity": "B676",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "16",
          "trigger_time_pst": "12:00",
          "etb_version": "Post-Tax, Post-WB",
          "actual_trigger_day": "25-July-2023 13:00",
          "effective_trigger_day": "25-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "27-07-2023 21:25:23 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "25-JUL-2023 12:00:08",
          "updated_time": "25-JUL-2023 12:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 529,
          "entity": "630",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "16",
          "trigger_time_pst": "12:00",
          "etb_version": "Post-Tax, Post-WB",
          "actual_trigger_day": "25-July-2023 13:00",
          "effective_trigger_day": "25-July-2023 12:00",
          "status": "FAILED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "25-JUL-2023 12:00:08",
          "updated_time": "25-JUL-2023 12:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 662,
          "entity": "MX_MSMR_LOCAL",
          "region": "LATAM_North",
          "report_period": "01-JUL-2023",
          "report_month": "AUG",
          "trigger_day": "15",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "21-August-2023 13:00",
          "effective_trigger_day": "21-August-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "21-AUG-2023 12:00:13",
          "updated_time": "21-AUG-2023 12:00:13",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 662,
          "entity": "B106",
          "region": "LATAM_North",
          "report_period": "01-JUL-2023",
          "report_month": "AUG",
          "trigger_day": "15",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "21-August-2023 13:00",
          "effective_trigger_day": "21-August-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "21-AUG-2023 12:00:13",
          "updated_time": "21-AUG-2023 12:00:13",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 662,
          "entity": "MF",
          "region": "LATAM_North",
          "report_period": "01-JUL-2023",
          "report_month": "AUG",
          "trigger_day": "15",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "21-August-2023 13:00",
          "effective_trigger_day": "21-August-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "21-AUG-2023 12:00:13",
          "updated_time": "21-AUG-2023 12:00:13",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 662,
          "entity": "B437",
          "region": "LATAM_North",
          "report_period": "01-JUL-2023",
          "report_month": "AUG",
          "trigger_day": "15",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "21-August-2023 13:00",
          "effective_trigger_day": "21-August-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "21-AUG-2023 12:00:13",
          "updated_time": "21-AUG-2023 12:00:13",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 662,
          "entity": "B423",
          "region": "LATAM_North",
          "report_period": "01-JUL-2023",
          "report_month": "AUG",
          "trigger_day": "15",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "21-August-2023 13:00",
          "effective_trigger_day": "21-August-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "21-AUG-2023 12:00:13",
          "updated_time": "21-AUG-2023 12:00:13",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 562,
          "entity": "5C",
          "region": "LATAM_North",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "16",
          "trigger_time_pst": "12:00",
          "etb_version": "Post-Tax, Post-WB",
          "actual_trigger_day": "25-July-2023 13:30",
          "effective_trigger_day": "25-July-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "MANUAL",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "25-JUL-2023 12:30:07",
          "updated_time": "25-JUL-2023 12:30:07",
          "retrigger_request_time": "2023-07-28T00:00:00",
          "retrigger_status": "REQUESTED",
          "retrigger_by": "uyyogara",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 662,
          "entity": "MH",
          "region": "LATAM_North",
          "report_period": "01-JUL-2023",
          "report_month": "AUG",
          "trigger_day": "15",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "21-August-2023 13:00",
          "effective_trigger_day": "21-August-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "21-AUG-2023 12:00:13",
          "updated_time": "21-AUG-2023 12:00:13",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 662,
          "entity": "M2",
          "region": "LATAM_North",
          "report_period": "01-JUL-2023",
          "report_month": "AUG",
          "trigger_day": "15",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "21-August-2023 13:00",
          "effective_trigger_day": "21-August-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "21-AUG-2023 12:00:13",
          "updated_time": "21-AUG-2023 12:00:13",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 662,
          "entity": "MR",
          "region": "LATAM_North",
          "report_period": "01-JUL-2023",
          "report_month": "AUG",
          "trigger_day": "15",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "21-August-2023 13:00",
          "effective_trigger_day": "21-August-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "21-AUG-2023 12:00:13",
          "updated_time": "21-AUG-2023 12:00:13",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 662,
          "entity": "DW",
          "region": "LATAM_North",
          "report_period": "01-JUL-2023",
          "report_month": "AUG",
          "trigger_day": "15",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "21-August-2023 13:00",
          "effective_trigger_day": "21-August-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "21-AUG-2023 12:00:13",
          "updated_time": "21-AUG-2023 12:00:13",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 662,
          "entity": "B407",
          "region": "LATAM_North",
          "report_period": "01-JUL-2023",
          "report_month": "AUG",
          "trigger_day": "15",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "21-August-2023 13:00",
          "effective_trigger_day": "21-August-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "21-AUG-2023 12:00:13",
          "updated_time": "21-AUG-2023 12:00:13",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 662,
          "entity": "B115",
          "region": "LATAM_North",
          "report_period": "01-JUL-2023",
          "report_month": "AUG",
          "trigger_day": "15",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "21-August-2023 13:00",
          "effective_trigger_day": "21-August-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "21-AUG-2023 12:00:13",
          "updated_time": "21-AUG-2023 12:00:13",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 662,
          "entity": "5C",
          "region": "LATAM_North",
          "report_period": "01-JUL-2023",
          "report_month": "AUG",
          "trigger_day": "15",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "21-August-2023 13:00",
          "effective_trigger_day": "21-August-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "21-AUG-2023 12:00:13",
          "updated_time": "21-AUG-2023 12:00:13",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 662,
          "entity": "CP",
          "region": "LATAM_North",
          "report_period": "01-JUL-2023",
          "report_month": "AUG",
          "trigger_day": "15",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "21-August-2023 13:00",
          "effective_trigger_day": "21-August-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "21-AUG-2023 12:00:13",
          "updated_time": "21-AUG-2023 12:00:13",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 664,
          "entity": "B583",
          "region": "APAC",
          "report_period": "01-AUG-2023",
          "report_month": "SEP",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "15-September-2023 13:00",
          "effective_trigger_day": "15-September-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "15-SEP-2023 12:00:13",
          "updated_time": "15-SEP-2023 12:00:13",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 664,
          "entity": "B757",
          "region": "APAC",
          "report_period": "01-AUG-2023",
          "report_month": "SEP",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "15-September-2023 13:00",
          "effective_trigger_day": "15-September-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "15-SEP-2023 12:00:13",
          "updated_time": "15-SEP-2023 12:00:13",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 664,
          "entity": "BW",
          "region": "APAC",
          "report_period": "01-AUG-2023",
          "report_month": "SEP",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "15-September-2023 13:00",
          "effective_trigger_day": "15-September-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "15-SEP-2023 12:00:13",
          "updated_time": "15-SEP-2023 12:00:13",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 664,
          "entity": "LK",
          "region": "APAC",
          "report_period": "01-AUG-2023",
          "report_month": "SEP",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "15-September-2023 13:00",
          "effective_trigger_day": "15-September-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "15-SEP-2023 12:00:13",
          "updated_time": "15-SEP-2023 12:00:13",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 664,
          "entity": "L8",
          "region": "APAC",
          "report_period": "01-AUG-2023",
          "report_month": "SEP",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "15-September-2023 13:00",
          "effective_trigger_day": "15-September-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "15-SEP-2023 12:00:13",
          "updated_time": "15-SEP-2023 12:00:13",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 664,
          "entity": "4G",
          "region": "APAC",
          "report_period": "01-AUG-2023",
          "report_month": "SEP",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "15-September-2023 13:00",
          "effective_trigger_day": "15-September-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "15-SEP-2023 12:00:13",
          "updated_time": "15-SEP-2023 12:00:13",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 664,
          "entity": "PN",
          "region": "APAC",
          "report_period": "01-AUG-2023",
          "report_month": "SEP",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "15-September-2023 13:00",
          "effective_trigger_day": "15-September-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "15-SEP-2023 12:00:13",
          "updated_time": "15-SEP-2023 12:00:13",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 664,
          "entity": "6C",
          "region": "APAC",
          "report_period": "01-AUG-2023",
          "report_month": "SEP",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "15-September-2023 13:00",
          "effective_trigger_day": "15-September-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "15-SEP-2023 12:00:13",
          "updated_time": "15-SEP-2023 12:00:13",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 664,
          "entity": "AQ",
          "region": "APAC",
          "report_period": "01-AUG-2023",
          "report_month": "SEP",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "15-September-2023 13:00",
          "effective_trigger_day": "15-September-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "15-SEP-2023 12:00:13",
          "updated_time": "15-SEP-2023 12:00:13",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 664,
          "entity": "CN_CD_CNY_LOCAL",
          "region": "APAC",
          "report_period": "01-AUG-2023",
          "report_month": "SEP",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "15-September-2023 13:00",
          "effective_trigger_day": "15-September-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "15-SEP-2023 12:00:13",
          "updated_time": "15-SEP-2023 12:00:13",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 664,
          "entity": "B436",
          "region": "APAC",
          "report_period": "01-AUG-2023",
          "report_month": "SEP",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "15-September-2023 13:00",
          "effective_trigger_day": "15-September-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "15-SEP-2023 12:00:13",
          "updated_time": "15-SEP-2023 12:00:13",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 664,
          "entity": "B110",
          "region": "APAC",
          "report_period": "01-AUG-2023",
          "report_month": "SEP",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "15-September-2023 13:00",
          "effective_trigger_day": "15-September-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "15-SEP-2023 12:00:13",
          "updated_time": "15-SEP-2023 12:00:13",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 664,
          "entity": "SG_AAPR_LOCAL",
          "region": "APAC",
          "report_period": "01-AUG-2023",
          "report_month": "SEP",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "15-September-2023 13:00",
          "effective_trigger_day": "15-September-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "15-SEP-2023 12:00:13",
          "updated_time": "15-SEP-2023 12:00:13",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 664,
          "entity": "CN_CNAICSCL_LOCAL",
          "region": "APAC",
          "report_period": "01-AUG-2023",
          "report_month": "SEP",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "15-September-2023 13:00",
          "effective_trigger_day": "15-September-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "15-SEP-2023 12:00:13",
          "updated_time": "15-SEP-2023 12:00:13",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 664,
          "entity": "69",
          "region": "APAC",
          "report_period": "01-AUG-2023",
          "report_month": "SEP",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "15-September-2023 13:00",
          "effective_trigger_day": "15-September-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "15-SEP-2023 12:00:13",
          "updated_time": "15-SEP-2023 12:00:13",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 664,
          "entity": "JG",
          "region": "APAC",
          "report_period": "01-AUG-2023",
          "report_month": "SEP",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "15-September-2023 13:00",
          "effective_trigger_day": "15-September-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "15-SEP-2023 12:00:13",
          "updated_time": "15-SEP-2023 12:00:13",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 595,
          "entity": "MX_MSMR_LOCAL",
          "region": "LATAM_North",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "25-July-2023 18:30",
          "effective_trigger_day": "17-July-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "25-JUL-2023 05:30:07",
          "updated_time": "25-JUL-2023 05:30:07",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 595,
          "entity": "MX_MSMR_LOCAL",
          "region": "LATAM_North",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "25-July-2023 18:30",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "25-JUL-2023 05:30:07",
          "updated_time": "25-JUL-2023 05:30:07",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 595,
          "entity": "CN_CD_CNY_LOCAL",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "25-July-2023 18:30",
          "effective_trigger_day": "17-July-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "25-JUL-2023 05:30:07",
          "updated_time": "25-JUL-2023 05:30:07",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 595,
          "entity": "CN_CD_CNY_LOCAL",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Post-WB",
          "actual_trigger_day": "25-July-2023 18:30",
          "effective_trigger_day": "18-July-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "25-JUL-2023 05:30:07",
          "updated_time": "25-JUL-2023 05:30:07",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 595,
          "entity": "CN_CD_CNY_LOCAL",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "16",
          "trigger_time_pst": "12:00",
          "etb_version": "Post-Tax, Post-WB",
          "actual_trigger_day": "25-July-2023 18:30",
          "effective_trigger_day": "25-July-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "25-JUL-2023 05:30:07",
          "updated_time": "25-JUL-2023 05:30:07",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 595,
          "entity": "MX_MSMR_LOCAL",
          "region": "LATAM_North",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "16",
          "trigger_time_pst": "12:00",
          "etb_version": "Post-Tax, Post-WB",
          "actual_trigger_day": "25-July-2023 18:30",
          "effective_trigger_day": "25-July-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "25-JUL-2023 05:30:07",
          "updated_time": "25-JUL-2023 05:30:07",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 529,
          "entity": "CNWFOE",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "16",
          "trigger_time_pst": "12:00",
          "etb_version": "Post-Tax, Post-WB",
          "actual_trigger_day": "25-July-2023 13:00",
          "effective_trigger_day": "25-July-2023 12:00",
          "status": "FAILED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "25-JUL-2023 12:00:08",
          "updated_time": "25-JUL-2023 12:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 596,
          "entity": "NX",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "16",
          "trigger_time_pst": "12:00",
          "etb_version": "Post-Tax, Post-WB",
          "actual_trigger_day": "25-July-2023 18:45",
          "effective_trigger_day": "25-July-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "MANUAL",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "25-JUL-2023 05:45:07",
          "updated_time": "25-JUL-2023 05:45:07",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "xiaomexu",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 596,
          "entity": "8V",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "16",
          "trigger_time_pst": "12:00",
          "etb_version": "Post-Tax, Post-WB",
          "actual_trigger_day": "25-July-2023 18:45",
          "effective_trigger_day": "25-July-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "MANUAL",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "25-JUL-2023 05:45:07",
          "updated_time": "25-JUL-2023 05:45:07",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "xiaomexu",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 596,
          "entity": "RT",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "16",
          "trigger_time_pst": "12:00",
          "etb_version": "Post-Tax, Post-WB",
          "actual_trigger_day": "25-July-2023 18:45",
          "effective_trigger_day": "25-July-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "MANUAL",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "25-JUL-2023 05:45:07",
          "updated_time": "25-JUL-2023 05:45:07",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "xiaomexu",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 529,
          "entity": "7G",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "16",
          "trigger_time_pst": "12:00",
          "etb_version": "Post-Tax, Post-WB",
          "actual_trigger_day": "25-July-2023 13:00",
          "effective_trigger_day": "25-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "27-07-2023 21:25:23 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "25-JUL-2023 12:00:08",
          "updated_time": "25-JUL-2023 12:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 529,
          "entity": "B110",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "16",
          "trigger_time_pst": "12:00",
          "etb_version": "Post-Tax, Post-WB",
          "actual_trigger_day": "25-July-2023 13:00",
          "effective_trigger_day": "25-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "27-07-2023 21:25:23 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "25-JUL-2023 12:00:08",
          "updated_time": "25-JUL-2023 12:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 529,
          "entity": "55",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "16",
          "trigger_time_pst": "12:00",
          "etb_version": "Post-Tax, Post-WB",
          "actual_trigger_day": "25-July-2023 13:00",
          "effective_trigger_day": "25-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "27-07-2023 21:25:23 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "25-JUL-2023 12:00:08",
          "updated_time": "25-JUL-2023 12:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 529,
          "entity": "B422",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "16",
          "trigger_time_pst": "12:00",
          "etb_version": "Post-Tax, Post-WB",
          "actual_trigger_day": "25-July-2023 13:00",
          "effective_trigger_day": "25-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "27-07-2023 21:25:23 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "25-JUL-2023 12:00:08",
          "updated_time": "25-JUL-2023 12:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 529,
          "entity": "Z4",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "16",
          "trigger_time_pst": "12:00",
          "etb_version": "Post-Tax, Post-WB",
          "actual_trigger_day": "25-July-2023 13:00",
          "effective_trigger_day": "25-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "27-07-2023 21:25:23 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "25-JUL-2023 12:00:08",
          "updated_time": "25-JUL-2023 12:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 529,
          "entity": "CN_CNWFOE_LOCAL",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "16",
          "trigger_time_pst": "12:00",
          "etb_version": "Post-Tax, Post-WB",
          "actual_trigger_day": "25-July-2023 13:00",
          "effective_trigger_day": "25-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "-0.01000000",
          "lg_etb_total": "0.01000000",
          "transformation_time": "27-07-2023 21:25:23 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "25-JUL-2023 12:00:08",
          "updated_time": "25-JUL-2023 12:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 529,
          "entity": "B859",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "16",
          "trigger_time_pst": "12:00",
          "etb_version": "Post-Tax, Post-WB",
          "actual_trigger_day": "25-July-2023 13:00",
          "effective_trigger_day": "25-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "27-07-2023 21:25:23 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "25-JUL-2023 12:00:08",
          "updated_time": "25-JUL-2023 12:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 529,
          "entity": "TM",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "16",
          "trigger_time_pst": "12:00",
          "etb_version": "Post-Tax, Post-WB",
          "actual_trigger_day": "25-July-2023 13:00",
          "effective_trigger_day": "25-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.01000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.01000000",
          "transformation_time": "27-07-2023 21:25:23 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "25-JUL-2023 12:00:08",
          "updated_time": "25-JUL-2023 12:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 529,
          "entity": "MJ",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "16",
          "trigger_time_pst": "12:00",
          "etb_version": "Post-Tax, Post-WB",
          "actual_trigger_day": "25-July-2023 13:00",
          "effective_trigger_day": "25-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "27-07-2023 21:25:23 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "25-JUL-2023 12:00:08",
          "updated_time": "25-JUL-2023 12:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 529,
          "entity": "65",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "16",
          "trigger_time_pst": "12:00",
          "etb_version": "Post-Tax, Post-WB",
          "actual_trigger_day": "25-July-2023 13:00",
          "effective_trigger_day": "25-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.01000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "27-07-2023 21:25:23 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "25-JUL-2023 12:00:08",
          "updated_time": "25-JUL-2023 12:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 529,
          "entity": "8W",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "16",
          "trigger_time_pst": "12:00",
          "etb_version": "Post-Tax, Post-WB",
          "actual_trigger_day": "25-July-2023 13:00",
          "effective_trigger_day": "25-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "27-07-2023 21:25:23 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "25-JUL-2023 12:00:08",
          "updated_time": "25-JUL-2023 12:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 529,
          "entity": "B437",
          "region": "LATAM_North",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "16",
          "trigger_time_pst": "12:00",
          "etb_version": "Post-Tax, Post-WB",
          "actual_trigger_day": "25-July-2023 13:00",
          "effective_trigger_day": "25-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "27-07-2023 21:25:23 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "25-JUL-2023 12:00:08",
          "updated_time": "25-JUL-2023 12:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 529,
          "entity": "6C",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "16",
          "trigger_time_pst": "12:00",
          "etb_version": "Post-Tax, Post-WB",
          "actual_trigger_day": "25-July-2023 13:00",
          "effective_trigger_day": "25-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.02000000",
          "us_gaap_etb_total": "0.01000000",
          "lg_etb_total": "0.01000000",
          "transformation_time": "27-07-2023 21:25:23 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "25-JUL-2023 12:00:08",
          "updated_time": "25-JUL-2023 12:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 529,
          "entity": "B436",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "16",
          "trigger_time_pst": "12:00",
          "etb_version": "Post-Tax, Post-WB",
          "actual_trigger_day": "25-July-2023 13:00",
          "effective_trigger_day": "25-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "27-07-2023 21:25:23 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "25-JUL-2023 12:00:08",
          "updated_time": "25-JUL-2023 12:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 529,
          "entity": "1I",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "16",
          "trigger_time_pst": "12:00",
          "etb_version": "Post-Tax, Post-WB",
          "actual_trigger_day": "25-July-2023 13:00",
          "effective_trigger_day": "25-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "27-07-2023 21:25:23 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "25-JUL-2023 12:00:08",
          "updated_time": "25-JUL-2023 12:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 529,
          "entity": "J3",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "16",
          "trigger_time_pst": "12:00",
          "etb_version": "Post-Tax, Post-WB",
          "actual_trigger_day": "25-July-2023 13:00",
          "effective_trigger_day": "25-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "27-07-2023 21:25:23 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "25-JUL-2023 12:00:08",
          "updated_time": "25-JUL-2023 12:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 529,
          "entity": "HS",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "16",
          "trigger_time_pst": "12:00",
          "etb_version": "Post-Tax, Post-WB",
          "actual_trigger_day": "25-July-2023 13:00",
          "effective_trigger_day": "25-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "-0.01000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "-0.02000000",
          "transformation_time": "27-07-2023 21:25:23 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "25-JUL-2023 12:00:08",
          "updated_time": "25-JUL-2023 12:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 529,
          "entity": "ML",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "16",
          "trigger_time_pst": "12:00",
          "etb_version": "Post-Tax, Post-WB",
          "actual_trigger_day": "25-July-2023 13:00",
          "effective_trigger_day": "25-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "27-07-2023 21:25:23 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "25-JUL-2023 12:00:08",
          "updated_time": "25-JUL-2023 12:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 529,
          "entity": "LK",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "16",
          "trigger_time_pst": "12:00",
          "etb_version": "Post-Tax, Post-WB",
          "actual_trigger_day": "25-July-2023 13:00",
          "effective_trigger_day": "25-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.16000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.16000000",
          "transformation_time": "27-07-2023 21:25:23 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "25-JUL-2023 12:00:08",
          "updated_time": "25-JUL-2023 12:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 529,
          "entity": "B180",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "16",
          "trigger_time_pst": "12:00",
          "etb_version": "Post-Tax, Post-WB",
          "actual_trigger_day": "25-July-2023 13:00",
          "effective_trigger_day": "25-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "27-07-2023 21:25:23 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "25-JUL-2023 12:00:08",
          "updated_time": "25-JUL-2023 12:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 529,
          "entity": "L8",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "16",
          "trigger_time_pst": "12:00",
          "etb_version": "Post-Tax, Post-WB",
          "actual_trigger_day": "25-July-2023 13:00",
          "effective_trigger_day": "25-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "-0.01000000",
          "lg_etb_total": "0.01000000",
          "transformation_time": "27-07-2023 21:25:23 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "25-JUL-2023 12:00:08",
          "updated_time": "25-JUL-2023 12:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 529,
          "entity": "SG_AAPR_LOCAL",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "16",
          "trigger_time_pst": "12:00",
          "etb_version": "Post-Tax, Post-WB",
          "actual_trigger_day": "25-July-2023 13:00",
          "effective_trigger_day": "25-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "-0.01000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "-0.01000000",
          "transformation_time": "27-07-2023 21:25:23 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "25-JUL-2023 12:00:08",
          "updated_time": "25-JUL-2023 12:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 529,
          "entity": "B583",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "16",
          "trigger_time_pst": "12:00",
          "etb_version": "Post-Tax, Post-WB",
          "actual_trigger_day": "25-July-2023 13:00",
          "effective_trigger_day": "25-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "27-07-2023 21:25:23 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "25-JUL-2023 12:00:08",
          "updated_time": "25-JUL-2023 12:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 529,
          "entity": "B106",
          "region": "LATAM_North",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "16",
          "trigger_time_pst": "12:00",
          "etb_version": "Post-Tax, Post-WB",
          "actual_trigger_day": "25-July-2023 13:00",
          "effective_trigger_day": "25-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "27-07-2023 21:25:23 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "25-JUL-2023 12:00:08",
          "updated_time": "25-JUL-2023 12:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 529,
          "entity": "HW",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "16",
          "trigger_time_pst": "12:00",
          "etb_version": "Post-Tax, Post-WB",
          "actual_trigger_day": "25-July-2023 13:00",
          "effective_trigger_day": "25-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "27-07-2023 21:25:23 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "25-JUL-2023 12:00:08",
          "updated_time": "25-JUL-2023 12:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 529,
          "entity": "QK",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "16",
          "trigger_time_pst": "12:00",
          "etb_version": "Post-Tax, Post-WB",
          "actual_trigger_day": "25-July-2023 13:00",
          "effective_trigger_day": "25-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "27-07-2023 21:25:23 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "25-JUL-2023 12:00:08",
          "updated_time": "25-JUL-2023 12:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 529,
          "entity": "B439",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "16",
          "trigger_time_pst": "12:00",
          "etb_version": "Post-Tax, Post-WB",
          "actual_trigger_day": "25-July-2023 13:00",
          "effective_trigger_day": "25-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "27-07-2023 21:25:23 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "25-JUL-2023 12:00:08",
          "updated_time": "25-JUL-2023 12:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 529,
          "entity": "B645",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "16",
          "trigger_time_pst": "12:00",
          "etb_version": "Post-Tax, Post-WB",
          "actual_trigger_day": "25-July-2023 13:00",
          "effective_trigger_day": "25-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "27-07-2023 21:25:23 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "25-JUL-2023 12:00:08",
          "updated_time": "25-JUL-2023 12:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 529,
          "entity": "B199",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "16",
          "trigger_time_pst": "12:00",
          "etb_version": "Post-Tax, Post-WB",
          "actual_trigger_day": "25-July-2023 13:00",
          "effective_trigger_day": "25-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "27-07-2023 21:25:23 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "25-JUL-2023 12:00:08",
          "updated_time": "25-JUL-2023 12:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 529,
          "entity": "BI",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "16",
          "trigger_time_pst": "12:00",
          "etb_version": "Post-Tax, Post-WB",
          "actual_trigger_day": "25-July-2023 13:00",
          "effective_trigger_day": "25-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "27-07-2023 21:25:23 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "25-JUL-2023 12:00:08",
          "updated_time": "25-JUL-2023 12:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 529,
          "entity": "B9",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "16",
          "trigger_time_pst": "12:00",
          "etb_version": "Post-Tax, Post-WB",
          "actual_trigger_day": "25-July-2023 13:00",
          "effective_trigger_day": "25-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "27-07-2023 21:25:23 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "25-JUL-2023 12:00:08",
          "updated_time": "25-JUL-2023 12:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 529,
          "entity": "B184",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "16",
          "trigger_time_pst": "12:00",
          "etb_version": "Post-Tax, Post-WB",
          "actual_trigger_day": "25-July-2023 13:00",
          "effective_trigger_day": "25-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "27-07-2023 21:25:23 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "25-JUL-2023 12:00:08",
          "updated_time": "25-JUL-2023 12:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 529,
          "entity": "V8",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "16",
          "trigger_time_pst": "12:00",
          "etb_version": "Post-Tax, Post-WB",
          "actual_trigger_day": "25-July-2023 13:00",
          "effective_trigger_day": "25-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "27-07-2023 21:25:23 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "25-JUL-2023 12:00:08",
          "updated_time": "25-JUL-2023 12:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 529,
          "entity": "B112",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "16",
          "trigger_time_pst": "12:00",
          "etb_version": "Post-Tax, Post-WB",
          "actual_trigger_day": "25-July-2023 13:00",
          "effective_trigger_day": "25-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "27-07-2023 21:25:23 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "25-JUL-2023 12:00:08",
          "updated_time": "25-JUL-2023 12:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 529,
          "entity": "B400",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "16",
          "trigger_time_pst": "12:00",
          "etb_version": "Post-Tax, Post-WB",
          "actual_trigger_day": "25-July-2023 13:00",
          "effective_trigger_day": "25-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "27-07-2023 21:25:23 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "25-JUL-2023 12:00:08",
          "updated_time": "25-JUL-2023 12:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 529,
          "entity": "BC61",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "16",
          "trigger_time_pst": "12:00",
          "etb_version": "Post-Tax, Post-WB",
          "actual_trigger_day": "25-July-2023 13:00",
          "effective_trigger_day": "25-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "27-07-2023 21:25:23 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "25-JUL-2023 12:00:08",
          "updated_time": "25-JUL-2023 12:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 529,
          "entity": "B451",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "16",
          "trigger_time_pst": "12:00",
          "etb_version": "Post-Tax, Post-WB",
          "actual_trigger_day": "25-July-2023 13:00",
          "effective_trigger_day": "25-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "27-07-2023 21:25:23 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "25-JUL-2023 12:00:08",
          "updated_time": "25-JUL-2023 12:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 529,
          "entity": "E3",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "16",
          "trigger_time_pst": "12:00",
          "etb_version": "Post-Tax, Post-WB",
          "actual_trigger_day": "25-July-2023 13:00",
          "effective_trigger_day": "25-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "-0.01000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "-0.01000000",
          "transformation_time": "27-07-2023 21:25:23 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "25-JUL-2023 12:00:08",
          "updated_time": "25-JUL-2023 12:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 529,
          "entity": "64",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "16",
          "trigger_time_pst": "12:00",
          "etb_version": "Post-Tax, Post-WB",
          "actual_trigger_day": "25-July-2023 13:00",
          "effective_trigger_day": "25-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "27-07-2023 21:25:23 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "25-JUL-2023 12:00:08",
          "updated_time": "25-JUL-2023 12:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 529,
          "entity": "4E",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "16",
          "trigger_time_pst": "12:00",
          "etb_version": "Post-Tax, Post-WB",
          "actual_trigger_day": "25-July-2023 13:00",
          "effective_trigger_day": "25-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "27-07-2023 21:25:23 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "25-JUL-2023 12:00:08",
          "updated_time": "25-JUL-2023 12:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 529,
          "entity": "MH",
          "region": "LATAM_North",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "16",
          "trigger_time_pst": "12:00",
          "etb_version": "Post-Tax, Post-WB",
          "actual_trigger_day": "25-July-2023 13:00",
          "effective_trigger_day": "25-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "27-07-2023 21:25:23 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "25-JUL-2023 12:00:08",
          "updated_time": "25-JUL-2023 12:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 529,
          "entity": "FX",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "16",
          "trigger_time_pst": "12:00",
          "etb_version": "Post-Tax, Post-WB",
          "actual_trigger_day": "25-July-2023 13:00",
          "effective_trigger_day": "25-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "27-07-2023 21:25:23 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "25-JUL-2023 12:00:08",
          "updated_time": "25-JUL-2023 12:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 529,
          "entity": "M2",
          "region": "LATAM_North",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "16",
          "trigger_time_pst": "12:00",
          "etb_version": "Post-Tax, Post-WB",
          "actual_trigger_day": "25-July-2023 13:00",
          "effective_trigger_day": "25-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "27-07-2023 21:25:23 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "25-JUL-2023 12:00:08",
          "updated_time": "25-JUL-2023 12:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 529,
          "entity": "9T",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "16",
          "trigger_time_pst": "12:00",
          "etb_version": "Post-Tax, Post-WB",
          "actual_trigger_day": "25-July-2023 13:00",
          "effective_trigger_day": "25-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "-0.01000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "-0.01000000",
          "transformation_time": "27-07-2023 21:25:23 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "25-JUL-2023 12:00:08",
          "updated_time": "25-JUL-2023 12:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 529,
          "entity": "B869",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "16",
          "trigger_time_pst": "12:00",
          "etb_version": "Post-Tax, Post-WB",
          "actual_trigger_day": "25-July-2023 13:00",
          "effective_trigger_day": "25-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "27-07-2023 21:25:23 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "25-JUL-2023 12:00:08",
          "updated_time": "25-JUL-2023 12:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 529,
          "entity": "AQ",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "16",
          "trigger_time_pst": "12:00",
          "etb_version": "Post-Tax, Post-WB",
          "actual_trigger_day": "25-July-2023 13:00",
          "effective_trigger_day": "25-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "27-07-2023 21:25:23 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "25-JUL-2023 12:00:08",
          "updated_time": "25-JUL-2023 12:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 529,
          "entity": "MR",
          "region": "LATAM_North",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "16",
          "trigger_time_pst": "12:00",
          "etb_version": "Post-Tax, Post-WB",
          "actual_trigger_day": "25-July-2023 13:00",
          "effective_trigger_day": "25-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "27-07-2023 21:25:23 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "25-JUL-2023 12:00:08",
          "updated_time": "25-JUL-2023 12:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 529,
          "entity": "MS",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "16",
          "trigger_time_pst": "12:00",
          "etb_version": "Post-Tax, Post-WB",
          "actual_trigger_day": "25-July-2023 13:00",
          "effective_trigger_day": "25-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.80000000",
          "lg_etb_total": "-0.80000000",
          "transformation_time": "27-07-2023 21:25:23 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Failed",
          "created_time": "25-JUL-2023 12:00:08",
          "updated_time": "25-JUL-2023 12:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 529,
          "entity": "KA",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "16",
          "trigger_time_pst": "12:00",
          "etb_version": "Post-Tax, Post-WB",
          "actual_trigger_day": "25-July-2023 13:00",
          "effective_trigger_day": "25-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "27-07-2023 21:25:23 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "25-JUL-2023 12:00:08",
          "updated_time": "25-JUL-2023 12:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 529,
          "entity": "6H",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "16",
          "trigger_time_pst": "12:00",
          "etb_version": "Post-Tax, Post-WB",
          "actual_trigger_day": "25-July-2023 13:00",
          "effective_trigger_day": "25-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "27-07-2023 21:25:23 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "25-JUL-2023 12:00:08",
          "updated_time": "25-JUL-2023 12:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 529,
          "entity": "KB",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "16",
          "trigger_time_pst": "12:00",
          "etb_version": "Post-Tax, Post-WB",
          "actual_trigger_day": "25-July-2023 13:00",
          "effective_trigger_day": "25-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "27-07-2023 21:25:23 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "25-JUL-2023 12:00:08",
          "updated_time": "25-JUL-2023 12:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 529,
          "entity": "B581",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "16",
          "trigger_time_pst": "12:00",
          "etb_version": "Post-Tax, Post-WB",
          "actual_trigger_day": "25-July-2023 13:00",
          "effective_trigger_day": "25-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "27-07-2023 21:25:23 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "25-JUL-2023 12:00:08",
          "updated_time": "25-JUL-2023 12:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 529,
          "entity": "B745",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "16",
          "trigger_time_pst": "12:00",
          "etb_version": "Post-Tax, Post-WB",
          "actual_trigger_day": "25-July-2023 13:00",
          "effective_trigger_day": "25-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "27-07-2023 21:25:23 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "25-JUL-2023 12:00:08",
          "updated_time": "25-JUL-2023 12:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 529,
          "entity": "EA",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "16",
          "trigger_time_pst": "12:00",
          "etb_version": "Post-Tax, Post-WB",
          "actual_trigger_day": "25-July-2023 13:00",
          "effective_trigger_day": "25-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "27-07-2023 21:25:23 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "25-JUL-2023 12:00:08",
          "updated_time": "25-JUL-2023 12:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 529,
          "entity": "CN_CNJV_LOCAL",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "16",
          "trigger_time_pst": "12:00",
          "etb_version": "Post-Tax, Post-WB",
          "actual_trigger_day": "25-July-2023 13:00",
          "effective_trigger_day": "25-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "27-07-2023 21:25:23 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "25-JUL-2023 12:00:08",
          "updated_time": "25-JUL-2023 12:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 529,
          "entity": "SG_AAPH_LOCAL",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "16",
          "trigger_time_pst": "12:00",
          "etb_version": "Post-Tax, Post-WB",
          "actual_trigger_day": "25-July-2023 13:00",
          "effective_trigger_day": "25-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.01000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "27-07-2023 21:25:23 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "25-JUL-2023 12:00:08",
          "updated_time": "25-JUL-2023 12:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 529,
          "entity": "BW",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "16",
          "trigger_time_pst": "12:00",
          "etb_version": "Post-Tax, Post-WB",
          "actual_trigger_day": "25-July-2023 13:00",
          "effective_trigger_day": "25-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "27-07-2023 21:25:23 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "25-JUL-2023 12:00:08",
          "updated_time": "25-JUL-2023 12:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 529,
          "entity": "69",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "16",
          "trigger_time_pst": "12:00",
          "etb_version": "Post-Tax, Post-WB",
          "actual_trigger_day": "25-July-2023 13:00",
          "effective_trigger_day": "25-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "27-07-2023 21:25:23 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "25-JUL-2023 12:00:08",
          "updated_time": "25-JUL-2023 12:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 529,
          "entity": "2I",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "16",
          "trigger_time_pst": "12:00",
          "etb_version": "Post-Tax, Post-WB",
          "actual_trigger_day": "25-July-2023 13:00",
          "effective_trigger_day": "25-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "27-07-2023 21:25:23 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "25-JUL-2023 12:00:08",
          "updated_time": "25-JUL-2023 12:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 529,
          "entity": "CN_FW_CONSOL_LOCAL",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "16",
          "trigger_time_pst": "12:00",
          "etb_version": "Post-Tax, Post-WB",
          "actual_trigger_day": "25-July-2023 13:00",
          "effective_trigger_day": "25-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "27-07-2023 21:25:23 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "25-JUL-2023 12:00:08",
          "updated_time": "25-JUL-2023 12:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 529,
          "entity": "B423",
          "region": "LATAM_North",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "16",
          "trigger_time_pst": "12:00",
          "etb_version": "Post-Tax, Post-WB",
          "actual_trigger_day": "25-July-2023 13:00",
          "effective_trigger_day": "25-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.32000000",
          "us_gaap_etb_total": "0.32000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "27-07-2023 21:25:23 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "25-JUL-2023 12:00:08",
          "updated_time": "25-JUL-2023 12:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 529,
          "entity": "QM",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "16",
          "trigger_time_pst": "12:00",
          "etb_version": "Post-Tax, Post-WB",
          "actual_trigger_day": "25-July-2023 13:00",
          "effective_trigger_day": "25-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "27-07-2023 21:25:23 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "25-JUL-2023 12:00:08",
          "updated_time": "25-JUL-2023 12:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 529,
          "entity": "PH_AOSP_LOCAL",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "16",
          "trigger_time_pst": "12:00",
          "etb_version": "Post-Tax, Post-WB",
          "actual_trigger_day": "25-July-2023 13:00",
          "effective_trigger_day": "25-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "-0.02000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "-0.02000000",
          "transformation_time": "27-07-2023 21:25:23 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "25-JUL-2023 12:00:08",
          "updated_time": "25-JUL-2023 12:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 529,
          "entity": "B672",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "16",
          "trigger_time_pst": "12:00",
          "etb_version": "Post-Tax, Post-WB",
          "actual_trigger_day": "25-July-2023 13:00",
          "effective_trigger_day": "25-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "27-07-2023 21:25:23 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "25-JUL-2023 12:00:08",
          "updated_time": "25-JUL-2023 12:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 529,
          "entity": "B644",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "16",
          "trigger_time_pst": "12:00",
          "etb_version": "Post-Tax, Post-WB",
          "actual_trigger_day": "25-July-2023 13:00",
          "effective_trigger_day": "25-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "27-07-2023 21:25:23 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "25-JUL-2023 12:00:08",
          "updated_time": "25-JUL-2023 12:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 529,
          "entity": "5Z",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "16",
          "trigger_time_pst": "12:00",
          "etb_version": "Post-Tax, Post-WB",
          "actual_trigger_day": "25-July-2023 13:00",
          "effective_trigger_day": "25-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "27-07-2023 21:25:23 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "25-JUL-2023 12:00:08",
          "updated_time": "25-JUL-2023 12:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 529,
          "entity": "R6",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "16",
          "trigger_time_pst": "12:00",
          "etb_version": "Post-Tax, Post-WB",
          "actual_trigger_day": "25-July-2023 13:00",
          "effective_trigger_day": "25-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "27-07-2023 21:25:23 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "25-JUL-2023 12:00:08",
          "updated_time": "25-JUL-2023 12:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 529,
          "entity": "B928",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "16",
          "trigger_time_pst": "12:00",
          "etb_version": "Post-Tax, Post-WB",
          "actual_trigger_day": "25-July-2023 13:00",
          "effective_trigger_day": "25-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "27-07-2023 21:25:23 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "25-JUL-2023 12:00:08",
          "updated_time": "25-JUL-2023 12:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 529,
          "entity": "DZ",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "16",
          "trigger_time_pst": "12:00",
          "etb_version": "Post-Tax, Post-WB",
          "actual_trigger_day": "25-July-2023 13:00",
          "effective_trigger_day": "25-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "27-07-2023 21:25:23 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "25-JUL-2023 12:00:08",
          "updated_time": "25-JUL-2023 12:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 529,
          "entity": "B179",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "16",
          "trigger_time_pst": "12:00",
          "etb_version": "Post-Tax, Post-WB",
          "actual_trigger_day": "25-July-2023 13:00",
          "effective_trigger_day": "25-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "27-07-2023 21:25:23 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "25-JUL-2023 12:00:08",
          "updated_time": "25-JUL-2023 12:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 529,
          "entity": "B900",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "16",
          "trigger_time_pst": "12:00",
          "etb_version": "Post-Tax, Post-WB",
          "actual_trigger_day": "25-July-2023 13:00",
          "effective_trigger_day": "25-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "27-07-2023 21:25:23 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "25-JUL-2023 12:00:08",
          "updated_time": "25-JUL-2023 12:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 529,
          "entity": "B872",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "16",
          "trigger_time_pst": "12:00",
          "etb_version": "Post-Tax, Post-WB",
          "actual_trigger_day": "25-July-2023 13:00",
          "effective_trigger_day": "25-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "27-07-2023 21:25:23 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "25-JUL-2023 12:00:08",
          "updated_time": "25-JUL-2023 12:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 529,
          "entity": "AU_55_CONS_LOCAL",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "16",
          "trigger_time_pst": "12:00",
          "etb_version": "Post-Tax, Post-WB",
          "actual_trigger_day": "25-July-2023 13:00",
          "effective_trigger_day": "25-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "27-07-2023 21:25:23 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "25-JUL-2023 12:00:08",
          "updated_time": "25-JUL-2023 12:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 529,
          "entity": "RT",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "16",
          "trigger_time_pst": "12:00",
          "etb_version": "Post-Tax, Post-WB",
          "actual_trigger_day": "25-July-2023 13:00",
          "effective_trigger_day": "25-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "27-07-2023 21:25:23 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "25-JUL-2023 12:00:08",
          "updated_time": "25-JUL-2023 12:00:08",
          "retrigger_request_time": "2023-07-26T00:00:00",
          "retrigger_status": "REQUESTED",
          "retrigger_by": "xiaomexu",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 529,
          "entity": "B407",
          "region": "LATAM_North",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "16",
          "trigger_time_pst": "12:00",
          "etb_version": "Post-Tax, Post-WB",
          "actual_trigger_day": "25-July-2023 13:00",
          "effective_trigger_day": "25-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "27-07-2023 21:25:23 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "25-JUL-2023 12:00:08",
          "updated_time": "25-JUL-2023 12:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 529,
          "entity": "B743",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "16",
          "trigger_time_pst": "12:00",
          "etb_version": "Post-Tax, Post-WB",
          "actual_trigger_day": "25-July-2023 13:00",
          "effective_trigger_day": "25-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "27-07-2023 21:25:23 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "25-JUL-2023 12:00:08",
          "updated_time": "25-JUL-2023 12:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 529,
          "entity": "HA",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "16",
          "trigger_time_pst": "12:00",
          "etb_version": "Post-Tax, Post-WB",
          "actual_trigger_day": "25-July-2023 13:00",
          "effective_trigger_day": "25-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "-0.01000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "-0.01000000",
          "transformation_time": "27-07-2023 21:25:23 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "25-JUL-2023 12:00:08",
          "updated_time": "25-JUL-2023 12:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 529,
          "entity": "CN_CNAICSCL_LOCAL",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "16",
          "trigger_time_pst": "12:00",
          "etb_version": "Post-Tax, Post-WB",
          "actual_trigger_day": "25-July-2023 13:00",
          "effective_trigger_day": "25-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "27-07-2023 21:25:23 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "25-JUL-2023 12:00:08",
          "updated_time": "25-JUL-2023 12:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 529,
          "entity": "PN",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "16",
          "trigger_time_pst": "12:00",
          "etb_version": "Post-Tax, Post-WB",
          "actual_trigger_day": "25-July-2023 13:00",
          "effective_trigger_day": "25-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "27-07-2023 21:25:23 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "25-JUL-2023 12:00:08",
          "updated_time": "25-JUL-2023 12:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 529,
          "entity": "B860",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "16",
          "trigger_time_pst": "12:00",
          "etb_version": "Post-Tax, Post-WB",
          "actual_trigger_day": "25-July-2023 13:00",
          "effective_trigger_day": "25-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "27-07-2023 21:25:23 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "25-JUL-2023 12:00:08",
          "updated_time": "25-JUL-2023 12:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 529,
          "entity": "DG",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "16",
          "trigger_time_pst": "12:00",
          "etb_version": "Post-Tax, Post-WB",
          "actual_trigger_day": "25-July-2023 13:00",
          "effective_trigger_day": "25-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "27-07-2023 21:25:23 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "25-JUL-2023 12:00:08",
          "updated_time": "25-JUL-2023 12:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 529,
          "entity": "DW",
          "region": "LATAM_North",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "16",
          "trigger_time_pst": "12:00",
          "etb_version": "Post-Tax, Post-WB",
          "actual_trigger_day": "25-July-2023 13:00",
          "effective_trigger_day": "25-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "27-07-2023 21:25:23 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "25-JUL-2023 12:00:08",
          "updated_time": "25-JUL-2023 12:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 529,
          "entity": "7J",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "16",
          "trigger_time_pst": "12:00",
          "etb_version": "Post-Tax, Post-WB",
          "actual_trigger_day": "25-July-2023 13:00",
          "effective_trigger_day": "25-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "27-07-2023 21:25:23 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "25-JUL-2023 12:00:08",
          "updated_time": "25-JUL-2023 12:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 529,
          "entity": "JG",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "16",
          "trigger_time_pst": "12:00",
          "etb_version": "Post-Tax, Post-WB",
          "actual_trigger_day": "25-July-2023 13:00",
          "effective_trigger_day": "25-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "27-07-2023 21:25:23 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "25-JUL-2023 12:00:08",
          "updated_time": "25-JUL-2023 12:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 529,
          "entity": "8O",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "16",
          "trigger_time_pst": "12:00",
          "etb_version": "Post-Tax, Post-WB",
          "actual_trigger_day": "25-July-2023 13:00",
          "effective_trigger_day": "25-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "27-07-2023 21:25:23 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "25-JUL-2023 12:00:08",
          "updated_time": "25-JUL-2023 12:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 529,
          "entity": "QL",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "16",
          "trigger_time_pst": "12:00",
          "etb_version": "Post-Tax, Post-WB",
          "actual_trigger_day": "25-July-2023 13:00",
          "effective_trigger_day": "25-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.24000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.24000000",
          "transformation_time": "27-07-2023 21:25:23 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "25-JUL-2023 12:00:08",
          "updated_time": "25-JUL-2023 12:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 529,
          "entity": "MF",
          "region": "LATAM_North",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "16",
          "trigger_time_pst": "12:00",
          "etb_version": "Post-Tax, Post-WB",
          "actual_trigger_day": "25-July-2023 13:00",
          "effective_trigger_day": "25-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "27-07-2023 21:25:23 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "25-JUL-2023 12:00:08",
          "updated_time": "25-JUL-2023 12:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 529,
          "entity": "63",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "16",
          "trigger_time_pst": "12:00",
          "etb_version": "Post-Tax, Post-WB",
          "actual_trigger_day": "25-July-2023 13:00",
          "effective_trigger_day": "25-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.06000000",
          "us_gaap_etb_total": "-0.01000000",
          "lg_etb_total": "0.07000000",
          "transformation_time": "27-07-2023 21:25:23 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "25-JUL-2023 12:00:08",
          "updated_time": "25-JUL-2023 12:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 529,
          "entity": "B757",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "16",
          "trigger_time_pst": "12:00",
          "etb_version": "Post-Tax, Post-WB",
          "actual_trigger_day": "25-July-2023 13:00",
          "effective_trigger_day": "25-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "27-07-2023 21:25:23 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "25-JUL-2023 12:00:08",
          "updated_time": "25-JUL-2023 12:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 529,
          "entity": "DX",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "16",
          "trigger_time_pst": "12:00",
          "etb_version": "Post-Tax, Post-WB",
          "actual_trigger_day": "25-July-2023 13:00",
          "effective_trigger_day": "25-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "27-07-2023 21:25:23 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "25-JUL-2023 12:00:08",
          "updated_time": "25-JUL-2023 12:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 529,
          "entity": "B115",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "16",
          "trigger_time_pst": "12:00",
          "etb_version": "Post-Tax, Post-WB",
          "actual_trigger_day": "25-July-2023 13:00",
          "effective_trigger_day": "25-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "-0.16000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "-0.16000000",
          "transformation_time": "27-07-2023 21:25:23 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "25-JUL-2023 12:00:08",
          "updated_time": "25-JUL-2023 12:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 529,
          "entity": "4G",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "16",
          "trigger_time_pst": "12:00",
          "etb_version": "Post-Tax, Post-WB",
          "actual_trigger_day": "25-July-2023 13:00",
          "effective_trigger_day": "25-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "27-07-2023 21:25:23 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "25-JUL-2023 12:00:08",
          "updated_time": "25-JUL-2023 12:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 529,
          "entity": "CP",
          "region": "LATAM_North",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "16",
          "trigger_time_pst": "12:00",
          "etb_version": "Post-Tax, Post-WB",
          "actual_trigger_day": "25-July-2023 13:00",
          "effective_trigger_day": "25-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "27-07-2023 21:25:23 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "25-JUL-2023 12:00:08",
          "updated_time": "25-JUL-2023 12:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 529,
          "entity": "B622",
          "region": "LATAM_North",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "16",
          "trigger_time_pst": "12:00",
          "etb_version": "Post-Tax, Post-WB",
          "actual_trigger_day": "25-July-2023 13:00",
          "effective_trigger_day": "25-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "27-07-2023 21:25:23 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "25-JUL-2023 12:00:08",
          "updated_time": "25-JUL-2023 12:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 529,
          "entity": "B427",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "16",
          "trigger_time_pst": "12:00",
          "etb_version": "Post-Tax, Post-WB",
          "actual_trigger_day": "25-July-2023 13:00",
          "effective_trigger_day": "25-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "27-07-2023 21:25:23 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "25-JUL-2023 12:00:08",
          "updated_time": "25-JUL-2023 12:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 529,
          "entity": "5C",
          "region": "LATAM_North",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "16",
          "trigger_time_pst": "12:00",
          "etb_version": "Post-Tax, Post-WB",
          "actual_trigger_day": "25-July-2023 13:00",
          "effective_trigger_day": "25-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.64000000",
          "lg_etb_total": "-0.64000000",
          "transformation_time": "27-07-2023 21:25:23 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Failed",
          "created_time": "25-JUL-2023 12:00:08",
          "updated_time": "25-JUL-2023 12:00:08",
          "retrigger_request_time": "2023-07-25T00:00:00",
          "retrigger_status": "REQUESTED",
          "retrigger_by": "elsane",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 529,
          "entity": "8V",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "16",
          "trigger_time_pst": "12:00",
          "etb_version": "Post-Tax, Post-WB",
          "actual_trigger_day": "25-July-2023 13:00",
          "effective_trigger_day": "25-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "27-07-2023 21:25:23 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "25-JUL-2023 12:00:08",
          "updated_time": "25-JUL-2023 12:00:08",
          "retrigger_request_time": "2023-07-26T00:00:00",
          "retrigger_status": "REQUESTED",
          "retrigger_by": "xiaomexu",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 529,
          "entity": "NX",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "16",
          "trigger_time_pst": "12:00",
          "etb_version": "Post-Tax, Post-WB",
          "actual_trigger_day": "25-July-2023 13:00",
          "effective_trigger_day": "25-July-2023 12:00",
          "status": "COMPLETED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": "0.00000000",
          "us_gaap_etb_total": "0.00000000",
          "lg_etb_total": "0.00000000",
          "transformation_time": "27-07-2023 21:25:23 PM",
          "transformation_status": "Completed",
          "pre_check_status": "Passed",
          "created_time": "25-JUL-2023 12:00:08",
          "updated_time": "25-JUL-2023 12:00:08",
          "retrigger_request_time": "2023-07-26T00:00:00",
          "retrigger_status": "REQUESTED",
          "retrigger_by": "xiaomexu",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 529,
          "entity": "64RBK",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "16",
          "trigger_time_pst": "12:00",
          "etb_version": "Post-Tax, Post-WB",
          "actual_trigger_day": "25-July-2023 13:00",
          "effective_trigger_day": "25-July-2023 12:00",
          "status": "FAILED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "25-JUL-2023 12:00:08",
          "updated_time": "25-JUL-2023 12:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 529,
          "entity": "65RBK",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "16",
          "trigger_time_pst": "12:00",
          "etb_version": "Post-Tax, Post-WB",
          "actual_trigger_day": "25-July-2023 13:00",
          "effective_trigger_day": "25-July-2023 12:00",
          "status": "FAILED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "25-JUL-2023 12:00:08",
          "updated_time": "25-JUL-2023 12:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 529,
          "entity": "9T_CONS",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "16",
          "trigger_time_pst": "12:00",
          "etb_version": "Post-Tax, Post-WB",
          "actual_trigger_day": "25-July-2023 13:00",
          "effective_trigger_day": "25-July-2023 12:00",
          "status": "FAILED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "25-JUL-2023 12:00:08",
          "updated_time": "25-JUL-2023 12:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 529,
          "entity": "1Y",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "16",
          "trigger_time_pst": "12:00",
          "etb_version": "Post-Tax, Post-WB",
          "actual_trigger_day": "25-July-2023 13:00",
          "effective_trigger_day": "25-July-2023 12:00",
          "status": "FAILED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "25-JUL-2023 12:00:08",
          "updated_time": "25-JUL-2023 12:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 529,
          "entity": "CNJV",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "16",
          "trigger_time_pst": "12:00",
          "etb_version": "Post-Tax, Post-WB",
          "actual_trigger_day": "25-July-2023 13:00",
          "effective_trigger_day": "25-July-2023 12:00",
          "status": "FAILED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "25-JUL-2023 12:00:08",
          "updated_time": "25-JUL-2023 12:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 529,
          "entity": "63A",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "16",
          "trigger_time_pst": "12:00",
          "etb_version": "Post-Tax, Post-WB",
          "actual_trigger_day": "25-July-2023 13:00",
          "effective_trigger_day": "25-July-2023 12:00",
          "status": "FAILED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "25-JUL-2023 12:00:08",
          "updated_time": "25-JUL-2023 12:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 529,
          "entity": "55_CONS",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "16",
          "trigger_time_pst": "12:00",
          "etb_version": "Post-Tax, Post-WB",
          "actual_trigger_day": "25-July-2023 13:00",
          "effective_trigger_day": "25-July-2023 12:00",
          "status": "FAILED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "25-JUL-2023 12:00:08",
          "updated_time": "25-JUL-2023 12:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 529,
          "entity": "HA",
          "region": "LATAM_North",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "16",
          "trigger_time_pst": "12:00",
          "etb_version": "Post-Tax, Post-WB",
          "actual_trigger_day": "25-July-2023 13:00",
          "effective_trigger_day": "25-July-2023 12:00",
          "status": "FAILED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "25-JUL-2023 12:00:08",
          "updated_time": "25-JUL-2023 12:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 529,
          "entity": "B115",
          "region": "LATAM_North",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "16",
          "trigger_time_pst": "12:00",
          "etb_version": "Post-Tax, Post-WB",
          "actual_trigger_day": "25-July-2023 13:00",
          "effective_trigger_day": "25-July-2023 12:00",
          "status": "FAILED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "25-JUL-2023 12:00:08",
          "updated_time": "25-JUL-2023 12:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 529,
          "entity": "63RBK",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "16",
          "trigger_time_pst": "12:00",
          "etb_version": "Post-Tax, Post-WB",
          "actual_trigger_day": "25-July-2023 13:00",
          "effective_trigger_day": "25-July-2023 12:00",
          "status": "FAILED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "25-JUL-2023 12:00:08",
          "updated_time": "25-JUL-2023 12:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 529,
          "entity": "5C",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "16",
          "trigger_time_pst": "12:00",
          "etb_version": "Post-Tax, Post-WB",
          "actual_trigger_day": "25-July-2023 13:00",
          "effective_trigger_day": "25-July-2023 12:00",
          "status": "FAILED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "25-JUL-2023 12:00:08",
          "updated_time": "25-JUL-2023 12:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 529,
          "entity": "B423",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "16",
          "trigger_time_pst": "12:00",
          "etb_version": "Post-Tax, Post-WB",
          "actual_trigger_day": "25-July-2023 13:00",
          "effective_trigger_day": "25-July-2023 12:00",
          "status": "FAILED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "25-JUL-2023 12:00:08",
          "updated_time": "25-JUL-2023 12:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 529,
          "entity": "CD_CNY",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "16",
          "trigger_time_pst": "12:00",
          "etb_version": "Post-Tax, Post-WB",
          "actual_trigger_day": "25-July-2023 13:00",
          "effective_trigger_day": "25-July-2023 12:00",
          "status": "FAILED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "25-JUL-2023 12:00:08",
          "updated_time": "25-JUL-2023 12:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 529,
          "entity": "MS_CONS",
          "region": "LATAM_North",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "16",
          "trigger_time_pst": "12:00",
          "etb_version": "Post-Tax, Post-WB",
          "actual_trigger_day": "25-July-2023 13:00",
          "effective_trigger_day": "25-July-2023 12:00",
          "status": "FAILED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "25-JUL-2023 12:00:08",
          "updated_time": "25-JUL-2023 12:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 529,
          "entity": "PK",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "16",
          "trigger_time_pst": "12:00",
          "etb_version": "Post-Tax, Post-WB",
          "actual_trigger_day": "25-July-2023 13:00",
          "effective_trigger_day": "25-July-2023 12:00",
          "status": "FAILED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "25-JUL-2023 12:00:08",
          "updated_time": "25-JUL-2023 12:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 529,
          "entity": "63",
          "region": "LATAM_North",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "16",
          "trigger_time_pst": "12:00",
          "etb_version": "Post-Tax, Post-WB",
          "actual_trigger_day": "25-July-2023 13:00",
          "effective_trigger_day": "25-July-2023 12:00",
          "status": "FAILED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "25-JUL-2023 12:00:08",
          "updated_time": "25-JUL-2023 12:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 529,
          "entity": "CNICP",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "16",
          "trigger_time_pst": "12:00",
          "etb_version": "Post-Tax, Post-WB",
          "actual_trigger_day": "25-July-2023 13:00",
          "effective_trigger_day": "25-July-2023 12:00",
          "status": "FAILED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "25-JUL-2023 12:00:08",
          "updated_time": "25-JUL-2023 12:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 529,
          "entity": "66RBK",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "16",
          "trigger_time_pst": "12:00",
          "etb_version": "Post-Tax, Post-WB",
          "actual_trigger_day": "25-July-2023 13:00",
          "effective_trigger_day": "25-July-2023 12:00",
          "status": "FAILED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "25-JUL-2023 12:00:08",
          "updated_time": "25-JUL-2023 12:00:08",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 529,
          "entity": "FW_CONSOL",
          "region": "APAC",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "16",
          "trigger_time_pst": "12:00",
          "etb_version": "Post-Tax, Post-WB",
          "actual_trigger_day": "25-July-2023 13:00",
          "effective_trigger_day": "25-July-2023 12:00",
          "status": "FAILED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "25-JUL-2023 12:00:08",
          "updated_time": "25-JUL-2023 12:00:08",
          "retrigger_request_time": "2023-07-26T00:00:00",
          "retrigger_status": "REQUESTED",
          "retrigger_by": "xiaomexu",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 529,
          "entity": "MS",
          "region": "LATAM_North",
          "report_period": "01-JUN-2023",
          "report_month": "JUL",
          "trigger_day": "16",
          "trigger_time_pst": "12:00",
          "etb_version": "Post-Tax, Post-WB",
          "actual_trigger_day": "25-July-2023 13:00",
          "effective_trigger_day": "25-July-2023 12:00",
          "status": "FAILED",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "25-JUL-2023 12:00:08",
          "updated_time": "25-JUL-2023 12:00:08",
          "retrigger_request_time": "2023-07-28T00:00:00",
          "retrigger_status": "REQUESTED",
          "retrigger_by": "uyyogara",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 663,
          "entity": "KB",
          "region": "APAC",
          "report_period": "01-JUL-2023",
          "report_month": "AUG",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "24-August-2023 09:00",
          "effective_trigger_day": "14-August-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "MANUAL",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "24-AUG-2023 08:00:12",
          "updated_time": "24-AUG-2023 08:00:12",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "tezkhamz",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 664,
          "entity": "B745",
          "region": "APAC",
          "report_period": "01-AUG-2023",
          "report_month": "SEP",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "15-September-2023 13:00",
          "effective_trigger_day": "15-September-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "15-SEP-2023 12:00:13",
          "updated_time": "15-SEP-2023 12:00:13",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 664,
          "entity": "8O",
          "region": "APAC",
          "report_period": "01-AUG-2023",
          "report_month": "SEP",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "15-September-2023 13:00",
          "effective_trigger_day": "15-September-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "15-SEP-2023 12:00:13",
          "updated_time": "15-SEP-2023 12:00:13",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 664,
          "entity": "B776",
          "region": "APAC",
          "report_period": "01-AUG-2023",
          "report_month": "SEP",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "15-September-2023 13:00",
          "effective_trigger_day": "15-September-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "15-SEP-2023 12:00:13",
          "updated_time": "15-SEP-2023 12:00:13",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 664,
          "entity": "2I",
          "region": "APAC",
          "report_period": "01-AUG-2023",
          "report_month": "SEP",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "15-September-2023 13:00",
          "effective_trigger_day": "15-September-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "15-SEP-2023 12:00:13",
          "updated_time": "15-SEP-2023 12:00:13",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 664,
          "entity": "4E",
          "region": "APAC",
          "report_period": "01-AUG-2023",
          "report_month": "SEP",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "15-September-2023 13:00",
          "effective_trigger_day": "15-September-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "15-SEP-2023 12:00:13",
          "updated_time": "15-SEP-2023 12:00:13",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 664,
          "entity": "1I",
          "region": "APAC",
          "report_period": "01-AUG-2023",
          "report_month": "SEP",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "15-September-2023 13:00",
          "effective_trigger_day": "15-September-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "15-SEP-2023 12:00:13",
          "updated_time": "15-SEP-2023 12:00:13",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 664,
          "entity": "6H",
          "region": "APAC",
          "report_period": "01-AUG-2023",
          "report_month": "SEP",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "15-September-2023 13:00",
          "effective_trigger_day": "15-September-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "15-SEP-2023 12:00:13",
          "updated_time": "15-SEP-2023 12:00:13",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 664,
          "entity": "PH_AOSP_LOCAL",
          "region": "APAC",
          "report_period": "01-AUG-2023",
          "report_month": "SEP",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "15-September-2023 13:00",
          "effective_trigger_day": "15-September-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "15-SEP-2023 12:00:13",
          "updated_time": "15-SEP-2023 12:00:13",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 664,
          "entity": "CN_CNICP_LOCAL",
          "region": "APAC",
          "report_period": "01-AUG-2023",
          "report_month": "SEP",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "15-September-2023 13:00",
          "effective_trigger_day": "15-September-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "15-SEP-2023 12:00:13",
          "updated_time": "15-SEP-2023 12:00:13",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 664,
          "entity": "B676",
          "region": "APAC",
          "report_period": "01-AUG-2023",
          "report_month": "SEP",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "15-September-2023 13:00",
          "effective_trigger_day": "15-September-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "15-SEP-2023 12:00:13",
          "updated_time": "15-SEP-2023 12:00:13",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 664,
          "entity": "QL",
          "region": "APAC",
          "report_period": "01-AUG-2023",
          "report_month": "SEP",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "15-September-2023 13:00",
          "effective_trigger_day": "15-September-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "15-SEP-2023 12:00:13",
          "updated_time": "15-SEP-2023 12:00:13",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 664,
          "entity": "B645",
          "region": "APAC",
          "report_period": "01-AUG-2023",
          "report_month": "SEP",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "15-September-2023 13:00",
          "effective_trigger_day": "15-September-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "15-SEP-2023 12:00:13",
          "updated_time": "15-SEP-2023 12:00:13",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 664,
          "entity": "B422",
          "region": "APAC",
          "report_period": "01-AUG-2023",
          "report_month": "SEP",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "15-September-2023 13:00",
          "effective_trigger_day": "15-September-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "15-SEP-2023 12:00:13",
          "updated_time": "15-SEP-2023 12:00:13",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 664,
          "entity": "V8",
          "region": "APAC",
          "report_period": "01-AUG-2023",
          "report_month": "SEP",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "15-September-2023 13:00",
          "effective_trigger_day": "15-September-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "15-SEP-2023 12:00:13",
          "updated_time": "15-SEP-2023 12:00:13",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 664,
          "entity": "CN_CNJV_LOCAL",
          "region": "APAC",
          "report_period": "01-AUG-2023",
          "report_month": "SEP",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "15-September-2023 13:00",
          "effective_trigger_day": "15-September-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "15-SEP-2023 12:00:13",
          "updated_time": "15-SEP-2023 12:00:13",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 664,
          "entity": "B400",
          "region": "APAC",
          "report_period": "01-AUG-2023",
          "report_month": "SEP",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "15-September-2023 13:00",
          "effective_trigger_day": "15-September-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "15-SEP-2023 12:00:13",
          "updated_time": "15-SEP-2023 12:00:13",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 664,
          "entity": "HS",
          "region": "APAC",
          "report_period": "01-AUG-2023",
          "report_month": "SEP",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "15-September-2023 13:00",
          "effective_trigger_day": "15-September-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "15-SEP-2023 12:00:13",
          "updated_time": "15-SEP-2023 12:00:13",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 664,
          "entity": "HW",
          "region": "APAC",
          "report_period": "01-AUG-2023",
          "report_month": "SEP",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "15-September-2023 13:00",
          "effective_trigger_day": "15-September-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "15-SEP-2023 12:00:13",
          "updated_time": "15-SEP-2023 12:00:13",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 664,
          "entity": "DZ",
          "region": "APAC",
          "report_period": "01-AUG-2023",
          "report_month": "SEP",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "15-September-2023 13:00",
          "effective_trigger_day": "15-September-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "15-SEP-2023 12:00:13",
          "updated_time": "15-SEP-2023 12:00:13",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 664,
          "entity": "DG",
          "region": "APAC",
          "report_period": "01-AUG-2023",
          "report_month": "SEP",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "15-September-2023 13:00",
          "effective_trigger_day": "15-September-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "15-SEP-2023 12:00:13",
          "updated_time": "15-SEP-2023 12:00:13",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 664,
          "entity": "B644",
          "region": "APAC",
          "report_period": "01-AUG-2023",
          "report_month": "SEP",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "15-September-2023 13:00",
          "effective_trigger_day": "15-September-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "15-SEP-2023 12:00:13",
          "updated_time": "15-SEP-2023 12:00:13",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 664,
          "entity": "QK",
          "region": "APAC",
          "report_period": "01-AUG-2023",
          "report_month": "SEP",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "15-September-2023 13:00",
          "effective_trigger_day": "15-September-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "15-SEP-2023 12:00:13",
          "updated_time": "15-SEP-2023 12:00:13",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 664,
          "entity": "HA",
          "region": "APAC",
          "report_period": "01-AUG-2023",
          "report_month": "SEP",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "15-September-2023 13:00",
          "effective_trigger_day": "15-September-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "15-SEP-2023 12:00:13",
          "updated_time": "15-SEP-2023 12:00:13",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 664,
          "entity": "Z4",
          "region": "APAC",
          "report_period": "01-AUG-2023",
          "report_month": "SEP",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "15-September-2023 13:00",
          "effective_trigger_day": "15-September-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "15-SEP-2023 12:00:13",
          "updated_time": "15-SEP-2023 12:00:13",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 664,
          "entity": "RT",
          "region": "APAC",
          "report_period": "01-AUG-2023",
          "report_month": "SEP",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "15-September-2023 13:00",
          "effective_trigger_day": "15-September-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "15-SEP-2023 12:00:13",
          "updated_time": "15-SEP-2023 12:00:13",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 664,
          "entity": "BI",
          "region": "APAC",
          "report_period": "01-AUG-2023",
          "report_month": "SEP",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "15-September-2023 13:00",
          "effective_trigger_day": "15-September-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "15-SEP-2023 12:00:13",
          "updated_time": "15-SEP-2023 12:00:13",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 664,
          "entity": "63",
          "region": "APAC",
          "report_period": "01-AUG-2023",
          "report_month": "SEP",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "15-September-2023 13:00",
          "effective_trigger_day": "15-September-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "15-SEP-2023 12:00:13",
          "updated_time": "15-SEP-2023 12:00:13",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 664,
          "entity": "KA",
          "region": "APAC",
          "report_period": "01-AUG-2023",
          "report_month": "SEP",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "15-September-2023 13:00",
          "effective_trigger_day": "15-September-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "15-SEP-2023 12:00:13",
          "updated_time": "15-SEP-2023 12:00:13",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 664,
          "entity": "B581",
          "region": "APAC",
          "report_period": "01-AUG-2023",
          "report_month": "SEP",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "15-September-2023 13:00",
          "effective_trigger_day": "15-September-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "15-SEP-2023 12:00:13",
          "updated_time": "15-SEP-2023 12:00:13",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 664,
          "entity": "B900",
          "region": "APAC",
          "report_period": "01-AUG-2023",
          "report_month": "SEP",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "15-September-2023 13:00",
          "effective_trigger_day": "15-September-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "15-SEP-2023 12:00:13",
          "updated_time": "15-SEP-2023 12:00:13",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 664,
          "entity": "B112",
          "region": "APAC",
          "report_period": "01-AUG-2023",
          "report_month": "SEP",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "15-September-2023 13:00",
          "effective_trigger_day": "15-September-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "15-SEP-2023 12:00:13",
          "updated_time": "15-SEP-2023 12:00:13",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 664,
          "entity": "B869",
          "region": "APAC",
          "report_period": "01-AUG-2023",
          "report_month": "SEP",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "15-September-2023 13:00",
          "effective_trigger_day": "15-September-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "15-SEP-2023 12:00:13",
          "updated_time": "15-SEP-2023 12:00:13",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 664,
          "entity": "B860",
          "region": "APAC",
          "report_period": "01-AUG-2023",
          "report_month": "SEP",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "15-September-2023 13:00",
          "effective_trigger_day": "15-September-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "15-SEP-2023 12:00:13",
          "updated_time": "15-SEP-2023 12:00:13",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 664,
          "entity": "R6",
          "region": "APAC",
          "report_period": "01-AUG-2023",
          "report_month": "SEP",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "15-September-2023 13:00",
          "effective_trigger_day": "15-September-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "15-SEP-2023 12:00:13",
          "updated_time": "15-SEP-2023 12:00:13",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 664,
          "entity": "B199",
          "region": "APAC",
          "report_period": "01-AUG-2023",
          "report_month": "SEP",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "15-September-2023 13:00",
          "effective_trigger_day": "15-September-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "15-SEP-2023 12:00:13",
          "updated_time": "15-SEP-2023 12:00:13",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 664,
          "entity": "B180",
          "region": "APAC",
          "report_period": "01-AUG-2023",
          "report_month": "SEP",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "15-September-2023 13:00",
          "effective_trigger_day": "15-September-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "15-SEP-2023 12:00:13",
          "updated_time": "15-SEP-2023 12:00:13",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 664,
          "entity": "9T",
          "region": "APAC",
          "report_period": "01-AUG-2023",
          "report_month": "SEP",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "15-September-2023 13:00",
          "effective_trigger_day": "15-September-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "15-SEP-2023 12:00:13",
          "updated_time": "15-SEP-2023 12:00:13",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 664,
          "entity": "8V",
          "region": "APAC",
          "report_period": "01-AUG-2023",
          "report_month": "SEP",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "15-September-2023 13:00",
          "effective_trigger_day": "15-September-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "15-SEP-2023 12:00:13",
          "updated_time": "15-SEP-2023 12:00:13",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 664,
          "entity": "B179",
          "region": "APAC",
          "report_period": "01-AUG-2023",
          "report_month": "SEP",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "15-September-2023 13:00",
          "effective_trigger_day": "15-September-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "15-SEP-2023 12:00:13",
          "updated_time": "15-SEP-2023 12:00:13",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 664,
          "entity": "AU_55_CONS_LOCAL",
          "region": "APAC",
          "report_period": "01-AUG-2023",
          "report_month": "SEP",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "15-September-2023 13:00",
          "effective_trigger_day": "15-September-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "15-SEP-2023 12:00:13",
          "updated_time": "15-SEP-2023 12:00:13",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 664,
          "entity": "65",
          "region": "APAC",
          "report_period": "01-AUG-2023",
          "report_month": "SEP",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "15-September-2023 13:00",
          "effective_trigger_day": "15-September-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "15-SEP-2023 12:00:13",
          "updated_time": "15-SEP-2023 12:00:13",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 664,
          "entity": "ML",
          "region": "APAC",
          "report_period": "01-AUG-2023",
          "report_month": "SEP",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "15-September-2023 13:00",
          "effective_trigger_day": "15-September-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "15-SEP-2023 12:00:13",
          "updated_time": "15-SEP-2023 12:00:13",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 664,
          "entity": "NX",
          "region": "APAC",
          "report_period": "01-AUG-2023",
          "report_month": "SEP",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "15-September-2023 13:00",
          "effective_trigger_day": "15-September-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "15-SEP-2023 12:00:13",
          "updated_time": "15-SEP-2023 12:00:13",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 664,
          "entity": "CN_FW_CONSOL_LOCAL",
          "region": "APAC",
          "report_period": "01-AUG-2023",
          "report_month": "SEP",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "15-September-2023 13:00",
          "effective_trigger_day": "15-September-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "15-SEP-2023 12:00:13",
          "updated_time": "15-SEP-2023 12:00:13",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 664,
          "entity": "B427",
          "region": "APAC",
          "report_period": "01-AUG-2023",
          "report_month": "SEP",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "15-September-2023 13:00",
          "effective_trigger_day": "15-September-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "15-SEP-2023 12:00:13",
          "updated_time": "15-SEP-2023 12:00:13",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 664,
          "entity": "B9",
          "region": "APAC",
          "report_period": "01-AUG-2023",
          "report_month": "SEP",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "15-September-2023 13:00",
          "effective_trigger_day": "15-September-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "15-SEP-2023 12:00:13",
          "updated_time": "15-SEP-2023 12:00:13",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 664,
          "entity": "7J",
          "region": "APAC",
          "report_period": "01-AUG-2023",
          "report_month": "SEP",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "15-September-2023 13:00",
          "effective_trigger_day": "15-September-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "15-SEP-2023 12:00:13",
          "updated_time": "15-SEP-2023 12:00:13",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 664,
          "entity": "8W",
          "region": "APAC",
          "report_period": "01-AUG-2023",
          "report_month": "SEP",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "15-September-2023 13:00",
          "effective_trigger_day": "15-September-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "15-SEP-2023 12:00:13",
          "updated_time": "15-SEP-2023 12:00:13",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 664,
          "entity": "SG_AAPH_LOCAL",
          "region": "APAC",
          "report_period": "01-AUG-2023",
          "report_month": "SEP",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "15-September-2023 13:00",
          "effective_trigger_day": "15-September-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "15-SEP-2023 12:00:13",
          "updated_time": "15-SEP-2023 12:00:13",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 664,
          "entity": "7G",
          "region": "APAC",
          "report_period": "01-AUG-2023",
          "report_month": "SEP",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "15-September-2023 13:00",
          "effective_trigger_day": "15-September-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "15-SEP-2023 12:00:13",
          "updated_time": "15-SEP-2023 12:00:13",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 664,
          "entity": "B439",
          "region": "APAC",
          "report_period": "01-AUG-2023",
          "report_month": "SEP",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "15-September-2023 13:00",
          "effective_trigger_day": "15-September-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "15-SEP-2023 12:00:13",
          "updated_time": "15-SEP-2023 12:00:13",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 664,
          "entity": "E3",
          "region": "APAC",
          "report_period": "01-AUG-2023",
          "report_month": "SEP",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "15-September-2023 13:00",
          "effective_trigger_day": "15-September-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "15-SEP-2023 12:00:13",
          "updated_time": "15-SEP-2023 12:00:13",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 664,
          "entity": "B672",
          "region": "APAC",
          "report_period": "01-AUG-2023",
          "report_month": "SEP",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "15-September-2023 13:00",
          "effective_trigger_day": "15-September-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "15-SEP-2023 12:00:13",
          "updated_time": "15-SEP-2023 12:00:13",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 664,
          "entity": "B451",
          "region": "APAC",
          "report_period": "01-AUG-2023",
          "report_month": "SEP",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "15-September-2023 13:00",
          "effective_trigger_day": "15-September-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "15-SEP-2023 12:00:13",
          "updated_time": "15-SEP-2023 12:00:13",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 664,
          "entity": "B928",
          "region": "APAC",
          "report_period": "01-AUG-2023",
          "report_month": "SEP",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "15-September-2023 13:00",
          "effective_trigger_day": "15-September-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "15-SEP-2023 12:00:13",
          "updated_time": "15-SEP-2023 12:00:13",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 664,
          "entity": "B743",
          "region": "APAC",
          "report_period": "01-AUG-2023",
          "report_month": "SEP",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "15-September-2023 13:00",
          "effective_trigger_day": "15-September-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "15-SEP-2023 12:00:13",
          "updated_time": "15-SEP-2023 12:00:13",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 664,
          "entity": "55",
          "region": "APAC",
          "report_period": "01-AUG-2023",
          "report_month": "SEP",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "15-September-2023 13:00",
          "effective_trigger_day": "15-September-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "15-SEP-2023 12:00:13",
          "updated_time": "15-SEP-2023 12:00:13",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 664,
          "entity": "B184",
          "region": "APAC",
          "report_period": "01-AUG-2023",
          "report_month": "SEP",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "15-September-2023 13:00",
          "effective_trigger_day": "15-September-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "15-SEP-2023 12:00:13",
          "updated_time": "15-SEP-2023 12:00:13",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 664,
          "entity": "MJ",
          "region": "APAC",
          "report_period": "01-AUG-2023",
          "report_month": "SEP",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "15-September-2023 13:00",
          "effective_trigger_day": "15-September-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "15-SEP-2023 12:00:13",
          "updated_time": "15-SEP-2023 12:00:13",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 664,
          "entity": "BC61",
          "region": "APAC",
          "report_period": "01-AUG-2023",
          "report_month": "SEP",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "15-September-2023 13:00",
          "effective_trigger_day": "15-September-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "15-SEP-2023 12:00:13",
          "updated_time": "15-SEP-2023 12:00:13",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 664,
          "entity": "DX",
          "region": "APAC",
          "report_period": "01-AUG-2023",
          "report_month": "SEP",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "15-September-2023 13:00",
          "effective_trigger_day": "15-September-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "15-SEP-2023 12:00:13",
          "updated_time": "15-SEP-2023 12:00:13",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 664,
          "entity": "CN_CNWFOE_LOCAL",
          "region": "APAC",
          "report_period": "01-AUG-2023",
          "report_month": "SEP",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "15-September-2023 13:00",
          "effective_trigger_day": "15-September-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "15-SEP-2023 12:00:13",
          "updated_time": "15-SEP-2023 12:00:13",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 664,
          "entity": "TM",
          "region": "APAC",
          "report_period": "01-AUG-2023",
          "report_month": "SEP",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "15-September-2023 13:00",
          "effective_trigger_day": "15-September-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "15-SEP-2023 12:00:13",
          "updated_time": "15-SEP-2023 12:00:13",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 664,
          "entity": "QM",
          "region": "APAC",
          "report_period": "01-AUG-2023",
          "report_month": "SEP",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "15-September-2023 13:00",
          "effective_trigger_day": "15-September-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "15-SEP-2023 12:00:13",
          "updated_time": "15-SEP-2023 12:00:13",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 664,
          "entity": "KB",
          "region": "APAC",
          "report_period": "01-AUG-2023",
          "report_month": "SEP",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "15-September-2023 13:00",
          "effective_trigger_day": "15-September-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "15-SEP-2023 12:00:13",
          "updated_time": "15-SEP-2023 12:00:13",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 664,
          "entity": "FX",
          "region": "APAC",
          "report_period": "01-AUG-2023",
          "report_month": "SEP",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "15-September-2023 13:00",
          "effective_trigger_day": "15-September-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "15-SEP-2023 12:00:13",
          "updated_time": "15-SEP-2023 12:00:13",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 664,
          "entity": "B859",
          "region": "APAC",
          "report_period": "01-AUG-2023",
          "report_month": "SEP",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "15-September-2023 13:00",
          "effective_trigger_day": "15-September-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "15-SEP-2023 12:00:13",
          "updated_time": "15-SEP-2023 12:00:13",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 664,
          "entity": "B872",
          "region": "APAC",
          "report_period": "01-AUG-2023",
          "report_month": "SEP",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "15-September-2023 13:00",
          "effective_trigger_day": "15-September-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "15-SEP-2023 12:00:13",
          "updated_time": "15-SEP-2023 12:00:13",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 664,
          "entity": "J3",
          "region": "APAC",
          "report_period": "01-AUG-2023",
          "report_month": "SEP",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "15-September-2023 13:00",
          "effective_trigger_day": "15-September-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "15-SEP-2023 12:00:13",
          "updated_time": "15-SEP-2023 12:00:13",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 664,
          "entity": "EA",
          "region": "APAC",
          "report_period": "01-AUG-2023",
          "report_month": "SEP",
          "trigger_day": "10",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "15-September-2023 13:00",
          "effective_trigger_day": "15-September-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "15-SEP-2023 12:00:13",
          "updated_time": "15-SEP-2023 12:00:13",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 665,
          "entity": "B106",
          "region": "LATAM_North",
          "report_period": "01-AUG-2023",
          "report_month": "SEP",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "18-September-2023 13:00",
          "effective_trigger_day": "18-September-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "18-SEP-2023 12:00:11",
          "updated_time": "18-SEP-2023 12:00:11",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 665,
          "entity": "MF",
          "region": "LATAM_North",
          "report_period": "01-AUG-2023",
          "report_month": "SEP",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "18-September-2023 13:00",
          "effective_trigger_day": "18-September-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "18-SEP-2023 12:00:11",
          "updated_time": "18-SEP-2023 12:00:11",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 665,
          "entity": "CP",
          "region": "LATAM_North",
          "report_period": "01-AUG-2023",
          "report_month": "SEP",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "18-September-2023 13:00",
          "effective_trigger_day": "18-September-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "18-SEP-2023 12:00:11",
          "updated_time": "18-SEP-2023 12:00:11",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 665,
          "entity": "B423",
          "region": "LATAM_North",
          "report_period": "01-AUG-2023",
          "report_month": "SEP",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "18-September-2023 13:00",
          "effective_trigger_day": "18-September-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "18-SEP-2023 12:00:11",
          "updated_time": "18-SEP-2023 12:00:11",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 665,
          "entity": "B622",
          "region": "LATAM_North",
          "report_period": "01-AUG-2023",
          "report_month": "SEP",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "18-September-2023 13:00",
          "effective_trigger_day": "18-September-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "18-SEP-2023 12:00:11",
          "updated_time": "18-SEP-2023 12:00:11",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 665,
          "entity": "M2",
          "region": "LATAM_North",
          "report_period": "01-AUG-2023",
          "report_month": "SEP",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "18-September-2023 13:00",
          "effective_trigger_day": "18-September-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "18-SEP-2023 12:00:11",
          "updated_time": "18-SEP-2023 12:00:11",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 665,
          "entity": "75",
          "region": "LATAM_North",
          "report_period": "01-AUG-2023",
          "report_month": "SEP",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "18-September-2023 13:00",
          "effective_trigger_day": "18-September-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "18-SEP-2023 12:00:11",
          "updated_time": "18-SEP-2023 12:00:11",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 665,
          "entity": "5C",
          "region": "LATAM_North",
          "report_period": "01-AUG-2023",
          "report_month": "SEP",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "18-September-2023 13:00",
          "effective_trigger_day": "18-September-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "18-SEP-2023 12:00:11",
          "updated_time": "18-SEP-2023 12:00:11",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 665,
          "entity": "MH",
          "region": "LATAM_North",
          "report_period": "01-AUG-2023",
          "report_month": "SEP",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "18-September-2023 13:00",
          "effective_trigger_day": "18-September-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "18-SEP-2023 12:00:11",
          "updated_time": "18-SEP-2023 12:00:11",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 665,
          "entity": "MS",
          "region": "LATAM_North",
          "report_period": "01-AUG-2023",
          "report_month": "SEP",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "18-September-2023 13:00",
          "effective_trigger_day": "18-September-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "18-SEP-2023 12:00:11",
          "updated_time": "18-SEP-2023 12:00:11",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 665,
          "entity": "B407",
          "region": "LATAM_North",
          "report_period": "01-AUG-2023",
          "report_month": "SEP",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "18-September-2023 13:00",
          "effective_trigger_day": "18-September-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "18-SEP-2023 12:00:11",
          "updated_time": "18-SEP-2023 12:00:11",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 665,
          "entity": "B437",
          "region": "LATAM_North",
          "report_period": "01-AUG-2023",
          "report_month": "SEP",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "18-September-2023 13:00",
          "effective_trigger_day": "18-September-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "18-SEP-2023 12:00:11",
          "updated_time": "18-SEP-2023 12:00:11",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 665,
          "entity": "MR",
          "region": "LATAM_North",
          "report_period": "01-AUG-2023",
          "report_month": "SEP",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "18-September-2023 13:00",
          "effective_trigger_day": "18-September-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "18-SEP-2023 12:00:11",
          "updated_time": "18-SEP-2023 12:00:11",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 665,
          "entity": "MX_MSMR_LOCAL",
          "region": "LATAM_North",
          "report_period": "01-AUG-2023",
          "report_month": "SEP",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "18-September-2023 13:00",
          "effective_trigger_day": "18-September-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "18-SEP-2023 12:00:11",
          "updated_time": "18-SEP-2023 12:00:11",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 665,
          "entity": "GC",
          "region": "LATAM_North",
          "report_period": "01-AUG-2023",
          "report_month": "SEP",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "18-September-2023 13:00",
          "effective_trigger_day": "18-September-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "18-SEP-2023 12:00:11",
          "updated_time": "18-SEP-2023 12:00:11",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 665,
          "entity": "B115",
          "region": "LATAM_North",
          "report_period": "01-AUG-2023",
          "report_month": "SEP",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "18-September-2023 13:00",
          "effective_trigger_day": "18-September-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "18-SEP-2023 12:00:11",
          "updated_time": "18-SEP-2023 12:00:11",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        },
        {
          "batch_id": 665,
          "entity": "DW",
          "region": "LATAM_North",
          "report_period": "01-AUG-2023",
          "report_month": "SEP",
          "trigger_day": "11",
          "trigger_time_pst": "12:00",
          "etb_version": "Pre-Tax/Pre-WB",
          "actual_trigger_day": "18-September-2023 13:00",
          "effective_trigger_day": "18-September-2023 12:00",
          "status": "IN_PROGRESS",
          "trigger_request_type": "AUTO",
          "local_gaap_etb_total": null,
          "us_gaap_etb_total": null,
          "lg_etb_total": null,
          "transformation_time": null,
          "transformation_status": null,
          "pre_check_status": null,
          "created_time": "18-SEP-2023 12:00:11",
          "updated_time": "18-SEP-2023 12:00:11",
          "retrigger_request_time": null,
          "retrigger_status": null,
          "retrigger_by": "",
          "created_by": null,
          "updated_by": null
        }
      ];
}