import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  AppLayout,
  HelpPanel,
  Box,
  SpaceBetween,
  Header,
  Button,
  BreadcrumbGroup,
  Container,
  Cards,
  Icon,
  Spinner,
  TextFilter,
  Pagination,
  CollectionPreferences,
  Link,
  Flashbar,
  ProgressBar,
  Table,
  Input,
  Select
} from "@amzn/awsui-components-react";

import axios from "axios";

import SideNav from "./SideNav";
import { useHistory, useLocation } from "react-router-dom";
import checkPageAccess from "../../checkPageAccess";
import AccessDenied from "../../AccessDenied";
import WaitForPageAccess from "../../WaitForPageAccess";

import { AgGridReact } from "ag-grid-react";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";

import { API, graphqlOperation } from "aws-amplify";
import { useAuth } from "../../context/AuthContextProvider";
import {select_default_value_all } from "./Constants";
import {setFilterOptions, setFilterOptionsWithAll} from "./FilterUtilities"
import { historyData } from "./Values";


export default () => {
  const userAuthenticationDetails = useAuth();

  const userLDAPS = userAuthenticationDetails.USER_LDAP_GROUPS;
  const dispatch = useDispatch();

  const history = useHistory();
 
  const [statusUpdate, setStatusUpdate] = useState(
    "No status update at the moment."
  );
  const [showLoadingIcon, setShowLoadingIcon] = useState(true);

  const [
    selectedItems,
    setSelectedItems
  ] = React.useState([{ name: "Item 2" }]);
  const currentAppId = useSelector(
    (globalState) => globalState.auth.currentAppId
  );
  const isCurrentAppAuthorised = useSelector(
    (globalState) => globalState.auth.isCurrentAppAuthorized
  );


  useEffect(() => {
   


    return () => {
      // this now gets called when the component unmounts
      // subscription.unsubscribe();
    };
  }, [statusUpdate]);


  const defaultColDef = {
    // set filtering on for all columns
    filter: true,
    filterParams: {
      buttons: ["reset"],
      debounceMs: 200,
    },
  };

  const preCheckIcon = props => (
    props.data.pre_check_status === 'Passed' ?
      <Box>
        <Icon name='status-positive' variant='success' ></Icon>
      </Box>

      : props.data.pre_check_status === 'Failed' ? <Box>
        <Icon name='status-negative' variant='warning' ></Icon>
      </Box>
        : <span>{props.data.pre_check_status}</span>

  )


  const onFollowHandler = (ev) => {
    ev.preventDefault();
    if (ev.detail.href) {
      history.push(ev.detail.href.substring(1));
    }
  };

  const breadItems = [
    { text: "Home", href: "/Home" },
    { text: "ATeam BI", href: "/ATeamBIHome" },
    { text: "Frisco", href: "/frisco" },
  ];

  const content = (
    <SpaceBetween direction="vertical" size="m">
          <Box></Box>
          <Container
            header={
              <Header
                variant="h2"
                actions={
                  <SpaceBetween direction="horizontal" size="xs">
                    {showLoadingIcon ? (
                      <Box>
                        <Spinner size="normal"></Spinner>
                      </Box>
                    ) : (
                      <Box></Box>
                    )}
                  </SpaceBetween>
                }
              >
                FRISCO workflows
              </Header>
            }
          >
            <div
              className="ag-theme-alpine"
            >
<Cards
      onSelectionChange={({ detail }) =>
        setSelectedItems(detail.selectedItems)
      }
      selectedItems={selectedItems}
      ariaLabels={{
        itemSelectionLabel: (e, n) => `select ${n.name}`,
        selectionGroupLabel: "Item selection"
      }}
      cardDefinition={{
        header: item => (
          <Link href="/frisco/workflow1" fontSize="heading-m">
            {item.name}
          </Link>
        ),
        sections: [
          {
            id: "description",
            header: "Description",
            content: item => item.description
          },
          {
            id: "type",
            header: "Type",
            content: item => item.type
          },
          {
            id: "size",
            header: "Size",
            content: item => item.size
          }
        ]
      }}
      cardsPerRow={[
        { cards: 1 },
        { minWidth: 500, cards: 2 }
      ]}
      items={[
        {
          name: "Workflow 1",
          alt: "First",
          description: "This is the first item",
          type: "1A",
          size: "Small"
        },
        {
          name: "Workflow 2",
          alt: "Second",
          description: "This is the second item",
          type: "1B",
          size: "Large"
        },
        {
          name: "Workflow 3",
          alt: "Third",
          description: "This is the third item",
          type: "1A",
          size: "Large"
        },
        {
          name: "Workflow 4",
          alt: "Fourth",
          description: "This is the fourth item",
          type: "2A",
          size: "Small"
        },
        {
          name: "Workflow 5",
          alt: "Fifth",
          description: "This is the fifth item",
          type: "2A",
          size: "Large"
        },
        {
          name: "Workflow 6",
          alt: "Sixth",
          description: "This is the sixth item",
          type: "1A",
          size: "Small"
        }
      ]}
      loadingText="Loading resources"
      trackBy="name"
      visibleSections={["description", "type", "size"]}
      empty={
        <Box
          margin={{ vertical: "xs" }}
          textAlign="center"
          color="inherit"
        >
          <SpaceBetween size="m">
            <b>No resources</b>
            <Button>Create resource</Button>
          </SpaceBetween>
        </Box>
      }
      filter={
        <TextFilter filteringPlaceholder="Find resources" />
      }
      header={
        <Header
          counter={
            selectedItems.length
              ? "(" + selectedItems.length + "/10)"
              : "(10)"
          }
        >
          Common cards with selection
        </Header>
      }
      pagination={
        <Pagination currentPageIndex={1} pagesCount={2} />
      }
      preferences={
        <CollectionPreferences
          title="Preferences"
          confirmLabel="Confirm"
          cancelLabel="Cancel"
          preferences={{
            pageSize: 6,
            visibleContent: [
              "description",
              "type",
              "size"
            ]
          }}
          pageSizePreference={{
            title: "Page size",
            options: [
              { value: 6, label: "6 resources" },
              { value: 12, label: "12 resources" }
            ]
          }}
          visibleContentPreference={{
            title: "Select visible content",
            options: [
              {
                label: "Main distribution properties",
                options: [
                  {
                    id: "description",
                    label: "Description"
                  },
                  { id: "type", label: "Type" },
                  { id: "size", label: "Size" }
                ]
              }
            ]
          }}
        />
      }
    />
            </div>
          </Container>
    </SpaceBetween>
  );


  return (
    <AppLayout
      breadcrumbs={
        <BreadcrumbGroup
          items={breadItems}
          onFollow={(event) => onFollowHandler(event)}
        ></BreadcrumbGroup>
      }
      content={content}
      navigation={<SideNav activeHref="/TriggerHistory" />}
      headerSelector="#TopBar"
      navigationHide={false}
      toolsHide={true}
      headerVariant="high-contrast"
    ></AppLayout>
  );
};

