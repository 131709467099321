import React, { useState, useEffect } from "react";
import {
    Box,
    Input,
    Button
} from "@amzn/awsui-components-react";

const CustomGroupsForAppsWithReloadNotifiers = (props) => {
    var [rows, setRows] = useState([]);
    useEffect(() => {
        loadRows()
        return () => {
        };
    }, []);

    useEffect(() => {
        // console.log(props,getMergedInputValues())
        props.updateValue(props.field, getMergedInputValues())
    }, [rows])

    useEffect(() => {
        if(props?.reloadNotifiers?.hasOwnProperty(props.field) && props.reloadNotifiers[props.field]){
            loadRows()
            props.setReloadNotifier(props.field, false)
        }
    }, [props?.reloadNotifiers])

    const getMergedInputValues = () => {
        var filtered = rows.filter(function (row) {
            return (row['group_name'] != null);
        });
        return filtered
    }

    const onEdit = (value, index) => {
        rows[index]['group_name'] = value
        setRows([...rows])
    }

    const getInputItem = (value, index) => {
        return <div className='rows' key={index}>
            <div className="row" >
                <Input
                onChange={({ detail }) => onEdit(detail.value, index)}
                value={value['group_name']}
            /></div>
            <div className="row"><Button iconName="close" variant="icon" onClick={() => {
                deleteRow(index)
            }} /></div>
        </div>
    }
    const deleteRow = (index) => {
        rows.splice(index, 1)
        setRows([...rows])
    }
    const addRow = () => {
        var newRow = {}
        newRow['group_name'] = null
        newRow['description'] = ''
        newRow['app_id'] = props.appId
        setRows([...rows, newRow])
    }
    const getAddButton = () => {
        return <div> <Button onClick={addRow}>Add</Button> </div>
    }

    const loadRows = () => {
        let values = props.getValues(props.field)
        rows=[];
        values.forEach(element => {
            if(element['description'] == null){
                element['description']=''   
            }
            rows.push(element)
        });
        setRows([...rows])
    }

    return (
        <Box>

            {rows? (Array.from(rows).map((value, index) => {
                return getInputItem(value, index);
            })) : ''}

            {
                getAddButton()
            }
        </Box>
    )
}

export default CustomGroupsForAppsWithReloadNotifiers;