import { select_default_value_all } from "./Constants";

export const setFilterOptions = (responseArray, key) => {
    let regions = new Set()
    let filterRegions = []
    responseArray?.forEach((item) => {
      regions.add(item[key])
    })
    regions.forEach((item) => {
      filterRegions.push({ label :item , value :item})
    })
    return filterRegions
  }

  export const setFilterOptionsWithAll = (responseArray, key) => {
    let filterRegions = setFilterOptions(responseArray, key)
    filterRegions.push(select_default_value_all)
    return filterRegions
  }