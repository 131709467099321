import React, {useState, useEffect} from 'react';

import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from "react-router-dom";


import { AppLayout, BreadcrumbGroup, 
            Box, SpaceBetween, Container,
            Header,Table, Button, Spinner, Alert, Modal
} from '@amzn/awsui-components-react';

import SideNav from './SideNav';
import appSettings from './app_settings';


import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

import { setCurrentAppId } from '../../store/userAuthSlice';

import AccessDenied from '../../AccessDenied'
import WaitForPageAccess from '../../WaitForPageAccess'

import {getBoffinComputesWithStartedStatus, updateBoffinComputeLockStatus} from './BoffinAPIs'
import {checkAppAdminAccess} from '../../checkPageAccess'



const BoffinUnlockCompute = () => {
    const history = useHistory();
    const currentAppId = useSelector((globalState)=>globalState.auth.currentAppId)
    const isCurrentAppAuthorised = useSelector((globalState)=>globalState.auth.isCurrentAppAuthorized)
    const [isCheckingAccess, setIsCheckingAccess] = useState(true);
    const [isAuthorized, setIsAuthorized] = useState();
    const dispatch = useDispatch()
    const [showLoadingIcon, setShowLoadingIcon] = useState(true);
    const [computes, setComputes] = useState([])
    const [unlockedCompute, setUnlockedCompute] = useState()
    const [showComputeUnlockedModal, setShowComputeUnlockedModal] = useState(false)
    const currentStage = useSelector((globalState)=>globalState.auth.currentStage)
    const currentUser = useSelector((globalState)=>globalState.auth.userId)
    const [canUnlockCompute, setCanUnlockCompute] = useState()


    

    useEffect(() => {
    
        if (currentAppId !== appSettings.appId){
          dispatch(setCurrentAppId(appSettings.appId))
        }
        setIsAuthorized(isCurrentAppAuthorised)
        loadData() 
        
        return () => {
          // this now gets called when the component unmounts
        };
      }, [isCurrentAppAuthorised]);
    
      useEffect(() => {
        if(isAuthorized === true || isAuthorized === false){
          setIsCheckingAccess(!isCheckingAccess);
        } 
      }, [isAuthorized]);

      const breadItems = [
        { text: 'Home', href: '/Home' },
        { text: 'ATeam BI', href: '/ATeamBIHome' },
        { text: 'Boffin', href: '/BoffinHome' },
        { text: 'Unlock Compute', href: '/' },
        
    
      ];
      
      const onFollowHandler = (ev) => {
        ev.preventDefault();
        if (ev.detail.href) {
            history.push(ev.detail.href.substring(1));
        }
    }
    const processPhonetoolLink = (props) => (
        <div>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={"https://phonetool.amazon.com/users/" + props.userId}
          >
            {props.userId}
          </a>
        </div>
      );

    const unlockCompute = async (props) => {
        let response = await updateBoffinComputeLockStatus(props?.id, `Completed`)
        setUnlockedCompute(response)
        setShowComputeUnlockedModal(true)
        loadData();
    }      
      
      const loadData = async () =>{
        setShowLoadingIcon(true)

        const params = {userAlias: currentUser,
            id: appSettings.appId,
            stage: currentStage
           }
        const canUnlock = await checkAppAdminAccess(params);
        if(!canUnlock) {
            setCanUnlockCompute(canUnlock)
            setShowLoadingIcon(false);
            return
        } else {
            let computesResponse = await getBoffinComputesWithStartedStatus()
            setComputes(computesResponse)
            setCanUnlockCompute(canUnlock)
            setShowLoadingIcon(false);
        }
        
      }
      
      
      const content = (
        <div>
          {isAuthorized ? (
            <Box>
              <Container
                header={
                  <Header
                    actions={
                      <SpaceBetween direction="horizontal" size="xs">
                        {showLoadingIcon ? (
                          <Box>
                            <Spinner size="normal"></Spinner>
                          </Box>
                        ) : (
                          <Box></Box>
                        )}
                        <Button
                      variant="normal"
                      iconName="refresh"
                      onClick={() => loadData()}
                    >
                      Refresh
                    </Button>
                        
                      </SpaceBetween>
                    }
                    description="Computes with lock status as 'Started'"
                  >
                    Started Computes
                  </Header>
                }
                
              >
                
                     <Table
                columnDefinitions={[
                    {
                        id: "id",
                        header: "Compute Id",
                        cell: e => e.id,
                    },
                    {
                        id: "triggeredBy",
                        header: "Triggered By",
                        cell: e => processPhonetoolLink(e),
                    },
                    {
                        id: "createDate",
                        header: "Created Date",
                        cell: e => e.createDate,
                    },
                    {
                        id: "processStatus",
                        header: "Process Status",
                        cell: e => e.processStatus,
                    },
                    {
                        id: "lockStatus",
                        header: "Lock Status",
                        cell: e => e.lockStatus
                    },
                    { id: "unlockStatus", header: "Unlock Compute?", cell: e => <Box textAlign="left"><Button onClick={() => unlockCompute(e)}>Unlock Compute</Button></Box> }
                    
                ]}
                enableKeyboardNavigation
                resizableColumns
                stripedRows
                items={computes}
                loading={computes.length === 0 && typeof(canUnlockCompute) !== 'boolean'}
                loadingText="Checking for user access and started computes..."
                trackBy="name"
                empty={
                    <Box
                        margin={{ vertical: "xs" }}
                        textAlign="center"
                        color="inherit"
                    >
                        {
                            computes?.length === 0 && canUnlockCompute === true ? (
                        <SpaceBetween size="m">
                            <b>No computes with lock status as 'Started'</b>
                        </SpaceBetween>
                            ) : computes?.length === 0 && canUnlockCompute === false ?
                            (
                                <>
                                <Alert
                                statusIconAriaLabel="Error"
                                type="error"
                                header="Access Denied"
                                >
                                User not authorized to unlock computes!
                                </Alert>
                                </>
                            ) :
                            (<></>)
                        }
                        
                    </Box>
                }
            />
                    

              </Container>

              <br />
              <Modal
      onDismiss={() => {setShowComputeUnlockedModal(false)
                        setUnlockedCompute()
                        }}
      visible={showComputeUnlockedModal}
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="primary" onClick={()=> { 
                setShowComputeUnlockedModal(false)
                setUnlockedCompute()
            }}>Okay</Button>
          </SpaceBetween>
        </Box>
      }
      header="Compute Unlocked"
    >
      Lock status is set to <strong>'Completed'</strong> for the following compute: <br/>
      <strong>Compute Id:</strong> {`${unlockedCompute?.id}`} <br/>
      <strong>Current lock status:</strong> {`${unlockedCompute?.lockStatus}`}
    </Modal>
            </Box>
          ) : isCheckingAccess ? (
            <div>
              <WaitForPageAccess></WaitForPageAccess>
            </div>
          ) : (
            <div>
              <AccessDenied appName={appSettings.appName}></AccessDenied>
            </div>
          )}
        </div>
      );

  return (
    <AppLayout
          breadcrumbs={<BreadcrumbGroup items={breadItems} onFollow={(event)=>onFollowHandler(event)}></BreadcrumbGroup>}
          content={content}
          navigation={<SideNav activeHref="/BoffinUnlockCompute" />}
          headerSelector="#TopBar"
          navigationHide={false}
          
          toolsHide={true}
          maxContentWidth = {Number.MAX_VALUE}
          headerVariant="high-contrast"
  
        ></AppLayout>
  )
}

export default BoffinUnlockCompute
