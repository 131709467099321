import React, { useState, useEffect } from "react";
import {
    Box,
    Input,
    Button,
    Select,
    SpaceBetween
} from "@amzn/awsui-components-react";
import './style.scss'
import { TEMPLATE_APP_SOURCE_DATA_TABLES } from '../Constants';

const CustomMultiHybridInputsForSourceTables = (props) => {
    var [rows, setRows] = useState([]);
    useEffect(() => {
        loadRows()
        return () => {
        };
    }, []);

    useEffect(() => {
        props.updateValue(props.field, getMergedInputValues())
    }, [rows])

    const getMergedInputValues = () => {
        var filtered = rows.filter(function (row) {
            return row != null;
        });
        return filtered
    }

    const onEdit = (value, index, key) => {
        rows[index][key] = value
        setRows([...rows])
    }

    const getInputItem = (value, index) => {
        return <div className='rows' key={index}>
            <SpaceBetween direction="horizontal" size="xs">
            <Input
                onChange={({ detail }) => onEdit(detail.value, index, TEMPLATE_APP_SOURCE_DATA_TABLES.UPSTREAM_SOURCE)}
                value={value.hasOwnProperty(TEMPLATE_APP_SOURCE_DATA_TABLES.UPSTREAM_SOURCE) ? value[TEMPLATE_APP_SOURCE_DATA_TABLES.UPSTREAM_SOURCE]: ''}
                placeholder="Upstream source"
            />
            <Input
                onChange={({ detail }) => onEdit(detail.value, index, TEMPLATE_APP_SOURCE_DATA_TABLES.DATABASE)}
                value={value.hasOwnProperty(TEMPLATE_APP_SOURCE_DATA_TABLES.DATABASE) ? value[TEMPLATE_APP_SOURCE_DATA_TABLES.DATABASE]: ''}
                placeholder="Database"
            />
            <Input
                onChange={({ detail }) => onEdit(detail.value, index, TEMPLATE_APP_SOURCE_DATA_TABLES.SCHEMA)}
                value={value.hasOwnProperty(TEMPLATE_APP_SOURCE_DATA_TABLES.SCHEMA) ? value[TEMPLATE_APP_SOURCE_DATA_TABLES.SCHEMA]: ''}
                placeholder="Schema"
            />
            <Input
                onChange={({ detail }) => onEdit(detail.value, index, TEMPLATE_APP_SOURCE_DATA_TABLES.TABLE)}
                value={value.hasOwnProperty(TEMPLATE_APP_SOURCE_DATA_TABLES.TABLE) ? value[TEMPLATE_APP_SOURCE_DATA_TABLES.TABLE]: ''}
                placeholder="Table"
            />
            <Input
                onChange={({ detail }) => onEdit(detail.value, index, TEMPLATE_APP_SOURCE_DATA_TABLES.DESCRIPTION)}
                value={value.hasOwnProperty(TEMPLATE_APP_SOURCE_DATA_TABLES.DESCRIPTION) ? value[TEMPLATE_APP_SOURCE_DATA_TABLES.DESCRIPTION]: ''}
                placeholder="Description"
            />
            <div className="row"><Button iconName="close" variant="icon" onClick={() => {
                deleteRow(index)
            }} /></div>
    </SpaceBetween>
            
        </div>
    }
    const deleteRow = (index) => {
        rows.splice(index, 1)
        setRows([...rows])
    }
    const addRow = () => {
        var newRow = {}
        newRow[TEMPLATE_APP_SOURCE_DATA_TABLES.UPSTREAM_SOURCE] = ''
        newRow[TEMPLATE_APP_SOURCE_DATA_TABLES.DATABASE] = ''
        newRow[TEMPLATE_APP_SOURCE_DATA_TABLES.TABLE] = ''
        newRow[TEMPLATE_APP_SOURCE_DATA_TABLES.SCHEMA] = ''
        newRow[TEMPLATE_APP_SOURCE_DATA_TABLES.DESCRIPTION] = ''

        setRows([...rows, newRow])
    }
    const getAddButton = () => {
        return <div> <Button onClick={addRow}>Add</Button> </div>
    }

    const loadRows = () => {
        let values = props.getValues(props.field)
        if(values == null){
            return
        }
        rows=[];
        values.forEach(element => {
            rows.push(element)
        });

        setRows(JSON.parse(JSON.stringify(values)))
    }

    return (
        <Box>

            {rows ? (Array.from(rows).map((value, index) => {
                return getInputItem(value, index);
            })) : ''}

            {
                getAddButton()
            }
        </Box>
    )
}

export default CustomMultiHybridInputsForSourceTables;