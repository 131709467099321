import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { Amplify, Auth } from 'aws-amplify';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { getAppSyncConfig, getMJEAppSyncConfig, getS3Config, getAPIGateWayConfig, getFriscoS3Config, getLogicoS3Config, getFlexicoAppSyncConfig, getFlexicoS3Config } from 'src/utilities/AWSServices';
import { getMJES3Config } from 'src/utilities/AWSServices';

export const configureGenericAppSync = async () => {
  try {
    const awsAppSync = await getAppSyncConfig();
    Amplify.configure(awsAppSync.Appsync);
    // console.log('Connecting to App Sync')
    // console.log(awsAppSync.Appsync)
  } catch (error: any) {
    console.error('Unable to fetch AppSync Config', error);
  }
};

export const configureFlexicoAppSync = async () => {
  try {
    const awsAppSync = await getFlexicoAppSyncConfig();
    Amplify.configure(awsAppSync.Appsync);
    // console.log('Connecting to flexico App Sync')
    // console.log(awsAppSync.Appsync)
  } catch (error: any) {
    console.error('Unable to fetch AppSync Config for Flexico', error);
  }
};

export const configureMJEAppSync = async () => {
  try {
    const awsAppSync = await getMJEAppSyncConfig();
    Amplify.configure(awsAppSync.Appsync);
    // console.log('Connecting to App Sync for MJE')
    // console.log(awsAppSync.Appsync)
  } catch (error: any) {
    console.error('Unable to fetch AppSync Config', error);
  }
};

export const configureGenericS3 = () => {
  try {
    // console.log("Configuring S3")
    const awsS3 = getS3Config();
    Amplify.configure(awsS3.S3);
    // console.log(awsS3.S3)
  } catch (error: any) {
    console.error('Unable to configure S3', error);
  }

}

export const configureDynamicS3 = (bucketName: string, region: string) => {
  try {
    Amplify.configure({
      aws_user_files_s3_bucket: bucketName,
    aws_user_files_s3_bucket_region: region
    });
  } catch (error: any) {
    console.error('Unable to configure S3', error);
  }

}

export const configureFriscoS3 = () => {
  try {
    // console.log("Configuring S3")
    const awsS3 = getFriscoS3Config();
    Amplify.configure(awsS3.S3);
    // console.log(awsS3.S3)
  } catch (error: any) {
    console.error('Unable to configure FRISCO S3', error);
  }

}
export const configureFlexicoS3 = () => {
  try {
    // console.log("Configuring S3")
    const awsS3 = getFlexicoS3Config();
    Amplify.configure(awsS3.S3);
    // console.log(awsS3.S3)
  } catch (error: any) {
    console.error('Unable to configure FLEXICO S3', error);
  }

}
export const configureLogicoS3 = () => {
  try {
    // console.log("Configuring S3")
    const awsS3 = getLogicoS3Config();
    Amplify.configure(awsS3.S3);
    // console.log(awsS3.S3)
  } catch (error: any) {
    console.error('Unable to configure LOGICO S3', error);
  }

}
const configureGenericAPIGateWay = () => {
  try {
    // console.log("Configuring APIGateWay")
    const awsAPIGateWay = getAPIGateWayConfig();
    Amplify.configure(awsAPIGateWay.APIGateWay);
    // console.log(awsAPIGateWay.APIGateWay)
    // console.log(Amplify.Cache)
  } catch (error: any) {
    console.error('Unable to configure APIGateway', error);
  }

}
export const setupAmplifyGeneric = async () => {
  await configureGenericAppSync();
  configureGenericAPIGateWay();
}

export const configureMJES3 = async () => {
  try {
    // console.log("Configuring MJE S3")
    const awsS3 = getMJES3Config();
    Amplify.configure(awsS3.S3);
    // console.log(awsS3.S3)
  } catch (error: any) {
    console.error('Unable to configure MJE S3', error);
  }

}


