import React, {useState} from 'react';

import SideNavigation from "@amzn/awsui-components-react/polaris/side-navigation";
import { useHistory, useLocation } from "react-router-dom";

export default (props) => {
  const [activeHref, setActiveHref] = useState(props.activeHref);
  const history = useHistory();

  const items = [
    {
        "type": "link",
        "text": "Introduction",
        "href": "/BoffinHome"
    },

    {
        "type": "section",
        "text": "Actions",
        "defaultExpanded": true,
        "items": [
            {
                "type": "link",
                "text": (<div>Inputs</div>),
                "href": "/BoffinInputs"
            },

            {
                "type": "link",
                "text": (<div>Compute</div>),
                "href": "/BoffinCompute"
            },
            {
                "type": "link",
                "text": "Review",
                "href": "/BoffinViewAllItems"
            }

        ]

    },

    {
        "type": "section",
        "text": "Reports",
        "defaultExpanded": true,
        "items": [
            {
                "type": "link",
                "text": "Dataset Info",                
                "href": "/BoffinDashboards"
            },
            {
                "type": "link",
                "text": "CIP Tracker",                
                "href": "/BoffinCipTracker"
            },
            {
                "type": "link",
                "text": "Supplier Master List",                
                "href": "/BoffinSupplierMasterList"
            },
            {
                "type": "link",
                "text": "Warning Rules",                
                "href": "/BoffinWarningRules"
            },
            {
                "type": "link",
                "text": "Posting Summary",                
                "href": "/BoffinPostingSummary"
            },
            {
                "type": "link",
                "text": "Category UEL Mapping",
                "href": "/BoffinCategoryUELMapping"
            },
            {
                "type": "link",
                "text": "Building Tracker",                
                "href": "/BoffinBuildingTracker"
            }
            
        ]
    },

    {
        "type": "section",
        "text": "Admin Panel",
        "defaultExpanded": true,
        "items": [

            {
                "type": "link",
                "text": " Rules & Warnings",
                "href": "/BoffinRulesAndWarnings"
            },
            {
                "type": "link",
                "text": "Team Name Mapping",
                "href": "/BoffinTeamNameMapping"
            },
            {
                "type": "link",
                "text": "Current Period",
                "href": "/BoffinCurrentPeriod"
            }
            ,
            {
                "type": "link",
                "text": "Mapping Book to Ledger",
                "href": "/BoffinMappingBookToLedger"
            },
            {
                "type": "link",
                "text": "Primary Ledger to Secondary Ledger Mapping",
                "href": "/BoffinPrimaryLedgerToSecondaryLedgerMapping"
            },
            {
                "type": "link",
                "text": "Supplier Master List Override",                
                "href": "/BoffinSupplierMasterListOverride"
            },
            {
                "type": "link",
                "text": "Warning Rules Override",                
                "href": "/BoffinWarningRulesOverride"
            },
            {
                "type": "link",
                "text": "Unlock Compute",                
                "href": "/BoffinUnlockCompute"
            }
        ]
    },




    { "type": "divider" },

    
    {
        "type": "link",
        "text": "Back",
        "href": "/"
    },


    ];
  return (
    <SideNavigation
      activeHref={activeHref}
      header={{ href: "/Boffin", text: "Boffin" }}
      onFollow={e => {
        e.stopPropagation();
        e.preventDefault();
        history.push(e.detail.href);
        setActiveHref(e.detail.href)
    }}
      items={items}
    />
  );
}