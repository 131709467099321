import React, { useState, useEffect, useRef} from 'react'
import { useSelector , useDispatch} from 'react-redux'

import {  AppLayout, HelpPanel,  Box, SpaceBetween, Header, Button, BreadcrumbGroup,
            Container,  Table, Icon, Spinner, ColumnLayout, ExpandableSection, Tabs,
            Modal, FormField, SplitPanel, Alert

        } from '@amzn/awsui-components-react';

import moment from 'moment';

import SideNav from './SideNav';
import appSettings from './app_settings';

import { useHistory , useLocation} from "react-router-dom";
import checkPageAccess from '../../checkPageAccess';
import AccessDenied from '../../AccessDenied'
import WaitForPageAccess from '../../WaitForPageAccess'

import { setCurrentAppId } from '../../store/userAuthSlice';
import { setBoffinRulesMode, setBoffinRulesWarnings, setBoffinRulesWarningsSortOrder } from '../../store/userBoffinSlice';


import { AgGridReact } from 'ag-grid-react';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

import { API, graphqlOperation } from 'aws-amplify';

import { useAuth } from "../../context/AuthContextProvider";
import {Board, BoardItem} from "@amzn/awsui-board-components";
import { boardI18nStrings, boardItemI18nStrings } from 'src/i18n-strings/board-I18nStrings';
import {checkAppAdminAccess} from '../../checkPageAccess'


const RulesAndWarnings = () => {
    const userAuthenticationDetails = useAuth();

    const userLDAPS = userAuthenticationDetails.USER_LDAP_GROUPS;

    const [isBoffinSuperUser, setIsBoffinSuperUser] = useState(false)

    const dispatch = useDispatch()

    const history = useHistory();
    
    const gridRef = useRef();

    const userId  = useSelector((globalState)=>globalState.auth.userId)
    const currentAppId = useSelector((globalState)=>globalState.auth.currentAppId)
    const isCurrentAppAuthorised = useSelector((globalState)=>globalState.auth.isCurrentAppAuthorized)
    const [isCheckingAccess, setIsCheckingAccess] = useState(true);
    const [isAuthorized, setIsAuthorized] = useState();
    const currentStage = useSelector((globalState)=>globalState.auth.currentStage)
    const currentUser = useSelector((globalState)=>globalState.auth.userId)

    const [items, setItems] = useState([]);
    
    
    const [toolsHide, setToolsHide] = useState(true);

    const [boardItems, setBoardItems] = useState([
    { id: "1", rowSpan: 5, columnSpan: 6,data:{}}
    ]);

    
    // const [currentPageIndex, setCurrentPageIndex] = useState(1);
    // const [pageItems, setPageItems] = useState([]);
    // const [tablePages, setTablePages] = useState(1);
    
    const [selectedItem, setSelectedItem] = useState([]);
    
    const [showLoadingIcon, setShowLoadingIcon] = useState(true);
    const [showApprovalModal, setShowApprovalModal] = useState(false);
    
    const [conditions, setConditions] = useState([]);
    const [actions, setActions] = useState([]);
    
    
 
    useEffect(() => {
    
        if (currentAppId !== appSettings.appId){
          dispatch(setCurrentAppId(appSettings.appId))
        }
        setIsAuthorized(isCurrentAppAuthorised)
        loadData()
        return () => {
          // this now gets called when the component unmounts
        };
      }, [ isCurrentAppAuthorised]);

      useEffect(() => {
        if(isAuthorized === true || isAuthorized === false){
          setIsCheckingAccess(!isCheckingAccess);
        } 
      }, [isAuthorized]);
    
    // 
    
    
    const defaultColDef = {
        // set filtering on for all columns
        filter: true,
        filterParams: {
            buttons: ['reset'],
            debounceMs: 200
        }
    };

    const openButton = props  => (
        <Button 
        variant='inline-icon'
        iconName='folder-open'
        onClick={()=>{
            //  console.log(props.data.pk)
                // setCurrentId(props.data.id)
                dispatch(setBoffinRulesMode('MODIFY'))
                dispatch(setBoffinRulesWarnings(props.data.pk))
                dispatch(setBoffinRulesWarningsSortOrder(props.data.sk))
                history.push('/BoffinRulesWarningsNewEdit')}        
    }></Button>
    )
    const approveButton = props  => (
        props.data.modifiedBy != userId &&  !props.data.approved ? 
        
        <Button
            variant='inline-icon'
            iconName='thumbs-up'
            onClick={()=>{
                let mTxt2 = props.data.whereClause
                let mJ2 = mTxt2.replace(/'/g, '"')
                setConditions(JSON.parse(mJ2))

                let mTxt1 = props.data.setActions
                let mJ1 = mTxt1.replace(/'/g, '"')
                setActions(JSON.parse(mJ1))
                setShowApprovalModal(true)}
            }
        ></Button>: <Box></Box>
        
    )
    const enabledIcon = props  => (
        props.data.enabled ?
        <div>  <Icon name='status-positive' variant='success' ></Icon></div> :
        <div><Icon name='status-pending' variant='warning' ></Icon></div>
    )
    const approvedIcon = props  => (
        props.data.approved ?
        <div>  <Icon name='status-positive' variant='success' ></Icon></div> :
        <div><Icon name='status-pending' variant='warning' ></Icon></div>
    )
    const processImgModifiedBy = props =>(
        <div>
            <img
                alt=""
                className="rounded-circle"
                src={'https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=' + props.data.modifiedBy}
                width={20}
                height={20}
            />
                &nbsp;
            <a 
                target="_blank" 
                rel="noopener noreferrer" 
                href={'https://phonetool.amazon.com/users/' + props.data.modifiedBy}>{props.data.modifiedBy}
            </a>
        </div>
    )
    const processImgApprovedBy = props =>(
        <div>
            <img
                alt=""
                className="rounded-circle"
                src={'https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=' + props.data.approvedBy}
                width={5}
                height={5}
            />
                &nbsp;
            <a 
                target="_blank" 
                rel="noopener noreferrer" 
                href={'https://phonetool.amazon.com/users/' + props.data.approvedBy}>{props.data.approvedBy}
            </a>
        </div>
    )
    const [columnDefs] = useState([
        { field: 'Open' ,  width: '80px',  cellRenderer:openButton, sortable: false, filter:false},
        { field: 'Approval' ,  width: '100px', cellRenderer:approveButton, sortable: false, resizable: true, filter:false},
        { field: 'pk' , headerName: 'Type', width: '120px',  sortable: true, resizable: true},
        { field: 'sk' , headerName: 'SortOrder', width: '100px', sortable: true, resizable: true},
        { field: 'description' , headerName: 'Description', width: '300px', sortable: true, resizable: true},
        { field: 'enabled' , headerName: 'Enabled', cellRenderer:enabledIcon, width: '120px', sortable: true, resizable: true},
        { field: 'approved' , headerName: 'Approved', cellRenderer:approvedIcon, width: '120px', sortable: true, resizable: true},
        { field: 'approvedBy' , headerName: 'Approved By', cellRenderer:processImgApprovedBy, width: '120px', sortable: true, resizable: true},
        { field: 'modifiedBy' , headerName: 'Modified By', cellRenderer:processImgModifiedBy, width: '120px', sortable: true, resizable: true},        
        { field: 'lastUpdatedDate' , headerName: 'lastUpdatedDate', width: '250px', sortable: true, resizable: true},
        { field: '' }
    ])

    const onFollowHandler = (ev) => {
        ev.preventDefault();
        if (ev.detail.href) {
            history.push(ev.detail.href.substring(1));
        }
    }

    const onSelectionChanged= () =>{
        const selectedRows = gridRef.current.api.getSelectedRows();
        if(selectedRows.length >0 ){
            setSelectedItem(selectedRows[0])
            let mTxt2 = selectedRows[0].whereClause
            let mJ2 = mTxt2.replace(/'/g, '"')
            setConditions(JSON.parse(mJ2))

            let mTxt1 = selectedRows[0].setActions
            let mJ1 = mTxt1.replace(/'/g, '"')
            setActions(JSON.parse(mJ1))
            // console.log(selectedRows[0])
            
        }
    
      }
    
    const breadItems = [
        { text: 'Home', href: '/Home' },
        { text: 'ATeam BI', href: '/ATeamBIHome' },
        { text: 'Boffin', href: '/BoffinHome' },
        { text: 'Rules & Warnings', href: '' },

    
      ];

    const loadData = async () =>{
        setShowLoadingIcon(true)

      const params = {userAlias: currentUser,
          id: appSettings.appId,
          stage: currentStage
         }
      const canUnlock = await checkAppAdminAccess(params);
      setIsBoffinSuperUser(canUnlock)

        var mQry = `query MyQuery {
            listUI4BI_BoffinRulesWarnings (limit: 1000) {
              items {
                pk
                sk
                enabled
                description
                setActions
                whereClause
                modifiedBy
                approved
                approvedBy
                lastUpdatedDate
                lastUpdatedBy
              }
            }
          }
    `
        

    
        // console.log(mQry)
        let response = await API.graphql(graphqlOperation(mQry));
        let qResultJSON = response.data.listUI4BI_BoffinRulesWarnings.items
       
        setItems(qResultJSON)
        setShowLoadingIcon(false)
           
           
    }

   
   const AddButtonClick = () =>{
    dispatch(setBoffinRulesMode('NEW'))
    history.push('/BoffinRulesWarningsNewEdit')
   }

   const ApproveButtonClick = async () =>{
            let dt = moment().format();
            
            var mQry = `
            mutation updateRuleWarning {
                updateUI4BI_BoffinRulesWarnings(input: {
                    pk              : "` + selectedItem.pk +`", 
                    sk              : "` + selectedItem.sk +`", 
                    approvedBy      : "` + userId + `",
                    approved        : true

                }){
                        pk
                        sk
                    }
            }
            
        `
            

        
            // console.log(mQry)
            let response = await API.graphql(graphqlOperation(mQry))
            setShowApprovalModal(false)
            loadData()

   }
    
    
    const content = (
      <SpaceBetween direction="vertical" size="m">
        {isAuthorized ? (
          <Box>
            <p></p>
            <Container
              header={
                <Header
                  variant="h2"
                  actions={
                    <SpaceBetween direction="horizontal" size="xs">
                      {showLoadingIcon ? (
                        <Box>
                          <Spinner size="normal"></Spinner>
                        </Box>
                      ) : (
                        <Box></Box>
                      )}
                      <Button
                        variant="normal"
                        iconName="refresh"
                        onClick={() => loadData()}
                      >
                        Refresh
                      </Button>
                      <Button
                        variant="primary"
                        disabled={!isBoffinSuperUser}
                        iconName="add-plus"
                        onClick={() => AddButtonClick()}
                      >
                        Add New
                      </Button>
                    </SpaceBetween>
                  }
                  description="The following table shows all the rules and warnings."
                >
                  Rules & Warnings
                </Header>
              }
            >
              <Board
                renderItem={(item) => (
                  <BoardItem i18nStrings={boardItemI18nStrings}>
                    <div
                      className="ag-theme-alpine"
                      style={{ height: "92%", width: "100%" }}
                    >
                      <AgGridReact
                        ref={gridRef}
                        rowData={items}
                        defaultColDef={defaultColDef}
                        columnDefs={columnDefs}
                        rowSelection="single"
                        animateRows={true}
                        rowHeight={30}
                        checkboxSelection={true}
                        enableCellTextSelection={true}
                        onSelectionChanged={onSelectionChanged}
                      ></AgGridReact>
                    </div>
                  </BoardItem>
                )}
                onItemsChange={(event) => setBoardItems(event.detail.items)}
                items={boardItems}
                i18nStrings={boardI18nStrings}
              />
            </Container>
            <br />
           
            <br />
            <p></p>
            <p></p>
            <Modal
              onDismiss={() => setShowApprovalModal(false)}
              visible={showApprovalModal}
              header="Approval"
              size="large"
              footer={
                <Box float="right">
                  <Button onClick={() => ApproveButtonClick()}>Approve</Button>
                </Box>
              }
            >
              <ColumnLayout columns={3}>
                <FormField label="Type">
                  {selectedItem.pk} / {selectedItem.sk}
                </FormField>
                <FormField label="Description">
                  {selectedItem.description}
                </FormField>
                <FormField label="Enabled">
                  {selectedItem.enabled ? (
                    <Icon variant="success" name="status-positive"></Icon>
                  ) : (
                    <Icon variant="error" name="status-negative"></Icon>
                  )}
                </FormField>
              </ColumnLayout>
              <ExpandableSection headerText="Details">
                <Tabs
                  tabs={[
                    {
                      label: "Rules",
                      id: "first",
                      content: (
                        <Table
                          columnDefinitions={[
                            {
                              id: "logic",
                              header: "",
                              cell: (item) => item.logic,
                            },
                            {
                              id: "col",
                              header: "Column name",
                              cell: (item) => item.columnName,
                            },
                            {
                              id: "operation",
                              header: "Operation",
                              cell: (item) => item.operation,
                            },
                            {
                              id: "value",
                              header: "Value",
                              cell: (item) => item.value,
                            },
                          ]}
                          items={conditions}
                          loadingText="Loading resources"
                          sortingDisabled
                        />
                      ),
                    },
                    {
                      label: "Actions",
                      id: "second",
                      content: (
                        <Table
                          columnDefinitions={[
                            {
                              id: "A1",
                              header: "Column Name",
                              cell: (item) => item.columnName,
                            },

                            {
                              id: "value",
                              header: "Value",
                              cell: (item) => item.value,
                            },
                          ]}
                          items={actions}
                          loadingText="Loading resources"
                          sortingDisabled
                        />
                      ),
                    },
                  ]}
                />
              </ExpandableSection>
            </Modal>
          </Box>
        ) : isCheckingAccess ? (
          <div>
            <WaitForPageAccess></WaitForPageAccess>
          </div>
        ) : (
          <div>
            <AccessDenied appName={appSettings.appName}></AccessDenied>
          </div>
        )}
      </SpaceBetween>
    );
    
    const helpContent = (
        <HelpPanel
            
            header={<div>
                <div>
    
                </div>
                <h2>Additional Info</h2>
               
            </div>}
        >
            
            <Box variant='h3'>
                What is a Rule?
            </Box>
            <Box>
                A rule is defined to change specific column values based on business logics.
                <strong> For example:</strong> Search line description for for the key word expenses and update major category column to EXPENSES.
            </Box>
            <hr></hr>
            <Box variant='h3'>
                What is a Warning?
            </Box>
            <Box>
                A Warning is displayed as a text message in the Excel Output Data tab (Column AT). 
                This is to enable the user identify potential risks associated with the transactions.
            </Box>
            <Box>
            <hr></hr>
            <Box variant='h3'>
                What is a 2 touch rule?
            </Box>
            2 touch rule; implies that the workflow does not allow the person submitting a rule or warning 
            to be the same person as the approver. 
            </Box>
        </HelpPanel>
      )
    
      const SPLIT_PANEL_I18NSTRINGS = {
        preferencesTitle: "Split panel preferences",
        preferencesPositionLabel: "Split panel position",
        preferencesPositionDescription: "Choose the default split panel position for the service.",
        preferencesPositionSide: "Side",
        preferencesPositionBottom: "Side",
        preferencesConfirm: "Confirm",
        preferencesCancel: "Cancel",
        closeButtonAriaLabel: "Close panel",
        openButtonAriaLabel: "Open panel",
        resizeHandleAriaLabel: "Resize split panel",
      };
    
      const detailView = (
        <Box>
           {
        selectedItem.length != 0 ?
            <Box>
                <ColumnLayout columns={3}>
                                <FormField label="Type" >
                                    {selectedItem.pk} / {selectedItem.sk}
                                </FormField>
                                <FormField label="Description" >
                                    {selectedItem.description}
                                </FormField>
                                <FormField label="Enabled" >
                                    {selectedItem.enabled ? <Icon variant='success' name='status-positive'></Icon> :
                                    <Icon variant='error' name='status-negative'></Icon> }

                                </FormField>
                                
                            </ColumnLayout>
                                <Tabs
                                tabs={[
                                    {
                                    label: "Rules",
                                    id: "first",
                                    content: (
                                        <Table
                                            columnDefinitions={[
                                                {
                                                    id: "logic",
                                                    header: "",
                                                    cell: item => item.logic,

                                                },
                                            {
                                                id: "col",
                                                header: "Column name",
                                                cell: item => item.columnName ,
                                            },
                                            {
                                                id: "operation",
                                                header: "Operation",
                                                cell: item => item.operation ,
                                            },
                                            {
                                                id: "value",
                                                header: "Value",
                                                cell: item => item.value
                                            }
                                            ]}
                                            items={conditions}
                                            loadingText="Loading resources"
                                            sortingDisabled
                                            
                                        />)
                                    },
                                    {
                                    label: "Actions",
                                    id: "second",
                                    content: (
                                        <Table
                                        columnDefinitions={[
                                            
                                            {
                                                id: "A1",
                                                header: "Column Name",
                                                cell: item => item.columnName ,

                                            },
                                        
                                            {
                                                id: "value",
                                                header: "Value",
                                                cell: item => item.value 
                                            }
                                        ]}
                                        items={actions}
                                        loadingText="Loading resources"
                                        sortingDisabled

                                        />
                                    )
                                    }
                                ]}
                            />

                                
            </Box>
        : <Box variant='p' color='text-status-error'>Select any row from above table</Box>
            
                    }
        </Box>
        
      )
    
  return (
    <AppLayout
        breadcrumbs={<BreadcrumbGroup items={breadItems} onFollow={(event)=>onFollowHandler(event)}></BreadcrumbGroup>}
        content={content}
        navigation={<SideNav activeHref="/BoffinRulesAndWarnings" />}
        headerSelector="#TopBar"
        navigationHide={false}
        onToolsChange={()=>{setToolsHide(!toolsHide)}}
        splitPanel={ <SplitPanel 
            closeBehavior='collapse'
            header='Preview'
            i18nStrings={SPLIT_PANEL_I18NSTRINGS}>   
            {detailView} 
            </SplitPanel>}
        tools={isAuthorized ? helpContent : ''}
        toolsOpen={isAuthorized? toolsHide : false}
        maxContentWidth = {Number.MAX_VALUE}
        headerVariant="high-contrast"
  ></AppLayout>
  )
}





export default RulesAndWarnings
