import React, { PureComponent } from 'react'

export default class CircleWithText extends PureComponent {

    constructor(props) {
        super(props)
        var inColor = ''
        // var defaultColor = '#34495E'
        var defaultColor = '#1f77b4'

        if ("Color" in this.props) {
            inColor = this.props.Color
        } else {
            inColor = defaultColor
        }

        if (inColor === '') {
            inColor = defaultColor
        }
        this.state = ({
            mTxt: this.props.Text,
            currColor: inColor

        })
    }
    componentDidMount() {

    }

    render() {
        return (
            <svg height={70} width={70} >
                <circle
                    cx="30" cy="30"
                    // fill="#1f77b4"
                    fill={this.state.currColor}
                    r="25"
                // stroke={this.state.currColor}
                // stroke-width="1"
                />

                <text
                    fill="white"
                    x="25" y="35" >
                    {this.state.mTxt}
                </text>

            </svg>
        )
    }
}
