import React, { useState, useEffect } from 'react';
import appSettings from './app_settings';

import {
  AppLayout, HelpPanel, Cards, Tabs, Icon, Spinner, ContentLayout, Table,
  Container, Header, Box, Pagination, SpaceBetween, ExpandableSection, Modal, ColumnLayout, Button, Badge, Select, Grid, PieChart, BarChart, Popover, StatusIndicator
} from '@amzn/awsui-components-react';
import Link from "@amzn/awsui-components-react/polaris/link";
import '../../index.scss';
import { useSelector, useDispatch } from 'react-redux'
import './styles.scss'

import AccessDenied from '../../AccessDenied'
import WaitForPageAccess from '../../WaitForPageAccess'
import { useHistory } from 'react-router-dom';

import { API, graphqlOperation } from 'aws-amplify';
import Template from '../Templates/Template';
import { glossary, TEMPLATE_APP, TEMPLATE_APP_OUTPUT, TEMPLATE_APP_PERMISSION, TEMPLATE_APP_TEAM, TEMPLATE_APP_SOURCE_DATA_TABLES, TEMPLATE_APP_CHART_OF_ACCOUNTS } from 'src/Ateam-BI/Templates/Constants';
import TemplateMaple from '../Templates/TemplateMaple';
import { favorite, checkIsFavorite } from '../../utilities/Favorites'
import favLogo from '../../images/star-regular-brown-for-home.svg'
import { setCorpReportingQuarter, setCurrentAppId } from '../../store/userAuthSlice';
import SideNav from './SideNav';
import { getProcessForAppId, getFriscoGroupsForAppId, getFRISCOGroupsWithConsolidatedStatusForQuarter, getFriscoTracksForQuarter, getFriscoProcessWithTracksForQuarter } from '../Templates/Utils/AppActivityUtils';
import { PROCESS_KEYS } from '../Frisco/Constants';
import CorpAdminApprovalsHome from './CorpAdminApprovalsHome';
import { getEditableFields } from "../Templates/Utils/FormMetadata";
import Feedback from 'src/generic-components/UserFeedback/Feedback';
import { TextFilter } from 'ag-grid-community';
import { queryTaskManagementsByAliasAndStatusIndex } from 'src/generic-components/TaskManagement';
import moment from 'moment';
import { isApprover, isUploaderAllowed } from '../Frisco/AccessUtilities';
import { filterItemsByAccess, getQuarterForUpload, getStateNonAdmin, groupModules } from './Utility';
import { isCorpAdmin } from '../Templates/Utils/Permissions';

// Component ServiceHomepage is a skeleton of a service's homepage using AWS-UI React components.
export default () => {
  const history = useHistory()

  const [renderJson, setRenderJson] = useState({})
  const currentStage = useSelector((globalState) => globalState.auth.currentStage)
  const currentAppId = useSelector((globalState) => globalState.auth.currentAppId)
  const dispatch = useDispatch();
  const [isFavorite, setIsFavorite] = useState(false);
  const userId = useSelector((globalState) => globalState.auth.userId);
  const [NavOpen, setNavOpen] = useState(false);
  const [showLoadingIcon, setShowLoadingIcon] = useState(false);
  const [subProcess, setSubProcesses] = useState([]);
  const [currentPageIndex, setCurrentPageIndex] = useState(1);
  const [filteredProcess, setFilteredProcess] = useState([]);
  const [pageItems, setPageItems] = useState()
  const [isProcessLoading, setIsProcessLoading] = useState(true);
  const [pagesCount, setPagesCount] = useState()
  const [activeTabId, setActiveTabId] = useState("activeTask");
  const [groups, setGroups] = useState([]);
  const [quarterOptions, setQuarterOptions] = useState([]);
  const [
    quarterOption,
    setQuarterOption
  ] = React.useState({});
  const [viewRoles, setViewRoles] = useState([{ 'label': userId, 'value': userId }]);
  const [viewRole, setViewRole] = useState({});

  const [activeTasks, setActiveTasks] = useState([]);
  const [completeTasks, setCompleteTasks] = useState({});
  const [allLeadsheets, setAllLeadsheets] = useState({});
  const [filteredLeadsheets, setFilteredLeadsheets] = useState([]);
  const [showfilteredLeadsheetsLoader, setShowFilteredLeadsheetsLoader] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false)
  const [rcMainUrl, setRCMainUrl] = useState(false)

  useEffect(() => {
    loadRenderJson()
    checkFavorite(appSettings[currentStage].appId)
    if (currentAppId !== appSettings[currentStage].appId) {
      dispatch(setCurrentAppId(appSettings[currentStage].appId))
    }
    setReportingCentralMainUrl()
    return () => {
      // this now gets called when the component unmounts
    };
  }, [currentStage]);


  useEffect(() => {
    setPageItems(
      filteredProcess
        .slice(15 * (currentPageIndex - 1), 15 * (currentPageIndex))
    )

  }, [currentPageIndex, filteredProcess])


  useEffect(() => {
    setPagesCount(Math.ceil(subProcess.length / 15))
  }, [subProcess]);

  useEffect(() => {
    loadProcessesWithTracksForQuarter(appSettings[currentStage].appId)
    dispatch(setCorpReportingQuarter(quarterOption.value))
  }, [quarterOption]);

  const loadRenderJson = async () => {
    let quarter = getQuarterForUpload()
    setQuarterOption({ label: quarter, value: quarter })
    var mQry = `
    query MyQuery {
      getAppsV2(ids: ["${appSettings[currentStage].appId}"]) {
        ${TEMPLATE_APP.APP_DESCRIPTION}
          ${TEMPLATE_APP.APP_NAME}
          ${TEMPLATE_APP.APP_ID}
          ${TEMPLATE_APP.TEMPLATE_ID}
          ${TEMPLATE_APP.URL}
          ${TEMPLATE_APP.PROCESS_OWNER}
          ${TEMPLATE_APP.FLASH_INFO_DESCRIPTION}
          ${TEMPLATE_APP.DEV_ALERT_MESSAGES}
          ${TEMPLATE_APP.TDD}
          ${TEMPLATE_APP.STATUS}
          ${TEMPLATE_APP.REGION}
          ${TEMPLATE_APP.CADENCE}
          ${TEMPLATE_APP.PROCESS_MJE_COUNT}
          ${TEMPLATE_APP.ANNUAL_MER_SAVINGS}
          ${TEMPLATE_APP.PDD}
          ${TEMPLATE_APP.LEDGER_INFO}
          ${TEMPLATE_APP.LAUNCH_MCM}
          ${TEMPLATE_APP.LAUNCH_QQ_YY}
          ${TEMPLATE_APP.INPUT_SOURCE_TYPE}
          ${TEMPLATE_APP.AUTOMATION_TOOLS}
          ${TEMPLATE_APP.ARD}
          ${TEMPLATE_APP.DATASOURCE}
          ${TEMPLATE_APP.ORGANIZATION}
          ${TEMPLATE_APP.VP}
          ${TEMPLATE_APP.CREATE_TIME_STAMP}
          ${TEMPLATE_APP.CREATED_BY}
          ${TEMPLATE_APP.LAST_UPDATED_TIME_STAMP}
          ${TEMPLATE_APP.LAST_UPDATED_BY}
          ${TEMPLATE_APP.SIM_TICKET_URL}
          ${TEMPLATE_APP.SIM_TICKET_LABEL}
          ${TEMPLATE_APP.USE_CASE}
          ${TEMPLATE_APP.CHART_OF_ACCOUNT}{
            ${TEMPLATE_APP_CHART_OF_ACCOUNTS.CHART_OF_ACCOUNTS_PK}
            ${TEMPLATE_APP_CHART_OF_ACCOUNTS.FPA_DIM_ACCOUNTS}
          }
          ${TEMPLATE_APP.SOURCE_DATA_TABLES}{
            ${TEMPLATE_APP_SOURCE_DATA_TABLES.DESCRIPTION}
            ${TEMPLATE_APP_SOURCE_DATA_TABLES.SCHEMA}
            ${TEMPLATE_APP_SOURCE_DATA_TABLES.SOURCE_TABLE_PK}
            ${TEMPLATE_APP_SOURCE_DATA_TABLES.TABLE}
            ${TEMPLATE_APP_SOURCE_DATA_TABLES.DATABASE}
            ${TEMPLATE_APP_SOURCE_DATA_TABLES.UPSTREAM_SOURCE}
          }
          ${TEMPLATE_APP.PERMISSION}{
            ${TEMPLATE_APP_PERMISSION.LINK}
            ${TEMPLATE_APP_PERMISSION.NAME}
            ${TEMPLATE_APP_PERMISSION.APP_PERMISSION_PK}
          }
          ${TEMPLATE_APP.DEVELOPER_TEAM}{
            ${TEMPLATE_APP_TEAM.TEAM}
            ${TEMPLATE_APP_TEAM.EMAIL}
          }
          ${TEMPLATE_APP.OUTPUT}{
            ${TEMPLATE_APP_OUTPUT.TYPE}
            ${TEMPLATE_APP_OUTPUT.VALUE}
            ${TEMPLATE_APP_OUTPUT.LABEL}
            ${TEMPLATE_APP_OUTPUT.APP_OUTPUT_PK}
          }
          ${TEMPLATE_APP.ACCOUNTING_TEAM} {
            ${TEMPLATE_APP_TEAM.TEAM}
            ${TEMPLATE_APP_TEAM.EMAIL}
          }
      }
    }   
`;
    // console.log(mQry)
    queryTaskManagementsByAliasAndStatusIndex(userId, 'INCOMPLETE', 100, null,
      function onSuccess(response) {
        setActiveTasks(response.data.queryTaskManagementsByAliasAndStatusIndex.items)
      },
      function onFailure(response) {
        setActiveTasks([])
      })
    queryTaskManagementsByAliasAndStatusIndex(userId, 'COMPLETE', 100, null,
      function onSuccess(response) {
        setCompleteTasks(response.data.queryTaskManagementsByAliasAndStatusIndex.items)
      },
      function onFailure(response) {
        setCompleteTasks([])
      })

    let response = await API.graphql(graphqlOperation(mQry)).then(result => {
      let qResultJSON = result.data.getAppsV2[0];
      setRenderJson(qResultJSON)
    }, function (error) {
      // no JSON in DB. Looks to be invalid appID. Go home
      history.push('/')
    });

    let res = await getEditableFields()
    setQuarterOptions(res.Quarter.values)
    quarter = getQuarterForUpload()
    setQuarterOption({ label: quarter, value: quarter })
    setViewRole({ 'label': userId, 'value': userId })
  }

  const setReportingCentralMainUrl = () => {
    let main_url = 'https://www.gamma.report-central.fintech.amazon.dev'
    if (currentStage == 'prod') {
      main_url = 'https://www.report-central.fintech.amazon.dev'
    }
    setRCMainUrl(main_url)
  }

  const checkFavorite = (appId) => {
    checkIsFavorite(appId, userId, function onResult(response) {
      setIsFavorite(response)
    })
  }

  const markFavorite = async (appId) => {
    setFavDisabled(true)
    await favorite(appId, userId, function onAdded(result) {
      setIsFavorite(true)
    }, function onDeleted(result) {
      setIsFavorite(false)
    }, function onFailure(result) {
    })
    setFavDisabled(false)
  }

  const loadProcessesWithTracksForQuarter = async (appId) => {
    let accessPerms = await isCorpAdmin(userId, appSettings[currentStage].appId, currentStage)
    setIsAdmin(accessPerms?.admin)
    getFriscoGroupsForAppId(appSettings[currentStage].appId,
      function onSuccess(result) {
        let resGroups = result.data.getFRISCOGroups
        getFRISCOGroupsWithConsolidatedStatusForQuarter(appId, quarterOption.value, async function onSuccess(result) {
          let res = result.data.getFRISCOGroupsWithConsolidatedStatusForQuarter
          let moduleLoadingComplete =false
          let leadsheetsLoadingComplete =false
          filterItemsByAccess(groupModules(res, resGroups), '', 'group_name', [{ label: userId, dismissLabel: "Alias" }], currentStage, function onResult(result, finished) {
            setGroups(result)
            moduleLoadingComplete=finished
            setIsProcessLoading(!(leadsheetsLoadingComplete && moduleLoadingComplete))
          })
          setAllLeadsheets(result?.data?.getFRISCOGroupsWithConsolidatedStatusForQuarter)
          filterItemsByAccess(result?.data?.getFRISCOGroupsWithConsolidatedStatusForQuarter, '', 'label', [{ label: userId, dismissLabel: "Alias" }], currentStage, function onResult(result, finished) {
            setFilteredLeadsheets(result)
            leadsheetsLoadingComplete=finished
            setIsProcessLoading(!(leadsheetsLoadingComplete && moduleLoadingComplete))
            setShowFilteredLeadsheetsLoader(leadsheetsLoadingComplete)
          })
        }, function onFailure(result) {
          setShowFilteredLeadsheetsLoader(false)
        })
      }, function onFailure(result) {

      })

  }

  const getDate = (value) => {
    var d = new Date(parseFloat(value) * 1000)
    return d.toLocaleString()
  }

  const lastUpdatedContent = (<>
    {renderJson[TEMPLATE_APP.LAST_UPDATED_TIME_STAMP] ? <>

      <span className="last_updated"> Last updated : <span className="bold">{getDate(renderJson[TEMPLATE_APP.LAST_UPDATED_TIME_STAMP])}</span></span>
      {renderJson[TEMPLATE_APP.LAST_UPDATED_BY] ? <span className="last_updated"> by  <span className="bold">{renderJson[TEMPLATE_APP.LAST_UPDATED_BY]}</span></span> : ''}
    </> : ''}
  </>)
  const getUrl = (item) => {
    return `/App/${appSettings[currentStage].appId}/process/${item[PROCESS_KEYS.process_id]}/history`
  }

  const getStatusBadge = (status) => {
    if (status == 'NOT INITIATED') {
      return <Box><span className='grey_badge'>NOT INITIATED</span></Box>
    } else if (status == 'COMPLETED') {
      return <Box><span className='green_badge'>COMPLETED</span></Box>
    } else if (status == 'INCOMPLETE') {
      return <Box><span className='red_badge'>INCOMPLETE</span></Box>
    }
  }

  const refresh = () => {
    loadRenderJson()
    loadProcessesWithTracksForQuarter()
  }

  const info = (
    <Box>
      <Container
      >
        <SpaceBetween>
          <div className="header_menu">
            <div className="header_left">
              <Box variant="h1">Application Details</Box>
            </div>
          </div>
          <br></br>
        </SpaceBetween>
        <SpaceBetween size="l">
          <ColumnLayout columns={2}>

            <div>
              <ExpandableSection
                variant="navigation"
                defaultExpanded={true}
                headerText="Process Output links"
              >
                <>
                  <br></br>
                  {renderJson[TEMPLATE_APP.OUTPUT] ? <Table
                    columnDefinitions={[
                      {
                        id: "type",
                        header: "Type",
                        cell: item => item[TEMPLATE_APP_OUTPUT.TYPE],
                        isRowHeader: true
                      },
                      {
                        id: "value",
                        header: "Link",
                        cell: item => <Link href={item[TEMPLATE_APP_OUTPUT.VALUE]} external>{item.hasOwnProperty(TEMPLATE_APP_OUTPUT.LABEL) ? item[TEMPLATE_APP_OUTPUT.LABEL] : item[TEMPLATE_APP_OUTPUT.TYPE]}</Link>,
                        sortingField: "alt"
                      }
                    ]}
                    items={renderJson[TEMPLATE_APP.OUTPUT]}
                    loadingText="Loading team details"
                    sortingDisabled
                    empty={
                      <Box
                        margin={{ vertical: "xs" }}
                        textAlign="center"
                        color="inherit"
                      >
                        <SpaceBetween size="m">
                          <b>No resources</b>
                        </SpaceBetween>
                      </Box>
                    }
                    variant='embedded'
                  /> : ''}
                </>
              </ExpandableSection>
            </div>

            <div>
              <ExpandableSection
                variant="navigation"
                defaultExpanded={true}
                headerText="Permissions"
              >
                <ul>
                  {renderJson[TEMPLATE_APP.PERMISSION]?.map((item) => (
                    (item.hasOwnProperty(TEMPLATE_APP_PERMISSION.LINK) ? <li key={item} className="list-item-data">
                      <Box variant="p">
                        <Link href={item.link} external>{item.name ? item.name : item.link}</Link>
                      </Box>
                    </li> : '')
                  ))}
                </ul>

              </ExpandableSection>
            </div>

            <div>
              <ExpandableSection
                variant="navigation"
                defaultExpanded={true}
                headerText="Organizations Supported"
              >
                <ul>
                  {renderJson[TEMPLATE_APP.ORGANIZATION]?.map((item) => (
                    <li key={item} className="list-item-data">
                      {item}
                    </li>
                  ))}
                </ul>

              </ExpandableSection>

            </div>

            <div>
              <ExpandableSection
                variant="navigation"
                defaultExpanded={true}
                headerText="Run Frequency"
              >
                <ul>
                  {renderJson[TEMPLATE_APP.USE_CASE]?.map((item) => (
                    <li key={item} className="list-item-data">
                      {item}
                    </li>
                  ))}
                </ul>

              </ExpandableSection>
            </div>

            <div>
              <Box variant="h4">Contact DEV TEAM for this Process</Box>
              <Box>{renderJson[TEMPLATE_APP.SIM_TICKET_URL] ?
                <Box variant="p">
                  <Button
                    href={renderJson[TEMPLATE_APP.SIM_TICKET_URL]}
                    iconAlign="right"
                    iconName="external"
                    target="_blank"
                  >
                    {renderJson[TEMPLATE_APP.SIM_TICKET_LABEL] ? renderJson[TEMPLATE_APP.SIM_TICKET_LABEL] : 'Raise a ticket'}
                  </Button>
                </Box> :
                <Box>
                  Not Available
                </Box>
              }
              </Box>

            </div>

            <div>
              <ExpandableSection
                variant="navigation"
                defaultExpanded={true}
                headerText="Use Case Type"
              >
                <ul>
                  {renderJson[TEMPLATE_APP.USE_CASE]?.map((item) => (
                    <li key={item} className="list-item-data">
                      {item}
                    </li>
                  ))}
                </ul>
              </ExpandableSection>
            </div>
          </ColumnLayout>
        </SpaceBetween>

      </Container>
    </Box>
  );


  const toolbar = (
    <Box>
      <Grid
        gridDefinition={[{ colspan: 3 }, { colspan: 3 }, { colspan: 3 }, { colspan: 3 }]}
      >
        <SpaceBetween direction='horizontal' size='s'>
          <Box>View as : </Box>
          <Select
            selectedOption={viewRole}
            onChange={({ detail }) => {
              viewRoles(detail.selectedOption)
            }
            }
            options={viewRoles}
          />
        </SpaceBetween>

        {/* <SpaceBetween direction='horizontal' size='s'>
                    <Box>Quarter : </Box>
                    <Select
                        selectedOption={quarterOption}
                        onChange={({ detail }) => {
                            setQuarterOption(detail.selectedOption)
                        }
                        }
                        options={quarterOptions}
                    />
                </SpaceBetween> */}
        <Button variant='normal' iconName='refresh' onClick={refresh}>Refresh</Button>
        <Box></Box>
      </Grid>
    </Box>
  )

  const getApprovalTasks = () => {
    let count = 0
    activeTasks.forEach((item) => {
      if (item?.taskType == 'APPROVAL') {
        count = count + 1
      }
    })
    return count
  }

  const brief = (
    <Grid
      gridDefinition={[{ colspan: 4 }, { colspan: 4 }, { colspan: 4 }]}
    >
      <Container>


        <SpaceBetween direction='vertical' size='l'>
          <Box textAlign="center"><h2>Workstreams</h2></Box>
          {
            isProcessLoading ? <Box textAlign="center"><Spinner /></Box> : <Box textAlign="center"><h2 class='pointer' onClick={() => { history.push("/CorpReportingHome/modules") }}>{groups.length}</h2></Box>
          }

        </SpaceBetween>
      </Container>
      <Container>


        <SpaceBetween direction='vertical' size='l'>
          <Box textAlign="center"><h2>Deliverables</h2></Box>
          {
            isProcessLoading ? <Box textAlign="center"><Spinner /></Box> :
              <Box textAlign="center">
                <h2 class='pointer' onClick={() => { history.push("/CorpReportingHome/deliverables") }}>{filteredLeadsheets.length}</h2>
              </Box>
          }
        </SpaceBetween>
      </Container>
      <Container>
        <SpaceBetween direction='vertical' size='l'>
          <Box textAlign="center"><h2>Approvals</h2></Box>
          {
            isProcessLoading ? <Box textAlign="center"><Spinner /></Box> :
              <Box textAlign="center">
                <h2 class='pointer' onClick={() => { history.push("/ApprovalsHome") }}>{activeTasks ? getApprovalTasks() : ''}</h2>
              </Box>
          }
        </SpaceBetween>
      </Container>
    </Grid>
  )

  const getNormalisedDateNumbers = (n) => {
    if (n <= 9) {
      n = `0${n}`
    }
    return n
  }
  const epochToTimestamp = (epoch) => {
    if(!epoch){
      return ''
    }
    let date = new Date(Number(epoch))
    let now_utc = Date.UTC(date.getUTCFullYear(), date.getUTCMonth(),
      date.getUTCDate(), date.getUTCHours(),
      date.getUTCMinutes(), date.getUTCSeconds());
    date = new Date(now_utc)
    return `${date.getUTCFullYear()}-${getNormalisedDateNumbers(date.getUTCMonth() + 1)}-${getNormalisedDateNumbers(date.getUTCDate())}`
  }

  const getClickAction = (item) => {
    if (item.taskType == 'UPLOAD') {
      return <SpaceBetween size="m" direction='horizontal'>
        <Link href={`/App/${item.appId}/process/${item.processId}/upload?back=CorpReportingHome&taskId=${item.taskId}`} >{item.description || "-"}</Link>
        {
          item?.previous_state_approval_id ? <Box>
            <Popover
              dismissButton={false}
              position="top"
              size="small"
              triggerType="custom"
              content={
                <StatusIndicator type="info">
                  {
                    item.previous_state ? <Button target="_blank" variant="inline-link" href={`/ApprovalsHome/Workflow/${item.previous_state_approval_id}`}>{item.previous_state}</Button> : 'Approval rejected'
                  }
                </StatusIndicator>
              }
            >
              <Button variant="inline-link" >Previous approval</Button>
            </Popover>
          </Box> : ''
        }
        {
          item?.rc_rejected_approval ? <Box>
            <Popover
              dismissButton={false}
              position="top"
              size="small"
              triggerType="custom"
              content={
                <StatusIndicator type="info">
                  <Button target="_blank" variant="inline-link" href={`${rcMainUrl}/#/reporting/workflows/view-workflow=%7B%22workflowId%22%3A%22${item.rc_rejected_approval}%22%7D`}> {item.rc_rejected_approval_message && item.rc_rejected_approval_message.length > 0 ? item.rc_rejected_approval_message.join(", ") : 'Approval rejected'}</Button>
                </StatusIndicator>
              }
            >
              <Button variant="inline-link" >Previous Admin approval</Button>
            </Popover>
          </Box> : ''
        }
      </SpaceBetween>
    } else if (item.taskType == 'APPROVAL') {
      return <Link href={`/ApprovalsHome/Workflow/${item.approvalId}`} >{item.description || "-"}</Link>
    } else if (item.taskType == 'ADMIN_APPROVAL') {
      return <Link href={`${rcMainUrl}/#/reporting/workflows/view-workflow=%7B%22workflowId%22%3A%22${item.approvalId}%22%7D`} >{item.description || "-"}</Link>
    }
  }
  const getTaskView = (taskList) => {
    return <Table
      columnDefinitions={[
        {
          id: "taskType",
          header: "Type",
          cell: item => item?.taskType,
          sortingField: "taskType",
          width: "150px"
        },
        {
          id: "description",
          header: "Description",
          cell: item => getClickAction(item),
          sortingField: "description",
          isRowHeader: true
        },
        {
          id: "requestor",
          header: "Requestor",
          cell: item => item?.requestor ? <SpaceBetween size='s' direction='horizontal'>
            <img
              alt=""
              className="rounded-circle-small"
              src={'https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=' + item?.requestor}

            />
            <Link href={`https://phonetool.amazon.com/users/${item?.requestor.trim()}`} target="_blank"> {item?.requestor.trim()} </Link>
          </SpaceBetween> : <>
            {
              item?.createdBy == 'FRISCO_BOT' ? 'BOT' : item?.createdBy
            }
          </>,
          sortingField: "requestor",
          width: "150px"
        },
        {
          id: "dueDate",
          header: "Due date (UTC)",
          cell: item => epochToTimestamp(item?.dueDate),
          sortingField: "dueDate",
          width: "150px"
        }
      ]}
      items={taskList}
      // loading={taskList.length==0}
      loadingText="Loading details"
      resizableColumns={true}
      empty={
        <Box
          margin={{ vertical: "xs" }}
          textAlign="center"
          color="inherit"
        >
          <SpaceBetween size="m">
            <b>No tasks</b>
          </SpaceBetween>
        </Box>
      }
    />
  }
  const activeTasksContent = (
    getTaskView(activeTasks)
  )
  const completedTasksContent = (
    getTaskView(completeTasks)
  )
  const tasks = (
    <Tabs
      onChange={({ detail }) =>
        setActiveTabId(detail.activeTabId)
      }
      activeTabId={activeTabId}
      tabs={[
        {
          label: "Active tasks",
          id: "activeTask",
          content: activeTasksContent
        },
        {
          label: "Completed tasks",
          id: "completedTask",
          content: completedTasksContent
        }
      ]}
    />
  )

  const teamsTask = (
    <SpaceBetween direction='vertical' size="m">
      <Box fontSize="heading-m" fontWeight="bold">
        Team's task
      </Box>
      <Table
        columnDefinitions={[
          {
            id: "taskType",
            header: "Type",
            cell: item => item?.taskType,
            sortingField: "taskType",
            width: "150px"
          },
          {
            id: "description",
            header: "Description",
            cell: item => (
              item.taskType == 'UPLOAD' ?
                <SpaceBetween size="m" direction='horizontal'>
                  <Link href={`/App/${item.appId}/process/${item.processId}/upload?back=CorpReportingHome&taskId=${item.taskId}`} external>{item.description || "-"}</Link>
                  {
                    item?.previous_state_approval_id ? <Box>
                      <Popover
                        dismissButton={false}
                        position="top"
                        size="small"
                        triggerType="custom"
                        content={
                          <StatusIndicator type="info">
                            {
                              item.previous_state ? <Button target="_blank" variant="inline-link" href={`/ApprovalsHome/Workflow/${item.previous_state_approval_id}`}>{item.previous_state}</Button> : 'Approval rejected'
                            }
                          </StatusIndicator>
                        }
                      >
                        <Button variant="inline-link" >Previous approval</Button>
                      </Popover>
                    </Box> : ''
                  }
                  {
                    item?.rc_rejected_approval ? <Box>
                      <Popover
                        dismissButton={false}
                        position="top"
                        size="small"
                        triggerType="custom"
                        content={
                          <StatusIndicator type="info">
                            <Button target="_blank" variant="inline-link" href={`${rcMainUrl}/#/reporting/workflows/view-workflow=%7B%22workflowId%22%3A%22${item.rc_rejected_approval}%22%7D`}> {item.rc_rejected_approval_message && item.rc_rejected_approval_message.length > 0 ? item.rc_rejected_approval_message.join(", ") : 'Approval rejected'}</Button>
                          </StatusIndicator>
                        }
                      >
                        <Button variant="inline-link" >Previous Admin approval</Button>
                      </Popover>
                    </Box> : ''
                  }
                </SpaceBetween>
                : <Link href={`/ApprovalsHome/Workflow/${item.approvalId}`} external>{item.description || "-"}</Link>

            ),
            sortingField: "description",
            isRowHeader: true
          },
          {
            id: "requestor",
            header: "Requestor",
            cell: item => item?.requestor ? <SpaceBetween size='s' direction='horizontal'>
              <img
                alt=""
                className="rounded-circle-small"
                src={'https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=' + item?.requestor}

              />
              <Link href={`https://phonetool.amazon.com/users/${item?.requestor.trim()}`} target="_blank"> {item?.requestor.trim()} </Link>
            </SpaceBetween> : <>
              {
                item?.createdBy == 'FRISCO_BOT' ? 'BOT' : item?.createdBy
              }
            </>,
            sortingField: "requestor",
            width: "150px"
          },
          {
            id: "dueDate",
            header: "Due date (UTC)",
            cell: item => epochToTimestamp(item?.dueDate),
            sortingField: "dueDate",
            width: "150px"
          }
        ]}
        items={[]}
        // loading={taskList.length==0}
        loadingText="Loading details"
        resizableColumns={true}
        empty={
          <Box
            margin={{ vertical: "xs" }}
            textAlign="center"
            color="inherit"
          >
            <SpaceBetween size="m">
              <b>No tasks</b>
            </SpaceBetween>
          </Box>
        }
      // header={<Header>Table that is loading</Header>}
      >

      </Table>
    </SpaceBetween>
  )

  const Content = (
    <>{
      showLoadingIcon ? <Modal visible={showLoadingIcon}><Box>
        <Spinner></Spinner>
        Loading...
      </Box></Modal> :
        renderJson && currentStage ?
          <SpaceBetween direction='vertical' size='xl'>
            <Box></Box>
            {toolbar}
            {brief}
            {tasks}
            {teamsTask}
            {/* <Feedback appId={appSettings[currentStage].appId} pageId={appSettings[currentStage].appId} userId={userId} parentId={"null"} level={0} limit={1}></Feedback> */}
          </SpaceBetween>
          : <Box>
            <Spinner></Spinner>
            Loading...
          </Box>
    }</>
  )


  return (
    <AppLayout
      disableContentPaddings={false}
      content={Content}
      contentType="default"
      navigationOpen={NavOpen}
      navigation={<SideNav activeHref={`/CorpReportingHome`} id={appSettings[currentStage].appId} moduleCount={groups.length} deliverablesCount={allLeadsheets.length} isAdmin={isAdmin} />}
      onNavigationChange={() => {
        setNavOpen(!NavOpen);
      }}
      toolsHide={true}
      headerVariant="high-contrast"
    />
  );
};


