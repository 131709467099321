import { API, graphqlOperation } from "aws-amplify";


export const getBoffinComputesWithStartedStatus = async () => {

    const variables = {
        limit: 100000
    }

    let computes = []
    let qry = `query MyQuery($limit: Int!, $nextToken: String) {
                    listUI4BI_BoffinComputeInfos(limit: $limit , filter: {lockStatus: {eq: "Started"}}, nextToken: $nextToken){
                    items {
                        accounts
                        createDate
                        datasets
                        id
                        jeTypes
                        ledgerTypes
                        pre_signed_url
                        teamName
                        processRow
                        processStatus
                        userId
                        showPostingButton
                        filterOption
                        selectedLedgers
                        periodMonth
                        periodYear
                        showPostingButton
                        isAllJEPosted
                        wdLink
                        selectedCompanyCodes
                        postingStatus
                        lockStatus
                    }
                    nextToken
                    }
                }
            `;

    let startedResponse = await API.graphql(graphqlOperation(qry, variables));
    computes = computes.concat(startedResponse.data.listUI4BI_BoffinComputeInfos.items);
    while (startedResponse.data.listUI4BI_BoffinComputeInfos.nextToken) {
        variables["nextToken"] = startedResponse.data.listUI4BI_BoffinComputeInfos.nextToken
        startedResponse = await API.graphql(graphqlOperation(qry, variables));
        computes = computes.concat(startedResponse.data.listUI4BI_BoffinComputeInfos.items)
    }
    return computes;
    
}

export const updateBoffinComputeLockStatus = async (computeId, lockStatus) => {

    let qry = `
    mutation MyMutation {
      updateUI4BI_BoffinComputeInfo(input: {id: "${computeId}", lockStatus :"${lockStatus}"})
            {
              id
              lockStatus
            }
    }`;
    let response = await API.graphql(graphqlOperation(qry));
    return response.data.updateUI4BI_BoffinComputeInfo;


}
