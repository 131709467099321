import { API, graphqlOperation } from "aws-amplify";


export const getLogsById = async (pk) => {

    const variables = {
        limit: 20000
    }

    let logs = []
    let qry = `query MyQuery($limit: Int!, $nextToken: String) {
        getABCUIAuditLogging(pk: "${pk}", first: $limit, nextToken: $nextToken) {
          items {
            pk
            action
            before
            after
            actor
            comments
            last_updated_time_stamp
            sk
          }
          nextToken
        }
      
      }
      `
    let response = await API.graphql(graphqlOperation(qry, variables));
    logs = logs.concat(response.data.getABCUIAuditLogging.items)
    while (response.data.getABCUIAuditLogging.nextToken) {
        variables["nextToken"] = response.data.getABCUIAuditLogging.nextToken
        response = await API.graphql(graphqlOperation(qry, variables));
        logs = logs.concat(response.data.getABCUIAuditLogging.items)
    }
    logs.sort((a,b) => b?.last_updated_time_stamp - a?.last_updated_time_stamp)
    return logs

}

export const getLogsByType = async (pk) => {

    const variables = {
        limit: 100000
    }

    let logs = []
    let qry = `query MyQuery($limit: Int!, $nextToken: String) {
        listABCUIAuditLoggings(limit: $limit, filter: {pk: {beginsWith: "${pk}"}} , nextToken: $nextToken) {
          items {
            action
            actor
            after
            before
            comments
            last_updated_time_stamp
            pk
            sk
          }
          nextToken
        }
      }
      `
    let response = await API.graphql(graphqlOperation(qry, variables));
    logs = logs.concat(response.data.listABCUIAuditLoggings.items)
    while (response.data.listABCUIAuditLoggings.nextToken) {
        variables["nextToken"] = response.data.listABCUIAuditLoggings.nextToken
        response = await API.graphql(graphqlOperation(qry, variables));
        logs = logs.concat(response.data.listABCUIAuditLoggings.items)
    }
    logs.sort((a,b) => b?.last_updated_time_stamp - a?.last_updated_time_stamp)
    return logs

}