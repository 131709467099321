import React, { useState, useEffect } from 'react';
import { Box, SpaceBetween } from "@amzn/awsui-components-react"
import { FRISCO_STATUS } from "../Frisco/Constants"
import { isApprover, isUploaderAllowed } from '../Frisco/AccessUtilities';

export const getStateNonAdmin = (item) => {
    if (item?.current_status == FRISCO_STATUS.COMPLETED || item?.current_status == FRISCO_STATUS.COMPLETE_NOTIFIED
        || item?.current_status == FRISCO_STATUS.PREPARING_DATA_FOR_APPROVAL || item?.current_status == FRISCO_STATUS.DATA_PREPARED_FOR_APPROVAL
        || item?.current_status == FRISCO_STATUS.DATA_PREPARING_FOR_APPROVAL || item?.current_status == FRISCO_STATUS.ADMIN_APPROVAL_PENDING
        || item?.current_status == FRISCO_STATUS.ADMIN_APPROVAL_COMPLETE || item?.current_status == FRISCO_STATUS.MOVED_TO_FDV 
        || item?.current_status == 'MOVING_TO_FDV' || item?.current_status == FRISCO_STATUS.SHARED_TO_CATALOG
        || item?.current_status == FRISCO_STATUS.CATALOG_DATA_WRITE_COMPLETE || item?.current_status == FRISCO_STATUS.REPORTING_CENTRAL_APPROVAL_STATUS_SUCCESS
        || item?.current_status == FRISCO_STATUS.REPORTING_CENTRAL_APPROVAL_STATUS_PENDING || item?.current_status == FRISCO_STATUS.SHARING_TO_CATALOG
        || item?.current_status == FRISCO_STATUS.REPORTING_CENTRAL_APPROVAL_STATUS_REQUESTED) {
        return <Box><span className='green_badge small_badge'>COMPLETE</span></Box>
    } else {
        return <Box><span className='red_badge small_badge'>INCOMPLETE</span></Box>
    }
    
}

export const getStateNonAdminPlain = (item) => {
    // if (item?.current_status == FRISCO_STATUS.COMPLETED || item?.current_status == FRISCO_STATUS.COMPLETE_NOTIFIED
    //     || item?.current_status == FRISCO_STATUS.PREPARING_DATA_FOR_APPROVAL || item?.current_status == FRISCO_STATUS.DATA_PREPARED_FOR_APPROVAL
    //     || item?.current_status == FRISCO_STATUS.DATA_PREPARING_FOR_APPROVAL || item?.current_status == FRISCO_STATUS.ADMIN_APPROVAL_PENDING
    //     || item?.current_status == FRISCO_STATUS.ADMIN_APPROVAL_COMPLETE || item?.current_status == FRISCO_STATUS.MOVED_TO_FDV 
    //     || item?.current_status == 'MOVING_TO_FDV' || item?.current_status == FRISCO_STATUS.SHARED_TO_CATALOG 
    //     || item?.current_status == FRISCO_STATUS.CATALOG_DATA_WRITE_COMPLETE || item?.current_status == FRISCO_STATUS.REPORTING_CENTRAL_APPROVAL_STATUS_SUCCESS
    //     || item?.current_status == FRISCO_STATUS.REPORTING_CENTRAL_APPROVAL_STATUS_PENDING || item?.current_status == FRISCO_STATUS.SHARING_TO_CATALOG) {
    //     return <SpaceBetween>
    //         <Box>COMPLETE</Box>
    //         <span className='small_values'> { item.current_status == FRISCO_STATUS.CATALOG_DATA_WRITE_COMPLETE? FRISCO_STATUS.REPORTING_CENTRAL_APPROVAL_STATUS_REQUESTED:item.current_status}</span>
    //     </SpaceBetween>
    // } else {
    //     return <SpaceBetween>
    //         <Box>INCOMPLETE</Box>
    //         <span className='small_values'> {item.current_status == FRISCO_STATUS.CATALOG_DATA_WRITE_COMPLETE? FRISCO_STATUS.REPORTING_CENTRAL_APPROVAL_STATUS_REQUESTED:item.current_status}</span>
    //     </SpaceBetween>
    // }
    if(item?.overall_status == "INCOMPLETE"){
        return <SpaceBetween>
             <Box><span className='red_badge small_badge'>INCOMPLETE</span></Box>
            <span className='small_values'> { item.current_status == FRISCO_STATUS.CATALOG_DATA_WRITE_COMPLETE? FRISCO_STATUS.REPORTING_CENTRAL_APPROVAL_STATUS_REQUESTED:item.current_status}</span>
         </SpaceBetween>
        
    }else if(item.overall_status == "PENDING"){
        return <SpaceBetween>
             <Box><span className='orange_badge small_badge'>PENDING</span></Box>
            <span className='small_values'> { item.current_status == FRISCO_STATUS.CATALOG_DATA_WRITE_COMPLETE? FRISCO_STATUS.REPORTING_CENTRAL_APPROVAL_STATUS_REQUESTED:item.current_status}</span>
         </SpaceBetween>
    }else if(item.overall_status == "COMPLETED"){
        return <SpaceBetween>
             <Box><span className='green_badge small_badge'>COMPLETED</span></Box>
            <span className='small_values'> { item.current_status == FRISCO_STATUS.CATALOG_DATA_WRITE_COMPLETE? FRISCO_STATUS.REPORTING_CENTRAL_APPROVAL_STATUS_REQUESTED:item.current_status}</span>
         </SpaceBetween>
    }else{
        return<SpaceBetween>
             <Box><span className='red_badge small_badge'>INCOMPLETE</span></Box>
            <span className='small_values'> { item.current_status == FRISCO_STATUS.CATALOG_DATA_WRITE_COMPLETE? FRISCO_STATUS.REPORTING_CENTRAL_APPROVAL_STATUS_REQUESTED:item.current_status}</span>
         </SpaceBetween>
    }
}


const getUniqueAlias = (aliasList) => {
    if(aliasList){
        let tempSet = new Set()
        let aliasSet = []
        aliasList.forEach((aliasItem) => {
            if(aliasItem && !tempSet.has(aliasItem.alias)){
                tempSet.add(aliasItem.alias)
                aliasSet.push(aliasItem)
            }
        })
        return aliasSet
    }
}

export const groupModules = (res, resGroups) => {
    let groupSet = {'status':{}, 'uploaders':{}, 'approvers':{}}
    res.forEach(item => {
        let status = item.overall_status
        if (!groupSet['status'][item.group_id]) {
            groupSet['status'][item.group_id] = []
        }
        if (status) {
            groupSet['status'][item.group_id].push(status.toUpperCase())
        }
        if (!groupSet['approvers'][item.group_id]) {
            groupSet['approvers'][item.group_id] = []
        }
        if (!groupSet['uploaders'][item.group_id]) {
            groupSet['uploaders'][item.group_id] = []
        }
        
        let temp = groupSet['approvers'][item.group_id]
        let approverAliasArr = getUniqueAlias(temp.concat(item?.approvers))
        temp = groupSet['uploaders'][item.group_id]
        let uploaderAliasArr = getUniqueAlias(temp.concat(item?.uploaders))
        groupSet['approvers'][item.group_id] = approverAliasArr
        groupSet['uploaders'][item.group_id] = uploaderAliasArr
    })

    for (let i = 0; i < resGroups.length; i++) {
        if (groupSet['status'][resGroups[i].group_id].length == 0) {
            resGroups[i]['consolidated_status'] = "INCOMPLETE"
        } else {
            if (groupSet['status'][resGroups[i].group_id].includes("INCOMPLETE") || groupSet['status'][resGroups[i].group_id].includes("PENDING")) {
                resGroups[i]['consolidated_status'] = "INCOMPLETE"
            } else {
                resGroups[i]['consolidated_status'] = "COMPLETED"
            }
        }

        resGroups[i]['approvers'] = groupSet['approvers'][resGroups[i].group_id]
        resGroups[i]['uploaders'] = groupSet['uploaders'][resGroups[i].group_id]
    }
    return resGroups
}
export const filterItemsByAccess = async (groups, filteringText, filteringKey, filters , currentStage,
    onResult=null) => {
    let fGroups = []
    for(let i =0;i<groups.length;i++){
        // if (groups[i][filteringKey]?.toLowerCase().includes(filteringText.toLowerCase())) {
            if(filters && filters.length > 0){
                let canUpload = await isUploaderAllowed(groups[i], filters[0].label, currentStage=='dev'?'beta':currentStage) 
                let canApprove = false
                if(!canUpload){
                    canApprove = await isApprover(groups[i], filters[0].label, currentStage=='dev'?'beta':currentStage)
                }
                if(canUpload || canApprove ){
                    fGroups.push(groups[i])
                    if(onResult){
                        onResult(fGroups, false)
                    }
                }
            // }else{
            //     fGroups.push(groups[i])
            //     if(onResult){
            //         onResult(fGroups, false)
            //     }
            // }
        }
    }
    onResult(fGroups, true)
    
    return fGroups
}

export const getQuarterForUpload = () => {
    var today = new Date();
    console
    switch(today.getMonth()){
        case 0: 
        case 1: 
        case 2: 
            return `Q4 ${today.getFullYear()-1}`
        case 3: 
        case 4: 
        case 5: 
            return `Q1 ${today.getFullYear()}`
        case 6: 
        case 7: 
        case 8: 
            return `Q2 ${today.getFullYear()}`
        case 9: 
        case 10: 
        case 11: 
            return `Q3 ${today.getFullYear()}`
    }
}