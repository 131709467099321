import React, { useState, useEffect } from "react";
import "../../index.scss";
import { useHistory, useParams } from "react-router-dom";
import AuditLoggingMain from '../AuditLogging/AuditLoggingMain';
import { getLogsById } from "../AuditLogging/AuditLogsAPIs";

export default (props) => {
    const [showLoadingIcon, setShowLoadingIcon] = useState(true);
    const [logsData, setLogsData] = useState();

    const [headerHref, setHeaderHref] = useState()
    const [sideNavBack, setSideNavBack] = useState()
    const [sideHeader, setSideHeader] = useState("Audit Logs")

    let { id } = useParams();
    let { fid } = useParams();


    useEffect(() => {
        loadData();
        window.scrollTo(0, 0)
        return () => {
            // this now gets called when the component unmounts
        };
    }, []);


    const loadData = async () => {
        setShowLoadingIcon(true);
        setHeaderHref(`/App/${id}/flexico/${fid}/FlexicoProcessAuditLogs`);
        setSideNavBack(`/App/${id}/flexico/${fid}`);
        const pk = `FLEXICO#${fid}`
        setSideHeader(`Audit Logs of ${pk}`)
        const logs = await getLogsById(pk)
        setLogsData(logs)
        setShowLoadingIcon(false);
    };

    return (
        <AuditLoggingMain logsData={logsData} headerHref={headerHref} sideNavBack={sideNavBack} sideHeader={sideHeader} />
    );
};
