import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import SideNav from "./SideNav";
import appSettings from "./app_settings";
import axios from "axios";
import WaitForPageAccess from "../WaitForPageAccess";
import AccessDenied from "../AccessDenied";
import { setCurrentAppId, setCurrentAppPage } from "../store/userAuthSlice";
import {
  AppLayout,
  Box,
  Grid,
  SpaceBetween,
  HelpPanel,
  Button,
  Flashbar
} from "@amzn/awsui-components-react/polaris";
import { API, Auth } from "aws-amplify";
import { useAuth } from "../context/AuthContextProvider";
import { getQuicksightDashboardConfig } from "src/utilities/AWSServices"

function Home() {
  const history = useHistory();
  const location = useLocation();
  const currentApp = useSelector((globalState) => globalState.auth.currentApp);
  const isCurrentAppAuthorised = useSelector(
    (globalState) => globalState.auth.isCurrentAppAuthorized
  );

  const [isCheckingAccess] = useState(true);
  const [isAuthorized, setIsAuthorized] = useState(false);

  const dispatch = useDispatch();

  const [navIsOpen, setNavIsOpen] = useState(false);
  const [toolsHide, setToolsHide] = useState(false);
  
  var url = getQuicksightDashboardConfig()?.dashboard?.url
  const [embedUrl, setEmbedUrl] = useState();

  const [flashMessage, setFlashMessage] = useState([{
    type: "info",
    dismissible: true,
    dismissLabel: "Dismiss message",
    onDismiss: () => setFlashMessage([]),
    content: (
      <>
        Quicksight will be Integrated soon.
      </>
    ),
    id: "comments"
  }]);
  
  useEffect(() => {
    getEmbedUrl(url)
  }, [url])

  useEffect(() => {
    dispatch(setCurrentAppId(''));

    const urlParams = new URLSearchParams(location?.search);
    const myParam = urlParams?.get('redirect_uri');
    if(myParam && atob(myParam)){
      history.push(atob(myParam))
    }
  },[])
  // useEffect(() => {
  //   if (currentApp !== appSettings.appId) {
  //     dispatch(setCurrentAppId(appSettings.appId));
  //     dispatch(setCurrentAppPage(location.pathname));
  //   }
  //   setIsAuthorized(isCurrentAppAuthorised);

  //   return () => {
  //     // this now gets called when the component unmounts
  //   };
  // }, [isCheckingAccess, isCurrentAppAuthorised]);

  const getStarted = () => {
    history.push("/ATeamBIHome");
  };

  const getEmbedUrl = async (url) => {

    const authorization = (await Auth.currentSession())
      .getIdToken()
      .getJwtToken();

    const config = {
      headers: {
        Authorization: authorization
      }
    };
    axios.get(url, config)
      .then(response => {
        setEmbedUrl(response.data)
      })
      .catch(error => {
        // Handle the error
      });
  }
  const items = [
    { id: "100", teamName: "Accounting Apps", link: "/ATeamBIAllProducts" }
  ];

  const userAuthenticationDetails = useAuth();

  const helpContent = (
    <HelpPanel
      footer={<div></div>}
      header={
        <div>
          <div></div>
          <h2>Additional Info </h2>
        </div>
      }
    >
      <div>
        <pre>Version : 1.0 , release Date: Oct 31,2022</pre>
      </div>
      <div></div>
    </HelpPanel>
  );
  const content = (
    
        <Box
          padding={{ top: "l", left: "s", right: "s", bottom: "l" }}
          margin={{ bottom: "l" }}
        >
          <div className="custom-home__header">
            <Grid gridDefinition={[{ colspan: { xxs: 12 } }]}>
              <Box padding={{ vertical: "xl" }}>
                <Grid
                  gridDefinition={[
                    { offset: { l: 2, xxs: 1 }, colspan: { l: 8, xxs: 10 } },
                    {
                      colspan: { xxs: 10, s: 6, l: 5, xl: 6 },
                      offset: { xxs: 1, l: 2 },
                    },
                    {
                      colspan: { xxs: 10, s: 4, l: 3, xl: 2 },
                      offset: { xxs: 1, s: 0 },
                    },
                    {
                      colspan: { xxs: 10, s: 4, l: 3, xl: 2 },
                      offset: { xxs: 1, s: 0 },
                    },
                  ]}
                >

                  <div className="custom-home__header-title">
                    <Box
                      variant="h1"
                      fontWeight="bold"
                      padding="n"
                      fontSize="display-l"
                      color="inherit"
                    >
                      Accounting Central
                    </Box>
                    <Box
                      fontWeight="light"
                      padding={{ bottom: "s" }}
                      fontSize="heading-l"
                      color="inherit"
                    >
                      One stop for Everything Accounting
                    </Box>
                    <Box fontWeight="light"
                      padding={{ bottom: "m" }}
                      fontSize="heading-l"
                      color="inherit">
                      <Button variant="primary" onClick={() => {
                        history.push("/ATeamBIAllProducts");
                      }}>Click here for accounting apps</Button>
                    </Box>
                  </div>

                </Grid>
              </Box>
            </Grid>
          </div>
          <Box padding={{ top: "xl" }}>
            {/* <Box padding={{ left: "m" }}>
              <p>
                <strong>Browse through the </strong><Link href="/ATeamBIAllProducts">Accounting apps</Link> <strong> for more information </strong>
              </p>
            </Box> */}
            {
              url ? <Box padding={{ left: "m" }}>
                <p>
                  <strong>Home to all accounting applications. The below report contains more information on our application.</strong>
                </p>
              </Box> : ''
            }

            {
              url ?
                <div>
                  <iframe
                    className="qs_dash"
                    src={embedUrl}>
                  </iframe>
                </div> :
                <div><Flashbar items={flashMessage} /></div>
            }
          </Box>
        </Box>
      
  );
  return (
    content
  );
}

export default Home;
