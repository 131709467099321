import React, { useState, useEffect } from "react";
import { glossary, TEMPLATE_APP, TEMPLATE_APP_ACCOUNTING, TEMPLATE_APP_USE_CASE } from "./Constants";
import { setCurrentAppId } from "../../store/userAuthSlice";
import SideNav from "./SideNav";
import {
  AppLayout,
  Button,
  ColumnLayout,
  Input,
  Icon,
  Textarea,
  Container,
  Header,
  Box,
  Grid,
  SpaceBetween,
  Link,
  ExpandableSection,
  ContentLayout,
  Select,
  Modal,
  Spinner,
  Popover,
  StatusIndicator,
  HelpPanel,
  Flashbar
} from "@amzn/awsui-components-react";

import "../../index.scss";
import "./template.scss"
import { useSelector, useDispatch } from "react-redux";

import { useHistory, useParams } from "react-router-dom";

import favLogo from '../../images/star-regular-brown-for-home.svg'
import favLogoSolid from '../../images/star-solid-brown-for-home.svg'
import { favorite, checkIsFavorite } from '../../utilities/Favorites'
import CustomSelect from "./components/CustomSelect";
import CustomMultiSelect from "./components/CustomMultiSelect";
import CustomInput from "./components/CustomInput";
import CustomMultiInput from "./components/CustomMultiInput";
import CustomMultiHybridInputsForAppOutputs from "./components/CustomMultiHybridInputsForAppOutputs"
import { getEditableFields } from "./Utils/FormMetadata";
import { canEditApplication, canDeleteApplication } from "./Utils/Permissions";
import { addApp, updateApp, deleteApp } from "./Utils/AppActivityUtils"
// Component ServiceHomepage is a skeleton of a service's homepage using AWS-UI React components.
export default (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [NavOpen, setNavOpen] = useState(true);
  const [showLoadingIcon, setShowLoadingIcon] = useState(true);
  const userId = useSelector((globalState) => globalState.auth.userId);

  //UseCase Details
  const [usecaseName, setUsecaseName] = useState('');
  const [usecaseDescription, setUsecaseDescription] = useState();
  const [usecaseStatus, setUsecaseStatus] = useState();
  const [usecaseComments, setUsecaseComments] = useState();
  const [usecaseType, setUsecaseType] = useState([]);
  const [usecaseRegions, setUsecaseRegions] = useState([]);
  const [usecaseLedgerImpact, setUsecaseLedgerImpact] = useState([]);
  const [usecaseLaunchMCM, setUsecaseLaunchMCM] = useState('');
  const [usecaseLaunchQQYY, setUsecaseLaunchQQYY] = useState();
  const [usecaseRunFrequency, setUsecaseRunFrequency] = useState([]);
  const [usecaseMERSavings, setUsecaseMERSavings] = useState();
  const [usecaseMJECount, setUsecaseMJECount] = useState();
  const [deleteConfirmationModal, setDeleteConfirmationModal] = useState(false);
  const [deleteAppName, setDeleteAppName] = useState('');

  // Operation Details
  const [usecaseInputSourceTypes, setUsecaseInputSourceTypes] = useState([]);
  const [usecaseDataSourceDetails, setUsecaseDataSourceDetails] = useState([]);
  const [usecaseOutputType, setUsecaseOutputType] = useState([]);
  const [usecaseAutomationTools, setUsecaseAutomationTools] = useState([]);
  const [usecaseARD, setUsecaseARD] = useState();
  const [usecasePDD, setUsecasePDD] = useState();
  const [usecaseTDD, setUsecaseTDD] = useState();
  const [usecaseOutputLink, setUsecaseOutputLink] = useState();

  //Team Details
  const [developerTeam, setDeveloperTeam] = useState();
  const [processOwnerAlias, setProcessOwnerAlias] = useState();
  const [accountingOrg, setAccountingOrg] = useState();
  const [accountingTeam, setAccountingTeam] = useState();
  const [accountingVP, setAccountingVP] = useState();
  const [accountingTeamEmail, setAccountingTeamEmail] = useState();

  const [toolsOpen, setToolsOpen] = useState(false);

  const [flashBarComments, setFlashBarComments] = useState();
  const [id, setId] = useState(props?.id);
  const [customSideNavitems, setCustomSideNavitems] = useState(props?.customSideNavitems)
  const [renderJson, setRenderJson] = useState(props?.renderJson)
  const [renderJsonEdited, setRenderJsonEdited] = useState(props?.renderJson)

  const [isFavorite, setIsFavorite] = useState(false);
  const [favDisabled, setFavDisabled] = useState(false);
  const [fieldMetadata, setFieldMetadata] = useState({});
  const [isEditMode, setIsEditMode] = useState(false);
  const [isNewApp, setIsNewApp] = useState(false);
  const [errorMessage, setErrorMessage] = useState('')

  useEffect(() => {

    setInitView()
    return () => {
      // this now gets called when the component unmounts
    };
  }, []);

  useEffect(() => {
    setInitView()
    checkFavorite(id)
    return () => {
      // this now gets called when the component unmounts
    };
  }, [id]);

  useEffect(() => {
    checkFavorite(id)
  }, [userId]);

  useEffect(() => {

    setFlashBarComments([{
      type: "error",
      dismissible: true,
      dismissLabel: "Dismiss message",
      onDismiss: () => setFlashBarComments([]),
      content: (
        <>
          {usecaseComments}
        </>
      ),
      id: "comments"
    }])

  }, [usecaseComments])

  useEffect(() => {
    setRenderJson(props.renderJson)
    setRenderJsonEdited(props.renderJson)
  }, [props.renderJson])

  useEffect(() => {
    loadData()
    setRenderJsonEdited(renderJson)
  }, [renderJson])

  const setInitView = () => {
    loadEditableFields();
    if (id) {
      dispatch(setCurrentAppId(id));
      loadData();
      setIsEditMode(false)
      setIsNewApp(false)
    } else {
      setIsNewApp(true)
      setShowLoadingIcon(false);
      setIsEditMode(true)
    }
    window.scrollTo(0, 0)
  }
  const loadEditableFields = () => {
    setFieldMetadata(getEditableFields())
  }
  const loadData = async () => {
    if (!isNewApp && renderJson && Object.keys(renderJson).length > 0) {
      setShowLoadingIcon(true);
      setUsecaseName(renderJson[TEMPLATE_APP.APP_NAME]);
      setUsecaseDescription(renderJson[TEMPLATE_APP.APP_DESCRIPTION]);
      setUsecaseStatus(renderJson[TEMPLATE_APP.STATUS]);
      setUsecaseComments(renderJson[TEMPLATE_APP.FLASH_INFO_DESCRIPTION]);
      setUsecaseLedgerImpact(renderJson[TEMPLATE_APP.LEDGER_INFO]);
      setUsecaseLaunchMCM(renderJson[TEMPLATE_APP.LAUNCH_MCM]);
      setUsecaseLaunchQQYY(renderJson[TEMPLATE_APP.LAUNCH_QQ_YY]);
      setUsecaseRunFrequency(renderJson[TEMPLATE_APP.CADENCE]);
      setUsecaseMERSavings(renderJson[TEMPLATE_APP.ANNUAL_MER_SAVINGS]);
      setUsecaseMJECount(renderJson[TEMPLATE_APP.PROCESS_MJE_COUNT]);
      setUsecaseRegions(renderJson[TEMPLATE_APP.REGION]);
      setUsecaseType(renderJson[TEMPLATE_APP.USE_CASE]);

      setUsecaseDataSourceDetails(renderJson[TEMPLATE_APP.DATASOURCE]);
      setUsecaseARD(renderJson[TEMPLATE_APP.ARD]);
      setUsecasePDD(renderJson[TEMPLATE_APP.PDD]);
      setUsecaseTDD(renderJson[TEMPLATE_APP.TDD]);
      setUsecaseInputSourceTypes(
        renderJson[TEMPLATE_APP.INPUT_SOURCE_TYPE]
      );
      setUsecaseOutputType(renderJson[TEMPLATE_APP.OUTPUT]);
      setUsecaseAutomationTools(
        renderJson[TEMPLATE_APP.AUTOMATION_TOOLS]
      );
      setUsecaseOutputLink(renderJson[TEMPLATE_APP.OUTPUT]);

      setDeveloperTeam(renderJson[TEMPLATE_APP.DEVELOPER_TEAM.team]);
      setProcessOwnerAlias(renderJson[TEMPLATE_APP.PROCESS_OWNER]);
      setAccountingOrg(renderJson[TEMPLATE_APP.ORGANIZATION]);
      setAccountingTeam(renderJson[TEMPLATE_APP.ACCOUNTING_TEAM.team]);
      setAccountingTeamEmail(renderJson[TEMPLATE_APP.ACCOUNTING_TEAM.email]);
      setAccountingVP(renderJson[TEMPLATE_APP.VP]);
      setShowLoadingIcon(false);
    }
  };



  const checkFavorite = (appId) => {
    checkIsFavorite(appId, userId, function onResult(response) {
      setIsFavorite(response)
    })
  }

  const markFavorite = async (appId) => {
    setFavDisabled(true)
    await favorite(appId, userId, function onAdded(result) {
      setIsFavorite(true)
    }, function onDeleted(result) {
      setIsFavorite(false)
    }, function onFailure(result) {
    })
    setFavDisabled(false)
  }

  const isEditable = (fieldName, subKey = null) => {
    if (subKey) {
      return isNewApp || (isEditMode && fieldMetadata && fieldMetadata[fieldName][subKey] && fieldMetadata[fieldName][subKey]['editable'])
    }
    else {
      return isNewApp || (isEditMode && fieldMetadata && fieldMetadata[fieldName] && fieldMetadata[fieldName]['editable'])
    }

  }

  const getSelectFieldValues = (fieldName, subKey=null) => {
    if (fieldMetadata && fieldMetadata[fieldName] && fieldMetadata[fieldName]['values']) {
      return fieldMetadata[fieldName]['values']
    } else if (subKey && fieldMetadata && fieldMetadata[fieldName][subKey] && fieldMetadata[fieldName][subKey]['values']) {
      return fieldMetadata[fieldName][subKey]['values']
    }else {
      return []
    }
  }

  const getMultiInputFieldValues = (fieldName) => {
    if (renderJsonEdited && renderJsonEdited[fieldName]) {
      return renderJsonEdited[fieldName]
    } else {
      return []
    }
  }

  const updateValue = (fieldName, value, subKey=null) => {
    if(subKey){
      if(!renderJsonEdited.hasOwnProperty(fieldName)){
        renderJsonEdited[fieldName]={}
      }
      renderJsonEdited[fieldName][subKey] = value
      console.log(renderJsonEdited[fieldName])
    }else{
      renderJsonEdited[fieldName] = value
    }
    setRenderJsonEdited(JSON.parse(JSON.stringify(renderJsonEdited)))
  }

  const getValue = (fieldName, subKey = null) => {
    if (subKey) {
      if(renderJsonEdited.hasOwnProperty(fieldName)){
        return renderJsonEdited[fieldName][subKey]
      }else{
        return null
      }
    } else {
      return renderJsonEdited[fieldName]
    }
  }

  const getMultiSelectedOptions = (fieldName) => {
    let arr = renderJsonEdited[fieldName]
    let selectedOptions = []
    if (arr) {
      arr.forEach((item) => {
        selectedOptions.push({ label: item, value: item })
      })
    }
    return selectedOptions
  }

  const cancelEdition = () => {
    setErrorMessage("")
    setIsEditMode(false)
    setRenderJsonEdited(renderJson)
  }

  const editApplication = () => {
    setIsEditMode(true)
    setRenderJsonEdited(renderJson)
  }

  const getMissingFields = (data, fields) => {
    let missingFields = []
    fields.forEach((field) => {
      if (!data.hasOwnProperty(field) || !data[field]) {
        missingFields.push(field)
      }
    })
    return missingFields
  }

  const addApplication = () => {
    setErrorMessage("")
    let missingFields = getMissingFields(renderJsonEdited, [TEMPLATE_APP.APP_DESCRIPTION, TEMPLATE_APP.APP_NAME])
    if (missingFields && missingFields.length > 0) {
      setErrorMessage('missing mandatory fields : ' + missingFields.join(","))
    } else {
      setShowLoadingIcon(true)
      setRenderJson(renderJsonEdited)
      addApp(renderJsonEdited, function onSuccess(response) {
        setIsNewApp(false)
        setRenderJson(JSON.parse(JSON.stringify(response.data.addAppV2)))
        setShowLoadingIcon(false)
        history.push("/App/" + response.data.addAppV2.app_id)
        setIsFavorite(false)
        setId(response.data.addAppV2.app_id);
        cancelEdition()
      }, function onFailure(error) {
        setErrorMessage(error?.errors[0]?.message)
        setShowLoadingIcon(false)
      })
    }
  }

  const deleteApplication = () => {
    setShowLoadingIcon(true)
    setErrorMessage("")
    deleteApp(id, function onSuccess(response) {
      setShowLoadingIcon(false)
      history.push("/ATeamBIAllProducts")
    }, function onFailure(error) {
      setShowLoadingIcon(false)
    })
  }

  const updateApplication = () => {
    setErrorMessage("")
    let missingFields = getMissingFields(renderJsonEdited, [TEMPLATE_APP.APP_ID, TEMPLATE_APP.APP_DESCRIPTION, TEMPLATE_APP.APP_NAME])
    if (missingFields && missingFields.length > 0) {
      setErrorMessage('missing mandatory fields : ' + missingFields.join(","))
    } else {
      setShowLoadingIcon(true)
      updateApp(renderJsonEdited, function onSuccess(response) {
        setRenderJson(JSON.parse(JSON.stringify(response.data.updateAppV2)))
        setShowLoadingIcon(false)
        cancelEdition()
        history.push("/App/" + response.data.updateAppV2.app_id)
        setId(response.data.updateAppV2.app_id);
      }, function onFailure(error) {
        setErrorMessage(error?.errors[0]?.message)
        setShowLoadingIcon(false)
      })
    }
  }

  const dismissDeleteDialog = () => {
    setDeleteAppName('')
    setDeleteConfirmationModal(false)
  }
  const Content = (
    <>
      {showLoadingIcon ? (
        <Modal visible={showLoadingIcon}>
          <Box>
            <Spinner></Spinner>
            Loading...
          </Box>
        </Modal>
      ) : (
        <ContentLayout
          headerVariant="high-contrast"
          header={
            <SpaceBetween size="l">
              <Header
                variant="h1"
                info={
                  isNewApp ?
                    '' :
                    canEditApplication(userId, '') ?
                      (isEditMode) ?
                        <Link onFollow={() => { cancelEdition() }}>cancel</Link> :
                        <span>
                          <Button iconName="edit" variant="icon" onClick={editApplication} />
                          {canDeleteApplication(userId, '') ? <Button iconName="remove" variant="icon" onClick={() => { setDeleteConfirmationModal(true) }} /> : ''}
                          <Modal
                            onDismiss={dismissDeleteDialog}
                            visible={deleteConfirmationModal}
                            footer={
                              <Box float="right">
                                <SpaceBetween direction="horizontal" size="xs">
                                  <Button variant="link" onClick={dismissDeleteDialog} >Cancel</Button>
                                  <Button disabled={deleteAppName !== renderJson[TEMPLATE_APP.APP_NAME]} variant="primary" onClick={deleteApplication}  >Confirm</Button>
                                </SpaceBetween>
                              </Box>
                            }
                            header={
                              <React.Fragment>
                                <Box variant="h2">
                                  Delete confirmation for '{renderJson[TEMPLATE_APP.APP_NAME]}'
                                </Box>
                              </React.Fragment>
                            }
                          >
                            <Box>To confirm deletion, enter the name of the app in the text input field below</Box>
                            <Box>
                              <Input placeholder={renderJson[TEMPLATE_APP.APP_NAME]} onChange={({ detail }) => setDeleteAppName(detail.value)} value={deleteAppName} />
                            </Box>
                          </Modal>
                        </span>
                      :
                      ''
                }
              >
                {
                  isEditable(TEMPLATE_APP.APP_NAME) ?
                    <span><CustomInput field={TEMPLATE_APP.APP_NAME} placeholder="App name" updateValue={updateValue} getValue={getValue}></CustomInput></span> :
                    <span>{usecaseName}
                      <span></span>
                      <img className="favorite_icon" src={isFavorite ? favLogoSolid : favLogo} onClick={() => markFavorite(id)} ></img></span>

                }

              </Header>
              {isEditable(TEMPLATE_APP.APP_DESCRIPTION)
                ?
                <Textarea
                  class="description"
                  onChange={({ detail }) => updateValue(TEMPLATE_APP.APP_DESCRIPTION, detail.value)}
                  value={getValue(TEMPLATE_APP.APP_DESCRIPTION)}
                  placeholder="Description"
                />
                : <Box class="description" variant="p">{usecaseDescription}</Box>
              }

              {
                isEditable(TEMPLATE_APP.STATUS)
                  ?
                  <CustomSelect field={TEMPLATE_APP.STATUS} getValue={getValue} updateValue={updateValue} getOptions={getSelectFieldValues} />
                  :
                  <span className="custom-home__category">
                    {usecaseStatus} &nbsp;
                    {usecaseStatus === "Active" ? (
                      <Icon
                        variant="success"
                        name="status-positive"
                        size="small"
                      ></Icon>
                    ) : usecaseStatus === "Deprecated" ? (
                      <Icon
                        variant="error"
                        name="status-negative"
                        size="small"
                      ></Icon>
                    ) : (
                      <Icon
                        variant="normal"
                        name="status-in-progress"
                        size="small"
                      ></Icon>
                    )}
                  </span>
              }
              {
                isEditable(TEMPLATE_APP.FLASH_INFO_DESCRIPTION) ?
                  <div className="comments">
                    <CustomInput field={TEMPLATE_APP.FLASH_INFO_DESCRIPTION} placeholder="Comments" updateValue={updateValue} getValue={getValue}></CustomInput>
                  </div>
                  :
                  <Box> {usecaseComments ?
                    <Flashbar items={
                      flashBarComments
                    } /> : <Box></Box>
                  }
                  </Box>
              }
            </SpaceBetween>
          }
        >
          <SpaceBetween size="l">
            <Container
            >
              <SpaceBetween>
                <div className="header_menu">
                  <div className="header_left">
                    <Box variant="h1">Application Details</Box>
                    <Box color="text-body-secondary">Other Application Details of {usecaseName}</Box>
                  </div>
                </div>
                <br></br>
              </SpaceBetween>
              <SpaceBetween size="l">
                <SpaceBetween direction="horizontal" size="l">
                  <ColumnLayout columns={4}>
                    <div>
                      <ExpandableSection
                        variant="navigation"
                        defaultExpanded={true}
                        headerText="Ledger Impact"
                      >
                        {
                          isEditable(TEMPLATE_APP.LEDGER_INFO) ?
                            <CustomMultiSelect field={TEMPLATE_APP.LEDGER_INFO}
                              updateValue={updateValue} getValues={getSelectFieldValues} getSelectedOptions={getMultiSelectedOptions} />
                            :
                            <ul>
                              {usecaseLedgerImpact?.map((item) => (
                                <li key={item} className="list-item-data">
                                  {item}
                                </li>
                              ))}
                            </ul>

                        }
                      </ExpandableSection>
                    </div>

                    <div>
                      <Box variant="h4">Launch MCM</Box>
                      {
                        isEditable(TEMPLATE_APP.LAUNCH_MCM) ?
                          <CustomInput field={TEMPLATE_APP.LAUNCH_MCM} placeholder="Launch MCM" updateValue={updateValue} getValue={getValue}></CustomInput>
                          : <Box variant="p">{getValue(TEMPLATE_APP.LAUNCH_MCM)}</Box>
                      }
                    </div>

                    <div>
                      <Box variant="h4">Launch QQ_YY</Box>
                      {
                        isEditable(TEMPLATE_APP.LAUNCH_QQ_YY) ?
                          <CustomInput field={TEMPLATE_APP.LAUNCH_QQ_YY} placeholder="Launch QQ_YY" updateValue={updateValue} getValue={getValue}></CustomInput>
                          : <Box variant="p">{getValue(TEMPLATE_APP.LAUNCH_QQ_YY)}</Box>
                      }
                    </div>

                    <div>
                      <ExpandableSection
                        variant="navigation"
                        defaultExpanded={true}
                        headerText="Run Frequency"
                      >
                        {
                          isEditable(TEMPLATE_APP.CADENCE) ?
                            <CustomMultiSelect field={TEMPLATE_APP.CADENCE}
                              updateValue={updateValue} getValues={getSelectFieldValues} getSelectedOptions={getMultiSelectedOptions} />
                            :
                            <ul>
                              {usecaseRunFrequency?.map((item) => (
                                <li key={item} className="list-item-data">
                                  {item}
                                </li>
                              ))}
                            </ul>

                        }
                      </ExpandableSection>
                    </div>

                    <div>
                      <Box variant="h4">Annual MER Savings</Box>
                      {
                        isEditable(TEMPLATE_APP.ANNUAL_MER_SAVINGS) ?
                          <CustomInput field={TEMPLATE_APP.ANNUAL_MER_SAVINGS} placeholder="Annual MER savings" updateValue={updateValue} getValue={getValue}></CustomInput>

                          : <Box variant="p">{getValue(TEMPLATE_APP.ANNUAL_MER_SAVINGS)}</Box>
                      }
                    </div>
                    <div>
                      <Box variant="h4">MJE Count</Box>
                      {
                        isEditable(TEMPLATE_APP.PROCESS_MJE_COUNT) ?
                          <CustomInput field={TEMPLATE_APP.PROCESS_MJE_COUNT} placeholder="MJE count" updateValue={updateValue} getValue={getValue}></CustomInput>
                          :
                          <Box variant="p">{getValue(TEMPLATE_APP.PROCESS_MJE_COUNT)}</Box>
                      }
                    </div>

                    <div>
                      <ExpandableSection
                        variant="navigation"
                        defaultExpanded={true}
                        headerText="Use Case Regions"
                      >
                        {
                          isEditable(TEMPLATE_APP.REGION) ?
                            <CustomMultiSelect field={TEMPLATE_APP.REGION}
                              updateValue={updateValue} getValues={getSelectFieldValues} getSelectedOptions={getMultiSelectedOptions} />
                            :
                            <ul>
                              {usecaseRegions?.map((item) => (
                                <li key={item} className="list-item-data">
                                  {item}
                                </li>
                              ))}
                            </ul>

                        }

                      </ExpandableSection>
                    </div>
                    <div>
                      <ExpandableSection
                        variant="navigation"
                        defaultExpanded={true}
                        headerText="Use Case Type"
                      >
                        {
                          isEditable(TEMPLATE_APP.USE_CASE) ?
                            <CustomMultiSelect field={TEMPLATE_APP.USE_CASE} updateValue={updateValue} getValues={getSelectFieldValues} getSelectedOptions={getMultiSelectedOptions} />
                            :
                            <ul>
                              {usecaseType?.map((item) => (
                                <li key={item} className="list-item-data">
                                  {item}
                                </li>
                              ))}
                            </ul>

                        }
                      </ExpandableSection>
                    </div>
                  </ColumnLayout>
                </SpaceBetween>
              </SpaceBetween>
            </Container>

            <Grid gridDefinition={[{ colspan: 7 }, { colspan: 5 }]}>
              <Container
                header={
                  <Header
                    variant="h2"

                    description={`Operational Details of ${usecaseName}`}
                  >
                    Operational Details
                  </Header>
                }
              >
                <SpaceBetween size="l">
                  <SpaceBetween direction="horizontal" size="l">
                    <ColumnLayout columns={2}>
                      <div>
                        <ExpandableSection
                          variant="navigation"
                          defaultExpanded={true}
                          headerText="Data Source Details"
                        >
                          {
                            isEditable(TEMPLATE_APP.DATASOURCE) ?
                              <CustomMultiSelect field={TEMPLATE_APP.DATASOURCE}
                                updateValue={updateValue} getValues={getSelectFieldValues} getSelectedOptions={getMultiSelectedOptions} />
                              :
                              <ul>
                                {usecaseDataSourceDetails?.map((item) => (
                                  <li key={item} className="list-item-data">
                                    {item}
                                  </li>
                                ))}
                              </ul>

                          }
                        </ExpandableSection>
                      </div>

                      <div>
                        <ExpandableSection
                          variant="navigation"
                          defaultExpanded={true}
                          headerText="Output"
                        >
                          {
                            isEditable(TEMPLATE_APP.OUTPUT) ?
                              <CustomMultiHybridInputsForAppOutputs field={TEMPLATE_APP.OUTPUT} subKeyForSelection={TEMPLATE_APP.OUTPUT_SUB.TYPE}
                              updateValue={updateValue} getOptions={getSelectFieldValues} getValues={getValue} />
                              :
                              <ul>
                                {usecaseOutputType?.map((item) => (
                                  <li key={item} className="list-item-data">
                                    <Box variant="p">
                                      <Link href={item.value} external>{item.label ? item.label : item.type}</Link>
                                    </Box>

                                  </li>
                                ))}
                              </ul>

                          }
                        </ExpandableSection>
                      </div>



                      <div>
                        <ExpandableSection
                          variant="navigation"
                          defaultExpanded={true}
                          headerText="Input Source Types"
                        >
                          {
                            isEditable(TEMPLATE_APP.INPUT_SOURCE_TYPE) ?
                              <CustomMultiSelect field={TEMPLATE_APP.INPUT_SOURCE_TYPE}
                                updateValue={updateValue} getValues={getSelectFieldValues} getSelectedOptions={getMultiSelectedOptions} />
                              :
                              <ul>
                                {usecaseInputSourceTypes?.map((item) => (
                                  <li key={item} className="list-item-data">
                                    {item}
                                  </li>
                                ))}
                              </ul>

                          }
                        </ExpandableSection>
                      </div>
                      <div>
                        <ExpandableSection
                          variant="navigation"
                          defaultExpanded={true}
                          headerText="Automation Tools"
                        >
                          {
                            isEditable(TEMPLATE_APP.AUTOMATION_TOOLS) ?
                              <CustomMultiSelect field={TEMPLATE_APP.AUTOMATION_TOOLS}
                                updateValue={updateValue} getValues={getSelectFieldValues} getSelectedOptions={getMultiSelectedOptions} />
                              :
                              <ul>
                                {usecaseAutomationTools?.map((item) => (
                                  <li key={item} className="list-item-data">
                                    {item}
                                  </li>
                                ))}
                              </ul>

                          }
                        </ExpandableSection>
                      </div>
                      <div>
                        <Box variant="h4">ARD</Box>
                        {
                          isEditable(TEMPLATE_APP.ARD) ?
                            <CustomInput field={TEMPLATE_APP.ARD} placeholder="ARD" updateValue={updateValue} getValue={getValue}></CustomInput>
                            : <Box>{getValue(TEMPLATE_APP.ARD) ?
                              <Box variant="p">
                                <Link href={getValue(TEMPLATE_APP.ARD)} external>ARD</Link>
                              </Box> :
                              <Box>
                                Not Available
                              </Box>
                            }</Box>
                        }
                      </div>

                      <div>
                        <Box variant="h4">PDD</Box>
                        {
                          isEditable(TEMPLATE_APP.PDD) ?
                            <CustomInput field={TEMPLATE_APP.PDD} placeholder="PDD" updateValue={updateValue} getValue={getValue}></CustomInput>
                            : <Box>{getValue(TEMPLATE_APP.PDD) ?
                              <Box variant="p">
                                <Link href={getValue(TEMPLATE_APP.PDD)} external>PDD</Link>
                              </Box> :
                              <Box>
                                Not Available
                              </Box>
                            }</Box>
                        }
                      </div>

                      <div>
                        <Box variant="h4">TDD</Box>
                        {
                          isEditable(TEMPLATE_APP.TDD) ?
                            <CustomInput field={TEMPLATE_APP.TDD} placeholder="TDD" updateValue={updateValue} getValue={getValue}></CustomInput>
                            : <Box>{getValue(TEMPLATE_APP.TDD) ?
                              <Box variant="p">
                                <Link href={getValue(TEMPLATE_APP.TDD)} external>TDD</Link>
                              </Box> :
                              <Box>
                                Not Available
                              </Box>
                            }</Box>
                        }
                      </div>
                    </ColumnLayout>
                  </SpaceBetween>
                </SpaceBetween>
              </Container>
              <Container
                header={
                  <Header
                    variant="h2"
                    description={`Team Details of ${usecaseName}`}
                  >
                    Team Details
                  </Header>
                }
              >
                <SpaceBetween size="l">
                  <SpaceBetween direction="horizontal" size="l">
                    <ColumnLayout columns={2}>
                      <div>
                        <Box variant="h4">Developer Team</Box>
                        {
                          isEditable(TEMPLATE_APP.DEVELOPER_TEAM) ?
                            <CustomSelect field={TEMPLATE_APP.DEVELOPER_TEAM} subKey={TEMPLATE_APP.TEAM_SUB.TEAM} getValue={getValue} updateValue={updateValue} getOptions={getSelectFieldValues} />
                            :
                            <Box variant="p">{getValue(TEMPLATE_APP.DEVELOPER_TEAM, TEMPLATE_APP.TEAM_SUB.TEAM)}</Box>
                        }
                      </div>

                      <div>
                        <ExpandableSection
                          variant="navigation"
                          defaultExpanded={true}
                          headerText="Process Owner (s)"
                        >
                          {
                            isEditable(TEMPLATE_APP.PROCESS_OWNER) ?
                              <CustomMultiInput field={TEMPLATE_APP.PROCESS_OWNER} updateValue={updateValue} getValues={getMultiInputFieldValues}></CustomMultiInput>
                              :
                              <ul>
                                {getMultiInputFieldValues(TEMPLATE_APP.PROCESS_OWNER)?.map((item) => (
                                  <li key={item.trim()} className="list-item-data">
                                    <Link href={`https://phonetool.amazon.com/users/${item.trim()}`} target="_blank">
                                      {item.trim()}
                                    </Link>
                                  </li>
                                ))}
                              </ul>
                          }

                        </ExpandableSection>
                      </div>
 
                      <div>
                        <ExpandableSection
                          variant="navigation"
                          defaultExpanded={true}
                          headerText="Organization (s)"
                        >
                          {
                            isEditable(TEMPLATE_APP.ORGANIZATION) ?
                              <CustomMultiSelect field={TEMPLATE_APP.ORGANIZATION} updateValue={updateValue} getValues={getSelectFieldValues} getSelectedOptions={getMultiSelectedOptions} />
                              :
                              <ul>
                                {accountingOrg?.map((item) => (
                                  <li key={item} className="list-item-data">
                                    {item}
                                  </li>
                                ))}
                              </ul>

                          }
                        </ExpandableSection>

                      </div>

                      <div>
                        <Box variant="h4">Accounting Team</Box>
                        {
                          isEditable(TEMPLATE_APP.ACCOUNTING_TEAM) ?
                            <CustomSelect field={TEMPLATE_APP.ACCOUNTING_TEAM} subKey={TEMPLATE_APP.TEAM_SUB.TEAM} getValue={getValue} updateValue={updateValue} getOptions={getSelectFieldValues} />
                            :
                            <Box variant="p">{getValue(TEMPLATE_APP.ACCOUNTING_TEAM, TEMPLATE_APP.TEAM_SUB.TEAM)}</Box>
                        }
                      </div>

                      <div>
                        <Box variant="h4">Team Email</Box>
                        {
                          isEditable(TEMPLATE_APP.ACCOUNTING_TEAM) ?
                            <CustomInput field={TEMPLATE_APP.ACCOUNTING_TEAM} subKey={TEMPLATE_APP.TEAM_SUB.EMAIL} placeholder="email" updateValue={updateValue} getValue={getValue}></CustomInput>
                            :
                            <Box variant="p">{getValue(TEMPLATE_APP.ACCOUNTING_TEAM, TEMPLATE_APP.TEAM_SUB.EMAIL)}</Box>
                        }
                      </div>

                     <div>
                        <ExpandableSection
                          variant="navigation"
                          defaultExpanded={true}
                          headerText="Accounting VP"
                        >
                          {
                            isEditable(TEMPLATE_APP.VP) ?
                              <CustomMultiInput field={TEMPLATE_APP.VP} updateValue={updateValue} getValues={getMultiInputFieldValues}></CustomMultiInput>
                              :
                              <ul>
                                {getMultiInputFieldValues(TEMPLATE_APP.VP)?.map((item) => (
                                  <li key={item} className="list-item-data">
                                    <Link href={`https://phonetool.amazon.com/users/${item.trim()}`} target="_blank"> {item.trim()} </Link>
                                  </li>
                                ))}
                              </ul>
                          }
                        </ExpandableSection>
                      </div>
                    </ColumnLayout>
                  </SpaceBetween>
                </SpaceBetween>
              </Container>
            </Grid>
            <Box>
              {errorMessage && (
                <Box variant="p" color="text-status-error">
                  {errorMessage}
                </Box>
              )}
            </Box>
            {
              isNewApp ?
                <div className="buttons">
                  <SpaceBetween direction="horizontal" size="l">
                    <Button variant="primary" onClick={addApplication}>Save</Button>
                  </SpaceBetween>
                </div>
                :
                ''
            }
            {
              !isNewApp && isEditMode ?
                <div className="buttons">
                  <SpaceBetween direction="horizontal" size="l">
                    <Button variant="primary" onClick={updateApplication}>Update</Button>
                    <Button onClick={cancelEdition}>Cancel</Button>
                  </SpaceBetween>
                </div>
                :
                ''
            }
          </SpaceBetween>
        </ContentLayout>
      )}
    </>
  );

  const helpContent = (
    <HelpPanel header={<Box variant="h2">Glossary</Box>}>
      <Box>
        <ul>
          {glossary.map((item) => (
            <li key={item.label} className="list-item-data">
              <ExpandableSection
                variant="navigation"
                headerText={item.label}
              >
                {item.description}
              </ExpandableSection>
            </li>
          ))}
        </ul>
      </Box>
    </HelpPanel>
  );

  return (
    <AppLayout
      content={Content}
      navigation={<SideNav activeHref={`/AppTemplate/${id}`} id={id} applicationName={usecaseName} customSideNavitems={customSideNavitems} />}
      contentType="default"
      navigationOpen={NavOpen}
      onNavigationChange={() => {
        setNavOpen(!NavOpen);
      }}
      tools={helpContent}
      toolsOpen={toolsOpen}
      onToolsChange={() => { setToolsOpen(!toolsOpen) }}
      headerVariant="high-contrast"
    />
  );
};
