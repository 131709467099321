import React, { useState, useEffect, useRef } from 'react'
import { useHistory, useParams } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux'

import {
    AppLayout, HelpPanel, Icon, BreadcrumbGroup, Box, Table, SpaceBetween, Header, Button,
    Container, Spinner, Pagination, Alert,  Modal, Link
} from '@amzn/awsui-components-react';
import SideNav from './SideNav';
import AccessDenied from '../../AccessDenied'
import WaitForPageAccess from '../../WaitForPageAccess'
import './history.scss'
import checkPageAccess, { checkIfPortalAdmin } from "../../checkPageAccess";
import { API, graphqlOperation } from 'aws-amplify';
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { useAuth } from "../../context/AuthContextProvider";
import { Storage } from 'aws-amplify';
import { setCurrentAppId } from '../../store/userAuthSlice';
import { getProcessById , queryFRISCOTransactionsByAppIdProcessIdIndex, getFriscoSTLLoadError} from "src/Ateam-BI/Templates/Utils/AppActivityUtils"
import moment from 'moment';
import { PROCESS_KEYS } from './Constants';
import { configureFriscoS3, configureGenericS3 } from 'src/context/AuthContextUtility'
import { isApprover, isUploaderAllowed } from './AccessUtilities';
import { isCorpAdmin } from '../Templates/Utils/Permissions';
import appSettings from '../CorpReporting/app_settings';


const FriscoHistory = (props) => {

    const userAuthenticationDetails = useAuth();
    const gridRef = useRef();
    const history = useHistory();
    const dispatch = useDispatch()
    const userLDAPS = userAuthenticationDetails.USER_LDAP_GROUPS;
    const [isCheckingAccess, setIsCheckingAccess] = useState(true);
    const [isAuthorised, setIsAuthorised] = useState(false);
    const [items, setItems] = useState([]);
    const [showLoadingIcon, setShowLoadingIcon] = useState(true);
    const [totalPageCount, setTotalPageCount] = useState(1);
    const [lastEvaluatedKey, setLastEvaluatedKey] = useState(null)
    const [openEndPagination, setOpenEndPagination] = useState(true)
    const [processData, setProcessData] = useState({})
    const [transactions, setTransactions] = useState([])
    const [loadErrorData, setLoadErrorData] = useState([])
    const currentStage = useSelector((globalState) => globalState.auth.currentStage)
    const userId = useSelector((globalState) => globalState.auth.userId);
    const [visible, setVisible] = useState(false)
    const [modalLoading, setModalLoading] = useState(false)
    // const isCurrentAppAuthorised = useSelector((globalState) => globalState.auth.isCurrentAppAuthorized)
    const currentAppId = useSelector(
        (globalState) => globalState.auth.currentAppId
      );
    let { id } = useParams();
    let { pid } = useParams();
    const [backUrl, setBackUrl] = useState(null)
    const limit = 20
    const query_page_batch_size = 100

    const [
        currentPageIndex,
        setCurrentPageIndex
    ] = React.useState(1);

    useEffect(() => {
        configureFriscoS3()
        loadData()
        setBackUrl(getParam('back'))
        if(id){
            dispatch(setCurrentAppId(id))
        }
        return () => {
            configureGenericS3()
        };
    }, []);

    const getS3FolderPath = (item) => {
        let status = item.processingStatus.toLowerCase()
        switch (status) {
            case 'completed': return 'completed';
            case 'pending':
            case 'processing': return 'pending';
            default: return status
        }
    }

    const processIcon = props => (
        props.data.status === 'Completed' ?
            <Box>
                <Icon name='status-positive' variant='success' ></Icon>
            </Box>

            : <Box>
                <Icon name='status-in-progress' variant='normal' ></Icon>
            </Box>


    )

    const downloadRawFile= async (item) => {
        configureFriscoS3()
        let fn = `${item.rawFilePath}`
        fn = fn.replace('frisco/','')
        let prefix = 'frisco/'
        const result = await Storage.get(fn, { level: 'public', customPrefix:{public:prefix}, expires: 60 });
        window.open(result);
    }
    const downloadFile = async (item) => {
        configureFriscoS3()
        let fn = `${item.filePath}`
        fn = fn.replace('frisco/','')
        let prefix = 'frisco/'
        const result = await Storage.get(fn, { level: 'public', customPrefix:{public:prefix}, expires: 60 });
        window.open(result);
    }

    const downloadIcon = props => (
        <SpaceBetween size='m' direction='horizontal'>
        <Button
            variant='inline-icon'
            iconName='download'
            onClick={() => {
                downloadFile(props.data)
            }
            }></Button>

            <>
            {
                props.data.rawFilePath ?
                 <Button
            variant='inline-link'
            onClick={() => {
                downloadRawFile(props.data)
            }
            }> [Raw file] </Button>
            : ''
            }</>
        </SpaceBetween>
        
    )

    const epochToTimestamp = props => (
        <Box>{ moment(new Date(Number(props.data.uploadedTimestamp))).format()   }</Box>
    )
    const epochToTimestampLastUpdatedTime = props => (
        <Box>{ moment(new Date(Number(props.data.lastUpdatedTimestamp))).format()   }</Box>
    )
    const epochToTimestampJobModificationTime = props => (
        <Box>{ moment(new Date(Number(props.data.jobStatusModificationTimestamp))).format()   }</Box>
    )

    const epochToTimestampApprovalRequestedTime = props => (
        props.data?.approvalRequestedTime?
        <Box>{ moment(new Date(Number(props.data?.approvalRequestedTime))).format()   }</Box>:
        <Box></Box>
    )

    const epochToTimestampApprovalModificationTime = props => (
        props.data?.approvalModificationTime?
        <Box>{ moment(new Date(Number(props.data?.approvalModificationTime))).format()   }</Box>:
        <Box></Box>
    )
    

    const processImg = (props) => (
        <Box float="left">
          <img
            alt=""
            className="rounded-circle"
            src={
              "https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=" +
              props.data.uploadedBy
            }
            width={20}
            height={20}
          />
          &nbsp;
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={"https://phonetool.amazon.com/users/" + props.data.uploadedBy}
          >
            {props.data.uploadedBy}
          </a>
        </Box>
      );

    const  processImgApprovedBy = (props) => (
        props.data.approvedBy ? <Box float="left">
          <img
            alt=""
            className="rounded-circle"
            src={
              "https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=" +
              props.data.approvedBy
            }
            width={20}
            height={20}
          />
          &nbsp;
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={"https://phonetool.amazon.com/users/" + props.data.approvedBy}
          >
            {props.data.approvedBy}
          </a>
        </Box> : <Box></Box>
      );

    const stlErrorsContent = (props) => {
        return (
        <Button onClick={() => {getFriscoSTLErrorOfFilePath(props.data.filePath)}}>Check STL Load Errors Table</Button>
        )
    }

    const approvalStatusButton = (props) => {
        if (props?.data.approvalStatus == 'pending') {
            return  <Link external href={`/ApprovalsHome/Workflow/${props.data.approvalId}`}> <span className='orange_badge' >{props.data.approvalStatus}</span> </Link>
          } else if (props.data.approvalStatus == 'completed') {
            return <Link external href={`/ApprovalsHome/Workflow/${props.data.approvalId}`}> <span className='green_badge' >{props.data.approvalStatus}</span> </Link>
          } else if (props.data.approvalStatus == 'rejected') {
            return <Link external href={`/ApprovalsHome/Workflow/${props.data.approvalId}`}> <span className='red_badge' >{props.data.approvalStatus}</span> </Link>
          } else if (props.data.approvalStatus == 'cancelled') {
            return <Link external href={`/ApprovalsHome/Workflow/${props.data.approvalId}`}> <span className='grey_badge' >{props.data.approvalStatus}</span> </Link>
          } else if (props.data.approvalStatus == 'NO APPROVAL') {
            return  <span className='blue_badge' >{props.data.approvalStatus}</span> 
          } else {
            if(props.data.approvalId){
                return <Link external href={`/ApprovalsHome/Workflow/${props.data.approvalId}`}> <span className='blue_badge' >{props.data.approvalStatus}</span> </Link>
            }else{
                return <span className='blue_badge' >{props.data.approvalStatus}</span>
            }
          }
    }


    const [columnDefs] = useState([
        { field: 'fileId', width: '300px',  headerName: 'file id',sortable: true, resizable: true },
        { field: 'uploadedFileName', width: '300px',  headerName: 'Uploaded File Name',sortable: true, resizable: true },
        { field: 'status', width: '200px', headerName: 'Status', sortable: true, resizable: true },
        { field: 'lastUpdatedTimestamp', width: '250px', headerName: 'Last updated', sortable: true,  cellRenderer: epochToTimestampLastUpdatedTime, resizable: true },
        { field: 'uploadedBy', width: '150px', headerName: 'Uploaded by', cellRenderer:processImg, sortable: true, resizable: true },
        { field: 'uploadedTimestamp', width: '250px',  headerName: 'Uploaded time', sortable: true, cellRenderer: epochToTimestamp, resizable: true },
        { field: 'approvalStatus', width: '200px', headerName: 'Approval status', sortable: true, cellRenderer:approvalStatusButton , resizable: true },
        { field: 'approvedBy', width: '150px', headerName: 'Approved by', cellRenderer:processImgApprovedBy, sortable: true, resizable: true },
        { field: 'approvalRequestedTime', width: '250px', headerName: 'Approval requested', cellRenderer: epochToTimestampApprovalRequestedTime,sortable: true, resizable: true },
        { field: 'approvalModificationTime', width: '250px', headerName: 'Approval modification time',cellRenderer: epochToTimestampApprovalModificationTime, sortable: true, resizable: true },
        { field: 'jobStartTime', width: '250px', headerName: 'Job start time', sortable: true, resizable: true },
        { field: 'filePath', width: '300px', headerName: 'Check for errors ?', cellRenderer: stlErrorsContent, sortable: true, resizable: true},
        { field: '', width: '200px', cellRenderer: downloadIcon }
    ])

    const defaultColDef = {
        // set filtering on for all columns
        filter: true,
        filterParams: {
            buttons: ["reset"],
            debounceMs: 200,
        },
    };

    const calculatePageCount = (length) => {
        let rawCount = length / limit
        let roundedCount = Math.trunc(rawCount)
        if (roundedCount < rawCount) {
            roundedCount = roundedCount + 1
        }
        return roundedCount
    }
    const loadPaginatedData = async () => {

        if (transactions?.nextToken) {
            queryFRISCOTransactionsByAppIdProcessIdIndex(id, pid, 100, transactions.nextToken, function onSuccess(response) {
                setTransactions(response.data.queryFRISCOTransactionsByAppIdProcessIdIndex)
                let qResultJSON = response.data.queryFRISCOTransactionsByAppIdProcessIdIndex.Items
                let lastEvKey = response.data.queryFRISCOTransactionsByAppIdProcessIdIndex.nextToken
                qResultJSON = items.concat(qResultJSON)
                setItems(qResultJSON)
                setTotalPageCount(calculatePageCount(qResultJSON.length))
                setShowLoadingIcon(false)
                setTotalPageCount(calculatePageCount(qResultJSON.length))
                if(lastEvKey){
                    setOpenEndPagination(true)
                }else{
                    // end of records
                    setOpenEndPagination(false)
                }
    
            }, function onFailure(response) {
                setShowLoadingIcon(false)
            })
        }

    }

    const handleAccess = async (data) => {
        let resp = false
        if (data[PROCESS_KEYS.created_by] == userId) {
            resp = true
        } else {
            resp = await checkIfPortalAdmin(userId, currentStage)
            if(!resp && id == appSettings[currentStage]['appId']){
                resp = await isCorpAdmin(userId,id,currentStage)
            }
            if (!resp) {
                resp = await isUploaderAllowed(data, userId, currentStage)
            }
            if (!resp) {
                resp = await isApprover(data, userId, currentStage)
            }
        }

        setIsAuthorised(resp)
        setIsCheckingAccess(false)
    }

    const loadData = async () => {
        setShowLoadingIcon(true)
        getProcessById(pid, async function onSuccess(response) {
            setProcessData(response.data.getProcessByIDV2)
            await handleAccess(response.data.getProcessByIDV2)
        }, function onFailure(response) {

        })

        queryFRISCOTransactionsByAppIdProcessIdIndex(id, pid, 1000, null, function onSuccess(response) {
            setTransactions(response.data.queryFRISCOTransactionsByAppIdProcessIdIndex)
            let qResultJSON = response.data.queryFRISCOTransactionsByAppIdProcessIdIndex.items
            let lastEvKey = response.data.queryFRISCOTransactionsByAppIdProcessIdIndex.nextToken
            qResultJSON.sort(function (a, b) {
                var dateA = new Date(Number(a.lastUpdatedTimestamp)), dateB = new Date(Number(b.lastUpdatedTimestamp));
                return dateB - dateA;
            });
            setItems(qResultJSON)
            setTotalPageCount(calculatePageCount(qResultJSON.length))
            setShowLoadingIcon(false)
            setOpenEndPagination(true)
            setCurrentPageIndex(1)
            if(lastEvKey){
                setOpenEndPagination(true)
            }else{
                // end of records
                setOpenEndPagination(false)
            }
        }, function onFailure(response) {
            setShowLoadingIcon(false)
        })
    }

    const onPageChanged = (detail) => {
        setCurrentPageIndex(detail.currentPageIndex)
        if (detail.currentPageIndex == totalPageCount && openEndPagination) {
            loadPaginatedData()
        }
    }

    const getPaginatedItems = () => {
        let start = limit * (currentPageIndex - 1)
        return items.slice(start, start + limit)
    }

    const onFollowHandler = (ev) => {

        ev.preventDefault();
        if (ev.detail.href) {
            history.push(ev.detail.href.substring(1));
        }
    }

    const getParam= (key) => {
        const search = props.location.search;
        const params = new URLSearchParams(search);
        const value = params.get(key);
        return value;
      }

    const getFriscoSTLErrorOfFilePath = async (filePath) => {
        setModalLoading(true)
        setVisible(true)
        getFriscoSTLLoadError(filePath, pid, function onSuccess(response) {
            const r = response.data.getFriscoSTLLoadError;
            const parsedData = r.map((item) => (JSON.parse(item)))
            setLoadErrorData(parsedData)
            setModalLoading(false)
        }, function onFailure(response) {
            setModalLoading(false)
            setVisible(false)

        })
    }  
    const content = (
        <div>

            {
                isAuthorised?
                <Box>
                    <SpaceBetween size='l'>
                    <Container
                        header={
                            <Header
                                variant='h2'
                                actions={
                                    <SpaceBetween direction="horizontal" size="xs">
                                        <Button variant='normal' onClick={() => { history.push(`/App/${id}/process/${pid}/upload?back=${backUrl}`) }}>
                                            Upload file
                                        </Button>
                                    </SpaceBetween>
                                }
                                description=""
                            >Upload file for processing


                            </Header>
                        }
                    >
                            {
                                processData?.hasOwnProperty(PROCESS_KEYS.team) && processData?.hasOwnProperty(PROCESS_KEYS.business)
                                    && processData?.hasOwnProperty(PROCESS_KEYS.dataset_name) ?
                                    <SpaceBetween size='s' direction='horizontal'>
                                        <Box variant="h4"> Load table : </Box>
                                        {
                                            processData[PROCESS_KEYS.redshift_table_name] ? 
                                            <Box>{processData[PROCESS_KEYS.redshift_table_name]}</Box>:
                                            <Box variant="p"> {processData[PROCESS_KEYS.team]}_{processData[PROCESS_KEYS.business]}_{processData[PROCESS_KEYS.dataset_name]}</Box>
                                        }
                                    </SpaceBetween> : <></>
                            }
                    </Container>

                        <Container
                            header={
                                <Header
                                    variant='h2'
                                    actions={
                                        <SpaceBetween direction="horizontal" size="xs">

                                            <Button variant='normal' iconName='refresh' onClick={loadData}></Button>
                                        </SpaceBetween>
                                    }
                                    description="The following table shows all the files uploaded."
                                >File Uploads
                                    {showLoadingIcon ?
                                        <Spinner size='normal'></Spinner> : <div></div>
                                    }

                                </Header>
                            }
                            fitHeight
                        >
                            <div
                                className="ag-theme-alpine"
                                style={{ height: 400, width: "100%" }}
                            >
                                <AgGridReact
                                    ref={gridRef}
                                    rowData={getPaginatedItems()}
                                    defaultColDef={defaultColDef}
                                    columnDefs={columnDefs}
                                    rowSelection='single'
                                    animateRows={true}
                                    rowHeight={40}
                                    enableCellTextSelection={true}
                                >
                                </AgGridReact>
                            </div>
                            <div className='pagination_box'>
                                <div className='paginator'>
                                    {openEndPagination ?
                                        <Pagination
                                            currentPageIndex={currentPageIndex}
                                            onChange={({ detail }) =>
                                                onPageChanged(detail)
                                            }
                                            openEnd
                                            pagesCount={totalPageCount}
                                        /> :
                                        <Pagination
                                            currentPageIndex={currentPageIndex}
                                            onChange={({ detail }) =>
                                                onPageChanged(detail)
                                            }
                                            pagesCount={totalPageCount}
                                        />}
                                </div>
                            </div>
                        </Container>
                    </SpaceBetween>
                    <Modal
                onDismiss={() => {
                    setVisible(false)
                    setLoadErrorData([])
                }}
                visible={visible}
                size="max"
                footer={
                    <Box float="right">
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button variant="primary" onClick={() => {
                                setVisible(false)
                                setLoadErrorData([])
                            }}>
                                Close
                            </Button>
                        </SpaceBetween>
                    </Box>
                }
            >
                <Table
                columnDefinitions={[
                    {
                        id: "starttime",
                        header: "starttime",
                        cell: e => e.starttime,
                        isRowHeader: true
                    },
                    {
                        id: "line_number",
                        header: "line_number",
                        cell: e => e.line_number
                    },
                    { id: "position", header: "position", cell: e => e.position },
                    { id: "colname", header: "colname", cell: e => e.colname },
                    { id: "type", header: "type", cell: e => e.type },
                    { id: "raw_field_value", header: "raw_field_value", cell: e => e.raw_field_value },
                    { id: "err_reason", header: "err_reason", cell: e => e.err_reason },
                    { id: "filename", header: "filename", cell: e => e.filename }
                    
                ]}
                enableKeyboardNavigation
                sortingDescending
                stripedRows
                items={loadErrorData}
                loading={modalLoading}
                loadingText="Checking for Errors"
                trackBy="name"
                empty={
                    <Box
                        margin={{ vertical: "xs" }}
                        textAlign="center"
                        color="inherit"
                    >
                        <SpaceBetween size="m">
                            <b>No errors for the file</b>
                        </SpaceBetween>
                    </Box>
                }
                header={<Header>STL Load Errors table data for the uploaded file (if any)</Header>}
            />

            </Modal>
                    </Box> : isCheckingAccess ? <div>
                        <WaitForPageAccess></WaitForPageAccess>
                    </div> :
                        <Alert
                            statusIconAriaLabel="Error"
                            type="error"
                            header="Access denied"
                        >
                            No access to view
                        </Alert>
            }
        </div>
    )

    return (
        <AppLayout
            content={content}
            navigation={<SideNav activeHref={`/App/${id}/process/${pid}/history`} id={id} pid={pid} process={processData} back={backUrl}/>}
            headerSelector="#TopBar"
            navigationHide={false}
            disableBodyScroll={true}
            toolsHide={true}
            headerVariant="high-contrast"
        ></AppLayout>
    )
}



export default FriscoHistory

