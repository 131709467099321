import React, { useState, useEffect, useMemo } from 'react';
import SideNav from '../Home/SideNav';
import appSettings from '../Home/app_settings';
import { Link } from 'react-router-dom'

import favLogo from '../images/star-regular-brown.svg'
import favLogoSolid from '../images/star-solid-brown.svg'

import {
  AppLayout, Table,
  Container, Box, Cards, Icon, Header,
  PropertyFilter, Badge
} from '@amzn/awsui-components-react';

import '../Home/homepage.scss';
import { useSelector, useDispatch } from 'react-redux'


import { setCurrentAppId } from '../store/userAuthSlice';
import { useHistory } from 'react-router-dom';

import { API, graphqlOperation } from 'aws-amplify';
import { addFavorite, listFavorites, delFavorite, getFavorite } from '../utilities/Favorites'
import { TEMPLATE_APP, TEMPLATE_APP_PERMISSION, TEMPLATE_APP_TEAM, TEMPLATE_APP_OUTPUT, TEMPLATE_APP_SOURCE_DATA_TABLES,TEMPLATE_APP_CHART_OF_ACCOUNTS } from 'src/Ateam-BI/Templates/Constants';

const Favorite = (props) => {
  const history = useHistory()
  const isCurrentAppAuthorised = useSelector((globalState) => globalState.auth.isCurrentAppAuthorized)
  var userId = useSelector((globalState) => globalState.auth.userId);

  const [isCheckingAccess] = useState(true);
  const [, setIsAuthorized] = useState(false);
  const [query, setQuery] = useState({
    tokens: [],
    operation: "and"
  });
  const [products, setProducts] = useState([]);

  const [isProducsLoading, setIsProducsLoading] = useState(true);
  const [toolsHide, setToolsHide] = useState(true);

  const [filteringOptions, setFilteringOptions] = useState([]);
  const [NavOpen, setNavOpen] = useState(false);
  const [toolsOpen, setToolsOpen] = useState(false);
  const [favoritesMap, setFavoritesMap] = useState({});
  const [favoritesLabelMapper, setFavoritesLabelMapper] = useState({});
  const [emptyMessage, setEmptyMessage] = useState('')
  const [favDisabled, setFavDisabled] = useState(false);
  const dispatch = useDispatch()

  if (props.userId) {
    userId = props.userId
  }

  useEffect(() => {
    loadProducts()
  }, [props])

  const filteredProducts = useMemo(() => {
    // console.log(query)
    if (query.tokens.length > 0) {


      if (query.tokens[0].propertyKey == 'app_name') {
        return products.filter(item => {
          return item.app_name.toLowerCase().includes(query.tokens[0].value.toLowerCase())
        })
      }
      if (query.tokens[0].propertyKey == 'process_owner') {
        return products.filter(item => {
          return item.process_owner.toLowerCase().includes(query.tokens[0].value.toLowerCase())
        })
      }
      if (query.tokens[0].propertyKey == 'developed_by') {
        return products.filter(item => {
          return item.developed_by.toLowerCase().includes(query.tokens[0].value.toLowerCase())
        })
      }

    } else {
      return products
    }
  }, [query, products])

  useEffect(() => {
    resetView()
  }, [favoritesMap, favDisabled]);

  useEffect(() => {
    setIsAuthorized(isCurrentAppAuthorised)
    if (isCurrentAppAuthorised) {
      resetView()
    }


    return () => {
      // this now gets called when the component unmounts
    };
  }, [isCheckingAccess, isCurrentAppAuthorised]);

  const changeToggle = () => {
    if(!props.hasOwnProperty('changeToggle')){
      return
    }
    props.changeToggle()
  }

  const resetView = async () => {
    await getAllFavorites()
    loadProducts()
  }
  const clearFavoriteMap = () => {
    let current_keys = Object.keys(favoritesMap)
    current_keys.forEach((key) => {
      delete favoritesMap[key]
    });

    current_keys = Object.keys(setFavoritesLabelMapper)
    current_keys.forEach((key) => {
      delete favoritesLabelMapper[key]
    });
  }
  const makeFavoriteMap = (items) => {
    items.forEach((item, index) => {
      favoritesMap[item.preference_identifier] = true

      if (!favoritesLabelMapper.hasOwnProperty(item.label ? item.label : 'default')) {
        favoritesLabelMapper[item.label ? item.label : 'default'] = new Set()
      }
      favoritesLabelMapper[item.label ? item.label : 'default'].add(item.preference_identifier)

    });
  }

  const getAllFavorites = async () => {
    await listFavorites(userId, function onSuccess(result) {
      clearFavoriteMap()
      makeFavoriteMap(result.data.listUserPreferences.items)
      if(Object.keys(favoritesLabelMapper).length == 0){
        setEmptyMessage('No favorites to display')
      }
    },
      function onFailure(result) {

      })
  }

  const isFavorite = async (appId) => {
    getFavorite(appId, userId, function onSuccess(result) {
      favoritesMap[appId] = true
    },
      function onFailure(result) {
        favoritesMap[appId] = false
      })
  }

  const filterOutNonFavorites = (apps) => {
    let filteredApps = []
    apps.forEach((app) => {
      if (favoritesMap[app.app_id]) {
        filteredApps.push(app)
      }
    })
    return filteredApps
  }
  const loadProducts = async () => {
    let favsArr = []
    Object.keys(favoritesMap).forEach((key) =>{
      if(favoritesMap[key] && parseInt(key) && parseInt(key).toString() == key){
        favsArr.push(`"${key}"`)
      }
    })
    let qResultJSON = []
    if (favsArr && favsArr.length > 0) {
      let mQry = `
      query MyQuery {
        getAppsV2(ids: [${favsArr.join(",")}]) {
          ${TEMPLATE_APP.APP_DESCRIPTION}
            ${TEMPLATE_APP.APP_NAME}
            ${TEMPLATE_APP.APP_ID}
            ${TEMPLATE_APP.TEMPLATE_ID}
            ${TEMPLATE_APP.URL}
            ${TEMPLATE_APP.PROCESS_OWNER}
            ${TEMPLATE_APP.FLASH_INFO_DESCRIPTION}
            ${TEMPLATE_APP.DEV_ALERT_MESSAGES}
            ${TEMPLATE_APP.TDD}
            ${TEMPLATE_APP.STATUS}
            ${TEMPLATE_APP.REGION}
            ${TEMPLATE_APP.CADENCE}
            ${TEMPLATE_APP.PROCESS_MJE_COUNT}
            ${TEMPLATE_APP.ANNUAL_MER_SAVINGS}
            ${TEMPLATE_APP.PDD}
            ${TEMPLATE_APP.LEDGER_INFO}
            ${TEMPLATE_APP.LAUNCH_MCM}
            ${TEMPLATE_APP.LAUNCH_QQ_YY}
            ${TEMPLATE_APP.INPUT_SOURCE_TYPE}
            ${TEMPLATE_APP.AUTOMATION_TOOLS}
            ${TEMPLATE_APP.ARD}
            ${TEMPLATE_APP.DATASOURCE}
            ${TEMPLATE_APP.ORGANIZATION}
            ${TEMPLATE_APP.VP}
            ${TEMPLATE_APP.CREATE_TIME_STAMP}
            ${TEMPLATE_APP.CREATED_BY}
            ${TEMPLATE_APP.LAST_UPDATED_TIME_STAMP}
            ${TEMPLATE_APP.LAST_UPDATED_BY}
            ${TEMPLATE_APP.SIM_TICKET_URL}
            ${TEMPLATE_APP.SIM_TICKET_LABEL}
            ${TEMPLATE_APP.CHART_OF_ACCOUNT}{
              ${TEMPLATE_APP_CHART_OF_ACCOUNTS.CHART_OF_ACCOUNTS_PK}
              ${TEMPLATE_APP_CHART_OF_ACCOUNTS.FPA_DIM_ACCOUNTS}
            }
            ${TEMPLATE_APP.SOURCE_DATA_TABLES}{
              ${TEMPLATE_APP_SOURCE_DATA_TABLES.DESCRIPTION}
              ${TEMPLATE_APP_SOURCE_DATA_TABLES.SCHEMA}
              ${TEMPLATE_APP_SOURCE_DATA_TABLES.SOURCE_TABLE_PK}
              ${TEMPLATE_APP_SOURCE_DATA_TABLES.TABLE}
              ${TEMPLATE_APP_SOURCE_DATA_TABLES.DATABASE}
              ${TEMPLATE_APP_SOURCE_DATA_TABLES.UPSTREAM_SOURCE}
            }
            ${TEMPLATE_APP.USE_CASE}
            ${TEMPLATE_APP.PERMISSION}{
              ${TEMPLATE_APP_PERMISSION.LINK}
              ${TEMPLATE_APP_PERMISSION.NAME}
              ${TEMPLATE_APP_PERMISSION.APP_PERMISSION_PK}
            }
            ${TEMPLATE_APP.DEVELOPER_TEAM}{
              ${TEMPLATE_APP_TEAM.TEAM}
              ${TEMPLATE_APP_TEAM.EMAIL}
            }
            ${TEMPLATE_APP.OUTPUT}{
              ${TEMPLATE_APP_OUTPUT.TYPE}
              ${TEMPLATE_APP_OUTPUT.VALUE}
              ${TEMPLATE_APP_OUTPUT.LABEL}
              ${TEMPLATE_APP_OUTPUT.APP_OUTPUT_PK}
            }
            ${TEMPLATE_APP.ACCOUNTING_TEAM} {
              ${TEMPLATE_APP_TEAM.TEAM}
              ${TEMPLATE_APP_TEAM.EMAIL}
            }
        }
      }  
          `;
      // console.log(mQry)

      let response = await API.graphql(graphqlOperation(mQry));
      qResultJSON = response.data.getAppsV2
    } else if(props.allApps) {
      qResultJSON = props.allApps
    }else{
      qResultJSON = []
    }
    // console.log(qResultJSON)
    let favs = filterOutNonFavorites(qResultJSON)
    var data = favs.map((data) => data.app_name)
    var mTmp = [...new Set(data)]
    var mAN = []
    mTmp.map((i) => mAN.push({ 'propertyKey': 'app_name', 'value': i }))


    data = favs.map((data) => data.process_owner)
    mTmp = [...new Set(data)]
    var mPN = []
    mTmp.map((i) => mPN.push({ 'propertyKey': 'process_owner', 'value': i }))

    data = favs.map((data) => data.developed_by)
    mTmp = [...new Set(data)]
    var mDB = []
    mTmp.map((i) => mDB.push({ 'propertyKey': 'developed_by', 'value': i }))

    const lst = [...mAN, ...mPN, ...mDB]
    // console.log(lst)

    setFilteringOptions(lst)
    setProducts(favs)
    setIsProducsLoading(false)
  }

  const favorite = async (appId) => {
    setFavDisabled(true)
    await isFavorite(appId)
    if (favoritesMap && favoritesMap[appId]) {
      await delFavorite(appId, userId, function onSuccess(result) {
        delete favoritesMap[appId]
        changeToggle()
        setFavDisabled(false)
      }, function onFailure(result) { })
    } else {
      await addFavorite(appId, userId, "default", function onSuccess(result) {
        favoritesMap[appId] = true
        changeToggle()
        setFavDisabled(false)
      }, function onFailure(result) { })
    }

  }

  const getUrl = (item) => {
    if (item.url) {
      return item.url
    } else {
      return `App/${item.app_id}`
    }
  }
  const getCommaSeperatedOutputs = (item) => {
    let types = []
    item.output.forEach((output) => {
      types.push(output.type)
    })
    return types.join(', ')
  }
  const getContent = (label) => (

    <Box>
      {label.toLowerCase() == 'default' ? <Box variant="h1"> </Box> : <Box variant="h1">{label}</Box>}
      <br></br>
      {props.view == 'List' ? <Table
      columnDefinitions={[
        {
          id: "fav",
          cell: e => <div className={favDisabled ? "center_disabled" : "center"} disabled={favDisabled} >
          {favoritesMap[e.app_id] && (<img className="favorite_icon" src={favLogoSolid} onClick={() => favorite(e.app_id)}></img>)}
          {!favoritesMap[e.app_id] && (<img className="favorite_icon" src={favLogo} onClick={() => favorite(e.app_id)}></img>)}
        </div> ,
        width: 50,
          isRowHeader: true
        },
        {
          id: "name",
          header: "Name",
          cell: e => <Link to={getUrl(e)}> {e.app_name}</Link> ,
          width: 300,
        },
        {
          id: "app_description",
          header: "Description",
          cell: e => e.app_description,
          width: 350,
        },
        {
          id: "organization",
          header: "Organization",
          cell: e => (e.organization ? e.organization.join(', ') : "Not Available"),
          width: 200,
        },
        {
          id: "useCaseType",
          header: "Use Case Type",
          cell: e => (e.use_case ? e.use_case.join(', ') : "Not Available"),
          width: 200,
        },
        {
          id: "processOutput",
          header: "Process output",
          cell: e => (e.output ? getCommaSeperatedOutputs(e) : "Not Available"),
          width: 200,
        },
        {
          id: "vp",
          header: "VP",
          cell: e => (e.vp ? e.vp.join(', ') : "Not Available"),
          width: 200,
        }
      ]}
      items={filterByLabel(label, filteredProducts)}
      loadingText="Loading resources"
      resizableColumns
      empty={
        <Box textAlign="center" color="inherit">
          <b>No Favorites</b>
          <Box
            padding={{ bottom: "s" }}
            variant="p"
            color="inherit"
          >
            No favorites to display.
          </Box>
        </Box>
      }
    /> :
      <Cards
      cardDefinition={{
        header: item => (
          <div className="header_menu">
            <div className="link">
              <Link to={getUrl(item)}> {item.app_name}</Link>
            </div>
            <div className={favDisabled ? "icon_disabled" : "icon"} disabled={favDisabled} >
              {favoritesMap[item.app_id] && (<img className="favorite_icon" src={favLogoSolid} onClick={() => favorite(item.app_id)} height="30"></img>)}
              {!favoritesMap[item.app_id] && (<img className="favorite_icon" src={favLogo} onClick={() => favorite(item.app_id)} height="30"></img>)}
            </div>
          </div>
        ),
        sections: [
          {
            id: "app_description",
            header: "",
            content: item => item.app_description
          },
          {
            id: "process_owner",
            header: "ProcessOwner",
            content: item => item.process_owner
          }
        ]
      }}
      cardsPerRow={[
        { cards: 1 },
        { minWidth: 500, cards: 3 }
      ]}
      items={filterByLabel(label, filteredProducts)}
      loading={isProducsLoading}
      loadingText="Loading resources"
      empty={
        <Box textAlign="center" color="inherit">
          <b>No Favorites</b>
          <Box
            padding={{ bottom: "s" }}
            variant="p"
            color="inherit"
          >
            No favorites to display.
          </Box>
        </Box>
      }
    />
      }
    </Box>

  )

  const filterByLabel = (label, items) => {
    items = items.filter(d => favoritesLabelMapper[label].has(d?.app_id));
    return items
  }
  const getRenderHTML = () => {
    let contents = []
    let labels = Object.keys(favoritesLabelMapper)
    if(labels.length == 0){
      contents.push(<Box key={0} textAlign="center">{emptyMessage}</Box>)
    }else{
      labels.forEach((label, index) => {
        contents.push(<div key={index}>{getContent(label)}</div>)
      })
    }
    
    return contents
  }
  return (
    <Box>
      {getRenderHTML()}
    </Box>
  )
}

export default Favorite
