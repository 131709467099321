import React, {useState} from 'react';

import SideNavigation from "@amzn/awsui-components-react/polaris/side-navigation";
import { useHistory, useLocation } from "react-router-dom";

export default (props) => {
  const [activeHref, setActiveHref] = useState(props.activeHref);
  const history = useHistory();

  const items = [
    {
        "type": "link",
        "text": "Introduction",
        "href": "/TEDHome"
    },

    {
        "type": "section",
        "text": "ETB Triggers",
        "defaultExpanded": true,
        "items": [
            {
                "type": "link",
                "text": (<div>Manual ETB trigger</div>),
                "href": "/TriggerEventLogs"
            },
            {
                "type": "link",
                "text": (<div>History</div>),
                "href": "/TriggerHistory"
            }
        ]

    },

    {
        "type": "section",
        "text": "Trigger Config",
        "defaultExpanded": true,
        "items": [

            {
                "type": "link",
                "text": "Region Trigger Mapping",
                "href": "/RegionTriggerMapping"
            },
            {
                "type": "link",
                "text": "Entity Region Mapping",
                "href": "/EntityRegionMapping"
            },
            {
                "type": "link",
                "text": "Email Subscription",
                "href": "/EmailSubscription"
            },
        ]
    },




    { "type": "divider" },

    
    {
        "type": "link",
        "text": "Back",
        "href": "/ATeamBIAllProducts"
    },


    ];
  return (
    <SideNavigation
      activeHref={activeHref}
      header={{ href: "/TEDHome", text: "Tax ETB Data" }}
      onFollow={e => {
        e.stopPropagation();
        e.preventDefault();
        history.push(e.detail.href);
        setActiveHref(e.detail.href)
    }}
      items={items}
    />
  );
}