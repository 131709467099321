import React, { useState, useEffect, useRef } from 'react';
import appSettings from './app_settings';

import {
    AppLayout, HelpPanel, Cards, Tabs, Icon, Spinner, ContentLayout, Table,
    Container, Header, Box, Pagination, SpaceBetween, ExpandableSection, Modal, ColumnLayout, Button, Badge, Textarea, Select, Alert, Input, TokenGroup
} from '@amzn/awsui-components-react';
import Link from "@amzn/awsui-components-react/polaris/link";

import { useParams } from "react-router-dom";
import '../../index.scss';
import { useSelector, useDispatch } from 'react-redux'

import './styles.scss'

import AccessDenied from '../../AccessDenied'
import WaitForPageAccess from '../../WaitForPageAccess'
import { useHistory } from 'react-router-dom';

import { API, graphqlOperation } from 'aws-amplify';
import Template from '../Templates/Template';
import { glossary, TEMPLATE_APP, TEMPLATE_APP_OUTPUT, TEMPLATE_APP_PERMISSION, TEMPLATE_APP_TEAM, TEMPLATE_APP_SOURCE_DATA_TABLES, TEMPLATE_APP_CHART_OF_ACCOUNTS } from 'src/Ateam-BI/Templates/Constants';
import TemplateMaple from '../Templates/TemplateMaple';
import { favorite, checkIsFavorite } from '../../utilities/Favorites'
import eyeLogo from '../../images/eye-svgrepo-com.svg'
import SideNav from './SideNav';
import { getAppPromise, getFRISCOGroupsWithConsolidatedStatusForQuarter, getFriscoGroupById, getFriscoGroupsForAppId, getFriscoProcessWithTracksForQuarter, getRedshiftTransformedTableDataForProcessId } from '../Templates/Utils/AppActivityUtils';
import { FRISCO_STATUS, PROCESS_KEYS } from '../Frisco/Constants';
import * as XLSX from 'xlsx';
import { getEditableFields } from "../Templates/Utils/FormMetadata";
import { canAddEditDeleteApplication, isCorpAdmin } from '../Templates/Utils/Permissions';
import Feedback from 'src/generic-components/UserFeedback/Feedback';
import { performActionOnWorkflow } from 'src/utilities/wasApprovalAPIs';

import CustomMultiInput from "../Templates/components/CustomMultiInput";
import { notifyByEmail } from "../../utilities/notificationAPIs"
import { isApprover, isUploaderAllowed } from '../Frisco/AccessUtilities';
import { getStateNonAdmin, filterItemsByAccess, groupModules, getQuarterForUpload } from './Utility';
import TextFilter from "@amzn/awsui-components-react/polaris/text-filter";

// Component ServiceHomepage is a skeleton of a service's homepage using AWS-UI React components.
export default (props) => {
    const history = useHistory()
    const currentStage = useSelector((globalState) => globalState.auth.currentStage)
    const userId = useSelector((globalState) => globalState.auth.userId);
    const corpReportingQuarter = useSelector((globalState) => globalState.auth.corpReportingQuarter)
    const [NavOpen, setNavOpen] = useState(false);
    const [groupName, setGroupName] = useState("");
    const [filteringText, setFilteringText] = useState("");
    const [showGroupsLoader, setShowGroupsLoader] = useState(true);
    const [groups, setGroups] = useState([]);
    const [groupsForAlias, setGroupsForAlias] = useState([]);
    const [filteredGroups, setFilteredGroups] = useState([]);
    const [showGroupsLoaderMain, setShowGroupsLoaderMain] = useState('');
    const [
        selectedItems,
        setSelectedItems
    ] = React.useState([]);
    const [cssLeadSheet, setCSSLeadSheet] = useState({})
    const [filters, setFilters] = useState([
    ]);
    const [isAdmin, setIsAdmin] = useState(false)
    let { group } = useParams();
    const filtersRef = useRef(null);

    useEffect(() => {
        loadProcessesWithTracksForQuarter(appSettings[currentStage].appId)
        setAccess()
        return () => {
            // this now gets called when the component unmounts
        };
    }, [currentStage]);

    useEffect(() => {
        filtersRef.current = filters
        filterGroups()
        return () => {
            // this now gets called when the component unmounts
        };
    }, [groups, filters]);

    useEffect(() => {
        let filteredByName=[]
        for(let i=0;i<groupsForAlias.length;i++){
            if(groupsForAlias[i].group_name.toLowerCase().includes(filteringText.toLowerCase())){
                filteredByName.push(groupsForAlias[i])
            }
        }
        setFilteredGroups(filteredByName)
        return () => {
            // this now gets called when the component unmounts
        };
    }, [filteringText, groupsForAlias]);

    const useFilteredResponse = (result, allData) => {
        // some times filter is removed but since filtering takes time, the filtered data is considered since it is slow and last 
        if(!filtersRef.current || filtersRef.current.length == 0){
            setGroupsForAlias(allData)
        }else{
            setGroupsForAlias(result)
        }
    }

    const filterGroups = async () => {
        setShowGroupsLoaderMain("Loading workstreams..")
        if(!filtersRef.current || filtersRef.current.length == 0){
            setShowGroupsLoaderMain('')
            setGroupsForAlias(groups)
        }else{
            filterItemsByAccess(groups, filteringText, 'group_name', filters, currentStage, function onResult(result, finished){
                if(finished){
                    setShowGroupsLoaderMain('')
                }
                useFilteredResponse(result, groups)
            })
        }
        
    }
    const setAccess = async () => {
        let accessPerms = await isCorpAdmin(userId, appSettings[currentStage].appId, currentStage)
        setIsAdmin(accessPerms?.admin)
    }

    const getStatusBadge = (status) => {
        if (status == 'NOT INITIATED') {
            return <Box><span className='grey_badge'>NOT INITIATED</span></Box>
        } else if (status == 'COMPLETED') {
            return <Box><span className='green_badge'>COMPLETED</span></Box>
        } else if (status == 'INCOMPLETE') {
            return <Box><span className='red_badge'>INCOMPLETE</span></Box>
        }
    }

    const loadProcessesWithTracksForQuarter = (appId) => {
        setFilters([{ label: userId, dismissLabel: "Alias" }])
        var quarterValue = getQuarterForUpload()

        getFriscoGroupsForAppId(appId,
            function onSuccess(result) {
                let resGroups = result.data.getFRISCOGroups
                if (quarterValue) {
                    getFRISCOGroupsWithConsolidatedStatusForQuarter(appId, quarterValue, function onSuccess(result) {
                        let res = result.data.getFRISCOGroupsWithConsolidatedStatusForQuarter

                        res = res.sort((a, b) => {
                            if (a.group_name < b.group_name) {
                                return -1;
                            }
                        });

                        setGroups(groupModules(res, resGroups))
                        setShowGroupsLoader(false)
                    }, function onFailure(result) {
                        setShowGroupsLoader(false)
                    })
                }
            }, function onFailure(result) {
                setShowGroupsLoader(false)
            })
    }
    const Content = (
        currentStage ?
            <ContentLayout
                headerVariant="high-contrast"
                className="content"
                header={
                    <SpaceBetween size="l">
                        <Header
                            variant="h1"
                        >
                            Workstreams
                        </Header>
                    </SpaceBetween>
                }
            >
                <Container>

                    <Table
                        columnDefinitions={[
                            {
                                id: "modules",
                                header: "Workstreams",
                                cell: item => (
                                    <Link href={`/CorpReportingHome/modules/${item.group_id}?name=${item.group_name}&quarter=${corpReportingQuarter}`}>{item.group_name || "-"}</Link>
                                ),
                                sortingField: "modules",
                                isRowHeader: true
                            },
                            {
                                id: "status",
                                header: "overall status",
                                cell: item => getStatusBadge(item?.consolidated_status) || "-",
                                sortingField: "status"
                            }
                        ]}
                        enableKeyboardNavigation
                        items={filteredGroups}
                        loading={filteredGroups.length == 0 && showGroupsLoader}
                        loadingText="Loading details"
                        resizableColumns
                        filter={
                            <Box>
                                <TextFilter
                                    filteringPlaceholder="Find workstreams"
                                    filteringText={filteringText}
                                    onChange={(details) => { setFilteringText(details.detail.filteringText) }}
                                />
                                <SpaceBetween direction='horizontal' size='m'>
                                <TokenGroup
                                    onDismiss={({ detail: { itemIndex } }) => {
                                        setFilters([
                                            ...filters.slice(0, itemIndex),
                                            ...filters.slice(itemIndex + 1)
                                        ]);
                                    }}
                                    items={filters}
                                />
                                {
                                    showGroupsLoaderMain? <Box textAlign="center" margin={{ top: "xl" }}>{showGroupsLoaderMain} <Spinner></Spinner></Box>:<></>
                                }
                                </SpaceBetween>
                            </Box>
                        }
                        empty={
                            <Box
                                margin={{ vertical: "xs" }}
                                textAlign="center"
                                color="inherit"
                            >
                                <SpaceBetween size="m">
                                    <b></b>
                                </SpaceBetween>
                            </Box>
                        }
                        pagination={
                            <Pagination currentPageIndex={1} pagesCount={1} />
                        }
                    />

                </Container>

                <br />
                <Feedback appId={appSettings[currentStage].appId} pageId="corpReportingModuleHome" userId={userId} parentId={"null"} level={0} limit={1}></Feedback>

            </ContentLayout> : <Box>
                <Spinner></Spinner>
                Loading...
            </Box>
    )

    return (
        <AppLayout
            disableContentPaddings={false}
            content={Content}
            contentType="default"
            navigationOpen={NavOpen}
            navigation={<SideNav activeHref={`/CorpReportingHome/modules`} id={appSettings[currentStage].appId} isAdmin={isAdmin}/>}
            onNavigationChange={() => {
                setNavOpen(!NavOpen);
            }}
            toolsHide={true}
            headerVariant="high-contrast"
        />
    );
};


