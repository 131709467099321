import React, { useState, useEffect } from "react";
import Amplify, { Storage } from 'aws-amplify';
import {
    Button,
    Header,
    Textarea,
    Container,
    SpaceBetween,
    Box,
    Grid,
    Link,
    ExpandableSection,
    FileUpload,
    Modal,
    Spinner
} from "@amzn/awsui-components-react";
import { executeQuery, executeQueryWithoutCallback, executeQueryWithVariables } from "src/Ateam-BI/Templates/Utils/AppActivityUtils";
import './styles.scss'
import { configureGenericS3 } from "src/context/AuthContextUtility";
const Feedback = (props) => {
    useEffect(() => {
        initView()
    }, [props?.appId])

    useEffect(() => {
        if (props.reloadSubLevels) {
            initView()
        }
    }, [props?.reloadSubLevels])

    const [newComment, setNewComment] = useState("");
    const [comments, setComments] = useState([])
    const [errorMessage, setErrorMessage] = useState("")
    const [loading, setloading] = useState("")
    const [selectedFilesForNewComment, setSelectedFilesForNewComment] = useState([])
    const [selectedFilesPerComment, setSelectedFilesPerComment] = useState([])
    const [editableCommentSection, setEditableCommentSection] = useState({})
    const [editableCommentSectionValues, setEditableCommentSectionValues] = useState({})
    const [reloadSubLevels, setReloadSubLevels] = useState(false)
    const [deleteModalComment, setDeleteModalComment] = useState(null)
    const [subCommentExpanded, setSubCommentExpanded] = useState(false)
    const s3Prefix = 'AccountingCentral/'
    const getUniqueId = (timestamp) => {
        let id = `${props.pageId}_${props.userId}_${timestamp}`
        return btoa(id)
    }
    const deleteComment = async (comment) => {
        setloading("Deleting the comment thread, please wait..")
        setReloadSubLevels(false)
        let idsToDelete = await getSubFeedbackUniqueIds(comment)
        idsToDelete = idsToDelete.slice().reverse()
        for (let i = 0; i < idsToDelete.length; i++) {
            idsToDelete[i].attachments.forEach((attachment) => {
                configureGenericS3()
                Storage.remove(attachment.url, { level: 'public', customPrefix: { public: s3Prefix } });
            })
            let qry = `mutation MyMutation {
            deleteUserFeedback(input: {pageId: "${comment.pageId}", uniqueId: "${idsToDelete[i].uniqueId}"}) {
              parentId
              userId
              appId
              timestamp
            }
          }
          `
            await executeQueryWithoutCallback(qry)
        }
        setloading("")
        setReloadSubLevels(true)
        getFeedbacks(props.parentId)
    }
    const editFeedback = (comment, value, saveUpdatedComment = false) => {
        let obj = {}
        obj[comment.uniqueId] = value
        setEditableCommentSection(prevState => {
            return { ...prevState, ...obj };
        });
        if (!value && saveUpdatedComment) {
            updateFeedbackMessage(comment.uniqueId, editableCommentSectionValues[comment.uniqueId],
                function onSuccess(response) {
                    getFeedbacks(props.parentId)
                }, function onFailure(response) {

                })
        } else {
            setErrorMessage("")
            editFeedbackValue(comment, comment.message)
        }
    }
    const editFeedbackValue = (comment, value) => {
        let obj = {}
        obj[comment.uniqueId] = value
        setEditableCommentSectionValues(prevState => {
            return { ...prevState, ...obj };
        });
    }
    const getFeedbacks = (parentId = null,nextToken=null) => {
        let inputParam=''
        if(parentId){
            inputParam = inputParam + `,parentId:"${parentId}"`
        }
        if(nextToken){
            inputParam = inputParam + `,after:"${nextToken}"`
        }
        let qry = `query queryUserFeedbacksByPageIdParentIdIndex {
            queryUserFeedbacksByPageIdParentIdIndex(pageId:"${props.pageId}" ${inputParam}) {
              items {
                parentId
              userId
              appId
              timestamp
              message
              pageId
              uniqueId
              urls
              attachments {
                fileName
                url
                timestamp
              }
              }
            }
          }`
        executeQuery(qry, function onSuccess(response) {
            setComments(response.data.queryUserFeedbacksByPageIdParentIdIndex.items)
        }, function onFailure(response) {
            setErrorMessage("Could not fetch comments")
        })
    }
    const getSubFeedbackUniqueIds = async (comment) => {
        let uniqueIds = []
        let queue = []
        queue.push(comment)
        while (queue.length > 0) {
            let parentItem = queue.shift()
            uniqueIds.push(parentItem)
            let inputParam=''
            if(parentItem.uniqueId){
                inputParam = inputParam + `,parentId:"${parentItem.uniqueId}"`
            }
            let qry = `query queryUserFeedbacksByPageIdParentIdIndex {
                queryUserFeedbacksByPageIdParentIdIndex(pageId:"${props.pageId}" ${inputParam}) {
                    items {
                    uniqueId
                    attachments{
                        fileName
                        url
                        timestamp
                    }
                  }
                }
              }`
            let resp = await executeQueryWithoutCallback(qry)
            if (resp.data?.queryUserFeedbacksByPageIdParentIdIndex?.items?.length > 0) {
                resp.data.queryUserFeedbacksByPageIdParentIdIndex.items.forEach((item) => {
                    queue.push(item)
                })
            }
        }
        return uniqueIds
    }
    const attachFilesToComment = (comment, extraFiles = []) => {
        uploadFile(comment, extraFiles,
            function onSuccess(response) {
                initView()
            },
            function onFailure(response) {

            })
    }
    const addFeedback = () => {
        setErrorMessage("")
        if (!newComment || newComment.length == 0) {
            setErrorMessage("Comment cannot be empty")
        } else {
            setloading("Adding comment..")
            let timestamp = Date.now().toString()
            let qry = `mutation MyMutation($message: String!) {
            createUserFeedback(input: {appId: "${props.appId}", message: $message, pageId: "${props.pageId}", uniqueId: "${getUniqueId(timestamp)}", userId: "${props.userId}", parentId: "${props.parentId}", timestamp: "${timestamp}"}) {
              parentId
              userId
              appId
              timestamp
              message
              pageId
              uniqueId
              urls
              attachments {
                fileName
                url
                timestamp
              }
            }
          }
          `
            let variables = {
                message: newComment
            }
            //   console.log(qry)
            executeQueryWithVariables(qry, variables, function onSuccess(response) {
                let comment = response.data.createUserFeedback
                setloading("")
                attachFilesToComment(comment, selectedFilesForNewComment)
            }, function onFailure(response) {
                setloading("")
                setErrorMessage("Could not add comment")
            })
        }

    }
    const updateFeedbackMessage = (commentUniqueId, message, onSuccess, onFailure) => {
        if (!message || message.length == 0) {
            setErrorMessage("Comment cannot be empty")
        } else {
            let qry = `mutation MyMutation($message: String!) {
                updateUserFeedback(input: {pageId: "${props.pageId}",uniqueId: "${commentUniqueId}", message: $message }) {
                    parentId
                    userId
                    appId
                    timestamp
                    message
                    pageId
                    uniqueId
                    urls
                    attachments {
                      fileName
                      url
                      timestamp
                    }
                }
              }
              `
            let variables = {
                message: message
            }
            executeQueryWithVariables(qry, variables, function onQuerySuccess(response) {
                let updated = response.data.updateUserFeedback
                setErrorMessage("")
                onSuccess(updated)
            }, function onQueryFailure(response) {
                onFailure(response)
                setErrorMessage("Could not add upload information to comments")
            })
        }
    }

    const updateFeedbackAttachments = (commentUniqueId, attachments, existingAttachments, onSuccess, onFailure) => {
        if (existingAttachments && existingAttachments.length > 0) {
            attachments = attachments.concat(existingAttachments)
        }
        let attachmentsSubQry = `[`
        if (attachments?.length > 0) {
            attachments.forEach((attachment) => {
                attachmentsSubQry = attachmentsSubQry + `{fileName:"${attachment.fileName}", url:"${attachment.url}", timestamp:"${attachment.timestamp}"}`
            })
        }
        attachmentsSubQry = attachmentsSubQry + `]`
        let qry = `mutation MyMutation {
            updateUserFeedback(input: {pageId: "${props.pageId}",uniqueId: "${commentUniqueId}", attachments: ${attachmentsSubQry} }) {
                parentId
                userId
                appId
                timestamp
                message
                pageId
                uniqueId
                urls
                attachments {
                  fileName
                  url
                  timestamp
                }
            }
          }
          `

        executeQuery(qry, function onQuerySuccess(response) {
            let updated = response.data.updateUserFeedback
            onSuccess(updated)
        }, function onQueryFailure(response) {
            onFailure(response)
            setErrorMessage("Could not add upload information to comments")
        })
    }
    const initView = () => {
        configureGenericS3()
        setNewComment('')
        getFeedbacks(props.parentId)
        setSelectedFilesForNewComment([])
    }
    const getDate = (value) => {
        var d = new Date(parseFloat(value))
        return d.toLocaleString()
    }

    const showSubLevel = () => {
        return props.level != null && props.limit != null && (parseInt(props.level) < parseInt(props.limit))
    }

    const uploadToS3 = async (selectedFile, comment) => {
        configureGenericS3()
        let fileToUpload = selectedFile
        let folder = `comments/${comment.userId}/${comment.uniqueId}`
        let fileName = `${folder}/${fileToUpload.name}`
        let result = await Storage.vault.put(fileName, fileToUpload, {
            contentType: fileToUpload.type,
            tagging: "userId=" + props.userId,
            level: 'public',
            customPrefix: { public: s3Prefix },
            acl: 'bucket-owner-full-control'
        })
        let res = {
            fileName: fileToUpload.name,
            url: result['key']
        }
        return res
    }
    const uploadFile = async (comment, attachedFiles = [], onSuccess, onFailure) => {
        let attachmentsToUpdate = []
        let selected = selectedFilesPerComment.hasOwnProperty(comment.uniqueId) ? selectedFilesPerComment[comment.uniqueId] : []
        selected = selected.concat(attachedFiles)
        for (let i = 0; i < selected.length; i++) {
            let resp = await uploadToS3(selected[i], comment)
            resp['timestamp'] = Date.now().toString()
            attachmentsToUpdate.push(resp)
        }
        updateFeedbackAttachments(comment.uniqueId, attachmentsToUpdate, comment.attachments, onSuccess, onFailure);
        if (selectedFilesPerComment.hasOwnProperty(comment.uniqueId)) {
            let obj = {}
            obj[comment.uniqueId] = []
            setSelectedFilesPerComment(prevState => {
                return { ...prevState, ...obj };
            });
        }
    }

    const download = async (s3Path) => {
        configureGenericS3()
        const result = await Storage.get(s3Path, { level: 'public', customPrefix: { public: s3Prefix }, expires: 60 });
        window.open(result);
    }
    const deleteFile = async (attachment, index, comment) => {
        comment.attachments.splice(index, 1)
        updateFeedbackAttachments(comment.uniqueId, [], comment.attachments, function onSuccess(response) {
            configureGenericS3()
            Storage.remove(attachment.url, { level: 'public', customPrefix: { public: s3Prefix } });
            initView()
        }, function onFailure(response) {
        });
    }
    const getCommentView = (comment, index) => {
        return (
            <Grid
                disableGutters
                gridDefinition={[{ colspan: 1 }, { colspan: 11 }]}
                key={`${comment.uniqueId}${index}`}
            >
                <Box textAlign="center">
                    <img
                        alt=""
                        className="dp"
                        src={'https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=' + comment.userId}
                        width={50}
                        height={50}
                    />
                </Box>
                <Container >
                    <SpaceBetween size="l" >
                        <Box>
                            <SpaceBetween direction="horizontal" size="s">
                                <Link href={`https://phonetool.amazon.com/users/${comment.userId}`} target="_blank">
                                    {comment.userId}
                                </Link>
                                <span className="xxsmall-grey">{getDate(comment.timestamp)}</span>
                            </SpaceBetween>
                            <Box >
                                <Grid
                                    disableGutters
                                    gridDefinition={[{ colspan: 11 }, { colspan: 1 }]}
                                >
                                    <Box>
                                        {
                                            (editableCommentSection.hasOwnProperty(comment.uniqueId) && editableCommentSection[comment.uniqueId]) ?
                                                <Textarea
                                                    onChange={({ detail }) => editFeedbackValue(comment, detail.value)}
                                                    value={(editableCommentSectionValues.hasOwnProperty(comment.uniqueId) && editableCommentSectionValues[comment.uniqueId] != null)
                                                        ? editableCommentSectionValues[comment.uniqueId] : comment.message}
                                                    placeholder="comments"
                                                /> :


                                                <div key={`${comment.uniqueId}${index}comment`}>
                                                    {comment.message.split('\n').map(i => {
                                                        return <span key={`${comment.uniqueId}${index}${i}`}>{i}<br /></span>
                                                    })}
                                                </div>
                                        }

                                    </Box>
                                    <SpaceBetween size="s" direction="horizontal" key={comment.uniqueId}>
                                        {
                                            comment?.userId == props?.userId ?
                                                <>
                                                    {
                                                        editableCommentSection && (!editableCommentSection.hasOwnProperty(comment.uniqueId) || !editableCommentSection[comment.uniqueId]) ?
                                                            <Button iconName="edit" variant="icon" onClick={() => { editFeedback(comment, true) }}></Button>
                                                            : ''
                                                    }
                                                    {
                                                        editableCommentSection && editableCommentSection.hasOwnProperty(comment.uniqueId) && editableCommentSection[comment.uniqueId]
                                                            && editableCommentSectionValues && editableCommentSectionValues.hasOwnProperty(comment.uniqueId) && editableCommentSectionValues[comment.uniqueId].trim() ?
                                                            <Button iconName="check" variant="icon" onClick={() => { editFeedback(comment, false, true) }}></Button>
                                                            : ''
                                                    }
                                                    {
                                                        editableCommentSection && editableCommentSection.hasOwnProperty(comment.uniqueId) && editableCommentSection[comment.uniqueId] ?
                                                            <Button iconName="close" variant="icon" onClick={() => { editFeedback(comment, false) }}></Button>
                                                            : ''
                                                    }
                                                    {
                                                        editableCommentSection && (!editableCommentSection.hasOwnProperty(comment.uniqueId) || !editableCommentSection[comment.uniqueId]) ?
                                                            <Button iconName="remove" variant="icon" onClick={() => { setDeleteModalComment(comment) }}></Button>
                                                            : ''
                                                    }
                                                </> : ''
                                        }


                                    </SpaceBetween>
                                </Grid>
                            </Box>
                            <Box  >
                                {
                                    (<ExpandableSection headerText={`Attachments(${comment?.attachments ? comment.attachments.length : 0})`}>
                                        <Grid
                                            disableGutters
                                            gridDefinition={[{ colspan: 6 }, { colspan: 6 }]}
                                        >
                                            <SpaceBetween direction="vertical" size="s">
                                                {comment?.attachments ?
                                                    Array.from(comment?.attachments).map((attachment, index) => {
                                                        return <Box key={`${comment.uniqueId}${index}${attachment.fileName}`}>
                                                            <SpaceBetween direction="horizontal" size="s" key={`${comment.uniqueId}${index}${attachment.fileName}`}>
                                                                <Button href="#" variant="inline-link" onClick={() => {
                                                                    download(attachment.url)
                                                                }}>
                                                                    {attachment.fileName}
                                                                </Button>
                                                                {
                                                                    comment?.userId == props?.userId ?
                                                                        <Button iconName="remove" variant="icon" onClick={() => deleteFile(attachment, index, comment)} />
                                                                        : ''
                                                                }
                                                                {
                                                                    (attachment?.timestamp && attachment?.timestamp != null && attachment?.timestamp != undefined && attachment?.timestamp != 'undefined') ?
                                                                        <span className="xxsmall-grey">{getDate(attachment.timestamp)}</span> : ''
                                                                }
                                                            </SpaceBetween>
                                                        </Box>
                                                    }) : ''
                                                }
                                            </SpaceBetween>
                                            {
                                                comment?.userId == props?.userId ?
                                                    <SpaceBetween direction="horizontal" size="s">

                                                        <FileUpload
                                                            onChange={({ detail }) => {
                                                                let obj = {}
                                                                obj[comment.uniqueId] = detail.value
                                                                setSelectedFilesPerComment(prevState => {
                                                                    return { ...prevState, ...obj };
                                                                });
                                                            }}
                                                            value={selectedFilesPerComment.hasOwnProperty(comment.uniqueId) ? selectedFilesPerComment[comment.uniqueId] : ''}
                                                            i18nStrings={{
                                                                uploadButtonText: e =>
                                                                    e ? "Choose files" : "Choose file",
                                                                dropzoneText: e =>
                                                                    e
                                                                        ? "Drop files to upload"
                                                                        : "Drop file to upload",
                                                                removeFileAriaLabel: e =>
                                                                    `Remove file ${e + 1}`,
                                                                limitShowFewer: "Show fewer files",
                                                                limitShowMore: "Show more files",
                                                                errorIconAriaLabel: "Error"
                                                            }}
                                                            multiple
                                                            showFileLastModified
                                                            showFileSize
                                                            showFileThumbnail
                                                            tokenLimit={3}
                                                            constraintText="Attach files"
                                                        />
                                                        <Button variant="normal" onClick={() => {
                                                            attachFilesToComment(comment)
                                                        }}>Upload</Button>
                                                    </SpaceBetween>
                                                    : ''
                                            }



                                        </Grid>


                                    </ExpandableSection>)
                                }
                            </Box>
                        </Box>
                        <Box>

                            {
                                comments && showSubLevel() ?
                                    <Feedback appId={props.appId} pageId={props.pageId} userId={props.userId} parentId={comment.uniqueId} level={props.level + 1} limit={props.limit} reloadSubLevels={reloadSubLevels}></Feedback>
                                    : ''
                            }

                        </Box>
                    </SpaceBetween>
                </Container>
            </Grid>
        )
    }

    const getMainView = () => {
        return (
            <SpaceBetween size="s" >
                {loading ? <div><Spinner /> {loading}</div> : ''}
                {errorMessage ? <div className="error-message"> {errorMessage} </div> : ''}
                <SpaceBetween size="s">

                    <SpaceBetween>
                        <Textarea
                            onChange={({ detail }) => setNewComment(detail.value)}
                            value={newComment}
                            placeholder="comments"
                        /></SpaceBetween>
                    <SpaceBetween alignItems="end">
                        <SpaceBetween direction="horizontal" size="s">
                            <FileUpload
                                onChange={({ detail }) => setSelectedFilesForNewComment(detail.value)}
                                value={selectedFilesForNewComment}
                                i18nStrings={{
                                    uploadButtonText: e =>
                                        e ? "Choose files" : "Choose file",
                                    dropzoneText: e =>
                                        e
                                            ? "Drop files to upload"
                                            : "Drop file to upload",
                                    removeFileAriaLabel: e =>
                                        `Remove file ${e + 1}`,
                                    limitShowFewer: "Show fewer files",
                                    limitShowMore: "Show more files",
                                    errorIconAriaLabel: "Error"
                                }}
                                multiple
                                showFileLastModified
                                showFileSize
                                showFileThumbnail
                                tokenLimit={3}
                                constraintText="Attach files"
                            />
                            <Button variant="primary" onClick={() => addFeedback()}> Add comment</Button>
                        </SpaceBetween>
                    </SpaceBetween>

                </SpaceBetween>
                <SpaceBetween size="s">
                    {
                        comments ? (Array.from(comments).map((comment, index) => {
                            return getCommentView(comment, index);
                        })) : ''
                    }
                </SpaceBetween>
            </SpaceBetween>
        )
    }
    return (
        <Box key={`${props.parentId}${comments}`}>
            {
                props.level == 0 ?
                    <Container
                        header={
                            <Header
                                variant="h2"
                                description={`User comments (${comments?.length})`}
                            >
                                Feedback
                            </Header>
                        }
                        key="maincontainer">
                        {getMainView()}
                    </Container>
                    :
                    <ExpandableSection onChange={(e) => { setSubCommentExpanded(e.detail.expanded) }} expanded={subCommentExpanded} headerText={`sub comments (${comments?.length})`} key="subcomment">
                        {getMainView()}
                    </ExpandableSection>

            }
            {
                deleteModalComment ?
                    <Modal
                        onDismiss={() => setDeleteModalComment(null)}
                        visible={deleteModalComment ? true : false}
                        footer={
                            <Box float="right">
                                <SpaceBetween direction="horizontal" size="xs">
                                    <Button variant="link" onClick={() => setDeleteModalComment(null)}>Cancel</Button>
                                    <Button variant="primary" onClick={() => {
                                        setDeleteModalComment(null)
                                        deleteComment(deleteModalComment)
                                    }}>Ok</Button>
                                </SpaceBetween>
                            </Box>
                        }
                        header="Deleting the comment"
                    >
                        This comment and the subsequent thread will be deleted. <br /> Are you sure you want to delete?
                    </Modal> : ''

            }

        </Box>
    )
}

export default Feedback;