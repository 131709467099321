import React, { useState, useEffect } from 'react'
import { Container, Icon, Box, Header, SpaceBetween, Button, Table } from '@amzn/awsui-components-react'

import { API, graphqlOperation } from 'aws-amplify';
import { mapper } from './constants/AppConstants';

const AccessDenied = (props) => {


  return (
    <div>
     <Container
        header={<Box variant='h2'>User does not have access to this page</Box>}
      >
      <Box variant='h1' color='text-status-error'>
        <Icon variant="error" name="status-negative"></Icon> &nbsp; Access Denied
      </Box>
      </Container>

      
    </div>
  )
}

export default AccessDenied
