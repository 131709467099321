import { executeQuery } from "src/Ateam-BI/Templates/Utils/AppActivityUtils"

export const queryTaskManagementsByAliasAndStatusIndex = (alias, status, first=100, after, onSuccess, onFailure) => {
    let qry = `query MyQuery {
        queryTaskManagementsByAliasAndStatusIndex(alias: "${alias}", status: "${status}", first:${first}) {
          nextToken
          items {
            taskId
            alias
            processId
            status
            taskType
            description
            appId
            approvalId
            dueDate
            createdBy
            createdTimestamp
            requestor
            previous_state_approval_id
            previous_state
            rc_rejected_approval
            rc_rejected_approval_message
          }
        }
      }
      `
      executeQuery(qry, onSuccess, onFailure)
}