import React, { useState, useEffect } from "react";
import { APPROVER_TYPES, FRISCO_NEGATIVE_STATUS, FRISCO_POSITIVE_STATUS, FRISCO_TABLE_STATUS, glossary, PROCESS_KEYS, UPLOADER_TYPES } from "./Constants";
import { setCurrentAppId, setTemplateId } from "../../store/userAuthSlice";
import SideNav from "./SideNav";
import {
  AppLayout,
  Button,
  ColumnLayout,
  Input,
  Icon,
  Textarea,
  Container,
  Header,
  Box,
  Grid,
  SpaceBetween,
  Link,
  ExpandableSection,
  ContentLayout,
  Table,
  Modal,
  Spinner,
  RadioGroup,
  FileUpload,
  HelpPanel,
  Flashbar,
  Alert,
  Checkbox
} from "@amzn/awsui-components-react";

import { CodeView } from '@amzn/awsui-code-view';
import rubyHighlight from "@amzn/awsui-code-view/highlight/ruby";


import "../../index.scss";
import "./styles.scss"
import { useSelector, useDispatch } from "react-redux";
import { canAddEditDeleteApplication } from "../Templates/Utils/Permissions";
import { useHistory, useParams } from "react-router-dom";

import * as XLSX from 'xlsx';

import CustomInput from "../Templates/components/CustomInput";
import CustomSelect from "../Templates/components/CustomSelect";
import CustomMultiSelect from "../Templates/components/CustomMultiSelect";
import CustomCheckbox from "../Templates/components/CustomCheckbox";
import CustomMultiHybridInputAndSelect from "../Templates/components/CustomMultiHybridInputAndSelect";
import CustomProcessSchemaInput from "../Templates/components/CustomProcessSchemaInput";
import { getEditableFields } from "../Templates/Utils/FormMetadata";
import { createProcess, getProcessById, updateProcess, getAppPromise, deleteProcess, getNormalisedColumnName, createAuditLog } from "src/Ateam-BI/Templates/Utils/AppActivityUtils"
import { TEMPLATE_APP, APPS_WITH_APP_LEVEL_PERMISSIONS_IN_TEMPLATE } from "../Templates/Constants";
import { STEP_FUNCTION_CONFIG, GLUE_JOB_CONFIG } from 'src/constants/AppConstants';
import Feedback from "src/generic-components/UserFeedback/Feedback";
import { configureFriscoS3 } from "src/context/AuthContextUtility";
import corpAppSettings from "../CorpReporting/app_settings";

export default (props) => {
  const dispatch = useDispatch();
  const currentStage = useSelector((globalState) => globalState.auth.currentStage)
  const templateId = useSelector((globalState) => globalState.auth.templateId)
  const history = useHistory();
  const [NavOpen, setNavOpen] = useState(true);
  const [showLoadingIcon, setShowLoadingIcon] = useState(true);
  const userId = useSelector((globalState) => globalState.auth.userId);
  let { id } = useParams();
  let { pid } = useParams();
  const [isNewProcess, setIsNewProcess] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [errorMessage, setErrorMessage] = useState('')
  const [alertErrorMessage, setAlertErrorMessage] = useState(null)
  const [accessPerms, setAccessPerms] = useState(props.accessPerms);
  const [canDelete, setCanDelete] = useState(false)
  const [canEdit, setCanEdit] = useState(false)
  const [canAdd, setCanAdd] = useState(false)
  const [enableEditAction, setEnableEditAction] = useState(false)
  const [processData, setProcessData] = useState({})
  const [processDataEdited, setProcessDataEdited] = useState({})
  const [fieldMetadata, setFieldMetadata] = useState({});
  const [sampleFile, setSampleFile] = useState([]);
  const [generatingSchemaLoader, setGeneratingSchemaLoader] = useState(false);
  const [deleteConfirmationModal, setDeleteConfirmationModal] = useState(false);
  const [deleteNotificationModal, setDeleteNotificationModal] = useState(false);
  const [updateNotificationModal, setUpdateNotificationModal] = useState(false);
  const [deleteAppName, setDeleteAppName] = useState('');
  const [flashBarComments, setFlashBarComments] = useState();
  const [schemaFileUrl, setSchemaFileUrl] = useState();
  const [isApprovalDisabled, setIsApprovalDisabled] = useState(false);
  const [backUrl, setBackUrl] = useState(null)
  const [updateComments, setUpdateComments] = useState("")
  const [modalError, setModalError] = useState("")
  const [toolsOpen, setToolsOpen] = useState(false);
  const [selectedDelsertKeys, setSelectedDelsertKeys] = useState([]);
  const [delsertDeleteScript, setDelsertDeleteScript] = useState([])
  const [delsertInsertScript, setDelsertInsertScript] = useState([])
  const [deleteDataOnUpdate, setDeleteDataOnUpdate] = useState(false)
  const [delsertSubmitPopupVisible, setDelsertSubmitPopupVisible] = useState(false)
  const [schemaStatus, setSchemaStatus] = useState(false)
  const [delsertPopupCalledFrom, setDelsertPopupCalledFrom] = useState ()
  const [dataGenerationPlatform, setDataGenerationPlatform] = useState('')
  const [dataSharePlatform, setDataSharePlatform] = useState('')
  const [dataGenerationPlatformClass, setDataGenerationPlatformClass] = useState('')
  const [dataSharePlatformClass, setDataSharePlatformClass] = useState('')
  useEffect(() => {
    configureFriscoS3()
    setInitView()
    setBackUrl(getParam('back'))
    return () => {
      // this now gets called when the component unmounts
    };
  }, []);

  useEffect(() => {

    if (processDataEdited.hasOwnProperty(PROCESS_KEYS.action) && processDataEdited[PROCESS_KEYS.action] === 'DELSERT') {

      if(selectedDelsertKeys.length > 0 && processDataEdited[PROCESS_KEYS.schema] && 
        processDataEdited[PROCESS_KEYS.team] && processDataEdited[PROCESS_KEYS.business] &&
        processDataEdited[PROCESS_KEYS.dataset_name]) {
         // Generate New Script 
      
      const tableName = `${processDataEdited[PROCESS_KEYS.team]}_${processDataEdited[PROCESS_KEYS.business]}_${processDataEdited[PROCESS_KEYS.dataset_name]}`.toLowerCase()
      setDelsertDeleteScript(generateDelsertDeleteScript(selectedDelsertKeys, tableName))
      setDelsertInsertScript(generateDelsertInsertScript(processDataEdited[PROCESS_KEYS.schema].map((item) => item.column_name), tableName))
      } else {
        //Reset Script
        setDelsertDeleteScript([])
        setDelsertInsertScript([])
      }
      
    }

  },[selectedDelsertKeys,processDataEdited])

  useEffect(() => {
    if (processDataEdited.hasOwnProperty(PROCESS_KEYS.critical_data) && processDataEdited.hasOwnProperty(PROCESS_KEYS.sox_approved)) {
    
      if(processDataEdited[PROCESS_KEYS.critical_data] && processDataEdited[PROCESS_KEYS.sox_approved]) {
        setDataGenerationPlatform("Red")
        setDataSharePlatform("None")
        setDataGenerationPlatformClass("red_badge")
        setDataSharePlatformClass("grey_badge")
      } else if (!processDataEdited[PROCESS_KEYS.critical_data] && processDataEdited[PROCESS_KEYS.sox_approved]) {
        setDataGenerationPlatform("Red")
        setDataSharePlatform("Orange")
        setDataGenerationPlatformClass("red_badge")
        setDataSharePlatformClass("orange_badge")
      } else if (!processDataEdited[PROCESS_KEYS.critical_data] && !processDataEdited[PROCESS_KEYS.sox_approved]) {
        setDataGenerationPlatform("Orange")
        setDataSharePlatform("Red")
        setDataGenerationPlatformClass("orange_badge")
        setDataSharePlatformClass("red_badge")
      } else if (processDataEdited[PROCESS_KEYS.critical_data] && !processDataEdited[PROCESS_KEYS.sox_approved]) {
        setDataGenerationPlatform("Red")
        setDataSharePlatform("None")
        setDataGenerationPlatformClass("red_badge")
        setDataSharePlatformClass("grey_badge")
      }

    }
  },[processDataEdited])

  useEffect(() => {
    setProcessDataEdited(processData)
    if (processData.hasOwnProperty(PROCESS_KEYS.status)
      && processData[PROCESS_KEYS.status]
      && processData[PROCESS_KEYS.status].toLowerCase().includes("failed")) {
      setFlashBarComments([{
        type: "error",
        dismissible: true,
        dismissLabel: "Dismiss message",
        onDismiss: () => setFlashBarComments([]),
        content: (
          <>
            {processData[PROCESS_KEYS.status]}
          </>
        ),
        id: "comments"
      }])
    }

    if (processData.hasOwnProperty(PROCESS_KEYS.action) &&  processData[PROCESS_KEYS.action] === 'DELSERT') {

      const delsertKeys = processData[PROCESS_KEYS.schema].filter((item) => item['delsert_key']).map((i) => i['column_name'])
      setSelectedDelsertKeys(delsertKeys)
    }

    if (FRISCO_POSITIVE_STATUS.includes(processData?.status) || FRISCO_NEGATIVE_STATUS.includes(processData?.status)) {
      setSchemaStatus(true)
    } else {
      setSchemaStatus(false)
    }
  }, [processData])

  useEffect(() => {
    if (sampleFile.length > 0) {
      suggestSchema()
    }
  }, [sampleFile])

  useEffect(() => {
    checkIfFormIsSubmittable()
  }, [processDataEdited, canEdit])

  const dismissDeleteDialog = () => {
    setDeleteAppName('')
    setDeleteConfirmationModal(false)
  }

  const dismissDeleteNotifyDialog = () => {
    setDeleteNotificationModal(false)
    if(backUrl){
      history.push(`/${backUrl}`)
    }else{
      history.push(`/App/${id}`)
    }
    

  }

  const dismissUpdateNotifyDialog = () => {
    setUpdateNotificationModal(false)
  }
  const generateDelsertDeleteScript = (delsertKeys, tableName) => {

    const deleteScript = []
    deleteScript.push(`-- delete statement: \n`)
    deleteScript.push(`DELETE FROM frisco.${tableName}`)
    deleteScript.push(`USING frisco_stg.${tableName} stg`)
    deleteScript.push(`WHERE`)

    delsertKeys.forEach((key, index) => {
      (index === delsertKeys.length - 1 ? deleteScript.push(`${tableName}.${key} = stg.${key};`) : deleteScript.push(`${tableName}.${key} = stg.${key} AND`))
    })
    return deleteScript;
  }
  
  const generateDelsertInsertScript = (columns, tableName) => {
    const insertScript = []
    insertScript.push(`-- insert statement: \n`)
    insertScript.push(`INSERT INTO frisco.${tableName} (`)
    columns.forEach((column, index) => {
      (index === columns.length - 1 ? insertScript.push(`${column})`) : insertScript.push(`${column},`))
    })
    insertScript.push(`SELECT`)
    columns.forEach((column, index) => {
      (index === columns.length - 1 ? insertScript.push(`${column}`) : insertScript.push(`${column},`))
    })
    insertScript.push(`FROM frisco_stg.${tableName};`)
    return insertScript;
  }

  const suggestSchema = async () => {
    setGeneratingSchemaLoader(true)
    let file = sampleFile[0]
    let data = await file.arrayBuffer();
    const wb = XLSX.readFile(data, { sheetRows: 1 })
    const workbook = XLSX.read(data)
    const worksheet = workbook.Sheets[workbook.SheetNames[0]]
    var readOptions = { type: 'binary', raw: false, defval: null, cellNF: false, cellDates: true, cellText: false }
    const jsonData = XLSX.utils.sheet_to_json(worksheet, readOptions)
    const headerInfo = XLSX.utils.sheet_to_json(worksheet, { header: 1, defval: "", raw: false, cellDates: true})

    const colNames = headerInfo[0]
    if(colNames.length > 1600){
      setErrorMessage("Max columns allowed is 1600. The file exceeds the allowed column length")
    }
    // create schema
    let schemas = []
    colNames.forEach((col, index) => {
      schemas.push({
        column_name: getNormalisedColumnName(col),
        data_type: getDataTypeForColumn(index, headerInfo),
        mandatory: true,
        delsert_key: false
      })
    })

    processDataEdited[PROCESS_KEYS.schema] = schemas
    setProcessDataEdited({ ...processDataEdited })
    setGeneratingSchemaLoader(false)
  }

  const increaseCount = (key, incVal, annotation) => {
    if (!annotation.hasOwnProperty(key)) {
      annotation[key] = 0
    }
    annotation[key] = annotation[key] + incVal
  }

  const getDataTypeForColumn = (index, headerInfo, defVal = 'VARCHAR') => {
    let annotation = {}
    let iter_length = headerInfo.length % 100;
    for (let i = 1; i < iter_length; i++) {
      let row = headerInfo[i]
      let boolArray = ["true", "false", "t", "f","yes","no"]
      if(row[index] == null 
        || row[index] == undefined 
        || row[index].toString().length <= 0){
        continue
      }
      if(boolArray.indexOf(row[index].toString().toLowerCase()) >=0 ){
        increaseCount('BOOLEAN', 1, annotation)
      }else if (!isNaN(Number(row[index]))) {
        if (Number(row[index]) % 1 == 0 && Number(row[index])<2147483647) {
          // priority to decimal
          increaseCount('INTEGER', 1, annotation)
        } else {
          increaseCount('DECIMAL', 999, annotation)
        }
      } else if (!isNaN(new Date(row[index]))) {
        let dateSplit = row[index].split('T')
        // priority to TIMESTAMP
        if (dateSplit.length > 1 && dateSplit[1] != '') {
          increaseCount('TIMESTAMP', 1, annotation)
        } else {
          increaseCount('DATE', 1, annotation)
        }
      } else {
        increaseCount('VARCHAR', 1, annotation)
      }
    }
    let max = { value: 0, key: defVal }
    Object.keys(annotation).forEach((key) => {
      if (annotation[key] > max['value']) {
        max['value'] = annotation[key]
        max['key'] = key
      }
    })

    return max['key']

  }

  const getParam= (key) => {
    const search = props.location.search;
    const params = new URLSearchParams(search);
    const value = params.get(key);
    return value;
  }

  const getGroup = () => {
    return getParam('group')
  }
  
  const setInitView = async () => {
    let group_id = getGroup()
  if(group_id){
    processData[PROCESS_KEYS.group_id] = group_id
  }
  let next_step = getParam('next_step')
  if(next_step){
    processData[PROCESS_KEYS.next_step] = next_step
  }
    processData[PROCESS_KEYS.app_id] = id
    processData[PROCESS_KEYS.approval_required] = false
    processData[PROCESS_KEYS.multipart_allowed] = false
    processData[PROCESS_KEYS.critical_data] = false
    processData[PROCESS_KEYS.sox_approved] = false

    // processData[PROCESS_KEYS.environment] = "Transfer_FAST_RedShift"
    loadEditableFields()
    if (pid) {
      dispatch(setCurrentAppId(id));
      loadData();
      setIsEditMode(false)
      setIsNewProcess(false)
    }else{
      setIsNewProcess(true)
      setShowLoadingIcon(false);
      (!isEditMode) ? setIsEditMode(true) : ''
    }
    let appTemplateID = templateId
    if (!templateId) {
      // fetch template from app id
      let result = await getAppPromise(id)
      setTemplateId(result.data.getAppsV2[0][TEMPLATE_APP.TEMPLATE_ID])
      appTemplateID = result.data.getAppsV2[0][TEMPLATE_APP.TEMPLATE_ID]
    }
    let accessPerms;
    if(id && APPS_WITH_APP_LEVEL_PERMISSIONS_IN_TEMPLATE[currentStage?.toUpperCase()].includes(id)) {
      accessPerms = await canAddEditDeleteApplication(userId, id, currentStage)
    }
    else {
      accessPerms = await canAddEditDeleteApplication(userId, appTemplateID, currentStage)
    }
    setCanDelete(accessPerms?.delete)
    setCanEdit(accessPerms?.edit)
    setCanAdd(accessPerms?.add)

    // !pid means isNewProcess=true. Sometimes the useeffect take time, so relying on pid. pid is a path param
    if(!pid && !accessPerms?.add){
      setAlertErrorMessage(
        {
          "header":"Access denied",
          "message": "No access to add a new process"
        }
      )
    }else{
      setAlertErrorMessage(null)
    }
  }

  const loadData =  () => {
    // setProcessData()
    const schemaStatusesForEditEnable = ['Table Schema Updated', 'Table Dropped', 'Table Created', 'Table Creation Failed', 'Table Schema Update Failed', 'Table Drop Failed', 'Data Write to Glue Catalog Failed', 'Data Write to Glue Catalog Complete']
    getProcessById(pid, function onSuccess(response) {
      const r = response.data.getProcessByIDV2
      const sorted = r[PROCESS_KEYS.schema].sort((a,b) => a.column_index - b.column_index)
      r[PROCESS_KEYS.schema].forEach((item) => item[PROCESS_KEYS.SCHEMA_KEYS.prev_column_name] = item[PROCESS_KEYS.SCHEMA_KEYS.column_name])
      if (schemaStatusesForEditEnable.includes(r?.status)) {
        setSchemaStatus(true)
      } else {
        setSchemaStatus(false)
      }
      if (r.hasOwnProperty(PROCESS_KEYS.approvers) && r[PROCESS_KEYS.approvers]) {
        r[PROCESS_KEYS.approvers].sort((a,b) => a.column_index - b.column_index)
      }
      setProcessData(r)
      setShowLoadingIcon(false);
    }, function onFailure(response) {
      setShowLoadingIcon(false);
    })
  }

  const loadEditableFields = async () => {
    let res = await getEditableFields()
    res[PROCESS_KEYS.approvers] = { values: [{ label: "USER", value: APPROVER_TYPES.APPROVER }, { label: "LDAP", value: APPROVER_TYPES.APPROVER_LDAP }] }
    res[PROCESS_KEYS.uploaders] = { values: [{ label: "USER", value: UPLOADER_TYPES.UPLOADER }, { label: "LDAP", value: UPLOADER_TYPES.UPLOADER_LDAP }] }
    res[PROCESS_KEYS.schema] = {
      values: [
        { label: "SMALLINT", value: "SMALLINT" },
        { label: "INTEGER", value: "INTEGER" },
        { label: "BIGINT", value: "BIGINT" },
        { label: "DECIMAL", value: "DECIMAL" },
        { label: "REAL", value: "REAL" },
        { label: "DOUBLE PRECISION", value: "DOUBLE PRECISION" },
        { label: "BOOLEAN", value: "BOOLEAN" },
        { label: "CHAR", value: "CHAR" },
        { label: "VARCHAR", value: "VARCHAR" },
        { label: "DATE", value: "DATE" },
        { label: "TIMESTAMP", value: "TIMESTAMP" },
        { label: "TIMESTAMPTZ", value: "TIMESTAMPTZ" },
        { label: "GEOMETRY", value: "GEOMETRY" },
        { label: "GEOGRAPHY", value: "GEOGRAPHY" },
        { label: "HLLSKETCH", value: "HLLSKETCH" },
        { label: "SUPER", value: "SUPER" },
        { label: "TIME", value: "TIME" },
        { label: "TIMETZ", value: "TIMETZ" },
        { label: "VARBYTE", value: "VARBYTE" }
      ]
    }
    setFieldMetadata(res)
  }

  const getSelectFieldValues = (fieldName, subKey = null) => {
    if (fieldMetadata && fieldMetadata[fieldName] && fieldMetadata[fieldName]['values']) {
      return fieldMetadata[fieldName]['values']
    } else if (subKey && fieldMetadata && fieldMetadata[fieldName][subKey] && fieldMetadata[fieldName][subKey]['values']) {
      return fieldMetadata[fieldName][subKey]['values']
    } else {
      return []
    }
  }

  const updateValue = (fieldName, value, subKey = null) => {
    if (subKey) {
      if (!processDataEdited.hasOwnProperty(fieldName)) {
        processDataEdited[fieldName] = {}
      }
      processDataEdited[fieldName][subKey] = value
    } else {
      processDataEdited[fieldName] = value
    }
    if(fieldName == PROCESS_KEYS.sox_approved) {
      if(value){
        // approval required MUST be true
        setIsApprovalDisabled(true)
        updateValue(PROCESS_KEYS.approval_required, true)
      } else{
        setIsApprovalDisabled(false)
      }   
    }

    if(fieldName === PROCESS_KEYS.action) {
      if(value !== 'DELSERT') {
        const updatedSchema = processDataEdited[PROCESS_KEYS.schema].map((item) => {
          const obj = {... item}
          obj['delsert_key'] = false
          return obj
        })
        updateValue(PROCESS_KEYS.schema, updatedSchema)
      }   
    }

    if(fieldName === PROCESS_KEYS.schema) {

      const delsertKeys = value.filter((item) => item['delsert_key']).map((i) => i['column_name'])
      setSelectedDelsertKeys(delsertKeys)
    }

    setProcessDataEdited(JSON.parse(JSON.stringify(processDataEdited)))
  }


  const getValue = (fieldName, subKey = null) => {
    if (subKey) {
      if (processDataEdited.hasOwnProperty(fieldName)) {
        return processDataEdited[fieldName][subKey]
      } else {
        return null
      }
    } else {
      return processDataEdited[fieldName]
    }
  }

  const getMultiInputFieldValues = (fieldName) => {
    if (processDataEdited && processDataEdited[fieldName]) {
      return processDataEdited[fieldName]
    } else {
      return []
    }
  }

  const getMultiSelectedOptions = (fieldName) => {
    let arr = processDataEdited[fieldName]
    let selectedOptions = []
    if (arr) {
      arr.forEach((item) => {
        selectedOptions.push({ label: item, value: item })
      })
    }
    return selectedOptions
  }

  const postProcessTeamEmails = (value) => {
    return value.replaceAll("@amazon.com", "")
  }

  const approversValidation = (elements) => {
    let emptyValues = true
    elements?.forEach((element) => {
      if (!element.type || !element.alias) {
        emptyValues = false
      }
    })
    return emptyValues
  }

  const mandatoryFieldListener = (key, enableUpdate) => {
    // mandatoryFieldsMissing[key] = enableUpdate
  }

  const columnNameValidator = (output) => {
    let valid = true
    for (let i = 0; i < output?.length; i++) {
      if (!output[i]?.column_name || !output[i]?.data_type) {
        valid = false
        break
      }
    }
    return valid
  }
  const checkIfNull = (data, field, missingFields) => {
    if (data && (!data.hasOwnProperty(field) || !data[field])) {
      missingFields.push(field)
    }else if(Array.isArray(data[field]) && data[field].length<=0){
      missingFields.push(field)
    }
  }

  const getMissingFields = (data, fields) => {
    let missingFields = []
    fields.forEach((field) => {
      if (typeof field == 'string') {
        checkIfNull(data, field, missingFields)
      } else {
        if (field?.mainKey) {
          field.subKeys.forEach(subKey => {
            data[field.mainKey]?.forEach((item) => {
              checkIfNull(item, subKey, missingFields)
            })
          })
        }
      }
    })
    return missingFields
  }

  const checkIfFormIsSubmittable = () => {
    let missingFields = getMissingFields(processDataEdited, [PROCESS_KEYS.process_name, PROCESS_KEYS.environment])
    if (missingFields.length <= 0
      && processDataEdited.hasOwnProperty(PROCESS_KEYS.environment)
      && processDataEdited[PROCESS_KEYS.environment] == 'Transfer_FAST_RedShift') {
      let missingFields2 = getMissingFields(processDataEdited, [PROCESS_KEYS.action, PROCESS_KEYS.team, PROCESS_KEYS.business, PROCESS_KEYS.dataset_name, PROCESS_KEYS.schema])
      missingFields = [...missingFields, ...missingFields2]
    }
    if (missingFields.length <= 0
      && processDataEdited.hasOwnProperty(PROCESS_KEYS.approval_required)
      && processDataEdited[PROCESS_KEYS.approval_required]) {
      if (!missingFields?.includes(PROCESS_KEYS.approvers)
        && processDataEdited?.hasOwnProperty(PROCESS_KEYS.approvers) && processDataEdited[PROCESS_KEYS.approvers]?.length == 0) {
        missingFields = [...missingFields, PROCESS_KEYS.approvers]
      }
      let missingFields2 = getMissingFields(processDataEdited, [{ mainKey: PROCESS_KEYS.approvers, subKeys: [PROCESS_KEYS.APPROVER_KEYS.alias, PROCESS_KEYS.APPROVER_KEYS.type] }])
      missingFields = [...missingFields, ...missingFields2]

    }

    let approvalDecision = true
    if (!validateSoxRequirements(processDataEdited)) {
      approvalDecision = false
    } else if (processDataEdited.hasOwnProperty(PROCESS_KEYS.approval_required) && processDataEdited[PROCESS_KEYS.approval_required] && processDataEdited.hasOwnProperty(PROCESS_KEYS.approvers)
     && processDataEdited[PROCESS_KEYS.approvers] && processDataEdited[PROCESS_KEYS.approvers].length <= 0) {
      approvalDecision = false
    }
    const delsertCondition = (processDataEdited.hasOwnProperty(PROCESS_KEYS.action) && processDataEdited[PROCESS_KEYS.action] === 'DELSERT' && selectedDelsertKeys.length < 1) ? false : true
    setEnableEditAction(missingFields.length <= 0 && approvalDecision && delsertCondition && ((isNewProcess && canAdd ) || (isEditMode && canEdit)))
  }

  const validateSoxRequirements = (processDataEdited) => {
    if (processDataEdited.hasOwnProperty(PROCESS_KEYS.sox_approved) && processDataEdited[PROCESS_KEYS.sox_approved]) {
      // if(processDataEdited.hasOwnProperty(PROCESS_KEYS.sox_evidence) && !processDataEdited[PROCESS_KEYS.sox_evidence] && processDataEdited[PROCESS_KEYS.sox_evidence] == ""){
      //   return false
      // }
      if (processDataEdited[PROCESS_KEYS.approvers].length < 2) {
        let groupCount = 0
        processDataEdited[PROCESS_KEYS.approvers].forEach((approver) => {
          if(approver[PROCESS_KEYS.APPROVER_KEYS.type] == APPROVER_TYPES.APPROVER_LDAP){
            groupCount = groupCount + 1
          }
        })
        if(groupCount > 0){
          return true
        }else{
          return false
        }
      } else {
        return true
      }
    } else {
      return true
    }
  }

  const delsertPopup = (val) => {
    setDelsertPopupCalledFrom(val)
    setDelsertSubmitPopupVisible(true)
  }
  const addProcessDetails = () => {
    setErrorMessage("")
    let missingFields = getMissingFields(processDataEdited, [PROCESS_KEYS.process_name, PROCESS_KEYS.action])
    if (missingFields && missingFields.length > 0) {
      setErrorMessage('missing mandatory fields : ' + missingFields.join(","))
    } else if(processDataEdited[PROCESS_KEYS.action] === 'DELSERT' && selectedDelsertKeys.length <= 0) {
      setErrorMessage('Minimum of 1 DELSERT KEY is required for DELSERT loadtype')
    } else {
      setShowLoadingIcon(true)
      let group_id = getGroup()
  if(group_id){
    processDataEdited[PROCESS_KEYS.group_id] = group_id
  }
  let next_step = getParam('next_step')
  if(next_step){
    processDataEdited[PROCESS_KEYS.next_step] = next_step
  }

      processDataEdited[PROCESS_KEYS.last_updated_by] = userId
      processDataEdited[PROCESS_KEYS.created_by] = userId
      processDataEdited[PROCESS_KEYS.step_function_name] = STEP_FUNCTION_CONFIG.find((c) => c.environment === currentStage)?.arn; 
      processDataEdited[PROCESS_KEYS.glue_job_name] = GLUE_JOB_CONFIG.find((c) => c.environment === currentStage)?.name
      // adding column index and prev_column_name
      processDataEdited[PROCESS_KEYS.schema].forEach((columnObj, i) => {
        columnObj[PROCESS_KEYS.SCHEMA_KEYS.column_index] = i+1;
        columnObj[PROCESS_KEYS.SCHEMA_KEYS.prev_column_name] = null;
      })

      processDataEdited[PROCESS_KEYS.status] = FRISCO_TABLE_STATUS.CREATING_TABLE
      createAuditLog(`FRISCO`,`ADD_REQ#${getCurrentTime()}`, "Adding a process", JSON.stringify({}), JSON.stringify(processDataEdited), "ADD REQ", userId)
      setProcessData(processDataEdited)
      createProcess(processDataEdited, function onSuccess(response) {
        setIsNewProcess(false)
        setProcessData(JSON.parse(JSON.stringify(response.data.createProcessV2)))
        setShowLoadingIcon(false)
        history.push(`/App/${response.data.createProcessV2.app_id}/process/${response.data.createProcessV2.process_id}`)
        cancelEdition()
      }, function onFailure(error) {
        setErrorMessage(error?.errors[0]?.message)
        setShowLoadingIcon(false)
      })
    }
  }

  const updateProcessDetails = () => {
    setErrorMessage("")
    let missingFields = getMissingFields(processDataEdited, [PROCESS_KEYS.process_id, PROCESS_KEYS.process_name, PROCESS_KEYS.action])
    if (missingFields && missingFields.length > 0) {
      setErrorMessage('missing mandatory fields : ' + missingFields.join(","))
    } else if(processDataEdited[PROCESS_KEYS.action] === 'DELSERT' && selectedDelsertKeys.length <= 0) {
      setErrorMessage('Minimum of 1 DELSERT KEY is required for DELSERT loadtype')
    } else {
      setShowLoadingIcon(true)
      let group_id = getGroup()
  if(group_id){
    processDataEdited[PROCESS_KEYS.group_id] = group_id
  }
  let next_step = getParam('next_step')
  if(next_step){
    processDataEdited[PROCESS_KEYS.next_step] = next_step
  }
      processDataEdited[PROCESS_KEYS.last_updated_by] = userId
      if (updateComments) {
        setErrorMessage("")
        // add an audit log
        createAuditLog(`FRISCO#${pid}`, `${getCurrentTime()}`, updateComments, JSON.stringify(processData), JSON.stringify(processDataEdited), "UPDATE REQ", userId)
        setUpdateComments("")
        // Updating Column Index and prev_column_name
        processDataEdited[PROCESS_KEYS.schema].forEach((columnObj, i) => {
          columnObj[PROCESS_KEYS.SCHEMA_KEYS.column_index] = i+1

          if (!columnObj.hasOwnProperty(PROCESS_KEYS.SCHEMA_KEYS.prev_column_name) || columnObj[PROCESS_KEYS.SCHEMA_KEYS.prev_column_name] === columnObj[PROCESS_KEYS.SCHEMA_KEYS.column_name]){
            columnObj[PROCESS_KEYS.SCHEMA_KEYS.prev_column_name] = null;
          }

        })
        processDataEdited[PROCESS_KEYS.status] = FRISCO_TABLE_STATUS.UPDATE_IN_PROGESS
        processDataEdited[PROCESS_KEYS.clear_table_data_on_update] = deleteDataOnUpdate
        setProcessData(processDataEdited)
        updateProcess(processDataEdited, function onSuccess(response) {
         
        }, function onFailure(error) {

        })
        setIsNewProcess(false)
        setShowLoadingIcon(false)
        cancelEdition()
        setUpdateNotificationModal(true)
      } else {
        setShowLoadingIcon(false)
        setErrorMessage("Please provide your comments for the update")
      }
      
    }
  }

  const cancelEdition = () => {
    setErrorMessage("")
    setIsEditMode(false)
    setProcessDataEdited(processData)
  }

  const editProcess = () => {
    setIsEditMode(true)
    setProcessDataEdited({ ...processData })
  }

  const delProcess = () => {
    if(updateComments){
      setShowLoadingIcon(true)
    dismissDeleteDialog()
      setModalError("")
      createAuditLog(`FRISCO#${pid}`,`${getCurrentTime()}`, updateComments, JSON.stringify(processData), JSON.stringify({}), "DELETE REQ", userId)
      setUpdateComments("")
      deleteProcess(pid, function onSuccess(response) {
        
      }, function onFailure(response) {
        
      })
      setShowLoadingIcon(false)
      setDeleteConfirmationModal(false)
      setDeleteNotificationModal(true)
    }else{
      setModalError("Please provide comments with the reason for deletion")
    }
    

  }

  const getCurrentTime = () => {
    return Date.now();
  }

  const getDate = (value) => {
    var d = new Date(value)
    return d.toLocaleString()
  }

  const lastUpdatedContent = (<>
    {getValue(PROCESS_KEYS.last_updated_time_stamp) ? <>

      <span className="last_updated"> Last updated : <span className="bold">{getDate(getValue(PROCESS_KEYS.last_updated_time_stamp))}</span></span>
      {getValue(PROCESS_KEYS.last_updated_by) ? <span className="last_updated"> &nbsp; by  <span className="bold">{getValue(PROCESS_KEYS.last_updated_by)}</span></span> : ''}
    </> : ''}
  </>)

  const getStatusIcon = (status) => {
    const positiveStatuses = FRISCO_POSITIVE_STATUS
    const negativeStatuses = FRISCO_NEGATIVE_STATUS
    if (positiveStatuses.includes(status)) {
      return <Icon name='status-positive' variant='success' ></Icon>
    } else if (negativeStatuses.includes(status)) {
      return <Icon name='status-negative' variant='error' ></Icon>
    } 
    else {
      return <Icon name='status-in-progress' variant='normal' ></Icon>
    }
  }

  const dataClasificationContent = (
    <Box>
    <SpaceBetween direction="horizontal" size="m">
      <Box>Data Generation Platform: <span className={dataGenerationPlatformClass}>{dataGenerationPlatform}</span></Box>
      <Box>Data Share Platform: <span className={dataSharePlatformClass}> {dataSharePlatform} </span></Box>
    </SpaceBetween>
    </Box>
  )
  const Content = (
    <>
      {showLoadingIcon ? <Modal visible={showLoadingIcon}>
        <Box>
          <Spinner></Spinner>
          Loading...
        </Box>
      </Modal> :
        <ContentLayout
          headerVariant="high-contrast"
          className="content"
          header={
            <SpaceBetween size="l">
              <Header
                variant="h1"
              >

              </Header>

            </SpaceBetween>
          }
        >

          <SpaceBetween size="l" >
          {
            alertErrorMessage?<Alert
            statusIconAriaLabel="Error"
            type="error"
            header={alertErrorMessage?.header}
          >
            {alertErrorMessage?.message}
          </Alert>:''
          }

            <Container
            >
              <SpaceBetween direction="vertical">
                <SpaceBetween direction="horizontal">
                  <Box variant="h1">Process Details</Box>
                  {
                    !isEditMode && canEdit && schemaStatus ?
                      <Button iconName="edit" variant="icon" onClick={editProcess} /> : <></>
                  }
                  {
                    !isEditMode && canDelete && schemaStatus ?
                      <Button iconName="remove" variant="icon" onClick={() => { setDeleteConfirmationModal(true) }} /> : <></>

                  }
                  <Modal
                    onDismiss={dismissDeleteDialog}
                    visible={deleteConfirmationModal}
                    footer={
                      <Box float="right">
                        <SpaceBetween direction="horizontal" size="xs">
                          <Button variant="link" onClick={dismissDeleteDialog} >Cancel</Button>
                          <Button disabled={deleteAppName !== processData[PROCESS_KEYS.process_name]} variant="primary" onClick={delProcess}  >Confirm</Button>
                        </SpaceBetween>
                      </Box>
                    }
                    header={
                      <React.Fragment>
                        <Box variant="h2">
                          Delete confirmation for '{processData[PROCESS_KEYS.process_name]}'
                        </Box>
                      </React.Fragment>
                    }
                  >
                    <SpaceBetween direction="vertical" size="m">
                      <Box>To confirm deletion, enter the name of the process in the text input field below</Box>
                      <Box>
                        <Input placeholder={processData[PROCESS_KEYS.process_name]} onChange={({ detail }) => setDeleteAppName(detail.value)} value={deleteAppName} />
                      </Box>
                      <Box>
                        <Textarea
                          class="description"
                          onChange={({ detail }) => setUpdateComments(detail.value)}
                          value={updateComments}
                          placeholder="Comments"
                        ></Textarea>
                      </Box>
                      <Box>
                        {modalError && (
                          <Box variant="p" color="text-status-error">
                            {modalError}
                          </Box>
                        )}
                      </Box>
                    </SpaceBetween>
                  </Modal>
                  <Modal
                    onDismiss={dismissDeleteNotifyDialog}
                    visible={deleteNotificationModal}
                    footer={
                      <Box float="right">
                        <SpaceBetween direction="horizontal" size="xs">
                          <Button variant="primary" onClick={dismissDeleteNotifyDialog}>Okay</Button>
                        </SpaceBetween>
                      </Box>
                    }
                    header={
                      <React.Fragment>
                        <Box variant="h2">
                          Delete scheduled for '{processData[PROCESS_KEYS.process_name]}'
                        </Box>
                      </React.Fragment>
                    }
                  >
                    <SpaceBetween direction="vertical" size="m">
                      <Box><Icon name="status-positive" variant="success" /> Process is scheduled to be deleted, please check back after 5 minutes.</Box>
                    </SpaceBetween>
                  </Modal>
                  <Modal
                    onDismiss={dismissUpdateNotifyDialog}
                    visible={updateNotificationModal}
                    footer={
                      <Box float="right">
                        <SpaceBetween direction="horizontal" size="xs">
                          <Button variant="primary" onClick={dismissUpdateNotifyDialog}>Okay</Button>
                        </SpaceBetween>
                      </Box>
                    }
                    header={
                      <React.Fragment>
                        <Box variant="h2">
                          Update scheduled for '{processData[PROCESS_KEYS.process_name]}'
                        </Box>
                      </React.Fragment>
                    }
                  >
                    <SpaceBetween direction="vertical" size="m">
                      <Box><Icon name="status-positive" variant="success" /> Process is scheduled to be updated, please check back after 5 minutes.</Box>
                    </SpaceBetween>
                  </Modal>
                </SpaceBetween>
                <Box >{lastUpdatedContent}</Box>
                <br></br>
              </SpaceBetween>
              
              <SpaceBetween size="l">
              {
                  !isEditMode?
                  <SpaceBetween direction="horizontal" size="m">
                            {
                              processData.hasOwnProperty(PROCESS_KEYS.approval_required) 
                              && processData[PROCESS_KEYS.approval_required]?
                              <Box><span className='orange_badge'>Approval required</span></Box>
                              :<></>
                            }
                            {
                              processData.hasOwnProperty(PROCESS_KEYS.sox_approved) 
                              && processData[PROCESS_KEYS.sox_approved]?
                              <Box><span className='green_badge'> 2 touch approval  </span></Box>
                              :<></>
                            }
                          
                  </SpaceBetween>
                          :
                          <></>
                }
                {
                  !isNewProcess ? <SpaceBetween direction="vertical" size="s">
                    <SpaceBetween size="s" direction="horizontal">
                      <Box>Status :</Box>
                      
                      {(processData.hasOwnProperty(PROCESS_KEYS.status) && processData[PROCESS_KEYS.status]) ?
                        <SpaceBetween direction="horizontal" size="s"> {getStatusIcon(processData[PROCESS_KEYS.status])}
                          <span className="small_grey_text_italics">{processData[PROCESS_KEYS.status]}</span></SpaceBetween> : 'Unknown'
                      }
                      
                      
                    </SpaceBetween>
                    <SpaceBetween size="s" direction="horizontal">
                      <Box>Table :</Box>
                      {(processData.hasOwnProperty(PROCESS_KEYS.redshift_table_name) && processData[PROCESS_KEYS.redshift_table_name]) ? <Box>{processData[PROCESS_KEYS.redshift_table_name]}</Box> : ''
                      }
                    </SpaceBetween>
                  </SpaceBetween> : ''
                }


                {flashBarComments ? <Flashbar items={flashBarComments} /> : <></>}
                <ColumnLayout columns={2}>
                  <div>
                    <Box variant="h4">Process Name</Box>
                    {
                      isEditMode ?
                        <CustomInput field={PROCESS_KEYS.process_name} placeholder="Process name" updateValue={updateValue} getValue={getValue}></CustomInput>
                        : processData?.hasOwnProperty(PROCESS_KEYS.process_name) ? processData[PROCESS_KEYS.process_name] : ''
                    }
                  </div>
                  <div>
                    <Box variant="h4">Label - To display in the application page</Box>
                    {
                      isEditMode ?
                        <CustomInput field={PROCESS_KEYS.label} placeholder="label" updateValue={updateValue} getValue={getValue}></CustomInput>
                        : processData?.hasOwnProperty(PROCESS_KEYS.label) ? processData[PROCESS_KEYS.label] : ''
                    }
                  </div>
                  <div>
                    <Box variant="h4">Description</Box>
                    {
                      isEditMode ?
                        <Textarea
                          class="description"
                          onChange={({ detail }) => updateValue(PROCESS_KEYS.description, detail.value)}
                          value={getValue(PROCESS_KEYS.description)}
                          placeholder="Description"
                        />
                        : processData?.hasOwnProperty(PROCESS_KEYS.description) ? processData[PROCESS_KEYS.description] : ''
                    }
                  </div>

                  <div>
                    <RadioGroup
                      onChange={({ detail }) => {
                        processDataEdited[PROCESS_KEYS.environment] = detail.value
                        setProcessDataEdited({ ...processDataEdited })
                      }}
                      value={processDataEdited[PROCESS_KEYS.environment]}
                      items={[
                        { value: "Transfer_FAST_RedShift", label: "File load to FAST Redshift" },
                        { value: "Transfer_S3_Workdocs", label: "File transfer to workdocs/S3", disabled: true },
                        { value: "Extract_PDF_data", label: "Extract PDF Data", disabled: true }
                      ]}
                    />
                  </div>
                </ColumnLayout>
              </SpaceBetween>

              <SpaceBetween size="m">

                {(processDataEdited[PROCESS_KEYS.environment] == 'Transfer_FAST_RedShift' || processData[PROCESS_KEYS.environment] == 'Transfer_FAST_RedShift') ?
                  <><SpaceBetween>
                    <ColumnLayout columns={2}>
                      <div>
                        <Box variant="h4">Load type</Box>
                        {
                          isEditMode ? 
                            <CustomSelect field={PROCESS_KEYS.action} getValue={getValue} updateValue={updateValue} getOptions={getSelectFieldValues} />
                            : processData?.hasOwnProperty(PROCESS_KEYS.action) ? processData[PROCESS_KEYS.action] : ''
                        }
                        
                      </div>

                      <div>
                        <Box variant="h4">Load file type</Box>
                        {
                          isEditMode ?
                            <CustomMultiSelect field={PROCESS_KEYS.file_types} updateValue={updateValue} getValues={getSelectFieldValues} getSelectedOptions={getMultiSelectedOptions} />
                            : processData?.hasOwnProperty(PROCESS_KEYS.file_types) ?
                              <ul>
                                {processData[PROCESS_KEYS.file_types]?.map((item) => (
                                  <li key={item} className="list-item-data">
                                    {item}
                                  </li>
                                ))}
                              </ul>
                              : ''
                        }
                      </div>

                      {isEditMode ?
                        <>
                          <div>
                            <Box variant="h4">&nbsp;</Box>
                            <CustomCheckbox field={PROCESS_KEYS.multipart_allowed} getValue={getValue} updateValue={updateValue} text="Multi-part allowed" disabled={true} />
                            <SpaceBetween direction="horizontal" size="s">
                              <SpaceBetween direction="horizontal" size="s">
                              <CustomCheckbox field={PROCESS_KEYS.sox_approved} getValue={getValue} updateValue={updateValue} text="Enable 2 touch approval" disabled={!isNewProcess} />
                              <Button  variant="inline-link" onClick={()=>{setToolsOpen(!toolsOpen)}}>info</Button>
                              </SpaceBetween>
                              {
                                processDataEdited[PROCESS_KEYS.sox_approved]?
                                <Box>
                                <Textarea
                                  class="Notes"
                                  onChange={({ detail }) => updateValue(PROCESS_KEYS.sox_evidence, detail.value)}
                                  value={getValue(PROCESS_KEYS.sox_evidence)}
                                  placeholder="notes"
                                />
                              </Box>:''
                              }
                            </SpaceBetween>
                            <CustomCheckbox field={PROCESS_KEYS.approval_required} getValue={getValue} updateValue={updateValue} text="Approval required" disabled={isApprovalDisabled} />
                            <CustomCheckbox field={PROCESS_KEYS.critical_data} getValue={getValue} updateValue={updateValue} text="Critical data" disabled={!isNewProcess} /> 
                            <Link external href="https://policy.a2z.com/docs/97/publication">Click here to review Amazon policy on Data Classification Standard</Link>
                            <br/>
                            <br/>
                            {dataClasificationContent}
                          </div></> : <>

                          {dataClasificationContent}
                          </>}
                    </ColumnLayout>
                  </SpaceBetween>
                    <SpaceBetween size="l">
                      <div className="header_menu">
                        <div className="header_left">
                          <Box variant="h1">Dataset Details</Box>
                        </div>
                      </div>
                      <ColumnLayout columns={3}>
                        <div>
                          <SpaceBetween direction="vertical">
                            <Box variant="h4">Team</Box>
                            {
                              isEditMode ?
                                <CustomSelect field={PROCESS_KEYS.team} getValue={getValue} updateValue={updateValue} getOptions={getSelectFieldValues} />
                                :
                                processData.hasOwnProperty(PROCESS_KEYS.team) ? processData[PROCESS_KEYS.team] : '-'
                            }
                          </SpaceBetween>
                        </div>
                        <div>
                          <SpaceBetween direction="vertical">
                            <Box variant="h4">Business</Box>
                            {
                              isEditMode ?
                                <CustomSelect field={PROCESS_KEYS.business} getValue={getValue} updateValue={updateValue} getOptions={getSelectFieldValues} />
                                :
                                processData.hasOwnProperty(PROCESS_KEYS.business) ? processData[PROCESS_KEYS.business] : '-'
                            }
                          </SpaceBetween>
                        </div>
                        <div>
                          <SpaceBetween direction="vertical">
                            <Box variant="h4">Dataset name</Box>
                            {
                              isEditMode ?
                                <CustomInput field={PROCESS_KEYS.dataset_name} placeholder="Dataset name" updateValue={updateValue} getValue={getValue} required={true} mandatoryFieldListener={mandatoryFieldListener}></CustomInput>
                                :
                                processData.hasOwnProperty(PROCESS_KEYS.dataset_name) ? processData[PROCESS_KEYS.dataset_name] : '-'
                            }
                          </SpaceBetween>
                        </div>
                      </ColumnLayout>
                      <ColumnLayout columns={2}>
                        <div>
                          <SpaceBetween direction="vertical">
                            <div className="header_menu">
                              <div className="header_left">
                                <Box variant="h1">Uploader(s) Details</Box>
                              </div>
                            </div>
                            {
                              isEditMode ?
                                <CustomMultiHybridInputAndSelect field={PROCESS_KEYS.uploaders}
                                  inputKey={PROCESS_KEYS.UPLOADER_KEYS.alias} selectKey={PROCESS_KEYS.UPLOADER_KEYS.type} selectHint="Choose type"
                                  placeholder="alias" getValues={getValue} postProcess={postProcessTeamEmails} validator={approversValidation} mandatoryFieldListener={mandatoryFieldListener}
                                  updateValue={updateValue} getOptions={getSelectFieldValues} info=' ' errorMessage='type and alias input are mandatory' duplicatesNotAllowed={true} />
                                :
                                <div>
                                  {getValue(PROCESS_KEYS.uploaders) ? <Table
                                    columnDefinitions={[
                                      {
                                        id: "variable",
                                        header: "Type",
                                        cell: item => (item[PROCESS_KEYS.UPLOADER_KEYS.type] == UPLOADER_TYPES.UPLOADER ? 'USER' : 'LDAP') || "-",
                                        isRowHeader: true
                                      },
                                      {
                                        id: "alt",
                                        header: "Alias",
                                        cell: item => item[PROCESS_KEYS.UPLOADER_KEYS.alias] || "-",
                                        sortingField: "alt"
                                      }
                                    ]}
                                    items={getValue(PROCESS_KEYS.uploaders)}
                                    loadingText="Loading uploaders details"
                                    sortingDisabled
                                    empty={
                                      <Box
                                        margin={{ vertical: "xs" }}
                                        textAlign="center"
                                        color="inherit"
                                      >
                                        <SpaceBetween size="m">
                                          <b>No uploaders yet</b>
                                        </SpaceBetween>
                                      </Box>
                                    }
                                    variant='embedded'
                                  /> : ''}
                                </div>
                            }

                          </SpaceBetween>
                        </div>

                        {
                          (processData?.approval_required || processDataEdited?.approval_required) ?
                            <SpaceBetween>
                              <br></br>
                              <div>
                                <SpaceBetween direction="vertical">
                                  <div className="header_menu">
                                    <div className="header_left">
                                      <Box variant="h1">Approver(s) Details</Box>
                                    </div>
                                  </div>
                                  {
                                    isEditMode ?
                                      <CustomMultiHybridInputAndSelect field={PROCESS_KEYS.approvers}
                                        inputKey={PROCESS_KEYS.APPROVER_KEYS.alias} selectKey={PROCESS_KEYS.APPROVER_KEYS.type} selectHint="Choose type"
                                        placeholder="alias" getValues={getValue} postProcess={postProcessTeamEmails} validator={approversValidation} mandatoryFieldListener={mandatoryFieldListener}
                                        updateValue={updateValue} getOptions={getSelectFieldValues} info=' ' errorMessage='type and alias input are mandatory' duplicatesNotAllowed={true}
                                        needIndex={true} indexKey={PROCESS_KEYS.APPROVER_KEYS.column_index} indexPlaceHolder="Order of approval" defIndex={0}
                                        />
                                      :
                                      <div>
                                        {getValue(PROCESS_KEYS.approvers) ? <Table
                                          columnDefinitions={[
                                            {
                                              id: "variable",
                                              header: "Type",
                                              cell: item => (item[PROCESS_KEYS.APPROVER_KEYS.type] == APPROVER_TYPES.APPROVER ? 'USER' : 'LDAP') || "-",
                                              isRowHeader: true
                                            },
                                            {
                                              id: "alt",
                                              header: "Alias",
                                              cell: item => item[PROCESS_KEYS.APPROVER_KEYS.alias] || "-",
                                              sortingField: "alt"
                                            }
                                          ]}
                                          items={getValue(PROCESS_KEYS.approvers)}
                                          loadingText="Loading approver details"
                                          sortingDisabled
                                          empty={
                                            <Box
                                              margin={{ vertical: "xs" }}
                                              textAlign="center"
                                              color="inherit"
                                            >
                                              <SpaceBetween size="m">
                                                <b>No approvers yet</b>
                                              </SpaceBetween>
                                            </Box>
                                          }
                                          variant='embedded'
                                        /> : ''}
                                      </div>
                                  }

                                </SpaceBetween>
                              </div>

                            </SpaceBetween> : <></>
                        }
                      </ColumnLayout>
                      <ColumnLayout columns={isEditMode && isNewProcess ? 2 : 1}>
                        <div>
                          <ExpandableSection
                            variant="navigation"
                            defaultExpanded={true}
                            headerText="Schema - columns to validate on input"
                          >
                            {isEditMode  ?
                              (generatingSchemaLoader ? <SpaceBetween size="l"><Box></Box><Box textAlign="center"><Spinner> </Spinner> generating schema... </Box></SpaceBetween> :
                              <>
                                <CustomProcessSchemaInput field={PROCESS_KEYS.schema}
                                  inputKey={PROCESS_KEYS.SCHEMA_KEYS.column_name} selectKey={PROCESS_KEYS.SCHEMA_KEYS.data_type} radioKey={PROCESS_KEYS.SCHEMA_KEYS.mandatory}
                                  scaleKey={PROCESS_KEYS.SCHEMA_KEYS.scale} precisionKey={PROCESS_KEYS.SCHEMA_KEYS.precision} lengthKey={PROCESS_KEYS.SCHEMA_KEYS.length} lastUpdatedTimeKey={PROCESS_KEYS.SCHEMA_KEYS.last_updated_time_stamp}
                                  selectHint="Choose data type" radioText="Mandatory" radioKeyVisible={false} radioKeyDefault={true}
                                  placeholder="column name" getValues={getValue} validator={columnNameValidator}
                                  updateValue={updateValue} getOptions={getSelectFieldValues} info=' ' errorMessage='data type and column name cannot be empty'
                                  delsertKey = {PROCESS_KEYS.SCHEMA_KEYS.delsert_key} delsertRadioKeyVisible={processDataEdited[PROCESS_KEYS.action] === 'DELSERT'} 
                                  delsertRadioText="DELSERT KEY" delsertRadioKeyDefault={false}/>

                                  {(isEditMode && !isNewProcess ?
                                    <>
                                    <br></br>
                                      <Alert statusIconAriaLabel="Warning" type="warning">
                                      If there is schema update, such as data type change. It is possible to encounter failures during the process of copying data into the newly updated schema. 
                                      Our suggestion is to update the data types to accommodate the information in the source table.
                                       <p><strong>Do you want to clear the data in the table while changing the schema?</strong></p>
                                        <Checkbox
                                          onChange={({ detail }) =>
                                            setDeleteDataOnUpdate(detail.checked)
                                          }
                                          checked={deleteDataOnUpdate}
                                        >
                                          Delete existing data
                                        </Checkbox>

                                      </Alert>
                                    </> : <></>)
                                  }

                              </>
                                  
                              )

                              :
                              <div>
                                {getValue(PROCESS_KEYS.schema) ? <Table
                                  columnDefinitions={[
                                    {
                                      id: "alt",
                                      header: "Column name",
                                      cell: item => item[PROCESS_KEYS.SCHEMA_KEYS.column_name] || "-",
                                      sortingField: "alt"
                                    },
                                    {
                                      id: "variable",
                                      header: "Data type",
                                      cell: item => item[PROCESS_KEYS.SCHEMA_KEYS.data_type] || "-",
                                      isRowHeader: true
                                    },
                                    {
                                      id: "variable",
                                      header: "Length",
                                      cell: item => item[PROCESS_KEYS.SCHEMA_KEYS.length] || "-",
                                      isRowHeader: true
                                    },
                                    {
                                      id: "variable",
                                      header: "Scale",
                                      cell: item => item[PROCESS_KEYS.SCHEMA_KEYS.scale] || "-",
                                      isRowHeader: true
                                    },
                                    {
                                      id: "variable",
                                      header: "Precision",
                                      cell: item => item[PROCESS_KEYS.SCHEMA_KEYS.precision] || "-",
                                      isRowHeader: true
                                    },
                                    {
                                      id: "variable",
                                      header: "Last updated time",
                                      cell: item => item[PROCESS_KEYS.SCHEMA_KEYS.last_updated_time_stamp] || "-",
                                      isRowHeader: true
                                    }
                                  ]}
                                  items={getValue(PROCESS_KEYS.schema)}
                                  loadingText="Loading schema details"
                                  sortingDisabled
                                  empty={
                                    <Box
                                      margin={{ vertical: "xs" }}
                                      textAlign="center"
                                      color="inherit"
                                    >
                                      <SpaceBetween size="m">
                                        <b>No schema yet</b>
                                      </SpaceBetween>
                                    </Box>
                                  }
                                  variant='embedded'
                                /> : ''}
                              </div>

                            }

                          </ExpandableSection>

                        </div>

                        {
                          isEditMode && isNewProcess ?
                            <div>
                              <SpaceBetween size="m">
                                <Box variant="awsui-key-label">Or upload a sample file to generate the schema</Box>
                                <FileUpload
                                  onChange={({ detail }) => { setSampleFile(detail.value) }}
                                  value={sampleFile}
                                  i18nStrings={{
                                    uploadButtonText: e =>
                                      e ? "Choose files" : "Choose file",
                                    dropzoneText: e =>
                                      e
                                        ? "Drop files to generate"
                                        : "Drop file to generate",
                                    removeFileAriaLabel: e =>
                                      `Remove file ${e + 1}`,
                                    limitShowFewer: "Show fewer files",
                                    limitShowMore: "Show more files",
                                    errorIconAriaLabel: "Error"
                                  }}
                                  showFileLastModified
                                  showFileSize
                                  showFileThumbnail
                                  tokenLimit={1}
                                  accept={'.csv'}
                                  constraintText="upload a sample csv file"
                                />
                              </SpaceBetween>
                            </div> :
                            <></>
                        }

                      </ColumnLayout>
                      <Box>
                        {
                          processDataEdited[PROCESS_KEYS.action] === 'DELSERT' ?
                            <>
                              {(selectedDelsertKeys.length >= 1 && processDataEdited[PROCESS_KEYS.team] && processDataEdited[PROCESS_KEYS.business]
                               && processDataEdited[PROCESS_KEYS.dataset_name]) ?
                              <SpaceBetween size="m">
                                <Alert
                                  statusIconAriaLabel="Info"
                                  header="Selected DELSERT Keys"
                                >
                                  <ul>
                                    {selectedDelsertKeys.map((delsertKey) => (
                                      <li key={delsertKey}> {delsertKey}</li>
                                    ))}
                                  </ul>
                                </Alert>
                                  <ExpandableSection headerText="View generated DELSERT script">
                                    <CodeView
                                      content={`${delsertDeleteScript.join('\n')} \n\n\n ${delsertInsertScript.join('\n')}\n`}
                                      highlight={rubyHighlight}
                                      lineNumbers
                                    />
                                  </ExpandableSection>
                              </SpaceBetween> :
                                <Alert
                                  statusIconAriaLabel="Info"
                                  type="error"
                                >
                                  Team, Business, Dataset name, Schema and minimum of one DELSERT key is required for DELSERT operation.
                                </Alert>
                              }

                            </> :
                            <></>
                        }
                      </Box>
                    </SpaceBetween>
                  </>

                  : <></>}
              </SpaceBetween>

            </Container>
          </SpaceBetween>
          <SpaceBetween size="m">
            <Box>
              {errorMessage && (
                <Box variant="p" color="text-status-error">
                  {errorMessage}
                </Box>
              )}
            </Box>
            {
              (isNewProcess && canAdd) ?
                <div className="buttons">
                  <SpaceBetween direction="horizontal" size="l">
                    <Button variant="primary" onClick={(processDataEdited.hasOwnProperty(PROCESS_KEYS.action) && processDataEdited[PROCESS_KEYS.action] === 'DELSERT') ? () => delsertPopup('add') : addProcessDetails}
                    disabled={!enableEditAction} >Save</Button>
                  </SpaceBetween>
                </div>
                :
                ''
            }
            {
              !isNewProcess && isEditMode ?
                <SpaceBetween direction="vertical" size="l">
                  <Textarea 
                  class="description"
                  onChange={({ detail }) => setUpdateComments(detail.value)}
                  value={updateComments}
                  placeholder="Comments"
                  ></Textarea>
                  <div className="buttons">
                  <SpaceBetween direction="horizontal" size="l">
                    <Button variant="primary" onClick={(processDataEdited.hasOwnProperty(PROCESS_KEYS.action) && processDataEdited[PROCESS_KEYS.action] === 'DELSERT') ? () => delsertPopup('update') : updateProcessDetails} disabled={!enableEditAction} >Update</Button>

                    <Button onClick={cancelEdition}>Cancel</Button>
                  </SpaceBetween>
                </div>
                </SpaceBetween>
                :
                ''
            }
          </SpaceBetween>

          <br />
          {
            !isNewProcess && !isEditMode ?
              <SpaceBetween size="l" >
                <Feedback appId={id} pageId={`frisco_process_${id}_${pid}`} userId={userId} parentId={"null"} level={0} limit={1}></Feedback>
              </SpaceBetween> : ''
          }
        </ContentLayout>}
        <Modal
      onDismiss={() => setDelsertSubmitPopupVisible(false)}
      visible={delsertSubmitPopupVisible}
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" onClick={() => {setDelsertSubmitPopupVisible(false)}}>Go Back</Button>
            <Button variant="primary" onClick={()=>{
              
              delsertPopupCalledFrom === 'add' ? addProcessDetails() : updateProcessDetails();
              setDelsertSubmitPopupVisible(false);}}>Save</Button>
          </SpaceBetween>
        </Box>
      }
      header="Please Review"
    >
      <Box>
      All unique values from the specified key(s) will be used to replace data from the file to the target table.
      </Box>
        <Alert
          statusIconAriaLabel="Info"
          header="Selected DELSERT Keys"
        >
          <ul>
            {selectedDelsertKeys.map((delsertKey) => (
              <li key={delsertKey}> {delsertKey}</li>
            ))}
          </ul>
        </Alert>
    </Modal>
    </>
  )

  const helpContent = (
    <HelpPanel header={<Box variant="h2">Glossary</Box>}>
        <Box>
        <SpaceBetween direction="horizontal" size="s">
          {/* <Box variant="strong">2 touch approval</Box> */}
          {/* <Box>Check the box if the use case is 'Enable 2 touch approval' for the two touch approval workflow for SOX purposes or for other regulatory purposes (such as statutory disclosure generation or publicly published balances such as TTC).</Box> */}
          <h4>2 touch approval required if</h4>
          <ul>
            <li>a) Ledger Impact = Primary Ledger (US GAAP) OR Secondary Ledger (Local GAAP) OR Both Primary and Secondary Ledger (US GAAP/Local GAAP/IFRS) AND</li>
            <li>b) Financial Impact &gt; $500MM</li>
          </ul>
          <h4>2 touch approval NOT required if</h4>
          <ul>
            <li>a) Ledger Impact = Other regulations OR Operational AND</li>
            <li>b) Financial Impact &lt; $500MM</li>
          </ul>
        </SpaceBetween>
        </Box>
    </HelpPanel>
);


  return (
    <AppLayout
      disableContentPaddings={true}
      content={Content}
      navigation={<SideNav activeHref={`/App/${id}/process/${pid}`} id={id} pid={pid} process={processData} back={backUrl}/>}
      contentType="default"
      navigationOpen={NavOpen}
      onNavigationChange={() => {
        setNavOpen(!NavOpen);
      }}
      tools={helpContent}
      toolsOpen={toolsOpen}
      onToolsChange={() => { setToolsOpen(!toolsOpen) }}
      headerVariant="high-contrast"
    />
  );
};