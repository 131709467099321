import React, { useEffect } from 'react';
import { AuthContextProvider, useAuth } from './context/AuthContextProvider';
import { ErrorBoundary } from 'react-error-boundary';

import { LoadingStatus } from './context/AppContextModels';
import { ErrorFallback } from './generic-components/ErrorFallback';

import { AppRouter } from './AppRouter';
import { BrowserRouter} from 'react-router-dom'


export const App: React.FC = () => {
    return (
        <>
            <ErrorBoundary
                FallbackComponent={ErrorFallback}
                onReset={() => {
                    window.location.reload();
                }}
            >
                <BrowserRouter>
                <AuthContextProvider>
                    <AppRouter />
                </AuthContextProvider>
            </BrowserRouter>
            </ErrorBoundary>
        </>
    );

}

export default App;
