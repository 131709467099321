import React, { useState, useEffect, useRef, useCallback} from 'react'
import { useSelector , useDispatch} from 'react-redux'

import {  AppLayout, HelpPanel,  Box, SpaceBetween, Header, Button, BreadcrumbGroup,
            Modal,  Container, Icon, Spinner, Tabs, ExpandableSection,
            Grid, Select, ColumnLayout
        } from '@amzn/awsui-components-react';
import SideNav from './SideNav';
import appSettings from './app_settings';
import { Link } from 'react-router-dom'

import { useHistory , useLocation} from "react-router-dom";
import checkPageAccess from '../../checkPageAccess';
import AccessDenied from '../../AccessDenied'
import WaitForPageAccess from '../../WaitForPageAccess'

import { setCurrentAppId } from '../../store/userAuthSlice';

import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

import { AgGridReact } from 'ag-grid-react';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

import {  months, years, uploadDatasets, teamNames, loadTypes, dsColDefs } from './Constants'
import * as XLSX from 'xlsx';

import { API, graphqlOperation } from 'aws-amplify';
import  { Storage } from 'aws-amplify';
import {Board, BoardItem} from "@amzn/awsui-board-components";
import { boardI18nStrings, boardItemI18nStrings } from 'src/i18n-strings/board-I18nStrings';


const InputsHistory = () => {
    const dispatch = useDispatch()
    

    const history = useHistory();
    const userId  = useSelector((globalState)=>globalState.auth.userId)
    const gridRef = useRef();

    const currentAppId = useSelector((globalState)=>globalState.auth.currentAppId)
    const isCurrentAppAuthorised = useSelector((globalState)=>globalState.auth.isCurrentAppAuthorized)
    const [isCheckingAccess, setIsCheckingAccess] = useState(true);
    const [isAuthorized, setIsAuthorized] = useState();
    const [isLoading, setIsLoading] = useState(false);
    
    const [isFileSelected, setIsFileSelected] = useState(false);
    const [showFileNotSeleted, setShowFileNotSeleted] = useState(false);
    const [fileName, setFileName] = useState('');
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [columnsList, setColumnsList] =useState([])

    
    const [items, setItems] = useState([]);
    const [processingStatus, setProcessingStatus] = useState('info');
    const [currentStatusId, setCurrentStatusId] = useState('');
    
    const [statusUpdate, setStatusUpdate] = useState('No status update at the moment.');
    
    const [showError, setShowError] = useState(false);
    const [showLoadingIcon, setShowLoadingIcon] = useState(true);

   

    const [selectedTeamName, setSelectedTeamName] = useState({ label :"AWS-EMEA" , value :"AWS-EMEA"} );
    const [selectedMonth, setSelectedMonth] = useState({});
    const [selectedYear, setSelectedYear] = useState({});
    const [selectedDataset, setSelectedDataset] = useState({ label: "Additional-Dataset", value: "ADDITIONAL_DATASET"});
    
    const [selectedLoadType, setSelectedLoadType] = useState({ label: "Delete previous & Add New Data ", value: "TRIN"});
    
    const [selectedColDefs, setSelectedColDefs] = useState(dsColDefs[0].QUEUE_MANUAL);
    
    const [selectedFileName, setSelectedFileName] = useState('');
    const [selectedFileJson, setSelectedFileJson] = useState([]);
    const [selectedFileCSV, setSelectedFileCSV] = useState(null);
    

    const [toolsHide, setToolsHide] = useState(false);
    const [activeTabId, setActiveTabId] = useState('New');
    const [activeStep, setActiveStep] = useState('first');
    
    const [isUploading, setIsUploading] = useState(false);
    const [showUploadingModal, setShowUploadingModal] = useState(false);
    const [uploadStatus, setUploadStatus] = useState('');
    const [isUploadingCompleted, setIsUploadingCompleted] = useState(false);
    const [viewUploadHistory, setViewUploadHistory] = useState(true);
    
    const [selectedItem, setSelectedItem] = useState({});
    const [boardItems, setBoardItems] = useState([
      { id: "1", rowSpan: 5, columnSpan: 6,data:{}}
    ]);
    
  useEffect(() => {
    if (currentAppId !== appSettings.appId){
      dispatch(setCurrentAppId(appSettings.appId))
    }
    setIsAuthorized(isCurrentAppAuthorised)

    let mecMonth = moment().subtract(1, 'months').format('MMM').toUpperCase();
    let mecYear = moment().subtract(1, 'months').format('YY');
    
    setSelectedMonth({ label: mecMonth, value: mecMonth, disabled: false })
    setSelectedYear({ label: mecYear, value: mecYear, disabled: false })


    loadData()

    //   Subscribe here
    var mQry = `
    subscription MySubscription {
        onUpdateUI4BI_BoffinUploadFiles {
        fileId
        processingStatus
        }
    }
    `
    
    const subscription = API.graphql(graphqlOperation(mQry)).subscribe({
      next: ({ provider, value }) => {
          // console.log({ provider, value })
          var currentStatusId = value.data.onUpdateUI4BI_BoffinUploadFiles.fileId
          var currentStatus = value.data.onUpdateUI4BI_BoffinUploadFiles.processingStatus
          var mStr = `
              {
                  id      : ` + currentStatusId + `
                  status  : ` + currentStatus + `
              }
          `
          console.log(mStr)
          setCurrentStatusId(currentStatusId)
          setStatusUpdate(currentStatus)
          if (currentStatus==='Completed'){
              setProcessingStatus('success')
            }
          // var newStatus = value.data.onUpdateUI4BI_BoffinComputeInfo.processStatus
          // console.log(newStatus)
      },
      error: (error) => console.warn(error)
  });


    return () => {
      // this now gets called when the component unmounts
      subscription.unsubscribe()
    };
    
    
    },[statusUpdate, isCurrentAppAuthorised]);

    useEffect(() => {
      if(isAuthorized === true || isAuthorized === false){
        setIsCheckingAccess(!isCheckingAccess);
      } 
    }, [isAuthorized]);
    
    const defaultColDef = {
      // set filtering on for all columns
      filter: true,
      filterParams: {
          buttons: ['reset'],
          debounceMs: 200
      }
    };
  
    const cancelClick = () =>{
      history.push('/BoffinHome')
    }

   
    const onFollowHandler = (ev) => {
        ev.preventDefault();
        if (ev.detail.href) {
            history.push(ev.detail.href.substring(1));
        }
    }
    
    const breadItems = [
        { text: 'Home', href: '/Home' },
        { text: 'ATeam BI', href: '/ATeamBIHome' },
        { text: 'Boffin', href: '/BoffinHome' },
        { text: 'Inputs', href: '/BoffinInputs' },
        { text: 'History', href: '' },


    
      ];
    
    
   
    
      const loadData = async () =>{
        setShowLoadingIcon(true)

        var mQry = `query MyQuery {
          listUI4BI_BoffinUploadFiles(limit: 1000) {
            items {
              fileId
              fileName
              loadType
              datasetYear
              datasetName
              datasetMonth
              createDate
              processingDetails
              processingStatus
              teamName
              userFileName
              userId
            }
          }
        }
        
        `
            // console.log(mQry)
            let response = await API.graphql(graphqlOperation(mQry));
            let qResultJSON = response.data.listUI4BI_BoffinUploadFiles.items
            qResultJSON.sort(function (a, b) {
                var dateA = new Date(a.createDate), dateB = new Date(b.createDate);
                return dateB - dateA;
            });
            setItems(qResultJSON)
            setShowLoadingIcon(false)
           
           
    }
      
      
      
    const processIcon = props =>(
      props.data.processingStatus === 'Completed' ?
          <Box>
              <Icon name='status-positive' variant='success' ></Icon>
              &nbsp; Completed
          </Box>
          
      :  <Box>
              <Icon name='status-in-progress' variant='normal' ></Icon>
              &nbsp; {props.data.processingStatus}
          </Box>

      
  )
  const onSelectionChanged= () =>{
    const selectedRows = gridRef.current.api.getSelectedRows();
    setSelectedItem(selectedRows[0])

  }

  const downloadFile = async () =>{
    
    
    if (selectedItem.fileId)
    {
      let folder = 'Boffin/uploads/completed/'
      let fn = folder + selectedItem.fileId + '.csv'
      const result = await Storage.get(fn, { level: 'public', expires: 60});
      window.open(result);
      
    }
    
    
  }
    
    const [columnDefs] = useState([
      { field: 'datasetName' ,  resizable: true },
      { field: 'createDate' , width: '230px',  sortable: true, resizable: true},
      { field: 'userId' , width: '130px', sortable: true, resizable: true },
      { field: 'processingStatus' ,  sortable: true, cellRenderer:processIcon, resizable: true},
      { field: 'processingDetails' ,width: '350px',  sortable: true, resizable: true},
      { field: 'teamName' ,  sortable: true, resizable: true},
      { field: 'loadType' ,  sortable: true, resizable: true},
      { field: 'userFileName' ,  sortable: true, resizable: true},
      { field: 'datasetMonth' ,  resizable: true},
      { field: 'datasetYear' ,  resizable: true},
      { field: 'fileId' ,  resizable: true},
      { field: '' }


    ])
      
   
    const content = (
      <div>
        {isAuthorized ? (
          <Box>
            {viewUploadHistory ? (
              <Box>
                <Container
                  header={
                    <Header
                      variant="h2"
                      actions={
                        <SpaceBetween direction="horizontal" size="xs">
                          <Button
                            variant="normal"
                            iconName="refresh"
                            onClick={loadData}
                          ></Button>
                          <Button
                            variant="normal"
                            iconName="close"
                            onClick={() => {
                              history.push("/BoffinInputs");
                            }}
                          ></Button>
                        </SpaceBetween>
                      }
                      description="The following table shows all the files uploaded."
                    >
                      File Uploads
                      {showLoadingIcon ? (
                        <Spinner size="normal"></Spinner>
                      ) : (
                        <div></div>
                      )}
                    </Header>
                  }
                >
                  <Board
                    renderItem={(item) => (
                      <BoardItem i18nStrings={boardItemI18nStrings}>
                        <div
                          className="ag-theme-alpine"
                          style={{ height: "92%", width: "100%" }}
                        >
                          <AgGridReact
                            ref={gridRef}
                            rowData={items}
                            defaultColDef={defaultColDef}
                            columnDefs={columnDefs}
                            rowSelection="single"
                            animateRows={true}
                            rowHeight={30}
                            enableCellTextSelection={true}
                            onSelectionChanged={onSelectionChanged}
                          ></AgGridReact>
                        </div>
                      </BoardItem>
                    )}
                    onItemsChange={(event) => setBoardItems(event.detail.items)}
                    items={boardItems}
                    i18nStrings={boardI18nStrings}
                  />
                </Container>
                <Container header="Details">
                  <ColumnLayout columns={3}>
                    <Box>
                      <Box variant="awsui-key-label">File id</Box>
                      <Box>{selectedItem.fileId}</Box>
                    </Box>
                    <Box>
                      <Box variant="awsui-key-label">User File Name</Box>
                      <Box>{selectedItem.userFileName}</Box>
                    </Box>
                    <Box>
                      <Box variant="awsui-key-label">Action</Box>
                      {selectedItem.fileId ? (
                        <Box>
                          <Button
                            variant="normal"
                            iconName="download"
                            onClick={() => {
                              downloadFile();
                            }}
                          >
                            Download
                          </Button>
                        </Box>
                      ) : (
                        <Box>Select item from above table.</Box>
                      )}
                    </Box>
                  </ColumnLayout>
                </Container>
              </Box>
            ) : (
              <div></div>
            )}
          </Box>
        ) : isCheckingAccess ? (
          <div>
            <WaitForPageAccess></WaitForPageAccess>
          </div>
        ) : (
          <div>
            <AccessDenied appName={appSettings.appName}></AccessDenied>
          </div>
        )}
      </div>
    );
    
    const helpContent = (
        <HelpPanel
            
            header={<div>
                <div>
    
                </div>
                <h2>Additional Info </h2>
                
               
            </div>}
        >
            
            <Box>
              <strong>LoadType</strong>
              <ol>
                <li>APPEND = Add data to existing table</li>
                <li>TRIN = Truncate & Insert</li>
              </ol>
              <br></br>
              
                  
            </Box>
        </HelpPanel>
      )
    
  return (
    <AppLayout
        breadcrumbs={<BreadcrumbGroup items={breadItems} onFollow={(event)=>onFollowHandler(event)}></BreadcrumbGroup>}
        content={content}
        navigation={<SideNav activeHref="#/BoffinInputs" />}
        headerSelector="#TopBar"
        navigationHide={false}
        disableBodyScroll={true}
        toolsOpen={toolsHide}
        onToolsChange={()=>{setToolsHide(!toolsHide)}}
        toolsWidth={350}
        tools={helpContent}
        maxContentWidth = {Number.MAX_VALUE}
        headerVariant="high-contrast"
  ></AppLayout>
  )
}



export default InputsHistory
