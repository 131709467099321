import React, { useEffect } from 'react';
import {TopNavigation} from '@amzn/awsui-components-react/polaris';
import logo from "./images/amz_logo_white_small.png"
import { useAuth } from './context/AuthContextProvider';
import { Box } from '@amzn/awsui-components-react';
import { useSelector, useDispatch } from 'react-redux'
import { setCurrentAppAuthorization, setCurrentUserId, setCurrentFullName, setCurrentEmail, setIsPortalAdmin, setLoginRedirectPath } from './store/userAuthSlice'
import checkPageAccess, { checkGeneralAccess } from './checkPageAccess'

import {checkIfPortalAdmin} from './checkPageAccess'
import {useHistory} from 'react-router-dom'
import appSettings from './Ateam-BI/CorpReporting/app_settings';


function TopNav() {  

  const userAuthenticationDetails = useAuth();

  const userLDAPS = userAuthenticationDetails.USER_LDAP_GROUPS;
  const userId  = userAuthenticationDetails.Alias;
  const userEmail  = userAuthenticationDetails.Email;
  const userGivenName = userAuthenticationDetails.DisplayName;
  const dispatch = useDispatch();
  const currentAppId = useSelector((globalState)=>globalState.auth.currentAppId)
  const currentAppPage = useSelector((globalState)=>globalState.auth.currentAppPage)
  const currentStage = useSelector((globalState)=>globalState.auth.currentStage)
  const isPortalAdmin = useSelector((globalState)=>globalState.auth.isPortalAdmin)
  const currentUser = useSelector((globalState)=>globalState.auth.userId)
  const loginRedirectPath = useSelector((globalState)=>globalState.auth.loginRedirectPath)
  const history = useHistory()

  
  useEffect(() => {
    dispatch(setCurrentUserId(userId))
    dispatch(setCurrentFullName(userGivenName))
    dispatch(setCurrentEmail(userEmail))
    if(loginRedirectPath && userId) {
      const path = loginRedirectPath;
      dispatch(setLoginRedirectPath(null))
      history.push(path)
    }
  },[]);

  useEffect(() => {
    async function checkPortalAdminUser() {
      dispatch(setIsPortalAdmin(false))
      const result = await checkIfPortalAdmin(userId, currentStage);
      dispatch(setIsPortalAdmin(result))
    }
    
      checkPortalAdminUser()
    
    
  }, [currentUser, currentStage]);

  useEffect(() => {
    
    async function checkUserAuthorizedForApp (){

      if(currentAppId && userId && currentStage) {

      dispatch(setCurrentAppAuthorization(null))
      const params = {userAlias: userId,
         id: currentAppId,
         stage: currentStage
        }
      const result = await checkGeneralAccess(params);
      dispatch(setCurrentAppAuthorization(result))

    }
  }
    
      if(isPortalAdmin) {
        dispatch(setCurrentAppAuthorization(true))
      } else {
          checkUserAuthorizedForApp()
      }
    return () => {
      // this now gets called when the component unmounts
    };
  }, [currentAppId, currentStage, isPortalAdmin]);
  
    return (
      <div id='h'>
        <TopNavigation
        identity={{
          href: currentAppId==appSettings[currentStage]?.appId? "/CorpReportingHome" : "/",
          title: currentAppId==appSettings[currentStage]?.appId? "Corporate Accounting Central" : "Accounting Central",
          logo: {
            src:
              `${logo}`,
            alt: currentAppId==appSettings[currentStage]?.appId? "Corporate Accounting Central" : "Accounting Central",
          }
        }}
        utilities={[
          {
            type: "button",
            text: "Contact us",
            href: "https://t.corp.amazon.com/create/templates/9c41d7b9-b693-433e-950a-ec29fcfce9f5",
            external: true,
            externalIconAriaLabel: " (opens in a new tab)"
          },
          {
            type: "menu-dropdown",
            text: `${userGivenName}`,
            description: `${userEmail}`,
            iconName: "user-profile",
            items: []
          },
          {
          
            type:"button",
            text: (
              
            <img
                alt=""
                className="rounded-circle"
                src={'https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=' + userId}
                width={35}
                height={35}
            />          
            ),
             href: 'https://phonetool.amazon.com/users/' + userId,
          },
          {
            type:"button",
            text:(<Box color='text-status-info'>{currentStage === 'prod' ? '' : currentStage}</Box> )
          },
        ]}
        i18nStrings={{
          searchIconAriaLabel: "Search",
          searchDismissIconAriaLabel: "Close search",
          overflowMenuTriggerText: "More",
          overflowMenuTitleText: "All",
          overflowMenuBackIconAriaLabel: "Back",
          overflowMenuDismissIconAriaLabel: "Close menu"
        }}
      /> 
    </div>
    );
}

export default TopNav;
