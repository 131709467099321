import { isMemberOf } from "src/checkPageAccess";
import { APPROVER_TYPES, PROCESS_KEYS, UPLOADER_TYPES } from "./Constants";

export const isUploaderAllowed = async(processData, userId, currentStage) => {
    let uploaderList = processData[PROCESS_KEYS.uploaders]
    let result = false
    if(uploaderList && uploaderList?.length>0){
        for(let i=0;i<uploaderList?.length;i++){
            if(uploaderList[i]?.type == UPLOADER_TYPES.UPLOADER && uploaderList[i]?.alias == userId){
                result = true
                break;
            }else if(uploaderList[i]?.type == UPLOADER_TYPES.UPLOADER_LDAP && uploaderList[i]?.alias){
                let resp = await isMemberOf(userId, uploaderList[i]?.alias, currentStage)
                if(resp){
                    result = resp
                    break;
                }
            }
        }
    }
    return result
}

export const isApprover = async(processData, userId, currentStage) => {
    let approverList = processData[PROCESS_KEYS.approvers]
    let result = false
    if(approverList && approverList?.length>0){
        for(let i=0;i<approverList?.length;i++){
            if(approverList[i]?.type == APPROVER_TYPES.APPROVER && approverList[i]?.alias == userId){
                result = true
                break;
            }else if(approverList[i]?.type == APPROVER_TYPES.APPROVER_LDAP && approverList[i]?.alias){
                let resp = await isMemberOf(userId, approverList[i]?.alias, currentStage)
                if(resp){
                    result = resp
                    break;
                }
            }
        }
    }
    return result
}