import React, { useState, useEffect } from "react";

import SideNavigation from "@amzn/awsui-components-react/polaris/side-navigation";
import { useHistory, useLocation } from "react-router-dom";
import { PROCESS_KEYS } from "./Constants";

export default (props) => {
  const [activeHref, setActiveHref] = useState(props.activeHref);
  const history = useHistory();
  const [items, setItems] = useState([
    {
      "type": "link",
      "text": "Back",
      "href": props.back?`/${props.back}`:`/App/${props.id}`
    }
  ])
  const [name, setName] = useState('')
  const [href, setHref] = useState('')
  useEffect(() => {
    if (props?.process?.hasOwnProperty(PROCESS_KEYS.process_name)) {
      setName(props.process[PROCESS_KEYS.process_name])
    }
      let hrefPrefix = `/App/${props.id}/process/${props.pid}`
      setHref(hrefPrefix)
      let items = [
        {
          "type": "link",
          "text": "History",
          "href": props.back?`${hrefPrefix}/history?back=${props.back}`:`${hrefPrefix}/history`
        }, 
        {
          "type": "link",
          "text": "Upload",
          "href": props.back?`${hrefPrefix}/upload?back=${props.back}`:`${hrefPrefix}/upload`
        },
        {
          "type": "link",
          "text": "Details",
          "href":  props.back?`${hrefPrefix}?back=${props.back}`:`${hrefPrefix}`
        },
        {
          "type": "link",
          "text": "Process Audit logs",
          "href":  props.back?`${hrefPrefix}/FriscoProcessAuditLogs?back=${props.back}`:`${hrefPrefix}/FriscoProcessAuditLogs`
        },
        { type: "divider" },
        {
          "type": "link",
          "text": "Back",
          "href": props.back?`/${props.back}`:`/App/${props.id}`
        },
      ];
      setItems(items)


  }, [props])
  return (
    <SideNavigation
      activeHref={activeHref}
      header={{ href: href, text: name }}
      onFollow={e => {
        e.stopPropagation();
        e.preventDefault();
        history.push(e.detail.href);
        setActiveHref(e.detail.href)
      }}
      items={items}
    />
  );
}