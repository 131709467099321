import React, { useState, useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";

import moment from "moment";
import {
  AppLayout,
  Box,
  BreadcrumbGroup,
  HelpPanel,
  Icon,
  Alert,
  ColumnLayout,
  FormField,
  Input,
  Button,
  Tabs,
  Grid,
  Multiselect,
  SpaceBetween,
  Select,
  RadioGroup,
  Checkbox,
  Container,
  Header,
  Modal,
  ExpandableSection,
  Flashbar,
  ProgressBar,
} from "@amzn/awsui-components-react";

import {
  months,
  years,
  reclassOptions,
  accrualsOptions,
  Datasets,
  teamNames,
  ledgerNames,
  defaultDatasets,
  defaultAccounts,
  filterOptions,
  AWSoptions,
} from "./Constants";

import { useHistory } from "react-router-dom";

import AccessDenied from "../../AccessDenied";
import WaitForPageAccess from "../../WaitForPageAccess";

import SideNav from "./SideNav";
import appSettings from "./app_settings";
import { setCurrentAppId } from "../../store/userAuthSlice";

import { API, graphqlOperation } from "aws-amplify";
import { Storage } from "aws-amplify";
import {getBoffinComputesWithStartedStatus} from './BoffinAPIs'


const BoffinCompute = () => {
  const userId = useSelector((globalState) => globalState.auth.userId);
  const userFN = useSelector((globalState) => globalState.auth.userFN);
  const currentStage = useSelector(
    (globalState) => globalState.auth.currentStage
  );

  const history = useHistory();
  const currentAppId = useSelector(
    (globalState) => globalState.auth.currentAppId
  );
  const isCurrentAppAuthorised = useSelector(
    (globalState) => globalState.auth.isCurrentAppAuthorized
  );
  const [isCheckingAccess, setIsCheckingAccess] = useState(true);
  const [isAuthorized, setIsAuthorized] = useState();

  const dispatch = useDispatch();

  // form constants here
  const [activeTab, setActiveTab] = useState("first");

  const [selectedFilterOption, setSelectedFilterOption] =
    useState("filterOption1");
  const [selectedTeamName, setSelectedTeamName] = useState({
    label: "AWS-EMEA",
    value: "AWS-EMEA",
  });
  const [selectedLedgerNames, setSelectedLedgerNames] = useState([]);
  const [selectedAwsOption, setSelectedAwsOption] = useState({
    label: "Non-AWS-Only",
  });
  const [selectedCompanyCodes, setSelectedCompanyCodes] = useState("");
  const [selectedAccounts, setSelectedAccounts] = useState(defaultAccounts);
  const [selectedDatasets, setSelectedDatasets] = useState(defaultDatasets);
  const [isReclassSelected, setIsReclassSelected] = useState(true);
  const [isAccrualSelected, setIsAccrualSelected] = useState(true);

  const [selectedMonth, setSelectedMonth] = useState({});
  const [selectedYear, setSelectedYear] = useState({});

  const [selectedReclassOption, setSelectedReclassOption] =
    useState("ReclassOption1");
  const [selectedAccrualOption, setSelectedAccrualOption] =
    useState("AccrualsOption1");

  const [showAlert, setShowAlert] = useState(false);
  const [alertMsg, setalertMsg] = useState();
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);
  const [newId, setNewId] = useState("");
  const [showProcessing, setShowProcessing] = useState(false);
  const [processingStatus, setProcessingStatus] = useState("info");

  const [emailNotificationReq, setEmailNotificationReq] = useState(true);
  const [computeInprogressModal, setComputeInprogressModal] = useState(false)
  const [statusUpdate, setStatusUpdate] = useState(
    "Click submit to start processing"
  );
  const [percentageCompleted, setPercentageCompleted] = useState(0);

  useEffect(() => {
    if (currentAppId !== appSettings.appId) {
      dispatch(setCurrentAppId(appSettings.appId));
    }
    setIsAuthorized(isCurrentAppAuthorised);

    let mecMonth = moment().subtract(1, "months").format("MMM").toUpperCase();
    let mecYear = moment().subtract(1, "months").format("YY");

    setSelectedMonth({ label: mecMonth, value: mecMonth, disabled: false });
    setSelectedYear({ label: mecYear, value: mecYear, disabled: false });

    return () => {
      // this now gets called when the component unmounts
    };
  }, [isCurrentAppAuthorised]);

  useEffect(() => {
    if(isAuthorized === true || isAuthorized === false){
      setIsCheckingAccess(!isCheckingAccess);
    } 
  }, [isAuthorized]);

  const breadItems = [
    { text: "Home", href: "/Home" },
    { text: "ATeam BI", href: "/ATeamBIHome" },
    { text: "Boffin", href: "/BoffinHome" },
    { text: "Compute", href: "" },
  ];

  const updateSelectedCompanyCodes = (detail) => {
    var mTxt = detail;
    mTxt = mTxt
      .replace('"', "")
      .replace("`", "")
      .replace("'", "")
      .replace(" ", "")
      .toUpperCase();
    setSelectedCompanyCodes(mTxt);
  };

  const onFollowHandler = (ev) => {
    ev.preventDefault();
    if (ev.detail.href) {
      history.push(ev.detail.href.substring(1));
    }
  };

  const helpContent = (
    <HelpPanel header={<Box variant="h2">Additional Info</Box>}>
      <Box>
        <Box variant="awsui-key-label">Checklist</Box>
        <ul>
          <li>Team Name / Company Code Mapping is updated</li>
          <li>Review QuickSight - Datasets Tab</li>
          <li>Review QuickSight - CIP Tracker</li>
          <li>Review QuickSight - TRIRIGA report</li>
          <li>Review Additional Datasets (if any)</li>
        </ul>
      </Box>
    </HelpPanel>
  );

  const validationChecks = () => {};

  const onCancelClick = () => {
    history.push("/BoffinHome");
  };

  const onSubmitClick = async () => {
    // Lets validate now
    setShowAlert(false);
    setalertMsg("-");

    if (selectedFilterOption === "filterOption1" && selectedTeamName === "") {
      setShowAlert(true);
      setalertMsg("Specify Team Name");
      setActiveTab("first");
      return;
    }

    let ledgers = [];
    selectedLedgerNames.forEach((d) => ledgers.push(`'` + d.value + `'`));

    if (
      selectedFilterOption === "filterOption2" &&
      selectedLedgerNames.length === 0
    ) {
      setShowAlert(true);
      setalertMsg("Specify Ledger Name");
      setActiveTab("first");
      return;
    }

    let accounts = [];
    selectedAccounts.forEach((d) => accounts.push(d.value));

    if (accounts.length === 0) {
      setShowAlert(true);
      setalertMsg("Specify Accounts to compute");
      setActiveTab("second");
      return;
    }

    let jeTypes = [];
    if (isReclassSelected) {
      jeTypes.push("RECLASS");
    }
    if (isAccrualSelected) {
      jeTypes.push("ACCRUAL");
    }

    if (jeTypes.length == 0) {
      setShowAlert(true);
      setalertMsg("JE Type not selected");
      setActiveTab("third");
      return;
    }

    let ds = [];
    selectedDatasets.forEach((d) => ds.push(d.value));

    if (ds.length == 0) {
      setShowAlert(true);
      setalertMsg("Specify Datasets compute");
      setActiveTab("second");
      return;
    }

    let res = await getBoffinComputesWithStartedStatus();

    if(res.length > 0) {
      setComputeInprogressModal(true)
      return;
    }

    // validations are now completed
    setDisableSubmitButton(true);
    setShowProcessing(true);
    setStatusUpdate("Sending info to server...");
    setShowAlert(false);
    setalertMsg("-");

    let dt = moment().format();
    let mTName = "";
    if (selectedFilterOption == "filterOption1") {
      mTName = selectedTeamName.label;
    }
    let mLed = "";
    let mAWSopt = "";
    if (selectedFilterOption == "filterOption2") {
      mLed = ledgers;
      mAWSopt = selectedAwsOption.label;
    }
    let mCoCo = "";
    if (selectedFilterOption == "filterOption3") {
      mCoCo = selectedCompanyCodes;
    }

   


    let mQry1 =
      `
              mutation createEntry {
                createUI4BI_BoffinComputeInfo(input: {         
                      teamName        : "` +
      mTName +
      `", 
                      createDate      : "` +
      dt +
      `", 
                      userId          : "` +
      userId +
      `",
                      processRow      : "Yes", 
                      processStatus   : "Pending", 
                      pre_signed_url  : "Pending",
                      periodMonth     : "` +
      selectedMonth.value +
      `",
                      periodYear      : "` +
      selectedYear.value +
      `",
                      jeTypes         : "` +
      jeTypes +
      `",
                      accounts        : "` +
      accounts +
      `",
                      datasets        : "` +
      ds +
      `",
                      recompute       : "No",
                      isReclassSelected    : "` +
      isReclassSelected +
      `",
                      isAccrualSelected    : "` +
      isAccrualSelected +
      `",
                      filterOption    : "` +
      selectedFilterOption +
      `",
                      reclassOption   : "` +
      selectedReclassOption +
      `",
                      accrualOption   : "` +
      selectedAccrualOption +
      `",
                      showPostingButton: "Yes",
                      isApproved: "No",
                      postingStatus: "Not Initiated",
                      selectedLedgers : "` +
      mLed +
      `",                      
                      selectedCompanyCodes        : "` +
      mCoCo +
      `",
                      selectedAWSoption           : "` +
      mAWSopt +
      `",
                      userName          : "` +
      userFN +
      `",
                      emailNotification : "` +
      emailNotificationReq +
      `",
                      percentageCompleted:"0"

                  }
                      ) {
                    id
                  }
                }

              `;
    // console.log(mQry1)

    const data = await API.graphql(graphqlOperation(mQry1)).then((result) => {
      //  lets drop the file into s3 bucket
      let newId = result.data.createUI4BI_BoffinComputeInfo.id;
      setNewId(newId);
      let folder = "contico_lambda_trigger/pending/";
      let fileName = folder + newId + ".json";
      let mTxt =
        `
                      {
                        "processName": "boffin",
                        "scriptName": "ComputeNew",
                        "parameters": {"computeId": "` +
        newId +
        `"}
                      }
                      `;

      Storage.put(fileName, mTxt, {
        tagging: "userId=" + userId,
        level: "public",
      })
        .then((result) => {
          setStatusUpdate("Processing is about to start");
          // console.log(result)
        })
        .catch((e) => {
          console.log(e);
        });

      let mQry3 =
        `
          subscription MySubscription {
            onUpdateUI4BI_BoffinComputeInfo(id: "` +
        newId +
        `") {
              id
              processStatus
              percentageCompleted
            }
          }
          `;
      // console.log(mQry3)
      API.graphql(graphqlOperation(mQry3)).subscribe({
        next: ({ provider, value }) => {
          var currentStatus =
            value.data.onUpdateUI4BI_BoffinComputeInfo.processStatus;
          var pc = parseInt(
            value.data.onUpdateUI4BI_BoffinComputeInfo.percentageCompleted
          );

          setShowProcessing(true);
          setStatusUpdate(currentStatus);
          setPercentageCompleted(pc);
          if (currentStatus === "Processing has completed") {
            setProcessingStatus("success");
          }
        },
        error: (error) => console.warn(error),
      });
    });
  };

  const Accounts = [
    { label: "16960", value: "16960", descriiption: "Account desc.." },
    {
      label: "16999",
      value: "16999",
      descriiption: "The Account desc is very long..",
    },
  ];

  const ledgers = selectedLedgerNames.map((ledger) => {
    return <li>{ledger.value}</li>;
  });

  const tabs = [
    {
      label: "Step 1",
      id: "first",
      content: (
        <Box>
          <br></br>
          <Box variant="awsui-key-label">
            Select from one of the following options.
          </Box>

          <br></br>
          <br></br>
          <ColumnLayout columns={3}>
            <RadioGroup
              value={selectedFilterOption}
              onChange={({ detail }) => {
                setSelectedFilterOption(detail.value);
              }}
              colmuns={3}
              items={filterOptions}
            />

            {selectedFilterOption === "filterOption1" ? (
              <Box variant="awsui-key-label">
                Select Team
                <Select
                  options={teamNames}
                  selectedOption={selectedTeamName}
                  onChange={({ detail }) => {
                    setSelectedTeamName(detail.selectedOption);
                  }}
                  selectedLabel="Selected"
                  filteringType="auto"
                ></Select>
              </Box>
            ) : selectedFilterOption === "filterOption2" ? (
              <div>
                <br />
                <br />
                <Box variant="awsui-key-label">Select ledger name</Box>
                <Multiselect
                  options={ledgerNames}
                  placeholder="Specify Ledger(s)"
                  checkboxes={true}
                  selectedOptions={selectedLedgerNames}
                  onChange={({ detail }) => {
                    setSelectedLedgerNames(detail.selectedOptions);
                  }}
                  keepOpen={true}
                  filteringType="auto"
                ></Multiselect>

                <Box variant="awsui-key-label">
                  Select AWS / Non Aws companies
                </Box>

                <Select
                  options={AWSoptions}
                  selectedOption={selectedAwsOption}
                  onChange={({ detail }) => {
                    setSelectedAwsOption(detail.selectedOption);
                  }}
                ></Select>
              </div>
            ) : (
              <div>
                <br />
                <br />
                <br />
                <br />
                <Box variant="awsui-key-label">Specify the company codes.</Box>
                <Input
                  value={selectedCompanyCodes}
                  onChange={({ detail }) => {
                    updateSelectedCompanyCodes(detail.value);
                  }}
                  placeholder="41,B123"
                ></Input>
                <br></br>
                <Alert header="How to provide multiple company codes">
                  <ul>
                    <li>
                      Seperate each company code with a comma<br></br>
                      For example: 41,B120
                    </li>
                    <li>The last comma is not required </li>
                    <li>
                      {" "}
                      <span style={{ color: "red" }}>incorrect: 41,B120,</span>
                    </li>

                    <li>Don't leave it blank</li>
                  </ul>
                </Alert>
              </div>
            )}
          </ColumnLayout>
          <br />
          <br />
          <br />
          <br />
          <br />
          <SpaceBetween direction="horizontal" size="s">
            <Button
              variant="normal"
              onClick={() => {
                history.push("/BoffinHome");
              }}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                setActiveTab("second");
              }}
            >
              Proceed to Step# 2
            </Button>
          </SpaceBetween>
        </Box>
      ),
    },
    {
      label: "Step 2",
      id: "second",
      content: (
        <Box>
          <ColumnLayout columns={4}>
            <Box>
              <Box variant="awsui-key-label">Preiod (Month & Year)</Box>
              <br></br>
              <br></br>
              <Grid gridDefinition={[{ colspan: 5 }, { colspan: 5 }]}>
                <Select
                  selectedOption={selectedMonth}
                  options={months}
                  onChange={({ detail }) => {
                    setSelectedMonth(detail.selectedOption);
                  }}
                ></Select>
                <Select
                  selectedOption={selectedYear}
                  options={years}
                  onChange={({ detail }) => {
                    setSelectedYear(detail.selectedOption);
                  }}
                ></Select>
              </Grid>
            </Box>
            <Box>
              <Box variant="awsui-key-label">Accounts</Box>
              <br></br>
              <br></br>

              <Multiselect
                options={Accounts}
                placeholder="Specify Account"
                checkboxes={true}
                selectedOptions={selectedAccounts}
                onChange={({ detail }) => {
                  setSelectedAccounts(detail.selectedOptions);
                }}
                keepOpen={true}
              ></Multiselect>
            </Box>
            <Box>
              <Box variant="awsui-key-label">Datasets</Box>
              <br />
              <br />

              <Multiselect
                options={Datasets}
                placeholder="Datasets"
                selectedOptions={selectedDatasets}
                checkboxes={true}
                onChange={({ detail }) => {
                  setSelectedDatasets(detail.selectedOptions);
                }}
                filteringType="auto"
              ></Multiselect>
            </Box>
          </ColumnLayout>
          <br />
          <br />
          <br />
          <SpaceBetween direction="horizontal" size="s">
            <Button
              variant="normal"
              onClick={() => {
                history.push("/BoffinHome");
              }}
            >
              Cancel
            </Button>
            <Button
              variant="normal"
              onClick={() => {
                setActiveTab("first");
              }}
            >
              Back
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                setActiveTab("third");
              }}
            >
              Proceed to Step# 3
            </Button>
          </SpaceBetween>
        </Box>
      ),
    },
    {
      label: "Step 3",
      id: "third",
      content: (
        <Box>
          <Box variant="awsui-key-label">Journal Entry Information</Box>
          <br />
          <br />
          <ColumnLayout columns={2} borders="vertical">
            <Box>
              <SpaceBetween size="m" direction="vertical">
                <Checkbox
                  checked={isReclassSelected}
                  onChange={({}) => {
                    setIsReclassSelected(!isReclassSelected);
                  }}
                >
                  Reclass
                </Checkbox>
                {isReclassSelected ? (
                  <RadioGroup
                    value={selectedReclassOption}
                    onChange={({ detail }) => {
                      setSelectedReclassOption(detail.value);
                    }}
                    colmuns={2}
                    items={reclassOptions}
                  />
                ) : (
                  <div></div>
                )}
              </SpaceBetween>
            </Box>
            <Box>
              <SpaceBetween size="m" direction="vertical">
                <Checkbox
                  checked={isAccrualSelected}
                  onChange={() => {
                    setIsAccrualSelected(!isAccrualSelected);
                  }}
                >
                  Accrual
                </Checkbox>
                {isAccrualSelected ? (
                  <RadioGroup
                    value={selectedAccrualOption}
                    onChange={({ detail }) => {
                      setSelectedAccrualOption(detail.value);
                    }}
                    colmuns={2}
                    items={accrualsOptions}
                  />
                ) : (
                  <div></div>
                )}
              </SpaceBetween>
            </Box>
          </ColumnLayout>
          <br />
          <br />

          <SpaceBetween direction="horizontal" size="s">
            <Button
              variant="normal"
              onClick={() => {
                history.push("/BoffinHome");
              }}
            >
              Cancel
            </Button>
            <Button
              variant="normal"
              onClick={() => {
                setActiveTab("second");
              }}
            >
              Back
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                setActiveTab("fourth");
              }}
            >
              Proceed to Step# 4
            </Button>
          </SpaceBetween>
        </Box>
      ),
    },
    {
      label: "Step 4",
      id: "fourth",
      content: (
        <Box>
          <Container header={<Header>Review</Header>}>
            <ColumnLayout columns={3}>
              <Box>
                <Box variant="awsui-key-label">Team Name</Box>
                <Box>
                  {selectedFilterOption === "filterOption1"
                    ? selectedTeamName.label
                    : "-"}
                </Box>
              </Box>
              <Box>
                <Box variant="awsui-key-label">Ledger Name</Box>
                <Box>
                  {selectedFilterOption === "filterOption2" &&
                  activeTab === "fourth" ? (
                    <Box>
                      <ul>{ledgers}</ul>
                      {selectedAwsOption.label}
                    </Box>
                  ) : (
                    "-"
                  )}
                </Box>
              </Box>
              <Box>
                <Box variant="awsui-key-label">Company Codes</Box>
                {selectedFilterOption === "filterOption3" ? (
                  <Box>{selectedCompanyCodes}</Box>
                ) : (
                  <Box>-</Box>
                )}
              </Box>
              <Box>
                <Box variant="awsui-key-label">Period Name</Box>
                <Box>
                  {selectedMonth.value}-{selectedYear.value}
                </Box>
                <Box></Box>
              </Box>
              <Box>
                <Box variant="awsui-key-label">JE Types</Box>
                <Box> {isReclassSelected ? selectedReclassOption : ""} </Box>
                <Box> {isAccrualSelected ? selectedAccrualOption : ""} </Box>
              </Box>
              <Box>
                <ExpandableSection headerText="Datasets">
                  <Box>
                    <ol>
                      {selectedDatasets.map((item, i) => {
                        return <li key={i}>{item.label}</li>;
                      })}
                    </ol>
                  </Box>
                </ExpandableSection>
              </Box>

              <Box>
                <Box variant="awsui-key-label">Email Notification</Box>
                <Checkbox
                  checked={emailNotificationReq}
                  onChange={({}) => {
                    setEmailNotificationReq(!emailNotificationReq);
                  }}
                >
                  Notify me by email for status updates
                </Checkbox>
                <Box variant="small">
                  Compute/Override/Approval/Posting Status
                </Box>
                {(currentStage == "beta") | (currentStage == "dev") ? (
                  <Alert
                    type="info"
                    header="Known issues/limitations (only in BETA)"
                  >
                    <Box color="text-body-secondary" variant="small">
                      This feature might not function as expected in BETA
                      environment. You would need to be added to verified list
                      in Amazon SES (Simple Email Service). Kindly reach out to
                      ATeam BI, if you would like test this feature.
                    </Box>
                  </Alert>
                ) : (
                  <div></div>
                )}
              </Box>
            </ColumnLayout>
          </Container>
          <br />
          <br />
          <br />
          <SpaceBetween direction="horizontal" size="s">
            <Button
              variant="normal"
              onClick={() => {
                history.push("/BoffinHome");
              }}
            >
              Cancel
            </Button>
            <Button
              variant="normal"
              onClick={() => {
                setActiveTab("third");
              }}
            >
              Back
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                onSubmitClick();
              }}
              disabled={disableSubmitButton}
            >
              Submit
            </Button>
          </SpaceBetween>
        </Box>
      ),
    },
  ];
  const content = (
    <Box>
      <p></p>
      {isAuthorized ? (
        <Box>
          <Tabs activeTabId={activeTab} variant="container" tabs={tabs}></Tabs>
          {showProcessing ? (
            <Modal
              visible={true}
              onDismiss={() => {
                history.push("/BoffinViewAllItems");
              }}
              header={<div>New Compute Processing</div>}
              footer={
                <Box float="right">
                  <SpaceBetween direction="horizontal" size="xs">
                    <Box variant="awsui-key-label">
                      Note: You can click close or wait until the processing
                      completes.
                    </Box>
                    <Button
                      variant="nomral"
                      onClick={() => {
                        history.push("/BoffinViewAllItems");
                      }}
                    >
                      Close
                    </Button>
                  </SpaceBetween>
                </Box>
              }
            >
              <Flashbar
                items={[
                  {
                    content: (
                      <ProgressBar
                        value={percentageCompleted}
                        variant="flash"
                        additionalInfo=""
                        description={statusUpdate}
                        label={"Processing : " + newId}
                      />
                    ),
                    id: "progressbar_1",
                  },
                ]}
              />
            </Modal>
            
          ) : (
            <Modal
            visible={computeInprogressModal}
            header={<div>Note!</div>}
            onDismiss={() => {
              setComputeInprogressModal(false)
            }}
            >
            Please Compute after sometime - Other Compute Underway!
            </Modal>
            
          )}
        </Box>
      ) : isCheckingAccess ? (
        <div>
          <WaitForPageAccess></WaitForPageAccess>
        </div>
      ) : (
        <div>
          <AccessDenied appName={appSettings.appName}></AccessDenied>
        </div>
      )}
    </Box>
  );

  return (
    <AppLayout
      breadcrumbs={
        <BreadcrumbGroup
          items={breadItems}
          onFollow={(event) => onFollowHandler(event)}
        ></BreadcrumbGroup>
      }
      content={content}
      navigation={<SideNav activeHref="/BoffinCompute" />}
      headerSelector="#TopBar"
      navigationHide={false}
      toolsWidth="400"
      tools={helpContent}
      headerVariant="high-contrast"
    ></AppLayout>
  );
};

export default BoffinCompute;
