
export const PROCESS_KEYS = {
    logico_process_id: "logico_process_id",
    app_id: "app_id",
    process_name: "process_name",
    process_description: "process_description",
    created_timestamp: "created_timestamp",
    last_updated_timestamp: "last_updated_timestamp",
    display_label: "display_label",
    created_by: "created_by",
    last_updated_by: "last_updated_by",
    approval_required: "approval_required",
    sox_approved: "sox_approved",
    critical_data: "critical_data",
    sox_evidence: "sox_evidence",
    approvers: "approvers",
    uploaders: "uploaders",
    fast_table: "fast_table",
    fast_view: "fast_view",
    APPROVER_KEYS: {
        alias: "alias",
        type: "type",
        column_index: "column_index"
    },
    UPLOADER_KEYS: {
        alias: "alias",
        type: "type"
    }
}

export const LOGICO_FILE_UPLOAD_KEYS = {
    logico_process_id: "logico_process_id",
    app_id: "app_id",
    updated_by: "updated_by",
    file_id: "file_id",
    filepath: "filepath",
    s3_bucket_name: "s3_bucket_name"
}


export const APPROVER_TYPES = {
    APPROVER: "APPROVER",
    APPROVER_LDAP: "APPROVER_LDAP"
}

export const UPLOADER_TYPES = {
    UPLOADER: "UPLOADER",
    UPLOADER_LDAP: "UPLOADER_LDAP"
}

export const LOGICO_STATUS = {

    FAILED: "File Upload Failed",
    APPROVAL_PENDING: "File Approval Pending",
    LOGICO_CONTICO_TRIGGERED: "Logico Contico Job Triggered",
    APPROVED: "File Approval Complete",
    PROCESS_INITIATED: "File Upload Process Initiated",
    UPLOADED: "File Uploaded",
    APPROVAL_CANCELLED: "File Approval Cancelled",
    APPROVAL_DENIED: "File Approval Denied",
}

export const RULES_SHEET_COLUMNS = ['Column1', 'Condition1', 'Value1', 'AndOr2', 'Column2', 'Condition2', 'Value2', 'AndOr3', 'Column3', 'Condition3', 'Value3', 'AndOr4', 'Column4', 'Condition4', 'Value4', 'AndOr5', 'Column5', 'Condition5', 'Value5', 'AndOr6', 'Column6', 'Condition6', 'Value6', 'AndOr7', 'Column7', 'Condition7', 'Value7', 'AndOr8', 'Column8', 'Condition8', 'Value8', 'Category1', 'Category2', 'Category3', 'Category4', 'Category5', 'Category6', 'Category7']



// export const FRISCO_TABLE_STATUS = {
//     CREATING_TABLE: 'Creating table',
//     TABLE_SCHEMA_UPDATED: 'Table Schema Updated',
//     TABLE_DROPPED: 'Table Dropped',
//     TABLE_CREATED: 'Table Created',
//     TABLE_CREATION_FAILED: 'Table Creation Failed',
//     TABLE_SCHEMA_UPDATE_FAILED: 'Table Schema Update Failed',
//     TABLE_DROP_FAILED: 'Table Drop Failed',
//     UPDATE_IN_PROGESS: 'Update In Progress'
// }

// export const FRISCO_POSITIVE_STATUS = [FRISCO_TABLE_STATUS.TABLE_SCHEMA_UPDATED, FRISCO_TABLE_STATUS.TABLE_DROPPED, FRISCO_TABLE_STATUS.TABLE_CREATED, 'Data Write to Glue Catalog Complete']
// export const FRISCO_NEGATIVE_STATUS = [FRISCO_TABLE_STATUS.TABLE_CREATION_FAILED, FRISCO_TABLE_STATUS.TABLE_SCHEMA_UPDATE_FAILED, FRISCO_TABLE_STATUS.TABLE_DROP_FAILED, 'Data Write to Glue Catalog Failed']

// export const FRISCO_NEGATIVE_STATUS_FOR_UPLOAD = [FRISCO_TABLE_STATUS.CREATING_TABLE, FRISCO_TABLE_STATUS.TABLE_CREATION_FAILED, FRISCO_TABLE_STATUS.TABLE_DROPPED, FRISCO_TABLE_STATUS.UPDATE_IN_PROGESS]
