import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  AppLayout,
  HelpPanel,
  Box,
  SpaceBetween,
  Header,
  Button,
  BreadcrumbGroup,
  Container,
  Alert,
  Icon,
  Spinner,
  ColumnLayout,
  Modal,
  Checkbox,
  SplitPanel,
  Flashbar,
  ProgressBar,
  Table,
  Input,
  Select
} from "@amzn/awsui-components-react";

import axios from "axios";

import SideNav from "./SideNav";
import { useHistory, useLocation } from "react-router-dom";
import checkPageAccess from "../../checkPageAccess";
import AccessDenied from "../../AccessDenied";
import WaitForPageAccess from "../../WaitForPageAccess";

import { AgGridReact } from "ag-grid-react";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";

import { API, graphqlOperation } from "aws-amplify";
import { useAuth } from "../../context/AuthContextProvider";
import {select_default_value_all } from "./Constants";
import {setFilterOptions, setFilterOptionsWithAll} from "./FilterUtilities"
import { historyData } from "./Values";


export default () => {
  const userAuthenticationDetails = useAuth();

  const userLDAPS = userAuthenticationDetails.USER_LDAP_GROUPS;
  const dispatch = useDispatch();

  const history = useHistory();
  const userId = useSelector((globalState) => globalState.auth.userId);
  const gridRef = useRef();

  const [isCheckingAccess, setIsCheckingAccess] = useState(true);
  const [isAuthorized, setIsAuthorized] = useState();

  const [items, setItems] = useState([]);

  const [statusUpdate, setStatusUpdate] = useState(
    "No status update at the moment."
  );
  const [showLoadingIcon, setShowLoadingIcon] = useState(true);
  const [showAllItems, setShowAllItems] = useState(false);

  const [toolsHide, setToolsHide] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [filterRegion, setFilterRegion] = useState(select_default_value_all);
  const [filterPreChecks, setFilterPreChecks] = useState(select_default_value_all);
  const [filterEntity, setFilterEntity] = useState('');
  const [filterPeriod, setFilterPeriod] = useState(select_default_value_all);
  const [filterRegionListWithAll, setFilterRegionListWithAll] = useState([]);
  const [filterPreChecksListWithAll, setFilterPreChecksListWithAll] = useState([]);
  const [filterPeriodListWithAll, setFilterPeriodListWithAll] = useState([]);
  const [visible, setVisible] = React.useState(false);

  const currentAppId = useSelector(
    (globalState) => globalState.auth.currentAppId
  );
  const isCurrentAppAuthorised = useSelector(
    (globalState) => globalState.auth.isCurrentAppAuthorized
  );


  useEffect(() => {
   
    loadData();


    return () => {
      // this now gets called when the component unmounts
      // subscription.unsubscribe();
    };
  }, [statusUpdate]);


  const defaultColDef = {
    // set filtering on for all columns
    filter: true,
    filterParams: {
      buttons: ["reset"],
      debounceMs: 200,
    },
  };

  const preCheckIcon = props => (
    props.data.pre_check_status === 'Passed' ?
      <Box>
        <Icon name='status-positive' variant='success' ></Icon>
      </Box>

      : props.data.pre_check_status === 'Failed' ? <Box>
        <Icon name='status-negative' variant='warning' ></Icon>
      </Box>
        : <span>{props.data.pre_check_status}</span>

  )

  const [columnDefs] = useState([
    // { field: "batch_id", width: "100px", resizable: true, headerName: "Batch" },
    { field: "region", width: "100px", resizable: true, headerName: "Region" },
    { field: "entity", width: "100px", resizable: true, headerName: "Entity" },
    { field: "report_period", width: "150px", resizable: true, headerName: "Period" },
    { field: "etb_version", width: "150px", resizable: true, headerName: "Version" },
    { field: "pre_check_status", width: "120px", resizable: true, headerName: "Pre-Checks", cellRenderer: preCheckIcon },
    { field: "created_time", width: "300px", resizable: true, headerName: "Processed Date" },
    { field: "trigger_request_type", width: "100px", resizable: true, headerName: "Request" },
    { field: "status", width: "150px", resizable: true, headerName: "Status" },
    { field: "retrigger_status", width: "150px", resizable: true, headerName: "Retrigger Status" },
    { field: "retrigger_by", width: "150px", resizable: true, headerName: "Retrigger By" },
    { field: "retrigger_request_time", width: "200px", resizable: true, headerName: "Retrigger Request" }
  ]);

  const onFollowHandler = (ev) => {
    ev.preventDefault();
    if (ev.detail.href) {
      history.push(ev.detail.href.substring(1));
    }
  };

  const breadItems = [
    { text: "Home", href: "/Home" },
    { text: "ATeam BI", href: "/ATeamBIHome" },
    { text: "Frisco", href: "" },
    { text: "Workflow1", href: "" },
  ];

  const getAppliedFiltersArray = () => {
    let filtersApplied = []
    if (filterRegion && filterRegion?.value) {
      filtersApplied.push(`region: "` + filterRegion?.value + `"`)
    }
    if (filterPreChecks && filterPreChecks?.value) {
      filtersApplied.push(`pre_check_status: "` + filterPreChecks?.value + `"`)
    }
    if (filterPeriod && filterPeriod?.value) {
      filtersApplied.push(`report_period: "` + filterPeriod?.value + `"`)
    }
    filtersApplied.push(`entity: "` + filterEntity + `"`)
    return filtersApplied
  }

  const getFiltersQueryString = () => {
    let filtersApplied = getAppliedFiltersArray()
    let filterQuery = ''
    if (filtersApplied && filtersApplied.length > 0) {
      filterQuery = `(` + filtersApplied.join(", ") + `)`
    }
    return filterQuery
  }
  const loadData = async () => {
    setShowLoadingIcon(true);

    // console.log(mQry)
      let qResultJSON = historyData()
      // load filters from data only when we have are fetching all the data - not on every load, since some loads can be after applying filters
      if(filterRegion === select_default_value_all){
        setFilterRegionListWithAll(setFilterOptionsWithAll(qResultJSON, 'region'))
      }
      if(filterPreChecks === select_default_value_all){
        setFilterPreChecksListWithAll(setFilterOptionsWithAll(qResultJSON, 'pre_check_status'))
      }
      if(filterPeriod === select_default_value_all){
        setFilterPeriodListWithAll(setFilterOptionsWithAll(qResultJSON, 'report_period'))
      }
      setItems(qResultJSON);
      setShowLoadingIcon(false);
  };

  const upload = () => {
    history.push('/frisco/upload')
  }

  const resetFilter = () => {
    setFilterRegion(select_default_value_all)
    setFilterPreChecks(select_default_value_all)
    setFilterPeriod(select_default_value_all)
    setErrorMessage('')
    setFilterEntity('')
  }

  const content = (
    <SpaceBetween direction="vertical" size="m">
        <Box>
          <Box></Box>
          <Container
            header={
              <Header
                variant="h2"
                actions={
                  <SpaceBetween direction="horizontal" size="xs">
                    {showLoadingIcon ? (
                      <Box>
                        <Spinner size="normal"></Spinner>
                      </Box>
                    ) : (
                      <Box></Box>
                    )}

                    <Button
                      variant="normal"
                      iconName="refresh"
                      onClick={() => loadData()}
                    >

                    </Button>

                    <Button
                      variant="normal"
                      onClick={() => upload()}
                    >
                      Upload
                    </Button>
                  </SpaceBetween>
                }
              >
                Frisco upload History
              </Header>
            }
          >
            <div
              className="ag-theme-alpine"
              style={{ height: 150, width: "100%" }}
            >
              <Box>
                <ColumnLayout columns={4}>
                  <Box>
                    <Box variant='awsui-key-label'>Region</Box>
                    <Select selectedOption={filterRegion} options={filterRegionListWithAll} onChange={({ detail }) => { setFilterRegion(detail.selectedOption) }}></Select>
                  </Box>
                  <Box>
                    <Box variant='awsui-key-label'>Pre-checks</Box>
                    <Select selectedOption={filterPreChecks} options={filterPreChecksListWithAll} onChange={({ detail }) => { setFilterPreChecks(detail.selectedOption) }}></Select>
                  </Box>
                  <Box>
                    <Box variant='awsui-key-label'>Period</Box>
                    <Select selectedOption={filterPeriod} options={filterPeriodListWithAll} onChange={({ detail }) => { setFilterPeriod(detail.selectedOption) }}></Select>
                  </Box>
                  <Box>
                    <Box variant='awsui-key-label'>Entity</Box>
                    <Input value={filterEntity} onChange={({ detail }) => setFilterEntity(detail.value)} ></Input>
                  </Box>
                  <Box textAlign="right">

                  </Box>
                  <Box></Box>
                </ColumnLayout>
                <SpaceBetween size='m' direction='horizontal'>
                  <Button variant='normal' onClick={resetFilter}>Reset</Button>
                  <Button variant='primary' onClick={loadData}>Filter</Button>
                </SpaceBetween>
              </Box>

              {errorMessage && (
                <Box variant="p" color="text-status-error">
                  {errorMessage}
                </Box>
              )}
            </div>

            <div
              className="ag-theme-alpine"
              style={{ height: 400, width: "100%" }}
            >
              <AgGridReact
                ref={gridRef}
                rowData={items}
                defaultColDef={defaultColDef}
                columnDefs={columnDefs}
                animateRows={true}
                rowHeight={30}
              ></AgGridReact>
            </div>
          </Container>
        </Box>

    </SpaceBetween>
  );

  const helpContent = (
    <HelpPanel
      header={
        <div>
          <h2>Info</h2>
        </div>
      }
    >
      <div>

        <p>What the icons mean?</p>
        <p><Icon name="status-negative" variant='warning'></Icon> = Failed</p>
        <p><Icon name='status-positive' variant="success"></Icon> = Passed</p>
        <p></p>
        <br></br>
      </div>

      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
    </HelpPanel>
  );

  return (
    <AppLayout
      breadcrumbs={
        <BreadcrumbGroup
          items={breadItems}
          onFollow={(event) => onFollowHandler(event)}
        ></BreadcrumbGroup>
      }
      content={content}
      navigation={<SideNav activeHref="/TriggerHistory" />}
      headerSelector="#TopBar"
      navigationHide={false}
      tools={helpContent}
      toolsOpen={toolsHide}
      onToolsChange={() => { setToolsHide(!toolsHide) }}
      headerVariant="high-contrast"
    ></AppLayout>
  );
};

