import { API, graphqlOperation } from 'aws-amplify';


/*

Sample details object to pass to notifyByEmail api 

const details = {
  fromAddress: "ateam-bi-support@amazon.com",
  toAddresses: '["subbui@amazon.com"]',
  subject: "Test Subject",
  textContent: "Test Content",
  htmlContent: "Test Content"
}

*/

  export const notifyByEmail = async ({fromAddress, toAddresses, subject, textContent, htmlContent}) => {

    htmlContent = htmlContent.split("\n").join("<br />")
    const variables = {
        textContent: textContent,
        htmlContent: htmlContent
      }

    let mQry = `mutation MyMutation($textContent: String!, $htmlContent: String!) {
        notifyByEmail(destination: {ToAddresses: ${toAddresses}}, html: $htmlContent, source: "${fromAddress}", subject: "${subject}", text: $textContent)
      }`
    let response = await API.graphql(graphqlOperation(mQry,variables));
    let notifyByEmailResponse = response.data.notifyByEmail;
    return JSON.parse(notifyByEmailResponse)
}
