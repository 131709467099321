import React, { useState, useEffect } from 'react';
import appSettings from './app_settings';

import {
    AppLayout, HelpPanel, Cards, Tabs, Icon, Spinner, ContentLayout, Table,
    Container, Header, Box, Pagination, SpaceBetween, ExpandableSection, Modal, ColumnLayout, Button, Badge, Textarea, Select, Alert, Input
} from '@amzn/awsui-components-react';
import Link from "@amzn/awsui-components-react/polaris/link";

import { useParams } from "react-router-dom";
import '../../index.scss';
import { useSelector, useDispatch } from 'react-redux'

import './styles.scss'

import AccessDenied from '../../AccessDenied'
import WaitForPageAccess from '../../WaitForPageAccess'
import { useHistory } from 'react-router-dom';

import { API, graphqlOperation } from 'aws-amplify';
import Template from '../Templates/Template';
import { glossary, TEMPLATE_APP, TEMPLATE_APP_OUTPUT, TEMPLATE_APP_PERMISSION, TEMPLATE_APP_TEAM, TEMPLATE_APP_SOURCE_DATA_TABLES, TEMPLATE_APP_CHART_OF_ACCOUNTS } from 'src/Ateam-BI/Templates/Constants';
import TemplateMaple from '../Templates/TemplateMaple';
import { favorite, checkIsFavorite } from '../../utilities/Favorites'
import eyeLogo from '../../images/eye-svgrepo-com.svg'
import SideNav from './SideNav';
import { getAppPromise, getFriscoGroupById, getFriscoProcessWithTracksForQuarter, getRedshiftTransformedTableDataForProcessId } from '../Templates/Utils/AppActivityUtils';
import { FRISCO_STATUS, PROCESS_KEYS } from '../Frisco/Constants';
import * as XLSX from 'xlsx';
import { getEditableFields, sortQuarters } from "../Templates/Utils/FormMetadata";
import { canAddEditDeleteApplication, isCorpAdmin } from '../Templates/Utils/Permissions';
import Feedback from 'src/generic-components/UserFeedback/Feedback';
import { performActionOnWorkflow } from 'src/utilities/wasApprovalAPIs';

import CustomMultiInput from "../Templates/components/CustomMultiInput";
import {notifyByEmail} from "../../utilities/notificationAPIs"
import { isUploaderAllowed } from '../Frisco/AccessUtilities';
import { getQuarterForUpload, getStateNonAdmin } from './Utility';

// Component ServiceHomepage is a skeleton of a service's homepage using AWS-UI React components.
export default (props) => {
    const history = useHistory()

    const [renderJson, setRenderJson] = useState({})
    const currentStage = useSelector((globalState) => globalState.auth.currentStage)
    const [isFavorite, setIsFavorite] = useState(false);
    const userId = useSelector((globalState) => globalState.auth.userId);
    const [NavOpen, setNavOpen] = useState(false);
    const [showLoadingIcon, setShowLoadingIcon] = useState(true);
    const [subProcess, setSubProcesses] = useState([]);
    const [currentPageIndex, setCurrentPageIndex] = useState(1);
    const [filteredProcess, setFilteredProcess] = useState([]);
    const [pageItems, setPageItems] = useState()
    const [isProcessLoading, setIsProcessLoading] = useState(true);
    const [showDataOnModal, setShowDataOnModal] = useState(false);
    const [showNotificationModal, setShowNotificationModal] = useState(false);
    const [dataSet, setDataSet] = useState(null);
    const [dataSetHeaders, setDataSetHeaders] = useState([]);
    const [adminApprovalId, setAdminApprovalId] = useState(null);
    const [pagesCount, setPagesCount] = useState()
    const [adminComments, setAdminComments] = useState("")
    const [activeTabId, setActiveTabId] = useState("uploads");
    const [groupName, setGroupName] = useState("");
    const [isAdmin, setIsAdmin] = useState(false)
    const [canDelete, setCanDelete] = useState(false)
    const [canEdit, setCanEdit] = useState(false)
    const [canAdd, setCanAdd] = useState(false)
    const [adminError, setAdminError] = useState(false)
    const [adminLoaderMapper, setAdminLoaderMapper] = useState({})

    const [emailContent, setEmailContent] = useState("")
    const [emailIds, setEmailIds] = useState([])
    const [emailSubject, setEmailSubject] = useState("")
    const [showSendingIcon, setShowSendingIcon] = useState(false)
    const [emailSent, setEmailSent] = useState(false)
    const [errorFields, setErrorFields] = useState(false)
    const [
        selectedItems,
        setSelectedItems
    ] = React.useState([]);
    const [cssLeadSheet, setCSSLeadSheet] = useState({})

    let { group } = useParams();

    const [
        quarterOption,
        setQuarterOption
    ] = React.useState({});

    const [
        quarterOptions,
        setQuarterOptions
    ] = React.useState([]);


    useEffect(() => {
        loadFriscoProcesses()
        accessPermissions()
        return () => {
            // this now gets called when the component unmounts
        };
    }, [currentStage]);

    useEffect(() => {
        // loadFriscoProcesses()
    }, [activeTabId])

    useEffect(() => {
        setPageItems(
            filteredProcess
                .slice(15 * (currentPageIndex - 1), 15 * (currentPageIndex))
        )

    }, [currentPageIndex, filteredProcess])

    useEffect(() => {
        setPagesCount(Math.ceil(subProcess.length / 15))
    }, [subProcess]);

    const accessPermissions = async () => {
        // fetch template from app id
        let result = await getAppPromise(appSettings[currentStage].appId)
        //   setTemplateId(result.data.getAppsV2[0][TEMPLATE_APP.TEMPLATE_ID])
        let appTemplateID = result.data.getAppsV2[0][TEMPLATE_APP.TEMPLATE_ID]
        let accessPerms = await canAddEditDeleteApplication(userId, appTemplateID, currentStage)
        setCanDelete(accessPerms?.delete)
        setCanEdit(accessPerms?.edit)
        setCanAdd(accessPerms?.add)
    }

    const getFromParam = (key) => {
        const search = props?.location?.search;
        if (search) {
            const params = new URLSearchParams(search);
            const value = params.get(key);
            return value;
        } else {
            return null;
        }
    }

    const loadFriscoProcesses = async () => {
        setIsProcessLoading(true);
        setShowLoadingIcon(false)

        let groupName = getFromParam('name')
        if (groupName) {
            setGroupName(groupName)
        } else {
            // get group name
            getFriscoGroupById(group, function onSuccess(result) {
                setGroupName(result.data.getFRISCOGroupById.group_name)
            }, function onFailure(result) {

            })
        }
        let res = await getEditableFields()
        sortQuarters(res.Quarter.values)
        setQuarterOptions(res.Quarter.values)

        let quarter = getFromParam('quarter')
        if (quarter) {
            setQuarterOption({ label: quarter, value: quarter })
        } else {
            let quarterVal = getQuarterForUpload()
            setQuarterOption({ label: quarterVal, value: quarterVal })
            
        }



        let accessPerms = await isCorpAdmin(userId, appSettings[currentStage].appId, currentStage)
        setIsAdmin(accessPerms?.admin)
        loadProcessesWithTracks(quarter)
    }

    const loadProcessesWithTracks = (quarter) => {
        setIsProcessLoading(true);
        setShowLoadingIcon(false)
        setSubProcesses([])
        setFilteredProcess([])
        getFriscoProcessWithTracksForQuarter(appSettings[currentStage].appId, group, quarter, function onSuccess(result) {
            setSubProcesses(result.data.getFRISCOProcessWithTracksForQuarter)
            setFilteredProcess(result.data.getFRISCOProcessWithTracksForQuarter)
            result.data.getFRISCOProcessWithTracksForQuarter.forEach(async (item)=>{
                let resp= await isUploaderAllowed(item, userId, currentStage)
                cssLeadSheet[item[PROCESS_KEYS.process_id]]=resp?'enabled':'is-disabled'
                setCSSLeadSheet({...cssLeadSheet})
            })
            setIsProcessLoading(false);
            setShowLoadingIcon(false)
        }, function onFailure(result) {
            setIsProcessLoading(false);
            setShowLoadingIcon(false)
        })
    }

    const getDate = (value) => {
        var d = new Date(parseFloat(value) * 1000)
        return d.toLocaleString()
    }

    const lastUpdatedContent = (<>
        {renderJson[TEMPLATE_APP.LAST_UPDATED_TIME_STAMP] ? <>

            <span className="last_updated"> Last updated : <span className="bold">{getDate(renderJson[TEMPLATE_APP.LAST_UPDATED_TIME_STAMP])}</span></span>
            {renderJson[TEMPLATE_APP.LAST_UPDATED_BY] ? <span className="last_updated"> by  <span className="bold">{renderJson[TEMPLATE_APP.LAST_UPDATED_BY]}</span></span> : ''}
        </> : ''}
    </>)

    const getUrl = (item) => {
        return `/App/${appSettings[currentStage].appId}/process/${item[PROCESS_KEYS.process_id]}/history?back=CorpReportingHome`
    }

    const getRedshiftTableName = (item) => {
        let redshiftTableName = item.redshift_table_name.replaceAll('"', "").replaceAll('\\', "")
        redshiftTableName = `${redshiftTableName}_transformed`
        return redshiftTableName
    }
    const showData = async (item) => {
        let redshiftTableName = getRedshiftTableName(item)
        setShowDataOnModal(true)
        getRedshiftTransformedTableDataForProcessId(redshiftTableName, function onSuccess(response) {
            let dataSetJson = []
            response.data.getRedshiftTransformedTableDataForProcessId.forEach((item) => {
                dataSetJson.push(JSON.parse(item))
            })
            setAdminApprovalId(item.approval_id)
            setDataSet(dataSetJson)
            let headers = []
            Object.keys(dataSetJson[0]).map((key, i) => (headers.push({
                id: key,
                header: key,
                cell: item => item[key] || '-'
            })))

            setDataSetHeaders(headers)
        }, function onFailure(response) {
            closeModal()
            setAdminError("Cannot load data for approval")
        })

    }

    const downloadCSVFile = (content, fileName, mimeType) => {
        var a = document.createElement('a');
        mimeType = mimeType || 'application/octet-stream';

        if (navigator.msSaveBlob) { // IE10
            navigator.msSaveBlob(new Blob([content], {
                type: mimeType
            }), fileName);
        } else if (URL && 'download' in a) { //html5 A[download]
            a.href = URL.createObjectURL(new Blob([content], {
                type: mimeType
            }));
            a.setAttribute('download', fileName);
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        } else {
            location.href = 'data:application/octet-stream,' + encodeURIComponent(content); // only this mime type is supported
        }
    }  

    const downloadFile = (item) => {
        let map = {}
        map[item.process_id] = true
        setAdminLoaderMapper({adminLoaderMapper, ...map})

        let redshiftTableName = getRedshiftTableName(item)
        getRedshiftTransformedTableDataForProcessId(redshiftTableName, function onSuccess(response) {
            let dataSetJson = []
            response.data.getRedshiftTransformedTableDataForProcessId.forEach((item) => {
                dataSetJson.push(JSON.parse(item))
            })
            let headers = []
            Object.keys(dataSetJson[0]).map((key, i) => (headers.push({
                id: key,
                header: key,
                cell: item => item[key] || '-'
            })))
            const worksheet = XLSX.utils.json_to_sheet(dataSetJson);
        var csv = XLSX.utils.sheet_to_csv(worksheet);
        downloadCSVFile(csv, `${redshiftTableName}.csv`, 'text/csv;encoding:utf-8');
        map[item.process_id] = false
        setAdminLoaderMapper({adminLoaderMapper, ...map})
        }, function onFailure(response) {
            setAdminError("Cannot load data for approval")
        })
    }

    const getState = (item) => {
        if (item?.overall_status == "INCOMPLETE") {
            if (item?.current_status == FRISCO_STATUS.DATA_PREPARED_FOR_APPROVAL || item?.current_status == FRISCO_STATUS.ADMIN_APPROVAL_PENDING
                || item?.current_status == FRISCO_STATUS.ADMIN_APPROVAL_COMPLETE || item?.current_status == FRISCO_STATUS.MOVED_TO_FDV || item?.current_status == 'MOVING_TO_FDV') {
                return <Box><span className='orange_badge'>PENDING</span></Box>
            }
            return <Box><span className='red_badge'>INCOMPLETE</span></Box>
        } else if (item.overall_status == "PENDING") {
            return <SpaceBetween direction='horizontal' size='s'><span className='orange_badge'>PENDING</span>
            {
                item.approval_id? <SpaceBetween direction='horizontal' size='s'>
                    <img className="icon" src={eyeLogo} onClick={() => showData(item)} ></img>
                    {
                    adminLoaderMapper && adminLoaderMapper[item.process_id] ? <Spinner></Spinner> :<Button
                    variant='inline-icon'
                    iconName='download'
                    onClick={() => {
                        downloadFile(item)
                    }
                    }></Button>
                }
                </SpaceBetween> :''
            }
            </SpaceBetween>
        } else if (item.overall_status == "COMPLETED") {
            return <SpaceBetween direction='horizontal' size='s'>
                <span className='green_badge'>COMPLETE</span>
                <img className="icon" src={eyeLogo} onClick={() => showData(item)} ></img>
                {
                    adminLoaderMapper && adminLoaderMapper[item.process_id] ? <Spinner></Spinner> :<Button
                    variant='inline-icon'
                    iconName='download'
                    onClick={() => {
                        downloadFile(item)
                    }
                    }></Button>
                }
            </SpaceBetween>
        } else {
            return <Box><span className='red_badge'>INCOMPLETE</span></Box>
        }
    }

    

    const getApprovalStatus = (item) => {
        if (item.current_status == FRISCO_STATUS.APPROVED) {
            return <SpaceBetween direction='horizontal' size='s'><span>{item.current_status}</span> <div id="clickable">
                {/* <Button href="#" variant="inline-link">
                    Revoke
                </Button> */}
                {/* <Badge color='red'>Revoke</Badge> */}
            </div></SpaceBetween>
        } else {
            return <SpaceBetween direction='horizontal' size='s'><span>{item.current_status}</span>
                {/* <div id="clickable"> <Badge color='green'>Approve</Badge></div> */}
            </SpaceBetween>
        }
    }

    const refresh = () => {
        setAdminError(null)
        loadProcessesWithTracks(quarterOption.value)
    }

    const approveByAdmin = async (approval) => {
        setDataSet(null)
        const details = {
            eventTriggeredBy: userId,
            stage: currentStage,
            eventType: approval,    // Possible values: Approve, Reject, Cancel
            workflowInstanceId: adminApprovalId
        }
        const response = await performActionOnWorkflow(details)
        closeModal()
        refresh()
    }

    const closeModal = () => {
        setDataSet(null);
        setAdminApprovalId(null);
        setShowDataOnModal(false);
    }

    const getEmailIds = (fieldName) => {
        return emailIds
    }

    const updateValue = (fieldName, value, subKey = null) => {
  
        setEmailIds(value)
        
      }
    
      const sendNotification = async () => {
        setShowSendingIcon(true)
        if (emailSubject.trim() && emailContent.trim() && emailIds.length > 0) {
            
            emailIds.map((id) => {
                if(!id.trim()) {
                    setErrorFields(true)
                    setShowSendingIcon(false)
                    setEmailSent(false) 
                    return;      
                }
            })
            setErrorFields(false)
            
            const details = {
                fromAddress: "ateam-bi-support@amazon.com",
                toAddresses: JSON.stringify( emailIds ),
                subject: emailSubject,
                textContent: emailContent,
                htmlContent: emailContent
              }

            const response = await notifyByEmail(details)
            if(response?.status === 200) {
                setEmailSent(true)
            }  
        }
        else {
            setErrorFields(true)
            setShowSendingIcon(false) 
            setEmailSent(false)
            return;
        }
        setShowSendingIcon(false) 
      }

    const info = (
        <Box>
            <Container
            >
                <SpaceBetween>
                    <div className="header_menu">
                        <div className="header_left">
                            <Box variant="h1">Application Details</Box>
                        </div>
                    </div>
                    <br></br>
                </SpaceBetween>
                <SpaceBetween size="l">
                    <ColumnLayout columns={2}>

                        <div>
                            <ExpandableSection
                                variant="navigation"
                                defaultExpanded={true}
                                headerText="Process Output links"
                            >
                                <>
                                    <br></br>
                                    {renderJson[TEMPLATE_APP.OUTPUT] ? <Table
                                        columnDefinitions={[
                                            {
                                                id: "type",
                                                header: "Type",
                                                cell: item => item[TEMPLATE_APP_OUTPUT.TYPE],
                                                isRowHeader: true
                                            },
                                            {
                                                id: "value",
                                                header: "Link",
                                                cell: item => <Link href={item[TEMPLATE_APP_OUTPUT.VALUE]} >{item.hasOwnProperty(TEMPLATE_APP_OUTPUT.LABEL) ? item[TEMPLATE_APP_OUTPUT.LABEL] : item[TEMPLATE_APP_OUTPUT.TYPE]}</Link>,
                                                sortingField: "alt"
                                            }
                                        ]}
                                        items={renderJson[TEMPLATE_APP.OUTPUT]}
                                        loadingText="Loading team details"
                                        sortingDisabled
                                        empty={
                                            <Box
                                                margin={{ vertical: "xs" }}
                                                textAlign="center"
                                                color="inherit"
                                            >
                                                <SpaceBetween size="m">
                                                    <b>No resources</b>
                                                </SpaceBetween>
                                            </Box>
                                        }
                                        variant='embedded'
                                    /> : ''}
                                </>
                            </ExpandableSection>
                        </div>

                        <div>
                            <ExpandableSection
                                variant="navigation"
                                defaultExpanded={true}
                                headerText="Permissions"
                            >
                                <ul>
                                    {renderJson[TEMPLATE_APP.PERMISSION]?.map((item) => (
                                        (item.hasOwnProperty(TEMPLATE_APP_PERMISSION.LINK) ? <li key={item} className="list-item-data">
                                            <Box variant="p">
                                                <Link href={item.link} external>{item.name ? item.name : item.link}</Link>
                                            </Box>
                                        </li> : '')
                                    ))}
                                </ul>

                            </ExpandableSection>
                        </div>

                        <div>
                            <ExpandableSection
                                variant="navigation"
                                defaultExpanded={true}
                                headerText="Organizations Supported"
                            >
                                <ul>
                                    {renderJson[TEMPLATE_APP.ORGANIZATION]?.map((item) => (
                                        <li key={item} className="list-item-data">
                                            {item}
                                        </li>
                                    ))}
                                </ul>

                            </ExpandableSection>

                        </div>

                        <div>
                            <ExpandableSection
                                variant="navigation"
                                defaultExpanded={true}
                                headerText="Run Frequency"
                            >
                                <ul>
                                    {renderJson[TEMPLATE_APP.USE_CASE]?.map((item) => (
                                        <li key={item} className="list-item-data">
                                            {item}
                                        </li>
                                    ))}
                                </ul>

                            </ExpandableSection>
                        </div>

                        <div>
                            <Box variant="h4">Contact DEV TEAM for this Process</Box>
                            <Box>{renderJson[TEMPLATE_APP.SIM_TICKET_URL] ?
                                <Box variant="p">
                                    <Button
                                        href={renderJson[TEMPLATE_APP.SIM_TICKET_URL]}
                                        iconAlign="right"
                                        iconName="external"
                                        target="_blank"
                                    >
                                        {renderJson[TEMPLATE_APP.SIM_TICKET_LABEL] ? renderJson[TEMPLATE_APP.SIM_TICKET_LABEL] : 'Raise a ticket'}
                                    </Button>
                                </Box> :
                                <Box>
                                    Not Available
                                </Box>
                            }
                            </Box>

                        </div>

                        <div>
                            <ExpandableSection
                                variant="navigation"
                                defaultExpanded={true}
                                headerText="Use Case Type"
                            >
                                <ul>
                                    {renderJson[TEMPLATE_APP.USE_CASE]?.map((item) => (
                                        <li key={item} className="list-item-data">
                                            {item}
                                        </li>
                                    ))}
                                </ul>
                            </ExpandableSection>
                        </div>
                    </ColumnLayout>
                </SpaceBetween>

            </Container>
        </Box>
    );

    const FriscoContent = (
        <Cards
            cardDefinition={{
                header: item => (
                    <div className={cssLeadSheet[item[PROCESS_KEYS.process_id]]}>
                        <SpaceBetween size='m' direction='horizontal'>
                            <Link href={getUrl(item)} > <span  >
                                {item[PROCESS_KEYS.label]}
                            </span>
                            </Link>
                            <Box float='right'>
                                {getStateNonAdmin(item)}
                            </Box>
                        </SpaceBetween>
                    </div>
                )
            }}
            header={
                <Header
                >
                    <SpaceBetween size='m' direction="horizontal">
                        {/* <span color="text-body-secondary">FRISCO Process ({filteredProcess.length}) </span> */}
                        {/* <Button variant='primary' href={`/App/${appSettings[currentStage].appId}/process?group=${activeTabId}`} target="_blank">+</Button> */}
                        <span color="text-body-secondary"> {quarterOption.value} </span>
                        <Select
                            selectedOption={quarterOption}
                            onChange={({ detail }) => {
                                setQuarterOption(detail.selectedOption)
                                loadProcessesWithTracks(detail.selectedOption.value)
                            }
                            }
                            options={quarterOptions}
                        />
                        <Button variant='normal' iconName='refresh' onClick={refresh}></Button>
                    </SpaceBetween>
                </Header>
            }
            stickyHeader
            cardsPerRow={[{ minWidth: 768, cards: 3 }]}
            items={filteredProcess}
            loading={isProcessLoading}
            loadingText="Loading resources"
            empty={
                <Box textAlign="center" color="inherit">
                    <b>No applications</b>
                    <Box
                        padding={{ bottom: "s" }}
                        variant="p"
                        color="inherit"
                    >
                        No applications to display.
                    </Box>
                </Box>
            }

            pagination={
                <Pagination currentPageIndex={currentPageIndex}
                    onChange={({ detail }) =>
                        setCurrentPageIndex(detail.currentPageIndex)
                    }
                    pagesCount={pagesCount} />
            }
        />
    );


    const FriscoContentAdmin = (
        <SpaceBetween direction='vertical' size='m'>
            {
                adminError?
                <Alert
      dismissible
      statusIconAriaLabel="Error"
      type="error"
    >
      {adminError}
    </Alert>:
    ''
            }
            <SpaceBetween direction='horizontal' size='m'>
                <Select
                    selectedOption={quarterOption}
                    onChange={({ detail }) => {
                        setQuarterOption(detail.selectedOption)
                        loadProcessesWithTracks(detail.selectedOption.value)
                    }
                    }
                    options={quarterOptions}
                />
                <Button variant='normal' iconName='refresh' onClick={refresh}></Button>
            </SpaceBetween>

            <Table
                onSelectionChange={({ detail }) =>
                    setSelectedItems(detail.selectedItems)
                }
                selectedItems={selectedItems}
                ariaLabels={{
                    selectionGroupLabel: "Items selection",
                    allItemsSelectionLabel: ({ selectedItems }) =>
                        `${selectedItems.length} ${selectedItems.length === 1 ? "item" : "items"
                        } selected`,
                    itemSelectionLabel: ({ selectedItems }, item) =>
                        item.name
                }}
                trackBy={PROCESS_KEYS.process_id}
                selectionType="multi"
                columnDefinitions={[
                    {
                        id: "process",
                        header: "Process",
                        cell: item => (
                            <Box> <Link href={getUrl(item)} external>{item[PROCESS_KEYS.label] || "-"}</Link>
                                <Box float='right'><Button iconName="notification" variant="icon" onClick={() => setShowNotificationModal(true)} /></Box></Box>
                        ),
                        width: "250px",
                        sortingField: "process",
                        isRowHeader: true
                    },
                    {
                        id: "state",
                        header: "State",
                        cell: item => getState(item),
                        width: "230px",
                        sortingField: "state"
                    },
                    {
                        id: "approvalStatus",
                        header: "Status",
                        cell: item => getApprovalStatus(item),
                        width: "280px"
                    },
                    {
                        id: "details",
                        header: "Details",
                        cell: item => item?.details,
                        width: "300px"
                    },
                    {
                        id: "lastAction",
                        header: "Last action date",
                        cell: item => item?.last_updated_time_stamp ? item.last_updated_time_stamp : '-',
                        width: "300px"
                    },
                    {
                        id: "-",
                        header: "",
                        cell: item => '',
                        width: "300px"
                    }
                ]}
                items={filteredProcess}
                loading={isProcessLoading}
                loadingText="Loading processes"
                stickyColumns={true}
                resizableColumns={true}
                empty={
                    <Box
                        margin={{ vertical: "xs" }}
                        textAlign="center"
                        color="inherit"
                    >
                        <SpaceBetween size="m">
                            <b>No processes</b>
                        </SpaceBetween>
                    </Box>
                }
            />

            {
                selectedItems.length > 0 ? <Box float='right'> <Button variant='primary'>Approve</Button></Box> : <></>
            }

            <Modal
                onDismiss={() => {setShowNotificationModal(false)
                                  setEmailSent(false)
                                  setErrorFields(false)
                                  setEmailIds([])
                                  setEmailSubject("")
                                  setEmailContent("")}}
                visible={showNotificationModal}
                footer={
                    <Box float="right">
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button variant="primary" onClick={() => sendNotification()}>Send</Button>
                        </SpaceBetween>
                    </Box>
                }
                header="Notification"
            >
                
                Add Emails to Notify:
                <CustomMultiInput field={"emails"} updateValue={updateValue}
                            getValues={getEmailIds} ></CustomMultiInput>
                
                <br/>

                <Input
                    onChange={({ detail }) => setEmailSubject(detail.value)}
                    placeholder="Email Subject"
                    value={emailSubject}
                />
                <br/>
                <Textarea
                    onChange={({ detail }) => setEmailContent(detail.value)}
                    value={emailContent}
                    placeholder="Email Content"
                    rows={10}
                />
                {showSendingIcon ? <Spinner></Spinner> : <></>}
                {emailSent ? <><Icon name="status-positive" variant="success" /> Email Sent</>: <></>}
                {errorFields ? <><Icon name="status-negative" variant="error" /> All fields are required and cannot be empty</>: <></>}
            </Modal>

            <Modal
                onDismiss={() => { closeModal() }}
                visible={showDataOnModal}
                footer={
                    <Box>
                        {
                            dataSet ? <Box>
                                <Textarea
                                    onChange={(detail) => setAdminComments(detail.detail.value)}
                                    value={adminComments}
                                    placeholder="Comments"
                                />

                                <Box float="right">
                                    <br></br>
                                    <SpaceBetween direction="horizontal" size="xs">
                                        {
                                            <>
                                                <Button variant="primary" onClick={() => { approveByAdmin("Approve") }}>Approve</Button>
                                                <Button onClick={() => approveByAdmin("Reject")}>Deny</Button>
                                                <Button onClick={() => closeModal()}>Cancel</Button>
                                            </>
                                        }
                                    </SpaceBetween>
                                </Box>

                            </Box> :
                                <Box>
                                    <Spinner size="large" />
                                    Loading, please wait..
                                </Box>
                        }
                    </Box>
                }
                header="Data set"
                size='max'
            >
                {
                    dataSet ?
                        <Box>
                            <Table
                                columnDefinitions={dataSetHeaders}
                                items={dataSet}
                                stickyHeader={true}
                                resizableColumns={true}
                            >

                            </Table>
                        </Box> : <Box></Box>
                }

            </Modal>
        </SpaceBetween>
    );

    const getTabs = () => {
        let tabs = [
            {
                label: "Upload",
                id: "uploads",
                content: FriscoContent
            }

        ]

        // if (isAdmin) {
        //     tabs.push({
        //         label: "Admin",
        //         id: "Admin",
        //         content: FriscoContentAdmin
        //     })
        // }

        return tabs
    }


    const Content = (
        currentStage ?
            <ContentLayout
                headerVariant="high-contrast"
                className="content"
                header={
                    <SpaceBetween size="l">
                        <Header
                            variant="h1"
                        >

                            {groupName}
                        </Header>
                    </SpaceBetween>
                }
            >
                <Container>
                    <SpaceBetween size="l">
                        {isAdmin ? <Tabs
                            activeTabId={activeTabId}
                            onChange={({ detail }) => setActiveTabId(detail.activeTabId)}
                            tabs={getTabs()}
                        /> : <Tabs
                            activeTabId={activeTabId}
                            onChange={({ detail }) => setActiveTabId(detail.activeTabId)}
                            tabs={getTabs()}
                        />}
                    </SpaceBetween>
                </Container>

                <br />
                <Feedback appId={appSettings[currentStage].appId} pageId={group} userId={userId} parentId={"null"} level={0} limit={1}></Feedback>

            </ContentLayout> : <Box>
                <Spinner></Spinner>
                Loading...
            </Box>
    )


    return (
        <AppLayout
            disableContentPaddings={false}
            content={Content}
            contentType="default"
            navigationOpen={NavOpen}
            navigation={<SideNav activeHref={`/CorpReportingHome`} id={appSettings[currentStage].appId} groupId={group} canEdit={canEdit} isAdmin={isAdmin}/>}
            onNavigationChange={() => {
                setNavOpen(!NavOpen);
            }}
            toolsHide={true}
            headerVariant="high-contrast"
        />
    );
};


