import appSettings from 'src/Ateam-BI/CorpReporting/app_settings'
import { FRISCO_STATUS, FRISCO_TRACK_CURRENT_STATUS, FRISCO_TRACK_OVERALL_STATUS, PROCESS_KEYS } from 'src/Ateam-BI/Frisco/Constants'
import { getFriscoProcessWithTracksForQuarter } from 'src/Ateam-BI/Templates/Utils/AppActivityUtils'

export const request = (request, onResponse) => {
    let corpReporingId = appSettings[request?.stage]['appId']
    if (request?.appId == corpReporingId) {
        // MAKE API CALL TO GET QUARTERLY STATUS AND SEE IF UPLOADED ALREADY
        getFriscoProcessWithTracksForQuarter(appSettings[request?.stage].appId, request?.group, request?.quarter, function onSuccess(result) {
            let responseVal = false
            let msg = ''
            let comments = ''
            for (let i = 0; i < result.data.getFRISCOProcessWithTracksForQuarter.length; i++) {
                let item = result.data.getFRISCOProcessWithTracksForQuarter[i]
                if (item[PROCESS_KEYS.process_id] == request?.processId) {
                    if (item['overall_status'] == FRISCO_TRACK_OVERALL_STATUS.INCOMPLETE
                        && (item['current_status'] == FRISCO_TRACK_CURRENT_STATUS.POST_WB
                            || item['current_status'] == FRISCO_TRACK_CURRENT_STATUS.RELOAD_INPUT_ADMIN_APPROVAL_DENIED
                            || item['current_status'] == FRISCO_TRACK_CURRENT_STATUS.RELOAD_INPUT_FILE_APPROVAL_DENIED
                            || item['current_status'] == FRISCO_TRACK_CURRENT_STATUS.UPLOAD_INPUT_FILE
                            || item['current_status'] == FRISCO_STATUS.FAILED)) {
                        msg = item['current_status']
                        responseVal = true
                    }else if(item['current_status'] == FRISCO_TRACK_CURRENT_STATUS.ALLOW_POST_WB_INPUT_FILE){
                        responseVal = true
                        msg = item['current_status']
                    } 
                    else {
                        msg = item['current_status']
                    }


                    let wbResponse = false
                    let hasWBDueDates = false
                    // check custom attributes
                    for (let j = 0; j < item[PROCESS_KEYS.custom_attributes]?.length; j++) {
                        let attribute = item[PROCESS_KEYS.custom_attributes][j]
                        if (attribute[PROCESS_KEYS.CUSTOM_ATTRIBUTES_KEYS.sub_type] == 'FRISCO'
                            && attribute[PROCESS_KEYS.CUSTOM_ATTRIBUTES_KEYS.attribute_type] == 'PRE_WB_DUE_DATE') {
                            // check if still valid to enable upload
                            hasWBDueDates = true
                            let time = attribute[PROCESS_KEYS.CUSTOM_ATTRIBUTES_KEYS.attribute]
                            wbResponse = wbResponse || (parseInt(time) >= Date.now())
                            let timeObj = new Date(parseInt(time))
                            let hours = ("0"+timeObj.getHours()).slice(-2)
                            let mins = ("0"+timeObj.getMinutes()).slice(-2)
                            comments = comments + '\n' + `Pre-WB due date is ${timeObj.getMonth()+ 1}/${timeObj.getDate()}/${timeObj.getFullYear()} ${hours}:${mins}`

                        }
                        if (attribute[PROCESS_KEYS.CUSTOM_ATTRIBUTES_KEYS.sub_type] == 'FRISCO'
                            && attribute[PROCESS_KEYS.CUSTOM_ATTRIBUTES_KEYS.attribute_type] == 'POST_WB_DUE_DATE') {
                            // check if still valid to enable upload
                            hasWBDueDates = true
                            let time = attribute[PROCESS_KEYS.CUSTOM_ATTRIBUTES_KEYS.attribute]
                            wbResponse = wbResponse || (parseInt(time) >= Date.now()) 
                            let timeObj = new Date(parseInt(time))
                            let hours = ("0"+timeObj.getHours()).slice(-2)
                            let mins = ("0"+timeObj.getMinutes()).slice(-2)
                            comments = comments + '\n' + `Post-WB due date is ${timeObj.getMonth()+ 1}/${timeObj.getDate()}/${timeObj.getFullYear()} ${hours}:${mins}`

                        }
                    }

                    if(hasWBDueDates){
                        responseVal = responseVal && wbResponse 
                        if(!wbResponse){
                            msg = "Upload is past due date"
                        }
                    }
                    
                    break;
                }
            }

            onResponse({ result: responseVal, message: msg, comments: comments })
        }, function onFailure(result) {
        })

    } else {
        onResponse({ result: true })
    }
}
