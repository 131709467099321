import { PORTAL } from "src/constants/AppConstants";
const appSettings = {
    dev:{
        'appId':'230',
        'appName':'corp reporting'
    },
    beta:{
        'appId':'230',
        'appName':'corp reporting'
    },
    prod:{
        'appId':'646',
        'appName':'corp reporting'
    }
}

export default appSettings;