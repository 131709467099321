import React, { useState, useEffect, useRef } from "react";

import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";

import {
  AppLayout,
  BreadcrumbGroup,
  HelpPanel,
  Button,
  Box,
  Select,
  Spinner,
  ColumnLayout,
  SpaceBetween,
  Container,
  Header,
  Icon,
  Input,
  Checkbox,
  SegmentedControl
} from "@amzn/awsui-components-react";
import axios from "axios";

import SideNav from "./SideNav";
import appSettings from "./app_settings";

import {select_default_value_all } from "./Constants";

import { AgGridReact } from "ag-grid-react";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";

import { setCurrentAppId } from "../../store/userAuthSlice";
import {checkAppFunctionalityAccess} from "../../checkPageAccess";

import checkPageAccess from "../../checkPageAccess";
import AccessDenied from "../../AccessDenied";
import WaitForPageAccess from "../../WaitForPageAccess";

import { Auth, Amplify } from "aws-amplify";

import { API, graphqlOperation } from "aws-amplify";
import { useAuth } from "../../context/AuthContextProvider";
import {setFilterOptions, setFilterOptionsWithAll} from "./FilterUtilities"
import { constructExcelSheetWithHeadersAndData } from "../Templates/Utils/AppActivityUtils";

const EntityRegionMapping = () => {
  const userId = useSelector((globalState) => globalState.auth.userId);
  const history = useHistory();
  const currentAppId = useSelector(
    (globalState) => globalState.auth.currentAppId
  );
  const isCurrentAppAuthorised = useSelector(
    (globalState) => globalState.auth.isCurrentAppAuthorized
  );
  const [items, setItems] = useState([]);

  const [isCheckingAccess, setIsCheckingAccess] = useState(true);
  const [isAuthorized, setIsAuthorized] = useState();
  const dispatch = useDispatch();
  const gridRef = useRef();
  const [dsInfo, setDsInfo] = useState([]);
  const [showLoadingIcon, setShowLoadingIcon] = useState(false);
  const [showUpdatingIcon, setShowUpdatingIcon] = useState(false);
  const [updateStatus, setUpdateStatus] = useState("");
  const [toolsHide, setToolsHide] = useState(true);

  // const [isAWS, setIsAWS] = useState(false);

  const [selectedItem, setSelectedItem] = useState([]);
  const [filterEntity, setFilterEntity] = useState('');
  const [filterRegion, setFilterRegion] = useState(select_default_value_all);
  const [editEntity, setEditEntity] = useState('');
  const [editRegion, setEditRegion] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [editWindowErrorMessage, setEditWindowErrorMessage] = useState('');
  const [editWindowSuccessMessage, setEditWindowSuccessMessage] = useState('');
  const [filterRegionList, setFilterRegionList] = useState([]);
  const [filterRegionListWithAll, setFilterRegionListWithAll] = useState([]);
  const [filterEntityListWithAll, setFilterEntityListWithAll] = useState([]);
  const [addNewRegion, setAddNewRegion] = useState(false)
  const [selectedSegmentId, setSelectedSegmentId] = useState('insertDelete')
  const [hasPermissionToAddNewRegion, setHasPermissionToAddNewRegion] = useState(false)
  const currentStage = useSelector((globalState) => globalState.auth.currentStage)


  useEffect(() => {
    if (currentAppId !== appSettings.appId) {
      dispatch(setCurrentAppId(appSettings.appId));
    }
    setIsAuthorized(isCurrentAppAuthorised);
    if (isCurrentAppAuthorised) {
      LoadData();
    }
    return () => {
      // this now gets called when the component unmounts
    };
  }, [isCurrentAppAuthorised]);

  useEffect(() => {
    if(isAuthorized === true || isAuthorized === false){
      setIsCheckingAccess(!isCheckingAccess);
    } 
  }, [isAuthorized]);

  const breadItems = [
    { text: "Home", href: "/Home" },
    { text: "ATeam BI", href: "/ATeamBIHome" },
    { text: "TED", href: "/TedHome" },
    { text: "Entity Region Mapping", href: "/" },
  ];
  const loadRegionMap = () => {
    let map = {}
    filterRegionListWithAll.forEach((d) => map[d.label] = d)
    return map;
  }
  const [regionMap, setRegionMap] = useState(loadRegionMap());

  useEffect(() => {
    setRegionMap(loadRegionMap())
  }, [filterRegionListWithAll])
  
  const onFollowHandler = (ev) => {
    ev.preventDefault();
    if (ev.detail.href) {
      history.push(ev.detail.href.substring(1));
    }
  };

  const filterItems = (itemsToFilter) => {
    let filtersApplied = getAppliedFiltersMap()
    Object.keys(filtersApplied).forEach(key => {
      if (filtersApplied[key] !== 'All') {
        itemsToFilter = itemsToFilter.filter(d => d[key] === filtersApplied[key]);
      }
    })
    return itemsToFilter
  }

  const getAppliedFiltersMap = () => {
    let filtersApplied = {}
    if (filterRegion && filterRegion?.value) {
      filtersApplied['region'] = filterRegion?.value
    }
    if (filterEntity) {
      filtersApplied['entity'] = filterEntity
    }
    return filtersApplied
  }

  const resetEditWindow = () => {
    setEditWindowErrorMessage('')
    setEditWindowSuccessMessage('')
    setEditEntity('')
    setEditRegion('')
  }

  const resetEditWindowValues = () => {
    setEditEntity('')
    setEditRegion('')
  }

  const resetEditWindowMessages = () => {
    setEditWindowErrorMessage('')
    setEditWindowSuccessMessage('')
  }

  const LoadData = async () => {
    setShowLoadingIcon(true);

    const details = {
      userAlias: userId,
      id: appSettings.appId,
      accessLevelId: "3",
      stage: currentStage
    }
    const permission = await checkAppFunctionalityAccess(details)
    setHasPermissionToAddNewRegion(permission)

    var mQry =
      `query MyQuery {
        listEntityRegionMapping{
          id
          entity
          created_time
          created_by
          region
          updated_by
          updated_time
        }
      }`;

    // console.log(mQry)   
    setShowLoadingIcon(true); 
    await executeQuery(mQry, function onSuccess(result) {
      setErrorMessage("")
      let qResultJSON = result.data.listEntityRegionMapping;
      setFilterRegionList(setFilterOptions(qResultJSON, 'region'))
      setFilterRegionListWithAll(setFilterOptionsWithAll(qResultJSON, 'region'))
      setFilterEntityListWithAll(setFilterOptionsWithAll(qResultJSON, 'entity'))
      setItems(filterItems(qResultJSON));
      setShowLoadingIcon(false);
    }, function onFailure(error) {
      setErrorMessage(error?.errors[0]?.message)
      setShowLoadingIcon(false);
    })
  };

  const defaultColDef = {
    // set filtering on for all columns
    filter: true,
    filterParams: {
      buttons: ["reset"],
      debounceMs: 200,
    },
  };

  const columnDefs = [
    {
      field: "entity",
      width: "150px",
      resizable: true,
      sortable: true,
      headerName: "Entity",
    },
    {
      field: "region",
      width: "150px",
      sortable: true,
      resizable: true,
      headerName: "Region",
    },
    {
      field: "created_time",
      width: "250px",
      sortable: true,
      sortable: true,
      resizable: true,
      headerName: "Created Time",
    },
    {
      field: "created_by",
      width: "150px",
      sortable: true,
      resizable: true,
      headerName: "Created By",
    },
    {
      field: "updated_time",
      width: "250px",
      sortable: true,
      resizable: true,
      headerName: "Updated Time",
    },
    {
      field: "updated_by",
      width: "150px",
      sortable: true,
      resizable: true,
      headerName: "Updated By",
    },
  ];
  const onSelectionChanged = () => {
    const selectedRows = gridRef.current.api.getSelectedRows();
    if (selectedRows.length > 0) {
      // console.log(selectedRows[0])
      let selectedRow = selectedRows[0]
      setSelectedItem(selectedRow);
      setEditEntity(selectedRow?.entity)
      setEditRegion(regionMap[selectedRow?.region])
    } else {
      setSelectedItem([]);
      setEditEntity('')
      setEditRegion('')

    }
  };

  // const downloadFileClick = () => {

  //   if(dsInfo.length >= 0) {
  //     let csv = '';

  //     for (let col of columnDefs) {
  //       csv += col.headerName + ','
  //     }
  //     csv += '\n'

  //     for(let row of dsInfo) {
  //       csv += `"${row.Company_code}"` + ','
  //       csv += `"${row.Description}"` + ','
  //       csv += `"${row.Ledger_Name}"` + ','
  //       csv += `"${row.team_name}"` + ','
  //       csv += `"${row.isAWS}"` + ','
  //       csv += '\n'
  //     }


  //   let link = document.createElement('a')
  //   link.id = 'download-csv'
  //   link.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(csv));
  //   link.setAttribute('download', 'TeamNameMapping.csv');
  //   document.body.appendChild(link)
  //   document.querySelector('#download-csv').click()

  //   }

  // }

  const resetFilter = () => {
    setFilterRegion(select_default_value_all)
    setErrorMessage('')
    setFilterEntity('')
  }

  const executeQuery = async (mQry, onSuccess, onFailure) => {
    // console.log(mQry)    
    let response = await API.graphql(graphqlOperation(mQry)).then(result => {
      onSuccess(result)
    }, function (error) {
      onFailure(error)
    });

  };

  const insertEntry = () => {
    resetEditWindowMessages()
    // check if entry already exists in local data
    if (!editRegion || editEntity === '') {
      setEditWindowErrorMessage("Enter valid inputs")
      return
    }
    let found = false
    for(let i = 0; i < items.length; i++){
      if (editRegion?.value === items[i]?.region && editEntity === items[i]?.entity) {
        found = true
        break
      }
    }

    if (found) {
      setEditWindowErrorMessage("Entry already exists")
    } else {
      setEditWindowErrorMessage()
      let qry = `mutation MyMutation{
        insertEntityRegionMapping(region: "`+ editRegion.value + `", entity: "` + editEntity + `", userId: "` + userId + `"){
            message
        }
      }`
      setShowLoadingIcon(true); 
      executeQuery(qry, function onSuccess(result) {
        let qResultJSON = result.data.insertEntityRegionMapping;
        if (qResultJSON?.message?.toLowerCase() === 'success') {
          setEditWindowSuccessMessage(`${editEntity} at ${editRegion?.value} inserted successfully`)
        } else {
          setEditWindowErrorMessage(`Insertion failed for ${editEntity} at ${editRegion?.value}`)
        }
        setShowLoadingIcon(false);
        LoadData()
      }, function onFailure(error) {
        setEditWindowErrorMessage(`Insertion failed for ${editEntity} at ${editRegion?.value}. ${error?.errors[0]?.message}`)
        setShowLoadingIcon(false); 
      })
    }
  }

  const deleteEntry = () => {
    resetEditWindowMessages()
    if (!editRegion || editEntity === '') {
      setEditWindowErrorMessage("Enter valid inputs")
      return
    }
    // check if entry exists in local data
    let found = false
    let selectedId = null

    for(let i = 0; i < items.length; i++){
      if (editRegion?.value === items[i]?.region && editEntity === items[i]?.entity) {
        found = true
        selectedId = items[i]?.id
        break
      }
    }

    if (!found) {
      setEditWindowErrorMessage("Cannot delete, entry does not exist in the data")
    } else {
      setEditWindowErrorMessage()
      let qry = `mutation MyMutation{
        deleteEntityRegionMapping(id: `+ selectedId + `){
            message
        }
      }`
      setShowLoadingIcon(true); 
      resetEditWindowMessages()
      executeQuery(qry, function onSuccess(result) {
        let qResultJSON = result.data.deleteEntityRegionMapping;
        if (qResultJSON?.message?.toLowerCase() === 'success') {
          setEditWindowSuccessMessage(`${editEntity} at ${editRegion?.value} deleted successfully`)
        } else {
          setEditWindowErrorMessage(`Delete failed for ${editEntity} at ${editRegion?.value}`)
        }
        setShowLoadingIcon(false); 
        LoadData()
      }, function onFailure(error) {
        setEditWindowErrorMessage(`Delete failed for ${editEntity} at ${editRegion?.value}. ${error?.errors[0]?.message}`)
        setShowLoadingIcon(false); 
      })
    }
  }

  const downloadFilteredData = () => {

    const headers = columnDefs.map((def) => (def["field"]))
    const data = items.map((item)=>({
      entity : item.entity,
      region : item.region,
      created_time : item.created_time,
      created_by: item.created_by,
      updated_time: item.updated_time,
      updated_by: item.updated_by

    }))

    constructExcelSheetWithHeadersAndData(headers, data, "EntityRegionMapping")           

  }

  const helpContent = (
    <HelpPanel
      header={
        <div>
          <h2>Manage Entity region Mapping </h2>
          {selectedItem.length == 0 ? (
            <small>Select a mapping to insert/delete</small>
          ) : (
            <div></div>
          )}
        </div>
      }
    >

      <Box>
      
        <Box>
        <SpaceBetween size="m">
          <Box variant="awsui-key-label">{selectedItem.Description}</Box>
          <Box>
            <Box>
              
            </Box>
            <Box variant="awsui-key-label">Region</Box>
            <Select
              selectedOption={editRegion}
              options={filterRegionList}
              onChange={({ detail }) => {
                setEditRegion(detail.selectedOption);
              }}
              disabled={addNewRegion}
            ></Select>
            <br></br>
            <Checkbox
                onChange={({ detail }) =>
                  {
                  setAddNewRegion(detail.checked)
                  gridRef.current?.api.deselectAll();
                  setSelectedItem([]);
                  setEditEntity('')
                  setEditRegion('')
                  }
                }
                checked={addNewRegion}
                disabled={!hasPermissionToAddNewRegion}
              >
                Add new region instead?
              </Checkbox>
              <br></br>
            {(addNewRegion) && (<Input
             onChange={({ detail }) => setEditRegion({ label: detail.value, value: detail.value })}
            value={editRegion?.value}
            placeholder="Type new region"
             />)}
          </Box>
          <Box>
            <Box variant="awsui-key-label">Entity</Box>
            <Input value={editEntity} onChange={({ detail }) => setEditEntity(detail.value)} ></Input>
          </Box>
          <Box>
            <SpaceBetween size='m' direction='horizontal'>
              <Button variant='normal' onClick={insertEntry}>Insert</Button>
              <Button variant='primary' onClick={deleteEntry}>Delete</Button>
            </SpaceBetween>

          </Box>
          <Box>
            {editWindowSuccessMessage && (
              <Box variant="p" color="text-status-success">
                {editWindowSuccessMessage}
              </Box>
            )}
            {editWindowErrorMessage && (
              <Box variant="p" color="text-status-error">
                {editWindowErrorMessage}
              </Box>
            )}
            {showUpdatingIcon ? (
              <Box>
                {" "}
                <Spinner></Spinner> Updating...{" "}
              </Box>
            ) : (
              <Box>
                {updateStatus === "Updated" ? (
                  <Icon variant="success" name="status-positive"></Icon>
                ) : (
                  ""
                )}
                &nbsp; {updateStatus}
              </Box>
            )}
          </Box>
        </SpaceBetween>
        </Box>
      </Box>
    </HelpPanel>
  );

  const content = (
    <div>
      {isAuthorized ? (
        <Box>
          <Box>
            <Container
              header={
                <Header
                  actions={
                    <SpaceBetween direction="horizontal" size="xs">
                      {showLoadingIcon ? (
                        <Box>
                          <Spinner size="normal"></Spinner>
                        </Box>
                      ) : (
                        <Box></Box>
                      )}
                      <Button
                        variant="normal"
                        iconName="download"
                        onClick={() => {downloadFilteredData()}}
                      >
                        Download filtered data
                      </Button>

                      <Button
                        variant="normal"
                        iconName="refresh"
                        onClick={() => LoadData()}
                      >

                      </Button>
                      
                    </SpaceBetween>
                  }
                >
                  Entity Region Mapping
                </Header>
              }
            >
              <div
                className="ag-theme-alpine"
                style={{ height: 150, width: "100%" }}
              >
                <Box>
                  <ColumnLayout columns={4}>
                    <Box>
                      <Box variant="awsui-key-label">Regions</Box>
                      <Select
                        selectedOption={filterRegion}
                        options={filterRegionListWithAll}
                        onChange={({ detail }) => {
                          setFilterRegion(detail.selectedOption);
                        }}
                      ></Select>
                    </Box>
                    <Box>
                      <Box variant="awsui-key-label">Entity</Box>
                      <Input value={filterEntity} onChange={({ detail }) => setFilterEntity(detail.value)} ></Input>
                    </Box>
                  </ColumnLayout>
                  <br></br>
                  <Box></Box>
                  <SpaceBetween size='m' direction='horizontal'>
                    <Button variant='normal' onClick={resetFilter}>Reset</Button>
                    <Button variant='primary' onClick={LoadData}>Filter</Button>
                  </SpaceBetween>
                  {errorMessage && (
                    <Box variant="p" color="text-status-error">
                      {errorMessage}
                    </Box>
                  )}
                </Box>
                <br></br>

              </div>

              <div
                className="ag-theme-alpine"
                style={{ height: 600, width: "100%" }}
              >
                <AgGridReact
                  ref={gridRef}
                  rowData={items}
                  defaultColDef={defaultColDef}
                  columnDefs={columnDefs}
                  rowSelection="single"
                  animateRows={true}
                  rowHeight={30}
                  onSelectionChanged={onSelectionChanged}
                  rowMultiSelectWithClick={true}
                  enableCellTextSelection={true}
                ></AgGridReact>
              </div>
            </Container>
          </Box>
        </Box>
      ) : isCheckingAccess ? (
        <div>
          <WaitForPageAccess></WaitForPageAccess>
        </div>
      ) : (
        <div>
          <AccessDenied appName={appSettings.appName}></AccessDenied>
        </div>
      )}
    </div>
  );

  return (
    <AppLayout
      breadcrumbs={
        <BreadcrumbGroup
          items={breadItems}
          onFollow={(event) => onFollowHandler(event)}
        ></BreadcrumbGroup>
      }
      content={content}
      navigation={<SideNav activeHref="/EntityRegionMapping" />}
      headerSelector="#TopBar"
      navigationHide={false}
      tools={isAuthorized ? helpContent : ''}
      toolsOpen={isAuthorized ? toolsHide : false}
      onToolsChange={() => { setToolsHide(!toolsHide) }}
      toolsWidth={300}
      headerVariant="high-contrast"
    ></AppLayout>
  );
};

export default EntityRegionMapping;
