import { configureStore, combineReducers } from "@reduxjs/toolkit";
import userBoffinSlice from './userBoffinSlice'
import authReducer from './userAuthSlice'

import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'


const persistConfig = {
    key: 'root',
    storage,
  }

  const rootReducer = combineReducers({ 
    auth: authReducer,
    boffin: userBoffinSlice,
  })

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const globalStore = configureStore({
    reducer: persistedReducer
})

export const persistor = persistStore(globalStore)


// export default globalStore