import React, { useState, useEffect, useRef } from 'react'
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux'

import {
    AppLayout, HelpPanel, Icon, BreadcrumbGroup, Box, Table, SpaceBetween, Header, Button,
    Container, Spinner, Pagination
} from '@amzn/awsui-components-react';
import SideNav from './SideNav';
import appSettings from './app_settings';
import AccessDenied from '../../AccessDenied'
import WaitForPageAccess from '../../WaitForPageAccess'
import './history.scss'
import checkPageAccess from "../../checkPageAccess";
import { API, graphqlOperation } from 'aws-amplify';
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { useAuth } from "../../context/AuthContextProvider";
import { Storage } from 'aws-amplify';
import { configureGenericAppSync, configureMJEAppSync, configureMJES3, configureGenericS3 } from 'src/context/AuthContextUtility'
import { PORTAL } from 'src/constants/AppConstants';
import { setCurrentAppId } from '../../store/userAuthSlice';


const History = () => {

    const userAuthenticationDetails = useAuth();
    const gridRef = useRef();
    const history = useHistory();
    const userLDAPS = userAuthenticationDetails.USER_LDAP_GROUPS;
    const [isCheckingAccess, setIsCheckingAccess] = useState(true);
    const [isAuthorized, setIsAuthorized] = useState();
    const [items, setItems] = useState([]);
    const [showLoadingIcon, setShowLoadingIcon] = useState(true);
    const [toolsHide, setToolsHide] = useState(false);
    const [totalPageCount, setTotalPageCount] = useState(1);
    const [lastEvaluatedKey, setLastEvaluatedKey] = useState(null)
    const [openEndPagination, setOpenEndPagination] = useState(true)
    const isCurrentAppAuthorised = useSelector((globalState) => globalState.auth.isCurrentAppAuthorized)
    const currentAppId = useSelector((globalState) => globalState.auth.currentAppId)

    const limit = 20
    const query_page_batch_size = 100

    const [
        currentPageIndex,
        setCurrentPageIndex
    ] = React.useState(1);

    const breadItems = [
        { text: 'Home', href: '/Home' },
        { text: 'ATeam BI', href: '/ATeamBIHome' },
        { text: 'MJE Monitoring', href: '/MJEMonitoringHome' },
        { text: 'History', href: '' }

    ];

    useEffect(() => {
        
        setup()
        return () => {
            // this now gets called when the component unmounts
            configureGenericAppSync()
            configureGenericS3()
        };
    }

        , []);


        useEffect(() => {

            if (currentAppId !== appSettings.appId) {
                dispatch(setCurrentAppId(appSettings.appId))
            }
            setIsAuthorized(isCurrentAppAuthorised)     
            return () => {
                // this now gets called when the component unmounts
            };
        }, [isCurrentAppAuthorised]);

        useEffect(() => {
            if(isAuthorized === true || isAuthorized === false){
              setIsCheckingAccess(!isCheckingAccess);
            } 
          }, [isAuthorized]);

    const setup = async () => {
        await configureMJEAppSync()
        await configureMJES3()
        loadData()
    }

    const getS3FolderPath = (item) => {
        let status = item.processingStatus.toLowerCase()
        switch (status) {
            case 'completed': return 'completed';
            case 'pending':
            case 'processing': return 'pending';
            default: return status
        }
    }

    const processIcon = props => (
        props.data.processingStatus === 'Completed' ?
            <Box>
                <Icon name='status-positive' variant='success' ></Icon>
            </Box>

            : <Box>
                <Icon name='status-in-progress' variant='normal' ></Icon>
            </Box>


    )
    const downloadFile = async (item) => {
        let folder = `mje_monitoring/${getS3FolderPath(item)}/`
        let fn = folder + item.fileId + '.csv'
        const result = await Storage.get(fn, { level: 'public', expires: 60 });
        window.open(result);
    }
    const downloadIcon = props => (
        <Button
            variant='inline-icon'
            iconName='download'
            onClick={() => {
                downloadFile(props.data)
            }
            }></Button>
    )

    const [columnDefs] = useState([
        { field: 'createDate', width: '250px', sortable: true, resizable: true },
        { field: 'processingStatus', width: '100px', headerName: 'Status', sortable: true, cellRenderer: processIcon, resizable: true },
        { field: 'processingDetails', width: '350px', sortable: true, resizable: true },
        { field: 'userId', width: '100px', sortable: true, resizable: true },
        { field: 'fileId', width: '200px', resizable: true },
        { field: '', width: '100px', cellRenderer: downloadIcon }


    ])
    const defaultColDef = {
        // set filtering on for all columns
        filter: true,
        filterParams: {
            buttons: ["reset"],
            debounceMs: 200,
        },
    };

    const calculatePageCount = (length) => {
        let rawCount = length / limit
        let roundedCount = Math.trunc(rawCount)
        if (roundedCount < rawCount) {
            roundedCount = roundedCount + 1
        }
        return roundedCount
    }
    const loadPaginatedData = async () => {
        if (lastEvaluatedKey) {
            let mQry = `
            query MyQuery {
                listUI4BI_MJE_Monitiring_File_Uploads(limit: ${limit * query_page_batch_size}, lastEvaluatedKey:{fileId: "${lastEvaluatedKey.fileId}"}) {
                Items {
                    createDate
                    processingStatus
                    processingDetails
                    fileId
                    userId
                    }
                LastEvaluatedKey {
                    fileId
                  }
                }
            }
        `
            setShowLoadingIcon(true)
            await executeQuery(mQry, function onSuccess(response) {
                // console.log(response.data.listUI4BI_MJE_Monitiring_File_Uploads.items)
                let qResultJSON = response.data.listUI4BI_MJE_Monitiring_File_Uploads.Items
                let lastEvKey = response.data.listUI4BI_MJE_Monitiring_File_Uploads.LastEvaluatedKey
                setLastEvaluatedKey(lastEvKey)
                if (qResultJSON) {
                    qResultJSON = items.concat(qResultJSON)
                    // qResultJSON.sort(function (a, b) {
                    //     var dateA = new Date(a.createDate), dateB = new Date(b.createDate);
                    //     return dateB - dateA;
                    // });
                    setItems(qResultJSON)
                    setTotalPageCount(calculatePageCount(qResultJSON.length))
                    if(lastEvKey){
                        setOpenEndPagination(true)
                    }else{
                        // end of records
                        setOpenEndPagination(false)
                    }
                }
                setShowLoadingIcon(false)
            },
                function onFailure(error) {
                    setShowLoadingIcon(false)
                })
        }

    }

    const loadData = async () => {
        let mQry = `
                query MyQuery {
                    listUI4BI_MJE_Monitiring_File_Uploads(limit: ${limit * query_page_batch_size}) {
                    Items {
                        createDate
                        processingStatus
                        processingDetails
                        fileId
                        userId
                        }
                    LastEvaluatedKey {
                        fileId
                      }
                    }
                }
            `
        setShowLoadingIcon(true)
        await executeQuery(mQry, function onSuccess(response) {
            // console.log(response.data.listUI4BI_MJE_Monitiring_File_Uploads.items)
            let qResultJSON = response.data.listUI4BI_MJE_Monitiring_File_Uploads.Items
            let lastEvKey = response.data.listUI4BI_MJE_Monitiring_File_Uploads.LastEvaluatedKey
            setLastEvaluatedKey(lastEvKey)
            qResultJSON.sort(function (a, b) {
                var dateA = new Date(a.createDate), dateB = new Date(b.createDate);
                return dateB - dateA;
            });
            setItems(qResultJSON)
            setTotalPageCount(calculatePageCount(qResultJSON.length))
            setShowLoadingIcon(false)
            setOpenEndPagination(true)
            setCurrentPageIndex(1)
            if(lastEvKey){
                setOpenEndPagination(true)
            }else{
                // end of records
                setOpenEndPagination(false)
            }
        },
            function onFailure(error) {
                setShowLoadingIcon(false)
            })
    }

    const executeQuery = async (qry, onSuccess, onFailure) => {

        // console.log(mQry)
        await API.graphql(graphqlOperation(qry)).then(result => {
            onSuccess(result)
        }, function (error) {
            onFailure(error)
        });
    }

    const onPageChanged = (detail) => {
        setCurrentPageIndex(detail.currentPageIndex)
        if (detail.currentPageIndex == totalPageCount && openEndPagination) {
            loadPaginatedData()
        }
    }

    const getPaginatedItems = () => {
        let start = limit * (currentPageIndex - 1)
        return items.slice(start, start + limit)
    }

    const onFollowHandler = (ev) => {

        ev.preventDefault();
        if (ev.detail.href) {
            history.push(ev.detail.href.substring(1));
        }
    }

    const content = (
        <div>
            {isAuthorized ?
                <Box>
                    <Box>
                        <Container
                            header={
                                <Header
                                    variant='h2'
                                    actions={
                                        <SpaceBetween direction="horizontal" size="xs">

                                            <Button variant='normal' iconName='refresh' onClick={loadData}></Button>
                                            <Button variant='normal' iconName='close' onClick={() => { history.push('/MJEMonitoringNewUpload') }}></Button>
                                        </SpaceBetween>
                                    }
                                    description="The following table shows all the files uploaded."
                                >File Uploads
                                    {showLoadingIcon ?
                                        <Spinner size='normal'></Spinner> : <div></div>
                                    }

                                </Header>
                            }
                            fitHeight
                        >
                            <div
                                className="ag-theme-alpine"
                                style={{ height: 400, width: "100%" }}
                            >
                                <AgGridReact
                                    ref={gridRef}
                                    rowData={getPaginatedItems()}
                                    defaultColDef={defaultColDef}
                                    columnDefs={columnDefs}
                                    rowSelection='single'
                                    animateRows={true}
                                    rowHeight={30}
                                    enableCellTextSelection={true}
                                >
                                </AgGridReact>
                            </div>
                            <div className='pagination_box'>
                                <div className='paginator'>
                                    {openEndPagination ?
                                        <Pagination
                                            currentPageIndex={currentPageIndex}
                                            onChange={({ detail }) =>
                                                onPageChanged(detail)
                                            }
                                            openEnd
                                            pagesCount={totalPageCount}
                                        /> :
                                        <Pagination
                                            currentPageIndex={currentPageIndex}
                                            onChange={({ detail }) =>
                                                onPageChanged(detail)
                                            }
                                            pagesCount={totalPageCount}
                                        />}
                                </div>
                            </div>
                        </Container>
                    </Box>
                </Box>
                : isCheckingAccess ?
                    <div>
                        <WaitForPageAccess></WaitForPageAccess>
                    </div> :
                    <div>
                        <AccessDenied appName={appSettings.appName}></AccessDenied>
                    </div>
            }
        </div>
    )

    const helpContent = (
        <HelpPanel

            header={<div>
                <div>

                </div>
                <h2>Additional Info </h2>

            </div>}
        >
            <div>

                <p>What the icons mean?</p>
                <p><Icon variant='normal' name="status-in-progress" ></Icon> = In Progress</p>
                <p><Icon name='status-positive' variant="success"></Icon> = Completed</p>
                <p></p>
            </div>
            <div>

            </div>
        </HelpPanel>
    )

    return (
        <AppLayout
            breadcrumbs={<BreadcrumbGroup items={breadItems} onFollow={(event) => onFollowHandler(event)}></BreadcrumbGroup>}
            content={content}
            navigation={<SideNav activeHref="#/MJEMonitoringHistory" />}
            headerSelector="#TopBar"
            navigationHide={false}
            disableBodyScroll={true}
            tools={helpContent}
            toolsOpen={toolsHide}
            onToolsChange={() => { setToolsHide(!toolsHide) }}
            headerVariant="high-contrast"
        ></AppLayout>
    )
}



export default History

