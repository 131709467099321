import React, { useState, useEffect, useRef } from "react";
import SideNav from "./SideNav";
import appSettings from "./app_settings";
import { Link, useLocation } from "react-router-dom";
import { filterAPI } from "../utilities/Factory";
import favLogo from '../images/star-regular-brown.svg'
import favLogoSolid from '../images/star-solid-brown.svg'

import list from '../images/list.svg'
import grid from '../images/grid.svg'

import {
  AppLayout,
  BreadcrumbGroup,
  Container,
  Box,
  Cards,
  Button,
  Header,
  Table,
  Badge,
  Autosuggest,
  ExpandableSection,
  SpaceBetween,
  Multiselect,
  ColumnLayout,
  Tabs,
  Pagination,
  Spinner
} from "@amzn/awsui-components-react";

import "./homepage.scss";
import { useSelector, useDispatch } from "react-redux";

import { useHistory } from "react-router-dom";
import { addFavorite, listFavorites, delFavorite, getFavorite } from '../utilities/Favorites'
import Favorite from 'src/generic-components/Favorite';
import { TEMPLATE_APP, TEMPLATE_APP_PERMISSION, TEMPLATE_APP_TEAM, TEMPLATE_APP_OUTPUT, TEMPLATE_APP_ACCOUNTING, TEMPLATE_APP_USE_CASE, TEMPLATE_APP_SOURCE_DATA_TABLES, TEMPLATE_APP_CHART_OF_ACCOUNTS } from 'src/Ateam-BI/Templates/Constants';
import { API, graphqlOperation } from "aws-amplify";
import { getEditableFields } from "src/Ateam-BI/Templates/Utils/FormMetadata";
import { setCurrentAppId } from "src/store/userAuthSlice";

const AllProducts = () => {
  const history = useHistory();
  const isCurrentAppAuthorised = useSelector(
    (globalState) => globalState.auth.isCurrentAppAuthorized
  );
  const userId = useSelector((globalState) => globalState.auth.userId);

  const [isCheckingAccess] = useState(true);
  const [, setIsAuthorized] = useState(false);
  const [query, setQuery] = useState({
    tokens: [],
    operation: "and",
  });
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);

  const [isProducsLoading, setIsProducsLoading] = useState(true);
  const [toolsHide, setToolsHide] = useState(true);

  const [filteringOptions, setFilteringOptions] = useState([]);
  const [NavOpen, setNavOpen] = useState(true);
  const [toolsOpen, setToolsOpen] = useState(false);

  const [value, setValue] = useState("");

  const [selectedOptions, setSelectedOptions] = useState();

  const [organizations, setOrganizations] = useState([]);
  const [useCaseTypes, setUseCaseTypes] = useState([]);
  const [allAccountingVps, setAllAccountingVps] = useState([]);
  const [processOutputs, setProcessOutputs] = useState([]);
  
  const [allSuggestions, setAllSuggestions] = useState([])
  const [searchSuggestions, setSearchSuggestions] = useState([])
  const [favoritesMap, setFavoritesMap] = useState({});
  const [favDisabled, setFavDisabled] = useState(false);
  const [activeTabId, setActiveTabId] = useState(false);
  const [changeToggle, setChangeToggle] = useState(false);
  const [currentPageIndex, setCurrentPageIndex] = useState(1);
  const [pagesCount, setPagesCount] = useState()
  const [pageItems, setPageItems] = useState()
  const [fieldMetadata, setFieldMetadata] = useState({});
  const [loader, setLoader] = useState(false);
  const [loaderRequestsQueue, setLoaderRequestsQueue] = useState(new Set());
  const [view, setView] = useState('Grid')
  const qryRef = useRef(null);
  const [filters, setFilters] = useState({
    searchQuery: "",
    orgs: [],
    types: [],
    accountingVps: [],
    processOutput: []
  });
  const dispatch = useDispatch();
  const search = useLocation().search
  const searchParams = new URLSearchParams(search)

  useEffect(() => {

    loadProducts();
    setActiveTabId(searchParams.get("section") ? searchParams.get("section") : 'default')

    return () => {
      // this now gets called when the component unmounts
    };
  }, []);

  useEffect(() => {
    dispatch(setCurrentAppId(''));
  },[])


  useEffect(() => {
    setPageItems(
      filteredProducts
        .slice(15 * (currentPageIndex - 1), 15 * (currentPageIndex))
    )
    
  }, [currentPageIndex, filteredProducts])

  useEffect(() => {
    const orgs = fieldMetadata[TEMPLATE_APP.ORGANIZATION]?.values;
    setOrganizations(orgs);


    const types = fieldMetadata[TEMPLATE_APP.USE_CASE]?.values;
    setUseCaseTypes(types);

    const processOutputs = fieldMetadata[TEMPLATE_APP.OUTPUT]?.values;
    setProcessOutputs(processOutputs);

  }, [fieldMetadata])

  useEffect(() => {

    const orgs = fieldMetadata[TEMPLATE_APP.ORGANIZATION]?.values;
    setOrganizations(orgs);


    const types = fieldMetadata[TEMPLATE_APP.USE_CASE]?.values;
    setUseCaseTypes(types);

    const processOutputs = fieldMetadata[TEMPLATE_APP.OUTPUT]?.values;
    setProcessOutputs(processOutputs);

    generateFilterDropdown(TEMPLATE_APP.VP);
    

    const suggestions = products.map((product) => (
      {
        value: product.app_name,
        description: product.app_description
      }
    ))
    setAllSuggestions(suggestions)
    setSearchSuggestions(suggestions)

    setPagesCount(Math.ceil(products.length / 15))
  }, [products]);

  useEffect(() => {
    // const filterKeyMapper = {
    //   filterOn: "apps",
    //   orgs: TEMPLATE_APP.ORGANIZATION,
    //   types: TEMPLATE_APP.USE_CASE,
    //   accountingVps: TEMPLATE_APP.VP
    // };

    // const filteredApps = filterApps(filterKeyMapper)
    // setFilteredProducts(filteredApps);
    // setPagesCount(Math.ceil(filteredApps.length/15))
    // setCurrentPageIndex(1)
    queryApps()
  }, [filters]);


  const generateFilterDropdown = async(dynamoDBKey) => {

    // Get this values from DB at all times, cannot use the values from previous response because they might have been filtered responses already
    let mQry = `
    query MyQuery {
      getAppsV2  {
          ${TEMPLATE_APP.VP}
      }
    }  
        `;
    let response = await API.graphql(graphqlOperation(mQry));
    let qResultJSON = response.data.getAppsV2;
    const tempFiltered = qResultJSON
      .filter(
        (p) =>
          p[dynamoDBKey] &&
          p[dynamoDBKey] !== "" &&
          p[dynamoDBKey] !== "To be updated"
      );
    const dropDown = []
    let valueSet = new Set()
    tempFiltered.forEach((qResultJSON) => {

      if (qResultJSON.hasOwnProperty(dynamoDBKey)) {

        qResultJSON[dynamoDBKey].forEach((val) => {
          (val == 'nan') ? '' : valueSet.add(val.trim())
        })
      }
    });

    valueSet.forEach((val) => {
      dropDown.push({
        label: val,
        value: val
      });
    })

    setAllAccountingVps(dropDown);
  }

  const clearFavoriteMap = () => {
    let current_keys = Object.keys(favoritesMap)
    current_keys.forEach((key) => {
      delete favoritesMap[key]
    });
  }

  const makeFavoriteMap = (items) => {
    items.forEach((item, index) => {
      favoritesMap[item.preference_identifier] = true
    });
  }

  const getAllFavorites = async () => {
    listFavorites(userId, function onSuccess(result) {
      clearFavoriteMap()
      makeFavoriteMap(result.data.listUserPreferences.items)
      setFavDisabled(false)
    },
      function onFailure(result) {

      })
  }

  const isFavorite = async (appId) => {
    getFavorite(appId, userId, function onSuccess(result) {
      favoritesMap[appId] = true
    },
      function onFailure(result) {
        favoritesMap[appId] = false
      })
  }

  const getItemsForFilterFromDropdown = (items) => {
    let res = []
    items.forEach((item) => {
      res.push(item.value)
    })
    return res
  }

  const getFilters = () => {
    let qry = []
    let filtersApplied = []
    if (filters?.searchQuery) {
      qry.push(`searchString: "${filters.searchQuery}"`)
    }
    if (filters?.orgs?.length > 0) {
      filtersApplied.push(`{type:"${TEMPLATE_APP.ORGANIZATION}",values:${JSON.stringify(getItemsForFilterFromDropdown(filters.orgs))}}`)
    }
    if (filters?.types) {
      filtersApplied.push(`{type:"${TEMPLATE_APP.USE_CASE}",values:${JSON.stringify(getItemsForFilterFromDropdown(filters.types))}}`)
    }
    if (filters?.accountingVps) {
      filtersApplied.push(`{type:"${TEMPLATE_APP.VP}",values:${JSON.stringify(getItemsForFilterFromDropdown(filters.accountingVps))}}`)
    }
    if (filters?.processOutput) {
      filtersApplied.push(`{type:"${TEMPLATE_APP.OUTPUT}",values:${JSON.stringify(getItemsForFilterFromDropdown(filters.processOutput))}}`)
    }

    if (filtersApplied?.length > 0) {
      let tempFilterQry = ''
      tempFilterQry = tempFilterQry + '['
      tempFilterQry = tempFilterQry + filtersApplied.join(',')
      tempFilterQry = tempFilterQry + ']'
      qry.push(`filters:${tempFilterQry}`)
    }

    if (qry.length > 0) {
      return `(${qry.join(",")})`
    } else {
      return ''
    }

  }

  const loadProducts = async () => {
    setLoader(true)
    await getAllFavorites()
    await loadSearchDropDownValues();
    await queryApps()
  };

  const queryApps= async () => {
    setLoader(true)
    let mQry = `
    query MyQuery {
      getAppsV2 ${getFilters()} {
        ${TEMPLATE_APP.APP_DESCRIPTION}
          ${TEMPLATE_APP.APP_NAME}
          ${TEMPLATE_APP.APP_ID}
          ${TEMPLATE_APP.TEMPLATE_ID}
          ${TEMPLATE_APP.URL}
          ${TEMPLATE_APP.PROCESS_OWNER}
          ${TEMPLATE_APP.FLASH_INFO_DESCRIPTION}
          ${TEMPLATE_APP.DEV_ALERT_MESSAGES}
          ${TEMPLATE_APP.TDD}
          ${TEMPLATE_APP.STATUS}
          ${TEMPLATE_APP.REGION}
          ${TEMPLATE_APP.CADENCE}
          ${TEMPLATE_APP.PROCESS_MJE_COUNT}
          ${TEMPLATE_APP.ANNUAL_MER_SAVINGS}
          ${TEMPLATE_APP.PDD}
          ${TEMPLATE_APP.LEDGER_INFO}
          ${TEMPLATE_APP.LAUNCH_MCM}
          ${TEMPLATE_APP.LAUNCH_QQ_YY}
          ${TEMPLATE_APP.INPUT_SOURCE_TYPE}
          ${TEMPLATE_APP.AUTOMATION_TOOLS}
          ${TEMPLATE_APP.ARD}
          ${TEMPLATE_APP.DATASOURCE}
          ${TEMPLATE_APP.ORGANIZATION}
          ${TEMPLATE_APP.FINANCIAL_RELEVANCE}
          ${TEMPLATE_APP.FINANCIAL_YEAR_IMPACT}
          ${TEMPLATE_APP.GRC_CONTROL_ID}
          ${TEMPLATE_APP.VP}
          ${TEMPLATE_APP.CREATE_TIME_STAMP}
          ${TEMPLATE_APP.CREATED_BY}
          ${TEMPLATE_APP.LAST_UPDATED_TIME_STAMP}
          ${TEMPLATE_APP.LAST_UPDATED_BY}
          ${TEMPLATE_APP.SIM_TICKET_URL}
          ${TEMPLATE_APP.SIM_TICKET_LABEL}
          ${TEMPLATE_APP.CHART_OF_ACCOUNT}{
            ${TEMPLATE_APP_CHART_OF_ACCOUNTS.CHART_OF_ACCOUNTS_PK}
            ${TEMPLATE_APP_CHART_OF_ACCOUNTS.FPA_DIM_ACCOUNTS}
          }
          ${TEMPLATE_APP.SOURCE_DATA_TABLES}{
            ${TEMPLATE_APP_SOURCE_DATA_TABLES.DESCRIPTION}
            ${TEMPLATE_APP_SOURCE_DATA_TABLES.SCHEMA}
            ${TEMPLATE_APP_SOURCE_DATA_TABLES.SOURCE_TABLE_PK}
            ${TEMPLATE_APP_SOURCE_DATA_TABLES.TABLE}
            ${TEMPLATE_APP_SOURCE_DATA_TABLES.DATABASE}
            ${TEMPLATE_APP_SOURCE_DATA_TABLES.UPSTREAM_SOURCE}
          }
          ${TEMPLATE_APP.USE_CASE}
          ${TEMPLATE_APP.PERMISSION}{
            ${TEMPLATE_APP_PERMISSION.LINK}
            ${TEMPLATE_APP_PERMISSION.NAME}
            ${TEMPLATE_APP_PERMISSION.APP_PERMISSION_PK}
          }
          ${TEMPLATE_APP.DEVELOPER_TEAM}{
            ${TEMPLATE_APP_TEAM.TEAM}
            ${TEMPLATE_APP_TEAM.EMAIL}
          }
          ${TEMPLATE_APP.OUTPUT}{
            ${TEMPLATE_APP_OUTPUT.TYPE}
            ${TEMPLATE_APP_OUTPUT.VALUE}
            ${TEMPLATE_APP_OUTPUT.LABEL}
            ${TEMPLATE_APP_OUTPUT.APP_OUTPUT_PK}
          }
          ${TEMPLATE_APP.ACCOUNTING_TEAM} {
            ${TEMPLATE_APP_TEAM.TEAM}
            ${TEMPLATE_APP_TEAM.EMAIL}
          }
      }
    }  
        `;
    // latest qry
    qryRef.current=mQry

    var utc = Date.now();
    // console.log("-----START QUERY------")
    // console.log(utc)
    let response = await API.graphql(graphqlOperation(mQry));
    let qResultJSON = response.data.getAppsV2;
    // console.log("-----END QUERY------")
    // console.log(utc, Date.now()-utc)

    if(qryRef.current == mQry){
      setProducts(qResultJSON);
      setFilteredProducts(qResultJSON);
      setLoader(false)
      setCurrentPageIndex(1)
      setIsProducsLoading(false);
    }
    
  }

  const loadSearchDropDownValues = async () => {
    let res = await getEditableFields()
    setFieldMetadata(res)
  }
  const favorite = async (appId) => {
    await isFavorite(appId)
    setFavDisabled(true)
    if (favoritesMap && favoritesMap[appId]) {
      await delFavorite(appId, userId, function onSuccess(result) {
        delete favoritesMap[appId]
      }, function onFailure(result) { })
    } else {
      await addFavorite(appId, userId, "default", function onSuccess(result) {
        favoritesMap[appId] = true
      }, function onFailure(result) { })
    }
    setFavDisabled(false)
  }

  const onFollowHandler = (ev) => {
    ev.preventDefault();
    if (ev.detail.href) {
      history.push(ev.detail.href.substring(1));
    }
  };
  const breadItems = [
    { text: "Home", href: "/Home" },
    { text: "Apps", href: "" },
  ];

  const getCommaSeperatedOutputs = (item) => {
    let types = []
    item.output.forEach((output) => {
      types.push(output.type)
    })
    return types.join(', ')
  }

  const getUrl = (item) => {
    if (item.url) {
      return item.url
    } else {
      return `App/${item.app_id}`
    }
  }
  const AllApps = (
    <>{loader ? (<Box>
      <Spinner></Spinner>
      Loading...
    </Box>) :
      (
        view == 'Grid' ? <Cards
        cardDefinition={{
          header: item => (
            <div className="header_menu">
              <div className="link">
                <Link to={getUrl(item)}> {item.app_name}</Link>
              </div>
              <div className={favDisabled ? "icon_disabled" : "icon"} disabled={favDisabled} >
                {favoritesMap[item.app_id] && (<img className="favorite_icon" src={favLogoSolid} onClick={() => favorite(item.app_id)}></img>)}
                {!favoritesMap[item.app_id] && (<img className="favorite_icon" src={favLogo} onClick={() => favorite(item.app_id)}></img>)}
              </div>
            </div>
          ),
          sections: [
            {
              id: "app_description",
              header: "",
              content: (item) => item.app_description,
            },
            {
              id: "organization",
              header: "Organization",
              content: (item) => (item.organization ? item.organization.join(', ') : "Not Available"),
            },
            {
              id: "useCaseType",
              header: "Use Case Type",
              content: (item) => (item.use_case ? item.use_case.join(', ') : "Not Available"),
            },
            {
              id: "processOutput",
              header: "Process output",
              content: (item) => (item.output ? getCommaSeperatedOutputs(item) : "Not Available"),
            },
            {
              id: "vp",
              header: "VP",
              content: (item) => (item.vp ? item.vp.join(', ') : "Not Available"),
            }
          ]
        }}
        header={
          <Header
            counter={
              pageItems
                ? "(" + filteredProducts.length + ")"
                : ""
            }
          >
            <span color="text-body-secondary">Results</span>
          </Header>
        }
        stickyHeader
        cardsPerRow={[{ minWidth: 768, cards: 3 }]}
        items={pageItems}
        loading={isProducsLoading}
        loadingText="Loading resources"
        empty={
          <Box textAlign="center" color="inherit">
            <b>No applications</b>
            <Box
              padding={{ bottom: "s" }}
              variant="p"
              color="inherit"
            >
              No applications to display.
            </Box>
          </Box>
        }

        pagination={
          <Pagination currentPageIndex={currentPageIndex}
            onChange={({ detail }) =>
              setCurrentPageIndex(detail.currentPageIndex)
            }
            pagesCount={pagesCount} />
        }
      />: 
      <Table
      columnDefinitions={[
        {
          id: "fav",
          cell: e => <div className={favDisabled ? "center_disabled" : "center"} disabled={favDisabled} >
          {favoritesMap[e.app_id] && (<img className="favorite_icon" src={favLogoSolid} onClick={() => favorite(e.app_id)}></img>)}
          {!favoritesMap[e.app_id] && (<img className="favorite_icon" src={favLogo} onClick={() => favorite(e.app_id)}></img>)}
        </div> ,
        width: 50,
          isRowHeader: true
        },
        {
          id: "name",
          header: "Name",
          cell: e => <Link to={getUrl(e)}> {e.app_name}</Link> ,
          width: 300,
        },
        {
          id: "app_description",
          header: "Description",
          cell: e => e.app_description,
          width: 350,
        },
        {
          id: "organization",
          header: "Organization",
          cell: e => (e.organization ? e.organization.join(', ') : "Not Available"),
          width: 200,
        },
        {
          id: "useCaseType",
          header: "Use Case Type",
          cell: e => (e.use_case ? e.use_case.join(', ') : "Not Available"),
          width: 200,
        },
        {
          id: "processOutput",
          header: "Process output",
          cell: e => (e.output ? getCommaSeperatedOutputs(e) : "Not Available"),
          width: 200,
        },
        {
          id: "vp",
          header: "VP",
          cell: e => (e.vp ? e.vp.join(', ') : "Not Available"),
          width: 200,
        }
      ]}
      items={pageItems}
      loadingText="Loading resources"
      resizableColumns
      empty={
        <Box textAlign="center" color="inherit">
          <b>No applications</b>
          <Box
            padding={{ bottom: "s" }}
            variant="p"
            color="inherit"
          >
            No applications to display.
          </Box>
        </Box>
      }
      header={
        <Header
          counter={
            pageItems
              ? "(" + filteredProducts.length + ")"
              : ""
          }
        >
          <span color="text-body-secondary">Results</span>
        </Header>
      }
      pagination={
        <Pagination currentPageIndex={currentPageIndex}
          onChange={({ detail }) =>
            setCurrentPageIndex(detail.currentPageIndex)
          }
          pagesCount={pagesCount} />
      }
    />
      )
    }
    </>
  )

  const onFavoriteChange = () => {
    //loadProducts()
    getAllFavorites()
  }
  const FavoriteUI = (
    <>
      {loader ? (<Box>
        <Spinner></Spinner>
        Loading...
      </Box>) : (<Box>
        <Favorite userId={userId} allApps={filteredProducts} changeToggle={onFavoriteChange} view={view} />
      </Box> )}
    </>
  )

  const Content = (
    <Box>
      <br></br>
      <Container
        header={
          <div>
            <Box variant="h1" color="text-body-secondary">
              Accounting Apps
            </Box>
            <Box variant="h5" color="text-body-secondary">
              Search all Accounting Applications
            </Box>
          </div>
        }
      >
        <Autosuggest
          onChange={({ detail }) =>
            setFilters((previous) => ({
              ...previous,
              searchQuery: detail.value,
            }))
          }
          value={filters.searchQuery}
          placeholder="Search Accounting Central"
          enteredTextLabel={(value) => `Searching: ${value}`}
          options={searchSuggestions}
          virtualScroll
        />
        <br />
        <Box>
          <ExpandableSection
            variant="default"
            defaultExpanded={true}
            headerText="Filter Options"
          >
            <ColumnLayout columns={4}>
              <Box>
                <Box variant="awsui-key-label">Organizations</Box>
                <Multiselect
                  selectedOptions={filters.orgs}
                  onChange={({ detail }) =>
                    setFilters((previous) => ({
                      ...previous,
                      orgs: detail.selectedOptions,
                    }))
                  }
                  options={organizations}
                  placeholder="Filter on Organizations"
                />
              </Box>

              <Box>
                <Box variant="awsui-key-label">Use Case Types</Box>
                <Multiselect
                  selectedOptions={filters.types}
                  onChange={({ detail }) =>
                    setFilters((previous) => ({
                      ...previous,
                      types: detail.selectedOptions,
                    }))
                  }
                  options={useCaseTypes}
                  placeholder="Filter on Use Case Types"
                />
              </Box>

              <Box>
                <Box variant="awsui-key-label">Accounting VPs</Box>
                <Multiselect
                  selectedOptions={filters.accountingVps}
                  onChange={({ detail }) =>
                    setFilters((previous) => ({
                      ...previous,
                      accountingVps: detail.selectedOptions,
                    }))
                  }
                  options={allAccountingVps}
                  placeholder="Filter on Accounting VPs"
                />
              </Box>

              <Box>
                <Box variant="awsui-key-label">Process output</Box>
                <Multiselect
                  selectedOptions={filters.processOutput}
                  onChange={({ detail }) =>
                    setFilters((previous) => ({
                      ...previous,
                      processOutput: detail.selectedOptions,
                    }))
                  }
                  options={processOutputs}
                  placeholder="Filter on process outputs"
                />
              </Box>

            </ColumnLayout>
          </ExpandableSection>
        </Box>
      </Container>
      <Box>
        <div class='views'>
          <SpaceBetween size="m" direction="horizontal">
          <img className={view == 'Grid' ? 'button_selected' :'button_unselected'} src={grid} onClick={() => setView('Grid')}></img>
        <img className={view == 'List' ? 'button_selected' :'button_unselected'} src={list} onClick={() => setView('List')}></img>
          </SpaceBetween>
                </div>
      </Box>
      <Tabs
        tabs={[
          {
            label: "All Apps",
            id: "default",
            content: AllApps
          },
          {
            label: "Favorites",
            id: "favorites",
            content: FavoriteUI
          }
        ]}
        activeTabId={activeTabId}
        onChange={(event) => { setActiveTabId(event.detail.activeTabId) }}
      />
    </Box>
  );
  return (
    <AppLayout
      breadcrumbs={
        <BreadcrumbGroup
          items={breadItems}
          onFollow={(event) => onFollowHandler(event)}
        ></BreadcrumbGroup>
      }
      disableContentPaddings={false}
      navigation={<SideNav activeHref="/ATeamBIAllProducts" />}
      navigationOpen={NavOpen}
      content={Content}
      contentType="default"
      headerSelector="#TopBar"
      toolsHide={true}
      onNavigationChange={() => {
        setNavOpen(!NavOpen);
      }}
      headerVariant="high-contrast"
    />
  );
};

export default AllProducts;