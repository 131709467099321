import { Alert, Button } from '@amzn/awsui-components-react';
import React from 'react';

export const ErrorFallback = (error: any) => {
  return (
    <Alert
      type="error"
      dismissible={false}
      visible={true}
      header="Error"
      action={<Button onClick={() => window.location.reload()}>Try again</Button>}
    >
      {error.message}
    </Alert>
  );
};