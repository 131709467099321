import { TEMPLATE_APP, TEMPLATE_APP_CHART_OF_ACCOUNTS, TEMPLATE_APP_SOURCE_DATA_TABLES, TEMPLATE_APP_PERMISSION, TEMPLATE_APP_TEAM, TEMPLATE_APP_OUTPUT } from "../Constants";
import { FRISCO_TABLE_STATUS, PROCESS_KEYS } from "src/Ateam-BI/Frisco/Constants";
import { PROCESS_KEYS as LOGICO_PROCESS_KEYS } from "src/Ateam-BI/Logico/Constants";

import { API, graphqlOperation } from "aws-amplify";
import * as XLSX from "xlsx";
import { configureGenericAppSync } from "src/context/AuthContextUtility";



const clean = (value) => {
  return value.replaceAll("'", "''")
}

function stringify(obj_from_json) {
  if (obj_from_json == null) {
    return null;
  }
  if (typeof obj_from_json !== "object" || Array.isArray(obj_from_json)) {
    // not an object, stringify using native function
    return JSON.stringify(obj_from_json);
  }
  // Implements recursive object serialization according to JSON spec
  // but without quotes around the keys.
  let props = Object
    .keys(obj_from_json)
    .map(key => `${key}:${stringify(obj_from_json[key])}`)
    .join(",");
  return `{${props}}`;
}

const cleanAll = (value) => {
  let cleaned = null
  if (value == null) {
  } else if (value instanceof Array) {
    cleaned = []
    value.forEach((item) => {
      if (typeof (item) == 'string') {
        cleaned.push(JSON.stringify(clean(item)))
      } else {
        cleaned.push(stringify(item))
      }
    })
    cleaned = `[${cleaned}]`
  } else {
    cleaned = stringify(value)
  }
  return cleaned
}

export const getQueryInput = (data, keysToIgnore = [TEMPLATE_APP.CREATE_TIME_STAMP, TEMPLATE_APP.LAST_UPDATED_TIME_STAMP, TEMPLATE_APP.DEV_ALERT_MESSAGES]) => {
  let val = []
  Object.keys(data).forEach(function (key) {
    if (keysToIgnore.includes(key)) {
      return;
    }
    if (data[key] !== null && data[key] !== undefined) {
      let value = data[key]
      if (typeof (value) == 'string') {
        value = clean(value)
        val.push(`${key}:${JSON.stringify(value)}`)
      } else if (value instanceof Array) {
        value = cleanAll(value)
        val.push(`${key}:${value}`)
      } else {
        value = cleanAll(value)
        val.push(`${key}:${value}`)
      }
    } else {
      val.push(`${key}:null`)
    }
  })
  return val.join(",")
}

const getMetadataInput = (data) => {
  let val = []
  let keysToIgnore = []
  Object.keys(data).forEach(function (key) {
    if (keysToIgnore.includes(key)) {
      return;
    }
    if (data[key]) {
      let value = data[key]
      if (typeof (value) == 'string') {
        value = clean(value)
        val.push(`${key}:${JSON.stringify(value)}`)
      } else if (value instanceof Array) {
        value = cleanAll(value)
        val.push(`${key}:${value}`)
      } else {
        value = cleanAll(value)
        val.push(`${key}:${value}`)
      }
    } else {
      val.push(`${key}:null`)
    }
  })
  return val.join(",")
}

export const updateApp = (data, onSuccess, onFailure) => {
  let qry = `mutation MyMutation {
      updateAppV2(
        input: {
          ${getQueryInput(data)}}) {
            ${TEMPLATE_APP.APP_DESCRIPTION}
            ${TEMPLATE_APP.APP_NAME}
            ${TEMPLATE_APP.APP_ID}
            ${TEMPLATE_APP.TEMPLATE_ID}
            ${TEMPLATE_APP.URL}
            ${TEMPLATE_APP.PROCESS_OWNER}
            ${TEMPLATE_APP.GRC_CONTROL_OWNER}
            ${TEMPLATE_APP.TDD}
            ${TEMPLATE_APP.STATUS}
            ${TEMPLATE_APP.REGION}
            ${TEMPLATE_APP.CADENCE}
            ${TEMPLATE_APP.PROCESS_MJE_COUNT}
            ${TEMPLATE_APP.FLASH_INFO_DESCRIPTION}
            ${TEMPLATE_APP.DEV_ALERT_MESSAGES}
            ${TEMPLATE_APP.ANNUAL_MER_SAVINGS}
            ${TEMPLATE_APP.PDD}
            ${TEMPLATE_APP.LEDGER_INFO}
            ${TEMPLATE_APP.LAUNCH_MCM}
            ${TEMPLATE_APP.LAUNCH_QQ_YY}
            ${TEMPLATE_APP.INPUT_SOURCE_TYPE}
            ${TEMPLATE_APP.AUTOMATION_TOOLS}
            ${TEMPLATE_APP.ARD}
            ${TEMPLATE_APP.DATASOURCE}
            ${TEMPLATE_APP.ORGANIZATION}
            ${TEMPLATE_APP.FINANCIAL_RELEVANCE}
            ${TEMPLATE_APP.FINANCIAL_YEAR_IMPACT}
            ${TEMPLATE_APP.VP}
            ${TEMPLATE_APP.CREATE_TIME_STAMP}
            ${TEMPLATE_APP.CREATED_BY}
            ${TEMPLATE_APP.LAST_UPDATED_TIME_STAMP}
            ${TEMPLATE_APP.LAST_UPDATED_BY}
            ${TEMPLATE_APP.SIM_TICKET_URL}
            ${TEMPLATE_APP.SIM_TICKET_LABEL}
            ${TEMPLATE_APP.CHART_OF_ACCOUNT}{
              ${TEMPLATE_APP_CHART_OF_ACCOUNTS.CHART_OF_ACCOUNTS_PK}
              ${TEMPLATE_APP_CHART_OF_ACCOUNTS.FPA_DIM_ACCOUNTS}
            }
            ${TEMPLATE_APP.SOURCE_DATA_TABLES}{
              ${TEMPLATE_APP_SOURCE_DATA_TABLES.DESCRIPTION}
              ${TEMPLATE_APP_SOURCE_DATA_TABLES.SCHEMA}
              ${TEMPLATE_APP_SOURCE_DATA_TABLES.SOURCE_TABLE_PK}
              ${TEMPLATE_APP_SOURCE_DATA_TABLES.TABLE}
              ${TEMPLATE_APP_SOURCE_DATA_TABLES.DATABASE}
              ${TEMPLATE_APP_SOURCE_DATA_TABLES.UPSTREAM_SOURCE}
            }
            ${TEMPLATE_APP.USE_CASE}
            ${TEMPLATE_APP.PERMISSION}{
              ${TEMPLATE_APP_PERMISSION.LINK}
              ${TEMPLATE_APP_PERMISSION.NAME}
              ${TEMPLATE_APP_PERMISSION.APP_PERMISSION_PK}
            }
            ${TEMPLATE_APP.DEVELOPER_TEAM}{
              ${TEMPLATE_APP_TEAM.TEAM}
              ${TEMPLATE_APP_TEAM.EMAIL}
            }
            ${TEMPLATE_APP.OUTPUT}{
              ${TEMPLATE_APP_OUTPUT.TYPE}
              ${TEMPLATE_APP_OUTPUT.VALUE}
              ${TEMPLATE_APP_OUTPUT.LABEL}
              ${TEMPLATE_APP_OUTPUT.APP_OUTPUT_PK}
            }
            ${TEMPLATE_APP.ACCOUNTING_TEAM} {
              ${TEMPLATE_APP_TEAM.TEAM}
              ${TEMPLATE_APP_TEAM.EMAIL}
            }
      }
    }
    `
  // console.log(qry)
  executeQuery(qry, onSuccess, onFailure)
}

export const addApp = (data, onSuccess, onFailure) => {
  let qry = `mutation MyMutation {
      addAppV2(
        input: {
          ${getQueryInput(data)}}) {
            ${TEMPLATE_APP.APP_DESCRIPTION}
            ${TEMPLATE_APP.APP_NAME}
            ${TEMPLATE_APP.APP_ID}
            ${TEMPLATE_APP.TEMPLATE_ID}
            ${TEMPLATE_APP.URL}
            ${TEMPLATE_APP.PROCESS_OWNER}
            ${TEMPLATE_APP.TDD}
            ${TEMPLATE_APP.STATUS}
            ${TEMPLATE_APP.REGION}
            ${TEMPLATE_APP.CADENCE}
            ${TEMPLATE_APP.PROCESS_MJE_COUNT}
            ${TEMPLATE_APP.ANNUAL_MER_SAVINGS}
            ${TEMPLATE_APP.PDD}
            ${TEMPLATE_APP.LEDGER_INFO}
            ${TEMPLATE_APP.LAUNCH_MCM}
            ${TEMPLATE_APP.LAUNCH_QQ_YY}
            ${TEMPLATE_APP.INPUT_SOURCE_TYPE}
            ${TEMPLATE_APP.AUTOMATION_TOOLS}
            ${TEMPLATE_APP.ARD}
            ${TEMPLATE_APP.DATASOURCE}
            ${TEMPLATE_APP.ORGANIZATION}
            ${TEMPLATE_APP.FINANCIAL_RELEVANCE}
            ${TEMPLATE_APP.FINANCIAL_YEAR_IMPACT}
            ${TEMPLATE_APP.VP}
            ${TEMPLATE_APP.CREATE_TIME_STAMP}
            ${TEMPLATE_APP.CREATED_BY}
            ${TEMPLATE_APP.LAST_UPDATED_TIME_STAMP}
            ${TEMPLATE_APP.LAST_UPDATED_BY}
            ${TEMPLATE_APP.SIM_TICKET_URL}
            ${TEMPLATE_APP.CHART_OF_ACCOUNT}{
              ${TEMPLATE_APP_CHART_OF_ACCOUNTS.CHART_OF_ACCOUNTS_PK}
              ${TEMPLATE_APP_CHART_OF_ACCOUNTS.FPA_DIM_ACCOUNTS}
            }
            ${TEMPLATE_APP.SOURCE_DATA_TABLES}{
              ${TEMPLATE_APP_SOURCE_DATA_TABLES.DESCRIPTION}
              ${TEMPLATE_APP_SOURCE_DATA_TABLES.SCHEMA}
              ${TEMPLATE_APP_SOURCE_DATA_TABLES.SOURCE_TABLE_PK}
              ${TEMPLATE_APP_SOURCE_DATA_TABLES.TABLE}
              ${TEMPLATE_APP_SOURCE_DATA_TABLES.UPSTREAM_SOURCE}
            }
            ${TEMPLATE_APP.USE_CASE}
            ${TEMPLATE_APP.PERMISSION}{
              ${TEMPLATE_APP_PERMISSION.LINK}
              ${TEMPLATE_APP_PERMISSION.NAME}
              ${TEMPLATE_APP_PERMISSION.APP_PERMISSION_PK}
            }
            ${TEMPLATE_APP.DEVELOPER_TEAM}{
              ${TEMPLATE_APP_TEAM.TEAM}
              ${TEMPLATE_APP_TEAM.EMAIL}
            }
            ${TEMPLATE_APP.OUTPUT}{
              ${TEMPLATE_APP_OUTPUT.TYPE}
              ${TEMPLATE_APP_OUTPUT.VALUE}
              ${TEMPLATE_APP_OUTPUT.LABEL}
              ${TEMPLATE_APP_OUTPUT.APP_OUTPUT_PK}
            }
            ${TEMPLATE_APP.ACCOUNTING_TEAM} {
              ${TEMPLATE_APP_TEAM.TEAM}
              ${TEMPLATE_APP_TEAM.EMAIL}
            }
      }
    }
    `

  //console.log(qry)
  executeQuery(qry, onSuccess, onFailure)

}

export const deleteApp = (app_id, onSuccess, onFailure) => {
  let qry = `mutation MyMutation {
        deleteAppV2(input: {app_id: "${app_id}"}) {
          message
        }
      }
      `
  executeQuery(qry, onSuccess, onFailure)

}

export const getAppPromise = async (id) => {
  var mQry = `query MyQuery {
    getAppsV2(ids: ["${id}"]) {
        ${TEMPLATE_APP.APP_DESCRIPTION}
          ${TEMPLATE_APP.APP_NAME}
          ${TEMPLATE_APP.APP_ID}
          ${TEMPLATE_APP.TEMPLATE_ID}
          ${TEMPLATE_APP.FLASH_INFO_DESCRIPTION}
          ${TEMPLATE_APP.DEV_ALERT_MESSAGES}
          ${TEMPLATE_APP.URL}
          ${TEMPLATE_APP.PROCESS_OWNER}
          ${TEMPLATE_APP.TDD}
          ${TEMPLATE_APP.STATUS}
          ${TEMPLATE_APP.REGION}
          ${TEMPLATE_APP.CADENCE}
          ${TEMPLATE_APP.PROCESS_MJE_COUNT}
          ${TEMPLATE_APP.ANNUAL_MER_SAVINGS}
          ${TEMPLATE_APP.PDD}
          ${TEMPLATE_APP.LEDGER_INFO}
          ${TEMPLATE_APP.LAUNCH_MCM}
          ${TEMPLATE_APP.LAUNCH_QQ_YY}
          ${TEMPLATE_APP.INPUT_SOURCE_TYPE}
          ${TEMPLATE_APP.AUTOMATION_TOOLS}
          ${TEMPLATE_APP.ARD}
          ${TEMPLATE_APP.DATASOURCE}
          ${TEMPLATE_APP.ORGANIZATION}
          ${TEMPLATE_APP.VP}
          ${TEMPLATE_APP.CREATE_TIME_STAMP}
          ${TEMPLATE_APP.CREATED_BY}
          ${TEMPLATE_APP.LAST_UPDATED_TIME_STAMP}
          ${TEMPLATE_APP.LAST_UPDATED_BY}
          ${TEMPLATE_APP.SIM_TICKET_URL}
          ${TEMPLATE_APP.SIM_TICKET_LABEL}
          ${TEMPLATE_APP.USE_CASE}
          ${TEMPLATE_APP.CHART_OF_ACCOUNT}{
            ${TEMPLATE_APP_CHART_OF_ACCOUNTS.CHART_OF_ACCOUNTS_PK}
            ${TEMPLATE_APP_CHART_OF_ACCOUNTS.FPA_DIM_ACCOUNTS}
          }
          ${TEMPLATE_APP.SOURCE_DATA_TABLES}{
            ${TEMPLATE_APP_SOURCE_DATA_TABLES.DESCRIPTION}
            ${TEMPLATE_APP_SOURCE_DATA_TABLES.SCHEMA}
            ${TEMPLATE_APP_SOURCE_DATA_TABLES.SOURCE_TABLE_PK}
            ${TEMPLATE_APP_SOURCE_DATA_TABLES.TABLE}
            ${TEMPLATE_APP_SOURCE_DATA_TABLES.DATABASE}
            ${TEMPLATE_APP_SOURCE_DATA_TABLES.UPSTREAM_SOURCE}
          }
          ${TEMPLATE_APP.PERMISSION}{
            ${TEMPLATE_APP_PERMISSION.LINK}
            ${TEMPLATE_APP_PERMISSION.NAME}
            ${TEMPLATE_APP_PERMISSION.APP_PERMISSION_PK}
          }
          ${TEMPLATE_APP.DEVELOPER_TEAM}{
            ${TEMPLATE_APP_TEAM.TEAM}
            ${TEMPLATE_APP_TEAM.EMAIL}
          }
          ${TEMPLATE_APP.OUTPUT}{
            ${TEMPLATE_APP_OUTPUT.TYPE}
            ${TEMPLATE_APP_OUTPUT.VALUE}
            ${TEMPLATE_APP_OUTPUT.LABEL}
            ${TEMPLATE_APP_OUTPUT.APP_OUTPUT_PK}
          }
          ${TEMPLATE_APP.ACCOUNTING_TEAM} {
            ${TEMPLATE_APP_TEAM.TEAM}
            ${TEMPLATE_APP_TEAM.EMAIL}
          }
      }
    }  
`;
  // console.log(mQry)
  return API.graphql(graphqlOperation(mQry))
};

export const getAllAppsForConsoilidatedSheet = async () => {
  let mQry = `
  query MyQuery {
    getAppsV2 {
      ${TEMPLATE_APP.APP_ID}
      ${TEMPLATE_APP.APP_NAME}
      ${TEMPLATE_APP.APP_DESCRIPTION}
        ${TEMPLATE_APP.TEMPLATE_ID}
        ${TEMPLATE_APP.URL}
        ${TEMPLATE_APP.PROCESS_OWNER}
        ${TEMPLATE_APP.GRC_CONTROL_OWNER}
        ${TEMPLATE_APP.FLASH_INFO_DESCRIPTION}
        ${TEMPLATE_APP.DEV_ALERT_MESSAGES}
        ${TEMPLATE_APP.TDD}
        ${TEMPLATE_APP.STATUS}
        ${TEMPLATE_APP.REGION}
        ${TEMPLATE_APP.CADENCE}
        ${TEMPLATE_APP.PROCESS_MJE_COUNT}
        ${TEMPLATE_APP.ANNUAL_MER_SAVINGS}
        ${TEMPLATE_APP.PDD}
        ${TEMPLATE_APP.LEDGER_INFO}
        ${TEMPLATE_APP.LAUNCH_MCM}
        ${TEMPLATE_APP.LAUNCH_QQ_YY}
        ${TEMPLATE_APP.INPUT_SOURCE_TYPE}
        ${TEMPLATE_APP.AUTOMATION_TOOLS}
        ${TEMPLATE_APP.ARD}
        ${TEMPLATE_APP.DATASOURCE}
        ${TEMPLATE_APP.ORGANIZATION}
        ${TEMPLATE_APP.FINANCIAL_RELEVANCE}
        ${TEMPLATE_APP.FINANCIAL_YEAR_IMPACT}
        ${TEMPLATE_APP.GRC_CONTROL_ID}
        ${TEMPLATE_APP.VP}
        ${TEMPLATE_APP.CREATE_TIME_STAMP}
        ${TEMPLATE_APP.CREATED_BY}
        ${TEMPLATE_APP.LAST_UPDATED_TIME_STAMP}
        ${TEMPLATE_APP.LAST_UPDATED_BY}
        ${TEMPLATE_APP.SIM_TICKET_URL}
        ${TEMPLATE_APP.SIM_TICKET_LABEL}
        ${TEMPLATE_APP.CHART_OF_ACCOUNT}{
          ${TEMPLATE_APP_CHART_OF_ACCOUNTS.CHART_OF_ACCOUNTS_PK}
          ${TEMPLATE_APP_CHART_OF_ACCOUNTS.FPA_DIM_ACCOUNTS}
        }
        ${TEMPLATE_APP.SOURCE_DATA_TABLES}{
          ${TEMPLATE_APP_SOURCE_DATA_TABLES.DESCRIPTION}
          ${TEMPLATE_APP_SOURCE_DATA_TABLES.SCHEMA}
          ${TEMPLATE_APP_SOURCE_DATA_TABLES.SOURCE_TABLE_PK}
          ${TEMPLATE_APP_SOURCE_DATA_TABLES.TABLE}
          ${TEMPLATE_APP_SOURCE_DATA_TABLES.DATABASE}
          ${TEMPLATE_APP_SOURCE_DATA_TABLES.UPSTREAM_SOURCE}
        }
        ${TEMPLATE_APP.USE_CASE}
        ${TEMPLATE_APP.PERMISSION}{
          ${TEMPLATE_APP_PERMISSION.LINK}
          ${TEMPLATE_APP_PERMISSION.NAME}
          ${TEMPLATE_APP_PERMISSION.APP_PERMISSION_PK}
        }
        ${TEMPLATE_APP.DEVELOPER_TEAM}{
          ${TEMPLATE_APP_TEAM.TEAM}
          ${TEMPLATE_APP_TEAM.EMAIL}
        }
        ${TEMPLATE_APP.OUTPUT}{
          ${TEMPLATE_APP_OUTPUT.TYPE}
          ${TEMPLATE_APP_OUTPUT.VALUE}
          ${TEMPLATE_APP_OUTPUT.LABEL}
          ${TEMPLATE_APP_OUTPUT.APP_OUTPUT_PK}
        }
        ${TEMPLATE_APP.ACCOUNTING_TEAM} {
          ${TEMPLATE_APP_TEAM.TEAM}
          ${TEMPLATE_APP_TEAM.EMAIL}
        }
    }
  }  
      `;

  let response = await API.graphql(graphqlOperation(mQry));
  let qResult = response.data.getAppsV2;
  return qResult
  
}

export const deleteAppWithApproval = (app_id, approval_id, onSuccess, onFailure) => {
  let qry = `mutation MyMutation {
    deleteAppRequestV2(input: {app_id: "${app_id}", approval_id:"${approval_id}"}) {
        message
      }
    }
    `
  executeQuery(qry, onSuccess, onFailure)

}

export const setMetadataValues = (data, onSuccess, onFailure) => {
  let qry = `mutation MyMutation {
    setAppMetadataFieldValuesV2(
      input: [{
        ${getMetadataInput(data)}}]) {
          message
    }
  }
  `
  executeQuery(qry, onSuccess, onFailure)

}

export const getApprovalsForApp = (app_id, onSuccess, onFailure) => {
  let qry = `query MyQuery {
    getApprovalForAppV2(app_id: "${app_id}") {
      app_id
      approval_id
      next_action
    }
  }`
  executeQuery(qry, onSuccess, onFailure)
}

export const createProcess = (data, onSuccess, onFailure) => {
  let qry = `mutation MyMutation {
    createProcessV2(input: {${getQueryInput(data, [])}}
    ) {
      ${PROCESS_KEYS.app_id}
      ${PROCESS_KEYS.label}
      ${PROCESS_KEYS.description}
      ${PROCESS_KEYS.glue_job_name}
      ${PROCESS_KEYS.step_function_name}
      ${PROCESS_KEYS.environment}
      ${PROCESS_KEYS.action}
      ${PROCESS_KEYS.team}
      ${PROCESS_KEYS.business} 
      ${PROCESS_KEYS.dataset_name}
      ${PROCESS_KEYS.created_by}
      ${PROCESS_KEYS.last_updated_by}
      ${PROCESS_KEYS.created_time_stamp}
      ${PROCESS_KEYS.last_updated_time_stamp}
      ${PROCESS_KEYS.approval_required}
      ${PROCESS_KEYS.multipart_allowed}
      ${PROCESS_KEYS.redshift_table_name}
      ${PROCESS_KEYS.status}
      ${PROCESS_KEYS.sox_approved}
      ${PROCESS_KEYS.sox_evidence}
      ${PROCESS_KEYS.critical_data}
      ${PROCESS_KEYS.approvers}{
        ${PROCESS_KEYS.APPROVER_KEYS.alias}
        ${PROCESS_KEYS.APPROVER_KEYS.type}
        ${PROCESS_KEYS.APPROVER_KEYS.column_index}
      }
      ${PROCESS_KEYS.uploaders}{
        ${PROCESS_KEYS.UPLOADER_KEYS.alias}
        ${PROCESS_KEYS.UPLOADER_KEYS.type}
      }
      ${PROCESS_KEYS.file_types}
      ${PROCESS_KEYS.label}
      ${PROCESS_KEYS.process_id}
      ${PROCESS_KEYS.process_name}
      ${PROCESS_KEYS.schema}{
        ${PROCESS_KEYS.SCHEMA_KEYS.column_name}
        ${PROCESS_KEYS.SCHEMA_KEYS.data_type}
        ${PROCESS_KEYS.SCHEMA_KEYS.mandatory}
        ${PROCESS_KEYS.SCHEMA_KEYS.delsert_key}
        ${PROCESS_KEYS.SCHEMA_KEYS.scale}
        ${PROCESS_KEYS.SCHEMA_KEYS.precision}
        ${PROCESS_KEYS.SCHEMA_KEYS.length}
        ${PROCESS_KEYS.SCHEMA_KEYS.last_updated_time_stamp}
        ${PROCESS_KEYS.SCHEMA_KEYS.column_index}
        ${PROCESS_KEYS.SCHEMA_KEYS.prev_column_name}
      }
    }
  }
  `
  // console.log(qry)
  executeQuery(qry, onSuccess, onFailure)

}

export const updateProcess = (data, onSuccess, onFailure) => {
  const arr = [PROCESS_KEYS.created_by, PROCESS_KEYS.created_time_stamp, PROCESS_KEYS.last_updated_time_stamp, PROCESS_KEYS.redshift_table_name]
  if(!data[PROCESS_KEYS.status] || data[PROCESS_KEYS.status] == FRISCO_TABLE_STATUS.CREATING_TABLE){
    arr.push(PROCESS_KEYS.status)
  }
  let qry = `mutation MyMutation {
    updateProcessV2(input: {${getQueryInput(data, arr)}}
    ) {
      ${PROCESS_KEYS.process_id}
      ${PROCESS_KEYS.app_id}
      ${PROCESS_KEYS.label}
      ${PROCESS_KEYS.description}
      ${PROCESS_KEYS.glue_job_name}
      ${PROCESS_KEYS.step_function_name}
      ${PROCESS_KEYS.environment}
      ${PROCESS_KEYS.action}
      ${PROCESS_KEYS.status}
      ${PROCESS_KEYS.team}
      ${PROCESS_KEYS.business}
      ${PROCESS_KEYS.dataset_name}
      ${PROCESS_KEYS.created_by}
      ${PROCESS_KEYS.last_updated_by}
      ${PROCESS_KEYS.created_time_stamp}
      ${PROCESS_KEYS.last_updated_time_stamp}
      ${PROCESS_KEYS.approval_required}
      ${PROCESS_KEYS.multipart_allowed}
      ${PROCESS_KEYS.redshift_table_name}
      ${PROCESS_KEYS.sox_approved}
      ${PROCESS_KEYS.sox_evidence}
      ${PROCESS_KEYS.critical_data}
      ${PROCESS_KEYS.approvers}{
        ${PROCESS_KEYS.APPROVER_KEYS.alias}
        ${PROCESS_KEYS.APPROVER_KEYS.type}
        ${PROCESS_KEYS.APPROVER_KEYS.column_index}
      }
      ${PROCESS_KEYS.uploaders}{
        ${PROCESS_KEYS.UPLOADER_KEYS.alias}
        ${PROCESS_KEYS.UPLOADER_KEYS.type}
      }
      ${PROCESS_KEYS.file_types}
      ${PROCESS_KEYS.label}
      ${PROCESS_KEYS.process_id}
      ${PROCESS_KEYS.process_name}
      ${PROCESS_KEYS.schema}{
        ${PROCESS_KEYS.SCHEMA_KEYS.column_name}
        ${PROCESS_KEYS.SCHEMA_KEYS.data_type}
        ${PROCESS_KEYS.SCHEMA_KEYS.mandatory}
        ${PROCESS_KEYS.SCHEMA_KEYS.delsert_key}
        ${PROCESS_KEYS.SCHEMA_KEYS.scale}
        ${PROCESS_KEYS.SCHEMA_KEYS.precision}
        ${PROCESS_KEYS.SCHEMA_KEYS.length}
        ${PROCESS_KEYS.SCHEMA_KEYS.last_updated_time_stamp}
        ${PROCESS_KEYS.SCHEMA_KEYS.column_index}
        ${PROCESS_KEYS.SCHEMA_KEYS.prev_column_name}
      }
    }
  }
  `
  // console.log(data, qry)
  executeQuery(qry, onSuccess, onFailure)

}

export const updateProcessLite = (data, onSuccess, onFailure) => {
  const arr = [PROCESS_KEYS.created_by, PROCESS_KEYS.created_time_stamp, PROCESS_KEYS.last_updated_time_stamp, PROCESS_KEYS.redshift_table_name]
  if(!data[PROCESS_KEYS.status] || data[PROCESS_KEYS.status] == FRISCO_TABLE_STATUS.CREATING_TABLE){
    arr.push(PROCESS_KEYS.status)
  }
  let qry = `mutation MyMutation {
    updateProcessLiteV2(input: {${getQueryInput(data, arr)}}
    ) {
      ${PROCESS_KEYS.process_id}
      ${PROCESS_KEYS.app_id}
      ${PROCESS_KEYS.label}
      ${PROCESS_KEYS.description}
      ${PROCESS_KEYS.glue_job_name}
      ${PROCESS_KEYS.step_function_name}
      ${PROCESS_KEYS.environment}
      ${PROCESS_KEYS.action}
      ${PROCESS_KEYS.status}
      ${PROCESS_KEYS.team}
      ${PROCESS_KEYS.business}
      ${PROCESS_KEYS.dataset_name}
      ${PROCESS_KEYS.created_by}
      ${PROCESS_KEYS.last_updated_by}
      ${PROCESS_KEYS.created_time_stamp}
      ${PROCESS_KEYS.last_updated_time_stamp}
      ${PROCESS_KEYS.approval_required}
      ${PROCESS_KEYS.multipart_allowed}
      ${PROCESS_KEYS.redshift_table_name}
      ${PROCESS_KEYS.sox_approved}
      ${PROCESS_KEYS.sox_evidence}
      ${PROCESS_KEYS.critical_data}
      ${PROCESS_KEYS.approvers}{
        ${PROCESS_KEYS.APPROVER_KEYS.alias}
        ${PROCESS_KEYS.APPROVER_KEYS.type}
        ${PROCESS_KEYS.APPROVER_KEYS.column_index}
      }
      ${PROCESS_KEYS.uploaders}{
        ${PROCESS_KEYS.UPLOADER_KEYS.alias}
        ${PROCESS_KEYS.UPLOADER_KEYS.type}
      }
      ${PROCESS_KEYS.file_types}
      ${PROCESS_KEYS.label}
      ${PROCESS_KEYS.process_id}
      ${PROCESS_KEYS.process_name}
      ${PROCESS_KEYS.schema}{
        ${PROCESS_KEYS.SCHEMA_KEYS.column_name}
        ${PROCESS_KEYS.SCHEMA_KEYS.data_type}
        ${PROCESS_KEYS.SCHEMA_KEYS.mandatory}
        ${PROCESS_KEYS.SCHEMA_KEYS.delsert_key}
        ${PROCESS_KEYS.SCHEMA_KEYS.scale}
        ${PROCESS_KEYS.SCHEMA_KEYS.precision}
        ${PROCESS_KEYS.SCHEMA_KEYS.length}
        ${PROCESS_KEYS.SCHEMA_KEYS.last_updated_time_stamp}
        ${PROCESS_KEYS.SCHEMA_KEYS.column_index}
        ${PROCESS_KEYS.SCHEMA_KEYS.prev_column_name}
      }
    }
  }
  `
  // console.log(data, qry)
  executeQuery(qry, onSuccess, onFailure)

}

export const createAuditLog = (pk, sk, comments, before, after, action, actor, onSuccess, onFailure) => {
  let qry = `mutation MyMutation {
    createABCUIAuditLogging(input: {pk: "${pk}", sk: "${sk}", comments: "${comments}", before: ${JSON.stringify(before)}, 
    after: ${JSON.stringify(after)}, action: "${action}", actor: "${actor}",last_updated_time_stamp: "${Date.now()}"}) {
      action
      actor
      after
      before
      comments
      pk
      sk
    }
  }
  `
  //console.log(qry)
  executeQuery(qry, onSuccess, onFailure)
}

export const getProcessForAppId = (appId, onSuccess, onFailure) => {
  let qry = `query MyQuery {
    getProcessesForAppIDV2(app_id: "${appId}") {
      ${PROCESS_KEYS.app_id}
      ${PROCESS_KEYS.process_id}
      ${PROCESS_KEYS.label}
      ${PROCESS_KEYS.created_by}
      ${PROCESS_KEYS.approvers} {
        ${PROCESS_KEYS.APPROVER_KEYS.alias}
        ${PROCESS_KEYS.APPROVER_KEYS.type}
      }
      ${PROCESS_KEYS.uploaders} {
        ${PROCESS_KEYS.UPLOADER_KEYS.alias}
        ${PROCESS_KEYS.UPLOADER_KEYS.type}
      }
  
    }
  }  
  `
  executeQuery(qry, onSuccess, onFailure)
}

export const getProcessForAppIdByGroup = (appId, groupId, onSuccess, onFailure) => {
  let qry = `query MyQuery {
    getProcessesForAppIDV2(app_id: "${appId}", group_id: "${groupId}") {
      ${PROCESS_KEYS.app_id}
      ${PROCESS_KEYS.process_id}
      ${PROCESS_KEYS.label}
    }
  }  
  `
  executeQuery(qry, onSuccess, onFailure)
}

export const getFriscoTracksForQuarter = (appId, groupId, quarter, onSuccess, onFailure) => {
  let qry = `query MyQuery {
    getFRISCOTracksForQuarter(app_id: "${appId}" 
  `
  if (groupId) {
    qry = qry + `, group_id: "${groupId}"`
  }
  qry = qry + `, quarter: "${quarter}") {
    tracking_id
    mapping_id
    track_group
    current_status
    overall_status
    details
    created_time_stamp
    last_updated_time_stamp
  }
}  `

  executeQuery(qry, onSuccess, onFailure)
}

export const listExternalDeliverables = (owner, first, onSuccess, onFailure) => {
  let qry = `query MyQuery {
    listExternalDeliverables(owner: "${owner}", first: ${first}) {
      nextToken
      items
    }
  }
  `
  executeQuery(qry, onSuccess, onFailure)
}

export const getFriscoProcessWithTracksForQuarter = (appId, groupId, quarter, onSuccess, onFailure) => {

  let qry = `query MyQuery {
    getFRISCOProcessWithTracksForQuarter(app_id: "${appId}"
  `
  if (groupId) {
    qry = qry + `, group_id: "${groupId}"`
  }

  qry = qry + `, quarter: "${quarter}") {
    process_id
    process_name
    app_id
    group_id
    group_name
    label
    tracking_id
    mapping_id
    track_group
    current_status
    overall_status
    details
    created_time_stamp
    last_updated_time_stamp
    file_id
    approval_id
    redshift_table_name
    uploaders {
      alias
      type
    }
    approvers {
      alias
      type
      column_index
    }
    custom_attributes {
      attribute
      attribute_type
      sub_type
    }
  }
} `

  executeQuery(qry, onSuccess, onFailure)
}

export const getFriscoGroupById = (groupId, onSuccess, onFailure) => {
  let qry = `query MyQuery {
    getFRISCOGroupById(group_id: "${groupId}") {
      group_id
      group_name
      app_id
      description
    }
  }
  `
  executeQuery(qry, onSuccess, onFailure)
}

export const getFriscoGroupsForAppId = (appId, onSuccess, onFailure) => {
  let qry = `query MyQuery {
    getFRISCOGroups(app_id: "${appId}") {
      group_id
      group_name
      app_id
      description
    }
  }
  `
  executeQuery(qry, onSuccess, onFailure)
}

export const getFRISCOGroupsWithConsolidatedStatusForQuarter = (appId, quarter, onSuccess, onFailure) => {
  let qry = `query MyQuery {
    getFRISCOGroupsWithConsolidatedStatusForQuarter(app_id: "${appId}", quarter: "${quarter}") {
      group_id
      group_name
      app_id
      description
      label
      tracking_id
      mapping_id
      track_group
      current_status
      overall_status
      details
      created_time_stamp
      last_updated_time_stamp
      uploaders{
        alias
        type
      }
      approvers{
        alias
        type
      }
    }
  }  
  `
  executeQuery(qry, onSuccess, onFailure)
}

export const deleteProcess = (processId, onSuccess, onFailure) => {
  let qry = `mutation MyMutation {
    deleteProcessV2(input: {process_id: "${processId}"}) {
      message
    }
  }
  `
  executeQuery(qry, onSuccess, onFailure)
}

export const getProcessForAppIdPromise = (appId) => {
  let qry = `query MyQuery {
    getProcessesForAppIDV2(app_id: "${appId}") {
      ${PROCESS_KEYS.app_id}
      ${PROCESS_KEYS.process_id}
      ${PROCESS_KEYS.label}
    }
  }  
  `
  return API.graphql(graphqlOperation(qry))
}

export const getProcessById = (processId, onSuccess, onFailure) => {
  let qry = `query MyQuery {
    getProcessByIDV2(process_id: "${processId}") {
      ${PROCESS_KEYS.app_id}
      ${PROCESS_KEYS.group_id}
      ${PROCESS_KEYS.label}
      ${PROCESS_KEYS.description}
      ${PROCESS_KEYS.glue_job_name}
      ${PROCESS_KEYS.step_function_name}
      ${PROCESS_KEYS.environment}
      ${PROCESS_KEYS.action}
      ${PROCESS_KEYS.team}
      ${PROCESS_KEYS.business}
      ${PROCESS_KEYS.dataset_name}
      ${PROCESS_KEYS.created_by}
      ${PROCESS_KEYS.last_updated_by}
      ${PROCESS_KEYS.created_time_stamp}
      ${PROCESS_KEYS.last_updated_time_stamp}
      ${PROCESS_KEYS.status}
      ${PROCESS_KEYS.approval_required}
      ${PROCESS_KEYS.multipart_allowed}
      ${PROCESS_KEYS.next_step}
      ${PROCESS_KEYS.redshift_table_name}
      ${PROCESS_KEYS.sox_approved}
      ${PROCESS_KEYS.sox_evidence}
      ${PROCESS_KEYS.critical_data}
      ${PROCESS_KEYS.custom_attributes}{
        ${PROCESS_KEYS.CUSTOM_ATTRIBUTES_KEYS.sub_type}
        ${PROCESS_KEYS.CUSTOM_ATTRIBUTES_KEYS.attribute_type}
        ${PROCESS_KEYS.CUSTOM_ATTRIBUTES_KEYS.attribute}
        
      }
      ${PROCESS_KEYS.approvers}{
        ${PROCESS_KEYS.APPROVER_KEYS.alias}
        ${PROCESS_KEYS.APPROVER_KEYS.type}
        ${PROCESS_KEYS.APPROVER_KEYS.column_index}
      }
      ${PROCESS_KEYS.uploaders}{
        ${PROCESS_KEYS.UPLOADER_KEYS.alias}
        ${PROCESS_KEYS.UPLOADER_KEYS.type}
      }
      ${PROCESS_KEYS.file_types}
      ${PROCESS_KEYS.label}
      ${PROCESS_KEYS.process_id}
      ${PROCESS_KEYS.process_name}
      ${PROCESS_KEYS.schema}{
        ${PROCESS_KEYS.SCHEMA_KEYS.column_name}
        ${PROCESS_KEYS.SCHEMA_KEYS.data_type}
        ${PROCESS_KEYS.SCHEMA_KEYS.mandatory}
        ${PROCESS_KEYS.SCHEMA_KEYS.delsert_key}
        ${PROCESS_KEYS.SCHEMA_KEYS.scale}
        ${PROCESS_KEYS.SCHEMA_KEYS.precision}
        ${PROCESS_KEYS.SCHEMA_KEYS.length}
        ${PROCESS_KEYS.SCHEMA_KEYS.last_updated_time_stamp}
        ${PROCESS_KEYS.SCHEMA_KEYS.column_index}
        ${PROCESS_KEYS.SCHEMA_KEYS.prev_column_name}
      }
    }
  }
  
  `
  // console.log(qry)
  executeQuery(qry, onSuccess, onFailure)

}

export const getMappingById = (app_id, processId, onSuccess, onFailure) => {
  let qry = `query MyQuery {
    getMappingIDV2(app_id: ${app_id}, process_id: ${processId}) {
      ${PROCESS_KEYS.mapping_id}
      ${PROCESS_KEYS.app_id}
      ${PROCESS_KEYS.process_id}
      ${PROCESS_KEYS.label}
    }
  }
    
  `
  // console.log(qry)
  executeQuery(qry, onSuccess, onFailure)

}

export const getFriscoSTLLoadError = (filePath, pid, onSuccess, onFailure) => {

  let qry = `query MyQuery {
    getFriscoSTLLoadError(frisco_filepath: "${filePath}", process_id: "${pid}")
  }
  `
  // console.log(qry)
  executeQuery(qry, onSuccess, onFailure)
}

export const getRedshiftTransformedTableDataForProcessId = (redshift_table_name, onSuccess, onFailure) => {
  let qry = `query MyQuery {
    getRedshiftTransformedTableDataForProcessId(redshift_table_name: "${redshift_table_name}")
  }
  `
  // console.log(qry)
  executeQuery(qry, onSuccess, onFailure)

}

export const resetProcessStatus = (process_id, due_date, quarter, onSuccess, onFailure) => {
  let qry = `mutation MyMutation {
    resetProcessStatus(input: {due_date: "${due_date}", process_id: "${process_id}", quarter: "${quarter}"})
  }
  `
  executeQuery(qry, onSuccess, onFailure)
}

export const getQuarter = (quarter) => {
  if (quarter) {
    return `,quarter:"${quarter}"`
  } else {
    return ''
  }
}
export const getTaskId = (taskId) => {
  if (taskId) {
    return `,taskId:"${taskId}"`
  } else {
    return ''
  }
}

export const createFriscoTransactions = (fileId, appID, pId, status, bucket_name, filePath, fileExt, uploadedTimestamp, uploadedBy, uploadedFileName, quarter = null, taskId = null, onSuccess, onFailure) => {
  let qry = `mutation MyMutation {
    createFRISCOTransactions(input: {fileId: "${fileId}", appId: "${appID}", processId: "${pId}", status: "${status}",bucket_name:"${bucket_name}",
     uploadedTimestamp: "${uploadedTimestamp}", createdTimestamp: "${uploadedTimestamp}", lastUpdatedTimestamp: "${uploadedTimestamp}",uploadedBy: "${uploadedBy}", uploadedFileName: "${uploadedFileName}",
      filePath:"${filePath}", fileExt: "${fileExt}"${getQuarter(quarter)}${getTaskId(taskId)}}) {
      fileId
      appId
      processId
    }
  }
  `
  executeQuery(qry, onSuccess, onFailure)
}

export const listFriscoTransactions = (processId, onSuccess, onFailure) => {
  let qry = `query MyQuery {
    listFRISCOTransactions(limit: 100,filter: {processId: {eq: "${processId}"}}) {
      items {
        fileId
        processId
        appId
        filePath
        fileExt
        status
        createdTimestamp
        lastUpdatedTimestamp
        uploadedTimestamp
        uploadedBy
        approvedBy
        approvalRequestedTime
        approvalModificationTime
        approvalStatus
        errorMessage
        stepInstanceID
        approvers{
          alias
          type
        }
      }
    }
  }
  `
  executeQuery(qry, onSuccess, onFailure)
}

export const getNextTokenQuery = (nextToken) => {
  if (nextToken) {
    return `, after: "${nextToken}"`
  } else {
    return ''
  }

}

export const queryFRISCOTransactionsByAppIdProcessIdIndex = (appId, processId, limit, nextToken, onSuccess, onFailure) => {
  let qry = `query MyQuery {
    queryFRISCOTransactionsByAppIdProcessIdIndex(appId: "${appId}", processId: "${processId}",first: ${limit} ${getNextTokenQuery(nextToken)}) {
      nextToken
      items {
        appId
        processId
        approvalModificationTime
        approvalId
        approvalRequestedTime
        approvalStatus
        approvedBy
        createdTimestamp
        errorMessage
        fileExt
        fileId
        filePath
        lastUpdatedTimestamp
        status
        stepInstanceID
        uploadedBy
        uploadedTimestamp
        jobStartTime
        jobStatusModificationTimestamp
        approvers{
          alias
          type
        }
        rawFileName
        rawFilePath
        uploadedFileName
      }
    }
  }
  `
  executeQuery(qry, onSuccess, onFailure)
}

export const updateGroups = (app_id, groups, onSuccess, onFailure) => {
  let input = {
    app_id: app_id,
    groups: groups
  }
  let qry = `mutation MyMutation {
    upsertOrDeleteGroupsForApp(input: { ${getQueryInput(input,[])} })
  }`
  // console.log(qry)
  executeQuery(qry, onSuccess, onFailure)
}

export const getAliasesByType = (app_id, type, onSuccess, onFailure) => {
  let qry = `query MyQuery {
    getAliasesByType(app_id: "${app_id}", type: "${type}") {
      type
      alias
    }
  }`
  // console.log(qry)
  executeQuery(qry, onSuccess, onFailure)
}

export const addAliasByType = (app_id, type, aliases, onSuccess, onFailure) => {
  let qry = `mutation MyMutation {
    addAliasByType(input: {app_id: "${app_id}", type: "${type}", aliases: ${JSON.stringify(aliases)}})
  }
  `
  // console.log(qry)
  executeQuery(qry, onSuccess, onFailure)
}

export const getAppTags = (app_id, onSuccess, onFailure) => {
  let qry = `query MyQuery {
    getAppTags(id: "${app_id}", id_type: "APP") {
      id
      id_type
      tag
      description
    }
  }`
  // console.log(qry)
  executeQuery(qry, onSuccess, onFailure)
}

export const addAppTags = (app_id, tags, onSuccess, onFailure) => {
  let qry = `mutation MyMutation {
    addAppTags(input: {id: "${app_id}", id_type: "APP",${getQueryInput(tags, [])}})
  }
`
  // console.log(qry)
  executeQuery(qry, onSuccess, onFailure)
}

export const executeQuery = async(mQry, onSuccess, onFailure) => {
  await configureGenericAppSync()
  API.graphql(graphqlOperation(mQry)).then(result => {
    if (onSuccess) {
      onSuccess(result)
    }
  }, function (error) {
    if (onFailure) {
      onFailure(error)
    }
  });
}

export const executeQueryWithVariables = (mQry, variables, onSuccess, onaFailure) => {
  API.graphql(graphqlOperation(mQry, variables = variables)).then(result => {
    onSuccess(result)
  }, function (error) {
    onaFailure(error)
  });
}

export const executeQueryWithoutCallback = (mQry) => {
  return API.graphql(graphqlOperation(mQry))
}

export const getSubLinks = (app_id) => {
  if (app_id == "230") {
    return [{ type: 'side_navigation_bar', label: 'Frisco', url: '/frisco' }]
  }
}

export const getNormalisedColumnName = (colName) => {
  colName = colName.toLowerCase()
  colName = colName.trim()
  let defChar = ''
  colName = colName.replaceAll(/[&\/\\#,+()$~%.'":*?<>{}!@=|-]/g, defChar).replaceAll('^', defChar).replaceAll('[', defChar).replaceAll(']', defChar).replaceAll(';', defChar).replaceAll('`', defChar);
  colName = colName.substring(0, 127)
  colName = colName.replace(/ +/g, ' ');
  colName = colName.trim()
  colName = colName.replaceAll(' ', '_')
  return colName
}


export const constructExcelSheetWithHeadersAndData = (headers, rawData, sheetName = "AllApps") => {


  rawData.forEach((item) => {

    headers.forEach((header) => {
      const val = item[header]     
      if (val && typeof val === 'object' && !Array.isArray(val)) {
        let arr = objToArray(val)
        item[header] =  arr.join("")
      } else if (Array.isArray(val)) {
        let arr = []
        val.forEach((v,i) => {
          if(typeof(v) === "string" || typeof(v) === "bigint" || typeof(v) === "number" || typeof(v) === "boolean") {
            arr.push(`${v} \r\n`)
          } else if (v && typeof v === 'object' && !Array.isArray(v)) {
            arr.push(...objToArray(v))
          }
        })
        item[header] = arr.join("")
      }
    })


  })


  let ws = XLSX.utils.json_to_sheet(rawData, {header: headers});;
  let wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, sheetName);
  XLSX.writeFile(wb, `${sheetName}.xlsx`);


}

const objToArray = (obj) => {
  let arr =[]
  for (const [key, value] of Object.entries(obj)) {
    arr.push(`${key} : ${value ? value : "Not Available"} \r\n`)
  }
  return arr
}

// Logico APIs

export const getLogicoTransactionsByLogicoProcessId = async (lpid) => {

  const variables = {
      limit: 100000
  }

  let transactions = []
  let qry = `query MyQuery($limit: Int!, $nextToken: String) {
      listLOGICOTransactions(limit: $limit, filter: {logicoProcessId: {eq: "${lpid}"}} , nextToken: $nextToken) {
        items {
          fileId
          logicoProcessId
          appId
          uploadedBy
          uploadedTimeStamp
          s3BucketName
          filePath
          status
        }
        nextToken
      }
    }
    `
  let response = await API.graphql(graphqlOperation(qry, variables));
  transactions = transactions.concat(response.data.listLOGICOTransactions.items)
  while (response.data.listLOGICOTransactions.nextToken) {
      variables["nextToken"] = response.data.listLOGICOTransactions.nextToken
      response = await API.graphql(graphqlOperation(qry, variables));
      transactions = transactions.concat(response.data.listLOGICOTransactions.items)
  }
  transactions.sort((a,b) => b?.uploadedTimeStamp - a?.uploadedTimeStamp)
  return transactions

}

export const createLogicoProcess = (data, onSuccess, onFailure) => {
  let qry = `mutation MyMutation {
    createLogicoProcess(input: {${getQueryInput(data, [])}}
    ) {
      ${LOGICO_PROCESS_KEYS.app_id}
      ${LOGICO_PROCESS_KEYS.process_description}
      ${LOGICO_PROCESS_KEYS.created_by}
      ${LOGICO_PROCESS_KEYS.last_updated_by}
      ${LOGICO_PROCESS_KEYS.created_timestamp}
      ${LOGICO_PROCESS_KEYS.last_updated_timestamp}
      ${LOGICO_PROCESS_KEYS.approval_required}
      ${LOGICO_PROCESS_KEYS.sox_approved}
      ${LOGICO_PROCESS_KEYS.sox_evidence}
      ${LOGICO_PROCESS_KEYS.fast_table}
      ${LOGICO_PROCESS_KEYS.fast_view}
      ${LOGICO_PROCESS_KEYS.critical_data}
      ${LOGICO_PROCESS_KEYS.uploaders}{
        ${LOGICO_PROCESS_KEYS.UPLOADER_KEYS.alias}
        ${LOGICO_PROCESS_KEYS.UPLOADER_KEYS.type}
      }
      ${LOGICO_PROCESS_KEYS.approvers}{
        ${LOGICO_PROCESS_KEYS.APPROVER_KEYS.alias}
        ${LOGICO_PROCESS_KEYS.APPROVER_KEYS.type}
        ${LOGICO_PROCESS_KEYS.APPROVER_KEYS.column_index}
      }
      ${LOGICO_PROCESS_KEYS.display_label}
      ${LOGICO_PROCESS_KEYS.logico_process_id}
      ${LOGICO_PROCESS_KEYS.process_name}
    }
  }
  `
  // console.log(qry)
  executeQuery(qry, onSuccess, onFailure)

}



export const updateLogicoProcess = (data, onSuccess, onFailure) => {
  const arr = [PROCESS_KEYS.created_by, PROCESS_KEYS.created_time_stamp, PROCESS_KEYS.last_updated_time_stamp]
  
  let qry = `mutation MyMutation {
    updateLogicoProcess(input: {${getQueryInput(data, arr)}}
    ) {
      ${LOGICO_PROCESS_KEYS.app_id}
      ${LOGICO_PROCESS_KEYS.process_description}
      ${LOGICO_PROCESS_KEYS.created_by}
      ${LOGICO_PROCESS_KEYS.last_updated_by}
      ${LOGICO_PROCESS_KEYS.created_timestamp}
      ${LOGICO_PROCESS_KEYS.last_updated_timestamp}
      ${LOGICO_PROCESS_KEYS.approval_required}
      ${LOGICO_PROCESS_KEYS.fast_table}
      ${LOGICO_PROCESS_KEYS.fast_view}
      ${LOGICO_PROCESS_KEYS.sox_approved}
      ${LOGICO_PROCESS_KEYS.sox_evidence}
      ${LOGICO_PROCESS_KEYS.critical_data}
      ${LOGICO_PROCESS_KEYS.uploaders}{
        ${LOGICO_PROCESS_KEYS.UPLOADER_KEYS.alias}
        ${LOGICO_PROCESS_KEYS.UPLOADER_KEYS.type}
      }
      ${LOGICO_PROCESS_KEYS.approvers}{
        ${LOGICO_PROCESS_KEYS.APPROVER_KEYS.alias}
        ${LOGICO_PROCESS_KEYS.APPROVER_KEYS.type}
        ${LOGICO_PROCESS_KEYS.APPROVER_KEYS.column_index}
      }
      ${LOGICO_PROCESS_KEYS.display_label}
      ${LOGICO_PROCESS_KEYS.logico_process_id}
      ${LOGICO_PROCESS_KEYS.process_name}
    }
  }
  `
  // console.log(data, qry)
  executeQuery(qry, onSuccess, onFailure)

}

export const deleteLogicoProcess = (logicoProcessId, appId, onSuccess, onFailure) => {
  let qry = `mutation MyMutation {
    deleteLogicoProcess(input: {logico_process_id: "${logicoProcessId}", app_id: "${appId}"})
  }
  `
  executeQuery(qry, onSuccess, onFailure)
}

export const getLogicoProcessById = (logicoProcessId, onSuccess, onFailure) => {
  let qry = `query MyQuery {
    getLogicoProcessByID(logico_process_id: "${logicoProcessId}") {
      ${LOGICO_PROCESS_KEYS.app_id}
      ${LOGICO_PROCESS_KEYS.process_description}
      ${LOGICO_PROCESS_KEYS.created_by}
      ${LOGICO_PROCESS_KEYS.last_updated_by}
      ${LOGICO_PROCESS_KEYS.created_timestamp}
      ${LOGICO_PROCESS_KEYS.last_updated_timestamp}
      ${LOGICO_PROCESS_KEYS.approval_required}
      ${LOGICO_PROCESS_KEYS.sox_approved}
      ${LOGICO_PROCESS_KEYS.sox_evidence}
      ${LOGICO_PROCESS_KEYS.critical_data}
      ${LOGICO_PROCESS_KEYS.fast_table}
      ${LOGICO_PROCESS_KEYS.fast_view}
      ${LOGICO_PROCESS_KEYS.uploaders}{
        ${LOGICO_PROCESS_KEYS.UPLOADER_KEYS.alias}
        ${LOGICO_PROCESS_KEYS.UPLOADER_KEYS.type}
      }
      ${LOGICO_PROCESS_KEYS.approvers}{
        ${LOGICO_PROCESS_KEYS.APPROVER_KEYS.alias}
        ${LOGICO_PROCESS_KEYS.APPROVER_KEYS.type}
        ${LOGICO_PROCESS_KEYS.APPROVER_KEYS.column_index}
      }
      ${LOGICO_PROCESS_KEYS.display_label}
      ${LOGICO_PROCESS_KEYS.logico_process_id}
      ${LOGICO_PROCESS_KEYS.process_name}
    }
  }
  
  `
  // console.log(qry)
  executeQuery(qry, onSuccess, onFailure)

}

export const getLogicoProcessesForAppId = async (appId) => {
  let qry = `query MyQuery {
    getLogicoProcessesForAppID(app_id: "${appId}") {
      ${LOGICO_PROCESS_KEYS.app_id}
      ${LOGICO_PROCESS_KEYS.process_description}
      ${LOGICO_PROCESS_KEYS.created_by}
      ${LOGICO_PROCESS_KEYS.last_updated_by}
      ${LOGICO_PROCESS_KEYS.created_timestamp}
      ${LOGICO_PROCESS_KEYS.last_updated_timestamp}
      ${LOGICO_PROCESS_KEYS.approval_required}
      ${LOGICO_PROCESS_KEYS.sox_approved}
      ${LOGICO_PROCESS_KEYS.sox_evidence}
      ${LOGICO_PROCESS_KEYS.fast_table}
      ${LOGICO_PROCESS_KEYS.fast_view}
      ${LOGICO_PROCESS_KEYS.critical_data}
      ${LOGICO_PROCESS_KEYS.uploaders}{
        ${LOGICO_PROCESS_KEYS.UPLOADER_KEYS.alias}
        ${LOGICO_PROCESS_KEYS.UPLOADER_KEYS.type}
      }
      ${LOGICO_PROCESS_KEYS.approvers}{
        ${LOGICO_PROCESS_KEYS.APPROVER_KEYS.alias}
        ${LOGICO_PROCESS_KEYS.APPROVER_KEYS.type}
        ${LOGICO_PROCESS_KEYS.APPROVER_KEYS.column_index}
      }
      ${LOGICO_PROCESS_KEYS.display_label}
      ${LOGICO_PROCESS_KEYS.logico_process_id}
      ${LOGICO_PROCESS_KEYS.process_name}
      }
  }  
  `
  return API.graphql(graphqlOperation(qry))
}

export const logicoFileUpload = (data, onSuccess, onFailure) => {
  let qry = `mutation MyMutation {
    fileUploadLogicoProcess(input: {${getQueryInput(data, [])}}) }
  `
  executeQuery(qry, onSuccess, onFailure)
}

export const createLogicoTransactions = (fileId, appID, lpid, status, bucket_name, filePath, uploadedTimeStamp, uploadedBy, onSuccess, onFailure) => {
  let qry = `mutation MyMutation {
    createLOGICOTransactions(input: {fileId: "${fileId}", appId: "${appID}", logicoProcessId: "${lpid}", status: "${status}",s3BucketName:"${bucket_name}",
    uploadedTimeStamp: "${uploadedTimeStamp}",uploadedBy: "${uploadedBy}",
      filePath:"${filePath}"}) {
      fileId
      appId
      logicoProcessId
    }
  }
  `
  executeQuery(qry, onSuccess, onFailure)
}

