import React, { useState, useEffect, useRef } from 'react';
import appSettings from './app_settings';

import {
    AppLayout, HelpPanel, Cards, Tabs, Icon, Spinner, ContentLayout, Table,
    Container, Header, Box, Pagination, SpaceBetween, ExpandableSection, Modal, ColumnLayout, Button, Badge, Textarea, Select, Alert, Input, TokenGroup, DatePicker, TimeInput
} from '@amzn/awsui-components-react';
import Link from "@amzn/awsui-components-react/polaris/link";

import { useParams } from "react-router-dom";
import '../../index.scss';
import { useSelector, useDispatch } from 'react-redux'

import './styles.scss'

import AccessDenied from '../../AccessDenied'
import WaitForPageAccess from '../../WaitForPageAccess'
import { useHistory } from 'react-router-dom';

import { API, graphqlOperation } from 'aws-amplify';
import Template from '../Templates/Template';
import { glossary, TEMPLATE_APP, TEMPLATE_APP_OUTPUT, TEMPLATE_APP_PERMISSION, TEMPLATE_APP_TEAM, TEMPLATE_APP_SOURCE_DATA_TABLES, TEMPLATE_APP_CHART_OF_ACCOUNTS } from 'src/Ateam-BI/Templates/Constants';
import TemplateMaple from '../Templates/TemplateMaple';
import { favorite, checkIsFavorite } from '../../utilities/Favorites'
import eyeLogo from '../../images/eye-svgrepo-com.svg'
import SideNav from './SideNav';
import { createAuditLog, getAppPromise, getFRISCOGroupsWithConsolidatedStatusForQuarter, getFriscoGroupById, getFriscoGroupsForAppId, getFriscoProcessWithTracksForQuarter, getRedshiftTransformedTableDataForProcessId, listExternalDeliverables, resetProcessStatus, updateProcessLite } from '../Templates/Utils/AppActivityUtils';
import { APPROVER_TYPES, FRISCO_STATUS, PROCESS_KEYS, UPLOADER_TYPES } from '../Frisco/Constants';
import * as XLSX from 'xlsx';
import { getEditableFields } from "../Templates/Utils/FormMetadata";
import { canAddEditDeleteApplication, isCorpAdmin } from '../Templates/Utils/Permissions';
import Feedback from 'src/generic-components/UserFeedback/Feedback';
import { performActionOnWorkflow } from 'src/utilities/wasApprovalAPIs';

import { notifyByEmail } from "../../utilities/notificationAPIs"
import { isApprover, isUploaderAllowed } from '../Frisco/AccessUtilities';
import { filterItemsByAccess, getQuarterForUpload, getStateNonAdmin, getStateNonAdminPlain } from './Utility';
import TextFilter from "@amzn/awsui-components-react/polaris/text-filter";
import { setCurrentAppId } from 'src/store/userAuthSlice';
import CustomMultiHybridInputAndSelect from '../Templates/components/CustomMultiHybridInputAndSelect';

// Component ServiceHomepage is a skeleton of a service's homepage using AWS-UI React components.
export default (props) => {
    const history = useHistory()
    const dispatch = useDispatch();
    const currentStage = useSelector((globalState) => globalState.auth.currentStage)
    const userId = useSelector((globalState) => globalState.auth.userId);
    const [NavOpen, setNavOpen] = useState(false);
    const [groupName, setGroupName] = useState("");
    const [filteringText, setFilteringText] = useState("");
    const [filteringTextCRDeliverable, setFilteringTextCRDeliverable] = useState("");

    const [showDeliverablesLoader, setShowDeliverablesLoader] = useState(true);
    const [showCRDeliverablesLoader, setShowCRDeliverablesLoader] = useState(true);

    const [showDeliverablesLoaderMain, setShowDeliverablesLoaderMain] = useState('');
    const [deliverables, setDeliverables] = useState([]);
    const [deliverablesForAlias, setDeliverablesForAlias] = useState([]);
    const [filteredDeliverables, setFilteredDeliverables] = useState([]);

    const [crDeliverables, setCrDeliverables] = useState([]);
    const [crDeliverablesForAlias, setCrDeliverablesForAlias] = useState([]);
    const [filteredCrDeliverables, setFilteredCrDeliverables] = useState([]);

    const [accessMap, setAccessMap] = useState({})
    const [isAdmin, setIsAdmin] = useState(false)
    const [configurationWindowContent, setConfigurationWindowContent] = useState(null);
    const [configurationWindowContentOriginal, setConfigurationWindowContentOriginal] = useState(null);
    const [filters, setFilters] = useState([
    ]);
    const [filtersCrDeliverables, setFiltersCrDeliverables] = useState([
    ]);
    const filtersRef = useRef(null);
    const filtersCrDeliverablesRef = useRef(null);

    const [resetLoadingMessage, setResetLoadingMessage] = useState(null)
    const [dueDatePickerValue, setDueDatePickerValue] = useState("")
    const [windowContentLoadingMessage, setWindowContentLoadingMessage] = useState(null)
    const [fieldMetadata, setFieldMetadata] = useState({});

    useEffect(() => {
        setViews()
        dispatch(setCurrentAppId(appSettings[currentStage].appId));
        return () => {
            // this now gets called when the component unmounts
        };
    }, [currentStage]);

    useEffect(() => {
        filtersRef.current = filters
        setFilteredGroups()
        return () => {
            // this now gets called when the component unmounts
        };
    }, [deliverables, filters]);

    useEffect(() => {
        filtersCrDeliverablesRef.current = filters
        setFilteredNonCrDeliverables()
        return () => {
            // this now gets called when the component unmounts
        };
    }, [crDeliverables, filtersCrDeliverables]);

    useEffect(() => {
        let filteredByName = []
        for (let i = 0; i < deliverablesForAlias.length; i++) {
            if (deliverablesForAlias[i].process_name.toLowerCase().includes(filteringText.toLowerCase())) {
                filteredByName.push(deliverablesForAlias[i])
            }
        }
        setFilteredDeliverables(filteredByName)
        return () => {
            // this now gets called when the component unmounts
        };
    }, [filteringText, deliverablesForAlias]);

    useEffect(() => {
        let filteredByName = []
        for (let i = 0; i < crDeliverablesForAlias.length; i++) {
            if (crDeliverablesForAlias[i].reportTypeDisplayName.toLowerCase().includes(filteringTextCRDeliverable.toLowerCase())) {
                filteredByName.push(crDeliverablesForAlias[i])
            }
        }
        setFilteredCrDeliverables(filteredByName)
        return () => {
            // this now gets called when the component unmounts
        };
    }, [filteringTextCRDeliverable, crDeliverablesForAlias]);

    const setFilteredNonCrDeliverables = () => {
        setCrDeliverablesForAlias(crDeliverables)
    }
    const useFilteredResponse = (result, allData) => {
        // some times filter is removed but since filtering takes time, the filtered data is considered since it is slow and last 
        if (!filtersRef.current || filtersRef.current.length == 0) {
            setDeliverablesForAlias(allData)
        } else {
            setDeliverablesForAlias(result)
        }
    }

    const setFilteredGroups = () => {
        setShowDeliverablesLoader(false)
        setShowDeliverablesLoaderMain("Loading deliverables..")
        if (!filtersRef.current || filtersRef.current.length == 0) {
            setShowDeliverablesLoaderMain('')
            setDeliverablesForAlias(deliverables)
        } else {
            filterItemsByAccess(deliverables, filteringText, 'label', filters, currentStage, function onResult(result, finished) {
                setShowDeliverablesLoader(false)
                if (finished) {
                    setShowDeliverablesLoaderMain('')
                }
                useFilteredResponse(result, deliverables)
            })
            // useFilteredResponse(filteredList, deliverables)
        }

    }
    const setViews = async () => {
        loadEditableFields()
        loadProcessesWithTracksForQuarter(appSettings[currentStage].appId)
        loadExternalDeliverables('Corporate reporting')
        setFilters([{ label: userId, dismissLabel: "Alias" }])
        // setFiltersCrDeliverables([{ label: userId, dismissLabel: "Alias" }])
        let accessPerms = await isCorpAdmin(userId, appSettings[currentStage].appId, currentStage)
        setIsAdmin(accessPerms?.admin)

    }

    const getStatusBadge = (status) => {
        if (status == 'NOT INITIATED') {
            return <Box><span className='grey_badge'>NOT INITIATED</span></Box>
        } else if (status == 'COMPLETED') {
            return <Box><span className='green_badge'>COMPLETED</span></Box>
        } else if (status == 'INCOMPLETE') {
            return <Box><span className='red_badge'>INCOMPLETE</span></Box>
        }
    }

    const getQuarter = () => {
        return getQuarterForUpload()

    }
    const loadExternalDeliverables = (owner) => {
        setShowCRDeliverablesLoader(true)
        listExternalDeliverables(owner, 2000, function onSuccess(result) {
            if (result.data.listExternalDeliverables.items.length <= 0) {
                return
            }
            let deliverables = []
            for (let i = 0; i < result.data.listExternalDeliverables.items.length; i++) {
                deliverables.push(JSON.parse(result.data.listExternalDeliverables.items[i]))
            }
            deliverables = deliverables.sort((a, b) => {
                if (a.reportTypeName < b.reportTypeName) {
                    return -1;
                }
            });
            setCrDeliverables(deliverables)
            setShowCRDeliverablesLoader(false)
        }, function onFailure(error) {

        })
    }

    const loadProcessesWithTracksForQuarter = (appId) => {
        setShowDeliverablesLoader(true)
        var quarterValue = getQuarter()
        getFriscoProcessWithTracksForQuarter(appId, null, quarterValue, async function onSuccess(result) {
            let deliverables = result?.data?.getFRISCOProcessWithTracksForQuarter
            deliverables = deliverables.sort((a, b) => {
                if (a.group_name < b.group_name) {
                    return -1;
                }
            });
            setDeliverables(deliverables)
            setShowDeliverablesLoader(false)
        }, function onFailure(result) {
            setShowDeliverablesLoader(false)
        })

    }

    const getAliasesApprovers = (item, key, individualType, groupType) => {
        let individualAliases = [];
        let groupAliases = [];
        item[key].sort((a, b) => (a.column_index > b.column_index ? 1 : -1));
        item[key]?.forEach((eachItem) => {
            if (eachItem['type'] == individualType) {
                individualAliases.push(eachItem['alias'])
            } else if (eachItem['type'] == groupType) {
                groupAliases.push(eachItem['alias'])
            }
        })

        let resp = <SpaceBetween direction='vertical'>
            {item[key] ? (Array.from(item[key]).map((value, index) => {
                return <span className='small_values'> {value['alias']} {value['column_index'] == 1 ? '(L8/Director)' : ''}</span>
            })) : ''}
        </SpaceBetween>

        return resp
    }

    const getAliases = (item, key, individualType, groupType) => {
        let individualAliases = [];
        let groupAliases = [];
        item[key]?.forEach((eachItem) => {
            if (eachItem['type'] == individualType) {
                individualAliases.push(eachItem['alias'])
            } else if (eachItem['type'] == groupType) {
                groupAliases.push(eachItem['alias'])
            }
        })

        let resp = <SpaceBetween direction='vertical'>
            {item[key] ? (Array.from(item[key]).map((value, index) => {
                return <span className='small_values'> {value['alias']} ({value['type'] == individualType ? 'user' : value['type'] == groupType ? 'ldap' : ''})</span>
            })) : ''}
        </SpaceBetween>

        return resp
    }

    const resetStatus = (item) => {
        setResetLoadingMessage("Resetting ..")
        var quarterValue = getQuarter()
        createAuditLog(`FRISCO#${item[PROCESS_KEYS.process_id]}`, `${getCurrentTime()}`, "Reset process via control panel", JSON.stringify(item), JSON.stringify(item), "RESET REQ", userId)
        resetProcessStatus(item[PROCESS_KEYS.process_id], new Date(dueDatePickerValue).valueOf(), quarterValue,
            function onSuccess(result) {
                setResetLoadingMessage(JSON.parse(result?.data.resetProcessStatus)?.message)
            },
            function onFailure(result) {
                setResetLoadingMessage("Reset failed")
            })
    }

    const loadEditableFields = async () => {
        let res = await getEditableFields()
        res[PROCESS_KEYS.approvers] = { values: [{ label: "USER", value: APPROVER_TYPES.APPROVER }, { label: "LDAP", value: APPROVER_TYPES.APPROVER_LDAP }] }
        res[PROCESS_KEYS.approvers] = { values: [{ label: "USER", value: APPROVER_TYPES.APPROVER }, { label: "LDAP", value: APPROVER_TYPES.APPROVER_LDAP }] }
        res[PROCESS_KEYS.uploaders] = { values: [{ label: "USER", value: UPLOADER_TYPES.UPLOADER }, { label: "LDAP", value: UPLOADER_TYPES.UPLOADER_LDAP }] }
        setFieldMetadata(res)
    }


    const getValue = (fieldName, subKey = null) => {
        if (subKey) {
            if (configurationWindowContent.hasOwnProperty(fieldName)) {
                return configurationWindowContent?.[fieldName][subKey]
            } else {
                return null
            }
        } else {
            return configurationWindowContent?.[fieldName]
        }

    }

    const postProcessTeamEmails = (value) => {
        return value.replaceAll("@amazon.com", "")
    }

    const approversValidation = (elements) => {
        let emptyValues = true
        elements?.forEach((element) => {
            if (!element.type || !element.alias) {
                emptyValues = false
            }
        })
        return emptyValues
    }

    const stripUniqueItemsAndMerge = (items, newItems) => {
        let lookup = {}
        let indexes = {}
        let resultItems = []
        newItems.forEach((val) => {
            indexes[val.column_index] = 1
        })
        for (let i = 0; i < items?.length; i++) {
            if (items[i].column_index in indexes) {
                continue
            } else {
                resultItems.push(items[i])
                let hashKey = `${items[i].alias}-${items[i].column_index}-${items[i].type}`
                if (!(hashKey in lookup)) {
                    lookup[hashKey] = 1
                }
            }

        }
        for (let i = 0; i < newItems?.length; i++) {
            let hashKey = `${newItems[i].alias}-${newItems[i].column_index}-${newItems[i].type}`
            if (!(hashKey in lookup)) {
                lookup[hashKey] = 1
                resultItems.push(newItems[i])
            }
        }
        return resultItems
    }

    const updateValue = (fieldName, value, subKey = null) => {
        if (subKey) {
            if (!configurationWindowContent.hasOwnProperty(fieldName)) {
                configurationWindowContent[fieldName] = {}
            }
            configurationWindowContent[fieldName][subKey] = value
        } else {
            if (fieldName == PROCESS_KEYS.approvers) {
                configurationWindowContent[fieldName] = stripUniqueItemsAndMerge(configurationWindowContent[fieldName], value)
            } else {
                configurationWindowContent[fieldName] = value
            }
        }
    }

    const getSelectFieldValues = (fieldName, subKey = null) => {
        if (fieldMetadata && fieldMetadata[fieldName] && fieldMetadata[fieldName]['values']) {
            return fieldMetadata[fieldName]['values']
        } else if (subKey && fieldMetadata && fieldMetadata[fieldName][subKey] && fieldMetadata[fieldName][subKey]['values']) {
            return fieldMetadata[fieldName][subKey]['values']
        } else {
            return []
        }
    }

    const resetModal = () => {
        setConfigurationWindowContent(null)
        setConfigurationWindowContentOriginal(null)
        setResetLoadingMessage(null)
        setWindowContentLoadingMessage(null)
    }

    const getEpoch = (dateStr, timeStr) => {
        try {
            if (!timeStr) {
                timeStr = "17:00"
            }
            return new Date(`${dateStr} ${timeStr}`).getTime()
        } catch (err) {
            return null
        }
    }

    const addOrreplace = (mainArr, item) => {
        let found = false
        for (let i = 0; i < mainArr.length; i++) {
            if (mainArr[i][PROCESS_KEYS.CUSTOM_ATTRIBUTES_KEYS.attribute_type] == item[PROCESS_KEYS.CUSTOM_ATTRIBUTES_KEYS.attribute_type]) {
                mainArr[i] = item
                found = true
            }
        }
        if (!found) {
            mainArr.push(item)
        }
        return mainArr
    }

    const getCurrentTime = () => {
        return Date.now();
    }

    const saveControlPanelDetails = () => {

        // generate update body
        let input = {}
        input[PROCESS_KEYS.process_id] = configurationWindowContent[PROCESS_KEYS.process_id]
        input[PROCESS_KEYS.process_name] = configurationWindowContent[PROCESS_KEYS.process_name]
        input[PROCESS_KEYS.app_id] = configurationWindowContent[PROCESS_KEYS.app_id]
        input[PROCESS_KEYS.approvers] = configurationWindowContent[PROCESS_KEYS.approvers]
        input[PROCESS_KEYS.uploaders] = configurationWindowContent[PROCESS_KEYS.uploaders]
        if (!input[PROCESS_KEYS.custom_attributes]) {
            input[PROCESS_KEYS.custom_attributes] = []
        }
        let due_date = {}
        due_date[PROCESS_KEYS.CUSTOM_ATTRIBUTES_KEYS.sub_type] = 'FRISCO',
            due_date[PROCESS_KEYS.CUSTOM_ATTRIBUTES_KEYS.attribute] = getEpoch(configurationWindowContent['due_date'], configurationWindowContent['due_time'])?.toString(),
            due_date[PROCESS_KEYS.CUSTOM_ATTRIBUTES_KEYS.attribute_type] = 'PRE_WB_DUE_DATE'
        if (due_date[PROCESS_KEYS.CUSTOM_ATTRIBUTES_KEYS.attribute] && due_date[PROCESS_KEYS.CUSTOM_ATTRIBUTES_KEYS.attribute] != 'NaN') {
            input[PROCESS_KEYS.custom_attributes] = addOrreplace(input[PROCESS_KEYS.custom_attributes], due_date)
        }
        let post_wb_due_date = {}
        post_wb_due_date[PROCESS_KEYS.CUSTOM_ATTRIBUTES_KEYS.sub_type] = 'FRISCO',
            post_wb_due_date[PROCESS_KEYS.CUSTOM_ATTRIBUTES_KEYS.attribute] = getEpoch(configurationWindowContent['post_wb_due_date'], configurationWindowContent['post_wb_due_time'])?.toString(),
            post_wb_due_date[PROCESS_KEYS.CUSTOM_ATTRIBUTES_KEYS.attribute_type] = 'POST_WB_DUE_DATE'
        if (post_wb_due_date[PROCESS_KEYS.CUSTOM_ATTRIBUTES_KEYS.attribute] && post_wb_due_date[PROCESS_KEYS.CUSTOM_ATTRIBUTES_KEYS.attribute] != 'NaN') {
            input[PROCESS_KEYS.custom_attributes] = addOrreplace(input[PROCESS_KEYS.custom_attributes], post_wb_due_date)
        }

        input[PROCESS_KEYS.last_updated_by] = userId
        setWindowContentLoadingMessage("Updating, please wait..")
        createAuditLog(`FRISCO#${configurationWindowContent[PROCESS_KEYS.process_id]}`, `${getCurrentTime()}`, "Update via control panel", JSON.stringify(configurationWindowContentOriginal), JSON.stringify(input), "UPDATE REQ", userId)
        updateProcessLite(input, function onSuccess(result) {
            loadProcessesWithTracksForQuarter(appSettings[currentStage].appId)
            resetModal()
        }, function onFailure(result) {
            resetModal()
        })
    }

    const getDueDates = (item) => {
        for (let i = 0; i < item?.[PROCESS_KEYS.custom_attributes]?.length; i++) {
            if (item[PROCESS_KEYS.custom_attributes][i][PROCESS_KEYS.CUSTOM_ATTRIBUTES_KEYS.attribute_type] == 'PRE_WB_DUE_DATE') {
                var due_date = new Date(parseInt(item[PROCESS_KEYS.custom_attributes][i][PROCESS_KEYS.CUSTOM_ATTRIBUTES_KEYS.attribute]))
                let month = ("0" + (due_date.getMonth() + 1)).slice(-2)
                let date = ("0" + due_date.getDate()).slice(-2)
                let hours = ("0" + due_date.getHours()).slice(-2)
                let mins = ("0" + due_date.getMinutes()).slice(-2)
                item['due_date'] = `${due_date.getFullYear()}-${month}-${date}`
                item['due_time'] = `${hours}:${mins}`
            }
            if (item[PROCESS_KEYS.custom_attributes][i][PROCESS_KEYS.CUSTOM_ATTRIBUTES_KEYS.attribute_type] == 'POST_WB_DUE_DATE') {
                var post_wb_due_date = new Date(parseInt(item[PROCESS_KEYS.custom_attributes][i][PROCESS_KEYS.CUSTOM_ATTRIBUTES_KEYS.attribute]))
                let month = ("0" + (post_wb_due_date.getMonth() + 1)).slice(-2)
                let date = ("0" + post_wb_due_date.getDate()).slice(-2)
                let hours = ("0" + post_wb_due_date.getHours()).slice(-2)
                let mins = ("00" + post_wb_due_date.getMinutes()).slice(-2)
                item['post_wb_due_date'] = `${post_wb_due_date.getFullYear()}-${month}-${date}`
                item['post_wb_due_time'] = `${hours}:${mins}`
            }
        }
        return item
    }
    const setConfigWindowJson = (item) => {
        item = getDueDates(item)
        setConfigurationWindowContent(item)
        setConfigurationWindowContentOriginal(item)
    }

    const showDueDatesTable = (date, time) => {
        return <SpaceBetween direction='horizontal'>
            <span className='small_values'>{date}</span>
            {
                time ? <span className='small_values'>T{time}</span> : ''
            }

        </SpaceBetween>
    }

    const getReportingCentralMainUrl = () => {
        let main_url = 'https://www.gamma.report-central.fintech.amazon.dev'
        if (currentStage == 'prod') {
          main_url = 'https://www.report-central.fintech.amazon.dev'
        }
        return main_url
      }

    const getCrReportTypeNameField = (item) => {
        if (item?.reportId) {
            return <Link href={`${getReportingCentralMainUrl}/#/reporting/existing-reports/view-report=%7B%22reportUuid%22%3A"${item['reportId']}"%7D`}>{item['reportTypeDisplayName']} </Link>
        } else {
            return <Box>
                {item['reportTypeDisplayName']}
            </Box>
        }
    }

    const getCrReportStatusField = (item) => {
        if (item?.approvalId && item.status &&item.status !='Not Started') {
            return <Link href={`${getReportingCentralMainUrl}/#/reporting/approvals/view-approval=%7B"workflowId"%3A"${item['approvalId']}"%7D`}>{item['status']} </Link>
        } else {
            return <Box>
                {item['status']}
            </Box>
        }
    }


    const Content = (
        currentStage ?
            <ContentLayout
                headerVariant="high-contrast"
                className="content"
                header={
                    <SpaceBetween size="l">
                        <Box variant="h1" margin={{ left: "xxxl" }}>
                            Deliverables
                        </Box>
                    </SpaceBetween>
                }
            >
                <ExpandableSection
                    defaultExpanded
                    variant="container"
                    headerText="Non-CR deliverables"
                >
                    <Table
                        columnDefinitions={[
                            {
                                id: "id",
                                header: "ID",
                                cell: item => (<Box>
                                    {item[PROCESS_KEYS.process_id]}
                                    {isAdmin ? <Box float="right"><Button iconName="settings" variant="inline-icon" onClick={() => { setConfigWindowJson(item) }} /></Box> : <></>}
                                </Box>),
                                sortingField: "id",
                                width: "100"
                            },
                            {
                                id: "workstream",
                                header: "Workstream",
                                cell: item => (
                                    <Box>
                                        {item.group_name}
                                    </Box>
                                ),
                                sortingField: "workstream",
                                isRowHeader: true,
                                width: "200"
                            },
                            {
                                id: "deliverables",
                                header: "Deliverables",
                                cell: item => (
                                    <Box>
                                        <Link href={`/App/${item.app_id}/process/${item.process_id}/upload?back=CorpReportingHome`}>{item[PROCESS_KEYS.label] || "-"}</Link>
                                    </Box>
                                ),
                                sortingField: "deliverables",
                                isRowHeader: true,
                                width: "200"
                            },
                            {
                                id: "status",
                                header: "Status",
                                cell: item => getStateNonAdminPlain(item),
                                sortingField: "status",
                                width: "220"
                            }, {
                                id: "due_date",
                                header: "Due date",
                                cell: item => showDueDatesTable(getDueDates(item)?.due_date, getDueDates(item)?.due_time),
                                sortingField: "due_date",
                                width: "150"
                            }, {
                                id: "post_wb_due_date",
                                header: "Post-WB due date",
                                cell: item => showDueDatesTable(getDueDates(item)?.post_wb_due_date, getDueDates(item)?.post_wb_due_time),
                                sortingField: "post_wb_due_date",
                                width: "150"
                            },
                            {
                                id: "uploaders",
                                header: "Uploaders",
                                cell: item => getAliases(item, PROCESS_KEYS.uploaders, UPLOADER_TYPES.UPLOADER, UPLOADER_TYPES.UPLOADER_LDAP),
                                sortingField: "uploaders"
                            },
                            {
                                id: "approvers",
                                header: "Approvers",
                                cell: item => getAliasesApprovers(item, PROCESS_KEYS.approvers, APPROVER_TYPES.APPROVER, APPROVER_TYPES.APPROVER_LDAP),
                                sortingField: "approvers"
                            }
                        ]}
                        enableKeyboardNavigation
                        items={filteredDeliverables}
                        loading={showDeliverablesLoader}
                        loadingText="Loading details"
                        resizableColumns
                        sortingDisabled
                        filter={
                            <Box>
                                <TextFilter
                                    filteringPlaceholder="Find non CR deliverables"
                                    filteringText={filteringText}
                                    onChange={(details) => { setFilteringText(details.detail.filteringText) }}
                                />
                                <SpaceBetween direction='horizontal' size='m'>
                                    <TokenGroup
                                        onDismiss={({ detail: { itemIndex } }) => {
                                            setFilters([
                                                ...filters.slice(0, itemIndex),
                                                ...filters.slice(itemIndex + 1)
                                            ]);
                                        }}
                                        items={filters}
                                    />
                                    {
                                        showDeliverablesLoaderMain ? <Box textAlign="center" margin={{ top: "xl" }}>{showDeliverablesLoaderMain} <Spinner></Spinner></Box> : <></>
                                    }
                                </SpaceBetween>
                            </Box>
                        }
                        empty={
                            <Box
                                margin={{ vertical: "xs" }}
                                textAlign="center"
                                color="inherit"
                            >
                                <SpaceBetween size="m">
                                    <b></b>
                                </SpaceBetween>
                            </Box>
                        }
                        pagination={
                            <Pagination currentPageIndex={1} pagesCount={1} />
                        }
                    />

                    {
                        configurationWindowContent ?
                            <Modal
                                onDismiss={() => resetModal()}
                                visible={configurationWindowContent}
                                footer={
                                    <Box float="right">
                                        <SpaceBetween direction="horizontal" size="xs">
                                            {
                                                windowContentLoadingMessage ?
                                                    <Box><Spinner></Spinner>{windowContentLoadingMessage}</Box> : ''
                                            }
                                            <Button variant="link" onClick={() => resetModal()}>Cancel</Button>
                                            <Button variant="primary" onClick={() => saveControlPanelDetails()} disabled={windowContentLoadingMessage} >Save</Button>
                                        </SpaceBetween>
                                    </Box>
                                }
                                header={`Control panel - ${configurationWindowContent && configurationWindowContent.hasOwnProperty(PROCESS_KEYS.label) ? configurationWindowContent[PROCESS_KEYS.label] : ''}`}
                                size='large'
                            >
                                <Container >
                                    <ColumnLayout columns={2}>
                                        <Box>Re-upload input</Box>
                                        <SpaceBetween direction='horizontal' size="s">
                                            <Box>
                                                {getQuarter()}
                                            </Box>
                                            <Button variant="primary" onClick={() => { resetStatus(configurationWindowContent) }}>Reset</Button>

                                            {
                                                resetLoadingMessage ? <Box float='center'> {resetLoadingMessage}</Box> : ''
                                            }
                                        </SpaceBetween>
                                    </ColumnLayout>

                                    <hr class="rounded"></hr>
                                    <ColumnLayout columns={2}>
                                        <Box>Due date</Box>
                                        <SpaceBetween direction='horizontal' size="s">
                                            <DatePicker
                                                onChange={({ detail }) => {
                                                    configurationWindowContent['due_date'] = detail.value
                                                    setConfigurationWindowContent({ ...configurationWindowContent })
                                                }}
                                                value={configurationWindowContent['due_date']}
                                                openCalendarAriaLabel={selectedDate =>
                                                (selectedDate
                                                    ? `, selected date is ${selectedDate}`
                                                    : "")
                                                }
                                                placeholder="YYYY/MM/DD"
                                                isDateEnabled={date =>
                                                    date.getDay() !== 6 && date.getDay() !== 0
                                                }
                                            />
                                            <TimeInput
                                                onChange={({ detail }) => {
                                                    configurationWindowContent['due_time'] = detail.value
                                                    setConfigurationWindowContent({ ...configurationWindowContent })
                                                }}
                                                value={configurationWindowContent['due_time']}
                                                format="hh:mm"
                                                placeholder="hh:mm"
                                            />

                                        </SpaceBetween>
                                    </ColumnLayout>
                                    <hr class="rounded"></hr>
                                    <ColumnLayout columns={2}>
                                        <Box>Post WB due date</Box>
                                        <SpaceBetween direction='horizontal' size="s">
                                            <DatePicker
                                                onChange={({ detail }) => {
                                                    configurationWindowContent['post_wb_due_date'] = detail.value
                                                    setConfigurationWindowContent({ ...configurationWindowContent })
                                                }}
                                                value={configurationWindowContent['post_wb_due_date']}
                                                openCalendarAriaLabel={selectedDate =>
                                                (selectedDate
                                                    ? `, selected date is ${selectedDate}`
                                                    : "")
                                                }
                                                placeholder="YYYY/MM/DD"
                                                isDateEnabled={date =>
                                                    date.getDay() !== 6 && date.getDay() !== 0
                                                }
                                            />
                                            <TimeInput
                                                onChange={({ detail }) => {
                                                    configurationWindowContent['post_wb_due_time'] = detail.value
                                                    setConfigurationWindowContent({ ...configurationWindowContent })
                                                }}
                                                value={configurationWindowContent['post_wb_due_time']}
                                                format="hh:mm"
                                                placeholder="hh:mm"
                                            />

                                        </SpaceBetween>
                                    </ColumnLayout>
                                    <hr class="rounded"></hr>
                                    <ColumnLayout columns={2}>
                                        <Box>Uploaders</Box>
                                        <CustomMultiHybridInputAndSelect field={PROCESS_KEYS.uploaders}
                                            inputKey={PROCESS_KEYS.UPLOADER_KEYS.alias} selectKey={PROCESS_KEYS.UPLOADER_KEYS.type} selectHint="Choose type"
                                            placeholder="alias" getValues={getValue} postProcess={postProcessTeamEmails} validator={approversValidation}
                                            updateValue={updateValue} getOptions={getSelectFieldValues} info=' ' errorMessage='type and alias input are mandatory' duplicatesNotAllowed={true} />
                                    </ColumnLayout>
                                    <hr class="rounded"></hr>
                                    <ColumnLayout columns={2}>
                                        <Box>Reviewers</Box>
                                        <CustomMultiHybridInputAndSelect field={PROCESS_KEYS.approvers}
                                            inputKey={PROCESS_KEYS.APPROVER_KEYS.alias} selectKey={PROCESS_KEYS.APPROVER_KEYS.type} selectHint="Choose type"
                                            placeholder="alias" getValues={getValue} filterCriteria={{ column_index: 0 }} postProcess={postProcessTeamEmails} validator={approversValidation} indexKey={PROCESS_KEYS.APPROVER_KEYS.column_index} defIndex={0}
                                            updateValue={updateValue} getOptions={getSelectFieldValues} info=' ' errorMessage='type and alias input are mandatory' duplicatesNotAllowed={true} />
                                    </ColumnLayout>
                                    <hr class="rounded"></hr>
                                    <ColumnLayout columns={2}>
                                        <Box>L8/Director Reviewers</Box>
                                        <CustomMultiHybridInputAndSelect field={PROCESS_KEYS.approvers}
                                            inputKey={PROCESS_KEYS.APPROVER_KEYS.alias} selectKey={PROCESS_KEYS.APPROVER_KEYS.type} selectHint="Choose type"
                                            placeholder="alias" getValues={getValue} filterCriteria={{ column_index: 1 }} postProcess={postProcessTeamEmails} validator={approversValidation} indexKey={PROCESS_KEYS.APPROVER_KEYS.column_index} defIndex={1}
                                            updateValue={updateValue} getOptions={getSelectFieldValues} info=' ' errorMessage='type and alias input are mandatory' duplicatesNotAllowed={true} />
                                    </ColumnLayout>
                                </Container>

                            </Modal> : <></>
                    }
                </ExpandableSection>



                <br />

                <ExpandableSection
                    defaultExpanded
                    variant="container"
                    headerText="CR deliverables"
                >

                    <Table
                        columnDefinitions={[
                            {
                                id: "reportTypeName",
                                header: "Deliverables",
                                cell: item => getCrReportTypeNameField(item),
                                sortingField: "reportTypeName",
                                width: "300"
                            },
                            {
                                id: "status",
                                header: "Status",
                                cell: item => getCrReportStatusField(item),
                                sortingField: "status",
                                width: "300"
                            },
                            {
                                id: "due_date",
                                header: "Due date",
                                cell: item => (<Box>
                                    -
                                </Box>),
                                sortingField: "due_date",
                                width: "300"
                            },
                            {
                                id: "post_wb_due_date",
                                header: "Post-WB due date",
                                cell: item => (<Box>
                                    -
                                </Box>),
                                sortingField: "post_wb_due_date",
                                width: "300"
                            },
                            {
                                id: "uploaders",
                                header: "Uploaders",
                                cell: item => (<Box>
                                    -
                                </Box>),
                                sortingField: "post_wb_due_date",
                                width: "300"
                            },
                            {
                                id: "approvers",
                                header: "Approvers",
                                cell: item => (<Box>
                                    {item['approvers']}
                                </Box>),
                                sortingField: "approvers",
                                width: "300"
                            }
                        ]}
                        enableKeyboardNavigation
                        items={filteredCrDeliverables}
                        loading={showCRDeliverablesLoader}
                        loadingText="Loading details"
                        resizableColumns
                        sortingDisabled
                        filter={
                            <Box>
                                <TextFilter
                                    filteringPlaceholder="Find CR deliverables"
                                    filteringText={filteringTextCRDeliverable}
                                    onChange={(details) => { setFilteringTextCRDeliverable(details.detail.filteringText) }}
                                />
                                <SpaceBetween direction='horizontal' size='m'>
                                    <TokenGroup
                                        onDismiss={({ detail: { itemIndex } }) => {
                                            setFiltersCrDeliverables([
                                                ...filtersCrDeliverables.slice(0, itemIndex),
                                                ...filtersCrDeliverables.slice(itemIndex + 1)
                                            ]);
                                        }}
                                        items={filtersCrDeliverables}
                                    />
                                    {
                                        showCRDeliverablesLoader ? <Box textAlign="center" margin={{ top: "xl" }}>{showCRDeliverablesLoader} <Spinner></Spinner></Box> : <></>
                                    }
                                </SpaceBetween>
                            </Box>
                        }
                        empty={
                            <Box
                                margin={{ vertical: "xs" }}
                                textAlign="center"
                                color="inherit"
                            >
                                <SpaceBetween size="m">
                                    <b></b>
                                </SpaceBetween>
                            </Box>
                        }
                        pagination={
                            <Pagination currentPageIndex={1} pagesCount={1} />
                        }
                    />

                </ExpandableSection>
                <br />
                <Feedback appId={appSettings[currentStage].appId} pageId="corpReportingDeliverablesHome" userId={userId} parentId={"null"} level={0} limit={1}></Feedback>

            </ContentLayout> : <Box>
                <Spinner></Spinner>
                Loading...
            </Box>
    )


    return (
        <AppLayout
            disableContentPaddings={true}
            content={Content}
            contentType="default"
            navigationOpen={NavOpen}
            navigation={<SideNav activeHref={`/CorpReportingHome/deliverables`} id={appSettings[currentStage].appId} isAdmin={isAdmin} />}
            onNavigationChange={() => {
                setNavOpen(!NavOpen);
            }}
            toolsHide={true}
            headerVariant="high-contrast"
        />
    );
};


