import React from 'react'
import { Spinner, Container, Header, ColumnLayout, Box} from '@amzn/awsui-components-react'

function WaitForPageAccess() {
  return (
    <div>
      <br></br>
      <ColumnLayout columns={4}>
        <div></div>
        <div>
          <Container
            header={<Header>Checking for page access</Header>}
          >
            <Box color='text-status-info' textAlign='center'>
              <Spinner variant='normal' size='large'></Spinner>
              <pre>waiting for response</pre>
            </Box>
          </Container>
        </div>
      </ColumnLayout>
      
    </div>
  )
}

export default WaitForPageAccess
