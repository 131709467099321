import React, { useState, useEffect, useRef } from "react";

import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";

import {
  AppLayout,
  BreadcrumbGroup,
  HelpPanel,
  Button,
  Box,
  Select,
  Spinner,
  Checkbox,
  SpaceBetween,
  Container,
  Header,
  Icon,
  SegmentedControl,
  Grid,
  Input,
  FormField,
  ColumnLayout,
  Modal
} from "@amzn/awsui-components-react";
import axios from "axios";

import SideNav from "./SideNav";
import appSettings from "./app_settings";

import { teamNames } from "./Constants";
import * as d3 from "d3";

import { AgGridReact } from "ag-grid-react";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";

import { setCurrentAppId } from "../../store/userAuthSlice";

import checkPageAccess from "../../checkPageAccess";
import AccessDenied from "../../AccessDenied";
import WaitForPageAccess from "../../WaitForPageAccess";

import { Auth, Amplify } from "aws-amplify";
import { Board, BoardItem } from "@amzn/awsui-board-components";
import {
  boardI18nStrings,
  boardItemI18nStrings,
} from "src/i18n-strings/board-I18nStrings";
import { Storage } from "aws-amplify";

const PrimaryLedgerToSecondaryLedger = () => {
  const userId = useSelector((globalState) => globalState.auth.userId);
  const history = useHistory();
  const currentAppId = useSelector(
    (globalState) => globalState.auth.currentAppId
  );
  const isCurrentAppAuthorised = useSelector(
    (globalState) => globalState.auth.isCurrentAppAuthorized
  );
  const [isCheckingAccess, setIsCheckingAccess] = useState(true);
  const [isAuthorized, setIsAuthorized] = useState();
  const dispatch = useDispatch();
  const gridRef = useRef();
  const gridRef2 = useRef();
  const [dsInfo, setDsInfo] = useState([]);
  const [showLoadingIcon, setShowLoadingIcon] = useState(false);
  const [showUpdatingIcon, setShowUpdatingIcon] = useState(false);
  const [updateStatus, setUpdateStatus] = useState(false);
  const [isItemSelected, setIsItemSelected] = useState(false)
  const [selectedTeamName, setSelectedTeamName] = useState({});
  const [isAWS, setIsAWS] = useState(false);
  const [tableBData, setTableBData] = useState([]);
  const [toolsHide, setToolsHide] = useState(true);
  const [selectedSegmentId, setSelectedSegmentId] = useState("add");
  const [addOrUpdateModal, setAddOrUpdateModal] = useState(false)
  const [addStatus, setAddStatus] = useState(false);

  const [selectedItem, setSelectedItem] = useState([]);
  const [boardItems, setBoardItems] = useState([
    {
      id: "TABLE_A",
      rowSpan: 5,
      columnSpan: 6,
      data: { content: <></> },
      header:
        "The following table shows the Primary Ledger to Secondary Ledger Mapping (Table A)",
    },
    {
      id: "TABLE_B",
      rowSpan: 5,
      columnSpan: 6,
      data: { content: <></> },
      header:
        "The following table shows the Primary Ledgers that are in Detailed Dataset but not in Primary Ledger to Secondary Ledger Mapping table. (Table B)",
    },
  ]);
  const [detailedDSInfo, setDetailedDSInfo] = useState([]);

  const [ledgerId, setLedgerId] = useState()
  const [primaryLedger, setPrimaryLedger] = useState()
  const [secondaryLedgerId, setSecondaryLedgerId] = useState()
  const [secondaryLedger, setSecondaryLedger] = useState()
  const [periodSetName, setPeriodSetName] = useState()
  const [ledgerDescription, setLedgerDescription] = useState()
  const [chartOfAccountsIds, setChartOfAccountsIds] = useState()
  const [active, setActive] = useState()
  const [currencyCode, setCurrencyCode] = useState()
  const [cap, setCap] = useState()
  const [secCoaid, setSecCoaid] = useState()
  const [secCurrCode, setSecCurrCode] = useState()
  const [secLedgerDescr, setSecLedgerDescr] = useState()
  const [secPeriodSetName, setSecPeriodSetName] = useState()


  const onSelectionChanged= () =>{
    const selectedRows = gridRef.current.api.getSelectedRows();
    if(selectedRows.length > 0) {

      setSelectedSegmentId("edit")
      setIsItemSelected(true)
      setLedgerId(selectedRows[0].LEDGER_ID)
      setPrimaryLedger(selectedRows[0].PRIMARY_LEDGER)
      setSecondaryLedgerId(selectedRows[0].SECONDARY_LEDGER_ID)
      setSecondaryLedger(selectedRows[0].SECONDARY_LEDGER)
      setPeriodSetName(selectedRows[0].PERIOD_SET_NAME)
      setLedgerDescription(selectedRows[0].LEDGER_DESCRIPTION)
      setChartOfAccountsIds(selectedRows[0].CHART_OF_ACCOUNTS_ID)
      setActive(selectedRows[0].Active)
      setCurrencyCode(selectedRows[0].CURRENCY_CODE)
      setCap(selectedRows[0].Cap)
      setSecCoaid(selectedRows[0].SEC_COAID)
      setSecCurrCode(selectedRows[0].SEC_CURR_CODE)
      setSecLedgerDescr(selectedRows[0].SEC_LEDGER_DESCR)
      setSecPeriodSetName(selectedRows[0].SEC_PERIOD_SET_NAME)

    } else {

      setIsItemSelected(false)
      setLedgerId()
      setPrimaryLedger()
      setSecondaryLedgerId()
      setSecondaryLedger()
      setPeriodSetName()
      setLedgerDescription()
      setChartOfAccountsIds()
      setActive()
      setCurrencyCode()
      setCap()
      setSecCoaid()
      setSecCurrCode()
      setSecLedgerDescr()
      setSecPeriodSetName()

    }
  }

  useEffect(() => {
    const primaryLedgers = dsInfo.map((row) => row.PRIMARY_LEDGER);
    const tableB = detailedDSInfo.filter((row) => {
      return (!primaryLedgers.includes(row.ledger_name) &&
      (
        !["Additional Dataset", "MJE", "GLTD" ].includes(row.dataset)
      ))
    });

    setBoardItems((previous) => {
      const updated = boardItems.map((item) => {
        if (item.id === "TABLE_A") {
          item.data.content = (
            <>
              <div
                className="ag-theme-alpine"
                style={{ height: "92%", width: "100%" }}
              >
                <AgGridReact
                  ref={gridRef}
                  rowData={dsInfo}
                  defaultColDef={defaultColDef}
                  columnDefs={columnDefs}
                  rowSelection="single"
                  animateRows={true}
                  rowHeight={30}
                  enableCellTextSelection={true}
                  onSelectionChanged={onSelectionChanged}
                  rowMultiSelectWithClick={true}
                ></AgGridReact>
              </div>
            </>
          );
        } else {
          item.data.content = (
            <div
              className="ag-theme-alpine"
              style={{ height: "92%", width: "100%" }}
            >
              <AgGridReact
                ref={gridRef2}
                rowData={tableB}
                columnDefs={tableBcolumnDefs}
                defaultColDef={defaultColDef}
                rowSelection="single"
                animateRows={true}
                rowHeight={30}
                enableCellTextSelection={true}
                rowMultiSelectWithClick={true}
              ></AgGridReact>
            </div>
          );
        }
        return item;
      });
      return updated;
    });
    console.log(dsInfo)
  }, [dsInfo, detailedDSInfo]);
  useEffect(() => {
    if (currentAppId !== appSettings.appId) {
      dispatch(setCurrentAppId(appSettings.appId));
    }
    setIsAuthorized(isCurrentAppAuthorised);
    if (isCurrentAppAuthorised) {
      LoadData();
    }
    return () => {
      // this now gets called when the component unmounts
    };
  }, [isCurrentAppAuthorised]);

  useEffect(() => {
    if(isAuthorized === true || isAuthorized === false){
      setIsCheckingAccess(!isCheckingAccess);
    } 
  }, [isAuthorized]);

  const breadItems = [
    { text: "Home", href: "/Home" },
    { text: "ATeam BI", href: "/ATeamBIHome" },
    { text: "Boffin", href: "/BoffinHome" },
    { text: "Primary Ledger to Secondary Ledger Mapping", href: "/" },
  ];

  const onFollowHandler = (ev) => {
    ev.preventDefault();
    if (ev.detail.href) {
      history.push(ev.detail.href.substring(1));
    }
  };

  const LoadData = async () => {
    setShowLoadingIcon(true);
    setIsItemSelected(false);
    // console.log('Need to execute sql here..')
    let mSQL = "SELECT * FROM boffin.Primary_Secondary_Ledger_Relationship;";
    let body =
      `{
          "processName" : "MySQL",
          "scriptName"  : "executeSQLonMySQL",
          "parameters"  : {"sql":"` +
      mSQL +
      `"}
        }`;

    // console.log(body)
    const ApiGateWayEndpoint = Amplify.Cache.config.ApiGateWayEndpoint;
    let url = ApiGateWayEndpoint + "/executeOnDemand";
    axios
      .post(url, body, {
        headers: {
          Authorization: (await Auth.currentSession())
            .getIdToken()
            .getJwtToken(),
        },
      })
      .then((response) => {
        //  console.log(response.data.data)
        setDsInfo(response.data.data);
      });

    let folder = "Boffin/datasets/";
    let detailedDatasetFn = folder + "boffin_datasets_detailed_info.csv";
    const detailedDatasetFileURL = await Storage.get(detailedDatasetFn, {
      level: "public",
      expires: 60,
    });
    let detailedDatasetCSVData = await d3
      .csv(detailedDatasetFileURL)
      .then((result) => {
        setDetailedDSInfo(result);
        // setState here..
      });

    setShowLoadingIcon(false);
  };

  const defaultColDef = {
    // set filtering on for all columns
    filter: true,
    filterParams: {
      buttons: ["reset"],
      debounceMs: 200,
      maxNumConditions: 10

    },
  };

  const columnDefs = [
    {
      field: "LEDGER_ID",
      width: "250px",
      resizable: true,
      sortable: true,
    },
    {
      field: "PRIMARY_LEDGER",
      width: "250px",
      resizable: true,
      sortable: true,
    },
    {
      field: "SECONDARY_LEDGER_ID",
      width: "250px",
      resizable: true,
      sortable: true,
    },
    {
      field: "SECONDARY_LEDGER",
      width: "250px",
      resizable: true,
      sortable: true,
    },
    {
      field: "PERIOD_SET_NAME",
      width: "250px",
      resizable: true,
      sortable: true,
    },
    {
      field: "LEDGER_DESCRIPTION",
      width: "250px",
      resizable: true,
      sortable: true,
    },
    {
      field: "CHART_OF_ACCOUNTS_ID",
      width: "250px",
      resizable: true,
      sortable: true,
    },
    {
      field: "Active",
      width: "250px",
      resizable: true,
      sortable: true,
    },
    {
      field: "CURRENCY_CODE",
      width: "250px",
      resizable: true,
      sortable: true,
    },
    {
      field: "Cap",
      width: "250px",
      resizable: true,
      sortable: true,
    },
    {
      field: "SEC_COAID",
      width: "250px",
      resizable: true,
      sortable: true,
    },
    {
      field: "SEC_CURR_CODE",
      width: "250px",
      resizable: true,
      sortable: true,
    },
    {
      field: "SEC_LEDGER_DESCR",
      width: "250px",
      resizable: true,
      sortable: true,
    },
    {
      field: "SEC_PERIOD_SET_NAME",
      width: "250px",
      resizable: true,
      sortable: true,
    },
    { field: "" },
  ];

  const tableBcolumnDefs = [
    {
      field: "ledger_name",
      width: "250px",
      sortable: true,
      resizable: true,
      headerName: "Ledger Name",
    },
    {
      field: "dataset",
      width: "250px",
      sortable: true,
      resizable: true,
      headerName: "Dataset",
    },
    { field: "", width: "250px" },
    { field: "", width: "250px" },
    { field: "", width: "250px" },
  ];

  const addRecord = async () => {

    if (!(ledgerId && primaryLedger && secondaryLedgerId && 
      secondaryLedger && periodSetName && ledgerDescription && chartOfAccountsIds
      && active && currencyCode && cap && secCoaid && secCurrCode && secLedgerDescr
      && secPeriodSetName)) {
      setAddOrUpdateModal(true)
      return;
    }
    setShowUpdatingIcon(true)
    console.log("In add record!");

    let mSQL =
      "INSERT into boffin.Primary_Secondary_Ledger_Relationship (LEDGER_ID, PRIMARY_LEDGER, SECONDARY_LEDGER_ID, SECONDARY_LEDGER, PERIOD_SET_NAME, LEDGER_DESCRIPTION, CHART_OF_ACCOUNTS_ID, Active, CURRENCY_CODE, Cap, SEC_COAID, SEC_CURR_CODE, SEC_LEDGER_DESCR, SEC_PERIOD_SET_NAME) VALUES ('" +
      ledgerId +
      "', '" +
      primaryLedger +
      "', '" +
      secondaryLedgerId +
      "', '" +
      secondaryLedger +
      "', '" +
      periodSetName +
      "', '" +
      ledgerDescription +
      "', '" +
      chartOfAccountsIds +
      "', '" +
      active +
      "', '" +
      currencyCode +
      "', '" +
      cap +
      "', '" +
      secCoaid +
      "', '" +
      secCurrCode +
      "', '" +
      secLedgerDescr +
      "', '" +
      secPeriodSetName +
      "');";

    let body =
      `{
          "processName" : "MySQL",
          "scriptName"  : "executeSQLonMySQL",
          "parameters"  : {"sql":"` +
      mSQL +
      `"}
        }`;

    // console.log(body)
    const ApiGateWayEndpoint = Amplify.Cache.config.ApiGateWayEndpoint;
    let url = ApiGateWayEndpoint + "/executeOnDemand";
    axios
      .post(url, body, {
        headers: {
          Authorization: (await Auth.currentSession())
            .getIdToken()
            .getJwtToken(),
        },
      })
      .then((response) => {
      
          setShowUpdatingIcon(false);
          setAddStatus(true);
          LoadData();
          setLedgerId()
          setPrimaryLedger()
          setSecondaryLedgerId()
          setSecondaryLedger()
          setPeriodSetName()
          setLedgerDescription()
          setChartOfAccountsIds()
          setActive()
          setCurrencyCode()
          setCap()
          setSecCoaid()
          setSecCurrCode()
          setSecLedgerDescr()
          setSecPeriodSetName()
        
      });
  };

  const updateRecord = async () => {

    if (!(ledgerId && primaryLedger && secondaryLedgerId && 
      secondaryLedger && periodSetName && ledgerDescription && chartOfAccountsIds
      && active && currencyCode && cap && secCoaid && secCurrCode && secLedgerDescr
      && secPeriodSetName)) {
      setAddOrUpdateModal(true)
      return;
    }
    setShowUpdatingIcon(true)
    let mSQL =
      "UPDATE boffin.Primary_Secondary_Ledger_Relationship SET PRIMARY_LEDGER = '" +
      primaryLedger +
      "', SECONDARY_LEDGER_ID = '" +
      secondaryLedgerId +
      "', SECONDARY_LEDGER = '" +
      secondaryLedger +
      "', PERIOD_SET_NAME = '" +
      periodSetName +
      "', LEDGER_DESCRIPTION = '" +
      ledgerDescription +
      "', CHART_OF_ACCOUNTS_ID = '" +
      chartOfAccountsIds +
      "', Active = '" +
      active +
      "', CURRENCY_CODE = '" +
      currencyCode +
      "', Cap = '" +
      cap +
      "', SEC_COAID = '" +
      secCoaid +
      "', SEC_CURR_CODE = '" +
      secCurrCode +
      "', SEC_LEDGER_DESCR = '" +
      secLedgerDescr +
      "', SEC_PERIOD_SET_NAME = '" +
      secPeriodSetName +
      "' WHERE LEDGER_ID ='" +
      ledgerId +
      "';";
    let body =
      `{
          "processName" : "MySQL",
          "scriptName"  : "executeSQLonMySQL",
          "parameters"  : {"sql":"` +
      mSQL +
      `"}
        }`;

    // console.log(body)
    const ApiGateWayEndpoint = Amplify.Cache.config.ApiGateWayEndpoint;
    let url = ApiGateWayEndpoint + "/executeOnDemand";
    axios
      .post(url, body, {
        headers: {
          Authorization: (await Auth.currentSession())
            .getIdToken()
            .getJwtToken(),
        },
      })
      .then((response) => {
        //  console.log(response.data.records)
          setShowUpdatingIcon(false);
          setUpdateStatus(true);
          LoadData();
          setSelectedSegmentId("add")
          setLedgerId()
          setPrimaryLedger()
          setSecondaryLedgerId()
          setSecondaryLedger()
          setPeriodSetName()
          setLedgerDescription()
          setChartOfAccountsIds()
          setActive()
          setCurrencyCode()
          setCap()
          setSecCoaid()
          setSecCurrCode()
          setSecLedgerDescr()
          setSecPeriodSetName()
      });
  };

  const downloadFileClick = () => {

    if(dsInfo.length >= 0) {
      let csv = '';

      for (let col of columnDefs) {
        if(col.field) {
          csv += col.field + ','
        }
      }
      csv += '\n'

      for(let row of dsInfo) {
        csv += `"${row.LEDGER_ID}"` + ','
        csv += `"${row.PRIMARY_LEDGER}"` + ','
        csv += `"${row.SECONDARY_LEDGER_ID}"` + ','
        csv += `"${row.SECONDARY_LEDGER}"` + ','
        csv += `"${row.PERIOD_SET_NAME}"` + ','
        csv += `"${row.LEDGER_DESCRIPTION}"` + ','
        csv += `"${row.CHART_OF_ACCOUNTS_ID}"` + ','
        csv += `"${row.Active}"` + ','
        csv += `"${row.CURRENCY_CODE}"` + ','
        csv += `"${row.Cap}"` + ','
        csv += `"${row.SEC_COAID}"` + ','
        csv += `"${row.SEC_CURR_CODE}"` + ','
        csv += `"${row.SEC_LEDGER_DESCR}"` + ','
        csv += `"${row.SEC_PERIOD_SET_NAME}"` + ','
        csv += '\n'
      }
    
    let link = document.createElement('a')
    link.id = 'download-csv'
    link.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(csv));
    link.setAttribute('download', 'PrimaryLedgerToSecondaryLedgerMapping.csv');
    document.body.appendChild(link)
    document.querySelector('#download-csv').click()

    }

  }


  const helpContent = (
    <HelpPanel
      header={
        <div>
          <h2>Add New Record / Edit Existing Record in Table A</h2>
        </div>
      }
    >
      <Container>
        <Box>
          <SegmentedControl
            selectedId={selectedSegmentId}
            onChange={({ detail }) =>{ 
              setSelectedSegmentId(detail.selectedId)
              if(detail.selectedId === 'add') {
                gridRef.current.api.deselectAll()
              }
            }}
            options={[
              { text: "Add New Record", id: "add", iconName: "add-plus" },
              { text: "Edit Existing Record", id: "edit", iconName: "edit" },
            ]}
          />
        </Box>
        <br />
        {selectedSegmentId === "add" ? (
          <>
          <Box>
            <ColumnLayout columns={2}>
              <FormField label="LEDGER_ID">
                <Input
                  type="number"
                  value={ledgerId}
                  onChange={({ detail }) => setLedgerId(detail.value)}
                  ariaRequired
                ></Input>
              </FormField>
              <FormField label="PRIMARY_LEDGER">
                <Input
                  type="text"
                  value={primaryLedger}
                  onChange={({ detail }) => setPrimaryLedger(detail.value)}
                  ariaRequired
                ></Input>
              </FormField>
              <FormField label="SECONDARY_LEDGER_ID">
                <Input
                  type="number"
                  value={secondaryLedgerId}
                  onChange={({ detail }) => setSecondaryLedgerId(detail.value)}
                ></Input>
              </FormField>
              <FormField label="SECONDARY_LEDGER">
                <Input
                  type="text"
                  value={secondaryLedger}
                  onChange={({ detail }) => setSecondaryLedger(detail.value)}
                ></Input>
              </FormField>
              <FormField label="PERIOD_SET_NAME">
                <Input
                  type="text"
                  value={periodSetName}
                  onChange={({ detail }) => setPeriodSetName(detail.value)}
                ></Input>
              </FormField>
              <FormField label="LEDGER_DESCRIPTION">
                <Input
                  type="text"
                  value={ledgerDescription}
                  onChange={({ detail }) => setLedgerDescription(detail.value)}
                ></Input>
              </FormField>
              <FormField label="CHART_OF_ACCOUNTS_ID">
                <Input
                  type="number"
                  value={chartOfAccountsIds}
                  onChange={({ detail }) => setChartOfAccountsIds(detail.value)}
                ></Input>
              </FormField>
              <FormField label="Active">
                <Input
                  type="text"
                  value={active}
                  onChange={({ detail }) => setActive(detail.value)}
                ></Input>
              </FormField>
              <FormField label="CURRENCY_CODE">
                <Input
                  type="text"
                  value={currencyCode}
                  onChange={({ detail }) => setCurrencyCode(detail.value)}
                ></Input>
              </FormField>
              <FormField label="Cap">
                <Input
                  type="number"
                  value={cap}
                  onChange={({ detail }) => setCap(detail.value)}
                ></Input>
              </FormField>
              <FormField label="SEC_COAID">
                <Input
                  type="number"
                  value={secCoaid}
                  onChange={({ detail }) => setSecCoaid(detail.value)}
                ></Input>
              </FormField>
              <FormField label="SEC_CURR_CODE">
                <Input
                  type="text"
                  value={secCurrCode}
                  onChange={({ detail }) => setSecCurrCode(detail.value)}
                ></Input>
              </FormField>
              <FormField label="SEC_LEDGER_DESCR">
                <Input
                  type="text"
                  value={secLedgerDescr}
                  onChange={({ detail }) => setSecLedgerDescr(detail.value)}
                ></Input>
              </FormField>
              <FormField label="SEC_PERIOD_SET_NAME">
                <Input
                  type="text"
                  value={secPeriodSetName}
                  onChange={({ detail }) => setSecPeriodSetName(detail.value)}
                ></Input>
              </FormField>
            </ColumnLayout>
          </Box>
          <br />
          <Button variant='primary' iconName= "add-plus" onClick={() => addRecord()}> Add</Button>
          <Box>
            {showUpdatingIcon ? (
              <Box>
                {" "}
                <Spinner></Spinner> Adding...{" "}
              </Box>
            ) : (
              <Box>
               
              </Box>
            )}
          </Box>
          </>
        ) : (
          <>
          {(isItemSelected) ? <>
            <Box>
            <ColumnLayout columns={2}>
              <FormField label="LEDGER_ID">
                <Input
                  type="number"
                  value={ledgerId}
                  onChange={({ detail }) => setLedgerId(detail.value)}
                  disabled
                ></Input>
              </FormField>
              <FormField label="PRIMARY_LEDGER">
                <Input
                  type="text"
                  value={primaryLedger}
                  onChange={({ detail }) => setPrimaryLedger(detail.value)}
                ></Input>
              </FormField>
              <FormField label="SECONDARY_LEDGER_ID">
                <Input
                  type="number"
                  value={secondaryLedgerId}
                  onChange={({ detail }) => setSecondaryLedgerId(detail.value)}
                ></Input>
              </FormField>
              <FormField label="SECONDARY_LEDGER">
                <Input
                  type="text"
                  value={secondaryLedger}
                  onChange={({ detail }) => setSecondaryLedger(detail.value)}
                ></Input>
              </FormField>
              <FormField label="PERIOD_SET_NAME">
                <Input
                  type="text"
                  value={periodSetName}
                  onChange={({ detail }) => setPeriodSetName(detail.value)}
                ></Input>
              </FormField>
              <FormField label="LEDGER_DESCRIPTION">
                <Input
                  type="text"
                  value={ledgerDescription}
                  onChange={({ detail }) => setLedgerDescription(detail.value)}
                ></Input>
              </FormField>
              <FormField label="CHART_OF_ACCOUNTS_ID">
                <Input
                  type="number"
                  value={chartOfAccountsIds}
                  onChange={({ detail }) => setChartOfAccountsIds(detail.value)}
                ></Input>
              </FormField>
              <FormField label="Active">
                <Input
                  type="text"
                  value={active}
                  onChange={({ detail }) => setActive(detail.value)}
                ></Input>
              </FormField>
              <FormField label="CURRENCY_CODE">
                <Input
                  type="text"
                  value={currencyCode}
                  onChange={({ detail }) => setCurrencyCode(detail.value)}
                ></Input>
              </FormField>
              <FormField label="Cap">
                <Input
                  type="number"
                  value={cap}
                  onChange={({ detail }) => setCap(detail.value)}
                ></Input>
              </FormField>
              <FormField label="SEC_COAID">
                <Input
                  type="number"
                  value={secCoaid}
                  onChange={({ detail }) => setSecCoaid(detail.value)}
                ></Input>
              </FormField>
              <FormField label="SEC_CURR_CODE">
                <Input
                  type="text"
                  value={secCurrCode}
                  onChange={({ detail }) => setSecCurrCode(detail.value)}
                ></Input>
              </FormField>
              <FormField label="SEC_LEDGER_DESCR">
                <Input
                  type="text"
                  value={secLedgerDescr}
                  onChange={({ detail }) => setSecLedgerDescr(detail.value)}
                ></Input>
              </FormField>
              <FormField label="SEC_PERIOD_SET_NAME">
                <Input
                  type="text"
                  value={secPeriodSetName}
                  onChange={({ detail }) => setSecPeriodSetName(detail.value)}
                ></Input>
              </FormField>
            </ColumnLayout>
          </Box>
          <br />
          <Button variant='primary' iconName= "edit" onClick={() => updateRecord()}>Update</Button>
          <Box>
          {showUpdatingIcon ? (
              <Box>
                {" "}
                <Spinner></Spinner> Updating...{" "}
              </Box>
            ) : (
              <Box>
                
              </Box>
            )}
          </Box>
          </>
          :
        <Box variant="awsui-key-label">Select a row from Table A to edit and update.</Box>
        }
          
          </>
        )}
      </Container>
    </HelpPanel>
  );

  const content = (
    <div>
      {isAuthorized ? (
        <Box>
          {showLoadingIcon ? (
            <Box>
              <Spinner></Spinner>
              <Box>Loading Primary Ledger to Secondary Ledger Mapping...</Box>
              <Box>Please wait, takes upto a minute...</Box>
            </Box>
          ) : (
            <Box>
              <Container
                header={
                  <Header
                    actions={
                      <SpaceBetween direction="horizontal" size="xs">
                        <Button
                          iconName="download"
                          onClick={() => downloadFileClick()}
                        >
                          Download Table A
                        </Button>
                        <Button
                          variant="normal"
                          iconName="refresh"
                          onClick={() => LoadData()}
                        >
                          Refresh
                        </Button>
                      </SpaceBetween>
                    }
                  >
                    Primary Ledger to Secondary Ledger Mapping
                  </Header>
                }
              >
                <Board
                  renderItem={(item) => (
                    <BoardItem
                      i18nStrings={boardItemI18nStrings}
                      header={<Header>{item.header}</Header>}
                    >
                      {item.data.content}
                    </BoardItem>
                  )}
                  onItemsChange={(event) => setBoardItems(event.detail.items)}
                  items={boardItems}
                  i18nStrings={boardI18nStrings}
                />
              </Container>
            </Box>
          )}
          <Modal
            visible={addOrUpdateModal}
            header={<div>Error</div>}
            onDismiss={() => {
              setAddOrUpdateModal(false)
            }}
            >
            All Fields are required!
          </Modal>
          <Modal
            visible={addStatus}
            header={<div>Info</div>}
            onDismiss={() => {
              setAddStatus(false)
            }}
            >
            <Icon variant="success" name="status-positive"></Icon>
            Record Added!
          </Modal>
          <Modal
            visible={updateStatus}
            header={<div>Info</div>}
            onDismiss={() => {
              setUpdateStatus(false)
            }}
            >
            <Icon variant="success" name="status-positive"></Icon>
            Record Updated!
          </Modal>
        </Box>
      ) : isCheckingAccess ? (
        <div>
          <WaitForPageAccess></WaitForPageAccess>
        </div>
      ) : (
        <div>
          <AccessDenied appName={appSettings.appName}></AccessDenied>
        </div>
      )}
    </div>
  );

  return (
    <AppLayout
      breadcrumbs={
        <BreadcrumbGroup
          items={breadItems}
          onFollow={(event) => onFollowHandler(event)}
        ></BreadcrumbGroup>
      }
      content={content}
      navigation={
        <SideNav activeHref="/BoffinPrimaryLedgerToSecondaryLedgerMapping" />
      }
      headerSelector="#TopBar"
      navigationHide={false}
      toolsWidth={550}
      toolsOpen={toolsHide}
      onToolsChange={()=>{setToolsHide(!toolsHide)}}
      maxContentWidth={Number.MAX_VALUE}
      tools={isAuthorized ? helpContent : ''}
      headerVariant="high-contrast"
    ></AppLayout>
  );
};

export default PrimaryLedgerToSecondaryLedger;
