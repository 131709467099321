import React, { useState, useEffect, useMemo } from "react";
import SideNav from "./SideNav";
import { Link, useParams } from "react-router-dom";

import {
  AppLayout,
  BreadcrumbGroup,
  Container,
  Box,
  Cards,
  HelpPanel,
  Header,
  PropertyFilter,
  Badge,
  Button,
  SpaceBetween,
} from "@amzn/awsui-components-react";

import "../Home/homepage.scss";
import { useSelector, useDispatch } from "react-redux";

import { useHistory } from "react-router-dom";

import { API, graphqlOperation } from "aws-amplify";

import { headings, toolsData } from "./Constants";
const GeneralTools = () => {
  const history = useHistory();
  const currentAppId = useSelector(
    (globalState) => globalState.auth.currentAppId
  );
  const isCurrentAppAuthorised = useSelector(
    (globalState) => globalState.auth.isCurrentAppAuthorized
  );

  const [isCheckingAccess] = useState(true);
  const [, setIsAuthorized] = useState(false);
  const [query, setQuery] = useState({
    tokens: [],
    operation: "and",
  });
  const [products, setProducts] = useState([]);

  const [isProducsLoading, setIsProducsLoading] = useState(true);
  const [toolsHide, setToolsHide] = useState(true);

  const [filteringOptions, setFilteringOptions] = useState([]);
  const [NavOpen, setNavOpen] = useState(true);
  const [toolsOpen, setToolsOpen] = useState(false);

  const { id } = useParams();

  const [descState, setDescState] = useState({});

  const dispatch = useDispatch();

  const filteredProducts = useMemo(() => {
    // console.log(query)
    if (query.tokens.length > 0) {
      if (query.tokens[0].propertyKey == "app") {
        return products.filter((item) => {
          return item.app
            .toLowerCase()
            .includes(query.tokens[0].value.toLowerCase());
        });
      }
    } else {
      // console.log(products)
      return products;
    }
  }, [query, products]);

  useEffect(() => {
    loadProducts();

    return () => {
      // this now gets called when the component unmounts
    };
  }, [id]);

  const loadProducts = async () => {
    var data = toolsData.map((data) => data.app);
    var mTmp = [...new Set(data)];
    var mAN = [];
    mTmp.map((i) => mAN.push({ propertyKey: "app", value: i }));

    const lst = [...mAN];
    // console.log(lst)

    setFilteringOptions(lst);

    setProducts(
      toolsData.filter((item) => item.tool.toUpperCase() === id.toUpperCase())
    );

    const obj = {}
    products.forEach((item) => {
      obj[item.app] = false
    })

    setDescState(obj)
    setIsProducsLoading(false);
  };

  const onFollowHandler = (ev) => {
    ev.preventDefault();
    if (ev.detail.href) {
      history.push(ev.detail.href.substring(1));
    }
  };
  const breadItems = [
    { text: "Home", href: "/" },
    { text: "General Tools", href: "" },
  ];

  const filteringProperties = [
    {
      key: "app",
      operators: ["="],
      propertyLabel: "App Name",
      groupValuesLabel: "App Names",
    },
  ];

  const showMoreOrHide = (app) => {
    setDescState( previous => (
     {
      ...previous,
      [app] : !previous[app]
    }
    ))
  }

  const Content = (
    <Box>
      <br></br>
      <Container
        header={
          <div>
            <Box variant="h1" color="text-body-secondary">
              {headings[id]}
            </Box>
          </div>
        }
      >
        <PropertyFilter
          onChange={({ detail }) => {
            setQuery(detail);
          }}
          query={query}
          i18nStrings={{
            filteringAriaLabel: "your choice",
            dismissAriaLabel: "Dismiss",
            filteringPlaceholder:
              "Filter Apps by Name, Process Owner or Developer Name",
            groupValuesText: "Values",
            groupPropertiesText: "Properties",
            operatorsText: "Operators",
            operationAndText: "and",
            operationOrText: "or",
            operatorLessText: "Less than",
            operatorLessOrEqualText: "Less than or equal",
            operatorGreaterText: "Greater than",
            operatorGreaterOrEqualText: "Greater than or equal",
            operatorContainsText: "Contains",
            operatorDoesNotContainText: "Does not contain",
            operatorEqualsText: "Equals",
            operatorDoesNotEqualText: "Does not equal",
            editTokenHeader: "Edit filter",
            propertyText: "Property",
            operatorText: "Operator",
            valueText: "Value",
            cancelActionText: "Cancel",
            applyActionText: "Apply",
            allPropertiesLabel: "All properties",
            tokenLimitShowMore: "Show more",
            tokenLimitShowFewer: "Show fewer",
            clearFiltersText: "Clear filters",
            removeTokenButtonAriaLabel: (token) =>
              `Remove token ${token.propertyKey} ${token.operator} ${token.value}`,
            enteredTextLabel: (text) => `Use: "${text}"`,
          }}
          countText={<Badge>{`"${filteredProducts?.length}" found`}</Badge>}
          expandToViewport
          filteringOptions={filteringOptions}
          filteringProperties={filteringProperties}
        />

        <br></br>
        <Cards
          cardDefinition={{
            header: (item) => item.app,
            sections: [
              {
                id: "appDesc",
                header: "",
                content: (item) => (item.description.length < 120 ? item.description : (

                  !descState[item.app] ?
                  (
                  <div>
                  {`${item.description.substring(0,120)}...  `}
                  <Button variant="link" onClick={()=>{showMoreOrHide(item.app)}}>Show More</Button>
                  </div>
                  ) :
                  (
                    <div>
                    {item.description}
                    <Button variant="link" onClick={()=>{showMoreOrHide(item.app)}}>Hide</Button>
                    </div>
                  )
                  
                  )
                  ),
              },
              {
                id: "Buttons",
                content: (item) => {
                  return (
                    <SpaceBetween direction="horizontal" size="m">
                      {item.buttons.map((button, index) => (
                        <Button
                          href={button.url}
                          target="_blank"
                          variant={index === 0 ? "primary" : "normal"}
                        >
                          {button.label}
                        </Button>
                      ))}
                    </SpaceBetween>
                  );
                },
              },
            ],
          }}
          cardsPerRow={[{ minWidth: 500, cards: 2 }]}
          items={filteredProducts}
          loading={isProducsLoading}
          loadingText="Loading resources"
        />
      </Container>
    </Box>
  );
  return (
    <AppLayout
      breadcrumbs={
        <BreadcrumbGroup
          items={breadItems}
          onFollow={(event) => onFollowHandler(event)}
        ></BreadcrumbGroup>
      }
      disableContentPaddings={false}
      navigation={<SideNav activeHref={`/GeneralTools/${id}`} />}
      navigationOpen={NavOpen}
      content={Content}
      contentType="default"
      headerSelector="#TopBar"
      toolsHide={true}
      toolsOpen={true}
      onNavigationChange={() => {
        setNavOpen(!NavOpen);
      }}
    />
  );
};

export default GeneralTools;
