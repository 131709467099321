import React, { useState, useEffect, useRef } from "react";

import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";

import {
  AppLayout,
  BreadcrumbGroup,
  HelpPanel,
  Button,
  Box,
  Select,
  Spinner,
  Checkbox,
  SpaceBetween,
  Container,
  Header,
  Icon,
  SegmentedControl,
  Grid,
  Input,
  FormField,
  ColumnLayout,
  Modal
} from "@amzn/awsui-components-react";
import axios from "axios";

import SideNav from "./SideNav";
import appSettings from "./app_settings";

import { teamNames } from "./Constants";

import { AgGridReact } from "ag-grid-react";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import * as d3 from "d3";

import { setCurrentAppId } from "../../store/userAuthSlice";

import checkPageAccess from "../../checkPageAccess";
import AccessDenied from "../../AccessDenied";
import WaitForPageAccess from "../../WaitForPageAccess";

import { Auth, Amplify, Storage } from "aws-amplify";
import {Board, BoardItem} from "@amzn/awsui-board-components";
import { boardI18nStrings, boardItemI18nStrings } from 'src/i18n-strings/board-I18nStrings';

const BoffinSupplierMasterListOverride = () => {
  const userId = useSelector((globalState) => globalState.auth.userId);
  const history = useHistory();
  const currentAppId = useSelector(
    (globalState) => globalState.auth.currentAppId
  );
  const isCurrentAppAuthorised = useSelector(
    (globalState) => globalState.auth.isCurrentAppAuthorized
  );
  // const [selectedSegmentId, setSelectedSegmentId] = useState("add");
  const [addOrUpdateModal, setAddOrUpdateModal] = useState(false)
  const [addStatus, setAddStatus] = useState(false);
  const [isItemSelected, setIsItemSelected] = useState(false)
  const [isCheckingAccess, setIsCheckingAccess] = useState(true);
  const [isAuthorized, setIsAuthorized] = useState();
  const dispatch = useDispatch();
  const gridRef = useRef();
  const [dsInfo, setDsInfo] = useState([]);
  const [showLoadingIcon, setShowLoadingIcon] = useState(false);
  const [showUpdatingIcon, setShowUpdatingIcon] = useState(false);
  const [updateStatus, setUpdateStatus] = useState("");
  const [toolsHide, setToolsHide] = useState(true);

  const [selectedTeamName, setSelectedTeamName] = useState({});
  const [isAWS, setIsAWS] = useState(false);

  const [selectedItem, setSelectedItem] = useState([]);
  const [boardItems, setBoardItems] = useState([
    { id: "1", rowSpan: 5, columnSpan: 6,data:{}}
  ]);

  const [vendorNumber, setVendorNumber] = useState()
  const [vendorName, setVendorName] = useState()
  const [assetBook, setAssetBook] = useState()
  const [majorCat, setMajorCat] = useState()
  const [minorCat, setMinorCat] = useState()
  const [ledgerName, setLedgerName] = useState()
  const [slBook, setSlBook] = useState()
  const [ranking, setRanking] = useState()
  const [selectedAssetBook, setSelectedAssetBook] = useState({ label: "", value: ""})
  const [selectedMajorCat, setSelectedMajorCat] = useState({ label: "", value: ""})
  const [selectedMinorCat, setSelectedMinorCat] = useState({ label: "", value: ""})

  const [UELDs, setUELDs] = useState([])
  const [booksDS, setBooksDS] = useState([])
  const [majorCatsDropdown, setMajorCatsDropdown] = useState([])
  const [minorCatsDropdown, setMinorCatsDropdown] = useState([])
  const [booksDropdown, setBooksDropdown] = useState([])

  useEffect(() => {
    if (currentAppId !== appSettings.appId) {
      dispatch(setCurrentAppId(appSettings.appId));
    }
    setIsAuthorized(isCurrentAppAuthorised);
    if (isCurrentAppAuthorised) {
      LoadData();
    }
    return () => {
      // this now gets called when the component unmounts
    };
  }, [isCurrentAppAuthorised]);

  useEffect(() => {
    if(isAuthorized === true || isAuthorized === false){
      setIsCheckingAccess(!isCheckingAccess);
    } 
  }, [isAuthorized]);

  const breadItems = [
    { text: "Home", href: "/Home" },
    { text: "ATeam BI", href: "/ATeamBIHome" },
    { text: "Boffin", href: "/BoffinHome" },
    { text: "Supplier Master List Override", href: "/" },
  ];

  const onFollowHandler = (ev) => {
    ev.preventDefault();
    if (ev.detail.href) {
      history.push(ev.detail.href.substring(1));
    }
  };

  useEffect(() => {

    const book_type_codes = [... new Set(booksDS.map((row) => row.BOOK_TYPE_CODE))];
    const maj_cats = [... new Set(UELDs.map((row) => row.majorcategory))];
    const min_cats = [... new Set(UELDs.map((row) => row.minorcategory))];

    console.log(UELDs)

    setBooksDropdown (book_type_codes.map((item) => (
      { label: item, value: item}
    )))

    setMajorCatsDropdown (maj_cats.map((item) => (
      { label: item, value: item}
    )))

    setMinorCatsDropdown (min_cats.map((item) => (
      { label: item, value: item}
    )))


  },[UELDs, booksDS])

  const LoadData = async () => {
    setShowLoadingIcon(true);
    setIsItemSelected(false);
    // console.log('Need to execute sql here..')

    let folder = "Boffin/datasets/";
    let fn = folder + "Category_EUL_Mapping.csv";
    const fileURL = await Storage.get(fn, { level: "public", expires: 60 });
    let csvdata = await d3.csv(fileURL).then((result) => {
      setUELDs(result);
      // setState here..
    });

    let mSQL1 = "SELECT * FROM boffin.mapping_book_type_ledger;";
    let body1 =
      `{
          "processName" : "MySQL",
          "scriptName"  : "executeSQLonMySQL",
          "parameters"  : {"sql":"` +
      mSQL1 +
      `"}
        }`;


    let mSQL =
      "SELECT * FROM boffin.supplier_master_list_override;";
    let body =
      `{
          "processName" : "MySQL",
          "scriptName"  : "executeSQLonMySQL",
          "parameters"  : {"sql":"` +
      mSQL +
      `"}
        }`;

    // console.log(body)
    const ApiGateWayEndpoint = Amplify.Cache.config.ApiGateWayEndpoint;
    let url = ApiGateWayEndpoint + "/executeOnDemand";

    axios
      .post(url, body1, {
        headers: {
          Authorization: (await Auth.currentSession())
            .getIdToken()
            .getJwtToken(),
        },
      })
      .then((response) => {
        console.log(response.data.data)
        setBooksDS(response.data.data);
      });

    axios
      .post(url, body, {
        headers: {
          Authorization: (await Auth.currentSession())
            .getIdToken()
            .getJwtToken(),
        },
      })
      .then((response) => {
        console.log(response.data.data)
        setDsInfo(response.data.data);
        setShowLoadingIcon(false);
      });



  };

  // const addRecord = async () => {

   
  //   if (!(vendorNumber && vendorName && assetBook && 
  //     majorCat && minorCat && ledgerName && slBook && ranking)) {
  //     setAddOrUpdateModal(true)
  //     return;
  //   }
  //   setShowUpdatingIcon(true)
  //   console.log("In add record!");

  //   let mSQL =
  //     "INSERT into boffin.supplier_master_list_override (vendor_number, vendor_name, asset_book, major_category, minor_category, LEDGER_NAME, sl_book, ranking) VALUES ('" +
  //     vendorNumber +
  //     "', '" +
  //     vendorName +
  //     "', '" +
  //     assetBook +
  //     "', '" +
  //     majorCat +
  //     "', '" +
  //     minorCat +
  //     "', '" +
  //     ledgerName +
  //     "', '" +
  //     slBook +
  //     "', '" +
  //     ranking +
  //     "');";

  //   let body =
  //     `{
  //         "processName" : "MySQL",
  //         "scriptName"  : "executeSQLonMySQL",
  //         "parameters"  : {"sql":"` +
  //     mSQL +
  //     `"}
  //       }`;

  //   // console.log(body)
  //   const ApiGateWayEndpoint = Amplify.Cache.config.ApiGateWayEndpoint;
  //   let url = ApiGateWayEndpoint + "/executeOnDemand";
  //   axios
  //     .post(url, body, {
  //       headers: {
  //         Authorization: (await Auth.currentSession())
  //           .getIdToken()
  //           .getJwtToken(),
  //       },
  //     })
  //     .then((response) => {
      
  //         setShowUpdatingIcon(false);
  //         setAddStatus(true);
  //         LoadData();
  //         setVendorNumber()
  //         setVendorName()
  //         setAssetBook()
  //         setMajorCat()
  //         setMinorCat()
  //         setLedgerName()
  //         setSlBook()
  //         setRanking()
  //     });

  // };

  const updateRecord = async () => {
    if (!(vendorNumber && vendorName && assetBook && 
      majorCat && minorCat && ledgerName)) {
      setAddOrUpdateModal(true)
      return;
    }
    setShowUpdatingIcon(true)
    let mSQL =
      "UPDATE boffin.supplier_master_list_override SET asset_book = '" +
      assetBook +
      "', major_category = '" +
      majorCat +
      "', minor_category = '" +
      minorCat +
      "' WHERE vendor_number ='" +
      vendorNumber +
      "' AND vendor_name ='" +
      vendorName +
      "' AND LEDGER_NAME ='" +
      ledgerName +
      "';";

    let body =
      `{
          "processName" : "MySQL",
          "scriptName"  : "executeSQLonMySQL",
          "parameters"  : {"sql":"` +
      mSQL +
      `"}
        }`;

    // console.log(body)
    const ApiGateWayEndpoint = Amplify.Cache.config.ApiGateWayEndpoint;
    let url = ApiGateWayEndpoint + "/executeOnDemand";
    axios
      .post(url, body, {
        headers: {
          Authorization: (await Auth.currentSession())
            .getIdToken()
            .getJwtToken(),
        },
      })
      .then((response) => {
        //  console.log(response.data.records)
          setShowUpdatingIcon(false);
          setUpdateStatus(true);
          LoadData();
          setVendorNumber()
          setVendorName()
          setAssetBook()
          setMajorCat()
          setMinorCat()
          setLedgerName()
          setSelectedAssetBook({ label: "", value: ""})
          setSelectedMajorCat({ label: "", value: ""})
          setSelectedMinorCat({ label: "", value: ""})

      });
  };

  const defaultColDef = {
    // set filtering on for all columns
    filter: true,
    filterParams: {
      buttons: ["reset"],
      debounceMs: 200,
    },
  };

  const columnDefs = [
    {
      field: "vendor_number",
      width: "150px",
      resizable: true,
      sortable: true,
      headerName: "Vendor Number",
    },
    {
      field: "vendor_name",
      width: "250px",
      sortable: true,
      resizable: true,
      headerName: "Vendor Name",
    },
    {
      field: "LEDGER_NAME",
      width: "250px",
      sortable: true,
      resizable: true,
      headerName: "Ledger Name",
    },
    {
      field: "asset_book",
      width: "250px",
      sortable: true,
      resizable: true,
      headerName: "Asset Book",
    },
    {
      field: "major_category",
      width: "250px",
      sortable: true,
      resizable: true,
      headerName: "Major Category",
    },
    {
        field: "minor_category",
        width: "250px",
        sortable: true,
        resizable: true,
        headerName: "Minor Category",
    },
    
  ];
  const onSelectionChanged= () =>{
    const selectedRows = gridRef.current.api.getSelectedRows();
    if(selectedRows.length > 0) {

      // setSelectedSegmentId("edit")
      setIsItemSelected(true)
  
      setVendorNumber(selectedRows[0].vendor_number)
      setVendorName(selectedRows[0].vendor_name)
      setAssetBook(selectedRows[0].asset_book)
      setMajorCat(selectedRows[0].major_category)
      setMinorCat(selectedRows[0].minor_category)
      setLedgerName(selectedRows[0].LEDGER_NAME)

      setSelectedAssetBook({ label: selectedRows[0].asset_book, value: selectedRows[0].asset_book})
      setSelectedMajorCat({ label: selectedRows[0].major_category, value: selectedRows[0].major_category})
      setSelectedMinorCat({ label: selectedRows[0].minor_category, value: selectedRows[0].minor_category})
    } else {

      setIsItemSelected(false)
      setVendorNumber()
      setVendorName()
      setAssetBook()
      setMajorCat()
      setMinorCat()
      setLedgerName()

      setSelectedAssetBook({ label: "", value: ""})
      setSelectedMajorCat({ label: "", value: ""})
      setSelectedMinorCat({ label: "", value: ""})

    }
  }

  // const downloadFileClick = () => {

  //   if(dsInfo.length >= 0) {
  //     let csv = '';

  //     for (let col of columnDefs) {
  //       csv += col.headerName + ','
  //     }
  //     csv += '\n'

  //     for(let row of dsInfo) {
  //       csv += `"${row.Company_code}"` + ','
  //       csv += `"${row.Description}"` + ','
  //       csv += `"${row.End_Date}"` + ','
  //       csv += `"${row.Enabled}"` + ','
  //       csv += `"${row.Parent}"` + ','
  //       csv += `"${row.Ledger_ID}"` + ','
  //       csv += `"${row.Ledger_Name}"` + ','
  //       csv += `"${row.Currency}"` + ','
  //       csv += `"${row.team_name}"` + ','
  //       csv += `"${row.isAWS}"` + ','
  //       csv += '\n'
  //     }
    
  //   let link = document.createElement('a')
  //   link.id = 'download-csv'
  //   link.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(csv));
  //   link.setAttribute('download', 'TeamNameMapping.csv');
  //   document.body.appendChild(link)
  //   document.querySelector('#download-csv').click()

  //   }

  // }

  const helpContent = (
    <HelpPanel
    header={
      <div>
        <h2>Edit Existing Record</h2>
      </div>
    }
  >
    <Container>
      {/* <Box>
        <SegmentedControl
          selectedId={selectedSegmentId}
          onChange={({ detail }) =>{ 
            setSelectedSegmentId(detail.selectedId)
            if(detail.selectedId === 'add') {
              gridRef.current.api.deselectAll()
            }
          }}
          options={[
            { text: "Add New Record", id: "add", iconName: "add-plus" },
            { text: "Edit Existing Record", id: "edit", iconName: "edit" },
          ]}
        />
      </Box> */}
      
        <>
        {(isItemSelected) ? <>
          <Box>
          <ColumnLayout columns={2}>
            <FormField label="Vendor Number">
              <Input
                type="number"
                value={vendorNumber}
                onChange={({ detail }) => setVendorNumber(detail.value)}
                disabled
              ></Input>
            </FormField>
            <FormField label="Vendor Name">
              <Input
                type="text"
                value={vendorName}
                onChange={({ detail }) => setVendorName(detail.value)}
                ariaRequired
                disabled
              ></Input>
            </FormField>
            <FormField label="Asset Book">
              {/* <Input
                type="text"
                value={assetBook}
                onChange={({ detail }) => setAssetBook(detail.value)}
              ></Input> */}
              
              <Select
              selectedOption={selectedAssetBook}
              options={booksDropdown}
              onChange={({ detail }) => {
                setSelectedAssetBook(detail.selectedOption);
                setAssetBook(detail.selectedOption.value)
              }}  
              virtualScroll          
            ></Select>
            </FormField>
            <FormField label="Major Category">
              {/* <Input
                type="text"
                value={majorCat}
                onChange={({ detail }) => setMajorCat(detail.value)}
              ></Input> */}
              <Select
              selectedOption={selectedMajorCat}
              options={majorCatsDropdown}
              onChange={({ detail }) => {
                setSelectedMajorCat(detail.selectedOption);
                setMajorCat(detail.selectedOption.value)
              }}      
              virtualScroll      
            ></Select>
            </FormField>
            <FormField label="Minor Category">
              {/* <Input
                type="text"
                value={minorCat}
                onChange={({ detail }) => setMinorCat(detail.value)}
              ></Input> */}
              <Select
              selectedOption={selectedMinorCat}
              options={minorCatsDropdown}
              onChange={({ detail }) => {
                setSelectedMinorCat(detail.selectedOption);
                setMinorCat(detail.selectedOption.value)
              }}
              virtualScroll            
            ></Select>
            </FormField>
            <FormField label="Ledger Name">
              <Input
                type="text"
                value={ledgerName}
                onChange={({ detail }) => setLedgerName(detail.value)}
                disabled
              ></Input>
            </FormField>
            {/* <FormField label="SL Book">
              <Input
                type="text"
                value={slBook}
                onChange={({ detail }) => setSlBook(detail.value)}
              ></Input>
            </FormField>
            <FormField label="Ranking">
              <Input
                type="number"
                value={ranking}
                onChange={({ detail }) => setRanking(detail.value)}
              ></Input>
            </FormField> */}
          </ColumnLayout>
        </Box>
        <br />
        <Button variant='primary' iconName= "edit" onClick={() => updateRecord()}>Update</Button>
        <Box>
        {showUpdatingIcon ? (
            <Box>
              {" "}
              <Spinner></Spinner> Updating...{" "}
            </Box>
          ) : (
            <Box>
              
            </Box>
          )}
        </Box>
        </>
        :
      <Box variant="awsui-key-label">Select a row to edit and update.</Box>
      }
        </>
    </Container>
  </HelpPanel>
  );

  const content = (
    <div>
      {isAuthorized ? (
        <Box>
          {showLoadingIcon ? (
            <Box>
              <Spinner></Spinner>
              <Box>Loading...</Box>
              <Box>Please wait, takes upto a minute...</Box>
            </Box>
          ) : (
            <Box>
              <Container
                header={
                  <Header
                    actions={
                      <SpaceBetween direction="horizontal" size="xs">
                        {/* <Button
                          iconName="download"
                          onClick={() => downloadFileClick()}
                        >
                          Download Team Name Mapping
                        </Button> */}
                        <Button
                          variant="normal"
                          iconName="refresh"
                          onClick={() => LoadData()}
                        >
                          Refresh
                        </Button>
                      </SpaceBetween>
                    }
                  >
                    Supplier Master List Override
                  </Header>
                }
              >
                <Board
                  renderItem={(item) => (
                    <BoardItem i18nStrings={boardItemI18nStrings}>
                      <div
                        className="ag-theme-alpine"
                        style={{ height: "92%", width: "100%" }}
                      >
                        <AgGridReact
                          ref={gridRef}
                          rowData={dsInfo}
                          defaultColDef={defaultColDef}
                          columnDefs={columnDefs}
                          rowSelection="single"
                          animateRows={true}
                          rowHeight={30}
                          onSelectionChanged={onSelectionChanged}
                          enableCellTextSelection={true}
                          rowMultiSelectWithClick={true}
                        ></AgGridReact>
                      </div>
                    </BoardItem>
                  )}
                  onItemsChange={(event) => setBoardItems(event.detail.items)}
                  items={boardItems}
                  i18nStrings={boardI18nStrings}
                />
              </Container>
            </Box>
          )}
           <Modal
            visible={addOrUpdateModal}
            header={<div>Error</div>}
            onDismiss={() => {
              setAddOrUpdateModal(false)
            }}
            >
              All Fields are required
          </Modal>
          <Modal
            visible={addStatus}
            header={<div>Info</div>}
            onDismiss={() => {
              setAddStatus(false)
            }}
            >
            <Icon variant="success" name="status-positive"></Icon>
            Record Added!
          </Modal>
          <Modal
            visible={updateStatus}
            header={<div>Info</div>}
            onDismiss={() => {
              setUpdateStatus(false)
            }}
            >
            <Icon variant="success" name="status-positive"></Icon>
            Record Updated!
          </Modal>
        </Box>
      ) : isCheckingAccess ? (
        <div>
          <WaitForPageAccess></WaitForPageAccess>
        </div>
      ) : (
        <div>
          <AccessDenied appName={appSettings.appName}></AccessDenied>
        </div>
      )}
    </div>
  );

  return (
    <AppLayout
      breadcrumbs={
        <BreadcrumbGroup
          items={breadItems}
          onFollow={(event) => onFollowHandler(event)}
        ></BreadcrumbGroup>
      }
      content={content}
      navigation={<SideNav activeHref="/BoffinSupplierMasterListOverride" />}
      headerSelector="#TopBar"
      navigationHide={false}
      toolsWidth={550}
      tools={isAuthorized ? helpContent : ''}
      toolsOpen={toolsHide}
      maxContentWidth = {Number.MAX_VALUE}
      onToolsChange={()=>{setToolsHide(!toolsHide)}}
      headerVariant="high-contrast"
    ></AppLayout>
  );
};

export default BoffinSupplierMasterListOverride;
