import React from 'react';
import { BrowserRouter, HashRouter, Route, Switch, useRouteMatch } from 'react-router-dom'
import Home from './Main/Home';
import TopNav from './TopNav';
import ATeamBIAllProducts from './Home/AllApps'
import GeneralTools from './general-tools/Home';
import Favorites from './Favorites';
import BoffinHome from './Ateam-BI/Boffin/Home'
import BoffinInputs from './Ateam-BI/Boffin/Inputs'

import BoffinCompute from './Ateam-BI/Boffin/Compute';

import BoffinViewAllItems from './Ateam-BI/Boffin//ViewAlltems'
import BoffinEditItem from './Ateam-BI/Boffin/EditItem'
import BoffinInputsHistory from './Ateam-BI/Boffin/InputsHistory'

import BoffinDashboards from './Ateam-BI/Boffin/Dashboards';
import BoffinNewOverride from './Ateam-BI/Boffin/NewOverride';
import BoffinRulesAndWarnings from './Ateam-BI/Boffin/RulesWarnings';
import BoffinRulesWarningsNewEdit from './Ateam-BI/Boffin/RulesWarningsNewEdit';
import BoffinTeamNameMapping from './Ateam-BI/Boffin/TeamNameMapping';
import BoffinCurrentPeriod from './Ateam-BI/Boffin/CurrentPeriod';
import BoffinSupplierMasterList from './Ateam-BI/Boffin/SupplierMasterList';
import BoffinWarningRules from './Ateam-BI/Boffin/WarningRules';
import BoffinCipTracker from './Ateam-BI/Boffin/CIPTracker';
import BoffinBuildingTracker from './Ateam-BI/Boffin/BuildingTracker';
import BoffinPostingSummary from './Ateam-BI/Boffin/PostingSummary';
import BoffinCategoryUELMapping from './Ateam-BI/Boffin/CategoryUELMapping'
import BoffinMappingBookToLedger from './Ateam-BI/Boffin/MappingBookToLedger'
import BoffinPrimaryLedgerToSecondaryLedgerMapping from './Ateam-BI/Boffin/PrimaryLedgerToSecondaryLedger'
import BoffinWarningRulesOverride from './Ateam-BI/Boffin/WarningRulesOverride'
import BoffinSupplierMasterListOverride from './Ateam-BI/Boffin/SupplierMasterListOverride'
import BoffinUnlockCompute from './Ateam-BI/Boffin/UnlockCompute';
// import BoffinMissingVendors from './Ateam-BI/Boffin/MissingVendors'

import TedHome from './Ateam-BI/Ted/Home';
import TriggerEventLogs from './Ateam-BI/Ted/TriggerEventLogs';
import TriggerHistory from './Ateam-BI/Ted/TriggerHistory';
import EmailSubscription from './Ateam-BI/Ted/EmailSubscription';
import EntityRegionMapping from './Ateam-BI/Ted/EntityRegionMapping';
import RegionTriggerMapping from './Ateam-BI/Ted/RegionTriggerMapping';
import MJEMonitoringHome from './Ateam-BI/MJEMonitoring/Home';
import MJEMonitoringNewUpload from './Ateam-BI/MJEMonitoring/NewUpload'
import MJEMonitoringHistory from './Ateam-BI/MJEMonitoring/History'
import AppTemplate from './Ateam-BI/TemplatedApp/AppTemplate';
import TemplateMaple from './Ateam-BI/Templates/TemplateMaple';
import AllTemplates from './Ateam-BI/TemplatedApp/AllTemplates';
import AdminPanel from './Ateam-BI/Admin/AdminPanel';
import FriscoWorkflow1 from './Ateam-BI/Frisco/FriscoWorkflow1';
import FriscoMainPage from './Ateam-BI/Frisco/FriscoMainPage';
import FriscoUploadPage from './Ateam-BI/Frisco/FriscoUploadPage';
import AddOrEditProcess from './Ateam-BI/Frisco/AddOrEditProcess';
import FriscoHistory from './Ateam-BI/Frisco/FriscoHistory';

import ApprovalsHome from './Approvals/approvalsHome';
import WorkflowInstance from './Approvals/workflowInstance';
import CorpReportingHome from './Ateam-BI/CorpReporting/Home';
import GroupPage from './Ateam-BI/CorpReporting/GroupPage';
import DeliverablesPage from './Ateam-BI/CorpReporting/DeliverablesPage';
import ModulesPage from './Ateam-BI/CorpReporting/ModulesPage';
import AdminPortal from './Ateam-BI/CorpReporting/AdminPortal';
import AppAuditLogging from './Ateam-BI/AuditLogging/AppAuditLogging';
import FriscoAuditLogging from './Ateam-BI/AuditLogging/FriscoAuditLogging';
import AllAuditLogs from './Ateam-BI/AuditLogging/AllAuditLogs';

import AddOrEditLogicoProcess from './Ateam-BI/Logico/AddOrEditLogicoProcess';
import LogicoHistory from './Ateam-BI/Logico/LogicoHistory';
import LogicoUploadPage from './Ateam-BI/Logico/LogicoUploadPage';
import AddOrEditTemplate from './Ateam-BI/FlexiCo/AddOrEditTemplate';
import FlexicoUploadPage from './Ateam-BI/FlexiCo/FlexicoUploadPage';
import FlexicoHistory from './Ateam-BI/FlexiCo/FlexicoHistory';
import FlexicoAuditLogging from './Ateam-BI/FlexiCo/FlexicoAuditLogging';





export const AppRouter: React.FC = () => {


    return (

        <>
            <TopNav />
            <Switch>
                <Route exact path="/" component={Home} />
                <Route exact path="/Home" component={Home} />
                <Route exact path="/Favorites" component={Favorites} />
                <Route exact path="/ATeamBIAllProducts" component={ATeamBIAllProducts} />
                <Route exact path="/ATeamBIHome" component={ATeamBIAllProducts} />
                <Route exact path="/BoffinHome" component={BoffinHome} />
                <Route exact path="/BoffinInputs" component={BoffinInputs} />
                <Route exact path="/BoffinCompute" component={BoffinCompute} />
                <Route exact path="/BoffinViewAllItems" component={BoffinViewAllItems} />
                <Route exact path="/BoffinInputsHistory" component={BoffinInputsHistory} />
                <Route exact path="/BoffinEditItem" component={BoffinEditItem} />
                <Route exact path="/BoffinDashboards" component={BoffinDashboards} />
                <Route exact path="/BoffinNewOverride" component={BoffinNewOverride} />
                <Route exact path="/BoffinRulesAndWarnings" component={BoffinRulesAndWarnings} />
                <Route exact path="/BoffinRulesWarningsNewEdit" component={BoffinRulesWarningsNewEdit} />
                <Route exact path="/BoffinTeamNameMapping" component={BoffinTeamNameMapping} />
                <Route exact path="/BoffinCurrentPeriod" component={BoffinCurrentPeriod} />
                <Route exact path="/BoffinCipTracker" component={BoffinCipTracker} />
                <Route exact path="/BoffinBuildingTracker" component={BoffinBuildingTracker} />
                <Route exact path="/BoffinSupplierMasterList" component={BoffinSupplierMasterList} />
                <Route exact path="/BoffinWarningRules" component={BoffinWarningRules} />
                <Route exact path="/BoffinPostingSummary" component={BoffinPostingSummary} />
                <Route exact path="/Boffin" component={BoffinHome} />
                <Route exact path="/BoffinCategoryUELMapping" component={BoffinCategoryUELMapping} />
                <Route exact path="/BoffinMappingBookToLedger" component={BoffinMappingBookToLedger} />
                <Route exact path="/BoffinPrimaryLedgerToSecondaryLedgerMapping" component={BoffinPrimaryLedgerToSecondaryLedgerMapping} />
                <Route exact path="/BoffinWarningRulesOverride" component={BoffinWarningRulesOverride} />
                <Route exact path="/BoffinSupplierMasterListOverride" component={BoffinSupplierMasterListOverride} />
                <Route exact path="/BoffinUnlockCompute" component={BoffinUnlockCompute} />


                <Route exact path="/TEDHome" component={TedHome} />
                <Route exact path="/TriggerEventLogs" component={TriggerEventLogs} />
                <Route exact path="/TriggerHistory" component={TriggerHistory} />
                <Route exact path="/EmailSubscription" component={EmailSubscription} />
                <Route exact path="/EntityRegionMapping" component={EntityRegionMapping} />
                <Route exact path="/RegionTriggerMapping" component={RegionTriggerMapping} /> 
                <Route exact path="/MJEMonitoringHome" component={MJEMonitoringHome} /> 
                <Route exact path="/MJEMonitoringNewUpload" component={MJEMonitoringNewUpload} />
                <Route exact path="/MJEMonitoringHistory" component={MJEMonitoringHistory} />
                <Route exact path= "/App/:id" component={AppTemplate} /> 
                <Route exact path= "/App" component={AllTemplates} />
                <Route exact path= "/Admin" component={AdminPanel} />
                <Route exact path= "/frisco" component={FriscoMainPage} />
                <Route exact path= "/frisco/workflow1" component={FriscoWorkflow1} />
                <Route exact path= "/frisco/upload" component={FriscoUploadPage} />
                <Route exact path= "/App/:id/process" component={AddOrEditProcess} />
                <Route exact path= "/App/:id/AppAuditLogs" component={AppAuditLogging} />
                <Route exact path= "/App/:id/process/:pid" component={AddOrEditProcess} />
                <Route exact path= "/App/:id/process/:pid/FriscoProcessAuditLogs" component={FriscoAuditLogging} />
                <Route exact path= "/AllAuditLogs" component={AllAuditLogs} />
                <Route exact path= "/App/:id/process/:pid/upload" component={FriscoUploadPage} />
                <Route exact path= "/App/:id/process/:pid/history" component={FriscoHistory} />
                <Route exact path= "/CorpReportingHome" component={CorpReportingHome} />
                <Route exact path= "/CorpReportingHome/modules" component={ModulesPage} />
                <Route exact path= "/CorpReportingHome/deliverables" component={DeliverablesPage} />
                <Route exact path= "/CorpReportingHome/modules/:group" component={GroupPage} />
                <Route exact path= "/CorpReportingHome/admin" component={AdminPortal} />


                // will be removed soon
                <Route exact path= "/AppTemplate/:id" component={AppTemplate} /> 
                <Route exact path= "/AppTemplate" component={AppTemplate} /> 
                <Route exact path="/GeneralTools/:id" component={GeneralTools} />

                <Route exact path="/ApprovalsHome" component={ApprovalsHome} />
                <Route exact path="/ApprovalsHome/App/:id" component={ApprovalsHome} />
                <Route exact path="/ApprovalsHome/Workflow/:workflowInstanceId" component={WorkflowInstance} />

                //Logico
                <Route exact path= "/logico/upload" component={LogicoUploadPage} />
                <Route exact path= "/App/:id/LogicoProcess/:lpid/upload" component={LogicoUploadPage} />
                <Route exact path= "/App/:id/LogicoProcess" component={AddOrEditLogicoProcess} />
                <Route exact path= "/App/:id/LogicoProcess/:lpid" component={AddOrEditLogicoProcess} />
                <Route exact path= "/App/:id/LogicoProcess/:lpid/history" component={LogicoHistory} />

                //FlexiCo
                <Route exact path= "/App/:id/flexico" component={AddOrEditTemplate} />
                <Route exact path= "/App/:id/flexico/:fid" component={AddOrEditTemplate} />
                <Route exact path= "/App/:id/flexico/:fid/FlexicoProcessAuditLogs" component={FlexicoAuditLogging} />
                <Route exact path= "/App/:id/flexico/:fid/upload" component={FlexicoUploadPage} />
                <Route exact path= "/App/:id/flexico/:fid/history" component={FlexicoHistory} />

            </Switch>
        </>



    );

}
