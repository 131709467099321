import React, { useState, useEffect } from "react";
import {
    Box,
    Input,
    Button,
    Select,
    SpaceBetween
} from "@amzn/awsui-components-react";
import './style.scss'

const CustomMultiHybridInputsWithPK = (props) => {
    var [rows, setRows] = useState([]);
    useEffect(() => {
        loadRows()
        return () => {
        };
    }, []);

    useEffect(() => {
        props.updateValue(props.field, getMergedInputValues())
    }, [rows])

    const getMergedInputValues = () => {
        var filtered = rows.filter(function (row) {
            return row != null;
        });
        return filtered
    }

    const onEdit = (value, index, key) => {
        rows[index][key] = value
        setRows([...rows])
    }

    const getInputItem = (value, index) => {
        return <div className='rows' key={index}>
            <SpaceBetween direction="horizontal" size="xs">

        <Box>

            {props.subKeys ? (Array.from(props.subKeys).map((subKeyItem, subKeyItemIndex) => {
                return <Input
                onChange={({ detail }) => onEdit(detail.value, index, subKeyItem.subKey)}
                value={value.hasOwnProperty(subKeyItem.subKey) ?value[subKeyItem.subKey] : ''}
                placeholder={subKeyItem.placeholder}
            />;
            })) : ''}
        </Box>
            <div className="row"><Button iconName="close" variant="icon" onClick={() => {
                deleteRow(index)
            }} /></div>
    </SpaceBetween>
            
        </div>
    }
    const deleteRow = (index) => {
        rows.splice(index, 1)
        setRows([...rows])
    }
    const addRow = () => {
        var newRow = {}
        props.subKeys.forEach((subKeyItem) => {
            newRow[subKeyItem.subKey] = ''
        })
        setRows([...rows,newRow ])
    }
    const getAddButton = () => {
        return <div> <Button onClick={addRow}>Add</Button> </div>
    }

    const loadRows = () => {
        let values = props.getValues(props.field)
        if(values == null){
            return
        }
        rows=[];
        values.forEach(element => {
            rows.push(element)
        });
        setRows(JSON.parse(JSON.stringify(values)))
    }

    return (
        <Box>

            {rows ? (Array.from(rows).map((value, index) => {
                return getInputItem(value, index);
            })) : ''}

            {
                getAddButton()
            }
        </Box>
    )
}

export default CustomMultiHybridInputsWithPK;