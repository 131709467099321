import { API, graphqlOperation } from 'aws-amplify';

/* 
Sample details object to pass to friscoStartWorkflow api (all are mandatory)
const details = {
    createdBy: "subbui",
    name:"Test Title",
    description:"Test description",
    stage:"beta",
    approverList: '[{type: "USER", identifier: "ravsbhar"}, {type: "GROUP", identifier: "ateam-bi(ldapGroup)"}]',
    entity_id: "qddwedwed-id-dcbscbsjc-sjjscjnjsdc",
    tags: '[{key: "appid", value:"123"}]'

  }
 */ 
export const friscoStartWorkflow = async ({createdBy, name, description, stage, entity_id, approverList, tags}) => {

    stage = (stage === 'dev' ? 'beta' : stage)
    let mQry = `mutation MyMutation {
        friscoStartWorkflow(createdBy: "${createdBy}", description: "${description}", entity_id: "${entity_id}", name: "${name}", stage: "${stage}", approverList: ${approverList}, tags: ${tags})
      }`
    let response = await API.graphql(graphqlOperation(mQry));
    let friscoStartWorkflowResponse = response.data.friscoStartWorkflow;
    return JSON.parse(friscoStartWorkflowResponse)
}

/* 
Sample details object to pass to friscoStartWorkflow2PR api (all are mandatory except for approveResponsesNeeded)
const details = {
    createdBy: "subbui",
    name:"Test Title",
    description:"Test description",
    stage:"beta",
    approverList: '[{type: "USER", identifier: "ravsbhar"}, {type: "GROUP", identifier: "ateam-bi(ldapGroup)"}]',
    entity_id: "qddwedwed-id-dcbscbsjc-sjjscjnjsdc",
    tags: '[{key: "appid", value:"123"}]',
    approveResponsesNeeded: '2'
  }
 */ 
  export const friscoStartWorkflow2PR = async ({createdBy, name, description, stage, entity_id, approverList, tags, approveResponsesNeeded}) => {

    stage = (stage === 'dev' ? 'beta' : stage)
    let mQry;
    if(typeof(approveResponsesNeeded) === 'undefined') {
      mQry = `mutation MyMutation {
        friscoStartWorkflow2PR(createdBy: "${createdBy}", description: "${description}", entity_id: "${entity_id}", name: "${name}", stage: "${stage}", approverList: ${approverList}, tags: ${tags})
      }`
    } else {
      mQry = `mutation MyMutation {
        friscoStartWorkflow2PR(createdBy: "${createdBy}", description: "${description}", entity_id: "${entity_id}", name: "${name}", stage: "${stage}", approverList: ${approverList}, tags: ${tags}, approve_responses_needed: "${approveResponsesNeeded}")
      }`
    }

    
    let response = await API.graphql(graphqlOperation(mQry));
    let friscoStartWorkflow2PRResponse = response.data.friscoStartWorkflow2PR;
    return JSON.parse(friscoStartWorkflow2PRResponse)
}


/* 
Sample details object to pass to startWASInorderApproval api (all are mandatory )
const details = {
    createdBy: "subbui",
    name:"Test Title",
    description:"Test description",
    stage:"beta",
    approversListInorder: '[{actors:[{type: "USER", identifier: "subbui"}, {type: "GROUP", identifier: "internaltest(ldapGroup)"}], responses_needed: "1"}]',
    entity_id: "qddwedwed-id-dcbscbsjc-sjjscjnjsdc",
    tags: '[{key: "appid", value:"123"}]'
  }
 */ 
  export const startWASInorderApproval  = async ({createdBy, name, description, stage, entity_id, approversListInorder, tags}) => {

    stage = (stage === 'dev' ? 'beta' : stage)
    let mQry;
    
      mQry = `mutation MyMutation {
        startWASInorderApproval(createdBy: "${createdBy}", description: "${description}", entity_id: "${entity_id}", name: "${name}", stage: "${stage}", approversListInorder: ${approversListInorder}, tags: ${tags})
      }`
    
    let response = await API.graphql(graphqlOperation(mQry));
    let startWASInorderApprovalResponse = response.data.startWASInorderApproval;
    return JSON.parse(startWASInorderApprovalResponse)
}
/*
Sample details object to pass to getRequestedWasWorkflows api (tags is optional)
const details = {
    ownerAlias: "subbui",
    stage:"beta",
    workflowStatus: "completed",      // Possible values: pending, rejected, canceled, completed
    tags: '[{key: "appid", value:"123"}]'
  }
*/

export const getRequestedWasWorkflows = async ({ownerAlias, stage, workflowStatus, tags, pageSize, pageNo}) => {

    stage = stage === "dev" ? "beta" : stage;
    let mQry;
    if(typeof(tags) === 'undefined') {
        mQry = `query MyQuery {
            getRequestedWorkflows(ownerAlias: "${ownerAlias}", stage: "${stage}", workflowStatus: "${workflowStatus}" ${getPaginationInput(pageSize, pageNo)})
          }
          `
    } else {
        mQry = `query MyQuery {
            getRequestedWorkflows(ownerAlias: "${ownerAlias}", stage: "${stage}", workflowStatus: "${workflowStatus}", tags: ${tags} ${getPaginationInput(pageSize, pageNo)})
          }
          `
    }
    let response = await API.graphql(graphqlOperation(mQry));
    let getRequestedWasWorkflowsResponse = response.data.getRequestedWorkflows;
    return JSON.parse(getRequestedWasWorkflowsResponse)

}


/*
Sample details object to pass to getApproverWasWorkflows api (tags is optional)
const details = {
    userAliasAllStates: "subbui",
    stage:"beta",
    userResponseStatus: "PENDING",      // Possible values: PENDING, COMPLETE
    tags: '[{key: "appid", value:"123"}]',
    workflowStatus: "pending"  //(optional)
  }
*/

const getPaginationInput = (pageSize, pageNo) => {
  let returnVal = ''
  if(pageSize){
    returnVal = returnVal + `, pageSize: ${pageSize}`
  }
  if(pageNo){
    returnVal = returnVal + `, pageNo: ${pageNo}`
  }
  return returnVal
}

export const getApproverWasWorkflows = async ({userAliasAllStates, stage, userResponseStatus, tags, workflowStatus, pageSize, pageNo}) => {

    stage = stage === "dev" ? "beta" : stage;
    let mQry;
    if(typeof(tags) === 'undefined') {
        if(typeof(workflowStatus) === 'undefined') {
            mQry = `query MyQuery {
                getApproverWorkflows(stage: "${stage}", userAliasAllStates: "${userAliasAllStates}", userResponseStatus: "${userResponseStatus}" ${getPaginationInput(pageSize, pageNo)})
              }          
              `
        } else {
            mQry = `query MyQuery {
                getApproverWorkflows(stage: "${stage}", userAliasAllStates: "${userAliasAllStates}", userResponseStatus: "${userResponseStatus}", workflowStatus:"${workflowStatus}" ${getPaginationInput(pageSize, pageNo)})
              }          
              `
        }
        
    } else {
        if(typeof(workflowStatus) === 'undefined') {
        mQry = `query MyQuery {
            getApproverWorkflows(stage: "${stage}", userAliasAllStates: "${userAliasAllStates}", userResponseStatus: "${userResponseStatus}", tags: ${tags} ${getPaginationInput(pageSize, pageNo)})
          }          
         `
        } else {
            mQry = `query MyQuery {
                getApproverWorkflows(stage: "${stage}", userAliasAllStates: "${userAliasAllStates}", userResponseStatus: "${userResponseStatus}", tags: ${tags}, workflowStatus:"${workflowStatus}" ${getPaginationInput(pageSize, pageNo)})
              }          
             ` 
        }
    }
    let response = await API.graphql(graphqlOperation(mQry));
    let getApproverWorkflowsResponse = response.data.getApproverWorkflows;
    return JSON.parse(getApproverWorkflowsResponse)

}


/*
Sample details object to pass to getWasWorkflowById api (all fields are mandatory)
const details = {
    workflowInstanceId: "fsfsdjjh-fsejbfsjb2q2bj-2424343b",
    stage:"beta",
  }
*/

export const getWasWorkflowById = async ({workflowInstanceId, stage}) => {

    stage = stage === "dev" ? "beta" : stage;
    let mQry = `query MyQuery {
        getWorkflowById(stage: "${stage}", workflowInstanceId: "${workflowInstanceId}")
      }
      `;
    let response = await API.graphql(graphqlOperation(mQry));
    let getWorkflowByIdResponse = response.data.getWorkflowById;
    const r = JSON.parse(getWorkflowByIdResponse)
    return r

}


/*
Sample details object to pass to performActionOnWorkflow api (all fields are mandatory)
const details = {
    eventTriggeredBy: "subbui",
    stage:"beta",
    eventType: "Approve",    // Possible values: Approve, Reject, Cancel
    workflowInstanceId: "fsfsdjjh-fsejbfsjb2q2bj-2424343b"
    comments: "This is a comment when performing action"
  }
*/

export const performActionOnWorkflow = async ({eventTriggeredBy, stage, eventType, workflowInstanceId, comments}) => {

    stage = stage === "dev" ? "beta" : stage;
    let mQry;
    if(typeof(comments) === 'undefined') {
     mQry = `mutation MyMutation {
        performActionOnWorkflow(eventTriggeredBy: "${eventTriggeredBy}", eventType: "${eventType}", stage: "${stage}", workflowInstanceId: "${workflowInstanceId}")
      }`;
    } else {
      mQry = `mutation MyMutation {
        performActionOnWorkflow(eventTriggeredBy: "${eventTriggeredBy}", eventType: "${eventType}", stage: "${stage}", workflowInstanceId: "${workflowInstanceId}", comments: "${comments}")
      }`;
    }
    let response = await API.graphql(graphqlOperation(mQry));
    let performActionOnWorkflowResponse = response.data.performActionOnWorkflow;
    return JSON.parse(performActionOnWorkflowResponse)

}


/*
Sample details object to pass to getAllowedActions api (all fields are mandatory)
const details = {
    workflowInstanceId: "fsfsdjjh-fsejbfsjb2q2bj-2424343b",
    stage:"beta",
    userAlias: "subbui"
}
*/

export const getAllowedActions = async ({userAlias, stage, workflowInstanceId}) => {

    stage = stage === "dev" ? "beta" : stage;
    let mQry = `query MyQuery {
        getAllowedActions(stage: "${stage}",userAlias: "${userAlias}", workflowInstanceId: "${workflowInstanceId}")
      }`;
    let response = await API.graphql(graphqlOperation(mQry));
    let getAllowedActionsResponse = response.data.getAllowedActions;
    return getAllowedActionsResponse
    // return ["Approve", "Cancel", "Reject"]

}