import React, { useState, useEffect, useRef } from "react";

import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";

import {
  AppLayout,
  BreadcrumbGroup,
  HelpPanel,
  Button,
  Box,
  Select,
  Spinner,
  Checkbox,
  SpaceBetween,
  Container,
  Header,
  Icon,
  Modal
} from "@amzn/awsui-components-react";
import axios from "axios";

import SideNav from "./SideNav";
import appSettings from "./app_settings";

import * as d3 from "d3";

import { AgGridReact } from "ag-grid-react";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";

import { setCurrentAppId } from "../../store/userAuthSlice";

import AccessDenied from "../../AccessDenied";
import WaitForPageAccess from "../../WaitForPageAccess";

import { Auth, Amplify } from "aws-amplify";
import { Board, BoardItem } from "@amzn/awsui-board-components";
import {
  boardI18nStrings,
  boardItemI18nStrings,
} from "src/i18n-strings/board-I18nStrings";
import { Storage } from "aws-amplify";

const MappingBookToLedger = () => {
  const userId = useSelector((globalState) => globalState.auth.userId);
  const history = useHistory();
  const currentAppId = useSelector(
    (globalState) => globalState.auth.currentAppId
  );
  const isCurrentAppAuthorised = useSelector(
    (globalState) => globalState.auth.isCurrentAppAuthorized
  );
  const [isCheckingAccess, setIsCheckingAccess] = useState(true);
  const [isAuthorized, setIsAuthorized] = useState();
  const dispatch = useDispatch();
  const gridRef = useRef();
  const gridRef2 = useRef();
  const [dsInfo, setDsInfo] = useState([]);
  const [showLoadingIcon, setShowLoadingIcon] = useState(false);
  const [toolsHide, setToolsHide] = useState(true);

  const [selectedItem, setSelectedItem] = useState([]);
  const [isItemSelected, setIsItemSelected] = useState(false)
  const [bookTypeCodeOptions, setBookTypeCodeOptions] = useState([])
  const [ledgerNameOptions, setLedgerNameOptions] = useState([])
  const [selectedBookType, setSelectedBookType] = useState()
  const [selectedLedgerName, setSelectedLedgerName] = useState()
  const [showUpdatingIcon, setShowUpdatingIcon] = useState(false)
  const [addStatus, setAddStatus] = useState("");
  const [boardItems, setBoardItems] = useState([
    {
      id: "TABLE_A",
      rowSpan: 5,
      columnSpan: 2,
      data: { content: <></> },
      header:
        "The following table shows the mapping for Book Type Code to Ledger Name (Table A)",
    },
    {
      id: "TABLE_B",
      rowSpan: 5,
      columnSpan: 2,
      data: { content: <></> },
      header:
        "The following table shows the books that are not mapped but currently used (Table B)",
    },
  ]);
  const [detailedDSInfo, setDetailedDSInfo] = useState([]);
  const [addRecordErrorModal, setAddRecordErrorModal] = useState(false)

  const addRecord = async () => {
    if (!(selectedBookType && selectedLedgerName)) {
      setAddRecordErrorModal(true)
      return;
    }
    setShowUpdatingIcon(true)
    console.log("In add record!");

    let mSQL =
      "INSERT into boffin.mapping_book_type_ledger (BOOK_TYPE_CODE, LEDGER_NAME) VALUES ('" +
      selectedBookType.value +
      "', '" +
      selectedLedgerName.value +
      "');";

    let body =
      `{
          "processName" : "MySQL",
          "scriptName"  : "executeSQLonMySQL",
          "parameters"  : {"sql":"` +
      mSQL +
      `"}
        }`;

    // console.log(body)
    const ApiGateWayEndpoint = Amplify.Cache.config.ApiGateWayEndpoint;
    let url = ApiGateWayEndpoint + "/executeOnDemand";
    axios
      .post(url, body, {
        headers: {
          Authorization: (await Auth.currentSession())
            .getIdToken()
            .getJwtToken(),
        },
      })
      .then((response) => {
      
          setShowUpdatingIcon(false);
          setAddStatus("Added");
          LoadData();
          setSelectedBookType()
          setSelectedLedgerName()
        
      });
  };

  useEffect(() => {
    const book_type_codes = dsInfo.map((row) => row.BOOK_TYPE_CODE);
    let tableB = detailedDSInfo.filter((row) => {
      return (!book_type_codes.includes(row.book_type_code) && (
        !["Additional Dataset", "MJE", "GLTD" ].includes(row.dataset)
      ) &&
      (row.book_type_code)
      )
    });

     tableB = tableB.map((item) => {
      let obj = {};
      ({book_type_code : obj.book_type_code, dataset : obj.dataset, ledger_name : obj.ledger_name} = item);
      return obj
    })
    tableB = removeDuplicates(tableB)

    const bookTypeCodesFromTableB = [...new Set(tableB.map((item) => (item.book_type_code)))].filter((item) => item.trim() !== '')
    const ledgerNamesFromTableB = [...new Set(tableB.map((item) => (item.ledger_name)))].filter((item) => item.trim() !== '')

    setBookTypeCodeOptions(bookTypeCodesFromTableB.map((item) => (
      { label: item, value: item}
    )))
    
    setLedgerNameOptions(ledgerNamesFromTableB.map((item) => (
      { label: item, value: item}
    )))

    setBoardItems((previous) => {
      const updated = boardItems.map((item) => {
        if (item.id === "TABLE_A") {
          item.data.content = (
            <>
              <div
                className="ag-theme-alpine"
                style={{ height: "92%", width: "100%" }}
              >
                <AgGridReact
                  ref={gridRef}
                  rowData={dsInfo}
                  defaultColDef={defaultColDef}
                  columnDefs={columnDefs}
                  rowSelection="single"
                  animateRows={true}
                  rowHeight={30}
                  enableCellTextSelection={true}
                  rowMultiSelectWithClick={true}
                ></AgGridReact>
              </div>
            </>
          );
        } else {
          item.data.content = (
            <div
              className="ag-theme-alpine"
              style={{ height: "92%", width: "100%" }}
            >
              <AgGridReact
                ref={gridRef2}
                rowData={tableB}
                columnDefs={tableBcolumnDefs}
                defaultColDef={defaultColDef}
                rowSelection="single"
                animateRows={true}
                rowHeight={30}
                enableCellTextSelection={true}
              ></AgGridReact>
            </div>
          );
        }
        return item;
      });
      return updated;
    });
    console.log(dsInfo)
  }, [dsInfo, detailedDSInfo]);
  useEffect(() => {
    if (currentAppId !== appSettings.appId) {
      dispatch(setCurrentAppId(appSettings.appId));
    }
    setIsAuthorized(isCurrentAppAuthorised);
    if (isCurrentAppAuthorised) {
      LoadData();
    }
    return () => {
      // this now gets called when the component unmounts
    };
  }, [isCurrentAppAuthorised]);

  useEffect(() => {
    if(isAuthorized === true || isAuthorized === false){
      setIsCheckingAccess(!isCheckingAccess);
    } 
  }, [isAuthorized]);

  const breadItems = [
    { text: "Home", href: "/Home" },
    { text: "ATeam BI", href: "/ATeamBIHome" },
    { text: "Boffin", href: "/BoffinHome" },
    { text: "Mapping Book to Ledger", href: "/" },
  ];

  const downloadFileClick = () => {

    if(dsInfo.length >= 0) {
      let csv = '';

      for (let col of columnDefs) {
        if(col.headerName) {
          csv += col.headerName + ','
        }
      }
      csv += '\n'

      for(let row of dsInfo) {
        csv += `"${row.BOOK_TYPE_CODE}"` + ','
        csv += `"${row.LEDGER_NAME}"` + ','
        csv += '\n'
      }
    
    let link = document.createElement('a')
    link.id = 'download-csv'
    link.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(csv));
    link.setAttribute('download', 'MappingBookToLedger.csv');
    document.body.appendChild(link)
    document.querySelector('#download-csv').click()

    }

  }

  const removeDuplicates = (arr) => {
 
    const jsonObject = arr.map(JSON.stringify);
    const uniqueSet = new Set(jsonObject);
    const uniqueArray = Array.from(uniqueSet).map(JSON.parse);
 
    console.log(uniqueArray);

    return uniqueArray;
  }

  const onFollowHandler = (ev) => {
    ev.preventDefault();
    if (ev.detail.href) {
      history.push(ev.detail.href.substring(1));
    }
  };

  const LoadData = async () => {
    setShowLoadingIcon(true);
    // console.log('Need to execute sql here..')
    let mSQL = "SELECT * FROM boffin.mapping_book_type_ledger;";
    let body =
      `{
          "processName" : "MySQL",
          "scriptName"  : "executeSQLonMySQL",
          "parameters"  : {"sql":"` +
      mSQL +
      `"}
        }`;

    // console.log(body)
    const ApiGateWayEndpoint = Amplify.Cache.config.ApiGateWayEndpoint;
    let url = ApiGateWayEndpoint + "/executeOnDemand";
    axios
      .post(url, body, {
        headers: {
          Authorization: (await Auth.currentSession())
            .getIdToken()
            .getJwtToken(),
        },
      })
      .then((response) => {
        //  console.log(response.data.data)
        setDsInfo(response.data.data);
      });

    let folder = "Boffin/datasets/";
    let detailedDatasetFn = folder + "boffin_datasets_detailed_info.csv";
    const detailedDatasetFileURL = await Storage.get(detailedDatasetFn, {
      level: "public",
      expires: 60,
    });
    let detailedDatasetCSVData = await d3
      .csv(detailedDatasetFileURL)
      .then((result) => {
        setDetailedDSInfo(result);
        // setState here..
      });

    setShowLoadingIcon(false);
  };

  const defaultColDef = {
    // set filtering on for all columns
    filter: true,
    filterParams: {
      buttons: ["reset"],
      debounceMs: 200,
      maxNumConditions: 10

    },
  };

  const columnDefs = [
    {
      field: "BOOK_TYPE_CODE",
      width: "250px",
      resizable: true,
      sortable: true,
      headerName: "BOOK TYPE CODE",
    },
    {
      field: "LEDGER_NAME",
      width: "250px",
      sortable: true,
      resizable: true,
      headerName: "LEDGER NAME",
    },
    { field: "" },
  ];

  const tableBcolumnDefs = [
    {
      field: "book_type_code",
      width: "250px",
      resizable: true,
      sortable: true,
      headerName: "BOOK TYPE CODE",
    },
    {
      field: "dataset",
      width: "250px",
      sortable: true,
      resizable: true,
      headerName: "Dataset",
    },
    {
      field: "ledger_name",
      width: "250px",
      sortable: true,
      resizable: true,
      headerName: "Ledger Name",
    },
    { field: "" },
  ];


  const helpContent = (
    <HelpPanel
      header={
        <div>
          <h2>Add New Line to Table A</h2>
        </div>
      }
    >
      <Container>
      <SpaceBetween size="m">
          <Box>
            <Box variant="awsui-key-label">Book Type Code</Box>
            <Select
              selectedOption={selectedBookType}
              options={bookTypeCodeOptions}
              onChange={({ detail }) => {
                setSelectedBookType(detail.selectedOption);
              }}            
            ></Select>
          </Box>
          <Box>
            <Box variant="awsui-key-label">Ledger Name</Box>
            <Select
              selectedOption={selectedLedgerName}
              options={ledgerNameOptions}
              onChange={({ detail }) => {
                setSelectedLedgerName(detail.selectedOption);
              }}           
            ></Select>
          </Box>
          <Box>
          <Button iconName="add-plus" variant="primary" onClick={() => addRecord()}>
              Add Line
          </Button>
          </Box>
          <Box>
            {showUpdatingIcon ? (
              <Box>
                {" "}
                <Spinner></Spinner> Adding...{" "}
              </Box>
            ) : (
              <Box>
                {addStatus === "Added" ? (
                  <Icon variant="success" name="status-positive"></Icon>
                ) : (
                  ""
                )}
                &nbsp; {addStatus}
              </Box>
            )}
          </Box>
      </SpaceBetween>
      </Container>
      
    </HelpPanel>
  );

  const content = (
    <div>
      {isAuthorized ? (
        <Box>
          {showLoadingIcon ? (
            <Box>
              <Spinner></Spinner>
              <Box>Loading Mapping Book to Ledger...</Box>
              <Box>Please wait, takes upto a minute...</Box>
            </Box>
          ) : (
            <Box>
              <Container
                header={
                  <Header
                    actions={
                      <SpaceBetween direction="horizontal" size="xs">
                        <Button
                          iconName="download"
                          onClick={() => downloadFileClick()}
                        >
                          Download Table A
                        </Button>
                        <Button
                          variant="normal"
                          iconName="refresh"
                          onClick={() => LoadData()}
                        >
                          Refresh
                        </Button>
                      </SpaceBetween>
                    }
                  >
                    Mapping Book to Ledger
                  </Header>
                }
              >
                <Board
                  renderItem={(item) => (
                    <BoardItem
                      i18nStrings={boardItemI18nStrings}
                      header={<Header>{item.header}</Header>}
                    >
                      {item.data.content}
                    </BoardItem>
                  )}
                  onItemsChange={(event) => setBoardItems(event.detail.items)}
                  items={boardItems}
                  i18nStrings={boardI18nStrings}
                />
              </Container>
            </Box>
          )}
          <Modal
            visible={addRecordErrorModal}
            header={<div>Error</div>}
            onDismiss={() => {
              setAddRecordErrorModal(false)
            }}
            >
            All Fields are required!
            </Modal>
        </Box>
      ) : isCheckingAccess ? (
        <div>
          <WaitForPageAccess></WaitForPageAccess>
        </div>
      ) : (
        <div>
          <AccessDenied appName={appSettings.appName}></AccessDenied>
        </div>
      )}
    </div>
  );

  return (
    <AppLayout
      breadcrumbs={
        <BreadcrumbGroup
          items={breadItems}
          onFollow={(event) => onFollowHandler(event)}
        ></BreadcrumbGroup>
      }
      content={content}
      navigation={<SideNav activeHref="/BoffinMappingBookToLedger" />}
      headerSelector="#TopBar"
      navigationHide={false}
      toolsOpen={toolsHide}
      onToolsChange={()=>{setToolsHide(!toolsHide)}}
      maxContentWidth={Number.MAX_VALUE}
      tools={isAuthorized ? helpContent : ''}
      headerVariant="high-contrast"
    ></AppLayout>
  );
};

export default MappingBookToLedger;
