import React, { useState, useEffect, useRef } from "react";

import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";

import {
  AppLayout,
  BreadcrumbGroup,
  HelpPanel,
  Button,
  Box,
  Select,
  Spinner,
  Checkbox,
  SpaceBetween,
  Container,
  Header,
  Icon,
  SegmentedControl,
  Grid,
  Input,
  FormField,
  ColumnLayout,
  Modal
} from "@amzn/awsui-components-react";
import axios from "axios";

import SideNav from "./SideNav";
import appSettings from "./app_settings";

import { teamNames } from "./Constants";

import { AgGridReact } from "ag-grid-react";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";

import { setCurrentAppId } from "../../store/userAuthSlice";

import checkPageAccess from "../../checkPageAccess";
import AccessDenied from "../../AccessDenied";
import WaitForPageAccess from "../../WaitForPageAccess";

import { Auth, Amplify } from "aws-amplify";
import {Board, BoardItem} from "@amzn/awsui-board-components";
import { boardI18nStrings, boardItemI18nStrings } from 'src/i18n-strings/board-I18nStrings';

const BoffinWarningRulesOverride = () => {
  const userId = useSelector((globalState) => globalState.auth.userId);
  const history = useHistory();
  const currentAppId = useSelector(
    (globalState) => globalState.auth.currentAppId
  );
  const isCurrentAppAuthorised = useSelector(
    (globalState) => globalState.auth.isCurrentAppAuthorized
  );
  const [isCheckingAccess, setIsCheckingAccess] = useState(true);
  const [isAuthorized, setIsAuthorized] = useState();
  const dispatch = useDispatch();
  const gridRef = useRef();
  const [dsInfo, setDsInfo] = useState([]);
  const [showLoadingIcon, setShowLoadingIcon] = useState(false);
  const [showUpdatingIcon, setShowUpdatingIcon] = useState(false);
  const [updateStatus, setUpdateStatus] = useState(false);
  const [addStatus, setAddStatus] = useState(false);
  const [isItemSelected, setIsItemSelected] = useState(false)

  const [selectedTeamName, setSelectedTeamName] = useState({});
  const [isAWS, setIsAWS] = useState(false);
  const [toolsHide, setToolsHide] = useState(true);

  const [selectedItem, setSelectedItem] = useState([]);
  const [boardItems, setBoardItems] = useState([
    { id: "1", rowSpan: 5, columnSpan: 6,data:{}}
  ]);
  const [selectedSegmentId, setSelectedSegmentId] = useState("add");
  const [addOrUpdateModal, setAddOrUpdateModal] = useState(false)


  const [warningMsg, setWarningMsg] = useState('')
  const [ruleNumber, setRuleNumber] = useState('')
  const [columnName1, setColumnName1] = useState('')
  const [columnName2, setColumnName2] = useState('')
  const [columnName3, setColumnName3] = useState('')
  const [operation1, setOperation1] = useState('')
  const [operation2, setOperation2] = useState('')
  const [operation3, setOperation3] = useState('')
  const [value1, setValue1] = useState('')
  const [value2, setValue2] = useState('')
  const [value3, setValue3] = useState('')
  const [logic1, setLogic1] = useState('')
  const [logic2, setLogic2] = useState('')

   

  useEffect(() => {
    if (currentAppId !== appSettings.appId) {
      dispatch(setCurrentAppId(appSettings.appId));
    }
    setIsAuthorized(isCurrentAppAuthorised);
    if (isCurrentAppAuthorised) {
      LoadData();
    }
    return () => {
      // this now gets called when the component unmounts
    };
  }, [isCurrentAppAuthorised]);

  useEffect(() => {
    if(isAuthorized === true || isAuthorized === false){
      setIsCheckingAccess(!isCheckingAccess);
    } 
  }, [isAuthorized]);

  const breadItems = [
    { text: "Home", href: "/Home" },
    { text: "ATeam BI", href: "/ATeamBIHome" },
    { text: "Boffin", href: "/BoffinHome" },
    { text: "Warning Rules Override", href: "/" },
  ];

  const onFollowHandler = (ev) => {
    ev.preventDefault();
    if (ev.detail.href) {
      history.push(ev.detail.href.substring(1));
    }
  };

  const LoadData = async () => {
    setShowLoadingIcon(true);
    setIsItemSelected(false);
    // console.log('Need to execute sql here..')
    let mSQL =
      "SELECT * FROM boffin.Boffin_warning_rules;";
    let body =
      `{
          "processName" : "MySQL",
          "scriptName"  : "executeSQLonMySQL",
          "parameters"  : {"sql":"` +
      mSQL +
      `"}
        }`;

    // console.log(body)
    const ApiGateWayEndpoint = Amplify.Cache.config.ApiGateWayEndpoint;
    let url = ApiGateWayEndpoint + "/executeOnDemand";
    axios
      .post(url, body, {
        headers: {
          Authorization: (await Auth.currentSession())
            .getIdToken()
            .getJwtToken(),
        },
      })
      .then((response) => {
        setDsInfo(response.data.data);
        setShowLoadingIcon(false);
      });
  };

  const addRecord = async () => {

    if (!(warningMsg && ruleNumber && columnName1 && 
      operation1 && value1)) {
      setAddOrUpdateModal(true)
      return;
    }
    setShowUpdatingIcon(true)
    console.log("In add record!");

    let mSQL =
      "INSERT into boffin.Boffin_warning_rules (WarningMsg, rule_number, column_name_1, operator_1, value_1, logic_1, column_name_2, operation_2, value_2, logic_2, column_name_3, operation_3, value_3) VALUES ('" +
      warningMsg +
      "', '" +
      ruleNumber +
      "', '" +
      columnName1 +
      "', '" +
      operation1 +
      "', '" +
      value1 +
      "', '" +
      logic1 +
      "', '" +
      columnName2 +
      "', '" +
      operation2 +
      "', '" +
      value2 +
      "', '" +
      logic2 +
      "', '" +
      columnName3 +
      "', '" +
      operation3 +
      "', '" +
      value3 +

      "');";

    let body =
      `{
          "processName" : "MySQL",
          "scriptName"  : "executeSQLonMySQL",
          "parameters"  : {"sql":"` +
      mSQL +
      `"}
        }`;

    // console.log(body)
    const ApiGateWayEndpoint = Amplify.Cache.config.ApiGateWayEndpoint;
    let url = ApiGateWayEndpoint + "/executeOnDemand";
    axios
      .post(url, body, {
        headers: {
          Authorization: (await Auth.currentSession())
            .getIdToken()
            .getJwtToken(),
        },
      })
      .then((response) => {
      
          setShowUpdatingIcon(false);
          setAddStatus(true);
          LoadData();
          setWarningMsg('')
          setRuleNumber('')
          setColumnName1('')
          setColumnName2('')
          setColumnName3('')
          setOperation1('')
          setOperation2('')
          setOperation3('')
          setValue1('')
          setValue2('')
          setValue3('')
          setLogic1('')
          setLogic2('')
      });

  };

  const updateRecord = async () => {
    if (!(warningMsg && ruleNumber && columnName1 && 
      operation1 && value1)) {
      setAddOrUpdateModal(true)
      return;
    }
    setShowUpdatingIcon(true)
    let mSQL =
      "UPDATE boffin.Boffin_warning_rules SET WarningMsg = '" +
      warningMsg +
      "', column_name_1 = '" +
      columnName1 +
      "', operator_1 = '" +
      operation1 +
      "', value_1 = '" +
      value1 +
      "', logic_1 = '" +
      logic1 +
      "', column_name_2 = '" +
      columnName2 +
      "', operation_2 = '" +
      operation2 +
      "', value_2 = '" +
      value2 +
      "', logic_2 = '" +
      logic2 +
      "', column_name_3 = '" +
      columnName3 +
      "', operation_3 = '" +
      operation3 +
      "', value_3 = '" +
      value3 +
      "' WHERE rule_number ='" +
      ruleNumber +
      "';";
    let body =
      `{
          "processName" : "MySQL",
          "scriptName"  : "executeSQLonMySQL",
          "parameters"  : {"sql":"` +
      mSQL +
      `"}
        }`;

    // console.log(body)
    const ApiGateWayEndpoint = Amplify.Cache.config.ApiGateWayEndpoint;
    let url = ApiGateWayEndpoint + "/executeOnDemand";
    axios
      .post(url, body, {
        headers: {
          Authorization: (await Auth.currentSession())
            .getIdToken()
            .getJwtToken(),
        },
      })
      .then((response) => {
        //  console.log(response.data.records)
          setShowUpdatingIcon(false);
          setUpdateStatus(true);
          LoadData();
          setWarningMsg('')
          setRuleNumber('')
          setColumnName1('')
          setColumnName2('')
          setColumnName3('')
          setOperation1('')
          setOperation2('')
          setOperation3('')
          setValue1('')
          setValue2('')
          setValue3('')
          setLogic1('')
          setLogic2('')

      });
  };

  const defaultColDef = {
    // set filtering on for all columns
    filter: true,
    filterParams: {
      buttons: ["reset"],
      debounceMs: 200,
    },
  };

  const [columnDefs] = useState([
    {
      field: "WarningMsg",
      width: "160px",
      resizable: true,
      sortable: true,
      headerName: "Warning Message",
    },
    {
        field: "rule_number",
        width: "160px",
        resizable: true,
        sortable: true,
        headerName: "Rule Number",
      },
    {
        field: "column_name_1",
        width: "160px",
        resizable: true,
        sortable: true,
        headerName: "Column Name 1",
    },
    {
        field: "operator_1",
        width: "160px",
        resizable: true,
        sortable: true,
        headerName: "Operation 1",
    },
    {
        field: "value_1",
        width: "160px",
        resizable: true,
        sortable: true,
        headerName: "Value 1",
    },
    {
        field: "logic_1",
        width: "160px",
        resizable: true,
        sortable: true,
        headerName: "Logic 1",
    },

    {
        field: "column_name_2",
        width: "160px",
        resizable: true,
        sortable: true,
        headerName: "Column Name 2",
    },
    {
        field: "operation_2",
        width: "160px",
        resizable: true,
        sortable: true,
        headerName: "Operation 2",
    },
    
    {
        field: "value_2",
        width: "160px",
        resizable: true,
        sortable: true,
        headerName: "Value 2",
    },
    {
        field: "logic_2",
        width: "160px",
        resizable: true,
        sortable: true,
        headerName: "Logic 2",
    },
      
      
      {
        field: "column_name_3",
        width: "160px",
        resizable: true,
        sortable: true,
        headerName: "Column Name 3",
    },
      {
        field: "operation_3",
        width: "160px",
        resizable: true,
        sortable: true,
        headerName: "Operation 3",
      },
      {
        field: "value_3",
        width: "160px",
        resizable: true,
        sortable: true,
        headerName: "Value 3",
      },
      
    { field: "" },
  ]);
  
  const onSelectionChanged= () =>{
    const selectedRows = gridRef.current.api.getSelectedRows();
    if(selectedRows.length > 0) {

      setSelectedSegmentId("edit")
      setIsItemSelected(true)
  
      setWarningMsg(selectedRows[0].WarningMsg)
      setRuleNumber(selectedRows[0].rule_number)
      setColumnName1(selectedRows[0].column_name_1)
      setColumnName2(selectedRows[0].column_name_2)
      setColumnName3(selectedRows[0].column_name_3)
      setOperation1(selectedRows[0].operator_1)
      setOperation2(selectedRows[0].operation_2)
      setOperation3(selectedRows[0].operation_3)
      setValue1(selectedRows[0].value_1)
      setValue2(selectedRows[0].value_2)
      setValue3(selectedRows[0].value_3)
      setLogic1(selectedRows[0].logic_1)
      setLogic2(selectedRows[0].logic_2)

    } else {

      setIsItemSelected(false)
      setWarningMsg('')
      setRuleNumber('')
      setColumnName1('')
      setColumnName2('')
      setColumnName3('')
      setOperation1('')
      setOperation2('')
      setOperation3('')
      setValue1('')
      setValue2('')
      setValue3('')
      setLogic1('')
      setLogic2('')

    }
  }

  // const downloadFileClick = () => {

  //   if(dsInfo.length >= 0) {
  //     let csv = '';

  //     for (let col of columnDefs) {
  //       csv += col.headerName + ','
  //     }
  //     csv += '\n'

  //     for(let row of dsInfo) {
  //       csv += `"${row.Company_code}"` + ','
  //       csv += `"${row.Description}"` + ','
  //       csv += `"${row.End_Date}"` + ','
  //       csv += `"${row.Enabled}"` + ','
  //       csv += `"${row.Parent}"` + ','
  //       csv += `"${row.Ledger_ID}"` + ','
  //       csv += `"${row.Ledger_Name}"` + ','
  //       csv += `"${row.Currency}"` + ','
  //       csv += `"${row.team_name}"` + ','
  //       csv += `"${row.isAWS}"` + ','
  //       csv += '\n'
  //     }
    
  //   let link = document.createElement('a')
  //   link.id = 'download-csv'
  //   link.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(csv));
  //   link.setAttribute('download', 'TeamNameMapping.csv');
  //   document.body.appendChild(link)
  //   document.querySelector('#download-csv').click()

  //   }

  // }

  const helpContent = (
    <HelpPanel
      header={
        <div>
          <h2>Add New Record / Edit Existing Record</h2>
        </div>
      }
    >
      <Container>
        <Box>
          <SegmentedControl
            selectedId={selectedSegmentId}
            onChange={({ detail }) =>{ 
              setSelectedSegmentId(detail.selectedId)
              if(detail.selectedId === 'add') {
                gridRef.current.api.deselectAll()
              }
            }}
            options={[
              { text: "Add New Record", id: "add", iconName: "add-plus" },
              { text: "Edit Existing Record", id: "edit", iconName: "edit" },
            ]}
          />
        </Box>
        <br />
        {selectedSegmentId === "add" ? (
          <>
          <Box>
            <ColumnLayout columns={2}>
              <FormField label="Warning Message">
                <Input
                  type="text"
                  value={warningMsg}
                  onChange={({ detail }) => setWarningMsg(detail.value)}
                  ariaRequired
                ></Input>
              </FormField>
              <FormField label="Rule Number">
                <Input
                  type="text"
                  value={ruleNumber}
                  onChange={({ detail }) => setRuleNumber(detail.value)}
                  ariaRequired
                ></Input>
              </FormField>
              <FormField label="Column Name 1">
                <Input
                  type="text"
                  value={columnName1}
                  onChange={({ detail }) => setColumnName1(detail.value)}
                ></Input>
              </FormField>
              <FormField label="Operation 1">
                <Input
                  type="text"
                  value={operation1}
                  onChange={({ detail }) => setOperation1(detail.value)}
                ></Input>
              </FormField>
              <FormField label="Value 1">
                <Input
                  type="text"
                  value={value1}
                  onChange={({ detail }) => setValue1(detail.value)}
                ></Input>
              </FormField>
              <FormField label="Logic 1">
                <Input
                  type="text"
                  value={logic1}
                  onChange={({ detail }) => setLogic1(detail.value)}
                ></Input>
              </FormField>
              <FormField label="Column Name 2">
                <Input
                  type="text"
                  value={columnName2}
                  onChange={({ detail }) => setColumnName2(detail.value)}
                ></Input>
              </FormField>
              <FormField label="Operation 2">
                <Input
                  type="text"
                  value={operation2}
                  onChange={({ detail }) => setOperation2(detail.value)}
                ></Input>
              </FormField>
              <FormField label="Value 2">
                <Input
                  type="text"
                  value={value2}
                  onChange={({ detail }) => setValue2(detail.value)}
                ></Input>
              </FormField>
              <FormField label="Logic 2">
                <Input
                  type="text"
                  value={logic2}
                  onChange={({ detail }) => setLogic2(detail.value)}
                ></Input>
              </FormField>
              <FormField label="Column Name 3">
                <Input
                  type="text"
                  value={columnName3}
                  onChange={({ detail }) => setColumnName3(detail.value)}
                ></Input>
              </FormField>
              <FormField label="Operation 3">
                <Input
                  type="text"
                  value={operation3}
                  onChange={({ detail }) => setOperation3(detail.value)}
                ></Input>
              </FormField>
              <FormField label="Value 3">
                <Input
                  type="text"
                  value={value3}
                  onChange={({ detail }) => setValue3(detail.value)}
                ></Input>
              </FormField>
            </ColumnLayout>
          </Box>
          <br />
          <Button variant='primary' iconName= "add-plus" onClick={() => addRecord()}> Add</Button>
          <Box>
            {showUpdatingIcon ? (
              <Box>
                {" "}
                <Spinner></Spinner> Adding...{" "}
              </Box>
            ) : (
              <Box>
                
              </Box>
            )}
          </Box>
          </>
        ) : (
          <>
          {(isItemSelected) ? <>
            <Box>
            <ColumnLayout columns={2}>
              <FormField label="Warning Message">
                <Input
                  type="text"
                  value={warningMsg}
                  onChange={({ detail }) => setWarningMsg(detail.value)}
                  ariaRequired
                ></Input>
              </FormField>
              <FormField label="Rule Number">
                <Input
                  type="text"
                  value={ruleNumber}
                  onChange={({ detail }) => setRuleNumber(detail.value)}
                  disabled
                ></Input>
              </FormField>
              <FormField label="Column Name 1">
                <Input
                  type="text"
                  value={columnName1}
                  onChange={({ detail }) => setColumnName1(detail.value)}
                ></Input>
              </FormField>
              <FormField label="Operation 1">
                <Input
                  type="text"
                  value={operation1}
                  onChange={({ detail }) => setOperation1(detail.value)}
                ></Input>
              </FormField>
              <FormField label="Value 1">
                <Input
                  type="text"
                  value={value1}
                  onChange={({ detail }) => setValue1(detail.value)}
                ></Input>
              </FormField>
              <FormField label="Logic 1">
                <Input
                  type="text"
                  value={logic1}
                  onChange={({ detail }) => setLogic1(detail.value)}
                ></Input>
              </FormField>
              <FormField label="Column Name 2">
                <Input
                  type="text"
                  value={columnName2}
                  onChange={({ detail }) => setColumnName2(detail.value)}
                ></Input>
              </FormField>
              <FormField label="Operation 2">
                <Input
                  type="text"
                  value={operation2}
                  onChange={({ detail }) => setOperation2(detail.value)}
                ></Input>
              </FormField>
              <FormField label="Value 2">
                <Input
                  type="text"
                  value={value2}
                  onChange={({ detail }) => setValue2(detail.value)}
                ></Input>
              </FormField>
              <FormField label="Logic 2">
                <Input
                  type="text"
                  value={logic2}
                  onChange={({ detail }) => setLogic2(detail.value)}
                ></Input>
              </FormField>
              <FormField label="Column Name 3">
                <Input
                  type="text"
                  value={columnName3}
                  onChange={({ detail }) => setColumnName3(detail.value)}
                ></Input>
              </FormField>
              <FormField label="Operation 3">
                <Input
                  type="text"
                  value={operation3}
                  onChange={({ detail }) => setOperation3(detail.value)}
                ></Input>
              </FormField>
              <FormField label="Value 3">
                <Input
                  type="text"
                  value={value3}
                  onChange={({ detail }) => setValue3(detail.value)}
                ></Input>
              </FormField>
            </ColumnLayout>
          </Box>
          <br />
          <Button variant='primary' iconName= "edit" onClick={() => updateRecord()}>Update</Button>
          <Box>
          {showUpdatingIcon ? (
              <Box>
                {" "}
                <Spinner></Spinner> Updating...{" "}
              </Box>
            ) : (
              <Box>
                
              </Box>
            )}
          </Box>
          </>
          :
        <Box variant="awsui-key-label">Select a row to edit and update.</Box>
        }
          
          </>
        )}
      </Container>
    </HelpPanel>
  );

  const content = (
    <div>
      {isAuthorized ? (
        <Box>
          {showLoadingIcon ? (
            <Box>
              <Spinner></Spinner>
              <Box>Loading...</Box>
              <Box>Please wait, takes upto a minute...</Box>
            </Box>
          ) : (
            <Box>
              <Container
                header={
                  <Header
                    actions={
                      <SpaceBetween direction="horizontal" size="xs">
                        {/* <Button
                          iconName="download"
                          onClick={() => downloadFileClick()}
                        >
                          Download Warning Rules
                        </Button> */}
                        <Button
                          variant="normal"
                          iconName="refresh"
                          onClick={() => LoadData()}
                        >
                          Refresh
                        </Button>
                      </SpaceBetween>
                    }
                  >
                    Warning Rules Override
                  </Header>
                }
              >
                <Board
                  renderItem={(item) => (
                    <BoardItem i18nStrings={boardItemI18nStrings}>
                      <div
                        className="ag-theme-alpine"
                        style={{ height: "92%", width: "100%" }}
                      >
                        <AgGridReact
                          ref={gridRef}
                          rowData={dsInfo}
                          defaultColDef={defaultColDef}
                          columnDefs={columnDefs}
                          rowSelection="single"
                          animateRows={true}
                          rowHeight={30}
                          onSelectionChanged={onSelectionChanged}
                          enableCellTextSelection={true}
                        ></AgGridReact>
                      </div>
                    </BoardItem>
                  )}
                  onItemsChange={(event) => setBoardItems(event.detail.items)}
                  items={boardItems}
                  i18nStrings={boardI18nStrings}
                />
              </Container>
            </Box>
          )}
          <Modal
            visible={addOrUpdateModal}
            header={<div>Error</div>}
            onDismiss={() => {
              setAddOrUpdateModal(false)
            }}
            >
            Following are required and Cannot be empty: Warning Message, Rule Number, Column Name 1, Operation 1, Value 1
          </Modal>
          <Modal
            visible={addStatus}
            header={<div>Info</div>}
            onDismiss={() => {
              setAddStatus(false)
            }}
            >
            <Icon variant="success" name="status-positive"></Icon>
            Record Added!
          </Modal>
          <Modal
            visible={updateStatus}
            header={<div>Info</div>}
            onDismiss={() => {
              setUpdateStatus(false)
            }}
            >
            <Icon variant="success" name="status-positive"></Icon>
            Record Updated!
          </Modal>
        </Box>
      ) : isCheckingAccess ? (
        <div>
          <WaitForPageAccess></WaitForPageAccess>
        </div>
      ) : (
        <div>
          <AccessDenied appName={appSettings.appName}></AccessDenied>
        </div>
      )}
    </div>
  );

  return (
    <AppLayout
      breadcrumbs={
        <BreadcrumbGroup
          items={breadItems}
          onFollow={(event) => onFollowHandler(event)}
        ></BreadcrumbGroup>
      }
      content={content}
      toolsWidth={550}
      navigation={<SideNav activeHref="/BoffinWarningRulesOverride" />}
      headerSelector="#TopBar"
      navigationHide={false}
      tools={isAuthorized ? helpContent : ''}
      toolsOpen={toolsHide}
      maxContentWidth = {Number.MAX_VALUE}
      onToolsChange={()=>{setToolsHide(!toolsHide)}}
      headerVariant="high-contrast"
    ></AppLayout>
  );
};

export default BoffinWarningRulesOverride;
