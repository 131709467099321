import React, {useState, useEffect, useRef, useMemo, useCallback} from 'react';

import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from "react-router-dom";


import { AppLayout, BreadcrumbGroup, HelpPanel, Table,
            Box,Container,Header,SpaceBetween, Button,
            Flashbar, Checkbox, Select,
            Input,Spinner,Modal, Grid, ProgressBar, Alert, SegmentedControl, Multiselect
            
} from '@amzn/awsui-components-react';
// import BoardItem from "@amzn/awsui-board-components/board-item";
import SideNav from './SideNav';
import appSettings from './app_settings';

import { setCurrentAppId } from '../../store/userAuthSlice';

import { inServiceMonths,  inServiceYears, reviewOptions, onscreenOverrideColumnOptions } from './Constants'

import AccessDenied from '../../AccessDenied'
import WaitForPageAccess from '../../WaitForPageAccess'

import * as d3 from "d3";
import axios from "axios";
import { AgGridReact } from 'ag-grid-react';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import './GridStyles.css';

import { API, graphqlOperation } from 'aws-amplify';
import { Auth, Amplify, Storage } from 'aws-amplify';
import Badge from "@amzn/awsui-components-react/polaris/badge"
import {numberFilterParams, dateFilterParams} from '../../utilities/Factory'
import {Board, BoardItem} from "@amzn/awsui-board-components";
import { boardI18nStrings, boardItemI18nStrings } from 'src/i18n-strings/board-I18nStrings';
import {getBoffinComputesWithStartedStatus} from './BoffinAPIs'


const NewOverride = () => {
    const history = useHistory();
    const currentAppId = useSelector((globalState)=>globalState.auth.currentAppId)
    const isCurrentAppAuthorised = useSelector((globalState)=>globalState.auth.isCurrentAppAuthorized)
    const [isCheckingAccess, setIsCheckingAccess] = useState(true);
    const [isAuthorized, setIsAuthorized] = useState();
    
    const currentComputeId  = useSelector((globalState)=>globalState.boffin.BoffinComputeId)
    const dispatch = useDispatch()

    const gridRef = useRef();

    const [isLoading, setIsLoading] = useState(false);
    
    const [items, setItems] = useState([]);
    const [filteredItems, setFilteredItems] = useState([]);
    const [selectedItem, setSelectedItem] = useState([]);
    const [selectedRowIds, setSelectedRowIds] = useState([]);
    

    const [overrideYN, setOverrideYN] = useState(true);
    const [prepareReclassJE, setPrepareReclassJE] = useState(true);
    const [prepareAccJE, setPrepareAccJE] = useState(true);
    const [cipYN, setCipYN] = useState(false);

    const [selectedMonth, setSelectedMonth] = useState({});
    const [selectedYear, setSelectedYear] = useState({});
    const [categories, setCategories] = useState([]);
    const [selectedSegmentId, setSelectedSegmentId] = useState("base");
    const [cosCategories, setCosCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState({});
    const [eul, setEul] = useState('');
    
  
    const [appliedChanges, setAppliedChanges] = useState([]);

    const [showProcessing, setShowProcessing] = useState(false);
    const [processingStatus, setProcessingStatus] = useState('info');
    const [statusUpdate, setStatusUpdate] = useState('');
    const [percentageCompleted, setPercentageCompleted] = useState(0);
    const [toolsHide, setToolsHide] = useState(true);

    const [selectedReviewOption, setSelectedReviewOption] = useState({ label :"All Rows" , value :"ALL-ROWS"} );
    const [rowCount, setRowCount] = useState();    
    const [rowCountLabel, setRowCountLabel ] = useState("All Rows")
    const [computeInprogressModal, setComputeInprogressModal] = useState(false)
    const [navigationOpen, setNavigationOpen] = useState(false)
    const [isItemsSelected, setIsItemsSelected] = useState(false)

    const [boardItems, setBoardItems] = useState([
     
      { id: "1", rowSpan: 6, columnSpan: 6,data:{}}
    ]);
    const [selectedColumns, setSelectedColumns] = useState(onscreenOverrideColumnOptions);
    const [sumOfCredit, setSumOfCredit] = useState(0)
    const [sumOfDebit, setSumOfDebit] = useState(0)

    useEffect(() => {
    
      if (currentAppId !== appSettings.appId){
        dispatch(setCurrentAppId(appSettings.appId))
      }
      setIsAuthorized(isCurrentAppAuthorised)
      if (isCurrentAppAuthorised){
        LoadData()
      }
      return () => {
        // this now gets called when the component unmounts
      };

      
    }, [isCurrentAppAuthorised]);

    useEffect(() => {
      if(isAuthorized === true || isAuthorized === false){
        setIsCheckingAccess(!isCheckingAccess);
      } 
    }, [isAuthorized]);
    
    
      const breadItems = [
        { text: 'Home', href: '/Home' },
        { text: 'ATeam BI', href: '/ATeamBIHome' },
        { text: 'Boffin', href: '/BoffinHome' },
        { text: 'Review', href: '/BoffinViewAllItems' },
        { text: 'Compute Details', href: '/BoffinEditItem' },
        { text: 'Override (New)', href: '' }
        
    
      ];
      
      const onFollowHandler = (ev) => {
        ev.preventDefault();
        if (ev.detail.href) {
            history.push(ev.detail.href.substring(1));
        }
    }

    const LoadData = async () =>{
      setIsLoading(true)
        let folder = 'Boffin/compute/data/'
        let fn = folder + 'data_' + currentComputeId +'.csv'
        const fileURL = await Storage.get(fn, { level: 'public', expires: 60});
        // console.log(fileURL)
        let csvdata = await d3.csv(fileURL)
            .then(result => {
                setItems(result)
                setFilteredItems(result)
                setRowCount(result.length)
                const initialValue = 0;
                setSumOfDebit(result.reduce((sum, currVal) => (
                  sum + Number(currVal["debit"].replaceAll(",",""))
                ),initialValue))

                setSumOfCredit(result.reduce((sum, currVal) => (
                  sum + Number(currVal["credit"].replaceAll(",",""))
                ),initialValue))
                // setState here..
            })
        
        let folder1 = 'Boffin/compute/categories/'
        let fn1 = folder1 + 'categories_' + currentComputeId +'.csv'
        const fileURL1 = await Storage.get(fn1, { level: 'public', expires: 60});
        // console.log(fileURL)
        let cat = []
        let csvdata1 = await d3.csv(fileURL1)
            .then(result1 => {
                result1.map((row)=>{
                  cat.push({label: row.MajMinCat, value: row.MajMinCat })
                })
                setCategories(cat)
                // setState here..
            })


        let fn2 = folder1 + 'cos_categories_' + currentComputeId +'.csv'
        const fileURL2 = await Storage.get(fn2, { level: 'public', expires: 60});
        // console.log(fileURL)
        let cat2 = []
        let csvdata2 = await d3.csv(fileURL2)
            .then(result1 => {
                result1.map((row)=>{
                  cat2.push({label: row.COS_Category_Names, value: row.COS_Category_Names })
                })
                setCosCategories(cat2)
            })
      
      setIsLoading(false)

    }
    const helpContent = (
      <HelpPanel header={<Box variant="h2">Step #2: Override Options</Box>}>
        <Box>
          <Container>
            {isItemsSelected ? (
              <SpaceBetween size="s" direction="vertical">
                {overrideYN ? (
                  <Box>
                    <Box variant="awsui-key-label">Journal Entries</Box>
                    <Checkbox
                      checked={prepareReclassJE}
                      onChange={() => {
                        setPrepareReclassJE(!prepareReclassJE);
                      }}
                    >
                      Prepare Reclass
                    </Checkbox>
                    <Checkbox
                      checked={prepareAccJE}
                      onChange={() => {
                        setPrepareAccJE(!prepareAccJE);
                      }}
                    >
                      Prepare Deprication Accrual
                    </Checkbox>

                    <br></br>

                    <Checkbox
                      checked={cipYN}
                      onChange={() => {
                        setCipYN(!cipYN);
                      }}
                    >
                      <Box>Change to CIP</Box>
                    </Checkbox>
                    <br></br>

                    {cipYN ? (
                      <Box></Box>
                    ) : (
                      <Box>
                        <Box variant="awsui-key-label">
                          Change InServiceDate Month & Year
                        </Box>
                        <Grid gridDefinition={[{ colspan: 5 }, { colspan: 5 }]}>
                          <Select
                            selectedOption={selectedMonth}
                            options={inServiceMonths}
                            onChange={({ detail }) => {
                              setSelectedMonth(detail.selectedOption);
                            }}
                            filteringType="auto"
                          ></Select>
                          <Select
                            selectedOption={selectedYear}
                            options={inServiceYears}
                            onChange={({ detail }) => {
                              setSelectedYear(detail.selectedOption);
                            }}
                            filteringType="auto"
                          ></Select>
                        </Grid>

                        <br></br>
                        <Box>
                        <SegmentedControl
                          selectedId={selectedSegmentId}
                          onChange={({ detail }) =>
                            setSelectedSegmentId(detail.selectedId)
                          }
                          options={[
                            { text: "Base Unit Categories", id: "base" },
                            { text: "Comprehensive Category List", id: "comp" },
                          ]}
                        />
                        </Box>

                        <br></br>
                        <Box>
                          <Box variant="awsui-key-label">
                            Change Major / Minor Category
                          </Box>
                          
                            <Select
                              selectedOption={selectedCategory}
                              options={selectedSegmentId === "base" ? cosCategories  : categories}
                              onChange={({ detail }) => {
                                setSelectedCategory(detail.selectedOption);
                              }}
                              filteringType="auto"
                              virtualScroll={true}
                            ></Select>
                          
                        </Box>

                        <br></br>

                        <Box>
                          <Box variant="awsui-key-label">Change EUL</Box>
                          <Grid
                            gridDefinition={[{ colspan: 5 }, { colspan: 5 }]}
                          >
                            <Input
                              type="number"
                              value={eul}
                              onChange={({ detail }) => setEul(detail.value)}
                            ></Input>
                            <Box variant="small" color="text-body-secondary">
                              Enter number between 1 through 999
                            </Box>
                          </Grid>
                        </Box>
                      </Box>
                    )}
                  </Box>
                ) : (
                  <Box></Box>
                )}
                <Box>
                  <hr></hr>

                  <SpaceBetween size="m" direction="horizontal">
                    <Button
                      variant="primary"
                      onClick={() => {
                        applyButtonClick();
                      }}
                    >
                      Apply
                    </Button>
                    <Button
                      variant="normal"
                      onClick={() => {
                        applyClearAllclick();
                      }}
                    >
                      Reset
                    </Button>
                  </SpaceBetween>
                </Box>
              </SpaceBetween>
            ) : (
              <Box>Select row to override</Box>
            )}
          </Container>
        </Box>
      </HelpPanel>
    );
    
    const applyButtonClick = () =>{
      var api = gridRef.current.api;
      // get the first child of the
      var selectedRows = api.getSelectedRows();
      if (!selectedRows || selectedRows.length === 0) {
        console.log('No rows selected!');
        return;
      }
      var updatedItems = [];
      selectedRows.forEach(function (oldItem) {
        let newItem = oldItem
        newItem.Override = 'Yes'
        prepareReclassJE ? newItem.Override_Prepare_JE_Reclass = 'Yes' : newItem.Override_Prepare_JE_Reclass = 'No'
        prepareAccJE ? newItem.Override_Prepare_JE_Dep_Accrual = 'Yes' : newItem.Override_Prepare_JE_Dep_Accrual = 'No'
        if (cipYN) {
          newItem.Override_CIP_YN = 'Yes'
          newItem.Override_Prepare_JE_Dep_Accrual = 'No'
          newItem.Override_InServiceDate_Month = ''
          newItem.Override_InServiceDate_Year = ''
          newItem.Override_Major_Min_Category = ''
          newItem.Override_EUL = ''
        }  else {
          newItem.Override_CIP_YN = ''
          if (selectedMonth.value){
            newItem.Override_InServiceDate_Month = selectedMonth.value
          } 
          if (selectedYear.value){
            newItem.Override_InServiceDate_Year = selectedYear.value
          }
          if (selectedCategory.value){
            newItem.Override_Major_Min_Category = selectedCategory.value
          }
          if (eul){
            newItem.Override_EUL = eul
          } 
        }

        updatedItems.push(newItem);
        gridRef.current.api.getRowNode(oldItem.RowId).setSelected(false); 
      });
      
      api.applyTransaction({ update: updatedItems });
      setAppliedChanges(list => (list ? list : []).concat(updatedItems));
      applyClearAllclick()
    }

  const applyClearAllclick = () =>{
  setSelectedMonth({})
  setSelectedYear({})
  setSelectedCategory({})
  setEul('')
  setCipYN(false)
  setSelectedSegmentId("base")
  }   

      
  const defaultColDef = {
        // set filtering on for all columns
        filter: true,
        filterParams: {
            buttons: ['reset'],
            debounceMs: 200,
            maxNumConditions: 10

        }
  };

    const onSelectionChanged= () =>{
      // const colState = gridRef.current.columnApi.getColumns();
      // console.log(gridRef.current.columnApi.columnModel.columnDefs)
      // let isDebitDesc = false
      // colState.find((col) => {
      //   if(col.colId === 'debit')
      // })
      const selectedRows = gridRef.current.api.getSelectedRows();
      // console.log(selectedRows)
      if(selectedRows.length > 0) {
        setIsItemsSelected(true)
        // console.log(selectedRows)

      } else {
        setIsItemsSelected(false)
        // console.log(selectedRows)
      }
      // setSelectedItem(selectedRows)
      // gridRef.current.api.applyTransaction({ remove: selectedRows });
      // gridRef.current.columnApi.applyColumnState({
      //   state: colState
      // });
      // console.log(gridRef.current.columnApi.getColumnState())

    }
 
    const columnDefs = [
      {field:'RowId' , width: '150px',sortable: true, resizable: true, 
            checkboxSelection: true, headerCheckboxSelection: true, 
            headerCheckboxSelectionFilteredOnly:true ,
            pinned: 'left'
      },
      {field:'dataset' ,    width: '150px', sortable: true, resizable: true },
      {field:'ledger_id' ,  width: '100px',sortable: true, resizable: true },
      {field:'ledger_name' ,width: '100px', sortable: true, resizable: true },
      {field:'periodname' , width: '100px',sortable: true, resizable: true },
      {field:'reference_id' ,width: '100px', sortable: true, resizable: true },
      {field:'company' , width: '75px',sortable: true, resizable: true },
      {field:'location' ,width: '75px', sortable: true, resizable: true },
      {field:'costcenter' , width: '75px',sortable: true, resizable: true },
      {field:'account' , width: '75px',sortable: true, resizable: true },
      {field:'product' , width: '75px',sortable: true, resizable: true },
      {field:'channel' , width: '75px',sortable: true, resizable: true },
      {field:'project' , width: '75px',sortable: true, resizable: true },
      {field:'debit' , width: '150px',sortable: true, resizable: true, filterParams: numberFilterParams, comparator: (a,b) => (customSort(a,b))},
      {field:'credit' , width: '150px',sortable: true, resizable: true, filterParams: numberFilterParams, comparator: (a,b) => (customSort(a,b))},
      {field:'transaction_currency' , width: '75px',sortable: true, resizable: true },
      {field:'journalname' , width: '150px',sortable: true, resizable: true },
      {field:'linedescription' ,width: '350px', sortable: true, resizable: true },
      {field:'suppliernumber' , width: '150px',sortable: true, resizable: true },
      {field:'suppliername' , width: '150px',sortable: true, resizable: true },
      {field:'dateplacedinservice' , width: '150px',sortable: true, resizable: true, filter: 'agDateColumnFilter', filterParams: dateFilterParams },
      {field:'ponumber' , width: '150px',sortable: true, resizable: true },
      {field:'invoicenumber' , width: '150px',sortable: true, resizable: true },
      {field:'userid' , width: '150px',sortable: true, resizable: true },
      {field:'book_type_code' , width: '150px',sortable: true, resizable: true },
      {field:'po_item_category' , width: '150px',sortable: true, resizable: true },
      {field:'islocation_cip' ,width: '150px', sortable: true, resizable: true },
      {field:'location_go_live_date' ,width: '150px', sortable: true, resizable: true },
      {field:'isproject_cip' , width: '150px',sortable: true, resizable: true },
      {field:'project_go_live_date' , width: '150px',sortable: true, resizable: true },
      {field:'final_date_for_comparison' , width: '150px',sortable: true, resizable: true, filter: 'agDateColumnFilter', filterParams: dateFilterParams},
      {field:'isproject_lhi' , width: '150px',sortable: true, resizable: true },
      {field:'is_building' , width: '150px',sortable: true, resizable: true },
      {field:'lease_end_date' , width: '150px',sortable: true, resizable: true },
      {field:'sl_maj_cat' , width: '150px',sortable: true, resizable: true },
      {field:'sl_min_cat' , width: '150px',sortable: true, resizable: true },
      {field:'sl_book' ,width: '150px', sortable: true, resizable: true },
      {field:'final_maj_cat' ,width: '150px', sortable: true, resizable: true },
      {field:'final_min_cat' , width: '150px',sortable: true, resizable: true },
      {field:'eul' , width: '150px',sortable: true, resizable: true },
      {field:'dep_in_months' , width: '150px',sortable: true, resizable: true },
      {field:'dep_amount' , width: '150px',sortable: true, resizable: true, filterParams: numberFilterParams, comparator: (a,b) => (customSort(a,b))},
      {field:'cost_acct' , width: '150px',sortable: true, resizable: true },
      {field:'acc_dep_acct' , width: '150px',sortable: true, resizable: true },
      {field:'dep_acct' , width: '150px',sortable: true, resizable: true },
      {field:'WarningMsg' , width: '150px',sortable: true, resizable: true },
      {field:'Rule_Number' , width: '150px',sortable: true, resizable: true },
      {field:'Override' , width: '150px',sortable: true, resizable: true, filter: 'agSetColumnFilter' },
      {field:'Override_Prepare_JE_Reclass' , width: '150px',sortable: true, resizable: true },
      {field:'Override_Prepare_JE_Dep_Accrual' , width: '150px',sortable: true, resizable: true },
      {field:'Override_CIP_YN' , width: '150px',sortable: true, resizable: true },
      {field:'Override_InServiceDate_Month' , width: '150px',sortable: true, resizable: true },
      {field:'Override_InServiceDate_Year' , width: '150px',sortable: true, resizable: true },
      {field:'Override_Major_Min_Category' , width: '150px',sortable: true, resizable: true },
      {field:'Override_EUL' , width: '150px',sortable: true, resizable: true },
      {field:'Validation_Status' , width: '150px',sortable: true, resizable: true },
      {field:'Validation_err_msg' , width: '150px',sortable: true, resizable: true },
      {field:'JE_POSTED_STATUS' , width: '150px',sortable: true, resizable: true },
      {field:'Suggested_Maj_Cat' , width: '150px',sortable: true, resizable: true },
      {field:'Suggested_Min_Cat' , width: '150px',sortable: true, resizable: true },
      {field:'Logic_Checks' , width: '150px',sortable: true, resizable: true, filter: 'agSetColumnFilter'},
      {field:'last_update_date' , width: '150px',sortable: true, resizable: true, filter: 'agDateColumnFilter', filterParams: dateFilterParams },
      {field:'Suggested_sl_book' , width: '150px',sortable: true, resizable: true },
      {field:'teamname' , width: '150px',sortable: true, resizable: true },


    ]

    const onGridReady = useCallback((params) => {

      var defaultSortModel = [
        {colId: 'debit', sort: 'desc', sortIndex: 0}
      ]

      params.columnApi.applyColumnState({state: defaultSortModel});
    }, [])

    const customSort = (a,b) => {
      const numA = Number(a.replaceAll(",",""))
      const numB = Number(b.replaceAll(",",""))
      if(numA === numB) return 0;
      return (numA > numB) ? 1: -1;
  }

    const getRowId = useMemo(() => {
      return (params) => params.data.RowId;
    }, []);

    const rowClassRules = useMemo(() => {
      
      return {
        // row style function
        
        'rag-green':  (params) => { 
          var rID = params.data.Override
          
          return rID ==  'Yes'
          
           
          // == '23435729'; 
        }
        
      };
    }, [appliedChanges]);

   
    const showOverrideOnly = () => {
      gridRef.current.api.setFilterModel(null);
      const overridenItems = items.filter((item) =>
        item.Override.startsWith("Yes")
      );
      setSelectedReviewOption({
        label: "Overriden Rows",
        value: "OverridenRows",
      });
      setFilteredItems(overridenItems);
      setRowCount(overridenItems.length);
      setRowCountLabel("Overriden Rows");

      const initialValue = 0;
      setSumOfDebit(
        overridenItems.reduce(
          (sum, currVal) => sum + Number(currVal["debit"].replaceAll(",", "")),
          initialValue
        )
      );

      setSumOfCredit(
        overridenItems.reduce(
          (sum, currVal) => sum + Number(currVal["credit"].replaceAll(",", "")),
          initialValue
        )
      );
    }

    const showNonOverrideOnly = () => {
      gridRef.current.api.setFilterModel(null);
      const nonOverridenItems = items.filter((item)=> (!item.Override.startsWith('Yes')))
      setSelectedReviewOption({ label :"NonOverriden Rows" , value :"NonOverridenRows"})
      setFilteredItems(nonOverridenItems);
      setRowCount(nonOverridenItems.length)
      setRowCountLabel("NonOverriden Rows")

      const initialValue = 0;
      setSumOfDebit(
        nonOverridenItems.reduce(
          (sum, currVal) => sum + Number(currVal["debit"].replaceAll(",", "")),
          initialValue
        )
      );

      setSumOfCredit(
        nonOverridenItems.reduce(
          (sum, currVal) => sum + Number(currVal["credit"].replaceAll(",", "")),
          initialValue
        )
      );
    }

    const showAllExpensed = () =>{
      gridRef.current.api.setFilterModel(null);
       const expensedItems = items.filter((item)=>(item.final_maj_cat.toLowerCase().includes("expense")))
       setFilteredItems(expensedItems);
       setRowCount(expensedItems.length);
       setRowCountLabel("Expensed Rows")

      const initialValue = 0;
      setSumOfDebit(
        expensedItems.reduce(
          (sum, currVal) => sum + Number(currVal["debit"].replaceAll(",", "")),
          initialValue
        )
      );

      setSumOfCredit(
        expensedItems.reduce(
          (sum, currVal) => sum + Number(currVal["credit"].replaceAll(",", "")),
          initialValue
        )
      );
    };

    const showAllEquipment = () =>{
      gridRef.current.api.setFilterModel(null);
        const equipmentItems = items.filter((item)=>(item.Rule_Number.toUpperCase() === 'R220'))
        setFilteredItems(equipmentItems);
        setRowCount(equipmentItems.length);
        setRowCountLabel("Equipment Rows")

      const initialValue = 0;
      setSumOfDebit(
        equipmentItems.reduce(
          (sum, currVal) => sum + Number(currVal["debit"].replaceAll(",", "")),
          initialValue
        )
      );

      setSumOfCredit(
        equipmentItems.reduce(
          (sum, currVal) => sum + Number(currVal["credit"].replaceAll(",", "")),
          initialValue
        )
      );
    };

    const showAllMJE = () =>{
      gridRef.current.api.setFilterModel(null);
      const MJEItems = items.filter((item)=> {
       
       return (
                (item.dataset.toUpperCase().includes("GLAP") || 
                item.dataset.toUpperCase().includes("GLTD") ||
                item.dataset.toUpperCase().includes("MJE")) &&

                (item.final_maj_cat.toUpperCase() !== 'CIP') &&
                (item.Rule_Number.toUpperCase() !== 'R220') &&
                (!(item.final_maj_cat.toLowerCase().includes("expense")))
              )
    })
      setFilteredItems(MJEItems); 
      setRowCount(MJEItems.length);
      setRowCountLabel("MJE Rows")

      const initialValue = 0;
      setSumOfDebit(
        MJEItems.reduce(
          (sum, currVal) => sum + Number(currVal["debit"].replaceAll(",", "")),
          initialValue
        )
      );

      setSumOfCredit(
        MJEItems.reduce(
          (sum, currVal) => sum + Number(currVal["credit"].replaceAll(",", "")),
          initialValue
        )
      );
    };
    
    const showAllCapitalied = () =>{
      gridRef.current.api.setFilterModel(null);

      const capitalisedItems = items.filter((item)=>{

        return (
          (!item.final_maj_cat.toLowerCase().includes("expense")) &&

          (!((item.dataset.toUpperCase().includes("GLAP") || 
            item.dataset.toUpperCase().includes("GLTD") ||
            item.dataset.toUpperCase().includes("MJE")))) &&
            
          (item.Rule_Number.toUpperCase() !== 'R220') &&
          
          (item.final_maj_cat.toUpperCase() !== 'CIP') &&

          (item.Rule_Number.toUpperCase() !== 'RULE-1.1')
        )
      })
       setFilteredItems(capitalisedItems);
       setRowCount(capitalisedItems.length);
       setRowCountLabel("Capitalised Rows")

      const initialValue = 0;
      setSumOfDebit(
        capitalisedItems.reduce(
          (sum, currVal) => sum + Number(currVal["debit"].replaceAll(",", "")),
          initialValue
        )
      );

      setSumOfCredit(
        capitalisedItems.reduce(
          (sum, currVal) => sum + Number(currVal["credit"].replaceAll(",", "")),
          initialValue
        )
      );
         
    };

    const showAllCIP = () => {
      gridRef.current.api.setFilterModel(null);
      const CIPItems = items.filter((item)=>(item.final_maj_cat.toUpperCase() === 'CIP'))
      setFilteredItems(CIPItems);
      setRowCount(CIPItems.length);
      setRowCountLabel("CIP Rows")

      const initialValue = 0;
      setSumOfDebit(
        CIPItems.reduce(
          (sum, currVal) => sum + Number(currVal["debit"].replaceAll(",", "")),
          initialValue
        )
      );

      setSumOfCredit(
        CIPItems.reduce(
          (sum, currVal) => sum + Number(currVal["credit"].replaceAll(",", "")),
          initialValue
        )
      );
    };

    const showAllPreviouslyPosted = () => {
      gridRef.current.api.setFilterModel(null);
      const previouslyPostedItems = items.filter((item)=>(item.Rule_Number.toUpperCase() === 'RULE-1.1'))
      setFilteredItems(previouslyPostedItems);
      setRowCount(previouslyPostedItems.length);
      setRowCountLabel("Previously Posted Rows")

      const initialValue = 0;
      setSumOfDebit(
        previouslyPostedItems.reduce(
          (sum, currVal) => sum + Number(currVal["debit"].replaceAll(",", "")),
          initialValue
        )
      );

      setSumOfCredit(
        previouslyPostedItems.reduce(
          (sum, currVal) => sum + Number(currVal["credit"].replaceAll(",", "")),
          initialValue
        )
      );
    }

    const showLogicFailedRows = () =>{
      gridRef.current.api.setFilterModel(null);
      const logicFailedItems = items.filter((item)=>(item.Logic_Checks !== ''))
      setSelectedReviewOption({label :"Failed Logic Checks" , value :"FAILED-LOGIC-CHECKS"})
      setFilteredItems(logicFailedItems)
      setRowCount(logicFailedItems.length)
      setRowCountLabel("Failed Logic Check")

      const initialValue = 0;
      setSumOfDebit(
        logicFailedItems.reduce(
          (sum, currVal) => sum + Number(currVal["debit"].replaceAll(",", "")),
          initialValue
        )
      );

      setSumOfCredit(
        logicFailedItems.reduce(
          (sum, currVal) => sum + Number(currVal["credit"].replaceAll(",", "")),
          initialValue
        )
      );
    }

    const showRowsWithWarning = () =>{
      gridRef.current.api.setFilterModel(null);
      const rowsWithWarningItems = items.filter((item)=>(item.WarningMsg !== ''))
      setSelectedReviewOption({label :"Rows With Warning Msg" , value :"ROWS-WITH-WARNING-MSG"})
      setFilteredItems(rowsWithWarningItems)
      setRowCount(rowsWithWarningItems.length)
      setRowCountLabel("Rows With Warning Msg")

      const initialValue = 0;
      setSumOfDebit(
        rowsWithWarningItems.reduce(
          (sum, currVal) => sum + Number(currVal["debit"].replaceAll(",", "")),
          initialValue
        )
      );

      setSumOfCredit(
        rowsWithWarningItems.reduce(
          (sum, currVal) => sum + Number(currVal["credit"].replaceAll(",", "")),
          initialValue
        )
      );
    }

    const showAllRows = () =>{
      gridRef.current.api.setFilterModel(null);
      setSelectedReviewOption({ label :"All Rows" , value :"ALL-ROWS"})
      setFilteredItems(items)
      setRowCount(items.length)
      setRowCountLabel("All Rows")

      const initialValue = 0;
      setSumOfDebit(
        items.reduce(
          (sum, currVal) => sum + Number(currVal["debit"].replaceAll(",", "")),
          initialValue
        )
      );

      setSumOfCredit(
        items.reduce(
          (sum, currVal) => sum + Number(currVal["credit"].replaceAll(",", "")),
          initialValue
        )
      );
    }

    

    const handleFilter = (selectedOption) => {

      const {value:filterBy} = selectedOption;
      switch(filterBy) {
        case 'CIP':
          showAllCIP();
          break;
        case 'EQUIPMENT':
          showAllEquipment();
          break;
        case 'CAPITALISED':
          showAllCapitalied();
          break;
        case 'EXPENSED':
          showAllExpensed();
          break;
        case 'MJE':
          showAllMJE();
          break;
        case 'PREVIOUSLY-POSTED':
          showAllPreviouslyPosted();
          break;
        case 'FAILED-LOGIC-CHECKS':
          showLogicFailedRows();
          break;
        case 'ROWS-WITH-WARNING-MSG':
          showRowsWithWarning();
          break;
        default:
          showAllRows();
          break;
      }

    }

    const submitChangesClick = async () => {

    let res = await getBoffinComputesWithStartedStatus();

    if(res.length > 0) {
      setComputeInprogressModal(true)
      return;
    }

      setShowProcessing(true)
      setStatusUpdate('Sending info to server...')

      const authorization = (await Auth.currentSession())
      .getIdToken()
      .getJwtToken();

      appliedChanges.map((r1, index)=>{
        // console.log(r1)
        let rowNumber = index + 1
        let msg = "Processing change " + rowNumber + " / " + appliedChanges.length  + " rowId : " + r1.RowId
         
        setStatusUpdate(msg)

        let mSQL = ''
        mSQL = mSQL + "UPDATE compute_data "
        mSQL = mSQL + "SET Override = 'Yes', "
        mSQL = mSQL + "Override_CIP_YN ='" + r1.Override_CIP_YN +"', "
        mSQL = mSQL + "Override_Prepare_JE_Reclass ='" + r1.Override_Prepare_JE_Reclass +"', "
        mSQL = mSQL + "Override_Prepare_JE_Dep_Accrual = '" + r1.Override_Prepare_JE_Dep_Accrual +"', "
        mSQL = mSQL + "Override_InServiceDate_Month = '" + r1.Override_InServiceDate_Month +"', "
        mSQL = mSQL + "Override_InServiceDate_Year = '" + r1.Override_InServiceDate_Year +"', "
        mSQL = mSQL + "Override_Major_Min_Category = '" + r1.Override_Major_Min_Category +"', "
        mSQL = mSQL + "Override_EUL = '" + r1.Override_EUL +"' "
        mSQL = mSQL + "WHERE id ='" + r1.id + "' AND RowId = " + r1.RowId +";"
          
        // console.log(mSQL)
        let body =`{
          "processName" : "MySQL",
          "scriptName"  : "executeSQLonMySQL",
          "parameters"  : {"sql":"` + mSQL+ `"}
        }`
      
        // console.log(body)
      const ApiGateWayEndpoint = Amplify.Cache.config.ApiGateWayEndpoint;   
      let url = ApiGateWayEndpoint + "/executeOnDemand"
      axios.post(url, body,{
        headers: {
          Authorization: authorization
        },
      } )
      .then((response) => {
         console.log(response)
          
      });
      })
      setStatusUpdate('Requesting ReCompute')
      let body =`{
          
        "processName" : "boffin",
        "scriptName"  : "ReComputeNew",
        "parameters"  : {"computeId": "`+ currentComputeId +`"}
      }`

      const ApiGateWayEndpoint = Amplify.Cache.config.ApiGateWayEndpoint;   
      let url = ApiGateWayEndpoint + "/executeOnDemand"
      axios.post(url, body,
        {
          headers: {
            Authorization: authorization
          },
        })
      .then((response) => {
          console.log(response.status);
      });
      let mQry3 = `
          subscription MySubscription {
            onUpdateUI4BI_BoffinComputeInfo(id: "` + currentComputeId +`") {
              id
              processStatus
              percentageCompleted
            }
          }
          `
          // console.log(mQry3)
          API.graphql(graphqlOperation(mQry3)).subscribe({
            next: ({ provider, value }) => {
                var currentStatus = value.data.onUpdateUI4BI_BoffinComputeInfo.processStatus      
                var pc = parseInt(value.data.onUpdateUI4BI_BoffinComputeInfo.percentageCompleted)
                
                setShowProcessing(true)
                setStatusUpdate(currentStatus)
                setPercentageCompleted(pc)
                if (currentStatus==='Processing has completed'){
                  setProcessingStatus('success')
                }
                
            },
            error: (error) => console.warn(error)
        });
    }

    const overrideOptions = (
      <Container
        
      >
        

         
      </Container>
     )
    
    
     

      const content = (
        <div>
          {isAuthorized ? (
            <Box>
              <Container
                header={
                  <Header
                    description="Multiple rows can be selected"
                    actions={
                      <SpaceBetween direction="horizontal" size="l">
                        {isLoading ? (
                          <Modal visible={isLoading}>
                            <Box>
                              Loading...
                              <Spinner></Spinner>
                            </Box>
                          </Modal>
                        ) : (
                          <Box variant="small" color="text-body-secondary">
                            computeId: &nbsp; {currentComputeId}
                            <br></br>
                            <br></br>
                            <Box variant="awsui-key-label">Review Options</Box>
                            <Select
                              selectedOption={selectedReviewOption}
                              options={reviewOptions}
                              onChange={({ detail }) => {
                                setSelectedReviewOption(detail.selectedOption);
                                handleFilter(detail.selectedOption);
                              }}
                            ></Select>
                          </Box>
                        )}

                        <Button
                          variant="normal"
                          iconName="close"
                          onClick={() => {
                            history.push("/BoffinEditItem");
                          }}
                        ></Button>
                      </SpaceBetween>
                    }
                  >
                    Step #1: Select Rows To Override
                  </Header>
                }
              >
                <Board
                  renderItem={(item) => (
                    <BoardItem
                      header={
                        <Header variant="h3">
                          <SpaceBetween direction="horizontal" size="m">
                            <div>
                              {rowCountLabel} - Row Count:{" "}
                              <Badge color="blue">{rowCount}</Badge>
                            </div>
                            <div>
                              {rowCountLabel} - Sum of Debit:{" "}
                              <Badge color="blue">{Number(sumOfDebit.toFixed(2)).toLocaleString("en-US")}</Badge>
                            </div>
                            <div>
                              {rowCountLabel} - Sum of Credit:{" "}
                              <Badge color="blue">{Number(sumOfCredit.toFixed(2)).toLocaleString("en-US")}</Badge>
                            </div>
                          </SpaceBetween>
                        </Header>
                      }
                      i18nStrings={boardItemI18nStrings}
                    >
                      <Box>
                        <Multiselect
                          options={onscreenOverrideColumnOptions}
                          placeholder="Show / Hide Columns"
                          checkboxes={true}
                          selectedOptions={selectedColumns}
                          onChange={({ detail }) => {
                            setSelectedColumns(detail.selectedOptions);
                            const keys = detail.selectedOptions.map(
                              (option) => option?.value
                            );
                            let notSelected =
                              onscreenOverrideColumnOptions.filter(
                                (option) => !keys.includes(option.value)
                              );
                            notSelected = notSelected.map(
                              (option) => option?.value
                            );
                            gridRef.current.columnApi.setColumnsVisible(
                              keys,
                              true
                            );
                            gridRef.current.columnApi.setColumnsVisible(
                              notSelected,
                              false
                            );
                          }}
                          keepOpen={true}
                          hideTokens={true}
                        ></Multiselect>
                      </Box>
                      <div
                        className="ag-theme-alpine"
                        style={{ height: "90%", width: "100%" }}
                      >
                        <br />
                        <br />

                        <AgGridReact
                          ref={gridRef}
                          rowData={filteredItems}
                          defaultColDef={defaultColDef}
                          columnDefs={columnDefs}
                          rowSelection="multiple"
                          rowHeight={30}
                          animateRows={true}
                          checkboxSelection={true}
                          rowMultiSelectWithClick={true}
                          getRowId={getRowId}
                          onSelectionChanged={onSelectionChanged}
                          rowClassRules={rowClassRules}
                          enableCellTextSelection={true}
                          maintainColumnOrder={true}
                          suppressChangeDetection={true}
                          suppressMultiSort={true}
                          onGridReady={onGridReady}
                        ></AgGridReact>
                      </div>
                    </BoardItem>
                  )}
                  onItemsChange={(event) => setBoardItems(event.detail.items)}
                  items={boardItems}
                  i18nStrings={boardI18nStrings}
                />

                <br></br>
                <br></br>
              </Container>

              <br></br>
              <Container header={<Header>Step #3: Submit Override</Header>}>
                <SpaceBetween direction="horizontal" size="m">
                  <Button variant="normal" onClick={() => showOverrideOnly()}>
                    Show Overriden Rows only
                  </Button>
                  <Button
                    variant="normal"
                    onClick={() => showNonOverrideOnly()}
                  >
                    Show NonOverriden Rows
                  </Button>
                  <Button variant="normal" onClick={() => showAllRows()}>
                    Show All Rows
                  </Button>
                  <Button
                    variant="primary"
                    onClick={() => submitChangesClick()}
                  >
                    Submit
                  </Button>
                </SpaceBetween>
              </Container>

              {showProcessing ? (
                <Modal
                  visible={true}
                  onDismiss={() => {
                    history.push("/BoffinViewAllItems");
                  }}
                  header={<div>Processing Override</div>}
                  footer={
                    <Box float="right">
                      <SpaceBetween direction="horizontal" size="xs">
                        <Box variant="awsui-key-label">
                          Note: You can click close or wait until the processing
                          completes.
                        </Box>
                        <Button
                          variant="nomral"
                          onClick={() => {
                            history.push("/BoffinViewAllItems");
                          }}
                        >
                          Close
                        </Button>
                      </SpaceBetween>
                    </Box>
                  }
                >
                  <Flashbar
                    items={[
                      {
                        content: (
                          <ProgressBar
                            value={percentageCompleted}
                            variant="flash"
                            additionalInfo=""
                            description={statusUpdate}
                            label={"Processing : " + currentComputeId}
                          />
                        ),
                        id: "progressbar_1",
                      },
                    ]}
                  />
                </Modal>
              ) : (
                <Modal
                  visible={computeInprogressModal}
                  header={<div>Note!</div>}
                  onDismiss={() => {
                    setComputeInprogressModal(false);
                  }}
                >
                  Please ReCompute after sometime - Other Compute Underway!
                </Modal>
              )}
            </Box>
          ) : isCheckingAccess ? (
            <div>
              <WaitForPageAccess></WaitForPageAccess>
            </div>
          ) : (
            <div>
              <AccessDenied appName={appSettings.appName}></AccessDenied>
            </div>
          )}
        </div>
      );

  return (
    
    <AppLayout
          breadcrumbs={<BreadcrumbGroup items={breadItems} onFollow={(event)=>onFollowHandler(event)}></BreadcrumbGroup>}
          content={content}
          navigation={<SideNav activeHref="/BoffinNewOverride" />}
          headerSelector="#TopBar"
          navigationOpen={navigationOpen}
          onNavigationChange={()=>{setNavigationOpen(!navigationOpen)}}
          tools={helpContent}
          toolsWidth={500}
          onToolsChange={()=>{setToolsHide(!toolsHide)}}
          toolsOpen={toolsHide}
          maxContentWidth = {Number.MAX_VALUE}
          headerVariant="high-contrast"
        ></AppLayout>
  )
}

export default NewOverride
